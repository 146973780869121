import React, { useEffect, useState } from 'react'
import { DatePickerProps } from 'antd'
import { DatePicker, Input, InputNumber, InputProps, Select, SelectProps } from 'antd'
import { formatMap } from '@utils/index'
import moment, { isMoment } from 'moment'

const { RangePicker } = DatePicker

const DoubleInput = ({props1, props2, value: pValue, onChange, ...rest}: any) => {
    const [value, setValue] = useState<number[]>(pValue||[])
    const ownOnChange = (idx: number, val: any) => {
        const [val0, val1] = value
        const newVal = idx === 0 ? [val, val1] : [val0, val]
        setValue(newVal)
        onChange && onChange(newVal)
    }

    return (<div style={{display: 'flex', alignItems: 'center'}} {...rest}>
        <InputNumber style={{width: 'calc(50% - 10px)'}} {...props1} value={value[0]} onChange={(val) => ownOnChange(0, val)} />
        <span style={{display: 'inline-block', margin: '0 6px'}}>-</span>
        <InputNumber style={{width: 'calc(50% - 10px)'}} {...props2} value={value[1]} onChange={(val) => ownOnChange(1, val)} />
     </div>)
}

export default DoubleInput

interface InputCompactSelectProps extends InputProps {
    addonPosition: 'addonAfter' | 'addonBefore'
    selectProps?: SelectProps;
    value?: { type?: any, value: any };
    onChange?: (value?: any) => void
}
export const InputCompactSelect = ({addonPosition, selectProps, value, onChange, ...rest}:InputCompactSelectProps) => {
    const onOwnChange = (type: string, val: any) => {
        const newValue = {...value, [type]: val}
        onChange && onChange(newValue)
    }
    const addonProps = {[addonPosition]: <Select value={value?.type} onChange={(val) => onOwnChange('type', val)} {...selectProps} />}
    return (<Input
        value={value?.value}
        onChange={(e) => onOwnChange('value', e?.target.value)}
        {...addonProps}
        {...rest}
    />)
}



const dateOptions = [
    { label: '按日', value: 'date' },
    { label: '按月', value: 'month' },
    { label: '按年', value: 'year' },
]
type CompactDateValueType = {
    type: 'date' | 'week' | 'month' | 'quarter' | 'year',
    value: any
}
type CompactDateProps = DatePickerProps & {
    options?: any[];
    value?: CompactDateValueType;
    onChange?: (val: CompactDateValueType) => void;
}
const compactDatedefaultValue: CompactDateValueType = { type: 'date', value: [] }

export const handleCompactDateValue = (value: CompactDateValueType) => {
    const { type, value: date } = value
    const [ startDate, endDate ] = date || []
    if(!startDate || !endDate){
        return { type,
            value: [undefined, undefined]
        }
    }
    const format = formatMap['date']
    let newStartDate
    let newEndDate
    if (type === 'month') {
        newStartDate = moment(startDate).startOf('month').format(format)
        newEndDate =  moment(endDate).endOf('month').format(format)
    } else if(type === 'year') {
        newStartDate = moment(startDate).startOf('year').format(format)
        newEndDate =  moment(endDate).endOf('year').format(format)
    } else if (type === 'date') {
        newStartDate = moment(startDate).format(format)
        newEndDate =  moment(endDate).format(format)
    } else {
        newStartDate = startDate ? (isMoment(startDate) ? startDate : moment(startDate)).format(format) : undefined
        newEndDate = endDate ? (isMoment(endDate) ? endDate : moment(endDate)).format(format) : undefined
    }
    
    return {
        type,
        value: [newStartDate, newEndDate]
    }
}

export const handleCompactDateSubmitValue = (value: CompactDateValueType) => {
    console.log(value, handleCompactDateValue(value))
    // if (){

    // }
    const { type, value: date } = handleCompactDateValue(value)
    const map: any = {
        'date': 1,
        'month': 2,
        'year': 3
    }
    const timeType = map[type]
    const startTime = date[0]
    const endTime = date[1]
    return {
        timeType,
        startTime,
        endTime
    }
}

export const CompactDate = ({value: pValue, onChange, options, ...rest}: CompactDateProps) => {
    const [value, setValue] = useState<CompactDateValueType>(pValue||compactDatedefaultValue)
    const ownOnChange = (val: any, key: any) => {
        const newVal = {...value, ...{[key]: val}}
        console.log(newVal)
        setValue(newVal)
        onChange && onChange(newVal)
    }
    return (<Input.Group compact>
        <Select
           style={{ width: '26%' }}
           options={options || dateOptions}
           value={value?.type}
           onChange={(val: any) => ownOnChange(val, 'type')}
        />
        <RangePicker
           value={value?.value}
           picker={value?.type}
           onChange={(val: any) => ownOnChange(val, 'value')}
           {...rest}
           style={{ width: '74%' }}
        />
    </Input.Group>)
}
