import React, { useRef, useState } from "react"
import { useTableHooks } from "@hooks/index"
import { columnsFn } from './data'
import { Alert, Button, Col, Modal, Row, Table } from "antd"
import { productApi } from "@utils/index"
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home"
import { ActionType, ProTable } from "@ant-design/pro-table"
import { useHistory } from "react-router-dom"
import { BASEURL, consumeBillPageList } from "@api/index"
import { stringify } from "qs"
import {
    InfoCircleFilled
  } from '@ant-design/icons';
import { Description } from "@components/Common/AlertTip"
import { ExportButton } from "@components/index"
// 列表操作的 类型校验
type Action = '详情' | '修改' | '删除' | '标为已读'

// 接口请求的 api 层
const getApi = productApi(consumeBillPageList)  // 查询的接口封装
const addUpdateApi = productApi(homeTableAddUpdate) // 列表数据的新增/编辑 接口
const delApi = productApi(homeTableDel) // 表单数据的删除 接口

const Summary = ({summaryData}: any) => {
    return (<Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>汇总</Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}>{summaryData?.consumePay||0}</Table.Summary.Cell>
          <Table.Summary.Cell index={5}>{summaryData?.moneyPay||0}</Table.Summary.Cell>
          <Table.Summary.Cell index={6}>{summaryData?.cloudPay||0}</Table.Summary.Cell>
          <Table.Summary.Cell index={7}>{summaryData?.orderAmount||0}</Table.Summary.Cell>
        </Table.Summary.Row>
    </Table.Summary>)
}
export default () => {
    const history = useHistory()
    const formRef = useRef<Performance>()
    
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [summaryData, setSummaryData] = useState<any>({})
    const [modalProps, setModalProps] = useState({visible: false, title: '新增', params: {}})
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    // operate 函数为增删改查需要处理的业务
    const operate = (type: Action, record: any) => {
        const { aciId, useStatusStr } = record || {}
        if (type === '详情') {
            history.push(`/cost/cloudDetail?${stringify({aciId, useStatusStr})}`)
            console.log('需要处理的新增业务')
        } else if (type === '删除') {
            const ids = record ? [aciId] : selectedRowKeys
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                centered: true,
                cancelText: '取消',
                onOk: async() => {
                    await delApi({ids}, true, () => {
                        console.log('数据删除成功, 可以执行回调了')
                        reFresh()
                    })
                },
            });
        }
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        console.log(params)
        const { data } = await getApi({...params, sorter}, false)
        const { values, total, ...rest } = data || {}
        setSummaryData(rest)
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const columns = columnsFn(operate) // 表格列配置

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        console.log('处理后的成功回调触发了')
        setModalProps({
            ...modalProps,
            visible: false
        })
        reFresh()
    }
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const calcDelDisabled = () => selectedRowKeys.length
    const getQueryParams = () => ({
        // @ts-ignore
        ...formRef.current?.getFieldsFormatValue()
    })
    return (<>
        <Alert
            description={<Description title="消费账单用来记录使用产品产生的费用，反映出每个产品的消费情况。" listTips={[
            ]} />}
            type="warning"
        />
        <ProTable<any>
            columns={columns}
            params={{}} // 查询所需要的额外参数
            request={getListApi}
            formRef={formRef as any}
            options={{reload: false, density: false, setting: false}}
            toolbar={{
                actions: [
                    <ExportButton key="export" type='primary' action={`${BASEURL}/web/dataStat/consumerBillsExport`} param={getQueryParams} btnText="导出" />
                ],
            }}
            rowKey="id"
            pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
            actionRef={ref as any}
            search={{
                optionRender: (searchConfig, formProps, dom) => [ ...dom.reverse() ]
            }}
            // rowSelection={{
            //     preserveSelectedRowKeys: true,
            //     onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
            // }}
            summary={(data: any) => <Summary summaryData={summaryData} />}
        />
    </>)
}
