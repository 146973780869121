import React, { useEffect, useRef, useState } from "react"
import { Button, Card, Col, message, Modal, Popover, Row } from "antd"
import ProTable, { ActionType } from "@ant-design/pro-table"
import { columnsFn } from "./data"
import { ExcelExt, PROCODE_BANKCARDTHREEELEMENT, PROCODE_REALTIMECHECK, productApi, sleep } from "@utils/index"
import { emptyCheckRecord, emptyCheckResult, emptyCheckUpload, emptyCheckRecodDel, BASEURL, phoneCheckResult, phoneCheck } from "@api/index"
import { Icon } from "@components/Common"
import { UploadDragger } from "@components/Common/Upload"
import { Pie } from '@ant-design/plots';
import { ExclamationCircleOutlined, SettingOutlined } from "@ant-design/icons"
import chilun from '@assets/images/chilun.png'
import ExportButton, { useExportHooks } from "@components/Common/ExportButton"
import { fileExportUrl } from "@api/baseUrl"
const { confirm } = Modal;


const getApi = productApi(phoneCheckResult)  // 查询的接口封装
const delApi = productApi(emptyCheckRecodDel) // 删除检测记录

const mockData = [
    {
      type: '分类一',
      value: 27,
    },
    {
      type: '分类二',
      value: 25,
    },
    {
      type: '分类三',
      value: 18,
    },
];

const pieConfig = (total: any) => ({
    width: 300,
    height: 178,
    appendPadding: 10,
    data: mockData,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'outer',
    //   offset: '-50%',
      content: '{name} {value}',
    //   style: {
    //     textAlign: 'center',
    //     fontSize: 14,
    //   },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false as any,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        content: `<div style="font-weight: normal"><span style="font-size: 14px; color: #888">号码总量</span><br/><strong>${total||0}个</strong></div>`
      },
    },
  })

const ClearResultPopover = () => {
    const content = (<div style={{fontSize: 12}}>
        1. 实号：即为活跃号码，正常在网使用手机的用户。<br/>
        2. 空号：因长期欠费或无人使用的空置无效手机号。<br/>
        3. 停机：因欠缴费用在停机状态的用户。<br/>
        4. 关机：号码检测当时处于关机状态的用户。<br/>
        5. 沉默号：一种是超过半年没有激活过的空号，二是近三月流量均消耗低于30M。<br/>
        6. 风险号：长时间关机或未开通语音服务以及易投诉的用户。<br/>
        7. 流量卡：只有上网功能的号码。<br/>
        8. 呼叫转移：来电转移到了其它电话号码上的用户。<br/>
        9. 号码错误 ：非法手机号。<br/>
        10. 库无（不计费）：空号检测系统未查询到数据的用户。<br/>
        11. 格式错误（不计费）：非11位数字或非数字格式的手机号码。<br/>
        12. 未知（不计费）：非以上状态的其他用户。
    </div>)
    return (<Popover content={content} title={<div style={{display: 'flex', justifyContent: 'space-between'}}><span>号码检测状态</span></div>}>
        <Icon type="icon-ziyuan" />
    </Popover>)
}

const CleanLoading = ({data}: any) => {
    const { numberDetails, fileName, finishTime } = data || {}
    const canvasRef = useRef<any>(null)
    const draw = (ctx: any, fontSize: number, drops: any) => {
        const width = ctx.canvas.offsetWidth
        const height = ctx.canvas.offsetHeight
        const text = '1038013219056864081850183'.split('')
        ctx.fillStyle = 'rgba(255, 255, 255, 0.2)'
        ctx.fillRect(0, 0, width, height)
        ctx.fillStyle = 'rgba(168, 168, 168, 0.4)'
        ctx.font = fontSize + 'px arial'
        for (let i =0; i < 50; i++) {
            const char = text[Math.floor(Math.random() * text.length)]
            ctx.fillText(char, i * fontSize, drops[i] * fontSize)
            if (drops[i] * fontSize > height || Math.random() > 0.95) {
                drops[i] = 0
            }
            drops[i] +=1
        }
        requestAnimationFrame(() => draw(ctx, fontSize, drops))
    }
    useEffect(() => {
        const ctx = canvasRef.current && canvasRef.current?.getContext('2d')
        const width = ctx.canvas.offsetWidth
        const fontSize = 14
        const drops = []
        const len = width / fontSize
        for (let i =0; i < len; i++) {
            drops[i] = 1
        }
        draw(ctx, fontSize, drops)
    }, [])
    return (<div style={{position: 'relative'}}>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', width: '100%', height: '80%'}}>
            <div style={{position: 'relative', width: 100, height: 100}}>
            <img width={50} src={chilun} className="animate-spin" style={{animation: 'spin 3.6s linear infinite', position: 'absolute', left: 20}} />
            <img width={40} src={chilun} className="animate-spin" style={{animation: 'spin 3.6s linear infinite', position: 'absolute', top: 46}} />
            <img width={30} src={chilun} className="animate-spin" style={{animation: 'spin 3.6s linear infinite', position: 'absolute', right: 25, top: 50}} />
            </div>
            <div>
                <h2>正在检测中...</h2>
                <div>检测结果会保存，您可以稍后回来</div>
                <div>文件名称：{fileName}</div>
                <div>开始时间：{finishTime}</div>
            </div>
        </div>
        <canvas ref={canvasRef} style={{width: '100%', height: 260, marginTop: -20}}></canvas>
    </div>)
}

const ClearUpload = ({ onUpload, loading }: any) => {
    const [fileList, setFileList] = useState([])
    const customRequest = ({file, fileList}: any) => setFileList([file] as any)
    const startClean = () => {
        const [file] = fileList as any
        if (!file) {
            return message.warning('请上传检测文件')
        }
        confirm({
            title: '确认要开始检测吗?',
            icon: <ExclamationCircleOutlined />,
            content: `即将检测的文档是：${file?.name}，请再次确认！任务一旦启动将不支持取消。`,
            onOk: () => onUpload({'file': file}),
            onCancel() {
              console.log('Cancel');
            },
        });
    }
    return (<Card title="检测上传" extra={<a href={fileExportUrl('doc/手机号码模板.xlsx')}>下载模版</a>} style={{height: 330}}>
        <UploadDragger
            label={null}
            extra={null}
            description={''}
            fieldProps={{
                max: 1,
                accept: ExcelExt,
                listType: 'text',
                fileList,
                customRequest,
                onChange: ({fileList}: any) => setFileList(fileList)
            } as any}
        />
        <Button type="primary" style={{width: '100%'}} loading={loading} onClick={startClean}>{ loading ? '号码检测中' : '开始检测'}</Button>
  </Card>)
}

const ClearAnalysis = ({data, loading}: any) => {
    const { exportApi } = useExportHooks({action: `${BASEURL}/web/emptyCheck/exportEmptyCheckFile`, method: 'GET'})
    const { numberDetails, phoneTotal, fileName, finishTime, id } = data || {}
    const pieData = (numberDetails||[]).map((v: any) => ({type: v.statusDesc, value: v.count}))
    return (<Card title={<>检测结果 <ClearResultPopover /></>} extra={<a href="#" onClick={() => exportApi({id})}>下载</a>} style={{height: 330}}>
        {
            !loading ? <Row justify="space-between">
                            <Col>
                            <div>文件名: {fileName}</div>
                            <div>完成时间: {finishTime}</div>
                            </Col>
                            <Col>
                            <Pie {...pieConfig(phoneTotal)} data={pieData} />
                            </Col>
                        </Row> : <CleanLoading data={data} />
        }
  </Card>)
}

export default () => {
    const [cleanResult, setCleanResult] = useState({})
    const [loading, setLoading] = useState(false)
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    const formRef = useRef<any>()
    const timer = useRef<any>(null)
    const { exportApi } = useExportHooks({action: `${BASEURL}/web/emptyCheck/exportEmptyCheckFile`, method: 'GET'})
    const operate = async (type: any, record: any) => {
        const { id } = record || {}
        switch (type) {
            case '删除':
                return Modal.confirm({
                    title: '确认要删除该数据吗?',
                    content: '删除后当前内容将永久删除，不可恢复。',
                    okText: '确认',
                    centered: true,
                    cancelText: '取消',
                    onOk: async() => {
                        await delApi({id}, true, () => {
                            console.log('数据删除成功, 可以执行回调了')
                            ref.current?.reload()
                        })
                    },
                });
            case '下载':
                exportApi({id})
        }
    }
    const getListApi = async (params: any, sorter: any, filter: any) => {
        if (params.phone) {
            const res = await phoneCheck(params)
            await sleep(1000)
        }
        const { data } = await getApi({ ...params, phone: undefined }, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }
    const getCleanResultApi = async () => {
        const { data } = await emptyCheckResult() as any
        const { status } = data || {} // 获取检测结果状态 1执行中 2执行完成
        setLoading([1].includes(status)) // 1执行中 2执行完成
        setCleanResult(data)
    }
    const columns = columnsFn(operate) // 表格列配置
    const onUpload = async (params: any) => {
        const res = await emptyCheckUpload(params)
        reFresh()
    }

    const otherParams = { productCode:PROCODE_REALTIMECHECK }
    const getQueryParams = () => ({
        // @ts-ignore
        // ...formRef.current?.getFieldsFormatValue(),
        ...otherParams
    })

    // 刷新页面
    const reFresh = () => {
        ref.current?.reload()
        getCleanResultApi()
    }

    useEffect(() => {
        getCleanResultApi()
    }, [])

    useEffect(() => {
        if (loading) {
            timer.current = setInterval(reFresh, 3000)
        } else {
            clearInterval(timer.current)
        }
        return () => {
            clearInterval(timer.current)
        }
    }, [loading])

    return (<div>
        {/* <Row gutter={[12, 12]}>
            <Col span={10}><ClearUpload onUpload={onUpload} loading={loading} /></Col>
            <Col span={14}>
                <ClearAnalysis data={cleanResult} loading={loading} />
            </Col>
        </Row> */}
        <ProTable<any>
            headerTitle=""
            style={{ marginTop: 20 }}
            // scroll={{ x: 1300 }}
            columns={columns}
            params={{productCode:PROCODE_REALTIMECHECK}} // 查询所需要的额外参数
            request={getListApi}
            options={{ reload: false, density: false, setting: false }}
            toolbar={{
                actions: [
                    <ExportButton key="export" type='primary' action={`${BASEURL}/web/product/exportCardOperatorList`} param={getQueryParams} btnText="导出" />
                ],
            }}
            rowKey="id"
            pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
            actionRef={ref as any}
            formRef={formRef as any}
            search={{
                searchText: '开始检测',
                optionRender: (searchConfig, formProps, dom) => [...dom.reverse()]
            }}
        />
    </div>)
}
