import { Descriptions, Switch, Tooltip } from "antd"
import React from "react"
import {
    QuestionCircleOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    CopyOutlined
  } from '@ant-design/icons';
import { copyText } from "@utils/util";


//   组件小标题
export const TitleBar = ({title, tooltip, editNode}: {title: string, tooltip?: any, editNode?: any}) => {
    return (<div className='TextTitleDiv'>
    <span style={{ marginRight: '10px' }}>{ title }</span>
    {tooltip ? <Tooltip overlayStyle={{ maxWidth: '600px' }} title={tooltip}>
        {/* <template slot="title">
            <div dangerouslySetInnerHTML={{__html: tooltip}}></div>
        </template> */}
        <QuestionCircleOutlined />
      </Tooltip> : null
    }
    {editNode ? editNode : null}
  </div>)
}


// 开关小组件
export const SwitchAndMsg = ({label, textValue, alertMsg, onChange, checked}: any)=> {
    return (<div style={{ lineHeight: '30px' }}>
    <span>{label || '状态：'}</span>
    {textValue ? <span>{textValue}</span> : <Switch onChange={(data) => onChange(data)} checked={checked} />}
    {alertMsg ? <span className="alertMsg"><ExclamationCircleOutlined />{ alertMsg }</span> : null}
  </div>)
}

  
  // 数据展示列表  list：数据list     column列数
  export const DescriptionsPro = ({list, column, }: {list: any, column: number}) => {
    return <div className='descriptionsDiv'>
    <Descriptions title="" bordered column={column}>
      {list.map((element: any, index: number) => {
        const { copy, text } = element || {}
        return <Descriptions.Item key={index} label={element.label}>
          <div className='descriptionsItemDiv'>
            { text }
            {copy ? <CopyOutlined onClick={()=> copyText(text)}/> : null}
          </div>
          
        </Descriptions.Item>
      })
      }
    </Descriptions>
  </div>
  }

  