/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, Suspense, useState, useMemo } from 'react';
import './index.less';
import {
  PerformanceMonitor,
  AccumulativeShadows,
  ContactShadows,
  RandomizedLight,
  Environment,
  Lightformer,
  Float,
  useGLTF,
  useMask,
} from '@react-three/drei';

import Model_Clound from './cpnts/Clound'
import Model_Demo from './cpnts/Demo'
import Model_Earth from './cpnts/Earth'
import Model_CDN from './cpnts/CDN'
import { OrbitControls, useTexture  } from '@react-three/drei'
import * as THREE from 'three'
import img from '@assets/images/404.jpg'
import { useLoader } from '@react-three/fiber'
import { Canvas, applyProps, useFrame } from '@react-three/fiber'
import { LayerMaterial, Color, Depth } from 'lamina'
import gsap from 'gsap'
import { isBrowser } from '@utils/util';
// import HDRI from './yun.hdr'
const LayerMaterialCpn = LayerMaterial as any;
export default ({ type }: any) => {
  const [camerarData, setCamerarData] = useState(null) as any;
  const [firstPosition, setFirstPosition] = useState([]) as any;
  const [nowPosition, setNowPosition] = useState('') as any;

  
  useEffect(() => {
    setCamerarData({ x: -77.6, y: 0.2, z: 58 });
  }, [type]);
  function Lightformers({ positions = [2, 0, 2, 0, 2, 0, 2, 0] }) {
    const group = useRef<any>(null);
    // useFrame((state, delta) => (group.current && group.current.position) && (group.current.position.z += delta * 10) > 20 && (group.current.position.z = -60))
    return (
      <>
        {/* Ceiling */}
        <Lightformer
          intensity={0.75}
          rotation-x={Math.PI / 2}
          position={[0, 5, -9]}
          scale={[10, 10, 1]}
        />
        <group rotation={[0, 0.5, 0]}>
          <group ref={group}>
            {positions.map((x, i) => (
              <Lightformer
                key={i}
                form="circle"
                intensity={2}
                rotation={[Math.PI / 2, 0, 0]}
                position={[x, 4, i * 4]}
                scale={[3, 1, 1]}
              />
            ))}
          </group>
        </group>
        {/* Sides */}
        <Lightformer
          intensity={4}
          rotation-y={Math.PI / 2}
          position={[-5, 1, -1]}
          scale={[20, 0.1, 1]}
        />
        <Lightformer
          rotation-y={Math.PI / 2}
          position={[-5, -1, -1]}
          scale={[20, 0.5, 1]}
        />
        <Lightformer
          rotation-y={-Math.PI / 2}
          position={[10, 1, 0]}
          scale={[20, 1, 1]}
        />
        {/* Accent (red) */}
        <Float speed={5} floatIntensity={2} rotationIntensity={2}>
          <Lightformer
            form="ring"
            color="red"
            intensity={1}
            scale={10}
            position={[-15, 4, -18]}
            target={[0, 0, 0]}
          />
        </Float>
        {/* Background */}
        <mesh scale={100}>
          <sphereGeometry args={[1, 64, 64]} />
          <LayerMaterial side={THREE.BackSide}>
            <Color color="#444" alpha={1} mode="normal" />
            <Depth
              colorA="blue"
              colorB="black"
              alpha={0.5}
              mode="normal"
              near={0}
              far={300}
              origin={[100, 100, 100]}
            />
          </LayerMaterial>
        </mesh>
      </>
    );
  }
  function CameraRig({ v = new THREE.Vector3() }) {
    return useFrame((state) => {
      // console.log(state)
      const t = state.clock.elapsedTime;
      if (camerarData) {
        state.camera.position.lerp(camerarData, 0.1);
        setCamerarData(null);
      } else {
        state.camera.position.lerp(
          v.set(Math.sin(t / 3), 0.3, 2.7 + Math.cos(t / 5) / 3),
          0.08,
        );
      }
      // const [x,y] = nowPosition.length === 0 ? [0,0] : nowPosition
      // let timeLine = gsap.timeline()
      // timeLine.to(state.scene.rotation, {
      //   // duration: 0.5,
      //   y: x,
      //   x: y ,
      //   duration: 0.5,
      // })
      state.camera.lookAt(0,  0, 0);
    });
  }
  
  useEffect(()=> {
    let firstValue = [] as any
    // document.getElementById('canvas')?.addEventListener('mouseleave', ()=> {
    //   firstValue=[]
    //   setNowPosition([0,0])
    // })
    // document.getElementById('canvas')?.addEventListener('mousemove', (e) => {
    //   (firstValue.length === 0 ) && (firstValue = [e.clientX, e.clientY])
    //   let x = (e.clientX - firstValue[0]) / 1000
    //   let y = (e.clientY - firstValue[1]) / 1000
    //   setNowPosition([x,y])
    // })
  }, [])
  return (
    <div className="three3D_file_div" id="canvas">
      <Canvas
        camera={{
          position: [-67.6, 0.2, 25],
          fov: (() => {
            if (isBrowser()) {
              if (window.innerWidth < 600) {
                return 60;
              } else if (window.innerWidth > 1200) {
                return 30;
              } else {
                return 100 - (40 * window.innerWidth) / 600;
              }
            }
          })(),
        }}
        dpr={[1, 1]}
        shadows={false}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <Suspense fallback={null}>
          {type === "1" ? (
            <Model_Earth
              scale={0.0032}
              rotation={[0, -0.6, 0]}
              position={[0.25, -0.52, 0.05]}
            />
          ) : null}
          {type === "2" ? (
            <Model_Clound
              scale={0.004}
              rotation={[0, -0.6, 0]}
              position={[0.05, -0.35, 0.05]}
            />
          ) : null}
          {type === "3" ? (
            <Model_Demo
              scale={0.004}
              rotation={[0, -0.6, 0]}
              position={[0.15, -0.32, 0.05]}
            />
          ) : null}
          {type === "4" ? (
            <Model_CDN
              scale={0.0038}
              rotation={[0, -0.6, 0]}
              position={[-1.25, -0.52, 0.05]}
            />
          ) : null}

          <spotLight angle={1} position={[-80, 200, -100]} intensity={1} />
        </Suspense>
        {/* <OrbitControls /> */}
        <spotLight
          position={[0, 15, 0]}
          angle={0.3}
          penumbra={1}
          castShadow
          intensity={2}
        />
        <ambientLight intensity={0.5} />
        <Environment frames={1} resolution={16} blur={0.5}>
          <Lightformers />
        </Environment>
        <CameraRig />
      </Canvas>
    </div>
  );
};
