import React, { useEffect } from "react"
import './index.less'
import { Button, Table, Checkbox, Popover } from 'antd';
import { fileHttpUrl } from "@api/baseUrl";
import ProForm, {
    ProFormText,
    ProFormUploadDragger,
    ProFormSelect,
    ProFormTextArea,
    ProFormDependency,
    ProFormRadio,
    ProFormProps
} from '@ant-design/pro-form';
import Animation from "@common/Animation"
import { CarouselImg } from "@components/Home/AboutUs/CarouselImg";
import { Icon } from "@components/index";
import { GoRegister } from "../PageIndex";
import { StateType } from "@store/index";
import { useSelector } from "react-redux";

const TopDiv = () => {
    return (<div className="top-div" style={{ background: `url(${fileHttpUrl('fileServer/2022/11/07/bd8040e2cd1445e8927c5d7df9a5d923.png')}) no-repeat` }} >
        <div className="title">关于我们</div>
        <div className="mt-8">努力成为云市场最丰富的、最开放的API、SaaS、PaaS集成平台；</div>
        <div className="">为合作伙伴构建基于云极产品的成功业务实践和解决方案。</div>
    </div>)
}

const Introduce = () => {
    return (<div className="introduce divCard">
        <div className="home-aboutus-itemTitle">
            企业介绍 / <span>COMPANIES TO INTRODU</span>
        </div>
        <div className="content">
            <div className="flex justify-around">
                <div>
                    <div className="text-red-500 text-4xl font-semibold text-center"><Animation.NumberRoll>{10000}</Animation.NumberRoll>+</div>
                    <div className="text-center mt-4 font-semibold">企业用户和开发者</div>
                </div>
                <div>
                    <div className="text-red-500 text-4xl font-semibold text-center"><Animation.NumberRoll>{1000}</Animation.NumberRoll>万+</div>
                    <div className="text-center mt-4 font-semibold">云服务受益网民</div>
                </div>
                <div>
                    <div className="text-red-500 text-4xl font-semibold text-center"><Animation.NumberRoll>{10000}</Animation.NumberRoll>+</div>
                    <div className="text-center mt-4 font-semibold">云服务行业解决方案</div>
                </div>
            </div>
            <div className="mt-4 text-base mt-10 leading-9">
            云极成立于2019年，是浙江卓见云科技有限公司旗下的云市场平台，由公司独立自主研发、运营。云极致力于为企业提供一站式云服务，助力企业数字化建设，实现多云资源的调配和管理。
极致匠心，云联世界。云极为用户提供丰富、安全、稳定的云产品及服务，帮助合作伙伴构建基于云产品和服务的成功业务实践和解决方案。从金融、教育、传媒、通信、互联网到政企，各行各业与云极携手前行。
公司60%以上员工获得阿里云ACP专业认证工程师，涵盖各类方向，例如大数据、互联网架构、云安全和弹性计算。 卓见云现有正式员工160多位，95%以上本科学历，平均年龄26岁。
            </div>
        </div>
    </div>)
}

const Environment = () => {
    return (<div className="environment divCard">
        <div className="home-aboutus-itemTitle ">
            公司环境 / <span>COMPANY ENVIRONMENT</span>
        </div>
        <div className="imgDiv mt-6">
             <CarouselImg />
        </div>
    </div>)
}

const Culture = () => {
    return (<div className="divCard culture">
        <div className="home-aboutus-itemTitle">
            企业文化 / <span>ERTERPRISE CULTURE</span>
        </div>
        <div className="flex justify-between">
            <div className="cardDiv">
                <img src={fileHttpUrl("fileServer/2022/11/07/f0259bdae4ca43dea9b33002d874a7f4.png")} className='item' alt="云极愿景" />
                <div className="title">云极愿景</div>
                <div className="text">做最开放的API、SaaS、PaaS集成平台。</div>
            </div>
            <div className="cardDiv">
                <img src={fileHttpUrl("fileServer/2022/11/07/ce1ffa1876aa4e66880702f36979b96f.png")} className='item' alt="云极使命" />
                <div className="title">云极使命</div>
                <div className="text">一站式全场景，助力企业全方位效能提升。</div>
            </div>
            <div className="cardDiv">
                <img src={fileHttpUrl("fileServer/2022/11/07/a483a430d9e5457e88925eb76ead7ddc.png")} className='item' alt="服务理念" />
                <div className="title">服务理念</div>
                <div className="text">高效、安全、可靠，一切只为更好的服务。</div>
            </div>
        </div>
    </div>)
}

const ContactUs = () => {
    return (<div className="divCard contactUs">
        <div className="home-aboutus-itemTitle">
            联系我们 / <span>CONTACT US</span>
        </div>
        <div className="flex justify-between">
            <div className="left">
                <div><Icon type="icon-gongsiguanli" />公司：浙江卓见云科技有限公司</div>
                <div><Icon type="icon-31dianhua" />电话：0571-87960033</div>
                <div><Icon type="icon-dizhiguanli" />地址:  中国杭州 云栖小镇 鲤鱼山街18号3幢4层</div>
                <div><Icon type="icon-youxiang" />邮箱:  linhl@juncdt.com</div>
                <img src={fileHttpUrl("fileServer/2022/11/22/548bc79bce1c430ca06ae2fe38f55af5.png")} className='mt-6' alt="云极" />
                
            </div>
            <div className="">
                <img src={fileHttpUrl("fileServer/2022/11/22/10f0078702564f92a5d7483174390b21.jpg")} className='mt-6' alt="卓见云" />
            </div>
        </div>
    </div>)
}


export default () => {
    const { isnull, ueSaasAboutUs } = useSelector((state: StateType) => state.domainInfo) as any;
    return (<div className="j-home-aboutUs">
        {/* 关于我们页面先用 isnull判断是不是云极，1 的话就是云极，就用咱们写死的关于我们页面，非1的话就用传进来的富文本内容填充页面 */}
        {(!isnull && ueSaasAboutUs) ? <div dangerouslySetInnerHTML={{ __html: ueSaasAboutUs }}></div> : 
            <div>
                {/* 上部分的组件 */}
                <TopDiv />
                {/* 企业介绍 */}
                <Introduce />
                {/* 企业环境 */}
                <Environment />
                {/* 企业文化 */}
                <Culture />
                {/* 联系我们 */}
                <ContactUs />
                {/* 注册引导 */}
                <GoRegister />
            </div>
        }
       
    </div>)
}