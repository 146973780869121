/**
 * 测试实验室
*/

import { fileHttpUrl } from "@api/baseUrl"
import AlertTip from "@components/Common/AlertTip"
import { useAnimate, useAsyncTask } from "@hooks/index"
import { asyncSetTaskObj, setTaskObj } from "@store/actions"
import { StateType } from "@store/index"
import { Alert, Badge, Button, Card, Col, Popover, Progress, Row, Tag, Tooltip } from "antd"
import { throttle } from "lodash"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AsyncTask, Icon, Upload } from ".."
import ThreeModel from "../Common/ThreeModel"

const imgSrc = fileHttpUrl('test/image/2A504FE91B5E4347A9C088EBF9D2B5B2.png')

const listTips: any[] = [
    '我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述1',
    '我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述2',
    '我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述我是描述3',
]

const TestTask = () => {
    const dispatch = useDispatch()
    const addTask = (taskId: string) => {
        console.log(taskId)
        const thatTaskObj = {
            [taskId]: {
                taskName: '异步任务1',
                progress: 75,
                finish: false,
                url: ''
            }
        }
        dispatch(setTaskObj('ADD_TASK', {[taskId]: {}}))
    }
    const delTask = (taskId: string) => {
        dispatch(setTaskObj('DEL_TASK', [taskId]))
    }
    return (<div>
        <Button type="primary" onClick={() => addTask('taskId-1')}>异步任务1</Button>
        <Button type="primary" onClick={() => addTask('task-2')}>异步任务2</Button>
        <Button type="primary" onClick={() => addTask('task-3')}>异步任务3</Button>

        <Button type="primary" onClick={() => delTask('taskid-1')}>删除异步任务1</Button>
        <Button type="primary" onClick={() => delTask('taskid-2')}>删除异步任务2</Button>
        <Button type="primary" onClick={() => delTask('taskid-3')}>删除异步任务3</Button>
    </div>)
}

const TestAnimate = () => {
    const [className, onClick] = useAnimate()
    return (<div>
        <div className={className}>hello, react</div>
        <div>
            <Button onClick={onClick}>动画</Button>
        </div>
    </div>)
}

const TestLess = () => {
    return (<div>
        <span className="j-text-black-600">hello</span>
        <Button className="test-color" danger>hello, test</Button>
    </div>)
}

const TestCount = () => {
    let [count, setCount] = useState(0)
    const timer1 = useRef<any>(null)
    const timer2 = useRef<any>(null)
    const onMouseEnter = () => {
        clearInterval(timer2.current)
        timer1.current = setInterval(() => {
            count < 24 && setCount(++count)
        }, 30)
    }

    const onMouseLeave = () => {
        clearInterval(timer1.current)
        timer2.current = setInterval(() => {
            count > 0 && setCount(--count)
        }, 30)
    }

    return (<div>
        <div
            className="test-img"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={{
                background: `url(${imgSrc})`,
                width: 80,
                height: 80,
                backgroundSize: 'cover',
                backgroundPositionY: 80 * count
            }}
        ></div>
    </div>)
}

export default () => {
    useAsyncTask()
    return (<div className="j-test">
        <Alert showIcon message="hello, 欢迎来到组件实验室!" />
        <div style={{position: 'absolute', width: 800, height: 600, right: 60, top: 40, zIndex:999}}>
          <ThreeModel />
        </div>
        <div style={{padding: 20}}>
            <Row wrap gutter={[16, 16]}>
                <Col span={6}>
                    <Card title="带折叠展开的AlertTip(基于antd-Alert 二次封装)">
                        <AlertTip
                           type="warning"
                           listTips={listTips}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="通用上传组件(基于antd-upLoad 二次封装)">
                        <Upload />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="测试异步任务">
                        <TestTask />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="测试 Animate 动画库">
                        <TestAnimate />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="less 测试">
                        <TestLess />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="count 测试">
                        <TestCount />
                    </Card>
                </Col>
            </Row>
        </div>
        <AsyncTask />
    </div>)
}
