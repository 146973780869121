import ProTable, { ActionType } from "@ant-design/pro-table"
import React, { useEffect, useRef, useState } from "react"
import { OrignReqHeader, tooltipData } from "../../data"
import { SwitchAndMsg, TitleBar } from "./cpnts"
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { Button, message, Modal, Table } from "antd"
import ProForm, {
    ModalForm,
    ProFormText,
    ProFormSelect,
    ProFormRadio,
    ProFormCascader,
    ProFormTextArea,
    ProFormDateTimePicker,
    ProFormCheckbox,
    ProFormUploadButton
} from '@ant-design/pro-form';
import { ProFormDependency } from '@ant-design/pro-form';
import FormItem from "antd/es/form/FormItem";
import {
  QuestionCircleOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  CopyOutlined
} from '@ant-design/icons';
import { cdnDeleteBackOriginRequestHeader, cdnGetBackOriginConfig, cdnPageBackOriginHeaderByDomainId, cdnSaveOrUpdateBackOriginRequestHeader, cdnSwitchStatus } from "@api/product"
import { filterObj, parseSearch, productApi } from "@utils/util"
import { useLocation } from "react-router-dom"

const { confirm } = Modal;


// 接口请求的 api 层
const getDataApi = productApi(cdnGetBackOriginConfig)  // 域名回源配置查询
const getTableApi = productApi(cdnPageBackOriginHeaderByDomainId)  // 回源请求头查询
const submitApi = productApi(cdnSaveOrUpdateBackOriginRequestHeader)  // 回源请求头编辑新增提交事件
const backModeApi = productApi(cdnSwitchStatus)  // 回源方式修改提交事件
const delApi = productApi(cdnDeleteBackOriginRequestHeader)  // 回源请求头删除


const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

//回源方式修改
const AddDomainModal = ({ params, defaultValue, onSuccess, ...rest }: any) => {
    const formRef = useRef<ProFormInstance>();
    const onFinish = async (values: any) => {
        const data = { 
            ...values,
            ...params,
        }
        const { success } = await submitApi(
            filterObj(data),
            true,
            () => {
                clearData()
                onSuccess ? onSuccess() : null
            }
        )
        return true
    }
    
    // useEffect(()=> {
    //   console.log(2333, defaultValue, formRef.current)
    //   const {action, name, value } = defaultValue || {}
    //   console.log(2333, {action, name, value }, formRef.current)

    //   formRef.current?.setFieldsValue({action, name, value });
    // }, [defaultValue])
   
      const clearData = () => {
        formRef.current?.resetFields()
      }
    return (
      <ModalForm<any>
        layout="horizontal"
        width={600}
        
        {...layout}
        onFinish={onFinish}
        preserve={false}
        modalProps={{
          onCancel: clearData,
        }}
        {...rest}
        formRef={formRef}
      >
       
        {/* 请求头操作 */}
        <ProFormSelect
          width="md"
          name="action"
          label="请求头操作"
          initialValue={'set'}
          fieldProps={{
            allowClear: false,
            placeholder: "请选择请求头操作",
          }}
          tooltip={tooltipData['YZLX']}
          options={[
                {
                value: 'set',
                label: '设置'
                },
                {
                value: 'delete',
                label: '删除'
                }
            ]}
          rules={[{ required: true, message: "请选择请求头操作" }]}
        />
        <ProFormText
          width="md"
          name="name"
          rules={[{ required: true, message: "请输入请求头参数" }]}
          label="请求头参数"
          placeholder="请输入请求头参数"
        />
        <ProFormDependency
            name={["action"]}
          >
            {({ action }: any) => {
              return (
                <>
                  {action === 'set' ? <ProFormText
                    width="md"
                    name="value"
                    rules={[{ required: true, message: "请输入请求头取值" }]}
                    label="请求头取值"
                    placeholder="请输入请求头取值"
                    /> : null}
                </>
              )
            }}
          </ProFormDependency>
      </ModalForm>
    );
}



//回源方式编辑
const BackToModeModal = ({ defaultValue, params, onSuccess, ...rest }: any) => {
  const formRef = useRef<ProFormInstance>();
  const [sourceName, setSourceName] = useState('')
  const [sourceTestValue, setSourceTestValue] = useState('')
  const [testResult, setTestResult] = useState(false)

  const onFinish = async (values: any) => {
      const data = { 
          ...values,
          ...params,
      }
      const { success } = await backModeApi(
          filterObj(data),
          true,
          () => {
              clearData()
              onSuccess ? onSuccess() : null
          }
      )
      return true
  }

 
    const clearData = () => {
      formRef.current?.resetFields()
    }
  return (
    <ModalForm<any>
      layout="horizontal"
      width={600}
      formRef={formRef}
      {...layout}
      onFinish={onFinish}
      preserve={false}
      modalProps={{
        onCancel: clearData,
      }}
      {...rest}
    >
     
      {/* 源站类型 */}
      <ProFormSelect
        width="md"
        name="status"
        label=" 协议类型"
        initialValue={defaultValue}
        fieldProps={{
          allowClear: false,
          placeholder: "请选择 协议类型",
          defaultValue: 1,
        }}
        tooltip={tooltipData['YZLX']}
        options={[
              {
              value: 0,
              label: '协议跟随'
              },
              {
              value: 1,
              label: 'HTTP'
              },
              {
                value: 2,
                label: 'HTTPS'
                },
          ]}
        rules={[{ required: false, message: "请选择源站类型" }]}
      />
    </ModalForm>
  );
}


export default () => {
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    const formRef = useRef<ProFormInstance>();
    const [backSourceData, setBackSourceData] = useState({
      backOriginFollowStatus: 0,
      backOriginProtocolType: 0,
      backOriginTimeout: 0,
      id: 65,
      rangeBackOriginStatus: 0,
    });
    const [modalProps, setModalProps] = useState({visible: false, title: '新增', params: {}, request: {}})


    const { pathname, search } = useLocation();
    const { domainId } = parseSearch(search)

    const operation = async (type: string, data?: any) => {
      console.log(type, data)
      const param = {
        status: data * 1,
        domainId,
        action: type === 'RanGe回源' ? 'range' : (type === '回源跟随' ? 'follow302' : '')
      }  as any
      
      const { success } = await backModeApi(
          filterObj(param),
          true,
          () => {
            getData()
          }
      )
      return true
    }
  
    const getData = async ()=> {
      const { data } = await getDataApi({domainId}, false) 
      setBackSourceData(data)
    }
    
    useEffect(()=> {
      getData()
    }, [])

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getTableApi({...params, domainId}, false)
        const {  values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const columnsFun = (type: string, data:any)=> {
      const {action, name, value, id } = data || {}

       if (type === '编辑'){
        console.log(222, data)
          setModalProps({
            ...modalProps,
            request: async() => {
              return {action, name, value }
            },
            title: '回源请求头编辑',
            params: {id, domainId},
            visible: true,
          })
       } else if(type === '删除'){
          confirm({
            title: '温馨提示',
            icon: <ExclamationCircleOutlined />,
            content: '确认删除回源请求头？',
            centered: true,
            onOk: async () => {
              await delApi({ ...data, domainId }, true)
              ref.current?.reload()
            }
          });
        
       } else if(type === '新增'){
          setModalProps({
            ...modalProps,
            title: '回源请求头',
            request: async() => {
              return {action: 'set', name: '', value: '' }
            },
            params: {domainId},
            visible: true,
          })
       }
    }
 
    const columns = OrignReqHeader(columnsFun)

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        ref.current?.reload()
        setModalProps({
          ...modalProps,
          visible: false,
      })
    }

    return (<div>
        <TitleBar title='回源方式'  tooltip={tooltipData.HYFS}  editNode={<BackToModeModal defaultValue={backSourceData.backOriginProtocolType} onSuccess={getData} params={{domainId, action: 'setbackOriginProtocolType'}} key="btn0" title="回源方式" trigger={<EditOutlined className="float-right"  type='primary' />}  />} />
        <SwitchAndMsg
          textValue={['协议跟随', 'HTTP', 'HTTPS'][backSourceData.backOriginProtocolType]}
          alertMsg='CDN默认回源方式为协议跟随，若协议类型选择“协议跟随”时，回源协议遵循客户端的请求方式。'
        />
        <TitleBar title='Range回源'  tooltip={ tooltipData.RANGEHY} />

        <SwitchAndMsg
          checked= {!!backSourceData.rangeBackOriginStatus}
          onChange={(data: any) => operation('RanGe回源', data)}
          alertMsg='开启前，请确认您的源站是否支持Range请求，若不支持，开启后可能导致回源失败。'
        />
        <TitleBar title='回源跟随'  tooltip={ tooltipData.HYGS} />
        <SwitchAndMsg
          checked= {!!backSourceData.backOriginFollowStatus}
          onChange={(data: any) => operation('回源跟随', data)}
          alertMsg='开启后，IPv6的客户端请求将支持以ipv6协议访问CDN，CDN也将携带ipv6的客户端IP信息访问您的源站。'
        />
        <TitleBar title='回源请求头'  tooltip={ tooltipData.HYQQT} />
        <ProTable<any>
        columns={columns}
        params={{}} // 查询所需要的额外参数
        request={getListApi}
        options={{reload: false, density: false, setting: false}}
        toolbar={{
            actions: [
              <Button key="btn0" type='primary' onClick={() => columnsFun('新增', null)}>添加</Button>,
            ],
        }}
        // rowKey="tiId"
        pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
        actionRef={ref as any}
        formRef={formRef}
        search={false}
        rowSelection={false}
    />
    <AddDomainModal
        modalProps={{
            maskClosable: false, 
            destroyOnClose: true,
            onCancel: () => setModalProps({...modalProps, visible: false})
        }}
        onSuccess={onSuccess} 
        {...modalProps} 
    />
    {/* <AddDomainModal params={{domainId}} onSuccess={onSuccess} key="btn0" title="回源请求头" /> */}
    </div>)
}
