import React, { useState } from "react"
import { Descriptions, Table } from "antd"
import { ActionType, ProTable } from "@ant-design/pro-table"
import { columnsFn } from "./data"
import { parseSearch, productApi } from "@utils/index"
import { homeSearch } from "@api/home"
import { BackBan } from "@components/Common"
import { getCostMyCloudDetail } from "@api/index"
import { useLocation } from "react-router-dom"
import { cloudStatusOptions } from "../../data"
// 接口请求的 api 层
const getApi = productApi(getCostMyCloudDetail)  // 查询的接口封装

const BaseInfo = ({baseInfo}: any) => {
    const { usableClouds, endTime } = baseInfo || {}
    return (<Descriptions bordered style={{padding: '0 20px', background: '#fff'}}>
    <Descriptions.Item label="可用云朵">{usableClouds||0}</Descriptions.Item>
    <Descriptions.Item label="失效时间">{endTime||''}</Descriptions.Item>
  </Descriptions>)
}

const DetailTable = () => {
    const { search } = useLocation();
    const { aciId, useStatusStr } = parseSearch(search)
    const [baseInfo, setBaseInfo] = useState<any>({})
    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params}, false)
        const { values, total, ...baseInfo } = data || {}
        setBaseInfo(baseInfo)
        return {
            data: values || [],
            success: true,
            total
        }
    }
    // 臧强说: 这个字段是根据时间计算出来的，目前没时间，暂时不返回，从列表中带
    const calcOrderTitle = () => cloudStatusOptions.find(v => v.value === baseInfo.useStatus)?.label
    return (<div style={{background: '#fff'}}>
        <BackBan title={useStatusStr}></BackBan>
        <div className="j-zen-ban" style={{margin: 20}}>基本信息</div>
        <BaseInfo baseInfo={baseInfo} />
        <div className="j-zen-ban" style={{margin: 20}}>收支明细</div>
        <ProTable<any>
            columns={columnsFn(() => null)}
            params={{aciId}} // 查询所需要的额外参数
            request={getListApi}
            options={{reload: false, density: false, setting: false}}
            search={false}
            toolbar={{}}
            rowKey="id"
            pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
        />
</div>)
}

export default DetailTable