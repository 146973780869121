import { ProColumns } from "@ant-design/pro-table";
import { fileHttpUrl } from "@api/baseUrl";
import { copyText } from "@utils/util";
import { Input, Select, Switch } from "antd";
import React, { ReactNode } from "react";
import { CopyOutlined } from "@ant-design/icons";
import { stringify } from "qs";
import { cdnDomainsOfCurrentUser } from "@api/product";
const options = [
  { label: "options1", value: 1 },
  { label: "options2", value: 2 },
];

// 加速类型
export const accelerateType = [
  {
    value: 1,
    label: "网页小文件",
  },
  {
    value: 2,
    label: "大文件下载",
  },
  {
    value: 3,
    label: "音视频点播",
  },
  {
    value: 4,
    label: "全站加速",
  },
];

export const valueEnumAccelerateType = {
  1: { text: "网页小文件" },
  2: { text: "大文件下载" },
  3: { text: "音视频点播" },
  4: { text: "全站加速" },
};

export const sourceType = [
  {
    value: 1,
    label: "源站IP",
  },
  {
    value: 2,
    label: "源站域名",
  },
];

const valueEnum = {
  // 0: { text: '全部', status: 'Default' },
  1: { text: "一致", status: "Sueecss" },
  2: { text: "不一致", status: "Error" },
  3: { text: "其他错误", status: "Processing" },
};

const valueEnumSwitch = {
  // 0: { text: '全部', status: 'Default' },
  1: { text: "开启", status: "Sueecss" },
  0: { text: "关闭", status: "Error" },
};

const valueEnumCharge = {
  0: { text: "不收费", status: "Default" },
  1: { text: "收费", status: "Sueecss" },
};

export const valueEnumStatus = {
  1: { text: "已开启" },
  2: { text: "已停用" },
  3: { text: "配置中" },
  4: { text: "配置失败" },
  5: { text: "审核中" },
  6: { text: "审核未通过" },
  7: { text: "删除" },
};

/**
 * 列表表格配置:
 *  自定义表格 CustomerDemo 和 antd 升级版组件 ProTableDemo 都可使用,
 * */
export const columnsFn: (operate: Function) => ProColumns<any>[] = (
  operate
) => {
  return [
    {
      title: "域名",
      dataIndex: "domainName",
      valueType: 'select',
      fieldProps:{
        showSearch: true,
      },
      request: async() => {
        const { data } = await cdnDomainsOfCurrentUser({}) as any
         return (data || []).map((v: any) => ({label: v, value: v}))
      },
      render: (text: any, record: any) => <a href={`/product/cdn/domianConfig?${stringify({domainId: record.id, domainName: record.domainName})}`}>{text}</a>,
    },
    {
      title: "状态",
      dataIndex: "domainStatus",
      valueType: "select",
      valueEnum: valueEnumStatus,
      hideInSearch: true,
    },
    {
      title: "启用停用",
      dataIndex: "domainStatus",
      render: (text: any, record: any, index: number) => (
        record.domainStatus < 3 ? <Switch
          checkedChildren="开启"
          unCheckedChildren="关闭"
          checked={text === 1}
          onChange={(data) =>
            operate(data ? "open" : "close", {
              ...record,
              checked: data,
              index,
            })
          }
        /> : null
      ),
      hideInSearch: true,
    },

    {
      title: "CNAME",
      dataIndex: "cname",
      hideInSearch: true,
      render: (text: any, record: any, index: number) => (
        <div className="descriptionsItemDiv">
          {text}
          <CopyOutlined onClick={() => copyText(text)} />
        </div>
      ),
    },
    {
      title: "加速类型",
      dataIndex: "businessType",
      valueType: 'select',
      // fieldProps: { mode: 'multiple' },
      valueEnum: valueEnumAccelerateType,
      // valueType: 'dateTimeRange',
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      valueType: 'dateTime',
      order: 4,
      hideInSearch: true,
    },
    {
      title: "操作",
      dataIndex: "operate",
      key: "operate",
      valueType: "option",
      fixed: "right",
      render: (text: any, record: any) => [
        <a
          key="配置管理"
          style={{ marginRight: 8 }}
          onClick={() => operate("配置管理", record)}
        >
          配置管理
        </a>,
        <a key="删除" onClick={() => operate("del", record)}>
          删除
        </a>,
      ],
    },
  ];
};

// 域名版
export const domainColumnsFn_domain: (
  operate: Function,
  table:any
) => ProColumns<any>[] = (operate, table) => {
  return [
    {
      title: "#",
      dataIndex: "index",
      width: 50,
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: "源站域名",
      //   slots: { title: 'customTitle' },
      dataIndex: "domainName",
      width: 350,
      render: (text: any, record: any, index: number) => (
        <Input
          value={text}
          onChange={(value) =>
            operate("修改域名", {
              ...record,
              index,
              ipOrDomain: value.target.value,
            })
          }
          placeholder="请输入域名，源站域名不能是加速域名"
        />
      ),
    },
    {
      title: "源站优先级",
      //   slots: { title: 'priorityTitle' },
      dataIndex: "activeStandby",
      width: 120,
      render: (text: any, record: any, index: number) => (
        <Switch
          checkedChildren="主源站"
          unCheckedChildren="备源站"
          checked={text}
          onChange={(data) =>
            operate("源站优先级", {
              ...record,
              activeStandby: data ? 1 : 0,
              index,
            })
          }
        />
      ),
    },
    {
      title: "操作",
      width: 120,
      dataIndex: "operation",
      render: (text: any, record: any, index: any) => (
        <div>
          {table.length < 15 ? <a
            onClick={(data) => operate("add", { ...record, index })}
            className="mr-2"
            key="添加"
          >
            添加
          </a>: null}
          {!record.activeStandby ? (
            <a
              onClick={(data) => operate("del", { ...record, index })}
              key="删除"
            >
              删除
            </a>
          ) : null}
        </div>
      ),
    },
  ];
};

// ip版
export const domainColumnsFn_ip: (operate: Function, table:any) => ProColumns<any>[] = (
  operate, table
) => {
  return [
    {
      title: "#",
      dataIndex: "index",
      width: 50,
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: "IP地址",
      //   slots: { title: 'customTitle' },
      dataIndex: "domainName",
      width: 350,
      render: (text: any, record: any, index: number) => (
        <Input
          value={text}
          onChange={(value) =>
            operate("修改域名", {
              ...record,
              index,
              ipOrDomain: value.target.value,
            })
          }
          placeholder="请输入IPv4格式的IP地址，最多支持15个源站IP"
        />
      ),
    },
    {
      title: "源站优先级",
      //   slots: { title: 'priorityTitle' },
      dataIndex: "activeStandby",
      width: 120,
      render: (text: any, record: any, index: number) => (
        <Switch
          checkedChildren="主源站"
          unCheckedChildren="备源站"
          checked={text}
          onChange={(data) =>
            operate("源站优先级", {
              ...record,
              activeStandby: data ? 1 : 0,
              index,
            })
          }
        />
      ),
    },
    {
      title: "操作",
      width: 120,
      dataIndex: "operation",
      render: (text: any, record: any, index: any) => (
        <div>
          {table.length < 15 ? <a
            onClick={(data) => operate("add", { ...record, index })}
            className="mr-2"
            key="添加"
          >
            添加
          </a>: null}
          {!record.activeStandby ? (
            <a
              onClick={(data) => operate("del", { ...record, index })}
              key="删除"
            >
              删除
            </a>
          ) : null}
        </div>
      ),
    },
  ];
};

// 编辑源站  域名版
export const editDomainColumnsFn_domain: (
  operate: Function,
  table:any
) => ProColumns<any>[] = (operate, table) => {
  return [
    {
      title: "#",
      dataIndex: "index",
      width: 50,
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: "源站域名",
      //   slots: { title: 'customTitle' },
      dataIndex: "ipOrDomain",
      width: 350,
      render: (text: any, record: any, index: number) => (
        <Input
          value={text}
          onChange={(value) =>
            operate("修改域名", {
              ...record,
              index,
              ipOrDomain: value.target.value,
            })
          }
          placeholder="请输入域名，源站域名不能是加速域名"
        />
      ),
    },
    {
      title: "HTTPS端口",
      dataIndex: "HTTPS",
      width: 150,
      render: (text: any, record: any, index: number) => (
        <Input
          value={text}
          onChange={(value) =>
            operate("HTTPS", {
              ...record,
              index,
              portHttps: value.target.value,
            })
          }
          placeholder="HTTPS端口"
        />
      ),
    },
    {
      title: "HTTP端口",
      dataIndex: "HTTP",
      width: 150,
      render: (text: any, record: any, index: number) => (
        <Input
          value={text}
          onChange={(value) =>
            operate("HTTP", { ...record, index, portHttp: value.target.value })
          }
          placeholder="HTTP端口"
        />
      ),
    },
    {
      title: "源站优先级",
      //   slots: { title: 'priorityTitle' },
      dataIndex: "activeStandby",
      width: 120,
      render: (text: any, record: any, index: number) => (
        <Switch
          checkedChildren="主源站"
          unCheckedChildren="备源站"
          checked={text}
          onChange={(data) =>
            operate("源站优先级", { ...record, checked: data, index })
          }
        />
      ),
    },
    {
      title: "操作",
      width: 120,
      dataIndex: "operation",
      render: (text: any, record: any, index: any) => (
        <div>
          {table.length < 15 ? <a
            onClick={(data) => operate("add", { ...record, index })}
            className="mr-2"
            key="添加"
          >
            添加
          </a>: null}
          {!record.activeStandby ? (
            <a
              onClick={(data) => operate("del", { ...record, index })}
              key="删除"
            >
              删除
            </a>
          ) : null}
        </div>
      ),
    },
  ];
};

// 编辑源站  ip版
export const editDomainColumnsFn_ip: (
  operate: Function,
  table: any
) => ProColumns<any>[] = (operate, table) => {
  return [
    {
      title: "#",
      dataIndex: "index",
      width: 50,
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: "IP地址",
      //   slots: { title: 'customTitle' },
      dataIndex: "ipOrDomain",
      width: 350,
      render: (text: any, record: any, index: number) => (
        <Input
          value={text}
          onChange={(value) =>
            operate("修改域名", {
              ...record,
              index,
              ipOrDomain: value.target.value,
            })
          }
          placeholder="请输入IPv4格式的IP地址，最多支持15个源站IP"
        />
      ),
    },
    {
      title: "HTTPS端口",
      dataIndex: "portHttps",
      width: 150,
      render: (text: any, record: any, index: number) => (
        <Input
          value={text}
          onChange={(value) =>
            operate("HTTPS", {
              ...record,
              index,
              portHttps: value.target.value,
            })
          }
          placeholder="HTTPS端口"
        />
      ),
    },
    {
      title: "HTTP端口",
      dataIndex: "portHttp",
      width: 150,
      render: (text: any, record: any, index: number) => (
        <Input
          value={text}
          onChange={(value) =>
            operate("HTTP", { ...record, index, portHttp: value.target.value })
          }
          placeholder="HTTP端口"
        />
      ),
    },
    {
      title: "源站优先级",
      //   slots: { title: 'priorityTitle' },
      dataIndex: "activeStandby",
      width: 120,
      render: (text: any, record: any, index: number) => (
        <Switch
          checkedChildren="主源站"
          unCheckedChildren="备源站"
          checked={text}
          onChange={(data) =>
            operate("源站优先级", { ...record, checked: data, index })
          }
        />
      ),
    },
    {
      title: "操作",
      width: 120,
      dataIndex: "operation",
      render: (text: any, record: any, index: any) => (
        <div>
          {table.length < 15 ? <a
            onClick={(data) => operate("add", { ...record, index })}
            className="mr-2"
            key="添加"
          >
            添加
          </a> : null}
          {!record.activeStandby ? (
            <a
              onClick={(data) => operate("del", { ...record, index })}
              key="删除"
            >
              删除
            </a>
          ) : null}
        </div>
      ),
    },
  ];
};

export const tooltipData = {
  JSYM: (
    <div>
      <div>【加速域名】说明：</div>
      <div>●域名长度不能超过75个字符，支持大小写字母、数字、“-”、“.”。</div>
      <div>●每个帐户最多支持加速100个域名。</div>
      <div>
        ●CDN不支持非法网站接入，对于已接入CDN的域名，会进行定期复审，如发现存在违规行为，将立即中止该域名的CDN加速，同时中止该用户名下所有域名的CDN服务。
      </div>
      <div>
        ●加速域名处于“停用”或“审核未通过”状态超过120天，系统会自动删除该域名相关记录。
      </div>
      <div>●CDN平台会自动停用超过180天没有任何访问流量的域名。</div>
      <div>●加速域名不允许重复添加。</div>
      <div>
        ●支持添加泛域名作为加速域名，如：您在CDN添加泛域名*.test.com作为加速域名，并将*.test.com解析至CDN生成的CNAME域名后，那么您所有*.test.com的次级域名（如a.test.com）都将默认支持CDN加速。泛域名（*.test.com）的三级域名（如b.a.test.com）不会被CDN加速。
      </div>
      <div>
        （1）A帐号添加泛域名后，其他帐号不能再添加该泛域名的所有次级域名。
      </div>
      <div>
        （2）泛域名不允许嵌套，如已添加*.a.b.com泛域名，不允许再添加
        *.c.a.b.com和*.b.com。
      </div>
      <div>
        （3）泛域名的所有次级域名的加速都会产生费用，泛域名有多个次级域名时，CDN统计时将泛域名的产生的流量做汇总，不提供单个次级域名的计费数据。
      </div>
    </div>
  ),
  JSLX: (
    <div>
      <div>【加速类型】说明：</div>
      <div>适用于不同场景下的加速类型：</div>
      <div>
        ●网页小文件：适用于电商、网站、UGC
        社区等以小型静态资源（如网页样式、图片和小文件）为主的业务场景。
      </div>
      <div>
        ●大文件下载：适用于较大文件，如游戏安装包、应用更新、应用程序包下载等业务场景。
      </div>
      <div>
        ●音视频点播：适用于在线音视频点播等音视频文件的点播加速业务场景。
      </div>
      <div>
        ●全站加速：适用于含有大量动态和静态内容混合，且多为动态资源请求的加速场景。
      </div>
    </div>
  ),
  JSQY: (
    <div>
      <div>【加速区域】说明：</div>
      <div>●中国境内：选择该加速区域时，加速域名需为已备案域名。</div>
      <div>●中国境外：选择该加速区域时，对加速域名备案不做要求。</div>
      <div>●全球：选择该加速区域时，加速域名需为已备案域名。</div>
    </div>
  ),
  YZLX: (
    <div>
      <div>●源站IP</div>
      <div>（1）使用IP作为源站地址时，CDN节点回源时直接访问该IP地址。</div>
      <div>（2）输入的IP地址总数不能超过15个。</div>
      <div>（3）设置多个源站IP地址时，CDN节点在回源时采用轮询策略</div>
      <div>●源站域名</div>
      <div>（1）支持设置一个源站域名。</div>
      <div>（2）源站域名不能与加速域名相同。</div>
      <div>（3）您也可以将“源站域名”设置为对象存储桶域名。</div>
      <div>（4）不支持私有桶通过源站域名的形式接入CDN。</div>
    </div>
  ),
  YZXX: (
    <div>
      <div>
        源站是您的网站服务器，是CDN加速分发数据的来源。如果您需要修改源站信息的源站IP、源站域名或回源端口等，您可以通过源站配置页面修改源站信息。
      </div>
    </div>
  ),
  IPv6: (
    <div>
      <div>
        CDN支持客户端以IPv6协议访问节点，您可以通过开启IPv6开关完成配置，配置完成后，CDN也将携带IPv6的客户端IP信息访问您的源站。
      </div>
    </div>
  ),
  HYFS: (
    <div>
      <div>
        您可以通过配置回源方式来指定CDN回源时的请求协议。CDN默认回源方式为协议跟随。
      </div>
    </div>
  ),
  HYURLGX: (
    <div>
      <div>
        当回源请求URL与源站URL不匹配时，会导致回源失败。可以通过改写回源URL，提高回源请求URL与源站的匹配度，提升回源命中率。
      </div>
    </div>
  ),
  RANGEHY: (
    <div>
      <div>
        Range回源是指源站在收到CDN节点回源请求时，根据HTTP请求头中的Range信息返回指定范围的数据给CDN节点。
      </div>
    </div>
  ),
  HYGS: (
    <div>
      <div>
        1.如果您的源站地址因业务需求做了301/302
        重定向，CDN的回源请求会返回301/302状态码，当您开启回源跟随后，CDN节点会先跳转到301/302对应地址获取资源，缓存后再返回给用户。
      </div>
      <div>2.开启或未开启回源跟随CDN的处理如下：</div>
      <div>
        （1）未开启：CDN会将301/302对应跳转地址直接返回给用户，让用户自己去请求跳转地址的资源。如果该跳转地址域名未加入CDN，则该请求过程不会有加速效果。
      </div>
      <div>
        （2）已开启：CDN会先跳转到301/302对应地址获取用户所需资源后缓存至节点并返回给用户，当其他用户再次请求同样资源时会直接命中节点缓存。
      </div>
    </div>
  ),
  HYCSSJ: (
    <div>
      <div>
        当用户请求的内容在CDN节点没有缓存时，会回源站请求资源，CDN默认回源请求超时时间为30s，如果请求超时，表示回源请求失败。您可以根据源站的业务特性及网络状态调整回源请求超时时间，保障正常回源。
      </div>
    </div>
  ),
  HYQQT: (
    <div>
      <div>
        当用户请求的内容CDN节点无缓存时，CDN会回源请求资源。如果您想要改写用户回源请求URL的头部信息，可以在此进行配置。
      </div>
    </div>
  ),
  HTTPSPZ: (
    <div>
      <div>
        CDN支持HTTPS安全加速，您可以在此界面配置加速域名的HTTPS证书，启用HTTPS加速服务。
      </div>
    </div>
  ),
  TLSBBKZ: (
    <div>
      <div>
        通过开启或关闭不同版本的TLS协议，您的加速域名也将开启或关闭对相应TLS协议版本的支持。
      </div>
      <div>
        CDN默认开启TLS1.0/1.1/1.2/1.3，您可以根据业务需求开启/关闭TLS版本。
      </div>
    </div>
  ),
  HTTP2: (
    <div>
      <div>
        HTTP/2（HTTP2.0）是继HTTP1.1版本之后的新版HTTP协议，支持二进制分帧、多路复用、首部压缩等最新的特性，能够大幅度提高Web性能，降低数据交互延迟。
      </div>
    </div>
  ),
  OCSPStapling: (
    <div>
      <div>
        开启OCSP
        Stapling功能，CDN可以预先查询并缓存在线证书验证结果，在与浏览器进行TLS连接时返回给浏览器，浏览器无需再次前往CA站点查询，从而减少用户验证时间。
      </div>
    </div>
  ),
  HCGZ: (
    <div>
      <div>
        此功能用于配置CDN节点中某个或某些缓存资源的缓存过期时间规则。
        当CDN节点缓存的资源达到设置的缓存过期时间后，用户再次请求该资源时，CDN将直接回源站请求对应的最新资源返回给用户，并缓存到CDN节点。支持按照“首页”“所有文件”、“目录路径”、“文件名后缀”及“全路径”方式指定资源范围。
      </div>
    </div>
  ),
  URLCS: (
    <div>
      <div>
        CDN节点回源时，将按照用户请求URL及设置的URL参数进行不同策略的回源和资源缓存，您可以根据业务需求合理设置URL参数，提高缓存命中率，提升分发效率。
      </div>
    </div>
  ),
  HCZXYZ: (
    <div>
      <div>
        如果源站配置了缓存过期时间，即源配置了Cache-Control:max-age，您希望CDN的缓存过期时间与源站配置一致，可以选择开启“缓存遵循源站”功能，CDN将执行源站的缓存过期时间。
      </div>
    </div>
  ),
  ZNYS: (
    <div>
      <div>
        当您开启智能压缩功能时，CDN会自动压缩您的静态文件。智能压缩能够有效缩小传输文件的大小，提升传输效率，减少带宽消耗。
        智能压缩包含Gzip压缩和Brotli压缩，Brotli压缩的性能比Gzip压缩提升约15%~25%。
      </div>
    </div>
  ),
  ZTMHCSJ: (
    <div>
      <div>
        CDN节点回源站请求资源时，源站会返回响应的状态码，您可以在CDN控制台设置状态码的缓存时间，当客户端再次请求相同资源时，不会触发回源，减少回源概率，减轻源站压力。
      </div>
    </div>
  ),
  IPHBMD: (
    <div>
      <div>
        通过识别客户端IP来过滤用户请求，拦截特定IP的访问或者允许特定IP的访问，可以用来解决恶意IP盗刷、攻击等问题。
      </div>
    </div>
  ),
  RefererFDL: (
    <div>
      <div>
        Referer防盗链，是基于HTTP请求头中Referer字段（例如，Referer黑白名单）来设置访问控制规则，实现对访客的身份识别和过滤，防止网站资源被非法盗用。
      </div>
    </div>
  ),
  PEMBMSL: (
    <div>
      <div>
        -----BEGIN
        CERTIFICATE-----MIICBPCgAwGBAgIQD2/G2/WOWehzJNpYSBUTFMF1c3RiG9BADByMQsQQGEwJDTjElMCMGA1UEChMcVHJ1c3RBc2lhIFRlb22llFcywgMQswCQYtYWluIFZhbGGETjCMGA1UEChMcc3RBc2lhIFRvlcw0ywgSW5jLjEdMB1UECxMURlkYXRlZCBTU0wxHBgNVBAMTFFlbxvZRydXN0QXoXDTIyBMB4XDTIMBsGATIDAwMFoTTIzNTk1.....
      </div>
    </div>
  ),
  PEMKEYBMSL: (
    <div>
      <div>
        -----BEGIN PRIVATE
        KEY-----MIIEvgIBADAH6eBgkqhkiGSIIBAax84Rox6FsGs9mz3pUmXjEAWcO5dMdqMw8L0SmH6ehZZ96zNkI0ggTRzf0ZFoAq1a7gwgpUJjvTAoIBAv7iUXYmbxVXRlgRXgSkAgEAd0sGs9mz3pUWcO5dMdqMw8LoR/0SmHZZ96zNkI0ggTRzA5pJf07biHvgr2nJBFAfQDqj8QYp1M8xQObs4K.....
      </div>
    </div>
  ),
  HYHOST: (
    <div>
      <div>【回源HOST】说明：</div>
      <div>●自定义CDN节点回源时需要访问的站点域名。</div>
      <div>
        ●默认回源HOST为加速域名，如果实际访问的源站站点域名不是加速域名（例：对象存储桶以源站域名的形式接入），您需要将回源HOST修改为对应的站点域名。
      </div>
    </div>
  ),
  YZJY: (
    <div>
      <div>示例如下：</div>
      <div>
        下文以阿里云的云解析为例介绍如何添加TXT记录，在其他域名解析服务商（例如：腾讯云、新网等）的配置方法类似。
      </div>
      <div>1.登录云解析DNS控制台。</div>
      <div>
        2.在域名解析页面，找到加速域名的根域名example.com，并单击右侧的解析设置。
      </div>
      <div>3.单击添加记录，填写步骤1中的记录类型、主机记录和记录值。</div>
      <div>
        <img
          style={{ width: "100%" }}
          src="https://juncdt-market-public.oss-cn-hangzhou.aliyuncs.com/BACKEND_banner29291660026133386853bjcokl64u.jpeg"
          alt="sidemenu"
        />
      </div>
      <div>4.单击确认，完成添加。</div>
      <div>5.等待TXT解析生效，返回CDN控制台，单击点击验证，完成验证。</div>
      <div>
        如果系统提示“验证失败”，请检查TXT记录是否正确填写，并等待DNS记录生效后重新验证。
      </div>
    </div>
  ),
};
// RANGEHY: <div><div></div></div>,
export const OrignDatacolumns = [
  {
    title: "源站类型",
    dataIndex: "originType",
    render: (text: number) => <span>{["", "源站IP", "源站域名"][text]}</span>,
  },
  {
    title: "源站地址",
    dataIndex: "ipOrDomain",
  },
  {
    title: "回源端口",
    dataIndex: "portHttp",
    render: (
      text: any,
      record: { portHttp: any; portHttps: any },
      index: any
    ) =>
      `回源http端口：${record.portHttp} ; 回源https端口：${record.portHttps}`,
  },
  {
    title: "回源HOST",
    dataIndex: "backOriginHost",
  },
  {
    title: "源站优先级",
    dataIndex: "activeStandby",
    render: (text: any, record: any, index: any) => (text ? "主站" : "备战"),
  },
];

export const OrignURLRewrite = [
  {
    title: "匹配方式",
    dataIndex: "applicableScene",
  },
  {
    title: "待改写回源URL",
    dataIndex: "applicableScene",
  },
  {
    title: "目标回源URL",
    dataIndex: "applicableScene",
  },
  {
    title: "源站优先级",
    dataIndex: "applicableScene",
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
  },
];

export const OrignReqHeader: (operate: Function) => ProColumns<any>[] = (
  operate
) => {
  return [
    {
      title: "请求头操作",
      dataIndex: "action",
      render: (text: any) => (text === 'delete' ? '删除' : '设置')
    },
    {
      title: "请求头参数",
      dataIndex: "name",
    },
    {
      title: "请求头取值",
      dataIndex: "value",
    },
    {
      title: "操作",
      width: 120,
      dataIndex: "operation",
      render: (text: any, record: any, index: any) => (
        <div>
          <a
            onClick={(data) => operate("编辑", { ...record, index })}
            className="mr-2"
            key="编辑"
          >
            编辑
          </a>
          <a
            onClick={(data) => operate("删除", { ...record, index })}
            key="删除"
          >
            删除
          </a>
        </div>
      ),
    },
  ];
};

// TLS版本控制
export const TLSTable = [
  {
    title: "TLS版本",
    dataIndex: "edition",
  },
  {
    title: "状态",
    dataIndex: "state",
    render: (text: any) => (
      <span style={{ color: text === "1" ? "green" : "red" }}>
        {text === "1" ? "已开启" : "已关闭"}
      </span>
    ),
  },
];

// 缓存配置 表格头
export const cacheTable: (operate: Function) => ProColumns<any>[] = (
  operate
) => {
  return [
    {
      title: "类型",
      dataIndex: "type",
      render: (text: any ) => (['所有文件', '文件后缀', '目录匹配', '全路径匹配', '首页'][text])
    },
    {
      title: "内容",
      dataIndex: "content",
    },
    {
      title: "优先级",
      dataIndex: "priority",
    },
    {
      title: "缓存过期时间",
      dataIndex: "ttl",
      render: (text: any, record: any) => (text + ['',' 秒',' 分',' 小时',' 天'][record.ttlType])
    },
    {
      title: "操作",
      width: 120,
      dataIndex: "operation",
      render: (text: any, record: any, index: any) => (
        <div>
          <a onClick={(data) => operate("编辑", { ...record, index })} className="mr-2" key="编辑">编辑</a>
          {index !== 0 ? <a onClick={(data) => operate("删除", { ...record, index })} key="删除">删除</a> : null}
        </div>
      ),
    },
  ];
};


// 缓存配置 表格头
export const codeCacheTimeTable = [
  {
    title: "状态码",
    dataIndex: "applicableScene",
  },
  {
    title: "缓存时间",
    dataIndex: "applicableScene",
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
  },
];

// tls版本选项
export const tlsEditionList = [
  {
    value: "TLSv1.0",
    label: "1.0",
  },
  {
    value: "TLSv1.1",

    label: "1.1",
  },
  {
    value: "TLSv1.2",

    label: "1.2",
  },
  {
    value: "TLSv1.3",

    label: "1.3",
  },
];
