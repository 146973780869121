import React, { useEffect, useMemo, useState } from "react"
import HomeHeader from "../../Layout/HomeHeader"
import { Button, Card, Checkbox, Col, Modal, Row, Typography, message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import {
    UpOutlined,
    InfoCircleFilled ,
    DownOutlined
} from '@ant-design/icons';
import { homeAddToCart, homeGetProFrequency, homeGetCheckTickAgree, homeGetProPackages, homePostBuySubmit } from "@api/home";
import { parseSearch, productApi, toFixedNoRound } from "@utils/util";
import { NumberInputPro } from "../ShoppingCar/data";
import { PopUpArea, useAlertNotLogin } from "@components/Home/Layout";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetShoppingCarNumber } from "@store/actions";
import { PROTYPE_SMS } from "@utils/enum";
import { StateType } from "@store/index";
import Agreement from "@components/Protocol";
import { ExclamationCircleFilled } from '@ant-design/icons';
import './index.less'
import { useAnimate } from "@hooks/index";
import { handleDvmCommonListResult, handleDvmCommonResult } from "@utils/request";
import { getRecommandProducts } from "@api/index";
import { fileHttpUrl } from "@api/baseUrl";
import { stringify } from "qs";

// 接口请求的 api 层
const getApi = productApi(homeGetProPackages)  // 查询的接口封装
// const addToCart = productApi(homeAddToCart) // 加入购物车
const submitApi = productApi(homePostBuySubmit) // 提交

const NoticeDiv = ({piModelType}:any) => {
    const [open, setOpen] = useState(false)
    return (<div className="border-0 border-blue-400 leading-8 bg-blue-100 px-4 py-3 pt-1 mb-4" style={{height: open ? '170px' : '38px', overflow: 'hidden', transition: '0.5s'}}>
        <div className="ml"><InfoCircleFilled style={{color: '#0379FE'}} className="mr-2 infoIcon"/>购买须知： 1.新用户都可领取0元试用套餐包，供免费测试使用。
            {!open ? <span className="float-right  cursor-pointer" onClick={()=> setOpen(true)}><DownOutlined className="mt-2"/>展开</span>:
            <span className="float-right cursor-pointer" onClick={()=> setOpen(false)}><UpOutlined className="mt-2"/>展开</span>}
        </div>
        <div className="ml-24"> 2.套餐包购买成功后即刻生效，到期后失效，到期后未使用余量自动过期。</div>
        <div className="ml-24"> 3.套餐包消耗完，若开通余额扣费，系统将按照通用价格按量扣费。</div>
        <div className="ml-24"> 4.未使用的套餐包支持退订，已使用套餐包剩余资源不支持退订。</div>
        {piModelType === PROTYPE_SMS ? <div className="ml-24"> 5.禁止发送金融相关的所有内容（验证码、通知和营销短信），请客户谨慎购买。</div> : null }
    </div>)
}

const TypeItem = ({ data, setList, typeTitleName, active }: any) => {
    const [activeKey, setActiveKey] = useState(0)

    const onClick = (index: number, element: any) => {
        setActiveKey(index)
        setList(index)
    }
    useEffect(() => {
        setActiveKey(0)
    }, [data])
    useEffect(() => {
        setActiveKey(active)
    }, [active])
    
    return (<div className="flex mb-5 items-center">
        <div className="font-semibold text-sm mr-6 w-24">{typeTitleName} : </div>
        <div className="flex flex-wrap" style={{ width: 'calc(100% - 150px)' }}>
            {(data && data.list || []).map((element: any, index: number) => {
                return <div onClick={() => onClick(index, element)} key={index} className={`border m-1 duration-700  mr-3 w-40 h-10 text-center cursor-pointer ${activeKey === index ? 'text-blue-600 border-blue-600' : ''}`}>{element.name}</div>
            })}
        </div>
    </div>)
}

// 产品选购列表
const ProPackage = ({ change, proTypeList }: any) => {
    const [dataList, setDataList] = useState({
        packageType: 0,
        packageSpecifications: 0,
        effectiveDuration: 0
    })
    const [number, setNumber] = useState(1 as number)
    const [unitPrice, setUnitPrice] = useState(0 as number)
    const { search } = useLocation()
    const { ppgModel1st,ppgModelAmountName,ppgModelLenName } = parseSearch(search)
    
    const setEchoData = (list:any, arr:any)=> {
        const arrData = [...arr]
       if(arrData.length === 0){
        list.forEach((item:any, index:number) => {
            if(ppgModel1st === item.name) {
                setEchoData(item.list, [...arrData, index]) 
            }
        });
       } else if(arrData.length === 1){
            list.forEach((item:any, index:number) => {
                if(ppgModelAmountName === item.name) {
                    setEchoData(item.list, [...arrData, index]) 
                }
            });
       } else if(arrData.length === 2){
            list.forEach((item:any, index:number) => {
                if(ppgModelLenName === item.name) {
                    setEchoData(item.list, [...arrData, index]) 
                }
            });
       } else if(arrData.length === 3){
        console.log(33222, arr)
        setDataList({ packageType: arr[0], packageSpecifications: arr[1], effectiveDuration: arr[2] })
       }    
    }

    // 有可能从产品推荐也进来的，一进来就处理下格式回显产品具体套餐包
    useEffect(() => {
        // setDataList({ packageType: 1, packageSpecifications: 0,  effectiveDuration: 0 })
        console.log(proTypeList)
        proTypeList && setEchoData(proTypeList.list, [])
    }, [proTypeList])


    useEffect(() => {
        onclick()
    }, [number, proTypeList, dataList])


    const onclick = async (type: string = '', index: number = 0) => {
       if (type === 'packageType') {
            setDataList({ packageSpecifications: 0, packageType: index, effectiveDuration: 0 })
        } else if (type === 'packageSpecifications') {
            setDataList({ ...dataList, packageSpecifications: index, effectiveDuration: 0 })
        } else if (type === 'effectiveDuration') {
            setDataList({ ...dataList, effectiveDuration: index })
        }
        const { ppgSourceTotalPrice = 0, ppgDiscount = '', ppgId, ppgName, ppgSaleTotalPrice } = proTypeList.list ? proTypeList.list[dataList.packageType].list[dataList.packageSpecifications].list[dataList.effectiveDuration].list[0] : []
        // 选定套餐之后向父类提交变化事件
        setUnitPrice(ppgSaleTotalPrice)
        ppgSaleTotalPrice === 0 && setNumber(1)
        change({ ppgSourceTotalPrice, count: number, ppgName, ppgId, ppgDiscount, ppgSaleTotalPrice })
    }
    return (<div className="bg-white p-8 leading-10">
        {
            proTypeList.list ?
                <>
                    <TypeItem active={dataList.packageType} typeTitleName='套餐类型' data={proTypeList} setList={(index: number) => onclick('packageType', index)} />
                    <TypeItem active={dataList.packageSpecifications} typeTitleName='套餐规格' data={proTypeList.list[dataList.packageType]} setList={(index: number) => onclick('packageSpecifications', index)} />
                    <TypeItem active={dataList.effectiveDuration} typeTitleName='有效时长' data={proTypeList.list[dataList.packageType].list[dataList.packageSpecifications]} setList={(index: number) => onclick('effectiveDuration', index)} />
                    <div className="flex"><span style={{marginRight: '60px'}} className="font-semibold text-sm">购买数量 :</span><NumberInputPro disabled={unitPrice === 0} min={1} max={999} onChange={(e: number) => setNumber(Number(e))} value={number} /> </div>
                </> : null}
    </div>)
}

// 购买或者加入购物车的时候判断是否已经购买过o元试用包了
export const handle_buy = (data:any, callBack:any) => {
    const { confirm } = Modal;
    const {state , package_name:packageName, order_code:oiCode} = data || {}
    if (state == '0') {
        confirm({
            title: '提示',
            icon: <ExclamationCircleFilled />,
            content: <div>您有一笔待确认的0元套餐包订单，<a href={`/cost/myorder?oiCode=${oiCode}`}>前往确认</a></div>,
            onOk() {},
        });
    } else if(state == '2') {
        confirm({
            title: '提示',
            icon: <ExclamationCircleFilled />,
            content: <div>每个用户仅限领取一次，您已领取0元套餐包，<a href={`/cost/pkg?packageName=${packageName}`}>前往查看</a></div>,
            onOk() {},
        });
    } else {
        callBack()
    }
}

// 结算台
const Settlement = ({ settlementData, proTypeList, piId }: any) => {
    const history = useHistory()
    const [className, onClick] = useAnimate() as any
    const dispatch = useDispatch();
    const [readState, setReadState] = useState(false)
    const { userInfo } = useSelector((state: StateType) => state) as any;
    const { baseInfo = [] } = userInfo || []
    const { verifyState } = baseInfo || {}
    const { ppgSourceTotalPrice, count, ppgDiscount, ppgId, ppgName, ppgSaleTotalPrice } = settlementData || {}
    const onCharge = useAlertNotLogin(baseInfo)

    const getProFrequency = async () => {
        const { data } = await homeGetCheckTickAgree({ piId }) as any
        setReadState(!!data)
    }

    useEffect(() => {
        getProFrequency()
    }, [])

    

    const submit = async (type: string) => {
        if (!readState) {
            message.warning('请阅读并勾选协议');
            onClick()
            return
        }
        if (type === 'buy') {
            const { data } = await submitApi({
                ppgId,
                odPackageAmount: count,
            },false, (data:any) => {
                handle_buy(data, ()=> {
                    history.push(`/shopping/orderSubmit?oiId=${data.oiId}`)
                })
            })
        } else if (type === 'add') {
            const { result, success } = await homeAddToCart({
                ocaAmount: count,
                ppgName,
                totalPrice: ppgSaleTotalPrice,
                ppgId
            }) as any
           
            const tempTypeOptions = handleDvmCommonResult(result)
            console.log(tempTypeOptions)
            // , true, (data:any) => { 
            //     console.log(22223,data)
            handle_buy(tempTypeOptions, ()=> {
                message.success('加入购物车成功');
                 dispatch(asyncGetShoppingCarNumber())
            })
            // })
           
        }
    }
    // ppgSourceTotalPrice, ppgDiscount, ppgSaleTotalPrice
    return (<div className="bg-white fixed items-center bottom-0 flex w-full justify-between p-4  j-shopping-purchase ">
        <div className={className}><Checkbox className="float-left test" checked={readState} onChange={(e) => setReadState(e.target.checked )}>我已阅读并同意<Agreement type={proTypeList.piModelType} /></Checkbox></div>
        <div className="flex justify-end">
            <div className="mr-2 mt-4">总费用：</div>
            <div>
                <div className="text-red-500 text-4xl font-semibold mr-4">￥{toFixedNoRound(ppgSaleTotalPrice * count || 0, 2)}</div>
                {(ppgDiscount !== 100 && ppgDiscount !== 0) ? <div>
                  <span className="border-red-500 w-16 mt-2 border rounded text-xs text-center text-red-400 bg-red-50" style={{padding: '0 5px'}}>{ppgDiscount / 10 }折优惠</span>
                </div> : null}
                {ppgSaleTotalPrice === 0 ? <div className="border-blue-500 w-16 mt-2 border rounded text-xs text-center text-blue-400 bg-blue-50">
                    0元试用
                </div> : null}
            </div>
            {[100, 0].includes(ppgDiscount) ? null : <div className="line-through text-gray-400 mr-4 mt-4">原价：{ppgSourceTotalPrice}元</div>}
            <Button type="primary" className="mr-4 jc-theme-button buyButton" onClick={() => onCharge(() => submit('buy'))}>立即购买</Button>
            <Button danger onClick={() => onCharge(() => submit('add'))} className="shoppingCarButton">加入购物车</Button>
        </div>
    </div>)
}

const RecommendCard = ({product}: any) => {
    const history = useHistory()
    const onClick = () => history.push(`/home/product?${stringify({piId: product?.piId})}`)
    return (<Card className="hover:text-red-500" style={{ cursor: 'pointer' }} hoverable onClick={onClick}>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <img style={{width: 37, height: 37, marginRight: 10}} src={fileHttpUrl(product?.piLogo)} alt="" />
            <div className="text-base font-semibold" style={{margin: '10px 0', display: 'inline-block'}}>{product?.piName}</div>
        </div>
        <Typography.Paragraph ellipsis={{rows: 2}} style={{color: '#7b7b7b', fontSize: '.9rem'}}>{product?.piDescribe} </Typography.Paragraph>
    </Card>)
}

// 推荐产品
const Recommend = ({recomand}: any) => {
    return (<>
        <div style={{fontWeight: 700, margin: '10px', fontSize: 20}}>推荐搭配</div>
        <Row gutter={16} style={{marginBottom: 50}}>
            { recomand?.map((v: any, i: number) => <Col key={i} span={6}><RecommendCard product={v} /></Col>) }
        </Row>
    </>
    )
}

export default () => {
    const [proTypeList, setProTypeList] = useState('' as any)
    const [recomand, setRecommand] = useState([])
    const [settlementData, setSettlementData] = useState({ ppgSourceTotalPrice: 0, ppgSaleTotalPrice: 0, count: 1 })
    const [readState, setReadState] = useState(false)
    const { search } = useLocation()
    const { piId } = parseSearch(search)

    const getData = async () => {
        const { data } = await getApi({ piId }, false)
        setProTypeList(data)
    }

    const getRecommandProductsApi = async (params: any) => {
        const { result } = await getRecommandProducts(params) as any
        const res = handleDvmCommonListResult(result, 'reference_product')
        setRecommand(res)
    }

    useEffect(() => {
        getData()
        getRecommandProductsApi({piId})
    }, [])
    const MemoSettlement = useMemo(() => <Settlement piId={piId} proTypeList={proTypeList} settlementData={settlementData}/>, [settlementData, readState])
    return (<div className="h-screen overflow-hidden">
        <HomeHeader showMenu={false} />
        <div className="px-28 mt-2 py-3 border shadow text-xl font-semibold" style={{ paddingTop: '64px', paddingLeft: 24 }}>购买{proTypeList.proName}</div>
        <div className="bg-gray-100 h-full" style={{ height: 'calc(100% - 182px)', padding: 24, overflow: 'auto' }}>
            {/* 提示的div */}
            <NoticeDiv piModelType={proTypeList.piModelType}/>
            {/* 产品套餐分类 */}
            <ProPackage proTypeList={proTypeList} change={(data: object) => setSettlementData( {...settlementData, ...data })}/>
            { recomand?.length ? <Recommend recomand={recomand} /> : null }
        </div>
        
        {/* 购物车悬浮块 */}
        <PopUpArea />

        {/* 结算组件 */}
        {MemoSettlement}
    </div>)
}