import React, { useEffect, useRef } from 'react';

import "./index.less";
import bg from "@assets/images/activities/Activities_61/bg.png";
import jx from "@assets/images/activities/Activities_61/jx.png";
import bz11 from "@assets/images/activities/Activities_61/bz11.png";
import activiesBg from "@assets/images/activities/Activities_61/activiesBg.png";
import bz7 from "@assets/images/activities/Activities_61/bz7.png";
import wt from "@assets/images/activities/Activities_61/wt.png";
import top from "@assets/images/activities/Activities_61/top.png";

import { useSelector } from "react-redux";
import { StateType } from "@store/index";

import TweenOne from "rc-tween-one";
import "rc-texty/assets/index.css";
import QueueAnim from "rc-queue-anim";
import Texty from "rc-texty";
import { fileHttpUrl } from "@api/baseUrl";
import { Animation } from '@components/index'
import { GoRegister } from '@components/Home/PageIndex';
const TweenOneCpn = TweenOne as any;

// 活动页面之一：  云极年中活动   61活动 

const TopDiv = () => {
  return (
    <div
      className="activities_topDiv2"
      style={{
        background: `url(${fileHttpUrl(
          "test/image/BAB25182D76C4307A3ECD6F5755985B0.png"
        )}) center center`,
      }}
    >
      <div className="topText">
        <TweenOneCpn style={{ height: 96 }} playscale={[0.1, 0.2]}>
          <QueueAnim
            key="23446543"
            leaveReverse
            delay={300}
            type="scale"
            forcedReplay={true}
            appear={true}
          >
             <h2 key="10"   className="title">
              <Texty>{`您有一份"年中权益"`}</Texty>
            </h2>
            <h2 key="11"   className="title">
              <Texty>待领取...</Texty>
            </h2>
            <h2 key="22">
              续费、新购“聚”划算
            </h2>
          </QueueAnim>
        </TweenOneCpn>
      </div>
      <img src={top} alt="" className="b_img" />
    </div>
  );
};

const WeChartImg = () => {

  return (<div className="WeChartImgDiv_activities">
      <div className="top">
          <div className="title">扫码领取</div>
      </div>
      <div className="QRcode">
          <div className="imgBg">
              <img src={wt} alt="" />
          </div>
      </div>
  </div>)
}

const One = () => {
  const ref = useRef(null) as any
 
  const voucherList = [
    {
      number: 900,
      text: '充值金额满50000赠送'
    },
    {
      number: 700,
      text: '充值金额满40000赠送'
    },
    {
      number: 500,
      text: '充值金额满30000赠送'
    },
    {
      number: 300,
      text: '充值金额满20000赠送'
    },
    {
      number: 100,
      text: '充值金额满10000赠送'
    }
  ]
  return (
    <div className='oneDiv'>
       <div className='div_title'>
          权益一（专享代金券）
       </div>
       <div className='xin_feng'>
          <div className='left' >
            <img src={jx} className='leftImg' alt="" />
            <div className='content'>
              <div className='line_div'>
                  <div className='item'>续费专属补贴</div>
                  <div className='item'>老客户专属</div>
              </div>
              <div className='line_div'>
                 <span className='red_price'>900</span>
                 <div className='price_text'>元封顶</div>
              </div>
              <div style={{marginTop: '10px'}}>
                 充值成功即可领取云朵（代金券），云朵可以在云极平台购买和消费任意api产品。
              </div>
              <div className="red_submit_Btn" style={{marginTop: '10px'}}>
                立即领取
                <WeChartImg />
              </div>
            </div>
            <div className='black_div'></div>
          </div>
          <Animation type="right" time={1.5}>
            <div key="9898989" className={`right`}>
              <div className='content'>
                {
                  voucherList.map((element, idx) => {
                    return <div className='voucher_item' key={idx}>
                      <div className='right_top'>代金券</div>
                      <div style={{color: '#C5390E', fontSize: '20px'}}>￥<span >{element.number}</span></div>
                      <div style={{color: '#C5390E'}}>{element.text}</div>
                    </div>
                  })
                }
              </div>
            </div>
          </Animation>
       </div>
       <div className='bz_img'>
           <img src={bz11}  alt="" />
       </div>
    </div>
  )
}

const Two = () => {
  const list = [
    {
      number: 2000,
      oneNumber: 0.30,
      discount: 9.0
    },
    {
      number: 3000,
      oneNumber: 0.29,
      discount: 8.5
    },
    {
      number: 5000,
      oneNumber: 0.24,
      discount: 7.0
    },
    {
      number: 10000,
      oneNumber: 0.23,
      discount: 6.8
    }
  ]
  return (
    <div className='Two'>
       <div className='div_title'>权益二（手机号三要素专享折扣）</div>
       <div>老客户享受手机号三要素专享折扣，一次性充值越多折扣越多。折扣价享受截止至9月30日（按量扣费享受折扣价）。</div>
       <div className='content'>
            {
              list.map((element, idx) => {
                 return <div className='item' key={idx}>
                    <div className='white_bg'>
                        <div>充值满</div>
                        <div><span style={{fontSize: '44px'}}>{element.number}</span><span>元</span></div>
                    </div>
                    <Animation type="left">
                    <div key="88888889" className='right'>
                        <div>享受优惠</div>
                        <div>
                          <span style={{fontSize: '40px'}}>{element.discount}</span><span className='mr-6'>折</span>
                          <span>折后单价￥</span><span style={{fontSize: '40px'}}>{element.oneNumber}</span>
                        </div>
                    </div>
                    </Animation>
                 </div>
              })
            }
       </div>
    </div>
  )
}

const Three = () => {
  return (
    <div className='Three'>
       <div className='div_title'>
       权益三（新用户专享）
       </div>
       <div  className='content' style={{ background: `url(${activiesBg}) center right  no-repeat`,}}>
           新客户免费试用平台产品，测试满意后充值即可<span>获得100云朵</span>（无充值门槛）
       </div>
    </div>
  )
}

const Four = () => {
  return (
    <div className='Four'>
       <div className='div_title'>
       权益四（现金红包 ）
       </div>
       <div  className='content'>
       老客户推荐新用户奖励 ：<span>50元现金红包</span>
       <img src={bz7} alt="" />
       </div>
      
    </div>
  )
}


const BottomTextDiv = () => {
  const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
  return (
    <div className='bottomTextDiv'>
      <div className="content">
          <div className="flex mt-2">
              活动说明：
              <div >
                  1、年中福利活动截止至：6月30日<br />
                  2、云朵是云极平台虚拟币，可消费平台任意产品，优先抵扣云朵。云朵有效期90天。<br />
                  3、新用户，是指首次在云极充值并消费且有调用记录的用户。老用户成功邀请新用户可联系在线客服领取现金红包，推荐的新用户也可获得20元云朵。<br />
                  4、满足充值送云朵的门槛，系统会在充值后1个工作日内将云朵赠送到用户账户。<br />
                  5、本活动不支持退款。<br />
                  6、如有疑问，请咨询客服。<br /><br />
                  
                  活动最终解释权在法律允许范围内归{ueSaasName}所有。<br />
              </div>
          </div>
          <br />
      </div>
    </div>
  );
};

export default () => {
  const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
  
  return (
    <div className="j-home-activities">
      <TopDiv />
      <One />
      <Two />
      <Three />
      <Four />
      <BottomTextDiv />
      <GoRegister />
    </div>
  );
};
