import { ProColumns } from "@ant-design/pro-table";
import { fileHttpUrl } from "@api/baseUrl";
import { DateRangeWithDisabled } from "@components/Common";
import { Input, Select } from "antd";
import React, { ReactNode } from "react";

const options = [{label: 'options1', value: 1}, {label: 'options2', value: 2}]


const valueEnum = {
    // 0: { text: '全部', status: 'Default' },
    1: { text: '一致', status: 'Sueecss' },
    2: { text: '不一致', status: 'Error' },
    3: { text: '其他错误', status: 'Processing' },
}


const valueEnumCharge = {
    0: { text: '不收费', status: 'Default' },
    1: { text: '收费', status: 'Sueecss' },
}

/**
 * 列表表格配置:
 *  自定义表格 CustomerDemo 和 antd 升级版组件 ProTableDemo 都可使用, 
 * */
export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        //刘冲说  不要这个字段
        // {
        //     title: '用户姓名',
        //     dataIndex: 'userName',
        // },
        {
            title: '人像比对分数',
            dataIndex: 'faceScore',
            hideInSearch: true,
            width: 200
        },
        // 刘冲说 这个不要了，2022.12.22
        // {
        //     title: '验证结果',
        //     dataIndex: 'checkResult',
        //     valueEnum,
        //     // hideInSearch: true
        // },
       
        {
            title: '备注',
            dataIndex: 'remark',
            hideInSearch: true
        },
        {
            title: '调用时间',
            dataIndex: 'sendTime',
            // valueType: 'dateTimeRange',
            valueType: 'dateRange',
            fieldProps: { },
            width: 200,
            search: {
                transform: (value) => {
                    return {
                        startTime: value[0] ? value[0] + ' 00:00:00' : undefined,
                        endTime: value[1] ? value[1] + ' 23:59:59' : undefined
                    }
                },
            },
            renderFormItem: (props) => <DateRangeWithDisabled />,
            render: (text: any, record: any) => record.sendTime,
        },
        {
            title: '是否计费',
            dataIndex: 'feeStr',
            valueType: 'select', 
            valueEnum: valueEnumCharge,
            width: 100,
            fieldProps: {
                allowClear: false
            },
            formItemProps: {
                // initialValue: '1',  
            },
            search: {
                transform: (value) => {
                    return {fee: value}
                },
            },
        },
    ]
}
