import { ProColumns } from "@ant-design/pro-table"
import { fileHttpUrl } from "@api/baseUrl"
import React from "react"

export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '检测手机号',
            dataIndex: 'phone',
            fieldProps: {
                placeholder: '请输入检测手机号'
            }
            // hideInSearch: true
        },
        {
            title: '状态描述',
            dataIndex: 'remark',
            hideInSearch: true
        },
        {
            title: '调用时间',
            dataIndex: 'callTime',
            hideInSearch: true
        },
        // {
        //     title: '操作',
        //     dataIndex: 'operate',
        //     key: 'operate',
        //     valueType: 'option',
        //     fixed: 'right',
        //     render: (text: any, record: any) => [
        //         // <a key="下载" style={{marginRight: 8}} download href={fileHttpUrl(record.url)}>下载</a>,
        //         [2].includes(record.status) ? <a key="下载" style={{marginRight: 8}} onClick={() => operate('下载', record)}>下载</a> : null,
        //         <a key="删除" onClick={() => operate('删除', record)}>删除</a>,
        //     ]
        // }
    ]
}