import { BASEURL } from "@api/index"
import { useCommonApiHooks } from "@hooks/index"
import { Tabs } from "antd"
import React, { createContext, useContext, useRef, useState } from "react"
import { Refresh, Preheat, OperationRecord } from './cpnts'

const Items = () => {
    const [data, , getApi] = useCommonApiHooks({url: `${BASEURL}/web/cdn/getUrlCounts`,}, {}, true)
    return [
        {
            label: '缓存刷新',
            key: '1',
            children: <Refresh restNum={data} />
        },
        {
            label: '缓存预热',
            key: '2',
            children: <Preheat restNum={data} />
        },
        {
            label: '操作记录',
            key: '3',
            children: <OperationRecord />
        }
    ]
}

export default () => {
    return (<Tabs 
        defaultActiveKey="1" 
        items={Items()}
        style={{background: '#fff', minHeight: '80vh'}}
        tabBarStyle={{padding: '0 20px', margin: 0}} 
    />)
}
