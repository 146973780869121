import { Card, Radio, Spin } from "antd"
import React, { useEffect, useState } from "react"
import { Line } from '@ant-design/plots';
import { columnsFn, mockData } from "./data";
import ProTable, { ActionType } from "@ant-design/pro-table"
import { ExportButton, SearchBox } from "@components/index";
import { handleSubmit, originForm, searchConfig } from "../Search/data";
import { Actions, CommonCardLineChart } from "../UseStatistic";
import { BASEURL, cdnAnalysisResult, cdnGraph } from "@api/index";
import { toFixedNoRound } from "@utils/util";

const options = [
    {
        label: '回源流量',
        value: 4,
    },
    {
        label: '回源带宽',
        value: 5,
    },
]

// const ReturnSourceTable = () => {
//     const getListApi = async() => []
//     return (<ProTable<any>
//         className="j-antd-protable-nopadding"
//         columns={columnsFn(() => null)}
//         params={{}} // 查询所需要的额外参数
//         request={getListApi}
//         options={{ reload: false, density: false, setting: false }}
//         toolbar={{
//             actions: [],
//         }}
//         rowKey="tiId"
//         search={false}
//         pagination={{ defaultPageSize: 10 }}
//     />)
// }

export default ()=> {
    const [queryParam, setQueryParam] = useState(originForm())
    const [radioValue, setRadioValue] = useState(4)
    const [chartData, setChartData] = useState(mockData)
    const [chartData2, setChartData2] = useState(mockData)
    const [loading, setLoading] = useState<boolean>(false)
    const onSearch = async (params: any) => {
        setLoading(true)
        setQueryParam(params)
        const requestParams = {...handleSubmit(params), graphType: radioValue}
        const { data: chartData } = await cdnGraph(requestParams) as any
        const { data: chartData2 } = await cdnGraph({...requestParams, graphType: 6}) as any
        setChartData(chartData)
        setChartData2(chartData2)
        setLoading(false)
    }
    const onChange = async (type: any, val: any) => {
        setLoading(true)
        if (type === '图表') {
            setRadioValue(val)
            const requestParams = {...handleSubmit(queryParam), graphType: val, type: 1}
            console.log('接口请求数据:', requestParams)
            const { data: chartData } = await cdnGraph(requestParams) as any
            setChartData(chartData)
        } else if (type === '导出') {

        } else if (type === '刷新') {
            const requestParams = {...handleSubmit(queryParam), graphType: radioValue, type: 1}
            const { data: chartData } = await cdnGraph(requestParams) as any
            const { data: chartData2 } = await cdnGraph({...requestParams, graphType: 6}) as any
            setChartData(chartData)
            setChartData2(chartData2)
        }
        setLoading(false)
    }


  const onValuesChange = (values: any, allValues: any) => {
    if ('time' in values) {
        onSearch(allValues)
    }
   }

    const calcYTitle = () => radioValue === 4 ? `总流量: ${toFixedNoRound(chartData.reduce((prev, next) => prev + next.num, 0), 2)}(mb)` : `宽带峰值: ${Math.max(...chartData.map(v => v.num))}(kbit/s)`

    useEffect(() => {
        onSearch({})
    }, [])

    const getQueryParams = () => {
        const requestParams = {...handleSubmit(queryParam), graphType: radioValue, type: 2}
        return requestParams
    }
    const [domain, ...rest] = searchConfig.filter(v => !['areaRange', 'provider'].includes(v.name))
    return (<>
      <SearchBox config={[{...domain, colProps: {span: 24}, style: {marginBottom: 8, width: '33.33%'}}, ...rest]} onValuesChange={onValuesChange} onFinish={onSearch} />
      <Actions onClick={onChange}>
        <ExportButton key="export" type='primary' action={`${BASEURL}/web/cdn/analysis/export`} param={getQueryParams} btnText="导出" />
      </Actions>
      <div style={{padding: 20}}>
        <Spin spinning={loading}>
        <CommonCardLineChart 
           title="回源流量/带宽" 
           yTitle={calcYTitle()}
           options={options} 
           value={chartData} 
           onChange={(ev) => onChange && onChange('图表', ev.target?.value)} 
           lineConfig={{
                meta: {
                    num: { alias: radioValue === 4 ? '回源流量' : '回源宽带' },
                }
           }}
        />
        <CommonCardLineChart 
           title="回源失败率" 
           yTitle="命中率(%)"  
           value={chartData2}
           style={{marginTop: 20}}
           lineConfig={{
                meta: {
                    num: { alias: '回源失败率' },
                }
           }}
        />
        <div style={{margin: '20px 0'}}>域名回源详情</div>
        <ProTable<any>
            className="j-antd-protable-nopadding"
            columns={columnsFn(() => null)}
            params={{...handleSubmit(queryParam), type: 2}} // 查询所需要的额外参数
            request={async(params: any, sorter: any, filter: any) => {
                const { data } = await cdnAnalysisResult(params) as any
                const { domainBacktrackItems } = data
                return {
                    data: domainBacktrackItems || [],
                    success: true,
                    total: domainBacktrackItems.length,
                };
            }}
            options={{ reload: false, density: false, setting: false }}
            toolbar={{
                actions: [],
            }}
            search={false}
            pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
        />
        </Spin>
      </div>
    </>)
}