import { fileHttpUrl } from "@api/baseUrl"
import { ProductType, PROTYPE_SMS, PROTYPE_GLOBALSMS, PROTYPE_CDN, PROTYPE_SAFE, PROTYPE_PART, PROTYPE_PHONECHECK, PROTYPE_ID, PROTYPE_CARDADDRESS, PROTYPE_PARTISP } from "@utils/enum"
import React from "react"

const 卓见云CDN服务协议 = 'https://cloud-parent.oss-cn-hangzhou.aliyuncs.com/doc/xieyi/%E5%8D%93%E8%A7%81%E4%BA%91CDN%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf'
const 云极市场服务条款注册使用 = 'https://cloud-parent.oss-cn-hangzhou.aliyuncs.com/doc/xieyi/%E4%BA%91%E6%9E%81%E5%B8%82%E5%9C%BA%E6%9C%8D%E5%8A%A1%E6%9D%A1%E6%AC%BE.pdf'
const 云罟主机安全服务协议 = 'https://cloud-parent.oss-cn-hangzhou.aliyuncs.com/doc/xieyi/%E4%BA%91%E7%BD%9F%E4%B8%BB%E6%9C%BA%E5%AE%89%E5%85%A8%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf'
const 卓见云身份认证服务协议 = 'https://cloud-parent.oss-cn-hangzhou.aliyuncs.com/doc/xieyi/%E5%8D%93%E8%A7%81%E4%BA%91%E8%BA%AB%E4%BB%BD%E8%AE%A4%E8%AF%81%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf'
const 通讯能力技术服务协议号码检测 = 'https://cloud-parent.oss-cn-hangzhou.aliyuncs.com/doc/xieyi/%E9%80%9A%E4%BF%A1%E8%83%BD%E5%8A%9B%E6%8A%80%E6%9C%AF%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE%EF%BC%88%E5%8F%B7%E7%A0%81%E6%A3%80%E6%B5%8B%EF%BC%89.pdf'
const 通讯能力技术服务协议短信服务 = 'https://cloud-parent.oss-cn-hangzhou.aliyuncs.com/doc/xieyi/%E9%80%9A%E4%BF%A1%E8%83%BD%E5%8A%9B%E6%8A%80%E6%9C%AF%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE%EF%BC%88%E7%9F%AD%E4%BF%A1%E6%9C%8D%E5%8A%A1%EF%BC%89.pdf'
const 卓见云实人认证服务协议 = 'https://cloud-parent.oss-cn-hangzhou.aliyuncs.com/doc/xieyi/%E5%8D%93%E8%A7%81%E4%BA%91%E5%AE%9E%E4%BA%BA%E8%AE%A4%E8%AF%81%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf'

const hrefMap: any = {
    [PROTYPE_SMS]: { //国内短信 1
        name: '《通信能力技术服务协议》',
        href: 通讯能力技术服务协议短信服务 
    },
    [PROTYPE_GLOBALSMS]: { //国际短信 2 
        name: '《通信能力技术服务协议》',
        href: 通讯能力技术服务协议短信服务
    },
    [PROTYPE_CDN]: { // cdn 3
        name: '《卓见云CDN服务协议》',
        href: 卓见云CDN服务协议
    },
    [PROTYPE_SAFE]: { // 安全产品 4
        name: '《云罟主机安全服务协议》',
        href: 云罟主机安全服务协议
    },
    [PROTYPE_PART]: { // 要素类 5
        name: '《云极市场服务协议》',
        href: 卓见云身份认证服务协议
    },
    [PROTYPE_PARTISP]: { //运营商要素类 9
        name: '《云极市场服务协议》',
        href: 卓见云身份认证服务协议
    },
    [PROTYPE_PHONECHECK]: { // 号码检测
        name: '《通信能力技术服务协议》',
        href: 通讯能力技术服务协议号码检测
    },
    [PROTYPE_ID]: { // 实人认证 7
        name: '《卓见云实人认证服务协议》',
        href: 卓见云实人认证服务协议
    },
    [PROTYPE_CARDADDRESS]: { // 银行卡归属地 6
        name: '《云极市场服务协议》',
        href: 卓见云身份认证服务协议
    },
    COMMON: { // 官网协议
        name: '《云极市场服务协议》',
        href: 云极市场服务条款注册使用
    }
}
// 返回协议预览标签
export const Agreement = ({ type }: { type?: ProductType | string }) => {
    const protocolObj = hrefMap[type as string] || hrefMap['COMMON']
    return <a target='_blank' href={fileHttpUrl(protocolObj?.href)} rel="noreferrer">{protocolObj.name}</a>
}

export default Agreement