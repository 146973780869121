export const SET_HISTORY_LIST = 'SET_HISTORY_LIST' // 设置浏览记录
export const SET_USER_INFO = 'SET_USER_INFO' // 用户信息
export const DOMAIN_INFO = 'DOMAIN_INFO' // 网站个性化信息
export const SET_AREA_TREE = 'SET_AREA_TREE' // 设置省市区数据
export const SET_SMS_SETTING = 'SET_SMS_SETTING' // 国内短信设置
export const SET_PRODUCT_DATA = 'SET_PRODUCT_DATA' // 产品树
export const SET_ALL_COUNTRY = 'SET_ALL_COUNTRY' // 所有国家
export const SET_SHOPPINGCAR_NUMBER = 'SET_SHOPPINGCAR_NUMBER' // 购物车总数量
export const SET_TASK = 'SET_TASK' // 设置异步任务
export const ADD_TASK = 'ADD_TASK' // 添加一个异步任务
export const DEL_TASK = 'DEL_TASK' // 删除一个异步任务
export const SET_PREVIEW = 'SET_PREVIEW' // 设置预览图片
export const SET_DOC_TREE = 'SET_DOC_TREE' // 设置文档树
export const SET_MSG_INFO = 'SET_MSG_INFO' // 设置信息数据