import React from 'react';
import { fileExportUrl } from "@api/baseUrl"
import { Modal } from "antd"
import { useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { columnsFn } from "./data"
import ProTable, { ActionType } from "@ant-design/pro-table"
import { cdnGetRefreshPreHeatList } from '@api/product';
import { productApi } from '@utils/util';
import { PROCODE_BANKCARDADDRESS } from '@utils/enum';
import AlertTip from '@components/Common/AlertTip';

const listTips: any = [
    '可查询15天内提交的的缓存刷新和缓存预热任务。'
]
// 接口请求的 api 层
const getApi = productApi(cdnGetRefreshPreHeatList)  // 查询的接口封装

// 在线发送
export default () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [modalProps, setModalProps] = useState({ visible: false, title: '新增', params: {} })
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    // operate 函数为增删改查需要处理的业务
    const operate = async (type: any, record: any) => {

    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({ ...params }, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }
    // const getListApi = handleDvmCommonPageListResult(getApi)

    const columns = columnsFn(operate) // 表格列配置

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        console.log('处理后的成功回调触发了')
        reFresh()
    }
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const calcDelDisabled = () => selectedRowKeys.length
    return (<>
    <div style={{padding: 20, paddingBottom: 0}}>
       <AlertTip type="warning" listTips={listTips} />
    </div>
    <ProTable<any>
        style={{ flex: 1, overflow: 'scroll' }}
        // scroll={{ x: 1300 }}
        columns={columns}
        params={{}} // 查询所需要的额外参数
        request={getListApi}
        options={{ reload: false, density: false, setting: false }}
        toolbar={{
            actions: [
            ],
        }}
        rowKey="tiId"
        pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
        actionRef={ref as any}
        search={{
            optionRender: (searchConfig, formProps, dom) => [...dom.reverse()]
        }}
    />
    </>)
}

