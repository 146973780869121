import request from "@utils/request";
import { stringify } from "qs";
import { BASEURL } from "./baseUrl";

export const headers = {'Content-Type': 'application/x-www-form-urlencoded'}
// 登陆
export const login = (data?: any) => request({
    url: `${BASEURL}/portal/manage/login`,
    headers,
    withToken: false,
    data: stringify(data),
})

// 注册
export const register  = (data?: object) => request({
    url:`${BASEURL}/portal/manage/register`,
    headers,
    data: stringify(data)
})

// 退出登录
export const logout  = (data?: object) => request({
    url:`${BASEURL}/logout`,
    data
})

// 发送验证码
export const sendCaptcha  = (data?: object) => request({
    url:`${BASEURL}/portal/manage/sendCaptcha`,
    headers,
    data: stringify(data)
}) 

// 忘记密码
export const forgetPwd  = (data?: object) => request({
    url:`${BASEURL}/portal/manage/resetPassword`,
    headers,
    data: stringify(data)
}) 
