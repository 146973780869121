import { combineReducers } from 'redux'
import { historyList, areaTree, productData, allCountry, ShoppingCarNumber, taskObj, previewObj, docTreeData } from './common'
import { userInfo, domainInfo, msgInfo } from './userInfo'
import { smsSetting } from './sms'

export default combineReducers({
    historyList,
    userInfo,
    areaTree,
    smsSetting,
    productData,
    allCountry,
    ShoppingCarNumber,
    domainInfo,
    taskObj,
    previewObj,
    docTreeData,
    msgInfo
})
