import { ProColumns } from "@ant-design/pro-table";
import { getProductOptions } from "@api/index";
import { CompactDate, handleCompactDateSubmitValue } from "@components/Common/Compact";
import { handleDvmCommonListResult } from "@utils/request";
import { Badge, Input, Select } from "antd";
import moment from "moment";
import React, { ReactNode } from "react";

export const cloudStatusOptions = [
    {label: '已读', value: 1}, 
    {label: '未读', value: 2},
]

/**
 * 列表表格配置:
 *  自定义表格 CustomerDemo 和 antd 升级版组件 ProTableDemo 都可使用, 
 * */
const consumeBillDateInitValue: any = {type: 'date', value: [moment().add(-30, 'days'), moment()]}
export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '消费时间',
            dataIndex: 'time',
            key: 'time',
            initialValue: consumeBillDateInitValue,
            renderFormItem: (props) => <CompactDate value={consumeBillDateInitValue} />,
            sorter: true,
            search: {
                transform: (value) => {
                    return handleCompactDateSubmitValue(value)
                },
            },
        },
        {
            title: '产品名称',
            dataIndex: 'piName',
            key: 'piName',
            valueType: 'select', // ProTable 专用，表示当前组件是 select 类型的搜索条件
            fieldProps: {
                mode: 'multiple'
            },
            request: async () => { // 搜索条件下拉框数据，可枚举，也可从接口获取
                const {result} = await getProductOptions({}) as any
                const res = handleDvmCommonListResult(result)
                return res?.map(({piId: value, piName: label}: any) => ({label, value}))
            },
            search: {
                transform: (piIds) => {
                    return {
                        piIds
                    }
                },
            },
        },
        {
            title: '产品规格',
            dataIndex: 'pmName',
            key: 'pmName',
        },
        {
            title: '总用量',
            dataIndex: 'amount',
            key: 'amount',
            tooltip: '此处只统计产品的计费用量',
            hideInSearch: true
        },
        {
            title: '消费金额(元)',
            dataIndex: 'consumePay',
            key: 'consumePay',
            hideInSearch: true
        },
        {
            title: '余额支付(元)',
            dataIndex: 'moneyPay',
            key: 'moneyPay',
            hideInSearch: true
        },
        {
            title: '云朵支付(元)',
            dataIndex: 'cloudPay',
            key: 'cloudPay',
            hideInSearch: true
        },
        {
            title: '套餐包用量',
            dataIndex: 'orderAmount',
            key: 'orderAmount',
            hideInSearch: true
        },
        // {
        //     title: '操作',
        //     dataIndex: 'operate',
        //     key: 'operate',
        //     valueType: 'option',
        //     fixed: 'right',
        //     readOnly: true,
        //     render: (text: any, record: any) => [
        //         <a key="详情" style={{marginRight: 8}} onClick={() => operate('详情', record)}>详情</a>
        //     ]
        // }
    ]
}
