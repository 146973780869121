import { parseSearch } from "@utils/util"
import { Tabs } from "antd"
import React, { useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { BasicConfig, BackSourceConfig, HTTPSConfig, CacheConfig, VisitConfig } from './cpnts'
import {
    LeftOutlined
  } from '@ant-design/icons';
const items = [
    {
        label: '基本配置',
        key: '1',
        children: <BasicConfig />
    },
    {
        label: '回源配置',
        key: '2',
        children: <BackSourceConfig />
    },
    {
        label: 'HTTPS配置',
        key: '3',
        children: <HTTPSConfig />
    },
    {
        label: '缓存配置',
        key: '4',
        children: <CacheConfig />
    },
    {
        label: '访问控制',
        key: '5',
        children: <VisitConfig />
    }
]

export default () => {

    const { pathname, search } = useLocation();
    const { domainName } = parseSearch(search)

    return (<div>
            <div className="bg-white p-3 border-b border-gray-200">
            <LeftOutlined className="mr-4" onClick={()=> history.go(-1)}/>  {domainName}
            </div>
            <Tabs
                defaultActiveKey="1"
                className="domainConfig"
                items={items}
                style={{background: '#fff', }}
                tabBarStyle={{padding: '0 20px', margin: 0}}
            />
        </div>)
}
