import React, { useState } from 'react'
import { Image } from 'antd';
import ProForm, { ModalForm, } from '@ant-design/pro-form';
import type { ModalFormProps } from '@ant-design/pro-form' 
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '@store/index';
import { setPreviewObj } from '@store/actions';
export interface PreviewProps extends ModalFormProps{
    src?: string;
}

const PreviewImg = ({...rest}: PreviewProps) => {
    const dispatch = useDispatch()
    const {visible, src} = useSelector((store: StateType) => store?.previewObj) as any
    const urls = Array.isArray(src) ? src : [src]
    return (<div style={{display: 'none'}}>
        <Image.PreviewGroup 
            preview={{
                visible, 
                onVisibleChange: (visible: boolean) => dispatch(setPreviewObj({src, visible}))
            }}
        >
            { urls.map((src, i) => <Image key={i} src={src} />) }
        </Image.PreviewGroup>
    </div>)
}

export default PreviewImg