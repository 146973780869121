import { ProColumns } from "@ant-design/pro-table"

export const mockData = []

export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
  return [
    {
      title: '域名',
      dataIndex: 'domain'
    },
    {
      title: '流量',
      dataIndex: 'flux',
      // sorter: true,
      sorter: (a, b) => a.flux - b.flux,
      render: (text: any, record: any) => record.flux + 'mb'
    },
    {
      title: '宽带峰值',
      dataIndex: 'maxBw',
      // sorter: true,
      sorter: (a, b) => a.maxBw - b.maxBw,
      render: (text: any, record: any) => record.maxBw + 'kbit/s'
    },
    {
      title: '流量命中率',
      dataIndex: 'hitPercent',
      // sorter: true,
      sorter: (a, b) => a.hitPercent - b.hitPercent,
      render: (text: any, record: any) => (record.hitPercent||0) + '%'
    }
  ]
}
