/* eslint-disable react/no-unknown-property */
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

export default (props: any) => {
  const { nodes, materials, scene } = useGLTF('/assets/glbFile/CDN.glb') as any;
  const ref1 = useRef() as any
  const ref2 = useRef() as any
  const ref3 = useRef() as any
  const ref4 = useRef() as any
  const ref5 = useRef() as any
  
  materials['金属'] = new THREE.MeshStandardMaterial({
    precision: 'lowp',
    transparent: false,
    side: THREE.DoubleSide,
    shadowSide: THREE.FrontSide,
    roughness: 0.3, //光滑度
    metalness: 0.7,
    color: 'rgb(243, 243, 243)',
  });
  materials['红色'] = new THREE.MeshStandardMaterial({
    precision: 'lowp',
    side: THREE.DoubleSide,
    lightMapIntensity: 0.8, //烘焙光强度
    roughness: 0.01, //光滑度
    emissiveIntensity: 5,
    color: '#e34e2c',
  });

  materials['玻璃'] = new THREE.MeshStandardMaterial({
    precision: 'lowp',
    transparent: true,
    side: THREE.DoubleSide,
    opacity: 0.35,
    roughness: 0.0, //光滑度
    metalness: 0.7,
    color: 'rgb(243, 243, 243)',
  });

  function Rotation ({ v = new THREE.Vector3() }) {
    return useFrame((data: any) => {
      // const t = data.clock.elapsedTime
      // data.camera.position.lerp(v.set(Math.sin(t / 3), 0.3, 2.7 + Math.cos(t / 5) / 3), 0.1)
      ref1.current.rotation.z +=  0.01
      ref2.current.rotation.x -=  0.0025
      ref2.current.rotation.z +=  0.004
      // ref3.current.rotation.z -=  0.01
      // ref4.current.rotation.y +=  0.01
      // ref5.current.rotation.y +=  0.01
    });
  }

  return (
    <group {...props} dispose={null}>
      <Rotation></Rotation>
      <mesh geometry={nodes.圆环.geometry} material={materials.玻璃} position={[233.83, 144.38, -242.96]} rotation={[Math.PI / 2, 0, 0]} scale={25.66} />
      <mesh geometry={nodes.立方体001.geometry} material={materials.红色} position={[233.43, 40.75, -242.44]} rotation={[Math.PI / 2, 0, 0]} scale={[100, 100, 23.8]} />
      <mesh geometry={nodes.立方体002.geometry} material={materials.金属} position={[233.43, 48.29, -242.44]} rotation={[Math.PI / 2, 0, 0]} scale={[100, 100, 84.34]} />
      <mesh geometry={nodes.立方体003.geometry} material={materials.金属} position={[234.98, 53.49, -242.44]} rotation={[Math.PI / 2, 0, 0]} scale={[105.88, 105.88, 207.81]} />
      <mesh geometry={nodes.立方体004.geometry} material={materials.红色} position={[234.98, 75.75, -242.44]} rotation={[Math.PI / 2, 0, 0]} scale={[94.88, 94.88, 86.84]} />
      <mesh geometry={nodes.立方体005.geometry} material={materials.金属} position={[234.98, 98.01, -242.44]} rotation={[Math.PI / 2, 0, 0]} scale={[105.88, 105.88, 207.81]} />
      <mesh geometry={nodes.平面.geometry} material={materials.红色} position={[233.93, 115.14, -242.44]} rotation={[Math.PI / 2, 0, 0]} scale={94.35} />
      <mesh geometry={nodes.立方体006.geometry} material={materials.红色} position={[234.98, 115.37, -242.44]} rotation={[Math.PI / 2, 0, 0]} scale={[59.3, 59.3, 21.88]} />
      <mesh geometry={nodes.立方体007.geometry} material={materials.金属} position={[173.98, 115.7, -303.2]} rotation={[Math.PI / 2, 0, 0]} scale={[6.17, 6.17, 26.29]} />
      <mesh geometry={nodes.立方体008.geometry} material={materials.金属} position={[295.73, 115.7, -303.2]} rotation={[Math.PI / 2, 0, 0]} scale={[6.17, 6.17, 26.29]} />
      <mesh geometry={nodes.立方体009.geometry} material={materials.金属} position={[173.98, 115.7, -182.13]} rotation={[Math.PI / 2, 0, 0]} scale={[6.17, 6.17, 26.29]} />
      <mesh geometry={nodes.立方体010.geometry} material={materials.金属} position={[295.73, 115.7, -182.13]} rotation={[Math.PI / 2, 0, 0]} scale={[6.17, 6.17, 26.29]} />
      <mesh ref={ref2} geometry={nodes.圆环001.geometry} material={materials.玻璃} position={[232.92, 169.2, -242.44]} rotation={[Math.PI / 2, 0, 0]} scale={[111.29, 111.29, 98.86]} />
      <mesh geometry={nodes.锥体.geometry} material={materials.红色} position={[234.61, 151.49, -243.24]} rotation={[Math.PI / 2, 0, 0]} scale={[11.4, 11.4, 7.01]} />
      <mesh ref={ref1} geometry={nodes.球体.geometry} material={materials.红色} position={[233.3, 206.85, -242.44]} rotation={[1.86, -0.38, 0.11]} scale={46.97}>
        <mesh geometry={nodes.柱体009.geometry} material={materials.金属} position={[0.12, 0, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={[2.4, 2.4, 1.78]} />
        <mesh geometry={nodes.柱体010.geometry} material={materials.金属} position={[0.12, 0, 0]} rotation={[-Math.PI / 2, -1.57, 0]} scale={[2.4, 2.4, 1.78]} />
        <mesh geometry={nodes.球体001.geometry} material={materials.金属} position={[1.66, 0, 0]} scale={0.59} />
        <mesh geometry={nodes.球体002.geometry} material={materials.金属} position={[-1.8, 0, 0]} scale={0.59} />
        <mesh geometry={nodes.球体003.geometry} material={materials.金属} position={[0.11, -1.78, 0]} scale={0.59} />
        <mesh geometry={nodes.球体004.geometry} material={materials.金属} position={[0.11, 1.82, 0]} scale={0.59} />
      </mesh>
    </group>
  )
}

useGLTF.preload('/assets/glbFile/CDN.glb')
