import React, { useState, useEffect, memo, useMemo } from "react";
import {
  Row,
  Anchor,
  Tabs,
  Modal,
  Button,
  message,
  BackTop,
  Checkbox,
  Col,
  ModalProps,
} from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { GoRegister } from "../PageIndex";
import { Link as routerLink } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;
import { typeList, recommendProductList } from "./data";
import "./index.less";
import { getToken, isBrowser, parseSearch, productApi } from "@utils/util";
import {
  homeGetProductHTML,
  homeGetProFrequency,
  homeGetCheckTickAgree,
  homeOpenProductPurchase,
  homeGetproductInfo,
  homeGetProductPurchaseo,
  homeAddToCart,
} from "@api/home";
import { handleDvmCommonListResult } from "@utils/request";
import Item from "antd/lib/list/Item";
import { groupBy } from "lodash";
import { useAlertNotLogin } from "../Layout";
import { fileHttpUrl } from "@api/baseUrl";
import { asyncGetShoppingCarNumber } from "@store/actions";
export { default as ShoppingCar } from "./ShoppingCar";
export { default as Purchase } from "./Purchase";
export { default as OrderSubmit } from "./OrderSubmit";
export { default as ProPrice } from "./ProPrice";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "@store/index";
import { ChargeModal } from "@components/CostCenter/Charge";
import Agreement from "@components/Protocol";
import { calcProLinkByProCode } from "@utils/enum";
import { Description } from "@components/Common/AlertTip";
const { Link } = Anchor;
interface OpenConfirmType extends ModalProps{
  piId: any;
  proType: any;
  onSuccess?: () => void;
}
export const OpenConfirm = ({ piId, proType, onSuccess, onOk, onCancel, ...rest }: OpenConfirmType) => {
  const [readState, setReadState] = useState(false);
  const { baseInfo } = useSelector((state: StateType) => state.userInfo) as any;
  const { aciMoney } = baseInfo || [];
  console.log(rest)
  const onSubmit = async () => {
    if (readState) {
      await homeOpenProductPurchase({ piId, upsIsOpen: 1 });
      message.success("成功开通");
      onSuccess && onSuccess()
    } else {
      message.info("请阅读并同意协议");
    }
  }
  const getProFrequency = async () => {
    const { data } = (await homeGetCheckTickAgree({ piId })) as any;
    setReadState(!!data);
  };
  useEffect(() => {
    getToken() && getProFrequency();
  }, []);

  return (
    <Modal
      title="立即开通"
      // open={isModalOpen}
      // onOk={submit}
      onCancel={onCancel}
      {...rest}
      footer={[
        <Button key="submit" onClick={onCancel}>
          取消
        </Button>,
        // <ChargeModal
        //   key="chargeModal"
        //   trigger={<Button key="back">账户充值</Button>}
        // />,
        <Button key="link" type="primary" onClick={onOk||onSubmit}>
          确定
        </Button>,
      ]}
    >
      <div className="ant-alert-warning p-2 mb-2">
      <Description title="温馨提示:" listTips={[
                '开通产品后,请保证余额充足，以免影响业务的正常使用',
            ]} />
      </div>

      <div className="ml-5" style={{marginTop: 24}}>
        <div>计费方式：按量扣费</div>
        <div className="mt-4 mb-4">账户余额：<span className="jc-theme-color">￥{aciMoney}元</span> </div>
        <Checkbox
          checked={readState}
          onChange={(e) => setReadState(e.target.checked)}
        >
          我已阅读并同意
          <Agreement type={proType} />
        </Checkbox>
      </div>
    </Modal>
  );
};

// 产品的详情组件
const ProductDetails = ({ piId }: any) => {
  const history = useHistory();
  const [productDetails, setProductDetails] = useState({} as any);
  const { baseInfo, permissions } = useSelector((state: StateType) => state.userInfo) as any;
  const { verifyState, userNature } = baseInfo || {};
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const onCharge = useAlertNotLogin(baseInfo);

  const onclick = (type: string) => {
    if (type === "buy") {
      history.push(`/shopping/purchase?piId=${piId}`);
    } else if (type === "open") {
      setIsModalOpen(true)
    } else if (type === "price") {
      history.push(
        `/proPrice?piId=${piId}&productDetails=${productDetails.piModelType}&pName=${productDetails.piName}`
      );
    } else if(type === "jumpTo") {
      const proLink = calcProLinkByProCode(productDetails.piCode)
      history.push(proLink)
    } else if(type === 'jumpToDoc') {
      // console.log('-----productDetails----', productDetails)
      // history.push(`/home/doc/${}?docId=29`)
    }
  };

  const getIsOpen = async () => {
    const { data } = (await homeGetProFrequency({ piId })) as any;
    setIsOpen(!!data);
  };

  const getData = async () => {
    const { result } = (await homeGetproductInfo({ piId })) as any;
    const data = handleDvmCommonListResult(result);
    setProductDetails(data[0]);
  };

  useEffect(() => {
    getData();
    getToken() && getIsOpen();
  }, []);

  const MemoPage = useMemo(
    () => (
      <OpenConfirm
        open={isModalOpen}
        piId={piId}
        proType={productDetails.piModelType}
        onCancel={() => setIsModalOpen(false)}
        onSuccess={() =>{ setIsModalOpen(false), getIsOpen()}}
      />
    ),
    [piId, isModalOpen]
  );

  return (
    <div
      className="ProductDetails"
      style={{
        background: `url(${fileHttpUrl(productDetails.piBanner)}) no-repeat scroll top center  #DDE1EC`,
      }}
    >
      <h1 className="title">{productDetails.piName}</h1>
      <div className="text">{productDetails.piDescribe}</div>
      <div className="btn pc-component">
        {productDetails.piIsShopping ? (
          <Button
            type="primary"
            onClick={() => onCharge(() => onclick("buy"))}
            danger
            className="redBtn mr-4"
          >
            购买套餐包
          </Button>
        ) : null}
        {productDetails.piIsOpen ? (
          <Button
            type="primary"
            onClick={() => onCharge(() => onclick("open"))}
            danger
            disabled={isOpen}
            className="redBtn mr-4"
          >
            <span style={{ color: "white" }}>
              {isOpen ? "已开通按量付费" : "开通按量付费"}
            </span>
          </Button>
        ) : null}
        {/* {productDetails.piIsOpen} */}
        {productDetails.piIsShowPrice ? <Button
          type="primary"
          onClick={() => onclick("price")}
          ghost
          danger
          className="redBtn_b mr-4"
        >
          产品价格
        </Button> : null}

        {(permissions || []).includes(productDetails.piCode) ? <Button
          type="primary"
          onClick={() => onclick("jumpTo")}
          ghost
          danger
          className="redBtn_b"
        >
          管理控制台
        </Button> : null}

        {/* <Button
          type="primary"
          onClick={() => onclick("jumpToDoc")}
          ghost
          danger
          className="redBtn_b"
        >
          接入文档
        </Button> */}
      </div>
      {/* 开通产品的弹窗 */}
      {productDetails.piIsOpen ? MemoPage : null}
    </div>
  );
};
// 单个的热门产品小组件
const RecommendProductItem = ({ data }: any) => {
  const { userInfo } = useSelector((state: StateType) => state) as any;
  const { baseInfo = [] } = userInfo || [];
  const history = useHistory();
  const onCharge = useAlertNotLogin(baseInfo);
  // 菜单点击事件
  const submitBuy = async () => {
    const { ppgName, ppgModelAmountName, ppgModelLenName, piId } = data || {};
    history.push(
      `/shopping/purchase?piId=${piId}&ppgModel1st=${ppgName}&ppgModelAmountName=${ppgModelAmountName}&ppgModelLenName=${ppgModelLenName}`
    );
  };

  return (
    <div className="RecommendProductItem">
      {/* <div>{JSON.stringify(data)}</div> */}
      <h2 className="title">{data.piName}</h2>
      <div className="text">{data.ppgName}</div>
      <div className="introduce">
        <div>
          <CheckOutlined className="icon" />
          套餐类型:{data.ppgName}
        </div>
        <div>
          <CheckOutlined className="icon" />
          套餐规格:{data.ppgModelAmountName}
        </div>
        <div>
          <CheckOutlined className="icon" />
          有效时长:{data.ppgModelLenName}
        </div>
      </div>
      <div className="priceDiv">
        <span className="price">{data.ppgSaleTotalPrice}</span>
        <span className="company">元</span>
        <span
          className={`originalPrice ${
            data.ppgDiscount === 100 ? "opacity" : ""
          }`}
        >
          {data.ppgSourceTotalPrice}元
        </span>
        <br />
        <div
          className={`discount ${data.ppgDiscount === 100 ? "opacity" : ""}`}
        >
          {data.ppgDiscount / 10}折优惠
        </div>
      </div>
      <Button
        type="primary"
        danger
        className="redBtn"
        onClick={() => onCharge(() => submitBuy())}
      >
        立即购买
      </Button>
    </div>
  );
};

// 推荐购买产品组件
const RecommendProduct = ({ productCard }: any) => {
  return productCard.length ? (
    <div className="recommendProduct" id='id_0'>
      <div className="modularTitle">推荐购买</div>
      <div className="proDiv">
        <Row>
          {productCard.map((element: any) => {
            return (
              <Col key={element.ppgId} span={8}>
                <RecommendProductItem data={element} />
              </Col>
            );
          })}
          {/* <div className="RecommendProductItem">5</div> */}
        </Row>
      </div>
    </div>
  ) : null;
};

//类型切换 tabs
const TypeModule = ({activeKey, onChange, items }: any) => {
  return (
    <div className="TypeModule">
      <Anchor onChange={onChange} targetOffset={150}>
        <Tabs activeKey={activeKey} centered items={items} />
      </Anchor>
    </div>
  );
};

// HTML 渲染区
const HtmlPre = ({htmlList}: any) => {
  console.log(htmlList)
  return (<>
  {(htmlList||[]).map((item: any, index: number) => {
        return (item.list || []).length ? (<RecommendProduct productCard={item.list || []} />) : (
          <div key={index} id={item.key.slice(1)} className="product-HTML-div">
            <div dangerouslySetInnerHTML={{ __html: item.HTML }}></div>
          </div>
        )
      })}
  </>)
}

const handleRecommendData = (data1: any) => {
  return (data1||[]).length ? [{
    key: "#id_0",
    label: <Link href="#id_0" title="推荐购买" />,
    list: data1,
  }] : []
}
const handleProductHtmlData = (data2: any, len: number) => {
  return (data2||[]).map((element: any, idx: number) => ({
    key: `#id_${idx + len}`,
    label: (
      <Link
        href={`#id_${idx + len}`}
        title={element.peTypeName}
      />
    ),
    HTML: element.peContent,
  }))
}

const getInitialProps = async (params: any) => {
  const [ res, res2 ] = await Promise.all([homeGetProductPurchaseo(params), homeGetProductHTML(params)])
  const { result: result1 } = res || {} as any
  const { result: result2 } = res2 || {} as any
  const data1 = handleDvmCommonListResult(result1)
  const data2 = handleDvmCommonListResult(result2)
  const recommendData = (isBrowser() && window.innerWidth > 768) ? handleRecommendData(data1) : []
  const productHtmlData = handleProductHtmlData(data2, recommendData.length)
  const willSetData = [...recommendData, ...productHtmlData]
  return willSetData
}

const Product = ({initialProps}: any) => {
  const [activeKey, setActiveKey] = useState('#id_0');
  const [modularData, setModularData] = useState<any>(initialProps||[]);

  const { search } = useLocation();
  const { piId } = parseSearch(search);

  const getData = async () => {
    const willSetData = await getInitialProps({ piId })
    setModularData(willSetData);
  };

  useEffect(() => {
    initialProps ? null : getData()
  }, [initialProps]);

  return (
    <div className="Home_Product">
      {/* 产品详情 */}
      <ProductDetails piId={piId} />
      {/* tabs 分类 */}
      <TypeModule items={modularData} activeKey={activeKey} onChange={setActiveKey} />

      <HtmlPre htmlList={modularData} />

      <GoRegister />
    </div>
  );
};

Product.getInitialProps = getInitialProps

export default Product
