import { ProColumns } from "@ant-design/pro-table";
import { Input, Select } from "antd";
import React, { ReactNode } from "react";

export const cloudStatusOptions = [
    {label: '可使用', value: 1}, 
    {label: '已用完', value: 2},
    {label: '已过期', value: 3},
]

/**
 * 列表表格配置:
 *  自定义表格 CustomerDemo 和 antd 升级版组件 ProTableDemo 都可使用, 
 * */
export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '可用云朵（个)',
            dataIndex: 'usableCloud',
            key: 'usableCloud',
            hideInSearch: true
        },
        {
            title: '失效时间',
            dataIndex: 'endDate',
            key: 'endDate',
            hideInSearch: true
        },
        {
            title: '状态',
            dataIndex: 'useStatusStr',
            key: 'useStatusStr',
            valueType: 'select',
            request: async () => { // 搜索条件下拉框数据，可枚举，也可从接口获取
                return cloudStatusOptions
            },
            search: {
                transform: (value) => {
                    return {
                        useStatus: value
                    }
                },
            },
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            valueType: 'option',
            fixed: 'right',
            readOnly: true,
            render: (text: any, record: any) => [
                <a key="详情" style={{marginRight: 8}} onClick={() => operate('详情', record)}>详情</a>
            ]
        }
    ]
}