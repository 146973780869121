import request from "@utils/request";
import { fromData } from "@utils/util";
import { stringify } from "qs";
import { BASEUPLOADURL, BASEURL, MockUrl } from "../baseUrl";
import { headers } from "../login";

// const BASEURL = ''

/****************空号检测******************/ 
// 在线检测结果
export const phoneCheck  = (data?: object) => request({
    url:`${BASEURL}/web/product/onlineRealCheck`,
    // headers,
    data
})

export const phoneCheckResult  = (data?: object) => request({
    url:`${BASEURL}/web/product/getCardOperatorList`,
    // headers,
    data
})