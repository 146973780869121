import React, { useRef, useState } from "react"
import { useTableHooks } from "@hooks/index"
import { columnsFn } from './data'
import { Button, Col, Modal, Row, Table } from "antd"
import { productApi } from "@utils/index"
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home"
import { ActionType, ProTable } from "@ant-design/pro-table"
import { useHistory } from "react-router-dom"
import { ExportButton } from "@components/Common"
import { handleDvmCommonPageListResult } from "@utils/request"
import { BASEURL, getCostMyOrderDel, getCostMyOrderPageList } from "@api/index"
import { stringify } from "qs"
// 列表操作的 类型校验
type Action = '支付' | '详情' | '删除'

// 接口请求的 api 层
const getApi = productApi(getCostMyOrderPageList)  // 查询的接口封装
const addUpdateApi = productApi(homeTableAddUpdate) // 列表数据的新增/编辑 接口
const delApi = productApi(getCostMyOrderDel) // 表单数据的删除 接口
const getListApi = handleDvmCommonPageListResult(getApi)

export default () => {
    const history = useHistory()
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    const formRef = useRef<Performance>(null); //  ProTable 组件的 formRef
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [modalProps, setModalProps] = useState({visible: false, title: '新增', params: {}})
    // operate 函数为增删改查需要处理的业务
    const operate = (type: Action, record: any) => {
        const { oiId } = record || {}
        if (type === '支付') {
            history.push(`/shopping/orderSubmit?${stringify({oiId})}`)
        } else if (type === '详情') {
            history.push(`/cost/orderDetail?${stringify({oiId})}`)
            console.log('需要处理的修改业务')
        } else if (type === '删除') {
            const oiIds = record ? [oiId] : selectedRowKeys
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                centered: true,
                cancelText: '取消',
                onOk: async() => {
                    await delApi({oiIds: oiIds.toString()}, true, () => {
                        console.log('数据删除成功, 可以执行回调了')
                        reFresh()
                    })
                },
            });
        }
    }

    // const getListApi = async (params: any, sorter: any, filter: any) => {
    //     const { data } = await getApi({...params}, false)
    //     const { values, total } = data || {}
    //     return {
    //         data: values || [],
    //         success: true,
    //         total
    //     }
    // }
    // const getListApi = handleDvmCommonPageListResult(getApi)

    const columns = columnsFn(operate) // 表格列配置
    const getQueryParams = () => ({
        // @ts-ignore
        ...formRef.current?.getFieldsFormatValue()
    })

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        console.log('处理后的成功回调触发了')
        setModalProps({
            ...modalProps,
            visible: false
        })
        reFresh()
    }
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const calcDelDisabled = () => selectedRowKeys.length

    return (<><ProTable<any>
            // scroll={{x: 1400}}
            columns={columns}
            params={{}} // 查询所需要的额外参数
            request={getListApi}
            options={{reload: false, density: false, setting: false}}
            toolbar={{
                actions: [
                    <ExportButton key="export" type='primary' action={`${BASEURL}/portal/expenseCenter/exportUserOrders`} param={getQueryParams} btnText="导出" />
                    // <Button key="btn1" type='primary' disabled={!calcDelDisabled()} onClick={() => operate('删除', null)}>删除</Button>,
                ],
            }}
            rowKey="oiId"
            pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
            formRef={formRef as any}
            actionRef={ref as any}
            search={{
                optionRender: (searchConfig, formProps, dom) => [ ...dom.reverse() ]
            }}
            // rowSelection={{
            //     preserveSelectedRowKeys: true,
            //     onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
            // }}
        />
    </>)
}
