
import React, { useState, useEffect, memo, useMemo } from "react";
import { Row, Col, Carousel, Button, Badge, BackTop, Tabs, List } from "antd";
import { stepList, servicePlan } from './data'
import './index.less'
import Img from '@/assets/images/programme.png'
import { useHistory } from "react-router-dom";
import { fileHttpUrl } from "@api/baseUrl";
import { PopUpArea } from "../Layout";
import { homeGetCarousel, homeGetGetInfomation, homeGetHotProList, homeGetproductList } from "@api/home";
import { handleDvmCommonListResult } from "@utils/request";
import { isBrowser, sliceText, setHref, isMobile } from "@utils/util";
import { StateType } from "@store/index";
import { useSelector } from "react-redux";
import ImgOrder from "@components/Common/ImgOrder";
import { OverPack } from 'rc-scroll-anim';
import "rc-texty/assets/index.css";
import QueueAnim from "rc-queue-anim";
import ThreeModel from "@components/Common/ThreeModel";
import bg1 from '@/assets/images/logo/1.png';
import bg2 from '@/assets/images/logo/2.png';
import bg3 from '@/assets/images/logo/3.png';
import bg4 from '@/assets/images/logo/4.png';
import bg5 from '@/assets/images/logo/5.png';
import bg6 from '@/assets/images/logo/6.png';
import bg7 from '@/assets/images/logo/7.png';
import bg8 from '@/assets/images/logo/8.png';
import bg9 from '@/assets/images/logo/9.png';
import bg10 from '@/assets/images/logo/10.png';
import bg11 from '@/assets/images/logo/11.png';
import bg12 from '@/assets/images/logo/12.png';
import bg13 from '@/assets/images/logo/13.png';
import bg14 from '@/assets/images/logo/14.png';
import bg15 from '@/assets/images/logo/15.png';
import bg16 from '@/assets/images/logo/16.png';
import bg17 from '@/assets/images/logo/17.png';
import bg18 from '@/assets/images/logo/18.png';
import bg19 from '@/assets/images/logo/19.png';
import bg20 from '@/assets/images/logo/20.png';
import bg21 from '@/assets/images/logo/21.png';
import bg22 from '@/assets/images/logo/22.png';
import bg23 from '@/assets/images/logo/23.png';
import bg24 from '@/assets/images/logo/24.png';
import bg25 from '@/assets/images/logo/25.png';
import bg26 from '@/assets/images/logo/26.png';
import bg27 from '@/assets/images/logo/27.png';
import bg28 from '@/assets/images/logo/28.png';
import bg29 from '@/assets/images/logo/29.png';
import bg30 from '@/assets/images/logo/30.png';
import bg31 from '@/assets/images/logo/31.png';
import bg32 from '@/assets/images/logo/32.png';
import bg33 from '@/assets/images/logo/33.png';
import bg34 from '@/assets/images/logo/34.png';
import bg35 from '@/assets/images/logo/35.png';
import bg36 from '@/assets/images/logo/36.png';
import bg37 from '@/assets/images/logo/37.png';
import bg38 from '@/assets/images/logo/38.png';
import { Animation } from '@components/index'
// 轮播组件
const Banner = () => {
    const [banner, setBanner] = useState([] as any)
    const [state, setState] = useState('1' as  any)
    const [show, setShow] = useState('1' as any)
    const getData = async () => {
        const { result } = await homeGetCarousel({ baPosition: 0 }) as any
        const data = handleDvmCommonListResult(result)
        setBanner(data)
    }
    useEffect(() => {
        getData()
    }, [])
    const afterChange = () => {
        setTimeout(() => {
          setState(false)
        }, 100);
    }
    const beforeChange = (from: any, to: any) => {
        setTimeout(() => {
            state === '1' ? setState(false) : setState(true)
        }, 50);
        setShow(banner[to]?.baActionCode)
    }
    return (<>
        <div className={'contentBanner'} >
            <Carousel autoplay autoplaySpeed={5000} afterChange={afterChange} beforeChange={beforeChange}>
                {
                    banner.map((element: any, index: number) => {
                        return <div key={index} className='bannerImgDiv'>
                            <div className="bannerBackgroundDiv" style={{background: `url(${fileHttpUrl(element.baImgs) || fileHttpUrl('test/image/D6A4D9D7AEEB4F1CBACC485D257EDA3A.jpg')}) no-repeat`}}>
                               {!state ? 
                                    <div key={element.baTitle}  className="info j-cover-red-color">
                                        {/* <Animation type="left">  */}
                                            <h3 key={'111'}>{element.baTitle}</h3>
                                            <h5  key={'222'}>{element.baDesc}</h5>
                                            <div  key={'333'} className="pc-component"> <a href={element.baUrl} ><Button type="primary" >{element.baButtonText || '了解更多'}</Button></a></div>
                                        {/* </Animation>  */}
                                    </div>
                                  : null}
                            </div>
                        </div>
                    })
                }
            </Carousel >
            {/* three js 组件 */}
            <div className="ThreeModel">
              {useMemo(()=> <ThreeModel type={show} />, [show]) }
            </div>
            {/* 小活动页面 */}
            {useMemo(()=> <Describe />, []) }
        </div>
    </>)
}

// 活动小组件
const Describe = () => {
    const [activityList, setActivityList] = useState([] as any)
    const getData = async () => {
        const { result } = await homeGetCarousel({ baPosition: 1 }) as any
        const data = handleDvmCommonListResult(result)
        setActivityList(data)
    }
    useEffect(() => {
        getData()
    }, [])

    return (<>
        <div id="desc">
            {activityList.map((element: any, index: number) => {
                return <div key={index} className="item" onClick={()=> element.baUrl && (location.href = element.baUrl)}>
                    <div className="text1">{element.baTitle}</div>
                    <div className="text2">{element.baDesc}</div>
                </div>
            })}
            {/* <ul>
                {
                    activityList.map((element: any, index: number) => {
                        return <li key={index}>
                            <div className="text">
                                <h3>{element.baDesc}</h3>
                                <h5>{element.baTitle}</h5>
                            </div>
                        </li>
                    })
                }
            </ul> */}
        </div>
    </>)
}

// 产品列表
const ProductList = ({dataSource}:any) => {

    const [activeIndex, setActiveIndex] = useState(0 as  number)
    const [state, setState] = useState(true as  any)
    const [maxHeight, setMaxHeight] = useState(0 as  any)


    // 产品分类的单击事件
    const proTypeClick = (index: number) => {
        setActiveIndex(index)
        setMaxHeight(Math.ceil(((dataSource[index]?.children || []).length) / 4) * 200)
        console.log(Math.ceil(((dataSource[index]?.children || []).length) / 4) * 200)
        setState(false)
        setTimeout(() => {
            setState(true)
        }, 10);
    }

    // 点击产品分类之后展开的产品块
    const ProductBlockContent = ({state}:any) => {
        return <Col span={24}>
            <div className="">
                <div style={{ display: 'flex',
                    flexWrap: 'wrap',}}
                >
                    <Row>
                    <Animation type="top" height={2000} delay={0.1}> 
                        {(dataSource[activeIndex]?.children || []).map((element: any, index: number) => {
                            return <Col span={6} key={element.piName} >
                                <div className={'SmallProBlock'} style={{ width: '100%',}} onClick={() =>  window.location.href = `/home/product?piId=${element.piId}`}>
                                    <img key="111" src={fileHttpUrl(element.piLogo)} alt="" />
                                    <p key="222">{element.piName}</p>
                                    <div key="333" className={'text'}>{sliceText(element.piDescribe, 25)}</div>
                                </div>
                            </Col>
                        })}
                    </Animation>
                    </Row>
                </div>
            </div>
        </Col>
    }

    // 判断当前行是否该插入产品块节点
    const judgeLine = (index: number, maxNumber: number) => {
        return (index > 0 && (index + 1) % 6 === 0) || index + 1 === maxNumber
    }
    return (
        <div className={'content_ProductList  pc-component'}>
            <div className={'title'}><Animation.text type="bottom">性能强大、安全、稳定的云产品</Animation.text></div>
            <div>
                <Row>
                    {
                        dataSource.map((element: any, index: number) => {
                            return <React.Fragment key={index}>
                                
                                <Col span={4} className="proTypeCol">
                                    <div onClick={() => proTypeClick(index)} className="itemDiv">
                                        <div>
                                            <ImgOrder src={fileHttpUrl(element.ptImg)} className="proTypeImg mb-2"/>
                                        </div>
                                        {/* <img src={fileHttpUrl(element.ptImg)} className="proTypeImg mb-2" /> */}
                                        {element.ptName}
                                        <img className={activeIndex === index ? 'activeIconImg' : 'IconImg'} src='https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng1a154047fdf1cbccdd0df0664676e2dcee9485183c127e98cfa8d2a7bb2056a5' alt="" />
                                    </div>
                                </Col>
                                {/* 判断每6个产品为一行，在每一行及最后一行的下边插入产品展示块 */}
                                <Col
                                    span={24}
                                    key={index}
                                    style={(index + 1) % 6 === 0 || index + 1 === dataSource.length ? { display: 'block'} : { display:'none'}}
                                    className={Math.ceil((index || 1) / 6) === Math.ceil((activeIndex || 1) / 6) ? 'activeType ejectDiv' : 'ProductBlockContent ejectDiv'}>
                                    {
                                        (judgeLine(index, dataSource?.length)) ? <ProductBlockContent state={state}/> : null
                                    }
                                </Col>

                            </React.Fragment>
                        })
                    }
                </Row>
            </div>
        </div>)
}

// 移动端产品列表组件
const Jm_ProductList = ({dataSource}:any) => {

  const SmallProductDiv = ({productList}:any) => {
       return <Row className="SmallProductDiv">
        {productList.map((element:any, index:number) => {
            return <Col span={12} key={element.piId} className="proTypeCol">
                <div className={'productDiv'} style={{ width: '100%',}} onClick={() =>  isBrowser() && (window.location.href = `/home/product?piId=${element.piId}`)}>
                    <img key="111" src={fileHttpUrl(element.piLogo)} alt="" />
                    <p key="222">{element.piName}</p>
                    <div key="333" className={'text'}>{sliceText(element.piDescribe, 20)}</div>
                </div>
            </Col>
        })}
       </Row>
  }

  return <div className="jm-productList jm-component jc-antd-reset">
    <Tabs
        defaultActiveKey="1"
        centered
        items={dataSource.map((item:any, i:number) => {
        return {
            label: item.ptName,
            key: item.ptId,
            children: <SmallProductDiv productList={item.children} />,
        };
        })}
    />
  </div>
}


// 成功客户列表
const SuccessCustomerList = () => {
    const titleList = [
       '迁移部署','优化提升','运维护航','安全等保','培训咨询'
    ]
    const imgList = [
        {url: bg1 ,col: 3, alt: '云极案例杭州马拉松'},
        {url: bg2 ,col: 3, alt: '云极案例千岛湖啤酒'},
        {url: bg3 ,col: 3, alt: '云极案例林清轩'},
        {url: bg4 ,col: 3, alt: '云极案例YAMAHA'},
        {url: bg5 ,col: 3, alt: '云极案例天擎'},
        {url: bg6 ,col: 3, alt: '云极案例斗石网'},
        {url: bg7 ,col: 3, alt: '云极案例'},
        {url: bg8 ,col: 3, alt: '云极案例华锋'},
        {url: bg9 ,col: 3, alt: '云极案例中医药出版社'},
        {url: bg10,col: 3, alt: '云极案例安徽新华教育'},
        {url: bg11,col: 3, alt: '云极案例秀购'},
        {url: bg12,col: 3, alt: '云极案例座上客'},
        {url: bg13,col: 3, alt: '云极案例APHP'},
        {url: bg14,col: 3, alt: '云极案例路幻信息'},
        {url: bg15,col: 3, alt: '云极案例ZOZO'},
        {url: bg16,col: 3, alt: '云极案例就爱学'},
        {url: bg17,col: 3, alt: '云极案例探恪'},
        {url: bg18,col: 3, alt: '云极案例小兵驿站'},
        {url: bg19,col: 3, alt: '云极案例德阳飞讯'},
        {url: bg20,col: 3, alt: '云极案例爱普雷德'},
        {url: bg21,col: 3, alt: '云极案例新亚医疗'},
        {url: bg22,col: 3, alt: '云极案例陌嗨短视频'},
        {url: bg23,col: 3, alt: '云极案例上海明帝'},
        {url: bg24,col: 3, alt: '云极案例吗链尚微淘'},
        {url: bg25,col: 3, alt: '云极案例蜂站'},
        {url: bg26,col: 6, alt: '云极案例宁波市住房和城乡建设局'},
        {url: bg27,col: 3, alt: '云极案例中科软'},
        {url: bg28,col: 3, alt: '云极案例集采'},
        {url: bg29,col: 3, alt: '云极案例凤凰网'},
        {url: bg30,col: 3, alt: '云极案例健培'},
        {url: bg31,col: 3, alt: '云极案例老爸评测'},
        {url: bg32,col: 3, alt: '云极案例中国移动'},
        {url: bg33,col: 3, alt: '云极案例杭州地铁'},
        {url: bg34,col: 3, alt: '云极案例宗盛智能'},
        {url: bg35,col: 3, alt: '云极案例百家云'},
        {url: bg36,col: 3, alt: '云极案例骑电'},
        {url: bg37,col: 3, alt: '云极案例中安科技'},
        {url: bg38,col: 3, alt: '云极案例南京伍牛'},
    ]
    return (
      <div className={"successCustomerList"}>
        <p>来自各行各业的最佳实践 值得选择</p>
        <div className={"flex "}>
          <div className="left">
            {titleList.map((item, i) => (
              <div className="item" key={i}>{item}</div>
            ))}
          </div>
          <div className="" style={{width: '100%' , overflowX: 'auto', overflowY: 'hidden'}}>
             <div className="right" style={{ overflow: "hidden"}}>
                <Animation height={600}>
                  {imgList.map((item2, index) => (
                      <div style={{width: `${item2.col/24 * 100}%`}} key={index} className={"img_col"}>
                          <div key={index} className={"img_col_div"}>
                              <img src={item2.url} alt={item2.alt} />
                          </div>
                      </div>
                  ))}
                </Animation>
            </div>
          </div>
          {/* <img src={fileHttpUrl("fileServer/2022/11/29/55f8af0bad6d4279924528a1d54ea9b1.jpg")} alt="" /> */}
        </div>
      </div>
    );
}

// 服务案例列表
const ServicePlan = () => {
    const [activeKey, setActiveKey] = useState(0)
    const TypeMenu = () => {
        return (<div>
            {
                servicePlan.map((item, index) => {
                    return <div key={index} onClick={() => { setActiveKey(index) }} className={activeKey === index ? 'active' : 'menuItem'}>
                        {item.typeName}
                    </div>
                })
            }
        </div>)
    }
    const PlanDetails = ({ data }: any) => {

        return (<div>
            <QueueAnim className="" type="left" style={{
                width: '100%',
            }}>
                <h2 key="11">面向丰富业务场景的服务方案</h2>
                <h3 key="12">{data.title}</h3>
                <div key="13" className="planDetails">
                    <div>
                        {
                            data.children.map((element: any, index: number) => {
                                return <p key={index}>· {element}</p>
                            })
                        }
                    </div>
                    <div className={`imgDiv imgDiv_${activeKey}`}>
                        <div className="img">
                            <img src={Img} alt="云极服务方案" />
                        </div>

                    </div>
                </div>
                <p key="14">客户案例</p>
                <div key="15" className={`bottomImg`}>
                    <div className="img">
                        <img src={data.imgUrl} alt="云极服务方案" />
                    </div>

                </div>
            </QueueAnim>
        </div>)
    }

    return (<div className={'servicePlan pc-component'}>
        <div className="left">
            <TypeMenu />
        </div>
        <div className="right">
            <PlanDetails data={servicePlan[activeKey]} />
        </div>
    </div>)
}



// 移动端   服务案例列表
const Jm_ServicePlan = () => {
    const [activeKey, setActiveKey] = useState(0) as any

    const Children = ({ data }: any) => {
      return <div>
          <h3>{data.title}</h3>
          <div className="planDetails">
            <div>
              {data.children.map((element: any, index: number) => {
                return <p key={index}>· {element}</p>;
              })}
            </div>
            <div className={`imgDiv`}>
              <div className="img">
                <img src={data.rImgUrl} alt="云极服务方案" />
              </div>
            </div>
          </div>
          <p key="14">客户案例</p>
          <div key="15" className={`bottomImg`}>
            <div className="img">
              <img src={data.imgUrl} alt="云极服务方案" />
            </div>
          </div>
        </div>
    };


    
        return (<div className="Jm_servicePlan jm-component jc-antd-reset">
            <h2>面向丰富业务场景的服务方案</h2>
            <Tabs
                defaultActiveKey="1"
                centered
                onChange={(activeKey)=> {
                    setActiveKey(activeKey)
                }}
                items={servicePlan.map((item:any, i:any) => {
                return {
                    label: item.typeName,
                    key: i,
                    children: <Children data={item} />,
                };
                })}
            />


            
        </div>)
}

//    <div className={'Jm_servicePlan jm-component'}>
//         <div className="right">
//             <PlanDetails data={servicePlan[activeKey]} />
//         </div>
//     </div>)



// 新手指南
const NoviceGuide = () => {

    // 小card
    const Card = ({ data }: any) => {
        return <div className={'card'}>
            <div className={'topNumber'}>{data.number}</div>
            <div className={'noviceGuideCard'}>
                <img src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng0a9887245d62d64333df4b6aa9e0223ef5670c442453578641cea9ec65220f90" alt="" />
                <p>{data.name}</p>
                {/* <Button>免费注册</Button> */}
            </div>
            <img className={'rightArrow'} src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng0a9887245d62d64333df4b6aa9e0223ef5670c442453578641cea9ec65220f90" alt="" />
        </div>
    }

    return (<div className={'noviceGuide'}>
        <p className={'title'}>新手指南</p>
        <div className={'noviceGuideBody'}>
            {
                stepList().map((element, index) => {
                    return <Card key={index} data={element} />
                })
            }
        </div>
    </div>)
}

// 新手指南
export const GoRegister = () => {
    const history = useHistory()
    const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;

    return (<div className={'j_bottom_DivCard_goregister j-cover-red-color'} style={{ overflow: 'hidden',  background: `url(${fileHttpUrl('test/image/381E743FDE60462AA1C35E249ED31118.png')}) no-repeat` }}>
                {/* <Animation > */}
                    <div key='1' className="text-black mt-2">{ueSaasName}为合作伙伴提供全方位合作与支持</div>
                    <Button key="2" type="primary" onClick={() =>  window.location.href = `/home/cooperation#joinUsDiv`}>立即加入</Button>
                {/* </Animation> */}
    </div>)
}

// 新闻中心
export const MainNews = ({dataSource}: any) => {
    const [first, ...restNews] = dataSource
    return (<div className="successCustomerList z-main-news" style={{paddingTop: 0}}>
        <p style={{marginTop: 0}}>行业资讯</p>
        <Row gutter={[40,2]} wrap>
            <Col className="first-new">
                <div style={{position: 'relative', borderRadius: 5, overflow: 'hidden', height: '100%', cursor: 'pointer'}} onClick={() => setHref(`/home/industryInformation/${first.pinId}`)}>
                    <div style={{position: 'absolute', top: 0, left: 0, background: '#30c8c6', padding: 4, color: '#fff', textAlign: 'center', fontSize: 12}}>
                        <div style={{fontSize: 20}}>{first?.createTime.slice(8, 10)}</div>
                        <div>{first?.createTime.slice(0, 7)}</div>
                    </div>
                    <img src={fileHttpUrl(first?.pinImage)} style={{width: '100%', height: '100%'}} />
                    <span style={{
                        position: 'absolute', 
                        bottom: 0, 
                        left: 0, 
                        right: 0, 
                        background: 'rgba(0,0,0, .6)', 
                        color: '#fff', 
                        padding: 6,
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                        }}>{first?.pinTitle}</span>
                </div>
            </Col>
            <Col className="other-new">
                <List
                    itemLayout="horizontal"
                    dataSource={restNews}
                    renderItem={(item: any, index) => (
                    <List.Item className="item">
                        <List.Item.Meta
                            style={{cursor: 'pointer'}}
                            onClick={() => setHref(`/home/industryInformation/${item.pinId}`)}
                            title={<span style={{color: 'red', fontWeight: 400, fontSize: 24}}>{item.createTime.slice(0, 10)}</span>}
                            description={<div>
                                <div style={{fontSize: 16, color: '#333', paddingBottom: 12}}>{item.pinTitle}</div>
                                <div style={{textIndent: 20, fontSize: 12}}>{sliceText(item.pinAbstract, 70)}</div>
                                <div style={{margin: '10px 0', fontSize: 12, cursor: 'pointer'}}>查看详情</div>
                            </div>}
                        />
                    </List.Item>
                    )}
                />
            </Col>
        </Row>
    </div>)
}

export const getInitialProps = async () => {
    const [res, res2, res3] = await Promise.all([homeGetHotProList(), homeGetproductList({ type: 10 }), homeGetGetInfomation({pageNum: 1, pageSize: 3})])
    const {result: hotList} = res || {} as any
    const {result } = res2 || {} as any
    const hotData = handleDvmCommonListResult(hotList)
    const newsDataSource = handleDvmCommonListResult(res3.result)
    const productDataSource = [{
          children: hotData,
          piId: 1,
          ptPid: 0,
          ptName: '热门推荐',
          ptImg: 'test/image/55CEB86BAEAD44BE812CE168391C286F.png'
      },
    ...result]
    return {
      productDataSource,
      newsDataSource
    }
}

const Home = ({initialProps}: any) => {
    const [dataSource, setDataSource] = useState(initialProps?.productDataSource||[])
    const [newsDataSource, setNewsDataSource] = useState(initialProps?.newsDataSource||[])

    const getDataApi = async() => {
        const { productDataSource, newsDataSource } = await getInitialProps()
        setDataSource(productDataSource)
        setNewsDataSource(newsDataSource)
    }

    useEffect(() => {
      initialProps ? null : getDataApi()
    }, [initialProps])

    return (<div className='homeContentIndex'>
        {/* 轮播 */}
        <Banner />

        {/* 产品列表 pc组件*/}
        <ProductList dataSource={dataSource} />

        {/* 移动端产品列表 */}
        <Jm_ProductList dataSource={dataSource}/>

        {/* 服务方案列表 */}
        <ServicePlan />

        {/* 移动端  服务方案列表 */}
        <Jm_ServicePlan />

        {/* 成功客户列表 */}
        <SuccessCustomerList />
        
        {/* 行业资讯 */}
        <MainNews dataSource={newsDataSource} />

        {/* 新手指南 */}
        {/* <NoviceGuide /> */}

        {/* 购物车，微信悬框等 */}
        <PopUpArea />

        {/* 立即注册 */}
        <GoRegister />
    </div>
    )
}

Home.getInitialProps = getInitialProps

export default Home
