
import request from "@utils/request";
import { fromData } from "@utils/util";
import { stringify } from "qs";
import { BASEURL } from "./baseUrl";
import { headers } from "./login";

// 文档中心  查询文档树列表
export const docGetDocTreeData = (data?: any) => request({
    url: `${BASEURL}/web/doc/getDocList`,
    data,
})

// 获取文档详情
export const docGetDocDetailById = (data?: any) => request({
    url: `${BASEURL}/web/doc/getDocById`,
    method: 'GET',
    data,
})

// 文档搜索
export const docSearch = (data?: any) => request({
    url: `${BASEURL}/web/doc/searchDoc`,
    data,
})
