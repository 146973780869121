import ProTable, { ActionType } from "@ant-design/pro-table"
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home"
import { productApi } from "@utils/util"
import { Button, Col, Modal, Popover, Row, Tabs, Tag, message } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { columnsFn, statusOptions, templateLibColumnsFn } from "./data"
import ProForm, { 
    ModalForm,
    ProFormText, 
    ProFormSelect,
    ProFormRadio,
    ProFormTextArea,
    ProFormDependency
} from '@ant-design/pro-form';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { SMSTemplateEditor } from "@components/Common"
import { smsTempAdd, smsTempApply, smsTempDel, smsTempGetTempTypes, smsTempInfo, smsTempPageList, smsTempPublicPageList, smsTempVarOptions } from "@api/product"
import { handleDvmCommonListResult, handleDvmCommonPageListResult, handleDvmCommonResult } from "@utils/request"
import { useSelector } from "react-redux"
import { StateType } from "@store/index"
import { SmsTempVarType, smsTempVarTypeOptions, SMSTEMPVAR_ALLVAR, SMSTEMPVAR_CAPTCHA, SMSTEMPVAR_CUSTOM, SMSTEMPVAR_NOVAR, smsTypeOptions, CERT_PERSON, CERT_PERSON_FAIL, CERT_COMPANY, CERT_COMPANY_FAIL, CERT_UPGRADE_FAIL, calcCertStatus } from "@utils/index"
import { calcVarMaxCountBySmsType, calcShowInsertBtn, handleSmsTempHtmlToData } from "@components/Common/SMSTemplateEditor"
import { useCommonApiHooks } from "@hooks/index"
import { BASEURL, headers } from "@api/index"
import { stringify } from "qs"

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 16 },
};

// 接口请求的 api 层
const getApi = productApi(smsTempPageList)  // 查询的接口封装
const getPublicApi = productApi(smsTempPublicPageList)
const addUpdateApi = productApi(smsTempAdd) // 列表数据的新增/编辑 接口
const getTempInfoApi = productApi(smsTempInfo) // 根据 utlId 获取模版信息
const delApi = productApi(smsTempDel) // 表单数据的删除 接口
const applyApi = productApi(smsTempApply) // 应用模版
const getListApi = handleDvmCommonPageListResult(getApi)
const smsTempVarOptionsApi = productApi(smsTempVarOptions)


const smsTempTypeOptions = [
    {
        label: '类型一(验证码变量)',
        value: 1,
        type: 1
    },
    {
        label: '类型一(自定义变量)',
        value: 2,
        type: 2
    },
    {
        label: '类型一(无变量)',
        value: 3,
        type: 3
    },
    {
        label: '类型一(全变量)',
        value: 4,
        type: 4
    },
]
// 短信模版库
const SMSTemplateLib = () => {
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getPublicApi({...params}, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }
    // const getListApi = handleDvmCommonPageListResult(getApi)

    const operate = async (type: any, record: any) => {
        const { utlId } = record || {}
        if (type === '应用该模版') {
            await applyApi({utlId}, true, onSuccess)
        }
    }

    const columns = templateLibColumnsFn(operate) // 表格列配置

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        console.log('处理后的成功回调触发了')
        reFresh()
    }
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    return (<ProTable<any>
        columns={columns}
        params={{utlSmsArea: 1 }} // 查询所需要的额外参数
        request={getListApi}
        options={{reload: false, density: false, setting: false}}
        toolbar={{
            actions: [],
        }}
        rowKey="id"
        pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
        actionRef={ref as any}
        search={{
            optionRender: (searchConfig, formProps, dom) => [ ...dom.reverse() ]
        }}
    />)
}

const PopoverContent = () => (<div style={{width : '500px'}}>
    ①如有变量，在内容输入框内插入变量，变量格式自动生成。<br/>

    <div dangerouslySetInnerHTML={{__html: '②变量格式是${ }，您可以修改{ }内的变量名称，支持英文、下划线。验证码类型支持插入一个变量。'}}></div>

    ③变量名称对应的变量替换值不能超过50个字符。<br/>

    ④同个模板内，变量名称不能重复。<br/>

    <span dangerouslySetInnerHTML={{__html: `⑤各变量类型规范说明：<br/>
                                                ·网址类型：输入字符不限制<br/>
                                                ·其他类型：不支持输入网址格式，如 https://XXX.com/，http://XXX.com，XXX.com 格式；“、，。.”这些符号后面第一个字符不能加字母.
                                            `
                                    }}>
    </span>
</div>)

interface SMSTemplateLibModalProps extends ModalFormProps {

}
// 模版库弹窗形式
const SMSTemplateLibModal = ({...rest}: SMSTemplateLibModalProps) => {
    return (<ModalForm {...rest}>
        <SMSTemplateLib />
    </ModalForm>)
}

// 新增编辑短信模版
type TemplateExtraProps = {
    type?: SmsTempVarType
}
const TemplateExtra = ({type}: TemplateExtraProps) => {
    switch (type) {
        case SMSTEMPVAR_CAPTCHA:
            return (<>
            <span dangerouslySetInnerHTML={{__html: `• 模版中不允许出现 "$"", 避免被识别为变量！`}}></span><br/>
            • 如有变量，请点击上方变量类型插入变量，不支持手动输入变量！<br/>
            <span dangerouslySetInnerHTML={{__html: `• 短信字数含"签名+模版内容+变量内容”，短信70个字数含以内，按1条短信计费；超出70个字为长短信，按照67个字数记为1条短信费用。`}}></span><br/>
            • 验证码模板只支持验证码作为变量；变量替换值4~6位数字或字母
            </>)
        case SMSTEMPVAR_CUSTOM:
            return (<>
            • <span style={{color: 'red'}}>如有变量，请点击上方变量类型插入变量，不支持手动输入变量！</span><br />
            <span dangerouslySetInnerHTML={{__html: `• 模版中不允许出现 "$"", 避免被识别为变量！`}}></span><br/>
            • <span dangerouslySetInnerHTML={{__html: '短信字数含"签名+模版内容+变量内容”，短信70个字数含以内，按1条短信计费；超出70个字为长短信，按照67个字数记为1条短信费用。'}}></span><br/>
            • 不能发送营销/贷款/借款/中奖/抽奖类短信,不支持金融理财&房产通知类短信。
            </>)
        case SMSTEMPVAR_NOVAR:
            return (<>
            <span dangerouslySetInnerHTML={{__html: `• 模版中不允许出现 "$"", 避免被识别为变量！`}}></span><br/>
            <span dangerouslySetInnerHTML={{__html: '• 短信字数含"签名+模版内容+变量内容”，短信70个字数含以内，按1条短信计费；超出70个字为长短信，按照67个字数记为1条短信费用。'}}></span><br/>
            • 短信支持退订（结尾自动加上“回T退订”，占模版内容4个字数）<br/>
            • 推广短信及群发助手不支持加变量<br/>
            • 推广短信建议在结尾加上空格或其他字符，便于区分营销内容<br/>
            • 不能发送贷款/借款/中奖/抽奖类短信,不支持金融理财&房产通知类短信。
            </>)
        case SMSTEMPVAR_ALLVAR:
            return (<>
            <span dangerouslySetInnerHTML={{__html: `• 模版中不允许出现 "$"", 避免被识别为变量！`}}></span><br/>
            <span dangerouslySetInnerHTML={{__html: '• 短信字数含"签名+模版内容+变量内容”，短信70个字数含以内，按1条短信计费；超出70个字为长短信，按照67个字数记为1条短信费用。'}}></span><br/>
            • 全变量类型下，模板是固定的，您只需命名模板名称，提交申请说明。<br/>
            • 一个模板名称对应一个模板code 。上传模板变量值时请使用正确的模板（选择对应的 模板code或者模板名称），并控制在500字符以内。<br/>
            <span dangerouslySetInnerHTML={{__html: `• 支持中英文和数字，并支持 ~·！!@#￥%‰&*（） ()-=_+——{}[]|:：；;「」'“”<>《》。·.,，?？/…这些特殊字符。`}}></span>
            </>)
        default:
            return null
    }
}
interface AddUpdateSMSTemplateProps extends ModalFormProps{
    submitParams?: any;
    onSuccess?: () => void;
}
const AddUpdateSMSTemplate = ({submitParams, onSuccess, ...rest}: AddUpdateSMSTemplateProps) => {
    const formRef = useRef<ProFormInstance>()
    const editorRef = useRef<any>(null)
    const { certInfo } = useSelector((store: StateType) => store?.userInfo) as any
    const { userId } = certInfo 
    // const [tempTypeOptions, , getApi] = useCommonApiHooks({url: `${BASEURL}/web/product/getTemplateRelation`, }, {}, true)
    const [smsTempTypeOptions, setSmsTempTypeOptions] = useState<any>([])
    // const [tempTypeOptions, , getApi] = useCommonApiHooks({url: `${BASEURL}/web/dvm/dvmPost`, headers, }, {}, true)
    // console.log('tempTypeOptions', tempTypeOptions)
    // const smsTempTypeOptions = (tempTypeOptions||[]).map((v: any) => {
    //     const {utlSmsType, utlParamType: type, utrName: label} = v
    //     return ({label, value: `${utlSmsType}-${type}`, type, ...v})
    // })

    const getTempTypeOptions = async () => {
        const { result } = await smsTempVarOptionsApi({})
        const tempTypeOptions = handleDvmCommonListResult(result, 'get_template_type')
        const smsTempTypeOptions = (tempTypeOptions||[]).map((v: any) => {
            const {utlSmsType, utlParamType: type, utrName: label} = v
            return ({label, value: `${utlSmsType}-${type}`, type, ...v})
        })
        setSmsTempTypeOptions(smsTempTypeOptions)
    }

    const onFinish = async (values: any) => {
        const { utlContent: html, utrId, ...rest } = values || {}
        const that = smsTempTypeOptions.find((v: any) => v.value == utrId) || {}
        const { content: utlContent } = handleSmsTempHtmlToData(html) as any
        const params = {...rest, userId, utlContent: that.utlParamType === SMSTEMPVAR_ALLVAR ? '${content}' : utlContent, ...that, utlOriginalText: html}
        const { success } = addUpdateApi({...params, ...(submitParams||{})}, true, onSuccess) as any
        return success
    }
    const onValuesChange = (value: any, allValues: any) => {
        if ('utrId' in value) {
            formRef.current?.setFieldValue('utlContent', '')
            editorRef?.current?.setValue('')
        }
    }
    useEffect(() => {
        getTempTypeOptions()
    }, [])
    return (<ModalForm<any>
        layout="horizontal" 
        {...layout} 
        onFinish={onFinish} 
        // request={request}
        formRef={formRef}
        onValuesChange={onValuesChange}
        {...rest}>
        <ProFormSelect 
            width="md" 
            name="utlSmsArea" 
            label="区域选择" 
            showSearch 
            initialValue={1}
            fieldProps={{
                // mode: 'multiple', 
                placeholder: '请选择区域', 
                showSearch: true, 
                filterOption: false
            }} 
            options={[{label: '中国区', value: 1}]} 
            rules={[{required: false, message: '请选择区域'}]} 
        />
        <ProFormText 
            width="md" 
            name="utlName" 
            label="模版名称"
            rules={[{ required: true, message: '请输入'}]} 
            placeholder="请输入" 
        />
        <ProFormRadio.Group
            name="utrId"
            label="模板类型"
            rules={[{ required: true, message: '请选择模版类型'}]} 
            request={async () => {
                return smsTempTypeOptions
            }}
        />
        <ProFormDependency name={['utrId']}>
            {
                ({utrId}) => {
                    const { type, utlSmsType } = smsTempTypeOptions.find((v: any) => v.value == utrId) || {}
                    return (<ProForm.Item name="utlContent" label="模版内容" extra={<TemplateExtra type={type||1} />}>
                        {
                            type === SMSTEMPVAR_ALLVAR ? 
                            <div dangerouslySetInnerHTML={{__html: '${content}'}}></div> : 
                            <SMSTemplateEditor 
                              showInsertBtn={calcShowInsertBtn(type)} 
                              type={type}
                              popoverContent={PopoverContent} 
                              ref={editorRef}
                              varMaxCount={calcVarMaxCountBySmsType(type)}
                            />
                        }
                    </ProForm.Item>)
                }
            }
        </ProFormDependency>
        <ProFormTextArea
            style={{width: '100%'}} 
            name="utlApplyDescribe" 
            label="申请说明"
            rules={[{ required: true, message: '请输入'}]} 
            placeholder="请描述详细的使用场景，并提供产品连接、App、公账号、公司标准全称等" 
            extra={<div>
                预计两小时完成审核。<br/>
                审核工作时间：周一至周日9：00-21：00。
            </div>}
        />
    </ModalForm>)
}

// 模版管理
export default () => {
    const { baseInfo, certInfo } = useSelector((store: StateType) => store?.userInfo) as any
    const { userId } = certInfo
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [modalProps, setModalProps] = useState<any>({visible: false, title: '添加模版', params: {}, onSuccess: null})
    const { userNature: type, verifyState: status } = (baseInfo || {}) as any
    const STATUS = calcCertStatus(type, status)
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    // const [smsTempTypeOptions, , getApi] = useCommonApiHooks({url: `${BASEURL}/web/product/getTemplateRelation`, }, {}, true)
    // operate 函数为增删改查需要处理的业务
    const operate = (type: any, record: any) => {
        const { utlId } = record || {}
        if (type === '新增') {
            // 需求方说 认证失败{个人/企业} 不允许新增
            if ([CERT_PERSON_FAIL, CERT_COMPANY_FAIL].includes(STATUS)) return message.warning('实名认证失败，不允许操作')
            setModalProps({
                ...modalProps,
                title: '添加模版',
                params: {_t: new Date().valueOf()},
                request: async() => {
                    return {
                        utlSmsArea: 1,
                        utlName: '',
                        utlContent: '',
                        utlSmsType: 1,
                        utlApplyDescribe: ''
                    }
                },
                visible: true,
                submitParams: {},
                onSuccess: () => {
                    setModalProps({
                        ...modalProps,
                        visible: false
                    })
                    reFresh()
                }
            })
        } else if (type === '修改') {
            setModalProps({
                ...modalProps,
                title: '修改模版',
                params: {utlId},
                submitParams: {utlId},
                request: async (params: any) => {
                    const {result} =  await getTempInfoApi(params, false)
                    const res = handleDvmCommonResult(result)
                    const {
                        utlSmsArea,
                        utlName,
                        utrId,
                        utlSmsType,
                        utlParamType,
                        utlOriginalText: utlContent,
                        utlApplyDescribe,

                    } = res || {}
                    // console.log(`${utlSmsType}-${utrParamType}`)
                    return {
                        utlSmsArea,
                        utlName,
                        utrId: `${utlSmsType}-${utlParamType}`,
                        utlContent,
                        utlSmsType,
                        utlApplyDescribe
                    }
                },
                visible: true,
                onSuccess: () => {
                    setModalProps({
                        ...modalProps,
                        visible: false
                    })
                    reFresh()
                }
            })
        } else if (type === '详情') {
            const { utlName, utlSmsType, utlParamType, utlVerifyState, utlCode, createTime, utlContent, utlApplyDescribe } = record || {}
            const { label } = smsTypeOptions.find((v: any) => v.value == utlSmsType) || {}
            const { label: statusStr } = statusOptions.find((v: any) => v.value === utlVerifyState) || {}
            const { label: utrParamLabel } = smsTempVarTypeOptions.find(v => v.value === utlParamType) || {}
            Modal.info({
                title: '详情信息',
                content: (
                  <div>
                    <div><span style={{display: 'inline-block', width: 100}}>模板名称: </span>{utlName}</div>
                    {/* 冰冰说: 把模版类型改成短信类型和变量类型，bug链接: http://192.168.8.213/zentao/bug-view-3111.html */}
                    {/* <div><span style={{display: 'inline-block', width: 100}}>模板类型: </span>{label}</div> */}
                    <div><span style={{display: 'inline-block', width: 100}}>短信类型: </span>{label}</div>
                    <div><span style={{display: 'inline-block', width: 100}}>变量类型: </span>{utrParamLabel}</div>
                    <div><span style={{display: 'inline-block', width: 100}}>模板CODE:</span>{utlCode}</div>
                    <div><span style={{display: 'inline-block', width: 100}}>创建时间: </span>{createTime}</div>
                    <div><span style={{display: 'inline-block', width: 100}}>审核状态: </span>{statusStr}</div>
                    <div><span style={{display: 'inline-block', width: 100}}>模板内容: </span>{utlContent}</div>
                    <div><span style={{display: 'inline-block', width: 100}}>申请说明: </span>{utlApplyDescribe}</div>
                  </div>
                ),
                onOk() {},
            })
        } else if (type === '删除') {
            const utlIds = record ? [utlId] : selectedRowKeys
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                centered: true,
                cancelText: '取消',
                onOk: async() => {
                    await delApi({utlIds: utlIds.toString()}, true, () => {
                        reFresh()
                    })
                },
            });
        }
    }

    // const getListApi = async (params: any, sorter: any, filter: any) => {
    //     const { data } = await getApi({...params}, false)
    //     const { values, total } = data || {}
    //     return {
    //         data: values || [],
    //         success: true,
    //         total
    //     }
    // }
    // const getListApi = handleDvmCommonPageListResult(getApi)

    const columns = columnsFn(operate) // 表格列配置

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        console.log('处理后的成功回调触发了')
        setModalProps({
            ...modalProps,
            visible: false
        })
        reFresh()
    }
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const calcDelDisabled = () => selectedRowKeys.length
    return (<>
    <ProTable<any>
        style={{flex: 1, overflow: 'scroll'}}
        scroll={{x: 1300}}
        columns={columns}
        params={{ userId, utlSmsArea: 1 }} // 查询所需要的额外参数
        request={getListApi}
        options={{reload: false, density: false, setting: false}}
        toolbar={{
            actions: [
                // 慧兰说先隐藏
                // <SMSTemplateLibModal 
                //   width={1200} 
                //   layout="horizontal" 
                //   key="btn0" 
                //   title="模版库" 
                //   onFinish={async () => {
                //     reFresh()
                //     return true
                //   }}
                //   trigger={<Button  type='primary'>模版库</Button>} 
                // />,
                <Button key="btn1" type='primary' onClick={() => operate('新增', null)}>添加模版</Button>,
                <Button key="btn2" type='primary' disabled={!calcDelDisabled()} onClick={() => operate('删除', null)}>删除</Button>,
            ],
        }}
        rowKey="utlId"
        pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
        actionRef={ref as any}
        search={{
            optionRender: (searchConfig, formProps, dom) => [ ...dom.reverse() ]
        }}
        rowSelection={{
            preserveSelectedRowKeys: true,
            onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
        }}
    />
    <AddUpdateSMSTemplate 
        modalProps={{
            maskClosable: false, 
            destroyOnClose: true,
            onCancel: () => setModalProps({...modalProps, visible: false})
        }}
        {...modalProps}
    />
    </>)
}
