import ProTable, { ActionType } from "@ant-design/pro-table"
import React, { useEffect, useRef, useState } from "react"
import { cacheTable, OrignReqHeader, tooltipData } from "../../data"
import { SwitchAndMsg, TitleBar } from "./cpnts"
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { Button, message, Modal, Table } from "antd"
import ProForm, {
    ModalForm,
    ProFormText,
    ProFormSelect,
    ProFormRadio,
    ProFormFieldSet,
    ProFormDigit,
    ProFormCascader,
    ProFormTextArea,
    ProFormDateTimePicker,
    ProFormCheckbox,
    ProFormUploadButton
} from '@ant-design/pro-form';
import {
  QuestionCircleOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  CopyOutlined
} from '@ant-design/icons'; 
import { ProFormDependency } from '@ant-design/pro-form';
import FormItem from "antd/es/form/FormItem";
import { useLocation } from "react-router-dom"
import { filterObj, parseSearch, productApi } from "@utils/util"
import { cdnDomainConfigurationRule, cdnDomainConfiguration, cdnUpDomainConfiguration, cdnSaveDomainConfigurationRule, cdnDelDomainConfigurationRule } from "@api/product"
const { confirm } = Modal;


const getListApi = productApi(cdnDomainConfigurationRule)  // 查询缓存规则表格
const getConfigApi = productApi(cdnDomainConfiguration)  //  查询缓存配置
const setApi = productApi(cdnSaveDomainConfigurationRule)  //  新增规则
const submitConfigApi = productApi(cdnUpDomainConfiguration)  //  提交缓存配置
const delApi = productApi(cdnDelDomainConfigurationRule)  // 缓存配置删除

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

//增加回源配置
const AddDomainModal = ({ params, onSuccess, ...rest }: any) => {
    const formRef = useRef<ProFormInstance>();


    const onFinish = async (values: any) => {
        const data = { 
            ...values,
            ...params,
            content:values.content || ''
        }
        const { success } = await setApi(
           data,
            true,
            () => {
                clearData()
                onSuccess ? onSuccess() : null
            }
        )
        return success
    }
   
      const clearData = () => {
        formRef.current?.resetFields()
      }
    return (
      <ModalForm<any>
        layout="horizontal"
        width={600}
        formRef={formRef}
        {...layout}
        onFinish={onFinish}
        preserve={false}
        modalProps={{
          onCancel: clearData,
        }}
        {...rest}
      >
        {/* 源站类型 */}
        <ProFormSelect
          width="md"
          name="type"
          label="类型"
          initialValue={1}
          fieldProps={{
            allowClear: false,
            placeholder: "请选择类型",
            defaultValue: 1,
          }}
          tooltip={tooltipData["YZLX"]}
          options={[
            {
              value: 1,
              label: "文件名后缀",
            },
            {
              value: 2,
              label: "目录路径",
            },
            {
              value: 3,
              label: "全路径",
            },
            {
              value: 4,
              label: "首页",
            }
          ]}
          rules={[{ required: false, message: "请选择类型" }]}
        />
        
        <ProFormDependency name={["type"]}>
          {({ type }: any) => {
            return (
              <>
                {type !== 4 ? <ProFormText
                  width="md"
                  name="content"
                  rules={[{ required: true, message: "请输入内容" }]}
                  label="内容"
                  placeholder={[
                    '',
                    '例如: .jpg;.zip;.exe',
                    '例如: /test/folder01',
                    '例如: /test/index.html或/test/*.jpg'][type]}
                />: null}
              </>
            );
          }}
        </ProFormDependency>
        <ProFormDigit
          width="md"
          name="priority"
          rules={[{ required: true, message: "请输入优先级" }]}
          label="优先级"
          fieldProps={{
            precision:0
          }}
          
          max={100}
          min={1}
          placeholder="请输入优先级"
        />
        
        <ProFormFieldSet
          name="list"
          initialValue={[ '', 1]}
          label="缓存过期时间"
          type="group"
          rules={[
            { message: '请输入缓存过期时间',
              validator: (_, value) => {
              const [name, name1] = value || [];
              if (!name) {
                throw new Error('第一个值不能为空');
              }
              if (!name1) 
              {
                throw new Error('第二个值不能为空');
              }
              return Promise.resolve()
            },}
          ]}
          transform={(value: any) => ({ ttl: value[0], ttlType: value[1] })}
        >
          <ProFormDigit width={180} />
          <ProFormSelect
              fieldProps={{
                allowClear: false,
              }}
              options={[
                {
                  value: 1,
                  label: "秒",
                },
                {
                  value: 2,
                  label: "分",
                },
                {
                  value: 3,
                  label: "小时",
                },
                {
                  value: 4,
                  label: "天",
                },
              ]}
        />
        </ProFormFieldSet>

      </ModalForm>
    );
}


//缓存配置修改
const EditDomainModal = ({ params, defaultValue, onSuccess, ...rest }: any) => {
  const formRef = useRef<ProFormInstance>();
  const onFinish = async (values: any) => {
      const data = { 
          ...values,
          ...params,
      }
      const { success } = await setApi(
          filterObj(data),
          true,
          () => {
              clearData()
              onSuccess ? onSuccess() : null
          }
      )
      return true
  }
  
  // useEffect(()=> {
  //   console.log(2333, defaultValue, formRef.current)
  //   const {action, name, value } = defaultValue || {}
  //   console.log(2333, {action, name, value }, formRef.current)

  //   formRef.current?.setFieldsValue({action, name, value });
  // }, [defaultValue])
 
    const clearData = () => {
      formRef.current?.resetFields()
    }
  return (
    <ModalForm<any>
      layout="horizontal"
      width={600}
      {...layout}
      onFinish={onFinish}
      preserve={false}
      modalProps={{
        onCancel: clearData,
      }}
      {...rest}
      formRef={formRef}
    >
     
     <FormItem
          label="内容"
        >
           全部文件
        </FormItem>
        <ProFormDigit
          width="md"
          name="priority"
          rules={[{ required: true, message: "请输入优先级" }]}
          label="优先级"
          fieldProps={{
            precision:0
          }}
          max={100}
          min={1}
          placeholder="请输入优先级"
        />
        <ProFormFieldSet
          name="list"
          initialValue={[ , 1]}
          label="缓存过期时间"
          type="group"
          rules={[
            {
              message: '请输入缓存过期时间',
              validator: (_, value) => {
                const [name, name1] = value || [];
                if (!name) {
                  throw new Error('第一个值不能为空');
                }
                if (!name1) 
                {
                  throw new Error('第二个值不能为空');
                }
                return Promise.resolve()
              },
            },
          ]}
          transform={(value: any) => ({ ttl: value[0], ttlType: value[1] })}
        >
          <ProFormDigit width={180} />
          <ProFormSelect
              fieldProps={{
                allowClear: false,
              }}
              options={[
                {
                  value: 1,
                  label: "秒",
                },
                {
                  value: 2,
                  label: "分",
                },
                {
                  value: 3,
                  label: "小时",
                },
                {
                  value: 4,
                  label: "天",
                },
              ]}
        />
        </ProFormFieldSet>
    </ModalForm>
  );
}

export default () => {
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    const formRef = useRef<ProFormInstance>()
    const [modalProps, setModalProps] = useState({visible: false, title: '新增', params: {}, request: {}})
    const [data, setData] = useState({} as any)
    const { pathname, search } = useLocation();
    const { domainId } = parseSearch(search)
    
    const operation = async (query?: any) => {
       const { success } = await submitConfigApi(query, true) 
       success && getData()
    }

    const operations = (type: string, data:any)=> {
      const {priority, ttl, ttlType, id } = data || {}

       if (type === '编辑'){
        console.log(222, data)
          setModalProps({
            ...modalProps,
            request: async() => {
              return {priority, list: [ttl, ttlType] }

            },
            title: '编辑',
            params: {id, domainId},
            visible: true,
          })
       } else if(type === '删除'){
          confirm({
            title: '确定删除这些吗?',
            icon: <ExclamationCircleOutlined />,
            content: '点击确定将删除',
            centered: true,
            onOk: async () => {
              await delApi({ ...data, domainId }, true)
              ref.current?.reload()
            }
          });
      } else if(type === '新增'){
        setModalProps({
          ...modalProps,
          title: '新增',
          request: async() => {
            return {action: 'set', name: '', value: '' }
          },
          params: {domainId},
          visible: true,
        })
     }
    }

    const getList = async (params: any, sorter: any, filter: any) => {
        const { data } = await getListApi({...params}, false)
        const {  values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }
    const getData = async ()=> {
      const { data } = await getConfigApi({domainId}, false) 
      setData(data)
    }
    
    useEffect(()=> {
      getData()
    }, [])
    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        ref.current?.reload()
    }

    return (<div>
       
        <TitleBar title='缓存规则'  tooltip={ tooltipData.HCGZ} />
        <ProTable<any>
        // style={{flex: 1, overflow: 'scroll'}}
        // scroll={{x: 1300}}
        columns={cacheTable(operations)}
        params={{domainId}} // 查询所需要的额外参数
        request={getList}
        options={{reload: false, density: false, setting: false}}
        toolbar={{
            actions: [
              <AddDomainModal params={{domainId}} onSuccess={onSuccess} key="btn0" title="新增规则" trigger={<Button type='primary'>新增规则</Button>} />,
            ],
        }}
        // rowKey="tiId"
        pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
        actionRef={ref as any}
        formRef={formRef}
        search={false}
        rowSelection={false}
    />
     <TitleBar title='URL参数:'  tooltip={tooltipData.URLCS}  />
        <SwitchAndMsg
          label='忽略指定URL参数：'
          checked= {!!data.ignoreUrlParameter}
          onChange={(data: any) => operation({domainId,  ignoreUrlParameter: data * 1})}
        />
        <TitleBar title='缓存遵循源站'  tooltip={ tooltipData.HCZXYZ} />

        <SwitchAndMsg
            label='缓存遵循源站：'
            checked= {!!data.compressSwitch}
          onChange={(data: any) => operation({domainId,  compressSwitch: data * 1})}
          alertMsg='开启后，缓存过期时间将遵循源站Cache-Control:max-age配置；关闭后，缓存过期时间将遵循自定义缓存规则。'
        />
        <TitleBar title='智能压缩'  tooltip={ tooltipData.ZNYS} />
        <SwitchAndMsg
           label='Gzip压缩：'
           checked= {!!data.compress}
          onChange={(data: any) => operation({domainId,  compress: data * 1})}
          alertMsg='自动压缩格式为 .js、.html、.css、.xml、.json、.shtml、.htm的文件，可减少用户传输内容大小，提升传输效率。'
        />

       <EditDomainModal  modalProps={{
            maskClosable: false, 
            destroyOnClose: true,
            onCancel: () => setModalProps({...modalProps, visible: false})
        }}
        onSuccess={onSuccess} 
        {...modalProps} />,

    </div>)
}
