import request from "@utils/request";
import { stringify } from "qs";
import { BASEURL, MockUrl } from "./baseUrl";
import { headers } from "./login";

// 获取ip 白名单
export const toolWhiteListPageList = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'toolsWhitelist:query'}),
})

// ip白名单添加
export const toolWhiteListAdd = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'toolsWhitelist:add'}),
})

// IP白名单 修改状态
export const toolWhiteListUpdateStatus = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'toolsWhitelist:updateStatus'}),
})

// ip白名单删除
export const toolWhiteListDel = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'toolsWhitelist:del'}),
})

// 号码分流
export const toolPhoneShunt = (data?: any) => request({
    url: `${BASEURL}/web/tool/importPhoneClassifyData`,
    headers,
    data
})
