import { ProColumns } from "@ant-design/pro-table";
import { DateRangeWithDisabled, Icon } from "@components/Common";
import { Input, Select, Tooltip } from "antd";
import moment from "moment";
import React, { ReactNode } from "react";

const options = [{label: '收入', value: 2}, {label: '支出', value: 1}]
const tradeTypeOptions = [
    {
        label: '自充值',
        value: 1
    },
    {
        label: '代充值',
        value: 2
    },
    {
        label: '系统赠送',
        value: 3
    },
    {
        label: '购买套餐包',
        value: 10
    },
    {
        label: '余额扣费',
        value: 11
    },
    {
        label: '失败回补',
        value: 20
    },
    {
        label: '订单退款',
        value: 30
    },
    {
        label: '账户退款',
        value: 50
    },
]

/**
 * 列表表格配置:
 *  自定义表格 CustomerDemo 和 antd 升级版组件 ProTableDemo 都可使用, 
 * */

export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        // 备注: 臧强说: 强哥跟产品沟通过 交易编号不要了(口头通知的，但是原型没去掉，以此为证) -22.11.15
        // {
        //     title: '交易编号',
        //     dataIndex: 'transactionCode',
        //     key: 'transactionCode',
        // },
        {
            title: '交易时间',
            dataIndex: 'transactionTime',
            key: 'transactionTime',
            valueType: 'dateRange',
            formItemProps: {
                initialValue: [moment().add(0, 'days'), moment()],  
            },
            search: {
                transform: (value) => {
                    return {
                        startTime: value[0] ? value[0] + ' 00:00:00' : undefined,
                        endTime: value[1] ? value[1] + ' 23:59:59' : undefined
                    }
                },
            },
            renderFormItem: (props) => <DateRangeWithDisabled />,
            render: (text: any, record: any) => record.transactionTime,
        },
        {
            title: '收支类型',
            dataIndex: 'expenseType',
            key: 'expenseType',
            valueType: 'select',
            request: async () => options,
        },
        {
            // title: '交易类型',
            title: (config, type) => type === 'table' ? <span>交易类型 <Tooltip title={(<div>
                （1）自充值：客户通过线上进行账户充值。<br />
                （2）代充值：由平台运营人员在管理后台代客户进行账户充值。<br />
                （3）购买套餐包：客户在平台购买产品套餐包，扣除账户余额。<br />
                （4）余额扣费：系统按产品实际使用量扣除账户余额。<br />
                （5）失败回补：部分产品在调用接口时，系统实时扣费，接口调用失败，系统将已扣除的费用回退到账户余额中。<br />
                （6）订单退款：由于订单退款产生的金额自动回退到账户余额中。<br />
                （7）账户退款：客户要求账户余额提现，由后台运营人员操作退款。
            </div>)}><Icon type="icon-ziyuan" style={{marginLeft: 6}} /></Tooltip></span> : '交易类型',
            dataIndex: 'transactionType',
            key: 'transactionType',
            valueType: 'select',
            fieldProps: {
                options: tradeTypeOptions
            },
            // tooltip: (<div>
            //     （1）自充值：客户通过线上进行账户充值。<br />
            //     （2）代充值：由平台运营人员在管理后台代客户进行账户充值。<br />
            //     （3）购买套餐包：客户在平台购买产品套餐包，扣除账户余额。<br />
            //     （4）余额扣费：系统按产品实际使用量扣除账户余额。<br />
            //     （5）失败回补：部分产品在调用接口时，系统实时扣费，接口调用失败，系统将已扣除的费用回退到账户余额中。<br />
            //     （6）订单退款：由于订单退款产生的金额自动回退到账户余额中。<br />
            //     （7）账户退款：客户要求账户余额提现，由后台运营人员操作退款。
            // </div>)
        },
        {
            title: '金额（元）',
            dataIndex: 'clouds',
            key: 'clouds',
            hideInSearch: true,
        },
        {
            title: '当前余额（元）',
            dataIndex: 'amount',
            key: 'amount',
            hideInSearch: true,
        }
    ]
}