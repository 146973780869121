import ProTable, { ActionType } from "@ant-design/pro-table"
import React, { useRef, useState, useEffect } from "react"
import { domainColumnsFn_domain, domainColumnsFn_ip, editDomainColumnsFn_domain, editDomainColumnsFn_ip, OrignDatacolumns, sourceType, tooltipData, valueEnumStatus } from "../../data"
import { DescriptionsPro, TitleBar } from "./cpnts"
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { Button, message, Table } from "antd"
import ProForm, {
    ModalForm,
    ProFormText,
    ProFormSelect,
    ProFormRadio,
    ProFormCascader,
    ProFormTextArea,
    ProFormDateTimePicker,
    ProFormCheckbox,
    ProFormUploadButton
} from '@ant-design/pro-form';
import { ProFormDependency } from '@ant-design/pro-form';
import FormItem from "antd/es/form/FormItem";
import { filterObj, parseSearch, productApi } from "@utils/util"
import { cdnGetDomainDetail, cdnPageDomainSourceByDomainId, cdnSaveOrUpdateDomainOrigin, cdnUpdateBackHost } from "@api/product"
import { useLocation } from "react-router-dom"


// 接口请求的 api 层
const getApi = productApi(cdnPageDomainSourceByDomainId)  // 源站信息查询的接口封装
const getDetailApi = productApi(cdnGetDomainDetail)  // 基础信息查询的接口封装
const submitApi = productApi(cdnSaveOrUpdateDomainOrigin)  // 新增编辑源站提交事件
const cdnUpdateBackHostApi = productApi(cdnUpdateBackHost)  // 修改回源HOST

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
};

// 子组件---输入项---源站地址
const DomainAddress = ({formRef,data, domainType}:any) => {
    const [tableData, setTableData] = useState([
      {ipOrDomain: '',
      portHttp: '',
      portHttps: '',
      activeStandby: 0}
    ])
    

    useEffect(()=>{
      if(data.length){
        setTableData(data)
        setFormValue(data)
      }
    }, [data])

    // 表格修改后立刻同步值
    const setFormValue = (data: any) => {
      formRef.current?.setFieldsValue({domainOrigins: data })
    }

    const operate = (type: any, record: any) => {
        const { id, index, checked } = record || {}
        // 新增
        if (type === 'add') {
            setTableData([...tableData, {
                ipOrDomain: '',
                portHttp: '',
                portHttps: '',
                activeStandby: 0
            }])
           // 数据改变之后立即设置表单数据
        setFormValue([...tableData, {
          ipOrDomain: '',
          portHttp: '',
          portHttps: '',
          activeStandby: 0
      }])
        // 删除
        } else if (type === 'del') {
            const data = [...tableData]
            data.splice(index, 1)
            setTableData(data)
           // 数据改变之后立即设置表单数据
        setFormValue(data)
        // 修改域名 , 'http', 'https'
        } else if (['修改域名', 'HTTP', 'HTTPS'].includes(type)) {
            const data = [...tableData]
            data[index] = record
            setTableData(data)
           // 数据改变之后立即设置表单数据
        setFormValue(data)
        } else if (type === '源站优先级') {
          // 修改源站优先级状态
          if (checked) {
            const data = [...tableData]
            data.forEach(element => {
              element.activeStandby = 0
            })
            data[index].activeStandby = 1
            setTableData(data)
            // 数据改变之后立即设置表单数据
            setFormValue(data)
          } else {
            message.info('主源站无法关闭，请直接开启您要设置的源站')
          }
         
        }
        
    }
    const columns_ip = editDomainColumnsFn_domain(operate, tableData) as any // 表格列配置
    const columns_domain = editDomainColumnsFn_ip(operate, tableData) as any // 表格列配置
    return (
        <div>
            <Table dataSource={tableData} pagination={false} columns={domainType ? columns_domain : columns_ip} />
        </div>
    )
}


//编辑源站弹窗组件
const EditDomainModal = ({params, tableData = [], onSuccess, ...rest }: any) => {
    const formRef = useRef<ProFormInstance>();
    const formRefInputAddress = useRef<ActionType>(null); // ProTable 组件的 ref
    const [sourceName, setSourceName] = useState('')
    const [sourceTestValue, setSourceTestValue] = useState('')
    const [testResult, setTestResult] = useState(false)
    
    const onFinish = async (values: any) => {
        const data = { 
            ...values,
            ...params,
        }
        const { success } = await submitApi(
            filterObj(data),
            true,
            () => {
                clearData()
                onSuccess ? onSuccess() : null
            }
        )
        return true
    }
    
     
      const clearData = () => {
        formRef.current?.resetFields()
      }
    return (
      <ModalForm<any>
        layout="horizontal"
        width={1100}
        formRef={formRef}
        {...layout}
        onFinish={onFinish}
        preserve={false}
        modalProps={{
          onCancel: clearData,
        }}
        {...rest}
      >
       
        {/* 源站类型 */}
        <ProFormSelect
          width="md"
          name="originType"
          label="选择源站类型"
          initialValue={1}
          fieldProps={{
            allowClear: false,
            placeholder: "请选择源站类型",
            defaultValue: tableData[0] ? tableData[0].originType : 1,
          }}
          tooltip={tooltipData['YZLX']}
          options={sourceType}
          rules={[{ required: false, message: "请选择源站类型" }]}
        />

        <ProFormDependency
            name={["originType"]}
          >
            {({ originType }: any) => {
              return (
                <>
                  {/* 源站地址输入项 */}
                  <FormItem
                    name="domainOrigins"
                    rules={[{ required: true, message: "请输入源站地址" }]}
                    label="源站地址"
                  >
                    <DomainAddress
                      domainType={originType}
                      formRef={formRef}
                      data={tableData}
                    />
                  </FormItem>
                </>
              )
            }}
          </ProFormDependency>
      </ModalForm>
    );
}


// 修改回源host 弹窗组件
const EditHostModal = ({ data, params, onSuccess, ...rest }: any) => {
    const formRef = useRef<ProFormInstance>();
    const [sourceName, setSourceName] = useState('')
    const [sourceTestValue, setSourceTestValue] = useState('')
    const [testResult, setTestResult] = useState(false)

   
   
    const onFinish = async (values: any) => {
        const data = { 
            ...values,
            ...params,
        }
        const { success } = await cdnUpdateBackHostApi(
            filterObj(data),
            true,
            () => {
                clearData()
                onSuccess ? onSuccess() : null
            }
        )
        return success
    }

   
      const clearData = () => {
        formRef.current?.resetFields()
      }
    return (
      <ModalForm<any>
        width={500}
        formRef={formRef}
        request= {
          ()=> {
           return data
          }
        }
        layout={{
          labelCol: { span: 4 },
          wrapperCol: { span: 21 },
      }}
        onFinish={onFinish}
        preserve={false}
        modalProps={{
          onCancel: clearData,
        }}
        {...rest}
      >
       
       <ProFormText
          width="md"
          name="backHost"
          rules={[{ required: true, message: "请输入回源HOST" }]}
          label="回源HOST："
        //   fieldProps={{
        //     onBlur: (e)=> domainNameChange(e)
        //   }}
          tooltip={tooltipData['HYHOST']}
          placeholder="请输入回源HOST"
        />
      </ModalForm>
    );
}

export default () => {
    const [basicInfo, setBasicInfo] = useState([]) as any
    const [tableData, setTableData] = useState([]) as any

    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    const formRef = useRef<ProFormInstance>()
    const { pathname, search } = useLocation();
    const { domainId } = parseSearch(search)
    const getData = async () => {
      const { data } = await getDetailApi({domainId}, false) as any
      const {domainStatus} =  data as {domainStatus: 1|2|3|4|5|6|7} || {}
      const {businessType} =  data as {businessType: 1|2|3|4} || {}
      const {serviceArea} =  data as {serviceArea: 1|2|3} || {}
      
      
      const { cname, createTime, domainName, httpsStatus, ipv6Status } = data as any || {}
        setBasicInfo([
          {
            label: '域名',
            text: domainName
          },
          {
            label: '状态',
            text: valueEnumStatus[domainStatus].text
          },
          {
            label: 'CNAME',
            text: cname,
            copy: true
          },
          {
            label: '创建时间',
            text: createTime
          },
          {
            label: '加速类型',
            text: {1:'网页加速',2:'文件下载加速',3:'点播加速',4:'全站加速'}[businessType]
          },
          {
            label: 'HTTPS',
            text: httpsStatus ? '打开': '关闭'
          },
          {
            label: '加速区域',
            text: {1:'中国境内',2:'中国大陆境外',3:'全球'}[serviceArea || 1]
          }])
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params}, false)
        const {  values, total } = data || {}
        setTableData(values)
        return {
            data: values || [],
            success: true,
            total: 1
        }
    }

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        ref.current?.reload()
    }

    useEffect(() => {
       getData()  
    }, [])
 
    return (<div>
        <TitleBar title={'基础信息'}/>
        <DescriptionsPro list={basicInfo} column={2} />
        <TitleBar title={'源站信息'} tooltip={tooltipData.YZXX}/>
        <ProTable<any>
        // style={{flex: 1, overflow: 'scroll'}}
        // scroll={{x: 1300}}
        columns={OrignDatacolumns as any}
        params={{domainId}} // 查询所需要的额外参数
        request={getListApi}
        options={{reload: false, density: false, setting: false}}
        toolbar={{
            actions: [
                <EditDomainModal params={{domainId}} tableData={tableData}  onSuccess={onSuccess} key="btn0" title="编辑源站" trigger={<Button type='primary'>编辑源站</Button>} />,
                <EditHostModal params={{domainId}} data={{backHost: tableData[0]?.backOriginHost}} onSuccess={onSuccess} key="btn0" title="修改回源HOST" trigger={<Button type='primary'>修改回源HOST</Button>} />,
            ],
        }}
        // rowKey="tiId"
        pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
        actionRef={ref as any}
        formRef={formRef}
        search={false}
        rowSelection={false}
    />
    </div>)
}
