import { getToken, productApi, request } from "@utils/index";
import { RequestOptions } from "@utils/request/requestInterface";
import { useEffect, useRef, useState } from "react";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "@store/index";
import { asyncSetTaskObj } from "@store/actions";
import { fileExportUrl } from "@api/baseUrl";
import { throttle } from "lodash";
import { debounce } from "lodash";

const { confirm } = Modal;

type DisabledRule = (v: any) => boolean
type CommonApiHooks<T> = (options: RequestOptions, param: T, isUseEffect: boolean, disabled?: DisabledRule) => [any, any, (arg: T) => Promise<any[]>]
export const useCommonApiHooks: CommonApiHooks<Object> = (options = {}, param = {}, isUseEffect = true) => {
    const [data, setData] = useState<any[]>([])
    const api = productApi((data?: object) => request({...options, data}))

    const getApi = async (param: Object) => {
        return await api(param, false, (data: any) => {
            setData(data)
        })
    }
    isUseEffect && useEffect(() => {
        getApi(param)
        return () => {
            setData([])
        }
    },[])

    return [data, setData, getApi]
}

export const useTableHooks = (api: any, defaultParams: Record<string, any> = {}, isInitQuery: boolean = true) => {
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [lastParams, setLastParams] = useState({})

    const fetchApi = async (params: Record<string, any> = {}, callBack?: Function) => {
        setLoading(true)
        const { data } = await api({...defaultParams, ...params})
        setLastParams({...defaultParams, ...params})
        setLoading(false)
        const {pageCurrent: current, total, pageSize, values } = (callBack ? callBack(data||{}) : data) || {}
        setDataSource([...(values||[])])
        setPagination({current, pageSize, total})
    }

    isInitQuery && useEffect(() => {
        fetchApi()
    }, [])

    return {
        setDataSource,
        setLoading,
        setPagination,
        fetchApi,
        tableProps: {
            dataSource,
            loading,
            pagination,
            onChange: ({current: pageCurrent, pageSize}: any, filters: any, sorter: any) => {
                fetchApi({...lastParams, pageCurrent, pageSize})
            }
        }
    }
}

export const useAsyncTask = () => {
    const { taskObj } = useSelector((state: StateType) => state) as any;
    const dispatch = useDispatch();
    const timer = useRef<any>(null);
    useEffect(() => {
        const taskIds = Object.keys(taskObj)
        const loadingTaskIds = taskIds.filter(taskId => {
            const url = fileExportUrl(taskObj[taskId].url)
            taskObj[taskId].finish && url && window.open(url)
            return !taskObj[taskId].finish
        }) // 已经完成的自动下载，未完成的导出任务ids
        
        loadingTaskIds.length && (timer.current = setTimeout(async () => {
            dispatch(asyncSetTaskObj(taskIds))
        }, 3000))
        return () => {
            timer.current && clearTimeout(timer.current)
        }
    }, [taskObj])
}

export const useAnimate = (str: String = 'shake') => {
    const [className, setClassName] = useState('')
    const onClick = () => {
        setClassName(`animated infinite ${str}`)
        setTimeout(() => {
            setClassName('')
        }, 1000)
    }
    return [className, onClick]
}
