
import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { DownOutlined } from '@ant-design/icons';
import { Button, Alert, Modal, Dropdown, Drawer } from 'antd';
import { menuList, testProDetailedList } from './data'
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { homeGetHotProduct } from "@api/home";
import { handleDvmCommonPageListResult, TOKENKEY } from "@utils/request";
import { getToken, isBrowser, removeStorage, setHref, sliceText } from "@utils/util";
import { CertificationLink } from "@components/AccountCenter/SafeSetting";
import { CERT_SUCCESS, CERT_UNCERT, CERT_UPGRADE } from "@utils/enum";
import { useCacheDispatch, } from 'react-keepalive-router'
import { ChargeModal } from "@components/CostCenter/Charge";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.less'
import { stringify } from "qs";
import logo from '@assets/images/logo.png'
import hotNew from '@assets/images/hot&new.png';
import { fileHttpUrl } from "@api/baseUrl";
import { MenuIcon } from "@components/Common";
const { confirm } = Modal;

const LogoImg = () => {
  const { ueSaasLogo, ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
  return (<div className="j-home-header-logo">
    <a href="/">
      <img
        className="home-header-logo"
        src={fileHttpUrl(ueSaasLogo || '/fileServer/2022/11/29/edffcd3f235148b8a012796cf18e15ed.png')}
        alt={ueSaasName}
      />
    </a>
  </div>)
}

// 格式化产品list数据，计算高度分为三列数据然后生成页面
const formatProList = (list: Array<any> = []) => {
  const arr = [[], [], []] as any
  list.forEach(element => {
    const temporaryList = [{ ...element, type: 'title', }];
    (element.children || []).forEach((element2: any) => {
      temporaryList.push({ ...element2, type: 'child' })
    });
    arr[findMinColumn(arr)].push(...temporaryList)
  });
  return arr
}

// 找出list中数量最少的列
const findMinColumn = (arr: Array<any>) => {
  const flag = {
    length: 999,
    index: 0
  }
  arr.forEach((element, index) => {
    if (element.length < flag.length) {
      flag.length = element.length
      flag.index = index
    }
  });
  return flag.index
}


// 整个弹出区域，div放在菜单底部，通过css控制悬浮之后高度变化
const EjectDiv = ({ type }: any) => {
  const [proTypeData, setProTypeData] = useState([] as any)
  const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
  const [serviceText, setServiceText] = useState('')
  const [proData, setProData] = useState({ type: 'hot' } as any)
  const [serviceData, setServiceData] = useState([] as any)
  const { treeData = [] } = useSelector((store: StateType) => store?.productData) as any

  //  查询产品数据
  const getProTypeData = async () => {
    const api = handleDvmCommonPageListResult(homeGetHotProduct)
    const { data } = await api({
      piIsRecommend: 1,
      piPublishState: 1,
      pageNum: 1,
      pageSize: 99,
    }, {}, {})
    setProTypeData([{ title: '热门推荐',ptEnglishName: 'Hot Recommended', type: 'hot', children: data }])
    setProData({ ...proData, list: data })
  }


  useEffect(() => {
    getProTypeData()
    setServiceText(testProDetailedList(ueSaasName).serviceText)
  }, [])
  return (
    <div className="ejectDiv shadow-lg text-left">
      <div className="left"  style={{ background: `url(${fileHttpUrl('fileServer/2022/11/28/ca5d5980476e4a3fa0a7fa4ab39d59cb.png')}) no-repeat` }}>
        {type === 'product' ? <ProTypeDiv click={setProData} proTypeList={[...proTypeData, ...treeData]} /> : <ServiceDiv serviceText={serviceText} />}
      </div>
      <div className="center">
        {proData.type === 'hot' ? <HotProColum proList={proData.list} /> : <MenuColumn proList={proData} />}
      </div>
      <div className="right">
        {testProDetailedList(ueSaasName).cardList.map((element, index) => {
            return <CardDiv key={index} data={element} />
          })}
      </div>
    </div>
  )
}
// 活动小卡片
const CardDiv = ({ data }: any) => {
  return (<div className="cardDiv">
    <img src={fileHttpUrl(data.url)} alt={data?.alt} />
    <h2>{data.title}</h2>
    <div>{data.text}</div>

  </div>
  )
}

// 产品分类列表区域
const ProTypeDiv = ({ proTypeList, click }: any) => {
  const history = useHistory()
  
  const [activeIndex, setActiveIndex] = useState(0)
  const onClick = (index: number, item: any) => {
   
    const { type = "pro", children } = item
    setActiveIndex(index)
    click({ type, list: type === 'hot' ? children : children })
    if(item.url) {
      isBrowser() && (window.location = item.url)
      return
    }
  }
  return (
    <div className="ProTypeDiv">
      {proTypeList.map((item: any, index: number) => {
        return <div
          key={index}
          className={[(activeIndex === index) ? 'active' : null, 'proTypeItem'].join(' ')}
          onClick={() => onClick(index, item)}
        >
          <div className="title">{item.title}
            {item.type === 'hot'?<div className="hotCard">hot</div> : null}
          </div>
          <div className="text mt-2">{item.ptEnglishName}</div>
        </div>
      })
      }
    </div>
  )
}

// 菜单点击事件
const menuClick = (item: any) => {
  const { piId } = item || {}
  setHref(`/home/product?piId=${piId}`)
}

// 热门产品组件
const HotProColum = ({ proList = [] }: any) => {

  return (<div className="HotProColum">
    {
      proList.map((element: any, index: number) => {
        return <div key={index} className='hotProCard' onClick={() => menuClick(element)}>
          <div className="title">
            {element.piName}
            {element.piIsNew ? <span className="men_item_new">NEW</span> : null}
          </div>
          <span className="text">{sliceText(element.piDescribe, 26)}</span>
        </div>
      })
    }
  </div>)
}

// 产品列表组件的产品单列组件
export const MenuColumn = ({ proList = [] }: any) => {

  // const history = useHistory()
  // // 菜单点击事件
  // const menuClick = (item: any) => {
  //   const {  piId } = item || {}
  //   history.push(`/home/product/${piId}`)
  // }
  // 菜单列组件
  const Column = ({ coumnList = [] }: any) => {
    return (<>
      {(coumnList || []).map((item: any, index: number) => {
        return <div
            key={index}
            onClick={() => {item.piId && menuClick(item)}}
            className={item.type === 'title' ? 'titleMenu flex' : 'proName flex'} >
              <span>{item.title}</span>
              {(item.piIsNew && item.piIsRecommend) ? <div><img className="hotIcon" src={hotNew} alt="" /></div> : null}
              {(item.piIsNew && !item.piIsRecommend) ? <div><span className="men_item_new">NEW</span></div> : null}
              {(!item.piIsNew && item.piIsRecommend) ? <div><span className="men_item_new">HOT </span></div> : null}
        </div>
      })}
    </>
    )
  }
  return (<div className="menuColumn">
    {formatProList(proList.list || []).map((item: any, index: number) => {
      return <div key={index} className='Column'>
        <Column coumnList={item} />
      </div>
    })
    }
  </div>)
}

// 服务区域
const ServiceDiv = ({ serviceText }: any) => {
  return (
    <div>
      <p className="title">服务产品</p>
      <p className="text">{serviceText}</p>
    </div>
  )
}

//  中间菜单区域
const MenuContent = () => {

  const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
  const { pathname } = useLocation()
  // 菜单点击事件
  const menuClick = (path: string) => {
    path && setHref(path)
  }


  return (<div className="menu home_menu_component">
    {
      menuList(ueSaasName).map((item, index) => {
        const { title, type, url, children }: any = item || {}
        return <div key={index} onClick={() => menuClick(url)} className={`text-center menuItem ${(pathname === url) ? 'activeMenu' : ''}`}>
          {(children || []).length ?
          <div className="ejectMentDiv">
            <text className="menuName" style={{paddingBottom: '17px'}}>{item.title}</text>
            <div className="MentDiv">
               {children.map((item:any) => {
                 return <div key={item.key} onClick={() => menuClick(item.key)} className="MentDivItem">{item.label}</div>
               })}
            </div>
          </div>
             : <span  className="menuName">{item.title}</span>
          }
          {['service', 'product'].includes(type) ? <DownOutlined className='icon ml-3' /> : null}
          {['service', 'product'].includes(type) ? <EjectDiv type={item.type} /> : null}
          {(children || []).length ? null : <div className="bottomLine"> </div>}
        </div>
      })
    }
  </div>)
}

const dispatch = useCacheDispatch()
export const loginOut = () => {
  confirm({
    title: '提示',
    icon: <ExclamationCircleOutlined />,
    content: '确认要退出登录吗？',
    centered: true,
    onOk() {
      removeStorage(TOKENKEY)
      dispatch({ type: 'reset', })
      // history.push('/login')
      if (isBrowser()) {
        const { pathname, search } = window?.location || {}
        setHref(`/login?${stringify({redirect: pathname + search})}`)
      }
    },
    onCancel() {
      console.log('Cancel');
    },
  });

}

const UserInfo = () => {
  const history = useHistory()
  const { userInfo } = useSelector((state: StateType) => state) as any;
  const { baseInfo = [] } = userInfo || []
  const { aciMoney = 0, url:agentUrl  = '', verifyState } = baseInfo || {}
  const { unRead } = useSelector((store: any) => (store.msgInfo||{}))
  // console.log(baseInfo)
  const UserInfoEject = () => {
    return <div className="topMenusUserInfoEject" style={{marginRight: '24px', zIndex: '9999'}}>
      {verifyState ===  CERT_UNCERT ?
      <Alert
        message={<>当前未认证，<a href="/account/certification">去认证 &gt;&gt;</a></>}
        style={{ marginBottom: '10px' }}
        closable={false}
        type="warning"
        showIcon
      /> : null }
      <div style={{fontSize: '16px',fontWeight: '600'}}>{baseInfo.username} </div>
      <div className="flex justify-between">
        <span className="hoverRed">实名认证</span>
        <CertificationLink type={baseInfo.userNature} status={baseInfo.verifyState} />
      </div>


      <div className="bottom">
        <div>
          <div>余额</div>
          <div className="redColor">￥{aciMoney}</div>
          <div onClick={() => history.push('/cost/myorder')} className="mt-2 j_a_color_black cursor-pointer">我的订单</div>
        </div>
        {/* <Button danger className="redColorBtn"><ChargeModal trigger={<a>充值</a>} /></Button> */}
      </div>
      <div className="user_msg_div">
        <div onClick={() => history.push('/account/message')}  className="j_a_color_black cursor-pointer">
            未读消息
            <div className="usermsg_redBgSpan">{unRead || 0}</div>
        </div>
      </div>
      <div className="loginOut text-gray-500 clear-both" onClick={(e) => loginOut()}>
        退出登录
      </div>
    </div>
  }



  const IsLogin = () => {
    return (<div className="userInfoDiv">
      <div className="userName  pr-6">
        <span className="ml-4 mr-2">{baseInfo.username}</span>
        <DownOutlined className='icon' />
        <UserInfoEject />
      </div>
      {agentUrl ? <span><a href={agentUrl} className="j_a_color_black mr-4">代理商入口</a></span> : null }
      <span className="mr-6"><a href="/overview" className="j_a_color_black">控制台</a></span>
    </div>)
  }


  const NotLogin = () => {
    return (<>
      <span className="mt-6 mr-4 cursor-pointer" onClick={() => history.push('/login')}>登录</span>
      <button className="gradientBtn" onClick={() => history.push('/register')}>立即注册</button>
    </>)
  }

  return (<div className="userInfo pc-component">
    {getToken() ? <IsLogin /> : <NotLogin />}
  </div>)
}




// 手机上显示时的菜单---手机端
const UserInfoMobile = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [proTypeData, setProTypeData] = useState([] as any)
  const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
  const [serviceText, setServiceText] = useState('')
  const [proData, setProData] = useState({ type: 'hot' } as any)
  const [serviceData, setServiceData] = useState([] as any)
  const { treeData = [] } = useSelector((store: StateType) => store?.productData) as any

  //  查询产品数据
  const getProTypeData = async () => {
    const api = handleDvmCommonPageListResult(homeGetHotProduct)
    const { data } = await api({
      piIsRecommend: 1,
      piPublishState: 1,
      pageNum: 1,
      pageSize: 99,
    }, {}, {})
    setProTypeData([{ title: '热门推荐',ptEnglishName: 'Hot Recommended', type: 'hot', children: data }])
    setProData({ ...proData, list: data })
  }
  const change = (state:boolean) => {
    setMenuOpen(state)
  }
  const menuList = [
    { title: '云极简介',ptEnglishName: 'Introduction to Yunji', type: '',url: '/home/AboutUs', children: [] },
    { title: '行业资讯',ptEnglishName: 'Industry Information', type: '',url: '/home/industryInformation', children: [] },
    { title: '帮助文档',ptEnglishName: 'Help Documents', type: '',url: '/home/doc', children: [] },
    { title: '合作伙伴',ptEnglishName: 'Partners', type: '',url: '/home/cooperation', children: [] },
  ]
  useEffect(() => {
    getProTypeData()
    setServiceText(testProDetailedList(ueSaasName).serviceText)
  }, [])

  return (<div className='jm-component'>
    <MenuIcon state={menuOpen} change={change}/>
    <Drawer
        placement={'bottom'}
        closable={false}
        className="jm_userInfoMobile_menu"
        height={'calc(100% - 64px)'}
        open={menuOpen}
      >
       <div className="jm_ejectDiv ">
          <div className="left"  style={{ background: `url(${fileHttpUrl('fileServer/2022/11/28/ca5d5980476e4a3fa0a7fa4ab39d59cb.png')}) no-repeat` }}>
            <ProTypeDiv click={setProData} proTypeList={[...proTypeData, ...treeData, ...menuList]} />
          </div>
          <div className="center">
            {proData.type === 'hot' ? <HotProColum proList={proData.list} /> : <MenuColumn proList={proData} />}
          </div>
        </div>
      </Drawer>
  </div>)
}

export default ({ showMenu = true, ...rest }: { showMenu?: boolean }) => {
  return (<div className="homeTopHeaderDiv" {...rest}>
    {/* 左边logo */}
    <LogoImg />
    {/* 中间的菜单 */}
    {showMenu ? <MenuContent /> : null}
    {/* 右侧用户状态 登录注册等 */}
    <UserInfo />
     {/* 右侧用户状态 登录注册等 */}
     <UserInfoMobile />
  </div>)
}
