import React from "react"
import { Input, InputNumber, Button, Space } from 'antd';
import {
    PlusOutlined,
    MinusOutlined,
} from '@ant-design/icons';
import { toFixedNoRound } from "@utils/util";


export const NumberInputPro = ({value, onChange, disabled,  max = 999, min = 1, ...res}: any)=> {
    return (<div>
      <Input.Group compact>
        <Button style={{width: '30px', padding: '5px 6px'}} disabled={value <= min || value >= max || disabled}  onClick={()=> onChange(value - 1 )}><MinusOutlined/></Button> 
        <InputNumber value={value} className="inputNumber_content_center" style={{ width : '80px'}} disabled={disabled} precision={0} onChange={(value)=> onChange(value )} min={min} max={max} controls={false} {...res}/>
        <Button style={{width: '30px', padding: '5px 6px'}} disabled={disabled} onClick={()=> onChange(++value )}><PlusOutlined /></Button>
      </Input.Group>
    </div>)
}

export const columns = (operate: any)=> {
  return [
    {
        title: '商品名称',
        dataIndex: 'piName',
    }, 
    {
        title: '配置详情',
        dataIndex: 'ppgName',
        width: 300,
        render: (text: string, record: any, index: number) => {
            return <div>
                <div>套餐类型： {text}</div>
                <div>套餐规格： {record.packgeAmount}</div>
            </div>
        }
    },
    {
        title: '购买数量',
        dataIndex: 'odPackageAmount',
        width: 200,
        render: (text: string, record: any, index: number) => [
            <NumberInputPro disabled={record.price === 0}  value={text} onChange={(number: number)=> operate('数量', number, index)} key={1} />
        ]
    },
    {
        title: '有效时长',
        dataIndex: 'effectiveTime',
    },
    {
        title: '价格',
        dataIndex: 'price',
    },
    {
        title: '小计',
        dataIndex: 'price',
        render: (text: string, record: any, index: number) => {
            return  <div>{toFixedNoRound(record.odPackageAmount * record.price, 2)}</div>
        }
    },
    {
        title: '操作',
        dataIndex: 'operation',
        render: (text: string, record: any) => [
            <a key="删除" onClick={() => operate('删除', [record.ocaId])}>删除</a>,
        ]
    }
]
}