import { Button, Tabs } from "antd"
import React, { useRef, useState } from "react"
import ProTable, { ActionType } from "@ant-design/pro-table";
import { PROCODE_SMS, productApi } from "@utils/index";
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home";
import { columnsFn } from "./data";
import { smsSendFeedbackPageList } from "@api/index";

// 接口请求的 api 层
const getApi = productApi(smsSendFeedbackPageList)  // 查询的接口封装
const addUpdateApi = productApi(homeTableAddUpdate) // 列表数据的新增/编辑 接口
const delApi = productApi(homeTableDel) // 表单数据的删除 接口

export default () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [modalProps, setModalProps] = useState({visible: false, title: '新增', params: {}})
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    // operate 函数为增删改查需要处理的业务
    const operate = (type: any, record: any) => {
        const { id } = record || {}
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params, productCode: PROCODE_SMS}, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const columns = columnsFn(operate) // 表格列配置
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    return (<ProTable<any>
        columns={columns}
        params={{}} // 查询所需要的额外参数
        request={getListApi}
        options={{reload: false, density: false, setting: false}}
        toolbar={{
            actions: [],
        }}
        rowKey="id"
        pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
        actionRef={ref as any}
        search={{
            optionRender: (searchConfig, formProps, dom) => [ ...dom.reverse() ]
        }}
    />)
}
