import React from "react"
import { ProColumns } from "@ant-design/pro-table";

const statusOptions  = [
    {
        label: '未审批',
        value: 0
    },
    {
        label: '审批中',
        value: 1
    },
    {
        label: '审批通过',
        value: 2
    },
    {
        label: '审批未通过',
        value: 9
    },
]
export const templateLibColumnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '模版名称',
            dataIndex: 'utlName',
            key: 'utlName',
        },
        {
            title: '模版内容',
            dataIndex: 'utlContent',
            key: 'utlContent',
            hideInSearch: true
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            valueType: 'option',
            readOnly: true,
            render: (text: any, record: any) => [
                <a key="应用该模版" style={{marginRight: 8}} onClick={() => operate('应用该模版', record)}>应用该模版</a>,
            ]
        }
    ]
}

export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '模版CODE',
            dataIndex: 'utlCode',
            key: 'utlCode',
            hideInSearch: true
        },
        {
            title: '模版名称',
            dataIndex: 'utlName',
            key: 'utlName',
        },
        {
            title: '短信内容',
            dataIndex: 'utlContent',
            key: 'utlContent',
            hideInSearch: true
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            hideInSearch: true
        },
        {
            title: '审核状态',
            dataIndex: 'utlVerifyState',
            key: 'utlVerifyState',
            hideInSearch: true,
            valueType: 'select',
            request: async () => statusOptions
        },
        {
            title: '拒绝理由',
            dataIndex: 'utlVerifyDescribe',
            key: 'utlVerifyDescribe',
            hideInSearch: true
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            valueType: 'option',
            fixed: 'right',
            render: (text: any, record: any) => [
                // <a key="详情" style={{marginRight: 8}} onClick={() => operate('详情', record)}>详情</a>,
                <a key="删除" onClick={() => operate('删除', record)}>删除</a>,
            ]
        }
    ]
}
