import React, { useEffect, useState } from "react"
import ProForm, { 
    ProFormText, 
    ProFormUploadButton, 
    ProFormUploadDragger,
    ProFormSelect, 
    ProFormTextArea, 
    ProFormCascader,
    ProFormItemProps
} from '@ant-design/pro-form';
import { Image, UploadProps } from "antd"
import { acceptImg, getToken, homePageReg } from "@utils/index";
import { BASEUPLOADURL, fileHttpUrl } from "@api/baseUrl";
import { PreviewProps } from "../Preview";
import { TOKENKEY } from "@utils/request";
import { useDispatch } from "react-redux";
import { setPreviewObj } from "@store/actions";

const UPLOAD_FILE_URL = `${BASEUPLOADURL}/upload/uploadFileTypeIsFile`
interface MyUploadProps extends ProFormItemProps {
    name?: any;
    action?: string;
    fieldProps?: UploadProps;
    description?: any;
}

const UploadButton = ({fieldProps, ...rest}: MyUploadProps) => {
    const dispatch = useDispatch()
    const isImg = fieldProps?.accept === acceptImg
    const onPreview = (file: any) => {
        const { response } = file || {}
        const {http: url, } = response ? (response||{}) : file
        dispatch(setPreviewObj({visible: true, src: fileHttpUrl(url)}))
    }
    return (<><ProFormUploadButton 
        width="md" 
        name="files" 
        label="附件" 
        fieldProps={{ 
          name: 'fileOptions.file', 
          listType: 'picture-card',
          onPreview: isImg ? onPreview : () => null,
          headers: {[TOKENKEY]: getToken()} as any,
          ...(fieldProps||{}),
        }} 
        action={UPLOAD_FILE_URL} 
        extra="图片大小不超过5MB，图片格式支持 JPG，PNG，BMP，GIF"
        {...rest}
      />
    </>
    )
}


export const UploadDragger = ({fieldProps, ...rest}: MyUploadProps) => {
    const dispatch = useDispatch()
    const isImg = fieldProps?.accept === acceptImg
    const onPreview = (file: any) => {
        const { response } = file || {}
        const {http: url, } = response ? (response||{}) : file
        dispatch(setPreviewObj({visible: true, src: fileHttpUrl(url)}))
    }
    return (<><ProFormUploadDragger 
        width="md" 
        name="files" 
        label="附件" 
        fieldProps={{ 
          name: 'fileOptions.file', 
          listType: 'picture-card',
          onPreview: isImg ? onPreview : () => null,
          headers: {[TOKENKEY]: getToken()} as any,
          ...(fieldProps||{}),
        }} 
        action={UPLOAD_FILE_URL} 
        extra="图片大小不超过5MB，图片格式支持 JPG，PNG，BMP，GIF"
        {...rest}
      />
    </>
    )
}

export function handleUploadFiles(files: any[]) {
    return (files||[]).map(({response, http, ...rest}) => http ? http : response ? response.http : rest)
}

export function handleUploadInitValue(url?: string | string[]) {
    return Array.isArray(url) ? url.map((v: string, i: number) => ({
        uid: v + i,
        name: 'image.png',
        status: 'done',
        url: fileHttpUrl(v),
        http: v,
    })) : url ? [{
        uid: url + 0,
        name: 'image.png',
        status: 'done',
        url: fileHttpUrl(url),
        http: url
    }] : []
}

export default UploadButton