import {DOMAIN_INFO, SET_MSG_INFO, SET_SHOPPINGCAR_NUMBER, SET_USER_INFO} from '../contants'
// 存储用户信息
export const userInfo = (state = {}, action: { type: any; userInfo: any }) => {
    const { userInfo } = action
    switch(action.type) {
        case SET_USER_INFO: 
            return userInfo
        default: 
            return state
    }
}

export const domainInfo = (state = {}, action: { type?: any; domainInfo?: {} }) => {
    const { domainInfo } = action
    switch(action.type) {
        case DOMAIN_INFO:
            return domainInfo
        default: 
            return state
    }
}

export const msgInfo = (state = {}, action: { type?: any; msgInfo?: {} }) => {
    const { msgInfo } = action
    switch(action.type) {
        case SET_MSG_INFO:
            return msgInfo
        default: 
            return state
    }
}