import request from "@utils/request";
import { stringify } from "qs";
import { BASEURL } from "./baseUrl";
import { headers } from "./login";

/****************** 公共接口 *******************/
// 我的订单查询
export const getCostMyOrderPageList  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'userOrderInfo:query' })
})

// 用户订单详情
export const getCostMyOrderInfo  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({ ...data, dvmControl: 'userOrderDetails:query' })
})

// 用户订单删除
export const getCostMyOrderDel  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({ ...data, dvmControl: 'orderInfo:del' })
})


// 收支明细分页列表
export const getCostPaymentPageList  = (data?: object) => request({
    url:`${BASEURL}/portal/expenseCenter/expenseDetails`,
    data
})

// 我的云朵分页列表
export const getCostMyCloudPageList  = (data?: object) => request({
    url:`${BASEURL}/portal/expenseCenter/myClouds`,
    data,
    // data: stringify({ ...data, dvmControl: 'portalSystem:getAreaTree' })
})

// 我的云朵详情
export const getCostMyCloudDetail  = (data?: object) => request({
    url:`${BASEURL}/portal/expenseCenter/myCloudsDetails`,
    data,
    // data: stringify({ ...data, dvmControl: 'portalSystem:getAreaTree' })
})

// 套餐包管理
export const getCostPkgPageList  = (data?: object) => request({
    url:`${BASEURL}/web/product/showQuota`,
    data,
    // data: stringify({ ...data, dvmControl: 'portalSystem:getAreaTree' })
})

// 套餐包管理 获取告警信息
export const getCostShowQuotaSetting  = (data?: object) => request({
    url:`${BASEURL}/web/product/showQuotaSetting`,
    data,
    // data: stringify({ ...data, dvmControl: 'portalSystem:getAreaTree' })
})

// 设置告警信息
export const updateQuotaSetting  = (data?: object) => request({
    url:`${BASEURL}/web/product/updateQuotaSetting`,
    data,
    // data: stringify({ ...data, dvmControl: 'portalSystem:getAreaTree' })
})

// 消费账单
export const consumeBillPageList  = (data?: object) => request({
    url:`${BASEURL}/web/dataStat/consumerBills`,
    data,
    // data: stringify({ ...data, dvmControl: 'portalSystem:getAreaTree' })
})
