import { Card, Radio, Spin } from "antd"
import React, { useEffect, useState } from "react"
import { Line } from '@ant-design/plots';
import { columnsFn, mockData } from "./data";
import ProTable, { ActionType } from "@ant-design/pro-table"
import { ExportButton, SearchBox } from "@components/index";
import { handleSubmit, originForm, searchConfig } from "../Search/data";
import { Actions, CommonCardLineChart } from "../UseStatistic";
import { BASEURL, cdnAnalysisResult, cdnStatusGraph } from "@api/index";

const StatusCodeTable = () => {
    const getListApi = async() => []
    return (<ProTable<any>
        className="j-antd-protable-nopadding"
        columns={columnsFn(() => null)}
        params={{}} // 查询所需要的额外参数
        request={getListApi}
        options={{ reload: false, density: false, setting: false }}
        toolbar={{
            actions: [],
        }}
        rowKey="tiId"
        search={false}
        pagination={{ defaultPageSize: 10 }}
    />)
}

export default ()=> {
  const [queryParam, setQueryParam] = useState(originForm())
  const [radioValue, setRadioValue] = useState(4)
  const [chartData, setChartData] = useState(mockData)
  const [loading, setLoading] = useState<boolean>(false)
  const onSearch = async (params: any) => {
      setLoading(true)
      setQueryParam(params)
      const requestParams = {...handleSubmit(params), graphType: radioValue}
      const { data: chartData } = await cdnStatusGraph(requestParams) as any
      const data = chartData.map(({num2xx, num3xx, num4xx, num5xx, time}: any) => ([
        {time, num: num2xx, category: '2xx'},
        {time, num: num3xx, category: '3xx'},
        {time, num: num4xx, category: '4xx'},
        {time, num: num5xx, category: '5xx'},
      ])).flat()
      setChartData(data)
      setLoading(false)
  }
  const onChange = async (type: any, val: any) => {
      setLoading(true)
      if (type === '图表') {
          setRadioValue(val)
          const requestParams = {...handleSubmit(queryParam), graphType: val, type: 1}
          const { data: chartData } = await cdnStatusGraph(requestParams) as any
          setChartData(chartData)
      } else if (type === '导出') {

      } else if (type === '刷新') {
          const requestParams = {...handleSubmit(queryParam), graphType: radioValue, type: 1}
          const { data: chartData } = await cdnStatusGraph(requestParams) as any
          setChartData(chartData)
      }
      setLoading(false)
  }

  const onValuesChange = (values: any, allValues: any) => {
    if ('time' in values) {
        onSearch(allValues)
    }
   }
  
  useEffect(() => {
      onSearch({})
  }, [])

  const getQueryParams = () => {
    const requestParams = {...handleSubmit(queryParam), graphType: radioValue, type: 4}
    return requestParams
  }

  return (<>
    <SearchBox config={searchConfig} onValuesChange={onValuesChange} onFinish={onSearch} />
    <Actions onClick={onChange}>
        <ExportButton key="export" type='primary' action={`${BASEURL}/web/cdn/analysis/export`} param={getQueryParams} btnText="导出" />
    </Actions>
    <div style={{padding: 20}}>
    <Spin spinning={loading}>
      <CommonCardLineChart 
          title="状态码统计" 
          yTitle="状态码数量(次)" 
          lineConfig={{seriesField: 'category'}}
          value={chartData} 
      />
      <div style={{margin: '20px 0'}}>域名流量/宽带使用详情</div>
      <ProTable<any>
          className="j-antd-protable-nopadding"
          columns={columnsFn(() => null)}
          params={{...handleSubmit(queryParam), type: 4}} // 查询所需要的额外参数
          request={async(params: any, sorter: any, filter: any) => {
              const { data } = await cdnAnalysisResult(params) as any
              const { statusItems } = data
              return {
                data: statusItems || [],
                success: true,
                total: statusItems.length,
              };
          }}
          options={{ reload: false, density: false, setting: false }}
          toolbar={{
              actions: [],
          }}
          search={false}
          pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
      />
      </Spin>
    </div>
  </>)
}
