import { ProColumns } from "@ant-design/pro-table";
import { fileHttpUrl } from "@api/baseUrl";
import { Input, Select } from "antd";
import React, { ReactNode } from "react";
import { Image } from 'antd';
import { typeOptions } from "../../data";
import { DateRangeWithDisabled } from "@components/Common";

const options = [
    {label: 'URL刷新', value: 1}, 
    {label: '目录刷新', value: 2},
    {label: 'URL预热', value: 3}
]


const valueEnumStatus = {
    1: { text: '处理中', status: 'Default' },
    2: { text: '完成', status: 'Sueecss' },
    3: { text: '失败', status: "Error" },
    4: { text: '等待', status: 'Processing' },
    5: { text: '刷新中', status: 'Processing' },
    6: { text: '预热中', status: 'Processing' },
}

/**
 * 列表表格配置:
 *  自定义表格 CustomerDemo 和 antd 升级版组件 ProTableDemo 都可使用, 
 * */
export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '操作类型',
            dataIndex: 'type',
            valueType: "select",
            request: async () => options,
            // hideInSearch: true,
            render: (text: any, record: any) => record.type,
        },
        {
            title: 'URL/目录',
            dataIndex: 'keyWord',
            // valueType: 'select',
            // request: async () => typeOptions
            // hideInSearch: true,
            render: (text: any, record: any) => (record.urls||'').toString(),
        },
        {
            title: '状态',
            dataIndex: 'status',
            valueType: 'select',
            // render: (text: any, record: any) => record.status,
            valueEnum: valueEnumStatus
        },
        {
            title: '创建时间',
            dataIndex: 'sendTime',
            // hideInSearch: true,
            valueType: 'dateRange',
            fieldProps: { },
            search: {
                transform: (value) => {
                    const startTime = value[0] ? value[0] + ' 00:00:00' : undefined
                    const endTime = value[1] ? value[1] + ' 23:59:59' : undefined
                    const createTime = [startTime, endTime]
                    return {
                        // startTime,
                        // endTime
                        createTime
                    }
                },
            },
            renderFormItem: (props) => <DateRangeWithDisabled />,
            render: (text: any, record: any) => record.createTime,
        }
    ]
}
