import { ProColumns } from "@ant-design/pro-table";
import { fileHttpUrl } from "@api/baseUrl";
import { Input, Select } from "antd";
import React, { ReactNode } from "react";
import { Image } from 'antd';
import { DateRangeWithDisabled } from "@components/Common";

const options = [{label: 'options1', value: 1}, {label: 'options2', value: 2}]


const valueEnumCharge = {
    0: { text: '不收费', status: 'Default' },
    1: { text: '收费', status: 'Sueecss' },
}

/**
 * 列表表格配置:
 *  自定义表格 CustomerDemo 和 antd 升级版组件 ProTableDemo 都可使用, 
 * */
export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '银行卡号',
            dataIndex: 'bankCard',
            // hideInSearch: true
        },
        {
            title: '归属省份',
            dataIndex: 'province',
            hideInSearch: true
        },
        {
            title: '归属城市',
            dataIndex: 'city',
            hideInSearch: true
        },
        {
            title: '归属银行',
            dataIndex: 'bankName',
            hideInSearch: true
        },
        {
            title: '银行卡类型',
            dataIndex: 'cardType',
            // hideInSearch: true
        },
        {
            title: '银行卡名称',
            dataIndex: 'cardName',
            // hideInSearch: true
        },
        {
            title: '客服电话',
            dataIndex: 'tel',
            hideInSearch: true
        },
        {
            title: '银行卡bin码',
            dataIndex: 'cardBin',
            hideInSearch: true
        },
        // {
        //     title: '银行卡logo',
        //     dataIndex: 'bankLogo',
        //     render: (text: any, record: any) => <Image width={80} src={text} />,
        //     hideInSearch: true
        // },
        {
            title: '调用时间',
            dataIndex: 'sendTime',
            // hideInSearch: true,
            valueType: 'dateRange',
            fieldProps: { },
            search: {
                transform: (value) => {
                    return {
                        startTime: value[0] ? value[0] + ' 00:00:00' : undefined,
                        endTime: value[1] ? value[1] + ' 23:59:59' : undefined
                    }
                },
            },
            renderFormItem: (props) => <DateRangeWithDisabled />,
            render: (text: any, record: any) => record.sendTime,
        },
        {
            title: '是否计费',
            dataIndex: 'fee',
            // hideInSearch: true,
            formItemProps: {
                // initialValue: '1',  
            },
            valueType: 'select',
            render: (text: any, record: any) => record.feeStr,
            valueEnum: valueEnumCharge
        }
    ]
}
