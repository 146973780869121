import { Button, Modal, Tabs, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import ProTable, { ActionType } from "@ant-design/pro-table";
import { filterObj, productApi } from "@utils/index";
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home";
import { BASEURL, cdndeleteCert, cdnGetCertConfigList, cdnGetUserDomain, cdnSaveOrUpdateCert, cdnSaveOrUpdateCertWithMultiSelect, smsGlobalSendDetailPageList, smsTempPageList } from "@api/index";
import { ExportButton } from "@components/Common";
import type { ModalFormProps, ProFormInstance } from "@ant-design/pro-form";
import moment from "moment";
import ProForm, {
  ModalForm,
  ProFormSelect,
  ProFormTextArea,
  ProFormSwitch,
  ProFormText,
  ProFormCheckbox,
} from '@ant-design/pro-form';
import {
  QuestionCircleOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  CopyOutlined
} from '@ant-design/icons';
import { columnsFn } from "./data";
import AlertTip from "@components/Common/AlertTip";
import { tooltipData } from "../DomainAdmin/data";
import { handleDvmCommonListResult } from "@utils/request";
import FormItem from "antd/es/form/FormItem";
import { StateType } from "@store/index";
import { useSelector } from "react-redux";

const { confirm } = Modal;
// 接口请求的 api 层
const getApi = productApi(cdnGetCertConfigList); // 查询的接口封装
const addApi = productApi(cdnSaveOrUpdateCertWithMultiSelect); // 多选配置证书
const delApi = productApi(cdndeleteCert); // 证书删除删除接口


const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 17 },
};

//配置证书
const AddCertificateModal = ({ params, onSuccess, ...rest }: any) => {
  const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
  const formRef = useRef<ProFormInstance>();
  const [sourceName, setSourceName] = useState('')
  const [sourceTestValue, setSourceTestValue] = useState('')
  const [testResult, setTestResult] = useState(false)

  const listTips = [
    `您配置证书的域名需要已接入${ueSaasName}CDN，且域名状态需要处于部署中或已启动。` ,
    '提交后，新配置的证书将应用于选定域名全部服务区域。'
  ] as any

  const onFinish = async (values: any) => {
      const data = { 
          ...values,
          ...params,
          domainName: values.domain.label,
          domainId: values.domain.value,
          domain:null

      }
      const { success } = await addApi(
          filterObj(data),
          true,
          () => {
              clearData()
              onSuccess ? onSuccess() : null
          }
      )
      console.log(success)
      return success
  }

 
    const clearData = () => {
      formRef.current?.resetFields()
    }
  return (
    <ModalForm<any>
      layout="horizontal"
      width={800}
      formRef={formRef}
      {...layout}
      onFinish={onFinish}
      preserve={false}
      modalProps={{
        onCancel: clearData,
      }}
      {...rest}
    >
      <AlertTip type="warning" listTips={listTips} />
     <div className="j-domainAdmin-titleText mt-3">关联域名</div>
     <ProFormSelect
        width="md"
        name="domain"
        label="加速域名"
        
        showSearch
        disabled={params.id !== ''}
        fieldProps={{
            placeholder: '请选择加速域名',
            // mode: 'multiple',
            labelInValue:true,
            showSearch: true,
            filterOption: false,
        }}
        options={[]}
        request={
            async () => {
            const { data } = await cdnGetUserDomain({pageNum: 1, pageSize: 999}) as any
            return data?.filter((item:any)=> item.isUsed === 0 ).map(({ id: value, domainName: label }: any) => ({ label, value}))
          }
        }
        rules={[{ required: true, message: '请选择加速域名' }]}
     /> 

     <div className="j-domainAdmin-titleText">选择证书</div>
     <FormItem label="证书来源">自有证书</FormItem>
      
      <ProFormText
        width="md"
        name="certName"
        rules={[{ required: true, message: "请输入证书名称" }]}
        label="证书名称"
        fieldProps={{maxLength: 32}}
        placeholder="建议输入英文，长度不超过32个字符"
      />
      <ProFormTextArea
          rules={[{ required: true, message: '请输入PEM格式的公钥内容' }]}
          placeholder='请输入PEM格式的公钥内容'
          name="certificate"
          label="证书内容"
          fieldProps={{
              allowClear: true,
          }}
          extra={ <Tooltip title={tooltipData.PEMBMSL}>
          <div className="text-blue-400 mt-2">pem编码参考样例</div>
        </Tooltip>}
  
      />
       <ProFormTextArea
          rules={[{ required: true, message: '请输入PEM格式的私钥内容' }]}
          placeholder='请输入PEM格式的私钥内容'
          name="privateKey"
          label="私钥内容"
          fieldProps={{
              allowClear: true,
          }}
          extra={ <Tooltip title={tooltipData.PEMKEYBMSL}>
          <div className="text-blue-400 mt-2">密钥key参考样例</div>
        </Tooltip>}
      />
    </ModalForm>
  );
}




export default () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [modalProps, setModalProps] = useState({
    visible: false,
    title: "新增",
    params: {},
  }) as any;
  const ref = useRef<ActionType>(null); // ProTable 组件的 ref
  const formRef = useRef<ProFormInstance>();


  // operate 函数为增删改查需要处理的业务
  const operate = (type: any, data: any) => {
    const {id, certName, certificate, privateKey, domainName, domainId } = data || {}
    if (type === "配置证书") {
      setModalProps({
        ...modalProps,
        title: '配置证书',
        request: async() => {
          return {id: '', certName: '', certificate: '',  privateKey: ''}
        },
        params: {id : ''},
        visible: true,
      })
    } else if (type === "编辑") {
         setModalProps({
            ...modalProps,
            request: async() => {
              return {id, certName, certificate, privateKey, domain:{label:domainName, value: domainId} }
            },
            title: '编辑证书',
            params: {id},
            visible: true,
          })
    } else if (type === "删除") {
      confirm({
        title: '提醒',
        icon: <ExclamationCircleOutlined />,
        content: '确定要删除这个证书吗',
        centered: true,
        onOk: async () => {
          await delApi({ids: [id] }, true)
          ref.current?.reload()
        }
      });
    }
  };
  
  const listTips = [
    '若您已有证书，可直接上传进行配置，同时可以在当前页面对证书进行编辑、删除等操作。'
  ] as any

  const getListApi = async (params: any, sorter: any, filter: any) => {
    const { data } = await getApi({ ...params }, false);
    const { values, total } = data || {};
    return {
      data: values || [],
      success: true,
      total,
    };
  };

  const columns = columnsFn(operate); // 表格列配置

  // 新增/修改提交表单后的回调操作
  const onSuccess = () => {
      setModalProps({
        ...modalProps,
        visible: false,
      })
      reFresh()
  }
  // 刷新页面
  const reFresh = () => {
    // @ts-ignore
    ref.current?.clearSelected();
    ref.current?.reload();
  };
  const getQueryParams = () => ({
    // @ts-ignore
    ...formRef.current?.getFieldsFormatValue(),
  });
  return (
    <div>
      {/*  */}
      <div style={{background: 'white'}} className='p-4'>
         <AlertTip type="warning" listTips={listTips} />
      </div>
      <ProTable<any>
        style={{ flex: 1, overflow: "scroll" }}
        scroll={{ x: 1300 }}
        columns={columns}
        params={{}} // 查询所需要的额外参数
        request={getListApi}
        options={{ reload: false, density: false, setting: false }}
        toolbar={{
          actions: [
            <Button key="btn0" type='primary' onClick={() => operate('配置证书', null)}>添加</Button>,

            // <AddCertificateModal  onSuccess={onSuccess} key="btn0" title="配置证书" trigger={<Button type='primary'>添加</Button>} />,
          ],
        }}
        // rowKey="tiId"
        pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
        actionRef={ref as any}
        formRef={formRef}
        search={{
          optionRender: (searchConfig, formProps, dom) => {
            return [
              dom[1],
              <Button
                key="reset"
                onClick={() => {
                  const { onClick: onSearch } = (dom[1] as any)?.props || {};
                  formRef.current?.resetFields();
                  formRef.current?.setFieldsValue({
                    tiId: "",
                    sendTime: [moment().add(0, "days"), moment()],
                  });
                  // ref.current?.reloadAndRest()
                  onSearch && onSearch();
                }}
              >
                重置
              </Button>,
            ];
          },
        }}
      />
      <AddCertificateModal
        modalProps={{
            maskClosable: false, 
            destroyOnClose: true,
            onCancel: () => setModalProps({...modalProps, visible: false})
        }}
        onSuccess={onSuccess} 
        {...modalProps} 
    />
    </div>
  );
};