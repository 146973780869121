import request from "@utils/request";
import { fromData } from "@utils/util";
import { stringify } from "qs";
import { BASEURL } from "../baseUrl";
import { headers } from "../login";


// 获取检验类api接口的调用明细
export const checkGetCardOperatorList  = (data?: object) => request({
    url:`${BASEURL}/web/product/getCardOperatorList`,
    // headers,
    data
})

