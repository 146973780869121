import { isBrowser, setHref } from "@/utils"
import { setTaskObj } from "@store/actions"
import { StateType } from "@store/index"
import { Popover, Progress, Row, Tooltip } from "antd"
import { throttle } from "lodash"
import React from "react"
import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Icon from "../Icon"

const PopoverContent = ({taskObj}: any) => {
    return (<div>
        {Object.keys(taskObj||{}).map(taskId => (<AsyncTask.Item key={taskId} taskId={taskId} taskObj={taskObj[taskId]} />))}
    </div>)
}

const AsyncTask = () => {
    const toolRef = useRef<any>(null)
    const taskObj = useSelector((state: StateType) => state?.taskObj) as any

    const onMouseMove = throttle((ev: any) => {
        const { offsetLeft, offsetTop } = toolRef.current
        const { offsetX, offsetY, clientX, clientY } = ev
        toolRef.current.style.left = clientX - 30 + 'px'
        toolRef.current.style.top = clientY - 30 + 'px'
    }, 16)

    const onMouseDown = (ev: any) => {
        const target = ev.target
        const flag = toolRef.current === target || toolRef.current?.contains(target)
        flag && document.addEventListener('mousemove', onMouseMove)
        flag && document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', onMouseMove)
        })
    }
    useEffect(() => {
        isBrowser() && document.addEventListener('mousedown', onMouseDown)
        return () => {
            document.removeEventListener('mousedown', onMouseDown)
        }
    }, [])

    return Object.keys(taskObj).length ? (<div className="j-download-tools" ref={toolRef}>
        <Popover content={<PopoverContent taskObj={taskObj} />}  title="下载明细">
           <Icon type="icon-yunxiazai" className="j-download-drag-ball" />
        </Popover>
    </div>) : null
}

AsyncTask.Item = ({taskId, taskObj}: any) => {
    const dispatch = useDispatch()
    const onDownLoad = () => {
        const { url } = taskObj
        url && setHref(url)
    }
    const onDelTask = () => {
        dispatch(setTaskObj('DEL_TASK', [taskId]))
    }
    return (<Row style={{display: 'flex', alignItems: 'center', marginBottom: 8}}>
        <a style={{marginRight: 8}}>{taskObj.taskName}:</a>
        <Progress style={{width: 150, margin: '0 8px'}} percent={taskObj.progress} size="small" />
        <Tooltip title="删除"><Icon type="icon-shanchu" onClick={onDelTask} /></Tooltip>
        <Tooltip title="下载"><Icon type="icon-xiazai" onClick={onDownLoad} /></Tooltip>
    </Row>)
}

export default AsyncTask
