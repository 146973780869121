import { ProColumns } from "@ant-design/pro-table";

export const columnsFn: (operate?: Function) => ProColumns<any>[] = (operate) => [
    {
        title: '产品名称',
        dataIndex: 'piName',
        key: 'piName',
        hideInSearch: true
    },
    {
        title: '套餐类型',
        dataIndex: 'packageType',
        key: 'packageType',
        hideInSearch: true
    },
    {
        title: '套餐规格',
        dataIndex: 'packgeModel',
        key: 'packgeModel',
        hideInSearch: true
    },
    {
        title: '有效时长',
        dataIndex: 'effectiveTime',
        key: 'effectiveTime',
        hideInSearch: true
    },
    {
        title: '失效时间',
        dataIndex: 'endDate',
        key: 'endDate',
        hideInSearch: true
    },
    {
        title: '余量',
        dataIndex: 'quotaAmount',
        key: 'quotaAmount',
        hideInSearch: true
    },
    {
        title: '单价(元)',
        dataIndex: 'salePrice',
        key: 'salePrice',
        hideInSearch: true
    },
    {
        title: '购买数量',
        dataIndex: 'amount',
        key: 'amount',
        hideInSearch: true
    },
    {
        title: '小计(元)',
        dataIndex: 'oiId',
        key: 'oiId',
        hideInSearch: true,
        render: (text: any, record: any) => record.totalPrice
    },
]