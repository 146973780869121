import React, { useRef, useState } from "react"
import ProForm, { 
    ProFormText, 
    ProFormUploadButton, 
    ProFormSelect, 
    ProFormTextArea, 
    ProFormCascader,
    ProFormProps
} from '@ant-design/pro-form';
import { Button } from "antd";
import AlertTip from "@components/Common/AlertTip";
import { productApi } from "@utils/util";
import { cdnPreHeat } from "@api/index";
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'

const cdnPreHeatApi = productApi(cdnPreHeat)

const labelCol = {span: 2}
const wrapperCol = {span: 12}

const listTips: any = [
// '当您的源站内容更新后，请先执行缓存刷新操作，可以通过控制台（或者调用API接口）的方式创建缓存预热任务，将最新内容缓存到CDN节点。', // 双亚说先去掉（或者调用API接口）
'当您的源站内容更新后，请先执行缓存刷新操作，可以通过控制台的方式创建缓存预热任务，将最新内容缓存到CDN节点。',
'执行大批量文件预热时，建议您尽量分批次执行，否则可能会导致您的源站带宽资源被占满，预热失败。',
'预热资源对应的缓存过期时间不能设置为0，预热资源在源站的cache-control配置不能为private、no-cache、no-store，CDN不允许缓存、源站不允许缓存将导致预热失败。'
]

interface ProFormCommonProps extends ProFormProps{
    otherSubmitParams?: string[];
    restNum?: any;
    onSuccess?: () => void;
}
  
// 用户--申请实名认证(个人)
export default ({restNum, ...rest}: ProFormCommonProps) => {
    const formRef = useRef<ProFormInstance>()
    const onFinish = async(values: any) => {
      const {urls} = values
      await cdnPreHeatApi({urls: urls.split('\n')}, true, () => {
        formRef.current?.setFieldsValue({urls: ''})
      })
    }

    return (<ProForm<any>
      layout="horizontal"
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      onFinish={onFinish}
      formRef={formRef}
      style={{padding: 20}}
      submitter={{render: (props, doms) => [
        <Button key="submit" type="primary" style={{marginLeft: '8.5%'}} onClick={() => props.form?.submit?.()}>提交</Button>,
      ]}}
      {...rest}
      >
        <AlertTip type="warning" listTips={listTips} style={{marginBottom: 20}} />
        <ProFormTextArea
          name="urls" 
          label="URL" 
          rules={[
            { required: true, message: '请填写URL'},
          ]} 
          extra={`今日剩余预热量${restNum['preHeatUrlCount']}条`}
          placeholder={`一行一个URL, 示例:\n  http://www.example01.com/folder01/ \n  http://www.example01.com/folder02/`}
        />
    </ProForm>)
}
  