import React from 'react';

import "./index.less";
import bg from "@assets/images/activities/CDN/bg.png";
import box from "@assets/images/activities/CDN/box.png";
import cdn_hot from "@assets/images/activities/CDN/cdn_hot.png";
import cdn from "@assets/images/activities/CDN/cdn.png";
import hotIcon from "@assets/images/activities/CDN/hot.png";
import left from "@assets/images/activities/CDN/left.png";
import right from "@assets/images/activities/CDN/right.png";
import top from "@assets/images/activities/CDN/top.png";
import msg from "@assets/images/activities/CDN/msg.png";
import msg_div from "@assets/images/activities/CDN/msg_div.png";

import turnRight from "@assets/images/activities/CDN/turnRight.png";
import img1 from "@assets/images/activities/CDN/1.png";
import img2 from "@assets/images/activities/CDN/2.png";
import img3 from "@assets/images/activities/CDN/3.png";
import { CheckOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAlertNotLogin } from "../../Layout";
import { StateType } from "@store/index";
import { Button, Col, Row } from "antd";

import { OverPack } from "rc-scroll-anim";
import TweenOne from "rc-tween-one";
import "rc-texty/assets/index.css";
import QueueAnim from "rc-queue-anim";
import Texty from "rc-texty";
import { fileHttpUrl } from "@api/baseUrl";
import { setHref } from "@utils/util";

const TweenOneCpn = TweenOne as any;


// 活动页面之一：  云极cdn活动页


const TopDiv = () => {
  const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
  return (
    <div
      className="activities_topDiv"
      style={{
        background: `url(${fileHttpUrl(
          "test/image/EB87D76A77E44E39937ABEF53F445C6C.png"
        )}) center center`,
      }}
    >
      <div className="topText">
        <TweenOneCpn style={{ height: 96 }} playscale={[0.1, 0.2]}>
          <QueueAnim
            key="23446543"
            leaveReverse
            delay={300}
            type="scale"
            forcedReplay={true}
            className="demo-content"
            appear={true}
          >
             <h2 key="10">
              <Texty>{`${ueSaasName}CDN`}</Texty>
            </h2>
            <h2 key="11">
              <Texty>火爆一“夏”专场来了</Texty>
            </h2>
            {/* <h2 key="22">
              邀请有礼，焕春特惠&nbsp;&nbsp; 所有产品0元试用，多款产品7折起
            </h2> */}
          </QueueAnim>
        </TweenOneCpn>
      </div>
      <img src={top} alt="" className="b_img" />
    </div>
  );
};

// 两边带钻石的标题框  title标题 ，text标题下方展示的文字
const Title = ({ title, text }: any) => {
  return (
    <TweenOneCpn style={{ height: 96 }} playscale={[0.1, 0.2]}>
      <QueueAnim
        key="23446543"
        leaveReverse
        delay={300}
        type="scale"
        forcedReplay={true}
        className="demo-content"
        appear={true}
      >
        <div key="a" className="activities_top_title">
          <div key="b" className="title">
            <img src={left} className="left" alt="" />
            <Texty>{title}</Texty>
            <img src={right} className="right" alt="" />
          </div>
          <div key="c" className="text">
            <Texty>{text}</Texty>
          </div>
        </div>
      </QueueAnim>
    </TweenOneCpn>
  );
};

const GiftDiv = () => {
  return (
    <div className="activities_gift">
      <Title
        title="新客户专享"
        // text="新老用户同享，云朵可用于全网产品消费"
      />

      <div key="110" className="flex justify-between">
        <TweenOneCpn
          style={{ height: 300, width: '100%' }}
          playscale={[0.1, 0.2]}
        >
          <QueueAnim
            key="567658797898090-"
            leaveReverse
            delay={300}
            forcedReplay={true}
            className="flex justify-between mt-12"
            appear={true}
          >
            <div key="111" className="box_gift_div">
              <div className="top">
                老客户感恩回馈
                <img src={box} className="box" alt="" />
              </div>
              <div className="bottom">
                <div>
                  新产品使用，每个产品赠送<span className="big-red">10</span>
                  云朵
                </div>
                <div>
                  新产品每充值<span className="big-red">1000</span>元，赠送
                  <span className="big-red">50</span>云朵
                </div>
              </div>
            </div>

            <div key="112" className="box_gift_div">
              <div className="top">
                老客户邀请有礼
                <img src={box} className="box" alt="" />
              </div>
              <div className="bottom">
                <div>
                  老客户奖励：<span className="big-red">10</span>元现金红包
                </div>
                <div>
                  新客户奖励：<span className="big-red">20</span>云朵
                </div>
              </div>
            </div>

            <div key="113" className="box_gift_div">
              <div className="top">
                新用户焕春特惠
                <img src={box} className="box" alt="" />
              </div>
              <div className="bottom">
                <div>
                  新用户消费<span className="big-red">100</span>元，一次性赠送
                  <span className="big-red">20</span>云朵
                </div>
                <div>
                  新用户每充值<span className="big-red">3000</span>元，赠送
                  <span className="big-red">50</span>云朵
                </div>
              </div>
            </div>
          </QueueAnim>
        </TweenOneCpn>
      </div>
    </div>
  );
};

// 单个的热门产品小组件
export const RecommendProductItem = ({ data, piId, submit = false }: any) => {
  const { userInfo } = useSelector((state: StateType) => state) as any;
  const { baseInfo = [] } = userInfo || [];
  const history = useHistory();
  const onCharge = useAlertNotLogin(baseInfo);

  const dispatch = useDispatch();
  // 菜单点击事件
  const submitBuy = async () => {
    const { ppgName, ppgModelAmountName, ppgModelLenName } = data || {};
    history.push(
      `/shopping/purchase?piId=${piId}&ppgModel1st=${ppgName}&ppgModelAmountName=${ppgModelAmountName}&ppgModelLenName=${ppgModelLenName}`,
    );
  };

  return (
    <div key="89089809" className="activities_recommendProductItem">
      <TweenOneCpn
        style={{ height: 450, width: '100%' }}
        playscale={[0.1, 0.2]}
      >
        <QueueAnim
          key="8977869768678678"
          leaveReverse
          type="bottom"
          delay={300}
          forcedReplay={true}
          className=""
          appear={true}
        >
          <h2 key="123546546456" className="title">
            {data.piName}
          </h2>
          <div key="987345645" className="text">
            {data.ppgName}
          </div>
          <div key="689878" className="introduce">
            <div>
              <CheckOutlined className="icon" />
              套餐类型:{data.ppgName}
            </div>
            <div>
              <CheckOutlined className="icon" />
              套餐规格:{data.ppgModelAmountName}
            </div>
            <div>
              <CheckOutlined className="icon" />
              有效时长:{data.ppgModelLenName}
            </div>
          </div>
          <div key="45654" className="priceDiv">
            <span className="price">{data.ppgSaleTotalPrice}</span>
            <span className="company">元</span>
            <span
              className={`originalPrice ${
                data.ppgDiscount === 100 ? 'opacity' : ''
              }`}
            >
              {data.ppgSourceTotalPrice}元
            </span>
            <br />
            <div
              className={`discount ${
                data.ppgDiscount === 100 ? 'opacity' : ''
              }`}
            >
              {data.ppgDiscount / 10}折优惠
            </div>
          </div>
          <div
            key="666"
            className="redBtn"
            onClick={() => submit ? submit() :  onCharge(() => submitBuy())}
          >
            {data.btnText ? data.btnText : '立即购买'}
          </div>
        </QueueAnim>
      </TweenOneCpn>
    </div>
  );
};

// 单个的热门产品小组件
const RecommendProductItem_small = ({ data, piId }: any) => {
  const { userInfo } = useSelector((state: StateType) => state) as any;
  const { baseInfo = [] } = userInfo || [];
  const history = useHistory();
  const onCharge = useAlertNotLogin(baseInfo);

  const dispatch = useDispatch();
  // 菜单点击事件
  const submitBuy = async () => {
    const { ppgName, ppgModelAmountName, ppgModelLenName } = data || {};
    history.push(
      `/shopping/purchase?piId=${piId}&ppgModel1st=${ppgName}&ppgModelAmountName=${ppgModelAmountName}&ppgModelLenName=${ppgModelLenName}`
    );
  };

  return (
    <div key="89089809" className="RecommendProductItem_small">
      <div className="internal_div">


      <TweenOneCpn style={{ height: 106, width: "100%" }} playscale={[0.1, 0.2]}>
        <QueueAnim
          key="8977869768678678"
          leaveReverse
          type="bottom"
          delay={300}
          forcedReplay={true}
          className=""
          appear={true}
        >
          <h2 key="123546546456" className="title">
            {data.piName}
          </h2>
          <div key="689878" className="introduce">
            <div>
              <CheckOutlined className="icon" />
              套餐类型:{data.ppgName}
            </div>
            {data.ppgModelAmountName ? <div>
              <CheckOutlined className="icon" />
              套餐规格:{data.ppgModelAmountName}
            </div> : null}
          </div>
        </QueueAnim>
      </TweenOneCpn>
      </div>
    </div>
  );
};

const HotProDiv = () => {
  const proList_msg = [
    {
      piName: '国内短信',
      ppgName: '验证码',
      ppgModelAmountName: '100条',
      ppgModelLenName: '1月',
      ppgSaleTotalPrice: '0元',
      ppgDiscount: 0,
      ppgSourceTotalPrice: '4.5元',
    },
    {
      piName: '国内短信',
      ppgName: '验证码',
      ppgModelAmountName: '1万条',
      ppgModelLenName: '2年',
      ppgSaleTotalPrice: '396元',
      ppgDiscount: 88,
      ppgSourceTotalPrice: '450元',
    },
    {
      piName: '国内短信',
      ppgName: '验证码',
      ppgModelAmountName: '5万条',
      ppgModelLenName: '2年',
      ppgSaleTotalPrice: '1935元',
      ppgDiscount: 86,
      ppgSourceTotalPrice: '2250元',
    },
  ];
  const proList_cdn = [
    {
      piName: "CDN",
      ppgName: "按流量计费 / 中国境内",
      ppgModelAmountName: "200GB",
      ppgModelLenName: "1月",
      ppgSaleTotalPrice: "0",
      ppgDiscount: 0,
      ppgSourceTotalPrice: "26.3",
      btnText: '立即领取'
    },
    {
      piName: "CDN",
      ppgName: "按流量计费 / 中国境内",
      ppgModelAmountName: "500GB",
      ppgModelLenName: "1月",
      ppgSaleTotalPrice: "65.09",
      ppgDiscount: 99,
      ppgSourceTotalPrice: "65.75",
    },
    {
      piName: "CDN",
      ppgName: "按流量计费 / 中国境内",
      ppgModelAmountName: "5TB",
      ppgModelLenName: "6月",
      ppgSaleTotalPrice: "659.81",
      ppgDiscount: 98,
      ppgSourceTotalPrice: "673.28",
    },
  ];
  const proList_cdn_small = [
    {
      piName: "免费赠送 国内短信 1000条",
      ppgName: "验证码/短信通知",
      ppgModelAmountName: "1000条",
    },
    {
      piName: "身份认证和实人认证产品 9折优惠券",
      ppgName: "二三四要素身份认证 / 人脸识别",
    },
  ];
  const priceList = [
    {
      a: '500G',
      b: '66',
      c: '100GB',
      d: '1个月',
    },
    {
      a: '1TB',
      b: '129',
      c: '500GB',
      d: '1个月',
    },
    {
      a: '5TB',
      b: '620',
      c: '1TB',
      d: '1个月',
    },
    {
      a: '10TB',
      b: '1200',
      c: '5TB',
      d: '1个月',
    },
    {
      a: '50TB',
      b: '5500',
      c: '5TB',
      d: '6个月',
    },
    {
      a: '200TB',
      b: '17800',
      c: '10TB',
      d: '1个月',
    },
    {
      a: '1PB',
      b: '83000',
      c: '10TB',
      d: '6个月',
    },
  ];
  return (
    <div className="activities_hot">
      <Title
        title="新客户专享"
        // text="0元套餐包免费领取，余量扣费免费开通，赠送云朵"
      />
      {/* 短信服务 推荐套餐 */}
      {/* <div className="mt-6">
        <div
          className="top"
          style={{ background: `url(${msg_div})`, backgroundSize: 'cover' }}
        >
          <img src={msg} className="hotIcon" alt="" />
        </div>
        <div className="bottom justify-between">
          {proList_msg.map((item, index) => (
            <RecommendProductItem key={`msg_${index}`} piId={5} data={item} />
          ))}
        </div>
      </div> */}
      {/* cdn推荐套餐 */}
      <div className="mt-12">
        <div
          className="top"
          style={{ background: `url(${cdn_hot})`, backgroundSize: 'cover' }}
        >
          <img src={hotIcon} className="hotIcon" alt="" />
        </div>
        <div className="bottom justify-between">
          {proList_cdn.map((item, index) => (
            <RecommendProductItem key={`cdn_${index}`} piId={21} data={item} />
          ))}
        </div>
      </div>
      {/* CDN 百万PB免费送 */}
      <div className="mt-12">
        <div
          className="top"
          style={{ background: `url(${cdn})`, backgroundSize: 'cover' }}
        >
          {/* <div className="cdn_title_text">
            购买流量包不限有效期，赠送流量包有对应的有效期。
            <br />
            如果用户同时购买了多个套餐包，可以选择优惠大的方案。
          </div> */}
          <img src={hotIcon} className="hotIcon" alt="" />
        </div>
        <div className="bottom " style={{ display: 'block' }}>
          <Row className="row_div  bg-gray-600 text-gray-50">
            <Col span={6}>购买规格</Col>
            <Col span={6}>套餐包价格（单位：元）</Col>
            <Col span={6}>赠送流量包</Col>
            <Col span={6}>赠送有效期</Col>
          </Row>
          {priceList.map((item, index) => (
            <Row
              key={index}
              className={
                index % 2 === 0 ? 'row_div bg-white' : 'row_div  bg-gray-50'
              }
            >
              <Col span={6}>{item.a}</Col>
              <Col span={6}>{item.b}</Col>
              <Col span={6}>{item.c}</Col>
              <Col span={6}>{item.d}</Col>
            </Row>
          ))}

        </div>
      </div>

       {/* cdn开通 */}
       <div className="mt-12">
        <div
          className="top"
          style={{ background: `url(${cdn_hot})`, backgroundSize: "cover" }}
        >
          <img src={hotIcon} className="hotIcon" alt="" />
          <div className="rightText">
            *说明：CDN配置成功即可获得福利
        </div>
        </div>
        <div className="bottom_open flex justify-between">
          {proList_cdn_small.map((item, index) => (
            // <div className="item_red_div">
              <RecommendProductItem_small key={`cdn_${index}`} piId={21} data={item} />
            // </div>
          ))}

        </div>

      </div>
    </div>
  );
};

const BottomTextDiv = () => {
  const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
  return (
    <div className="bottomTextDiv">
      <div className="mb-2">新客专享活动规则：</div>
      <div>
      活动对象：未购买/领取过CDN产品的用户
        <br />
        <div className="flex mt-2">
        活动说明：
        <div >
            1、新人流量包可以与其他CDN流量包叠加使用，有效期不叠加，按照到期时间优先抵扣有效期较短的资源包。<br />
            2、若当月用量超出可用流量包额度，出账时将优先抵扣完流量包额度，超出部分再按照官网标准结算。<br />
            3、静态加速场景下 http 协议和 https 协议均不额外收取请求次数费用，动态加速场景额外收取动态加速请求次数费用。<br />
            4、下单前请仔细核对商品规格是否准确，购买七天后或已经发生抵扣的商品无法退款。<br />
            5、所有参加本活动的用户，均视为认可并同意遵守《平台用户协议》  <br />
            6、活动时间：5月10日~6月10日<br /><br />
            活动最终解释权在法律允许范围内归{ueSaasName}所有。<br />
        </div>
        </div>
        <br />
      </div>
    </div>
  );
};

export default () => {
  const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
  
  return (
    <div className="j-home-activities">
      <TopDiv />
      <div className="bottom_div">
        {/* <GiftDiv /> */}
        <HotProDiv />
        <BottomTextDiv />
        {/* 底部步骤条 */}
        <div className="mt-10">
          <Title
            title="简单3步,轻松使用"
            // text="0元套餐包免费领取，余量扣费免费开通，赠送云朵"
          />
          <div className="bottom_red_div">
            <div className="div">
              <div className=" flex justify-between">


                 <div className="flex item">
                   <img src={img1} alt="" />
                   <div>
                      <h3>注册账号</h3>
                      <span>注册{ueSaasName}账号并完成实名认证</span>
                   </div>
                 </div>
                 <img style={{height: '40px', marginTop: '30px'}} src={turnRight} alt="" />
                 <div className="flex item">
                   <img src={img2} alt="" />
                   <div>
                      <h3>添加加速域名</h3>
                      <span>前往CDN控制台，在【域名管理】添加域名</span>
                   </div>
                 </div>
                 <img style={{height: '40px', marginTop: '30px'}} src={turnRight} alt="" />

                 <div className="flex item">
                   <img src={img3} alt="" />
                   <div>
                      <h3>使用CDN服务</h3>
                      <span>查询CDN流量带宽，查看使用日志</span>
                   </div>
                 </div>
                </div>

                <div className="redBtn" onClick={()=> setHref('/register')}>立即注册</div>
            </div>

          </div>
        </div>
      </div>

    </div>
  );
};
