import ProTable, { ActionType } from "@ant-design/pro-table"
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home"
import { productApi } from "@utils/util"
import { Button, Form, Modal, Tabs } from "antd"
import React, { useRef, useState } from "react"
import ProForm, { 
    ModalForm,
    ProFormText, 
    ProFormDigit
} from '@ant-design/pro-form';
import { smsDataPushQuery, smsDataAmountLimit } from "@api/index"
import { handleDvmCommonResult } from "@utils/request"
import { useDispatch, useSelector } from "react-redux"
import { StateType } from "@store/index"
import { asyncSetSmsSetting } from "@store/actions"

const updateApi = productApi(smsDataAmountLimit)


export default ({...rest}: any) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch();
    const { ueId, ueSmsDayLimit, ueSingleSmsDayLimit } = useSelector((store: StateType) => store?.smsSetting) as any
    const onFinish = async (values: any) => {
        await updateApi({...values, ueId}, true)
        await dispatch(asyncSetSmsSetting()) // 获取国内短信设置
    }
    return (<ProForm<any> 
        form={form} 
        onFinish={onFinish} 
        submitter={false} 
        style={{background: '#fff', padding: 20, minHeight: 'calc(100vh - 260px)'}} 
        request={async () => {
            return {
                ueSmsDayLimit, 
                ueSingleSmsDayLimit
            }
        }}
        {...rest}>
        <ProFormDigit 
            name="ueSmsDayLimit" 
            width="md"
            label="每日发送上限(条)"
            min={0}
            max={1000000000}
            fieldProps={{ precision: 0 }}
            rules={[
                { required: false, message: '请填写发送上限'}, 
            ]} 
        />
        <ProFormDigit 
            name="ueSingleSmsDayLimit" 
            width="md"
            label="每日单个手机号发送上限(条)"
            min={0}
            max={1000000000}
            fieldProps={{ precision: 0 }}
            rules={[
                { required: false, message: '请填写每日单个手机号发送次数上限'}, 
            ]} 
        />
        <Form.Item>
            <Button 
                type="primary" 
                size="large" 
                htmlType="submit" 
            >
                保存
            </Button>
        </Form.Item>
    </ProForm>)
}