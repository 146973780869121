import React, { useRef, useState } from "react"
import { useTableHooks } from "@hooks/index"
import { columnsFn } from './data'
import { Alert, Button, Col, Modal, Row, Table } from "antd"
import { productApi } from "@utils/index"
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home"
import { ProFormCascader, SearchBox } from "@components/index"

import { ActionType, ProTable } from "@ant-design/pro-table"
import { ProFormProps, ProForm, ProFormText, ModalForm, ProFormRadio, ProFormUploadDragger, ProFormSelect, ProFormTextArea } from '@ant-design/pro-form' 
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { toolWhiteListAdd, toolWhiteListDel, toolWhiteListPageList, toolWhiteListUpdateStatus } from "@api/tool"
import { handleDvmCommonPageListResult } from "@utils/request"
import { useSelector } from "react-redux"
import { StateType } from "@store/index"
import {
    InfoCircleFilled
} from '@ant-design/icons';
import { Description } from "@components/Common/AlertTip"

// 列表操作的 类型校验
type Action = '新增' | '修改' | '删除' | '开启状态'

// 接口请求的 api 层
const getApi = productApi(toolWhiteListPageList)  // 查询的接口封装
const addUpdateApi = productApi(toolWhiteListAdd) // 列表数据的新增/编辑 接口
const updateStatusApi = productApi(toolWhiteListUpdateStatus)
const delApi = productApi(toolWhiteListDel) // 表单数据的删除 接口
const getListApi = handleDvmCommonPageListResult(getApi)

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 16 },
};

// 新增｜编辑的 form 表单弹窗
// 弹窗参数的类型扩展
interface AddFormProps extends ModalFormProps{
    submitParams?: any;
    onSuccess?: () => void;
}
const AddFormModal = ({onSuccess, submitParams, ...rest}: AddFormProps) => {
    const { productData } = useSelector((state: StateType) => state) as any;
    const { treeData } = productData
    const onFinish = async (values: any) => {
        const { piId: proIds, ...rest } = values
        const piId = proIds ? proIds[proIds.length - 1].slice(1) : undefined
        addUpdateApi({...rest, piId: proIds ? piId : undefined, ...submitParams}, true, onSuccess)
    }
    return (<ModalForm<any>
        layout="horizontal" 
        {...layout} 
        onFinish={onFinish} 
        request={async () => {
            const {initialValues} = rest
            return initialValues
        }}
        {...rest}>
           <Alert
            description="白名单功能只支持Api调用"
            type="warning"
            style={{marginBottom: 24}}
            />
        {
            submitParams.uilId ? <ProFormText width="md" name="proName" label="选择产品" disabled rules={[{required: true, message: '请选择产品'}]} />: <ProFormCascader 
            width="md" 
            name="piId" 
            label="选择产品"  
            fieldProps={{
                mode: 'multiple', 
                placeholder: '请选择产品', 
                showSearch: true, 
                filterOption: false,
                options: treeData
            }} 
            rules={[{required: true, message: '请选择产品'}]} 
        />
        }
        <ProFormTextArea
            width="md" 
            name="uilIp" 
            label="ip地址"
            fieldProps={{
                placeholder: '请填写ip地址；例如:\n127.0.0.1',
            }} 
            rules={[{required: true, message: '请填写ip'}]} 
        />
    </ModalForm>)
}

/**
 * antd 升级版企业级组件(常用)
*/
export default () => {
    const { baseInfo } = useSelector((store: StateType) => store?.userInfo) as any
    const { userId } = baseInfo
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [modalProps, setModalProps] = useState<any>({visible: false, title: '新增', submitParams: {}, initialValues: {}})
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    // operate 函数为增删改查需要处理的业务
    const operate = (type: Action, record: any) => {
        const { uilId, ptName, piName, uilIp, uilIsEnabled, piId } = record || {}
        if (type === '新增') {
            setModalProps({
                ...modalProps,
                title: '新增',
                submitParams: { userId },
                params: {_t: new Date().valueOf()},
                initialValues: {},
                visible: true,
            })
            console.log('需要处理的新增业务')
        } else if (type === '修改') {
            const proName = `${ptName}/${piName}`
            setModalProps({
                ...modalProps,
                title: '修改',
                params: {proName, _t: new Date().valueOf()},
                submitParams: { piId, userId, uilId, ptName, piName },
                initialValues: { uilIp, proName},
                visible: true,
            })
            console.log('需要处理的修改业务')
        } else if (type === '开启状态') {
            updateStatusApi({userId, ...record, uilIsEnabled: uilIsEnabled ? 0 : 1 }, true, onSuccess)
        } else if (type === '删除') {
            const uilIds = record ? [uilId] : selectedRowKeys
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                centered: true,
                cancelText: '取消',
                onOk: async() => {
                    await delApi({uilIds: uilIds.toString()}, true, () => {
                        reFresh()
                    })
                },
            });
        }
    }

    // const getListApi = async (params: any, sorter: any, filter: any) => {
    //     const { data } = await getApi({...params}, false)
    //     const { values, total } = data || {}
    //     return {
    //         data: values || [],
    //         success: true,
    //         total
    //     }
    // }
    // const getListApi = handleDvmCommonPageListResult(getApi)
    const columns = columnsFn(operate) // 表格列配置

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        setModalProps({
            ...modalProps,
            visible: false
        })
        reFresh()
    }
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const calcDelDisabled = () => selectedRowKeys.length
    return (<>
        <div style={{padding: 20, paddingBottom: 0, background: '#fff'}}>
            <Alert
                description={<Description listTips={[
                    '为了保证您购买的产品/服务只有您的服务器才能使用，我们采用了IP白名单机制，通过设置IP白名单指定您的服务器IP，除了您指定的IP外，其他IP不能使用产品/服务。',
                    '一个产品最多可添加一个IP白名单。'
                ]} />}
                type="warning"
            />
        </div>
        <ProTable<any>
            columns={columns}
            params={{userId}} // 查询所需要的额外参数
            request={getListApi}
            options={{reload: false, density: false, setting: false}}
            toolbar={{
                actions: [
                    <Button key="btn0" type='primary' onClick={() => operate('新增', null)}>新增</Button>,
                    // <Button key="btn1" type='primary' disabled={!calcDelDisabled()} onClick={() => operate('删除', null)}>删除</Button>,
                ],
            }}
            rowKey="uilId"
            pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
            actionRef={ref as any}
            search={{
                optionRender: (searchConfig, formProps, dom) => [ ...dom.reverse() ]
            }}
            // rowSelection={{
            //     preserveSelectedRowKeys: true,
            //     onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
            // }}
        />
        <AddFormModal
            modalProps={{
                maskClosable: false, 
                destroyOnClose: true,
                onCancel: () => setModalProps({...modalProps, visible: false})
            }}
            onSuccess={onSuccess} 
            {...modalProps} 
        />
    </>)
}
