import { Button, Card, CardProps, Col, Input, Row, Select, Tooltip, Typography } from "antd"
import React from "react"
import { smsDataPushQuery, smsDataPushUpdate } from "@api/index"
import { handleDvmCommonResult } from "@utils/request"
import { productApi } from "@utils/index"
import { useCommonApiHooks } from "@hooks/index"
import { useSelector } from "react-redux"
import { StateType } from "@store/index"

const getApi = productApi(smsDataPushQuery)
const updateApi = productApi(smsDataPushUpdate)

const { Paragraph } = Typography;

// 短信服务，国际短信 API调用密钥 公共部分
interface CommonAPIKeyProps extends CardProps {
    data?: { accessKey: string, accessSecret: string }
}
export const CommonAPIKey = ({children, data, ...rest}: CommonAPIKeyProps) => {
    const {accessKey, accessSecret} = data || {}
    return (<Card title="短信服务 API 调用密钥" {...rest}>
        <Input.Group compact>
            <Input addonBefore="accessKey" disabled value={accessKey} style={{width: 'calc(100% - 200px)'}} />
            <Button>
                <Paragraph copyable={{text: accessKey}}></Paragraph>
            </Button>
        </Input.Group>
        <Input.Group compact style={{margin: '20px 0'}}>
            <Input addonBefore="accessSecret" disabled value={accessSecret} style={{width: 'calc(100% - 200px)'}} />
            <Button>
                <Paragraph copyable={{text: accessSecret}}></Paragraph>
            </Button>
        </Input.Group>
        {children}
        <div>温馨提醒：请妥善保管好您的密钥，不要泄露密钥！</div>
    </Card>)
}

export default () => {
    const { ueAccessKey: accessKey, ueAccessSecret: accessSecret } = useSelector((store: StateType) => store?.smsSetting) as any
    return (<CommonAPIKey title="API 调用密钥" data={{accessKey, accessSecret}}>
        {/* 强哥说这部分不要了 */}
        {/* <Input.Group compact>
            <Select defaultValue="mysite" style={{width: '200px'}} />
            <Input disabled defaultValue="mysite" style={{width: 'calc(100% - 400px)'}} placeholder="套餐码" />
            <Button>
                <Paragraph copyable={{text: 'sddfg'}}></Paragraph>
            </Button>
        </Input.Group> */}
    </CommonAPIKey>)
}