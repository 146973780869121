import { ProColumns } from "@ant-design/pro-table";
import { Icon } from "@components/Common";
import { Tooltip } from "antd";
import React from "react";

export const columnsFn: (operate?: Function) => ProColumns<any>[] = (operate) => [
    {
        title: '交易时间',
        dataIndex: 'transactionTime',
        key: 'transactionTime',
    },
    {
        title: '收支类型',
        dataIndex: 'expenseType',
        key: 'expenseType',
    },
    {
        title: () => <span>交易类型<Tooltip title={(<div>
            （1）系统赠送：由平台赠送给用户的虚拟币。<br/>
            （2）购买套餐包：客户在平台购买产品套餐包，扣除云朵。<br/>
            （3）余额扣费：系统按产品实际使用量扣除云朵。<br/>
            （4）失败回补：在调用接口时，系统实时扣费，接口调用失败，系统将已扣除的云朵回退到账户中。<br/>
            （5）订单退款：由于订单退款产生的云朵自动回退到账户中。<br/>
        </div>)}><Icon type="icon-ziyuan" style={{marginLeft: 6}} /></Tooltip></span>,
        dataIndex: 'transactionType',
        key: 'transactionType',
    },
    {
        title: '云朵(个)',
        dataIndex: 'clouds',
        key: 'clouds',
    },
]