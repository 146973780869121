import React, { useEffect, useState } from "react"
import HomeHeader from "../../Layout/HomeHeader"
import { Button, InputNumber, Table, Modal, message, Checkbox } from 'antd';
import {
  PlusOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { columns } from "./data";
import { homeDelShoppingCar, homeEditShoppingCarNumber, homeGetShoppingCar, homePostShoppingCarSubmit } from "@api/home";
import { handleDvmCommonListResult } from "@utils/request";
import { productApi, toFixedNoRound } from "@utils/util";
import { useHistory } from "react-router-dom";
const { confirm } = Modal;

import './index.less'
import { handle_buy } from "../Purchase";

// 接口请求的 api 层
const delApi = productApi(homeDelShoppingCar)  // 购物车删除
// 结算台
interface SettlementProps {
  selections: any;
  operate?: any;
  tableList: Array<any>;
  checkChange: any
}
const Settlement = ({ selections, tableList, operate, checkChange }: SettlementProps) => {
  const { countPrice, selectedKeys } = selections || {}
  const [ allCheck, setAllCheck ] = useState(false)
  const [ indeterminate, setIndeterminate ] = useState(true)

  
  
  const history = useHistory()


  const submit = async () => {
    if (selectedKeys.length === 0) {
      message.info('没有选中的商品');
      return
    }
    const list = tableList.filter((item: any) => selectedKeys.includes(item.ocaId))
    const { data } = await homePostShoppingCarSubmit({
      packages: list.map((item: any) => {
        const { odPackageAmount, ppgId } = item || []
        return {
          ppgId,
          odPackageAmount
        }
      })
    }) as any
    handle_buy(data, ()=> {
      history.push(`/shopping/orderSubmit?oiId=${data.oiId}`)
    })
    // const resdata = handleDvmCommonListResult(result)
  }


  useEffect(()=> {
    if (selectedKeys.length === 0 ){
      setAllCheck(false)
      setIndeterminate(false)
    } else if( selectedKeys.length === tableList.length){
      setIndeterminate(false)
      setAllCheck(true)
    } else if ( selectedKeys.length < tableList.length){
      setIndeterminate(true)
    }
  }, [selectedKeys, tableList])

  return (<div className="bg-white fixed items-center bottom-0 flex w-full justify-between p-4 px-40" style={{ boxShadow: '0px 2px 8px 0px rgba(0,21,41,0.1)' }}>
    <div className="float-left">
    <Checkbox
        indeterminate={indeterminate}
        style={{marginRight: '10px'}}
        onChange={(data:any) => {setAllCheck(data), setIndeterminate(false), checkChange(data)}}
        checked={allCheck}
    ></Checkbox>
      已选（{selectedKeys.length}/{tableList.length}）
      <a className="ml-4" onClick={() => operate('删除')}>批量删除</a>
    </div>
    <div className="flex justify-end ">
      <div className="mr-2">总费用：</div>
      <div className="text-red-500 text-4xl font-semibold mr-4">
        ￥{toFixedNoRound(countPrice, 2)}

      </div>
      <Button type="primary" danger className="mr-4" onClick={() => submit()}>去结算</Button>
    </div>

  </div>)
}


export default () => {
  const [selections, setSelections] = useState({
    countPrice: 0,
    selectedKeys: [],
  });
  const [tableList, setTableList] = useState([] as any)



  const onChange = (selectedRowKeys: any, selectedRows: any) => {
    let [countPrice, number] = [0, selectedRows.length, tableList.length]
    selectedRows.forEach((element: any) => {
      countPrice += Number(element.price) * Number(element.odPackageAmount)
    });
    setSelections({ countPrice, selectedKeys: selectedRowKeys })
  };
  interface OperateProps {
    type: string;
    data?: Array<any>;
    index?: number
  }
  const operate = async (type: string, data: any = selections.selectedKeys, index: number = 0) => {
    const { ocaId } = tableList[index] || []
    if (type === '删除') {
      if(data.length){
        confirm({
          title: '确定从购物车中移除这些产品吗?',
          icon: <ExclamationCircleOutlined />,
          content: '点击确定将移除',
          centered: true,
          onOk: async () => {
            await delApi({ ocaIds: data }, true)
            onChange([], [])
            getData()
          }
        });

      } else {
        message.error('当前选项为空')
      }
      
    } else if (type === '数量') {
      const { ocaId } = tableList[index] || []
      await homeEditShoppingCarNumber({ amount: data, ocaIds: [ocaId] }) as any
      const list = [...tableList]
      list[index].odPackageAmount = data
      setTableList(list)
      onChange(selections.selectedKeys, tableList.filter((item: any) => selections.selectedKeys.includes(item.ocaId)))

    }
  }

  const getData = async () => {
    const { result } = await homeGetShoppingCar() as any
    const data = handleDvmCommonListResult(result)
    setTableList(data)
  }

  useEffect(() => {
    getData()
    setSelections({ countPrice: 0, selectedKeys: [] as any })
  }, [])

  const checkChange = (data: any) => {
    const check = data.target?.checked || false
     if (check) {
      setSelections({
        ...selections,
        selectedKeys: tableList.map((item:any)=> item.ocaId)
      })
     } else {
      setSelections({
        ...selections,
        selectedKeys: []
      })
     }
  }

  return (<div className="h-screen">
    <HomeHeader showMenu={false} />
    <div className="px-40 pt-8 overflow-auto j-home-shoppingCar-content" style={{ paddingTop: '64px' }}>
      <p className="mt-8 text-2xl">购物车</p>
      <Table
        columns={columns(operate)}
        pagination={false}
        scroll={{ y: 600 }}
        bordered
        dataSource={tableList}
        rowKey='ocaId'
        rowSelection={{
          selectedRowKeys: selections.selectedKeys,
          onChange: onChange
        }}
      />
    </div>
    {/* 结算组件 */}
    <Settlement checkChange={checkChange} operate={operate} tableList={tableList} selections={selections} />
  </div>)
}