import { SET_AREA_TREE, SET_HISTORY_LIST, SET_PRODUCT_DATA, SET_ALL_COUNTRY, SET_SHOPPINGCAR_NUMBER, SET_TASK, SET_PREVIEW, SET_DOC_TREE } from "@store/contants"
import { getAreaTree as getAreaTreeApi, homeGetProductTree as getProductTreeApi, homeGetCountry, homeGetShoppingCarNumber, getExportTask } from '@api/index'
import { docGetDocTreeData } from "@api/doc"
import { handleAreaTree, handleCommonTreeData, handleProductTree } from "@utils/index"
import { PreviewType } from ".."

export const setHistoryList = (historyList: any[]) => {
    return {
        type: SET_HISTORY_LIST,
        historyList
    }
}

export const setAreaTree = (areaTree: any) => {
    return {
        type: SET_AREA_TREE,
        areaTree
    }
}

export const setAllCountry = (allCountry: any) => {
    return {
        type: SET_ALL_COUNTRY,
        allCountry
    }
}

export const setProductData = (productData: any) => {
    return {
        type: SET_PRODUCT_DATA,
        productData
    }
}

export const setDocTreeData = (docTreeData: any) => {
    return {
        type: SET_DOC_TREE,
        docTreeData
    }
}

export const setShoppingCarNumber = (ShoppingCarNumber: number) => {
    return {
        type: SET_SHOPPINGCAR_NUMBER,
        ShoppingCarNumber
    }
}

export const setPreviewObj = (previewObj: PreviewType) => {
    return {
        type: SET_PREVIEW,
        previewObj
    }
}

type TaskType = 'SET_TASK' | 'ADD_TASK' | 'DEL_TASK'
export const setTaskObj = (type: TaskType = SET_TASK, payload: Object) => {
    return {
        type,
        taskObj: payload
    }
}

// 异步获取省市区数据
export const asyncSetAreaTree = () => {
    return async (dispatch: any) => {
        dispatch(setAreaTree([]))
        const {result} = await getAreaTreeApi({}) as any
        const res = handleAreaTree(result)
        dispatch(setAreaTree(res))
    }
}

// 异步获取所有国家
export const asyncSetAllCountry = () => {
    return async (dispatch: any) => {
        dispatch(setAllCountry([]))
        const {result} = await homeGetCountry({}) as any
        const res = (result||[]).map(({countryCode: value, countryName: label}: any) => ({label, value}))
        dispatch(setAllCountry(res))
    }
}

// 异步获取产品树
export const asyncSetProductData = () => {
    return async (dispatch: any) => {
        dispatch(setProductData({}))
        const { result } = await getProductTreeApi({}) as any
        const res = handleProductTree(result)
        dispatch(setProductData(res))
    }
}

// 获取购物车产品总数量
export const asyncGetShoppingCarNumber = () => {
    return async (dispatch: any) => {
        dispatch(setShoppingCarNumber(0))
        const { result } = await homeGetShoppingCarNumber({}) as any
        if ( result[0].alias === 'resultcount'){
            dispatch(setShoppingCarNumber(result[0].value[0].RET))
        } else {
            dispatch(setShoppingCarNumber(result[1].value[0].RET))
        }
    }
}

// 获取文档树
export const asyncGetDocTreeData = () => {
    return async (dispatch: any) => {
        dispatch(setDocTreeData([]))
        const { data } = await docGetDocTreeData({}) as any
        const res = handleCommonTreeData(data, (v: any) => {
            const { docId, docPid, docTitle } = v
            return {...v, label: docTitle, value: docId, pId: docPid, title: docTitle, id: docId}
        })
        dispatch(setDocTreeData(res))
    }
}

const getTasksApi = async (taskIds?: any[]) => {
    const ids = Array.isArray(taskIds) ? taskIds : [taskIds]
    const allExportTaskApi = ids.map(taskId => getExportTask({taskId}))
    const taskArr = await Promise.all(allExportTaskApi) as any
    let obj: any = {};
    (ids||[]).forEach((taskId: any, i) => {
        const finish = (taskArr[i]?.data || '').length > 0
        obj[`${taskId}`] = {taskName: `异步任务-${i}`, progress: 80, finish, url: `${taskArr[i]?.data}`}
    })
    return obj
}

// 异步设置任务列表
export const asyncSetTaskObj = (taskIds?: any) => {
    return async (dispatch: any) => {
        const taskObj = await getTasksApi(taskIds)
        dispatch(setTaskObj(SET_TASK, taskObj))
    }
}
