import { Button, ButtonProps, Modal } from "antd"
import React, { useState } from "react"
import { productApi, request, setHref } from '@utils/index'
import { isFunction } from "lodash"
import { fileExportUrl, fileHttpUrl } from "@api/baseUrl";
import { useDispatch } from "react-redux";
import { setTaskObj } from "@store/actions";
import { useHistory } from "react-router-dom";

interface ExportButtonProps extends ButtonProps{
    action?: string;
    method?: 'GET' | 'POST' | 'DELETE' | 'PUT';
    param?: Record<string, any> | ((param?: any) => Object);
    btnText?: string;
    onSuccess?: () => void;
}

export const useExportHooks = ({action, method, onSuccess}: ExportButtonProps) => {
    const [loding, setLoading] = useState<boolean>(false)
    const dispatch = useDispatch()
    const methods = method ? { method } : {}
    const api = productApi((data?: object) => request({ url: action, data, ...methods }))
    const exportApi = async (param: any) => {
        setLoading(true)
        await api(isFunction(param) ? param() : param, false, (data: any) => {
            // const { file: filename } = data || {}
            // const url = fileExportUrl(filename)
            // filename && window.open(url)
            const { exportType, serialNumber } = data || {} //exportType: 1 同步，2 异步
            if (exportType === 1) {
                dispatch(setTaskObj('ADD_TASK', {[serialNumber]: {}}))
            } else if (exportType === 2) {
                Modal.confirm({
                    title: '温馨提示',
                    content: <div>后端已接收该导出任务，具体请前往<a onClick={() => setHref('/account/taskList')}>导出记录</a>查看</div>,
                    okText: '确认',
                    centered: true,
                    cancelText: '取消',
                });
            }
            onSuccess && onSuccess()
        })
        setLoading(false)
    }
    return {
        loding,
        exportApi,
    }
}

export default ({action, btnText, param, onSuccess, ...rest}: ExportButtonProps) => {
    const { loding, exportApi } = useExportHooks({action, onSuccess})
    return <Button loading={loding} onClick={() => exportApi(param||{})} {...rest}>{btnText || '批量导出'}</Button>
}
