import React from "react"
import { ProColumns } from "@ant-design/pro-table";
import { Button } from "antd";
import { smsTempVarTypeOptions } from "@utils/index";
import { InputCompactSelect } from "@components/Common/Compact";
import { getEnumByCode } from "@api/home";
import { DateRangeWithDisabled } from "@components/Common";

export const statusOptions  = [
    {
        label: '未审批',
        value: 0
    },
    {
        label: '审批中',
        value: 1
    },
    {
        label: '审批通过',
        value: 2
    },
    {
        label: '审批不通过',
        value: 9
    },
]

const tempOptions = [
    {
        label: '模版名称',
        value: 'utlName'
    },
    {
        label: '模版CODE',
        value: 'utlCode'
    },
    {
        label: '模版内容',
        value: 'utlContent'
    },
]

export const templateLibColumnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '模版名称',
            dataIndex: 'utlName',
            key: 'utlName',
        },
        {
            title: '模版内容',
            dataIndex: 'utlContent',
            key: 'utlContent',
            hideInSearch: true
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            valueType: 'option',
            readOnly: true,
            render: (text: any, record: any) => [
                <Button type="link" key="应用该模版" disabled={record.isApply} style={{marginRight: 8}} onClick={() => operate('应用该模版', record)}>应用该模版</Button>,
            ]
        }
    ]
}

export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '模版CODE',
            dataIndex: 'utlCode',
            key: 'utlCode',
            // initialValue: { type: 'utlContent', value: '' },
            // renderFormItem: () => <InputCompactSelect addonPosition="addonBefore" placeholder="请输入" selectProps={{options: tempOptions}} />,
            search: {
                transform: (param) => {
                    return { utlName: param }
                },
            },
        },
        {
            title: '模版名称',
            dataIndex: 'utlName',
            key: 'utlName',
            // hideInSearch: true,
        },
        {
            title: '模版类型',
            dataIndex: 'utlSmsType',
            key: 'utlSmsType',
            valueType: 'select',
            request: async () => { // 搜索条件下拉框数据，可枚举，也可从接口获取
                const { result } = await getEnumByCode({codeTypes: '605'}) as any
                console.log(446, result[0]?.value?.map(({ value, caption: label }: any) => ({ label, value })))
                return result[0]?.value?.map(({ value, caption: label }: any) => ({ label, value }))
            },
            search: {
                transform: (param) => {
                    return {utlSmsType: param}
                },
            },
        },
        {
            title: '模版内容',
            dataIndex: 'utlContent',
            key: 'utlContent',
            width: 300,
            // hideInSearch: true,
        },
        {
            title: '审核状态',
            dataIndex: 'utlVerifyState',
            key: 'utlVerifyState',
            valueType: 'select',
            request: async () => statusOptions,
            // hideInSearch: true,
        },
        {
            title: '审核备注',
            dataIndex: 'utlVerifyDescribe',
            key: 'utlVerifyDescribe',
            hideInSearch: true
        },
        {
            title: '申请说明',
            dataIndex: 'utlApplyDescribe',
            key: 'utlApplyDescribe',
            hideInSearch: true
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            valueType: 'dateRange',
            // hideInSearch: true,
            search: {
                transform: (value) => {
                    return {
                        createTimeStart: value[0] ? value[0] + ' 00:00:00' : undefined,
                        createTimeEnd: value[1] ? value[1] + ' 23:59:59' : undefined
                    }
                },
            },
            renderFormItem: (props) => <DateRangeWithDisabled />,
            render: (text: any, record: any) => record.createTime
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            valueType: 'option',
            fixed: 'right',
            render: (text: any, record: any) => [
                // <a key="详情" style={{marginRight: 8}} onClick={() => operate('详情', record)}>详情</a>,
                [0,1,9].includes(record.utlVerifyState) ? <a key="修改" onClick={() => operate('修改', record)}>修改</a> : null,
                <a key="删除" onClick={() => operate('删除', record)}>删除</a>,
            ]
        }
    ]
}
