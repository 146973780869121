/* eslint-disable react/no-unknown-property */
import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

export default (props: any) => {
  const { nodes, materials, scene } = useGLTF(
    '/assets/glbFile/clound.glb',
  ) as any;
  const ref1 = useRef() as any;
  const ref2 = useRef() as any;
  const ref3 = useRef() as any;
  const ref4 = useRef() as any;
  const ref5 = useRef() as any;

  materials['金属'] = new THREE.MeshStandardMaterial({
    precision: 'lowp',
    transparent: false,
    side: THREE.DoubleSide,
    shadowSide: THREE.FrontSide,
    roughness: 0.3, //光滑度
    metalness: 0.7,
    color: 'rgb(243, 243, 243)',
  });
  materials['红色'] = new THREE.MeshStandardMaterial({
    precision: 'lowp',
    side: THREE.DoubleSide,
    lightMapIntensity: 0.8, //烘焙光强度
    roughness: 0.01, //光滑度
    emissiveIntensity: 5,
    color: '#e34e2c',
  });

  materials['玻璃'] = new THREE.MeshStandardMaterial({
    precision: 'lowp',
    transparent: true,
    side: THREE.DoubleSide,
    opacity: 0.35,
    roughness: 0.0, //光滑度
    metalness: 0.7,
    color: 'rgb(243, 243, 243)',
  });

  function Rotation({ v = new THREE.Vector3() }) {
    return useFrame((data: any) => {
      // const t = data.clock.elapsedTime
      // data.camera.position.lerp(v.set(Math.sin(t / 3), 0.3, 2.7 + Math.cos(t / 5) / 3), 0.1)
      ref1.current.rotation.z += 0.01;
      ref2.current.rotation.x -= 0.0025;
      ref2.current.rotation.z += 0.004;
      ref3.current.rotation.z -= 0.01;
      ref4.current.rotation.y += 0.01;
      ref5.current.rotation.y += 0.01;
    });
  }

  return (
    <group {...props} dispose={null}>
      <Rotation></Rotation>
      <group position={[1.76, 0.67, 1.9]} ref={ref4}>
        <mesh
          receiveShadow
          castShadow
          geometry={nodes.球体1.geometry}
          material={materials.金属}
          position={[78.1, 0.34, -88.03]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={7.09}
        />
        <mesh
          receiveShadow
          castShadow
          geometry={nodes.环体005.geometry}
          material={materials['玻璃']}
          position={[-1.76, -0.67, -1.9]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={162.83}
        />
        <mesh
          receiveShadow
          castShadow
          geometry={nodes.球体.geometry}
          material={materials.金属}
          position={[-76.35, 0.34, 89.93]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={7.09}
        />
      </group>
      <group position={[0, 50.84, 0]}>
        <mesh
          receiveShadow
          castShadow
          ref={ref5}
          geometry={nodes.立方体.geometry}
          material={materials.金属}
          position={[0, -1.84, 0]}
          rotation={[0.89, 0.71, 2.46]}
          scale={100}
        />
        <mesh
          receiveShadow
          castShadow
          geometry={nodes.小小圈.geometry}
          material={materials['玻璃']}
          position={[0, 11.31, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <mesh
          receiveShadow
          castShadow
          geometry={nodes.Curve001.geometry}
          material={materials.红色}
          position={[0, -50.84, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <mesh
          receiveShadow
          castShadow
          ref={ref1}
          geometry={nodes.小火箭.geometry}
          material={materials.金属}
          position={[0, -51.14, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        >
          <mesh
            geometry={nodes.平面.geometry}
            material={materials.金属}
            position={[-0.23, -0.02, 1.2]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[0.06, 0.1, 0.1]}
          />
          <mesh
            geometry={nodes.平面001.geometry}
            material={materials.金属}
            position={[0.23, -0.02, 1.2]}
            rotation={[-Math.PI / 2, 0, -Math.PI]}
            scale={[0.06, 0.1, 0.1]}
          />
        </mesh>
        <mesh
          receiveShadow
          castShadow
          ref={ref2}
          geometry={nodes.遮罩圆.geometry}
          material={materials['玻璃']}
          position={[0, 92.5, 0]}
          rotation={[-Math.PI / 2, 0, -2.98]}
          scale={100}
        />
      </group>
      <mesh
        geometry={nodes.环体.geometry}
        material={materials['玻璃']}
        position={[0, 37.79, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={100}
      />
      {/* <mesh geometry={nodes.环体001.geometry} material={nodes.环体001.material} position={[0, 37.79, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={100} /> */}
      <mesh
        receiveShadow
        castShadow
        ref={ref3}
        geometry={nodes.中间的转圈圆.geometry}
        material={materials.金属}
        position={[0, 37.79, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={100}
      />
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.柱体001.geometry}
        material={materials.金属}
        position={[0, 41.87, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={100}
      />
    </group>
  );
};

useGLTF.preload('/assets/glbFile/clound.glb');
