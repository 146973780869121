import React, { useEffect } from "react"
import { BackTop, Badge, Modal } from "antd";
import HomeHeader from "./HomeHeader"
import HomeFooter from "./HomeFooter"
import { useHistory } from "react-router-dom";
import './index.less'
import {
    ShoppingCartOutlined,
    MessageOutlined
} from '@ant-design/icons';
import { fileHttpUrl } from "@api/baseUrl";
import { useDispatch, useSelector } from 'react-redux'
import { asyncGetShoppingCarNumber, asyncSetUserInfo } from "@store/actions";
import { StateType } from "@store/index";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getToken, isBrowser, setHref } from "@utils/util";
import { Icon } from "@components/Common";
import { calcCertStatus, CERT_COMPANY, CERT_COMPANY_CERTING, CERT_COMPANY_FAIL, CERT_COMPANY_SUCCESS, CERT_PERSON, CERT_PERSON_CERTING, CERT_PERSON_FAIL, CERT_PERSON_SUCCESS, CERT_SUCCESS, CERT_UNCERT, CERT_UPGRADE, CERT_UPGRADE_CERTING, CERT_UPGRADE_FAIL, CERT_UPGRADE_SUCCESS } from "@utils/enum";

const { confirm } = Modal;

export const WeChartImg = () => {
    const { ueSaasWechatQrcode } = useSelector((state: StateType) => state.domainInfo) as any;

    return (<div className="WeChartImgDiv">
        <div className="top">
            <div className="title">加微咨询</div>
            <p>一对一服务，更有优惠惊喜</p>
        </div>
        <div className="QRcode">
            <div className="imgBg">
                <img src={fileHttpUrl(ueSaasWechatQrcode)} alt="" />
            </div>
        </div>
    </div>)
}

// export const alertNotLogin = (fun: any, verifyState: any = false) => {
//     if (!getToken()) {
//         confirm({
//             title: '您当前还没有登录',
//             icon: <ExclamationCircleOutlined />,
//             content: '登录之后即可使用系统',
//             okText: '立即登录',
//             onOk:()=> {
//                 window.location.href = '/login'
//             }
//         });
//         return
//     } else if (![CERT_PERSON, CERT_COMPANY ].includes(verifyState) && verifyState !== false) {
//         confirm({
//             title: '请认证通过之后再操作哦',
//             icon: <ExclamationCircleOutlined />,
//             content: '当前未认证或者未通过，请等待认证后再操作',
//             okText: '去认证',
//             onOk:()=> {
//                 window.location.href = '/account/certification'
//             }
//         });
//         return
//     } else {
//         fun()()
//     }

// }

export const CertificationStatusMaps: any = (type: any) => {
    switch (type) {
      case CERT_UNCERT:
        return '您尚未进行实名认证，请认证后再操作'
      case CERT_PERSON_CERTING:
        return '实名认证正在审核中，请耐心等待'
      case CERT_PERSON_FAIL:
        return '实名认证审核未通过，请重新提交认证'
      case CERT_COMPANY_CERTING:
        return '实名认证正在审核中，请耐心等待'
      case CERT_COMPANY_FAIL:
        return '实名认证审核未通过，请重新提交认证'
      case CERT_UPGRADE_CERTING:
        return '实名认证正在审核中，请耐心等待'
      case CERT_UPGRADE_FAIL:
        return '实名认证审核未通过，请重新提交认证'
      default:
        return '您当前尚未通过实名认证，请通过后操作'
    }
  }

export const useAlertNotLogin = (baseInfo: any) => {


    const { ueIsRealname} = baseInfo || {}
    return (fun: Function) => {
        if (!getToken()) {
            // confirm({
            //     title: '您当前还没有登录',
            //     icon: <ExclamationCircleOutlined />,
            //     content: '登录之后即可使用系统',
            //     okText: '立即登录',
            //     centered: true,
            //     onOk:()=> {
            //         window.location.href = '/login'
            //     }
            // });
            setHref('/login')
            return
        } else if (ueIsRealname !== 1) {
            const { username, registerTime, userNature: type, verifyState: status } = (baseInfo || {}) as any
            const STATUS = calcCertStatus(type, status)
            console.log(112, STATUS)

            confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: CertificationStatusMaps(STATUS),
                okText: '去认证',
                centered: true,
                onOk:()=> {
                    setHref('/account/certification')
                }
            });
            return
        } else {
            fun && fun()
        }
    }
}

// 右侧悬浮小区域
export const PopUpArea = () => {
    const history = useHistory()
    const dispatch = useDispatch();
    const { baseInfo } = useSelector((store: StateType) => store?.userInfo) as any
    const { ShoppingCarNumber } = useSelector((state: StateType) => state) as any;
    const onCharge = useAlertNotLogin(baseInfo)
    useEffect(() => {
        getToken() &&  dispatch(asyncGetShoppingCarNumber())
    }, [])

    return (<div className="j-home-shoppingcar-PopUpAreaDiv pc-component">
        <div className="shoppingCar">
            <Badge count={ShoppingCarNumber} size="small">
                <Icon type="icon-gouwuche" style={{fontSize: 25}} onClick={() => onCharge(()=> history.push('/shoppingCar'))}/>
            </Badge>
        </div>
        <div className="msg">
            {/* <MessageOutlined /> */}
            <Icon type="icon-duihua" style={{fontSize: 25}}/>

            <WeChartImg />
        </div>
    </div>)
}

const HomeLayOut = ({ RouterView, children, footerInitialProps }: any) => {
    return (<div className="j-home-layout-slot">
        <HomeHeader />
        {children}

        {/* 购物车悬浮块 */}
        <PopUpArea />

        {/* 回到顶部 */}
        <BackTop className="j-ant-backTop-style-cover"/>

        <HomeFooter initialProps={footerInitialProps} />
    </div>)
}

const getInitialProps = async () => {
  const footerInitialProps = await HomeFooter.getInitialProps()
  return {
    footerInitialProps
  }
}

HomeLayOut.getInitialProps = getInitialProps

export default HomeLayOut
