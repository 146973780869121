import { ProColumns } from "@ant-design/pro-table";
import { calcUnitLabel, unitOptions } from "@utils/enum";
import { parseSearch, stringifySearch } from "@utils/util";
import { Badge, Input, Progress, Select } from "antd";
import React, { ReactNode, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

export const cloudStatusOptions = [
    {label: '有效', value: 1}, 
    {label: '失效', value: 2},
]

const options = [{label: 'options1', value: 1}, {label: 'options2', value: 2}]


const valueEnum = {
    // 0: { text: '全部', status: 'Default' },
    1: { text: '有效', status: 'Sueecss' },
    2: { text: '已失效', status: 'Error' },
}


const valueEnumCharge = {
    0: { text: '不收费', status: 'Default' },
    1: { text: '收费', status: 'Sueecss' },
}

// old 枚举
// export const unitOptions = [
//     {
//       label: 'GB',
//       value: 1
//     },
//     {
//       label: 'TB',
//       value: 'TB'
//     },
//     {
//       label: '次',
//       value: '次'
//     },
//     {
//       label: '次',
//       value: 2
//     },
//     {
//       label: '亿次',
//       value: '亿次'
//     }
// ]



/**
 * 列表表格配置:
 *  自定义表格 CustomerDemo 和 antd 升级版组件 ProTableDemo 都可使用, 
 * */
export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    const history = useHistory()
    const { search } = useLocation();
    const { packageName, ...rest } = parseSearch(search)
    packageName &&  history.replace({search: stringifySearch({...rest})})
    return [
        {
            title: '套餐包名称',
            dataIndex: 'packageName',
            initialValue: packageName,
            key: 'packageName',
        },
        // 刘冲让去掉的
        // {
        //     title: '套餐类型',
        //     dataIndex: 'endDate',
        //     key: 'endDate',
        //     valueType: 'select',
        // },
        {
            title: '套餐包状态',
            dataIndex: 'packageStatus',
            key: 'packageStatus',
            valueType: 'select',
            request: async () => { // 搜索条件下拉框数据，可枚举，也可从接口获取
                return cloudStatusOptions
            },
            search: {
                transform: (value) => {
                    return {
                        status: value
                    }
                },
            },
        },
        {
            title: '生效时间',
            dataIndex: 'entryTime',
            key: 'entryTime',
            valueType: 'dateRange',
            fieldProps: { },
            sorter: true,
            render: (text: any, record: any) => record.entryTime,
            width: 180,
            search: {
                transform: (value) => {
                    return {
                        startTime: value[0] ? value[0] + ' 00:00:00' : undefined,
                        endTime: value[1] ? value[1] + ' 23:59:59' : undefined
                    }
                },
            },
        },
        {
            title: '失效时间',
            dataIndex: 'failureTime',
            key: 'failureTime',
            hideInSearch: true,
            sorter: true,
        },
        {
            title: '使用情况',
            dataIndex: 'feeStr',
            order: 4,
            valueType: 'select', 
            valueEnum: valueEnumCharge,
            hideInSearch: true,
            render: (text: any, record: any) => <>
              <Progress percent={(record.used/record.gross) * 100} showInfo={false} />
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <span>已用: {record.used} {calcUnitLabel(record.unit)}</span>
                <span>剩余{record.quota}{calcUnitLabel(record.unit)}/共{record.gross}{calcUnitLabel(record.unit)}</span>
              </div>
            </>
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            valueType: 'option',
            fixed: 'right',
            readOnly: true,
            render: (text: any, record: any) => [
                <a key="购买新包" style={{marginRight: 8}} onClick={() => operate('购买新包', record)}>购买新包</a>
            ]
        }
    ]
}

export const editTableColumnFn: (operate?: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '套餐分类',
            dataIndex: 'packageName',
            key: 'packageName',
            width: 300,
            editable: false,
            // render: (text: any, record: any) => record.ualModel1st === '1' ? '流量计费' : '次数计费'
        },
        {
            title: '余量预警值',
            dataIndex: 'ualLimit',
            key: 'ualLimit',
            // fieldProps: { style: {width: 150}, addonAfter: <Select style={{width: 72}} value={recorcd.ualModel1st} disabled options={unitOptions} /> },
            fieldProps:(form: any, {entity}) => {
                return { 
                    style: {width: 150}, 
                    addonAfter: <Select style={{width: 72}} value={entity.unit} disabled options={unitOptions} /> 
                }
            },
            valueType: 'digit',
        },
        {
            title: '预警开关',
            dataIndex: 'ualIsEnabled',
            key: 'ualIsEnabled',
            valueType: 'switch',
            fieldProps: {
                checkedChildren: '开启',
                unCheckedChildren: '关闭'
            },
        },
    ]
}