import request from "@utils/request";
import { fromData } from "@utils/util";
import { stringify } from "qs";
import { BASEURL } from "../baseUrl";
import { headers } from "../login";

/****************短信签名******************/ 
// 短信签名pageList
export const smsGlobalSendOnlinePageList  = (data?: object) => request({
    url:`${BASEURL}/portal/internationalMessage/onlineSend`,
    // headers,
    data
})

// 发送统计
export const smsGlobalSendStatisticPageList  = (data?: object) => request({
    url:`${BASEURL}/portal/internationalMessage/sendStatistics`,
    // headers,
    data
})

// 发送明细
export const smsGlobalSendDetailPageList  = (data?: object) => request({
    url:`${BASEURL}/portal/internationalMessage/inlSendDetails`,
    // headers,
    data
})

// 国际短信在线发送
export const smsGlobalSendOnLine  = (data?: object) => request({
    url:`${BASEURL}/web/product/sendInternationMessageByFormData`,
    // headers,
    data: fromData({ ...data})
})
