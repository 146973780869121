import React, { useEffect, useState } from "react"
import { Avatar, Button, Card, Descriptions, Alert, Divider, Row, Col, Input, Form, Popover } from "antd"
import ProForm, { 
    ModalForm,
    ProFormText, 
    ProFormUploadButton, 
    ProFormSelect, 
    ProFormTextArea, 
    ProFormDependency,
    ProFormCaptcha
} from '@ant-design/pro-form';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { sendCaptcha, userGetImgCapcha, userSetEmail, userSetPhone, userUpdatePwd } from "@api/index";
import { emailReg, phoneReg, productApi, validatorUserPassword } from "@utils/index";
import { PrefixSelector, validatorUserConfirmPassword } from "@components/Login";
import { useDispatch, useSelector } from "react-redux";
import { CertificationStatusMaps } from "../Certification";
import { useHistory } from "react-router-dom";
import { stringify } from "qs";
import { StateType } from "@store/index";
import { asyncSetUserInfo } from "@store/actions";
import { encryptionString } from '@utils/util';
import boyImg from '@assets/images/boy.png';
import {
  CERT_PERSON,
  CERT_COMPANY,
  CERT_UNCERT,
  CERT_CERTING,
  CERT_SUCCESS,
  CERT_FAIL,
  CERT_UPGRADE,
  calcCertStatus,
} from '@utils/enum'
import { Icon, ProFormTextCaptcha } from "@components/Common";

const updatePwdApi = productApi(userUpdatePwd)
const updateEmailApi = productApi(userSetEmail)
const updatePhoneApi = productApi(userSetPhone)

export const CertificationLink = ({type, status}: any) => {
  const history = useHistory()
  const STATUS = calcCertStatus(type, status)
  const onClick = () => {
    history.push(`/account/certification`)
    // switch (STATUS) {
    //   case CERT_UNCERT: // 未认证
    //     return history.push(`/account/certification?${stringify({status: STATUS})}`)
    //   case CERT_CERTING: // 认证中
    //     return history.push(`/account/certification?${stringify({status: CERT_CERTING})}`)
    //   case CERT_SUCCESS: // 认证成功
    //     return history.push(`/account/certification?${stringify({status: CERT_SUCCESS})}`)
    //   case CERT_FAIL: // 认证失败
    //     return history.push(`/account/certification?${stringify({status: CERT_FAIL})}`)
    // }
  }
  return <a onClick={onClick}>
    <CertificationStatusMaps type={type} status={status} />
  </a>
}

// 用户--基本信息
type BaseInfoProps = {
  userInfo: Object
}
export const BaseInfo = ({userInfo}: BaseInfoProps) => {
    const { username, registerTime, userNature: type, verifyState: status } = (userInfo || {}) as any
    return (<Card title="基本信息" bordered={false}>
      <div className="flex">
        <Avatar shape="square" style={{ width: 100, height: 100, marginRight: 10 }} src={boyImg} />
        <Descriptions style={{width: 'calc(100% - 300px)'}} labelStyle={{width: 80, textAlign: 'right', display: 'inline-block'}}>
          <Descriptions.Item label="用户名" span={3}><span className="text-gray-500">{username}</span></Descriptions.Item>
          <Descriptions.Item label="实名认证" span={3}>
            <CertificationLink type={type} status={status} />
          </Descriptions.Item>
          <Descriptions.Item label="注册时间" span={3}><span className="text-gray-500">{registerTime}</span></Descriptions.Item>
        </Descriptions>
    </div>
  </Card>)
}

const useImgCapchaHook = () => {
  const [data, setData] = useState<any>({imgSrc: '', key: ''})
  const getImgCapchaApi = async () => {
      const key = new Date().getTime()
      const res = await userGetImgCapcha({key}) as any
      setData({imgSrc: res?.result, key})
  }
  useEffect(() => {
      getImgCapchaApi()
  }, [])
  return {
      data,
      getImgCapchaApi
  }
}

const ImgCaptcha = ({data, getImgCapchaApi, ...props}: any) => {
  return (<Row gutter={12}>
      <Col span={14}>
          <Input placeholder='请输入图片验证码' style={{width: '137.5px'}} {...props} />
      </Col>
      <Col span={9}>
          <img style={{width: '100%', height: '100%',}} src={data?.imgSrc} onClick={getImgCapchaApi} />
      </Col>
  </Row>)
}

// 修改用户手机号
const UpdatePhone = ({...rest}: ModalFormProps) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState<boolean>(false)
    const [form] = Form.useForm()
    // const { data, getImgCapchaApi } = useImgCapchaHook() 
    const onFinish = async (values: any) => {
      const { success, data } = await updatePhoneApi({...values, type: 3}, true) as any
      if (success) {
          await dispatch(asyncSetUserInfo()) as any
          return true
      }
      if (!success) {
        // getImgCapchaApi()
      }
    }
    const onGetCaptcha = async () => {
      const {userPhone, imageCaptchaCode} = form.getFieldsValue()
      if (userPhone && imageCaptchaCode) {
        // const { code } = await sendCaptcha({userPhone, imageCaptchaCode, imageCaptchaKey: data?.key, type: 3}) as any
        const { code } = await sendCaptcha({userPhone, imageCaptchaCode, type: 3}) as any
        if (code < 0) {
          // getImgCapchaApi()
        }
        if (code !== 0) {
          throw new Error('不需要倒计时')
        }
        return
      }
      throw new Error('不需要倒计时')
    }
    return (<ModalForm<any>
        layout="horizontal"
        labelCol={{span: 3}}
        wrapperCol={{span: 8}}
        onFinish={onFinish}
        form={form}
        modalProps={{destroyOnClose: true}}
        {...rest}
        >
            <ProFormText 
              name="userPhone" 
              label="新手机号" 
              placeholder="请输入手机号"
              fieldProps={{addonBefore: PrefixSelector}} 
              rules={[
                  { required: true, message: '请输入11位手机号码'}, 
                  { pattern: phoneReg, message: '非法手机号' }
              ]} 
            />
            {/* <ProForm.Item label="图片验证码" name="imageCaptchaCode" rules={[{ required: true, message: '请输入图片验证码' }]}>
                <ImgCaptcha data={data} getImgCapchaApi={getImgCapchaApi} />
            </ProForm.Item> */}
            <ProFormCaptcha
                label="验证码"
                phoneName={['userPhone', 'imageCaptchaCode']}
                name="captcha"
                rules={[{required: true,message: '请输入验证码',}]}
                placeholder="请输入验证码"
                captchaTextRender={(timing: boolean, count: number) => {
                  if (!timing) {
                      return <Popover 
                                  placement="topRight"
                                  overlayInnerStyle={{transform: 'translateX(17px)'}}
                                  open={open}
                                  content={<ProFormTextCaptcha 
                                      name="imageCaptchaCode"
                                      fieldProps={{width: 276, height: 150, topTips: true }} 
                                      rules={[{required: true, message: '请点击检验文字验证码',}]} 
                                  />}
                              >
                                  <span onClick={() => {
                                    const {userPhone, imageCaptchaCode} = form.getFieldsValue()
                                    if (userPhone) {
                                        setOpen(!open)
                                    }
                                }}>获取验证码</span>
                              </Popover>
                  }
                  return `${count} 后重新获取`
                }}
                onGetCaptcha={onGetCaptcha}
            />    
      </ModalForm>)
}

// 修改密码
const UpdatePassWord = ({...rest}: ModalFormProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const onFinish = async (values: any) => {
      const { oldPassword, newPassword } = values
      const { success, data } = await updatePwdApi({ oldPassword: encryptionString(oldPassword), newPassword: encryptionString(newPassword) }, true) as any
      if (success) {
        if (success) {
          await dispatch(asyncSetUserInfo()) as any
          history.push(`/login`)
          return true
        }
      }
    }
    return (<ModalForm<any>
        layout="horizontal"
        labelCol={{span: 3}}
        wrapperCol={{span: 8}}
        onFinish={onFinish}
        {...rest}
        >
            <ProFormText.Password 
              name="oldPassword" 
              label="原密码" 
              fieldProps={{autoComplete: "new-password"}}
              rules={[{ required: true, message: '请输入密码',}]} 
              placeholder="请输入原登录密码"
            />
            <ProFormText.Password 
              name="newPassword" 
              label="新密码" 
              fieldProps={{autoComplete: "new-password"}}
              rules={[
                { required: true, validator: validatorUserPassword}
              ]} 
              placeholder="请输入新登录密码"
            />
            <ProFormDependency name={['newPassword']}>
              {
                ({newPassword}) => (<ProFormText.Password 
                  name="confirmPassword" 
                  label="确认密码" 
                  fieldProps={{autoComplete: "new-password"}}
                  rules={[
                    { required: true, validator: (...rest) => validatorUserConfirmPassword(...rest, newPassword)}
                  ]}
                  placeholder="请再次输入新登录密码"
                />)
              }
            </ProFormDependency>
      </ModalForm>)
}

// 修改用户信息
export const UpdateEmail = ({...rest}: ModalFormProps) => {
  const dispatch = useDispatch();
  const onFinish = async (values: any) => {
    const { success, data } = await updateEmailApi(values, true) as any
    if (success) {
      await dispatch(asyncSetUserInfo()) as any
      return true
    }
  }
  return ( <ModalForm<any>
    layout="horizontal"
    labelCol={{span: 3}}
    wrapperCol={{span: 8}}
    onFinish={onFinish}
    {...rest}
    >
    <ProFormText 
      width="md" 
      name="email" 
      label="邮箱" 
      rules={[
        {required: true, message: '请填写邮箱'}, 
        {pattern: emailReg, message: '请填写正确的邮箱'}
      ]} 
      />
  </ModalForm>)
}
  

// 用户--基本设置
export const BaseSetting = ({userInfo}: BaseInfoProps) => {
  const { userPhone, userEmail } = (userInfo || {}) as any

  return (<Card title="基本设置" bordered={false} style={{marginTop: 20, paddingBottom: 80}}>
      <Row justify="space-between" style={{padding: '20px 0', borderBottom: '1px dashed #F5F5F5'}}>
        <Col>
          <span style={{display: 'inline-block', width: 128, marginLeft: 16}}>绑定手机</span>
          <span className="text-gray-500">您当前的手机号码是：{userPhone}</span>
        </Col>
        <Col style={{paddingRight: 50}}>
          {
            userPhone ? <span style={{color: '#00B122'}}><Icon type="icon-news-success-full" /> 已绑定</span> :
            <span style={{color: '#FAAD14'}}><Icon type="icon-tixingshixin" style={{color: '#FAAD14'}} /> 未绑定</span>
          }
          <Divider type="vertical" />
          <UpdatePhone trigger={<a>立即修改</a>} title="修改手机号" />
        </Col>
      </Row>
      <Row justify="space-between" style={{padding: '20px 0', borderBottom: '1px dashed #F5F5F5'}}>
        <Col>
          <span style={{display: 'inline-block', width: 128, marginLeft: 16}}>绑定邮箱</span> 
          <span className="text-gray-500"> {userEmail ? `您当前的邮箱是：${userEmail}` : '添加邮箱，保障账户安全、接收通知更方便'} </span>
        </Col>
        <Col style={{paddingRight: 50}}>
          {
            userEmail ? <span style={{color: '#00B122'}}><Icon type="icon-news-success-full" /> 已绑定</span> :
            <span style={{color: '#FAAD14'}}><Icon type="icon-tixingshixin" style={{color: '#FAAD14'}} /> 未绑定</span>
          }
          <Divider type="vertical" />
          <UpdateEmail trigger={<a>{userEmail ? '修改邮箱' : '绑定邮箱'}</a>} title={userEmail ? '修改邮箱' : '绑定邮箱'} />
        </Col>
      </Row>
      <Row justify="space-between" style={{padding: '20px 0', borderBottom: '1px dashed #F5F5F5'}}>
        <Col>
          <span style={{display: 'inline-block', width: 128, marginLeft: 16}}>登录密码</span> 
          <span className="text-gray-500">安全性高的密码可以使帐号更安全，建议您定期更换密码以保护帐号安全。</span> 
        </Col>
        <Col style={{paddingRight: 50}}>
          <UpdatePassWord trigger={<a>立即修改</a>} title="修改密码" />
        </Col>
      </Row>
  </Card>)
}

export default () => {
  const { baseInfo } = useSelector((store: StateType) => store?.userInfo) as any
  return (<div>
      <BaseInfo userInfo={baseInfo} />
      <BaseSetting userInfo={baseInfo} />
  </div>)
}

