import { Button, Tabs } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { ActionType, EditableProTable, ProTable } from "@ant-design/pro-table"
import { handleTableSorter, phoneReg, PROCODE_SMS, productApi } from "@utils/index";
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home";
import { BASEURL, cdnSetPkgAlert, getCostPkgPageList, getCostShowQuotaSetting, cdnGetPkgAlert, smsGlobalSendDetailPageList, smsGetAlertInfo, updateQuotaSetting } from "@api/index";
import { ExportButton } from "@components/Common";
import moment from "moment";
import { columnsFn, editTableColumnFn } from "./data";
import AlertTip from "@components/Common/AlertTip";
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import ProForm, { 
  ProFormText, 
  ProFormUploadButton, 
  ProFormSelect, 
  ProFormTextArea, 
  ProFormRadio,
  ProFormCascader,
  ModalForm 
} from '@ant-design/pro-form';
import { useHistory } from "react-router-dom";

// 接口请求的 api 层
const getApi = productApi(getCostPkgPageList); // 查询的接口封装
const getAlertApi = productApi(getCostShowQuotaSetting); // 获取告警信息
const UpdateApi = productApi(updateQuotaSetting); // 更新告警信息
const delApi = productApi(homeTableDel); // 表单数据的删除 接口

const listTips: any = [
  '仅支持查询当前有效套餐包或失效时间未超一年的套餐包。',
  '套餐包用完时，超出部分将按通用单价计费，请及时按需补够新包。'
]

const alertListTips: any = [
  '当套餐包剩余量小于预警阈值时，将会发送短信提醒（最多连续提醒3天）。',
  '当有多个套餐包叠加使用时，会叠加统计剩余量。',
  // <>可在<a href='/account/safeSetting'>账号中心-安全设置</a>中修改接收提醒的手机号</>
] 

// 设置余量预警
interface SetRestAlertFormProps extends ModalFormProps{
  params?: any;
  onSuccess?: () => void;
}
const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 },
};

const SetRestAlertForm = ({onSuccess, params, ...rest}: SetRestAlertFormProps) => {
  const formRef = useRef<ProFormInstance>()
  const [editableKeys, setEditableKeys] = useState<any>([])
  const request = async (params: any) => {
      const { data } = await getAlertApi({...params}, false)
      const { phone, list } = data || {}
      const newItems = (list||[]).map(({id, ...rest}: any, i: number) => ({...rest, id: id ? id : `zqs-${i}`}))
      setEditableKeys((newItems||[]).map((v: any) => v.id))
      return { phone, list: newItems }
  }
  const onFinish = async (values: any) => {
      const { list: arr, ...restParams } = values || {}
      const list = arr.map(({ualIsEnabled, id, ...rest}: any) => ({...rest, id: (id+'').startsWith('zqs-') ? null : id, ualIsEnabled: ualIsEnabled ? 1 : 0}))
      const { success } = await UpdateApi({...restParams, list, ...params}, true, onSuccess) as any
      return success
  }
  return (<ModalForm<any>
      layout="horizontal" 
      {...layout} 
      onFinish={onFinish} 
      request={request}
      formRef={formRef}
      modalProps={{
        destroyOnClose: true
      }}
      {...rest}>
      <div style={{background: 'white'}} className='p-4'>
        <AlertTip type="warning" listTips={alertListTips} style={{marginBottom: 20}} />
      </div>
      <ProForm.Item name="list" label="报警规则">
          <EditableProTable<any>
              className="j-proform-editable"
              rowKey="id"
              style={{marginTop: '-6px'}}
              columns={editTableColumnFn()}
              recordCreatorProps={false}
              controlled
              editable={{
                  type: 'multiple',
                  deleteText: null,
                  editableKeys,
                  actionRender: (row, config, defaultDoms) => {
                      return [defaultDoms.delete];
                  },
              }}
          />
      </ProForm.Item>
      <ProFormText 
          width="md" 
          name="phone" 
          label="预警手机号" 
          fieldProps={{
              placeholder: '请输入报警手机号', 
          }} 
          rules={[{ required: true, message: '请输入11位手机号码'}, { pattern: phoneReg, message: '非法手机号' }]}
      />
  </ModalForm>)
}

export default () => {
  const history = useHistory()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [modalProps, setModalProps] = useState({
    visible: false,
    title: "新增",
    params: {},
  });
  const ref = useRef<ActionType>(null); // ProTable 组件的 ref
  const formRef = useRef<ProFormInstance>();
  // operate 函数为增删改查需要处理的业务
  const operate = (type: any, record: any) => {
    const { piId } = record || {};
    if (type === "购买新包") {
      history.push(`/shopping/purchase?piId=${piId}`)
    } else if (type === "设置余量报警") {

    }
  };

  const getListApi = async (params: any, sorter: any, filter: any) => {
    const { data } = await getApi({ ...params, ...handleTableSorter(sorter), ...filter }, false);
    const { values, total } = data || {}; // 刘冲说不要分页
    return {
      data: values || [],
      success: true,
      total,
    };
  };

  const columns = columnsFn(operate); // 表格列配置
  // 刷新页面
  const reFresh = () => {
    // @ts-ignore
    ref.current?.clearSelected();
    ref.current?.reload();
  };
  const getQueryParams = () => ({
    // @ts-ignore
    ...formRef.current?.getFieldsFormatValue(),
  });
  return (<>
    <AlertTip type="warning" listTips={listTips} />
    <ProTable<any>
      style={{ flex: 1, overflow: "scroll" }}
      // scroll={{ x: 1300 }}
      columns={columns}
      params={{productCode: PROCODE_SMS}} // 查询所需要的额外参数
      request={getListApi}
      options={{ reload: false, density: false, setting: false }}
      toolbar={{
        actions: [
          <Button key="1" type="primary" onClick={() => operate('购买新包', {piId: 5})}>购买新包</Button>,
          <Button key="2" type="primary" onClick={() => history.push('/cost/pkg')}>设置余量预警</Button>,
          // <SetRestAlertForm key="btn1" title="设置余量预警" trigger={<Button type="primary">设置余量预警</Button>} />,
        ],
      }}
      // rowKey="tiId"
      pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
      actionRef={ref as any}
      formRef={formRef}
      search={{
        optionRender: (searchConfig, formProps, dom) => [ ...dom.reverse() ]
      }}
      // rowSelection={{
      //     preserveSelectedRowKeys: true,
      //     onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
      // }}
    />
    </>
  );
};
