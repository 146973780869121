import ProTable, { ActionType } from "@ant-design/pro-table"
import { BASEURL, fileHttpUrl } from "@api/baseUrl"
import { homeGetProPrice } from "@api/home"
import { ExportButton, WithSearchTree } from "@components/Common"
import { StateType } from "@store/index"
import { PROTYPE_CDN, PROTYPE_GLOBALSMS, PROTYPE_PAAS, PROTYPE_PARTISP, PROTYPE_SAAS, PROTYPE_SAFE, PROTYPE_SMS } from "@utils/index"
import { handleDvmCommonPageListResult } from "@utils/request"
import { fromData, parseSearch, productApi } from "@utils/util"
import { Alert, Button, message } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import HomeHeader from "../../Layout/HomeHeader"
import { columns_cdn, columns_gmsg, columns_msg, columns_others, columns_safe, paasOptions, partIspOptions, saasOptions } from "./data"
import './index.less'
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { Description } from "@components/Common/AlertTip"


// 接口请求的 api 层
const getApi = productApi(homeGetProPrice)  // 查询的接口封装
const getListApi = handleDvmCommonPageListResult(getApi)

    
export default () => {
    const formRef = useRef<ProFormInstance>()
    const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    const [expandedKeys, setExpandedKeys] = useState<string[]>([])
    const [proName, setProName] = useState<string>(parseSearch(useLocation().search)['pName'] || '产品')
    const [treeActiveKey, setTreeActiveKey] = useState([`p${parseSearch(useLocation().search)['piId']}`])  // 当前选中的节点
    const [productModelType, setProductModelType] = useState(Number(parseSearch(useLocation().search)['productDetails']))  // 当前选中的节点
    const [treeData, setTreeData] = useState(null)
    const memoTreeData = useSelector((state: StateType) => state).productData.treeData
    // const getListApi = handleDvmCommonPageListResult(getApi)

    const onExpand = (expandedKeys: any) => {
        setExpandedKeys(expandedKeys)
    }
    // 节点单击事件
    const titleOperate = (e:any) => {
        const { piId = '', piModelType = '' , ptName} = e || {}
        if(piId) {
            setProName(ptName || '产品')
            setTreeActiveKey([`p${piId}`])
            setProductModelType(piModelType)
        } else {
            message.info('请选择具体的产品查看价格');
        }
       
    }
    const treeSearch = ({ filterData, expandedKeys }: any) => {
        setTreeData(filterData)
        setExpandedKeys(expandedKeys)
    }

    const operate = (type: any, record: any) => {
        const { id } = record || {}
    }

    const findCoumns = () => {
        if (productModelType === PROTYPE_SMS) {
            return columns_msg
        } else  if (productModelType === PROTYPE_GLOBALSMS) {
            return columns_gmsg
        } else  if (productModelType === PROTYPE_CDN) {
            return columns_cdn
        }  else  if (productModelType === PROTYPE_SAFE) {
            return columns_safe
        }   else  if (productModelType === PROTYPE_PARTISP) {
            return partIspOptions
        }  else  if (productModelType === PROTYPE_PAAS) {
            return paasOptions
        } else  if (productModelType === PROTYPE_SAAS) {
            return saasOptions
        } else {
            return columns_others
        }
        
        
    }
    const getQueryParams = () => (
        // @ts-ignore
        fromData({...formRef.current?.getFieldsFormatValue(), productModelType: 2, piId: 11}) 
    )

    return (<div className="j-home-proPrice j-cover-red-color">
        <HomeHeader />
        <div className="j-top-img" style={{ background: `url(${fileHttpUrl('fileServer/2022/11/09/e0e83ca4f5de4327a257957223ecfc00.jpg')}) no-repeat` }}>
            <h2 className=" text-5xl font-semibold">{ueSaasName}{proName}价格</h2>
        </div>
        <div className="j-home-proPrice-content">
            <div className="left">
                <WithSearchTree
                    style={{ marginTop: '12px' }}
                    treeData={treeData || memoTreeData || []}
                    selectedKeys={treeActiveKey}
                    
                    blockNode
                    showIcons={[]}
                    fieldNames={{
                        key: 'id'
                    }
                    }
                    expandedKeys={expandedKeys}
                    autoExpandParent={true}
                    onExpand={onExpand}
                    onSearch={treeSearch}
                    titleOperate={titleOperate}
                />
            </div>
            <div className="right ">
                <div className="text-lg font-semibold mt-4 mb-4">产品价格</div>
                <Alert
                    // message={(<>
                    //     <div>扣费方式说明：</div>
                    //     <div>1. 套餐包扣费：产品使用量优先从套餐包扣除（按购买单价），套餐包余额耗尽，超出的使用量按量计费（按通用单价）。</div>
                    //     <div>2. 按量扣费：产品使用量直接从账户充值的余额扣除（按通用单价）。</div>
                    // </>)}
                    description={<Description title="扣费方式说明：" listTips={[
                        '套餐包扣费：产品使用量优先从套餐包扣除（按购买单价），套餐包余额耗尽，超出的使用量按量计费（按通用单价）。',
                        '按量扣费：产品使用量直接从账户充值的余额扣除（按通用单价）。'
                    ]} />}
                    type="warning"
                />
                <div  className="text-base mt-4">通用单价详情</div>
                <ProTable<any>
                    columns={findCoumns() as any}
                    params={{productModelType, piId: treeActiveKey[0].substring(1)}} // 查询所需要的额外参数
                    request={getListApi}
                    options={{ reload: false, density: false, setting: false }}
                    toolbar={{
                        // 导出功能，勇哥说不要了
                        // actions: PROTYPE_GLOBALSMS === productModelType ? [
                        //     <ExportButton action={`${BASEURL}/web/product/exportProductModelPrice`}  key="export" type='primary' param={getQueryParams} btnText="导出" />
                        // ] : [],
                    }}
                    rowKey="id"
                    pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
                    actionRef={ref as any}
                    formRef={formRef}
                    search={PROTYPE_GLOBALSMS === productModelType ? {
                        optionRender: (searchConfig, formProps, dom) => [...dom.reverse()]
                    } : false}
                />
            </div>
        </div>
    </div>)
}