import { ProColumns } from "@ant-design/pro-table"
import { smsSignPageList, smsTempPageList } from "@api/index"
import { Ellipsis, Icon } from "@components/Common"
import { CompactDate, handleCompactDateSubmitValue, handleCompactDateValue } from "@components/Common/Compact"
import { statusOptions, valueEnum } from "@components/MyProduct/GlobalSMS/SendDetailed/data"
import { StateType } from "@store/index"
import { allDateRanges, parseSearch, productApi, smsTypeOptions } from "@utils/index"
import { handleDvmCommonPageListResult } from "@utils/request"
import { Tooltip } from "antd"
import moment from "moment"
import React from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

const getSignApi = productApi(smsSignPageList)  // 查询的接口封装
const getSignListApi = handleDvmCommonPageListResult(getSignApi)

const getTempApi = productApi(smsTempPageList)  // 查询的接口封装
const getTempListApi = handleDvmCommonPageListResult(getTempApi)

export const dateOptions = [
    { label: '按日', value: 'date' },
    { label: '按月', value: 'month' },
    // { label: '按年', value: 'year' },
]

const defaultValue = {type: 'date', value: allDateRanges['近30天']}

export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '时间',
            dataIndex: 'time',
            key: 'time',
            // hideInSearch: true,
            initialValue: defaultValue,
            renderFormItem: () => <CompactDate options={dateOptions} />,
            search: {
                transform: (value) => {
                    return handleCompactDateSubmitValue(value)
                },
            },
        },
        // 暂时隐藏   
        // {
        //     title: '签名名称',
        //     dataIndex: 'usName',
        //     key: 'usName',
        //     valueType: 'select',
        //     request: async() => {
        //         const { data } = await getSignListApi({pageSize: 1000, utlSmsArea: 1, dvmControl: 'smsSign:query'}, {}, {})
        //         return (data||[]).map(({usName: label, usCode: value}: any) => ({label, value}))
        //     },
        // },
        {
            title: '短信类型',
            dataIndex: 'msgType',
            key: 'msgType',
            valueType: 'select',
            request: async () => smsTypeOptions,
            search: {
                transform: (value: any) => {
                    return {
                        msgType: value
                    }
                },
            },
            // hideInSearch: true
        },
        {
            title: '发送数量(条)',
            dataIndex: 'sendAmount',
            key: 'sendAmount',
            hideInSearch: true
        },
        {
            title: '成功数(条)',
            dataIndex: 'successAmount',
            key: 'successAmount',
            hideInSearch: true
        },
        {
            title: '失败数(条)',
            dataIndex: 'failAmount',
            key: 'failAmount',
            hideInSearch: true
        },
        {
            title: '发送中(条)',
            dataIndex: 'sendingAmount',
            key: 'sendingAmount',
            hideInSearch: true
        },
        {
            title: '计费数(条)',
            dataIndex: 'chargeAmount',
            key: 'chargeAmount',
            hideInSearch: true
        },
        {
            title: '计费金额(元)',
            dataIndex: 'chargePay',
            key: 'chargePay',
            hideInSearch: true
        },
        {
            title: '成功率',
            dataIndex: 'successRate',
            key: 'successRate',
            hideInSearch: true
        },
        {
            title: '失败率',
            dataIndex: 'failRate',
            key: 'failRate',
            hideInSearch: true
        },
    ]
}
