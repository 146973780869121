import React, { ReactNode, useEffect, useRef, useState, useMemo } from 'react'
import { Badge, Dropdown, Layout, Menu, Avatar, MenuProps, notification, Tooltip, Popover, Input, Select, SubMenuProps, Divider, Row, Col, Card, Tabs, Modal, List } from 'antd'
import { useHistory, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { WaterMark } from '@ant-design/pro-layout'
import { StateType } from '@store/index'
import logoImg from '@assets/images/logo.png';
import { removeStorage, getPathStage, parseSearch, treeDataToFlatArr, productApi, setWindowHref, isBrowser, setHref } from '@utils/util';
import { MenuRouter, MenuItem } from '@router/index'
import { Breadcrumb, Icon, Preview } from '@components/index'
import { stringify } from 'qs';
import { useCacheDispatch, } from 'react-keepalive-router'
import Masonry from 'react-masonry-css'
import './index.less'
import { calcProLinkByProCode } from '@utils/index';
import { CertificationLink } from '@components/AccountCenter/SafeSetting';
import { loginOut } from '@components/Home/Layout/HomeHeader';
import { fileHttpUrl } from '@api/baseUrl';
import { useAsyncTask } from '@hooks/index';
import { asyncSetMsgInfo } from '@store/actions';

const { Search } = Input

type BasicLayoutProps = {
    children?: ReactNode | any;
}
interface RenderMenusProps extends MenuProps{
    menus?: MenuItem[];
    subMenuProps?: SubMenuProps
}
interface CustomSiderProps extends RenderMenusProps{
    siderName?: string;
}

type CustomHeaderProps = {
    name?: string;
    msgCount?: number;
}

const { Header, Sider, Content } = Layout
const { SubMenu } = Menu

const OverlayMenu = () => {
    const dispatch = useCacheDispatch()
    const history = useHistory()
    const { baseInfo } = useSelector((state: StateType) => state?.userInfo) as any
    const { username, userNature: type, verifyState: status } = (baseInfo || {}) as any
    const overlayHandle = async (e: any, type: string) => {
        if (type === 'loginOut') {
            loginOut()
        }
    }
    return (
        <Menu className="basic-layout-overlay" style={{padding: 0}}>
            <Menu.Item style={{ width: '200px', padding: '10px 24px', }}>
                <div className="login-out">
                    <div className='text-gray-500' style={{fontSize: 16, fontWeight: 500}}>{username}</div>
                    <div><CertificationLink type={type} status={status} /></div>
                </div>
            </Menu.Item>
            <Menu.Item style={{ width: '100%', padding: '10px 24px', borderTop: '1px solid #F6F6F6' }}>
                <div className='text-gray-500' onClick={() => history.push('/account/safeSetting')}>安全设置</div>
            </Menu.Item>
            <Menu.Item style={{ width: '100%', padding: '10px 24px', }}>
                <div className='text-gray-500' onClick={() => history.push('/account/certification')}>实名认证</div>
            </Menu.Item>
            <Menu.Item style={{ width: '100%', padding: '10px 24px', }}>
                <div className='text-gray-500' onClick={() => history.push('/account/apiCall')}>API调用密钥</div>
            </Menu.Item>
            <Menu.Item style={{ width: '100%', padding: '10px 24px', }}>
                <div className='text-gray-500' onClick={() => history.push('/account/ipWhiteList')}>IP白名单</div>
            </Menu.Item>
            <Menu.Item style={{ width: '100%', padding: '10px 24px', background: '#F6F6F6', textAlign: 'center' }}>
                <div className="login-out" style={{color: '#989898', fontSize: 12}} onClick={(e) => overlayHandle(e, 'loginOut')}>
                    退出登录
                </div>
            </Menu.Item>
        </Menu>
    )
}

const RenderMainMenus = (props: RenderMenusProps) => {
    const { menus, children, subMenuProps, ...rest } = props
    const { userInfo } = useSelector((state: StateType) => state)
    const { permissions } = userInfo || {}

    const RenderMenuItems = (menus: MenuItem[] = []) => {
        return (menus||[]).map(({name, path, icon, children, authCode, hideInMenu}: MenuItem) => {
            const hasChildren = (children||[]).length
            const isRender = (authCode && (permissions||[]).includes(authCode)) || !authCode || [-1].includes(authCode as number)
            const MenuIcon = typeof icon === 'string' ?  <Icon type={icon} /> : icon
            if (isRender) {
                return !hideInMenu ? hasChildren ? <SubMenu key={path} icon={MenuIcon} title={name} {...subMenuProps}>
                    {RenderMenuItems(children||[])}
                </SubMenu> : <Menu.Item className={ icon ? '' : 'leftMargin' } icon={MenuIcon} key={path}><Link to={path}>{ name as any }</Link></Menu.Item> : null
            }
        })
    }
    return <Menu {...rest}>
        {RenderMenuItems(menus)}
        {children}
    </Menu>
}


const RenderMenus = (props: RenderMenusProps) => {
    const { menus, children, subMenuProps, ...rest } = props
    const { userInfo } = useSelector((state: StateType) => state)
    const { permissions } = userInfo || {}

    const RenderMenuItems = (menus: MenuItem[] = []) => {
        return (menus||[]).map(({name, path, icon, children, authCode, hideInMenu}: MenuItem) => {
            const hasChildren = (children||[]).length
            const isRender = (authCode && (permissions||[]).includes(authCode)) || !authCode || [-1].includes(authCode as number)
            const MenuIcon = typeof icon === 'string' ?  <Icon type={icon} /> : icon
            if (isRender) {
                return !hideInMenu ? hasChildren ? <SubMenu key={path} icon={MenuIcon} title={name} {...subMenuProps}>
                    {RenderMenuItems(children||[])}
                </SubMenu> : <Menu.Item className={ icon ? '' : 'leftMargin' } icon={MenuIcon} key={path}><Link to={path}>{ name as any }</Link></Menu.Item> : null
            }
        })
    }
    return <Menu {...rest}>
        {RenderMenuItems(menus)}
        {children}
    </Menu>
}

const data = [
    {
        id: 1,
        title: 'Ant Design Title 1',
    },
    {
        id: 2,
        title: 'Ant Design Title 2',
    },
    {
        id: 3,
        title: 'Ant Design Title 3',
    },
    {
        id: 4,
        title: 'Ant Design Title 4',
    },
];

const MeaasgeContent = ({dataSource}: any) => {
    // console.log('dataSource', dataSource)
    const onClick = (href: string) => setWindowHref(href)
    return (<div className='msg-box'>
        <List
           itemLayout="horizontal"
           dataSource={dataSource||[]}
           style={{width: 240}}
           size="small"
           split={false}
           renderItem={(item: any) => (<List.Item style={{padding: 0}}>
            {/* <List.Item.Meta
              title={<a className='msg-item' onClick={() => onClick(`/account/messageDetail?${stringify({msgId: item.miId})}`)}>{item.miTitle}</a>}
            //   description="2022-06-28 16:48:34"
            /> */}
            <a className='msg-item' onClick={() => onClick(`/account/message?${stringify({msgId: item.mrId, msgTitle: item.miTitle})}`)}>{item.miTitle}</a>
            {/* <a className='msg-item' onClick={() => onClick(`/account/messageDetail?${stringify({msgId: item.miId})}`)}>{item.miTitle}</a> */}
           </List.Item>)}
           footer={<a className='msg-item-more' href="/account/message">查看更多&gt;</a>}
        />
    </div>)
}
const RenderMessage = () => {
    const { unRead, msgTop5 } = useSelector((store: any) => (store.msgInfo||{}))
    return (
        <Popover overlayClassName='header-msg-popover' placement="bottomRight" title={<div style={{padding: '5px 8px'}}>未读消息</div>} content={<MeaasgeContent dataSource={msgTop5} />}>
            <Badge count={unRead} size="small" style={{marginRight: '24px'}}>
                <Avatar
                   style={{background: 'transparent', marginRight: '22px', marginTop: '-1px', cursor: 'pointer'}}
                   icon={<Icon type="icon-xiaoxi1" style={{fontSize: '18px',color: 'rgba(0, 0, 0, 0.65)', marginTop: '1px'}} />}
                   size={24}
                   onClick={() => setWindowHref('/account/message')}
                />
            </Badge>
        </Popover>
    )
}

const Logo = (props: any) => {
    const history = useHistory()
    const { ueSaasLogo } = useSelector((store: any) => (store.domainInfo||{}))
    const logo = fileHttpUrl(ueSaasLogo)
    return (<img src={logo} onClick={() => history.push(`/home/index`)} style={{ width: 80, height: 22, marginLeft: 20, cursor: 'pointer' }} />)
}

const UserLogo = () => {
    return (<Dropdown overlay={<OverlayMenu />}>
           <Icon type="icon-touxiang" style={{fontSize: 34}} />
            {/* <a className="ant-dropdown-link" style={{display: 'inline-block'}}>
                <Icon type="icon-touxiang" style={{fontSize: 34}} />
            </a> */}
        </Dropdown>)
}


const ProductBuyed = () => {
    const history = useHistory()
    const { buyedPro } = useSelector((store: StateType) => store?.userInfo) as any
    const jumpTo = ({piCode}: any) => {
        const proLink = calcProLinkByProCode(piCode)
        isBrowser() && setHref(proLink)
        // history.push(proLink)
    }
    return (<Row gutter={[16, 16]} style={{paddingTop: 20, width: 500 }}>
    {buyedPro.map((v: any) => <Col key={v.piId} span={10} style={{background: '#fff', marginRight: 10}} onClick={() => jumpTo(v)}>
        <a style={{display: 'inline-block', padding: 10}}>{v.piName}</a>
    </Col>)}
    </Row>)
}

const ProductItem = ({item}: any) => {
    const history = useHistory()
    const { label, children } = item
    const jumpTo = ({piId}: any) => {
        history.push(`/home/product?${stringify({piId})}`)
    }
    return (<div style={{boxSizing: 'border-box', paddingRight: 50}}>
        <h3 style={{padding: 5, borderBottom: '1px solid #E3E5EB'}}>{label}</h3>
        <ul>
            {(children||[]).map((v: any) => (<li className='hover:text-red-500' key={v.id} style={{padding: 6, cursor: 'pointer'}} onClick={() => jumpTo(v)}>{v.label}</li>))}
        </ul>
    </div>)
}

// 全部产品
const ProAll = ({treeData}: any) => {
    return (<Masonry
        breakpointCols={3}
        className="flex"
        style={{width: 500, paddingTop: 20}}
        >
        {(treeData||[]).map((v: any) => (<ProductItem key={v.id} item={v} />))}
      </Masonry>
    )
}

const buyedItems = [
    {
        label: '已购产品',
        key: 'buyed',
        children: <ProductBuyed />
    }
]

const ProList = () => {
    const { treeData } = useSelector((store: StateType) => store?.productData) as any
    const items = (treeData||[]).map((v: any, i: number) => ({
        label: v.label,
        key: v.id,
        children: <ProAll key={v.id} treeData={v.children} />
    }))
    return (<div className='flex' style={{ height: 'calc(100vh - 64px)'}}>
        <Tabs
           className='j-logined-header-product'
           style={{background: '#F5F5F5'}}
           tabBarStyle={{background: '#fff', width: 150}}
           tabPosition="left"
           items={[...buyedItems, ...items]}
           tabBarGutter={2}
        />
    </div>)
}

const CustomHeader = (props: CustomSiderProps) => {
    const { baseInfo } = useSelector((store: StateType) => store?.userInfo) as any
    const { url } = baseInfo || {}
    return (<Header className='flex items-center justify-between jc-antd-reset' style={{background: '#fff', boxShadow: '0px 1px 8px 0px rgba(0,21,41,0.05)', paddingLeft: 0, paddingRight: 20}}>
            <div className='flex items-center'>
                <Dropdown placement="bottomLeft" overlay={<ProList />} >
                    <span style={{width: 64, color: '#fff', fontSize: 24, textAlign: 'center', background: 'linear-gradient(142deg, #FF2020 0%, #FF8466 100%)'}}>
                        <Icon type="icon-31liebiao" />
                    </span>
                </Dropdown>
                <Logo />
                <div style={{minWidth: 360, marginLeft: 80}} className="j-console-header-menu">
                    <RenderMainMenus mode="horizontal" {...props} />
                </div>
            </div>
            <div className='flex items-center'>
                <a style={{marginRight: 20, color: '#282828'}} href={'/account/taskList'}><span className='hover:text-red-600'>导出记录</span></a>
                { url ? <a style={{marginRight: 20, color: '#282828'}} target="_blank" rel="noreferrer" href={url}><span className='hover:text-red-600'>代理商入口</span></a> : null }
                <RenderMessage />
                <UserLogo />
            </div>
        </Header>
    )
}

export const CustomSider = ({siderName, ...rest}: CustomSiderProps) => {
    return (<Sider width={180} theme="light" className='jc-antd-reset'>
            <div style={{padding: '19px 24px', fontSize: 16, fontWeight: 600}}>{siderName}</div>
            <RenderMenus mode="inline" theme='light' {...rest} />
        </Sider>
    )
}

export default ({children}: BasicLayoutProps) => {
    useAsyncTask()
    const dispatch = useDispatch();
    const { pathname, search } = useLocation();
    const userInfo = useSelector((state: StateType) => state) as any;
    const { user } = userInfo
    const { userName, name: nickName } = user || {}
    const name = userName || nickName || ''
    const calcSelectedKeys = () => {
        const flatArr = treeDataToFlatArr(MenuRouter, false)
        const { parentPath } = flatArr.find(({path}) => path === pathname) || {}
        const { fromPath } = parseSearch(search)
        return [ pathname, fromPath || parentPath]
    }

    const calcSiderMenus = () => {
        const pathStage = getPathStage(pathname + '/')
        const mainPathName = pathname.startsWith('/product') ? pathStage[1] : pathStage[0]
        const {children, name} = MenuRouter.find(v => v.path === mainPathName) || {}
        return {
            siderMenus: children,
            siderName: name
        }
    }
    const { siderMenus, siderName} = calcSiderMenus()

    useEffect(() => {
        dispatch(asyncSetMsgInfo()) // 获取消息
    }, [])

    return (
        <Layout>
            <Preview />
            <CustomHeader menus={MenuRouter} selectedKeys={calcSelectedKeys()} subMenuProps={{popupClassName: 'jc-theme-menu-inline'}} />
            <Layout style={{minHeight: 'calc(100vh - 64px)'}}>
                { siderMenus ? <CustomSider siderName={siderName as string} menus={siderMenus} selectedKeys={calcSelectedKeys()} defaultOpenKeys={getPathStage(pathname)} /> : null}
                <Content>
                   <div style={{paddingLeft: 20,}}><Breadcrumb /></div>
                   <WaterMark content={nickName} zIndex={8} gapY={80} gapX={100} fontColor='rgba(0,0,0,0.07)'>
                        <Content className='Content customScrollBar'>
                            <div className="main-container" style={{ height: '100%',boxSizing: 'border-box', padding: '5px 20px'}}>
                                {children}
                            </div>
                        </Content>
                    </WaterMark>
                </Content>
            </Layout>
        </Layout>
    )
}
