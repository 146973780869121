import React, { useRef, useState } from "react"
import { useTableHooks } from "@hooks/index"
import { columnsFn } from './data'
import { Button, Col, Modal, Row, Table } from "antd"
import { phoneReg, productApi } from "@utils/index"
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home"
import { SearchBox } from "@components/index"

import { ActionType, ProTable } from "@ant-design/pro-table"
import { ProFormProps, ProForm, ProFormText, ModalForm, ProFormRadio, ProFormUploadDragger, ProFormSelect, ProFormTextArea } from '@ant-design/pro-form' 
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { smsBlackListAdd, smsBlackListDel, smsBlackListPageList } from "@api/index"
import { handleDvmCommonPageListResult } from "@utils/request"

// 列表操作的 类型校验
type Action = '新增' | '修改' | '删除'

// 接口请求的 api 层
const getApi = productApi(smsBlackListPageList)  // 查询的接口封装
const addUpdateApi = productApi(smsBlackListAdd) // 列表数据的新增/编辑 接口
const delApi = productApi(smsBlackListDel) // 表单数据的删除 接口
const getListApi = handleDvmCommonPageListResult(getApi)

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 16 },
};

// 新增｜编辑的 form 表单弹窗
// 弹窗参数的类型扩展
interface AddFormProps extends ModalFormProps{
    submitParams?: any;
    onSuccess?: () => void;
}
const AddFormModal = ({onSuccess, submitParams, ...rest}: AddFormProps) => {
    const onFinish = async (values: any) => {
        addUpdateApi({...values, ...submitParams}, true, onSuccess)
    }
    return (<ModalForm<any>
        layout="horizontal" 
        {...layout} 
        onFinish={onFinish} 
        {...rest}>
        <ProFormText 
            width="md" 
            name="ublPhone" 
            label="手机号"
            rules={[
                { required: true, message: '请输入11位手机号码'}, 
                { pattern: phoneReg, message: '非法手机号' }
            ]}
            placeholder="请选择" 
        />
    </ModalForm>)
}


/**
 * antd 升级版企业级组件(常用)
*/
export default () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [modalProps, setModalProps] = useState({visible: false, title: '新增', submitParams: {}})
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    // operate 函数为增删改查需要处理的业务
    const operate = (type: Action, record: any) => {
        const { ublId } = record || {}
        if (type === '新增') {
            setModalProps({
                ...modalProps,
                title: '添加黑名单',
                submitParams: { ublSourceType: 0 },
                visible: true,
            })
        } else if (type === '修改') {
            setModalProps({
                ...modalProps,
                title: '修改',
                submitParams: { ublId },
                visible: true,
            })
        } else if (type === '删除') {
            const ublIds = record ? [ublId] : selectedRowKeys
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                centered: true,
                cancelText: '取消',
                onOk: async() => {
                    await delApi({ublIds: ublIds.toString()}, true, () => {
                        console.log('数据删除成功, 可以执行回调了')
                        reFresh()
                    })
                },
            });
        }
    }

    // const getListApi = async (params: any, sorter: any, filter: any) => {
    //     const { data } = await getApi({...params}, false)
    //     const { values, total } = data || {}
    //     return {
    //         data: values || [],
    //         success: true,
    //         total
    //     }
    // }
    // const getListApi = handleDvmCommonPageListResult(getApi)

    const columns = columnsFn(operate) // 表格列配置

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        console.log('处理后的成功回调触发了')
        setModalProps({
            ...modalProps,
            visible: false
        })
        reFresh()
    }
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const calcDelDisabled = () => selectedRowKeys.length
    return (<><ProTable<any>
            columns={columns}
            params={{}} // 查询所需要的额外参数
            request={getListApi}
            options={{reload: false, density: false, setting: false}}
            toolbar={{
                actions: [
                    <Button key="btn0" type='primary' onClick={() => operate('新增', null)}>添加黑名单</Button>,
                    <Button key="btn1" type='primary' disabled={!calcDelDisabled()} onClick={() => operate('删除', null)}>删除</Button>,
                ],
            }}
            rowKey="ublId"
            pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
            actionRef={ref as any}
            search={{
                optionRender: (searchConfig, formProps, dom) => [ ...dom.reverse() ]
            }}
            rowSelection={{
                preserveSelectedRowKeys: true,
                onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
            }}
        />
        <AddFormModal
            modalProps={{
                maskClosable: false, 
                onCancel: () => setModalProps({...modalProps, visible: false})
            }}
            onSuccess={onSuccess} 
            {...modalProps} 
        />
    </>)
}
