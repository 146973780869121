import { Button, Tabs } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { ActionType, EditableProTable, ProTable } from "@ant-design/pro-table"
import { handleTableSorter, phoneReg, productApi, setHref } from "@utils/index";
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home";
import { BASEURL, cdnSetPkgAlert, cdnPkgList, cdnGetPkgAlert, smsGlobalSendDetailPageList, smsGetAlertInfo } from "@api/index";
import { ExportButton } from "@components/Common";
import moment from "moment";
import { columnsFn, editTableColumnFn } from "./data";
import AlertTip from "@components/Common/AlertTip";
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import ProForm, { 
  ProFormText, 
  ProFormUploadButton, 
  ProFormSelect, 
  ProFormTextArea, 
  ProFormRadio,
  ProFormCascader,
  ModalForm 
} from '@ant-design/pro-form';
import { useHistory } from "react-router-dom";

// 接口请求的 api 层
const getApi = productApi(cdnPkgList); // 查询的接口封装
const addUpdateApi = productApi(cdnSetPkgAlert); // 列表数据的新增/编辑 接口
const delApi = productApi(homeTableDel); // 表单数据的删除 接口

const listTips: any = [
  'CDN资源包为“总量递减”型资源包，资源包余量用尽与失效后将按量付费，请及时关注资源包使用情况。',
  '同类型流量包中优先使用先到期的流量包。'
]

const alertListTips: any = [
  '您设置的余量预警将分别生效于不同套餐分类的流量包上；',
  '当您账户下某个套餐分类的所有有效流量包余量不足预警值时将会向您发送提醒。',
  '示例：假设您有2个100GB中国境内流量包，您设置的{中国境内流量包}套餐分类的余量告警比例是20%，那么当中国境内流量包不足40GB时会进行短信提醒。'
] 

// 设置余量预警
interface SetCDNRestAlertFormProps extends ModalFormProps{
  params?: any;
  onSuccess?: () => void;
}
const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 16 },
};

const SetCDNRestAlertForm = ({onSuccess, params, ...rest}: SetCDNRestAlertFormProps) => {
  const formRef = useRef<ProFormInstance>()
  const [editableKeys, setEditableKeys] = useState<any>([])
  const request = async (params: any) => {
      const { data } = await cdnGetPkgAlert(params) as any
      // const { data } = await smsGetAlertInfo(params) as any
      const { phone, items } = data || {}
      const newItems = (items||[]).map(({id, ...rest}: any, i: number) => ({...rest, id: id ? id : `zqs-${i}`}))
      setEditableKeys((newItems||[]).map((v: any) => v.id))
      return { phone, items: newItems }
  }
  const onFinish = async (values: any) => {
      const { items: arr, ...restParams } = values || {}
      const items = arr.map(({ualIsEnabled, id, ...rest}: any) => ({...rest, id: (id+'').startsWith('zqs-') ? null : id, ualIsEnabled: ualIsEnabled ? 1 : 0}))
      const { success } = await addUpdateApi({...restParams, items, ...params}, true, onSuccess) as any
      return success
  }
  return (<ModalForm<any>
      layout="horizontal" 
      {...layout} 
      onFinish={onFinish} 
      request={request}
      formRef={formRef}
      modalProps={{
        destroyOnClose: true
      }}
      {...rest}>
      <AlertTip type="warning" listTips={alertListTips} style={{marginBottom: 20}} />
      <ProForm.Item name="items" label="报警规则">
          <EditableProTable<any>
              className="j-proform-editable"
              rowKey="id"
              columns={editTableColumnFn()}
              recordCreatorProps={false}
              controlled
              editable={{
                  // form,
                  type: 'multiple',
                  deleteText: null,
                  editableKeys,
                  actionRender: (row, config, defaultDoms) => {
                      return [defaultDoms.delete];
                  },
                  onValuesChange: (value: any, allValues: any) => {
                      formRef.current?.setFieldValue('items', allValues)
                  }
              }}
              // onChange={()}
          />
      </ProForm.Item>
      <ProFormText 
          width="md" 
          name="phone" 
          label="预警手机号" 
          fieldProps={{
              placeholder: '请输入报警手机号', 
          }} 
          rules={[{ required: true, message: '请输入11位手机号码'}, { pattern: phoneReg, message: '非法手机号' }]}
      />
  </ModalForm>)
}

export default () => {
  const history = useHistory()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [modalProps, setModalProps] = useState({
    visible: false,
    title: "新增",
    params: {},
  });
  const ref = useRef<ActionType>(null); // ProTable 组件的 ref
  const formRef = useRef<ProFormInstance>();
  // operate 函数为增删改查需要处理的业务
  const operate = (type: any, record: any) => {
    const { id } = record || {};
    if (type === "购买资源包") {
      history.push(`/home/product?piId=21`)
    } else if (type === "设置余量报警") {

    }
  };

  const getListApi = async (params: any, sorter: any, filter: any) => {
    const { data } = await getApi({ ...params, ...handleTableSorter(sorter), ...filter }, false);
    const { values, total } = data || {}; // 刘冲说不要分页; 刘冲后面改成了分页
    return {
      data: values || [],
      success: true,
      total,
    };
  };

  const columns = columnsFn(operate); // 表格列配置
  // 刷新页面
  const reFresh = () => {
    // @ts-ignore
    ref.current?.clearSelected();
    ref.current?.reload();
  };
  const getQueryParams = () => ({
    // @ts-ignore
    ...formRef.current?.getFieldsFormatValue(),
  });
  return (<>
    <AlertTip type="warning" listTips={listTips} />
    <ProTable<any>
      style={{ flex: 1, overflow: "scroll" }}
      // scroll={{ x: 1300 }}
      columns={columns}
      params={{}} // 查询所需要的额外参数
      request={getListApi}
      options={{ reload: false, density: false, setting: false }}
      toolbar={{
        actions: [
          <Button key="btn0" type="primary" onClick={() => operate("购买资源包", null)}>购买资源包</Button>,
          <Button key="btn1" type="primary" onClick={() => setHref('/cost/pkg')}>设置余量预警</Button>,
          // <SetCDNRestAlertForm key="btn1" title="设置余量预警" trigger={<Button type="primary">设置余量预警</Button>} />,
        ],
      }}
      // rowKey="tiId"
      pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
      actionRef={ref as any}
      formRef={formRef}
      search={false}
      // rowSelection={{
      //     preserveSelectedRowKeys: true,
      //     onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
      // }}
    />
    </>
  );
};
