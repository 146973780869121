import React, { useRef, useState } from "react"
import { useTableHooks } from "@hooks/index"
import { columnsFn } from './data'
import { Alert, Button, Col, Modal, Row, Table, Tooltip } from "antd"
import { productApi } from "@utils/index"
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home"
import { ActionType, ProTable } from "@ant-design/pro-table"
import { ExportButton, Icon } from "@components/Common"
import { BASEURL, getCostPaymentPageList } from "@api/index"
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { Description } from "@components/Common/AlertTip"
// 列表操作的 类型校验
type Action = '新增' | '修改' | '删除'

// 接口请求的 api 层
const getApi = productApi(getCostPaymentPageList)  // 查询的接口封装
const addUpdateApi = productApi(homeTableAddUpdate) // 列表数据的新增/编辑 接口
const delApi = productApi(homeTableDel) // 表单数据的删除 接口

export default () => {
    const [baseInfo, setBaseInfo] = useState<any>({})
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [modalProps, setModalProps] = useState({visible: false, title: '新增', params: {}})
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    const formRef = useRef<ProFormInstance>()
    const [total, setTotal] = useState(0)
    // operate 函数为增删改查需要处理的业务
    const operate = (type: Action, record: any) => {
        const { id } = record || {}
        if (type === '新增') {
            setModalProps({
                ...modalProps,
                title: '新增',
                params: {},
                visible: true,
            })
            console.log('需要处理的新增业务')
        } else if (type === '修改') {
            setModalProps({
                ...modalProps,
                title: '修改',
                params: { id },
                visible: true,
            })
            console.log('需要处理的修改业务')
        } else if (type === '删除') {
            const ids = record ? [id] : selectedRowKeys
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                centered: true,
                cancelText: '取消',
                onOk: async() => {
                    await delApi({ids}, true, () => {
                        console.log('数据删除成功, 可以执行回调了')
                        reFresh()
                    })
                },
            });
        }
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params}, false)
        const { values, total, ...baseInfo } = data || {}
        setBaseInfo(baseInfo)
        setTotal(total)
        return {
            data: values || [],
            success: true,
            total: total > 50000 ? 50000 : total
        }
    }

    const columns = columnsFn(operate) // 表格列配置

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        console.log('处理后的成功回调触发了')
        setModalProps({
            ...modalProps,
            visible: false
        })
        reFresh()
    }
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const calcDelDisabled = () => selectedRowKeys.length
    const getQueryParams = () => ({
        // @ts-ignore
        ...formRef.current?.getFieldsFormatValue()
    })
    return (<>
        {/* <Alert
            description={<Description title="查询结果仅支持显示5万条数据，想要查看更多数据，可导出数据查看。" listTips={[]} />}
            type="warning"
        /> */}
        <ProTable<any>
            // style={{flex: 1, overflow: 'scroll'}}
            // scroll={{x: 1300}}
            columns={columns}
            params={{}} // 查询所需要的额外参数
            request={getListApi}
            options={{reload: false, density: false, setting: false}}
            headerTitle={<span key="desc">
                <Tooltip title="此处只汇总列表查询结果的交易数据"><Icon type="icon-ziyuan" /></Tooltip>
                支出: {baseInfo.expense||0} 元, 收入: {baseInfo.income||0} 元; 数据总条数: {total}条
                <span style={{fontSize: 14, color: 'coral'}}>(查询结果仅支持显示5万条数据，想要查看更多数据，可导出数据查看。)</span>
                </span>}
            toolbar={{
                actions: [
                    <ExportButton key="export" action={`${BASEURL}/portal/expenseCenter/exportExpenseDetails`} type='primary' param={getQueryParams} btnText="导出" />
                ],
            }}
            rowKey="id"
            pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
            actionRef={ref as any}
            formRef={formRef}
            search={{
                optionRender: (searchConfig, formProps, dom) => [ ...dom.reverse() ]
            }}
            // rowSelection={{
            //     preserveSelectedRowKeys: true,
            //     onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
            // }}
        />
    </>)
}
