import React, { useEffect, useState } from "react";
import "./index.less";
import { homeGetGetInfomation } from "@api/home";
import { productApi, setHref, sliceText } from "@utils/util";
import { List, Pagination } from "antd";
import { getCostMyCloudPageList } from "@api/costCenter";
import { useHistory, useLocation, useParams } from "react-router-dom";
export { default as DetailsIndex } from "./DetailsIndex";
import { ArrowRightOutlined } from "@ant-design/icons";
import { fileHttpUrl } from "@api/baseUrl";
import { handleDvmCommonListResult } from "@utils/request";
import { GoRegister } from "../PageIndex";
import DetailsIndex from "./DetailsIndex";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";

export const Top_header = () => {
  const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
  
  return (
    <div
      className="top_div"
      style={{
        background: `url(${fileHttpUrl(
          "test/image/82332C7F7D2B468A90B59327D4451DB0.png"
        )}) no-repeat`,
      }}
    >
      <div className="w-96 text-6xl mt-8 title">行业资讯</div>
      <div className="w-96 text-base mt-8 title">
        关注{ueSaasName}最新动态，了解产业最新成果
      </div>
    </div>
  );
};

const Content = ({ newsList, total, onChange }: any) => {
  const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
  return (
    <div className="news_list_div">
      <List
        dataSource={newsList}
        pagination={{ position: "bottom", total, onChange }}
        renderItem={(item: any, index) => (
          <List.Item>
            <div key={index} className="news_item flex">
              <div style={{width: '100px'}} className="text-center pc-component mr-6 flex flex-col justify-center">
                <div className="text-5xl">{item.updateTime.slice(8, 10)}</div>
                <div className="text-gray-400">
                  {item.updateTime.slice(0, 7)}
                </div>
              </div>
              {item.pinImage ? <div className="picture">
                <img src={fileHttpUrl(item.pinImage)} alt={`${ueSaasName}资讯`} />
              </div> : null}
              <div className="itemContent flex flex-col justify-between">
                <div>
                  <div className="title cursor-pointer hover:text-red-500 duration-300"  onClick={() =>
                    setHref(`/home/industryInformation/${item.pinId}`)
                  }>{sliceText(item.pinTitle, 20)}</div>
                  <div className="text mt-2">{sliceText(item.pinAbstract, 70)}</div>
                </div>
                <div
                  className="jump"
                  onClick={() =>
                    setHref(`/home/industryInformation/${item.pinId}`)
                  }
                >
                  <ArrowRightOutlined />
                </div>
              </div>
            </div>
          </List.Item>
        )}
      />
    </div>
  );
};

export default ({children}:any) => {
    const [newsList, setNewsList] = useState([]) as any
    const [total, setTotal] = useState(0)
    const history = useHistory()
    const { search, pathname } = useLocation()
    const getList = async (params: any) => {
        const { result } = await homeGetGetInfomation(params) as any
        const res = handleDvmCommonListResult(result)
        const total = handleDvmCommonListResult(result, 'resultcount')[0]?.RET
        total >= 0 &&  setTotal(total)
        setNewsList(res)
    }

    const onChange = (pageNum: any) => {
      getList({pageNum, pageSize: 10})
    }

    useEffect(()=> {
        getList({pageNum: 1, pageSize: 10})
    }, [])
    return (<div className="j_home_industryInformation">
        {/* 上半部分 */}
        <Top_header />

        {/* 下半部分 */}
        {
           pathname === '/home/industryInformation' ? <Content newsList={newsList} total={total} onChange={onChange} /> :  children
        }
        <div style={{clear: 'both'}} className="mb-4"></div>
        <GoRegister />
    </div>)
}
