import React, { useEffect, useState } from 'react'
import { Button, Col, message, Popover, Radio, Row, Select } from 'antd';
import ProForm, {
    ProFormUploadButton,
    ProFormTextArea,
    ProFormSelect
} from '@ant-design/pro-form';
import {
    QuestionCircleOutlined
} from '@ant-design/icons';
import { BASEURL } from '@api/baseUrl';
import { getToken, phoneReg } from '@utils/util';

import './index.less'
import TextArea from 'antd/lib/input/TextArea';
import { smsMaiPageList } from '@api/product';
import { handleDvmCommonListResult } from '@utils/request';

const englishChar = '@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !"#¤%&\'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà^{}\\[~]|€'
const specialChar = `^{}\\[~]€| `   // 空格也算特殊字符, 就很奇怪

// 判断 是否存在中文
const existsChinese = (text: string) => {
    for (let item of text) {
        if (!englishChar.includes(item)) {
          return true
        }
    }
    return false
}

// 判断 是否存在特俗字符，如果存在特殊字符，统计一下特殊字符存在的数量
const existsSpecialChar = (text: string) => {
    let num = 0
    for (let item of text) {
        if (specialChar.includes(item)) {
          num++
        }
    }
    return num
}

// 计算模版内容中存在的字符长度，以及短信条数
const calcSmsTextLength = (text: string) => {
    if (existsChinese(text)) {  // 如果存在中文，按中文统计规则
        return {
            msgLength: text.length,
            msgCount: text.length <= 70 ? 1 : Math.ceil(text.length / 67)
        }
    } else { // 没有中文，按英文计算
        const specialCharCount = existsSpecialChar(text)
        const msgLength = text.length + specialCharCount
        return {
            msgLength,
            msgCount: msgLength <= 160 ? 1 : Math.ceil((msgLength - 160) / 153 + 1)
        }
    }
}

interface MsgPreviewProps {
    msgContent: string; //短信文本   格式【***】***${**}******
    style?: object;
    type?: string; //global 不传默认国内短信
}
export default ({ msgContent, type, ...rest }: MsgPreviewProps) => {
    // const test = '【测试签名】短信内容短信${变量1}内容短${变量2}信内容'

    const [data, setData] = useState({
        wordsCount: 0,
        msgNumber: 0,
        msgFormatList: [] as any
    })

    // const calculationSmsTextLength = (data: any) => {
    //     // 国际短信技术规则两种
    //     // 计费规则：纯英文：单条短信（含签名）长度为160字，超过160字按153字/条分隔多条计费（纯英文签名符号为[])
    //     // 其他语言：单条短信（含签名)长度70字，超过70字按67字/条分隔多条计费
    //     // 普通英文字符按照一个字符计算长度，如出现^ { } \ [ ~ ] €| 这些特殊字符，按2个字符计算
    //     let phoneTextLength = 0
    //     let smsNumber = 0
    //     let type = false
    //     const text = '@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !"#¤%&\'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà^{}\\[~]|€'
    //     const text2 = `^{}\\[~]€|`
    //     for (let item of data) {
    //       if (!text.includes(item)) {
    //         type = true
    //       }
    //     }
    //     if (type) {
    //       phoneTextLength = data.length
    //     //   smsNumber = data.length <= 70 ? 1 : Math.ceil((data.length - 70) / 67 + 1)  // 惠康的计算方式
    //       smsNumber = data.length <= 70 ? 1 : Math.ceil(data.length / 67)
    //       return {phoneTextLength, smsNumber}
    //     } else {
    //       for (let item of data) {
    //         if (text2.includes(item)) {
    //             phoneTextLength += 2
    //         } else {
    //             phoneTextLength += 1
    //         }
    //       }
    //       smsNumber = phoneTextLength <= 160 ? 1 : Math.ceil((phoneTextLength - 160) / 153 + 1)
    //       return {phoneTextLength, smsNumber}
    //     }
    // }

    const formatContent = (msgContent: string) => {
        const reg = /\$\{(.+?)\}/g
        const text = msgContent.replace(reg, '')
        if (type === 'global'){
            // const {phoneTextLength: wordsCount, smsNumber: msgNumber} = calculationSmsTextLength(text || '')
            // let msgNumber = wordsCount <= 70 ? 1 : Math.ceil(wordsCount / 67)
            const {msgLength: wordsCount, msgCount: msgNumber} = calcSmsTextLength(text || '')
            let msgFormatList = msgContent.split(/(\$\{.*?\})/g)
            setData({ wordsCount, msgNumber, msgFormatList })
        } else {
             let msgNumber = text.length <= 70 ? 1 : Math.ceil(text.length / 67)
             let msgFormatList = msgContent.split(/(\$\{.*?\})/g)
             setData({ wordsCount: text.length, msgNumber, msgFormatList })
        }
       
    }

    useEffect(() => {
        formatContent(msgContent || '')
    }, [msgContent])

    return (
        <div className='sms-send-msg-preview' {...rest}>
            <div className='msgContent'>
                {
                    data.msgFormatList.map((item: string, index: number) => {
                        return (<span key={index} className={`${item[0] === '$' ? 'text-red-600' : ''}`}>{item}</span>)
                    })
                }
            </div>
            <div className='mt-4'>
                当前发送内容<span className='text-red-600'>{data.wordsCount}</span>个字,预计<span className='text-red-600'>{data.wordsCount ? data.msgNumber : 0}</span>条短信
                <div>（实际发送时，模板变量会影响计费条数，请特别关注！）
                    <Popover content={type === 'global' ? globalCountingRule : CountingRule} title="计数规则" trigger="hover">
                        <span className='text-blue-400'>(查看计数规则)</span>
                    </Popover>
                </div>
            </div>
        </div>
    )
}

const globalCountingRule = () => (<div style={{ width: '300px' }}>
    计费规则：纯英文：单条短信（含签名）长度为160字，超过160字按
    153字/条分隔多条计费（纯英文签名符号为[ ])
    其他语言：单条短信（含签名)长度70字，超过70字按67字/条分隔多条计费
    普通英文字符按照一个字符计算长度，如出现^  {`{ }`}  \ [ ~ ] €| 这些
    特殊字符，按2个字符计算。
</div>)

const CountingRule = () => (
    <div style={{ width: '300px' }}>
        短信字数＝短信模版内容字数 + 签名字数<br />
        短信字数<span className="orange">≤70</span>个字数，按照<span className="orange">70</span>个字数一条短信计算，<br />
        短信字数<span className="orange">&gt;70</span>个字数，即为长短信，<br />
        长短信按照<span className="orange">67</span>个字数记为一条短信计算。<br />
        注意：在统计短信字数时，小数点、字母、汉字以及其他符号、<br />
        空格均按照一个字符位进行统计。最终按照实际发送成功的短<br />
        信条数计费。
    </div>
)


// 上传号码的组件   上传文件、选择通讯里、手动输入过滤
export const MsgSendType = ({ formRef, onlyExcel, setNumberList }: any) => {
    const [typeCode, setTypeCode] = useState(1)
    const [groupIdListNumber, setGroupIdListNumber] = useState({
        groupNumber: 0,
        phoneNumber: 0
    })
    // 手动输入手机号的数据存储
    const [phoneData, setPhoneData] = useState({
        content: '',  //输入框文本
        correct: [] as any,  //过滤出的正确号码list
        error: [] as any,  // 过滤出的错误号码
    })

    useEffect(() => {
        onlyExcel && setTypeCode(1)
    }, [onlyExcel])

    // 过滤号码的方法，区分出正确错误号码
    const filterNumber = () => {
        const mobileReg = /([1][3,4,5,6,7,8,9][0-9]{9})(?![0-9])/g
        const mobileRegErr = /([1][0,1,2][0-9]{9})(?![0-9])/g
        const mobileRegErr2 = /([0-9]{11})([0-9])/g
        const correct = [...new Set(phoneData.content.match(mobileReg))]
        const error = [...new Set([...phoneData.content.match(mobileRegErr2) || [], ...phoneData.content.match(mobileRegErr) || []])]
        setPhoneData({
            content: (correct.length > 0 ? correct.join('\r\n') : ''),
            correct,
            error
        })
        setNumberList(correct);
        message.success(`筛选出格式正确号码${correct.length}个`)
    }

    // 分类的切换事件
    const typeChange = (typeId: number) => {
        setTypeCode(typeId)
        formRef?.current?.setFieldsValue({
            typeCode: typeId,
            // phoneNumber: []
        });
    }
    return (<div className='j-msg-send-type'>
        {/* 三类上传号码方式的切换 */}
        <Radio.Group value={typeCode} onChange={(e) => typeChange(e.target.value)}>
            <Radio.Button value={1}>excel上传</Radio.Button>
            <Radio.Button value={2} disabled={onlyExcel}>通讯录</Radio.Button>
            <Radio.Button value={3} disabled={onlyExcel}>手动输入</Radio.Button>
        </Radio.Group>
        {/* 上传文件 */}
        {typeCode === 1 ?
            <div className='mt-4'>
                <ProFormUploadButton
                    width="md"
                    name="file"
                    fieldProps={{
                        name: 'file',
                        maxCount: 1,
                        beforeUpload: (file: any) => {
                            console.log(file)
                            return false
                        }
                    }}
                    rules={[{ required: true, message: '请上传资料' }]}
                    action={`${BASEURL}/upload`}
                    extra={<div className='mt-4'>
                        单次最多上传100000个号码，单次任务最大支持8个变量。<br />
                        点击下载：<a href="https://cloud-parent.oss-cn-hangzhou.aliyuncs.com/fileServer/%E7%BE%A4%E5%8F%91%E7%9F%AD%E4%BF%A1%E6%A8%A1%E6%9D%BF%281%29.xlsx">群发模板</a> 导入手机号和变量。
                    </div>}
                />
            </div> : null
        }
        {/* 选择通讯录 */}
        {typeCode === 2 ?
            <div className='mt-4'>
                <ProFormSelect
                    style={{ width: 180 }}
                    name="groupIdList"
                    rules={[{ required: true, message: '请选择通讯录' }]}
                    fieldProps={{
                        mode: 'multiple',
                        allowClear: false,
                        placeholder: '请选择通讯录名单',
                        onChange: (value, options) => {
                            let count = 0
                            options.forEach((element: { phoneNum: any; }) => {
                                count += element.phoneNum
                            });
                            setGroupIdListNumber({
                                groupNumber: options.length,
                                phoneNumber: count
                            })
                        }
                    }}
                    request={
                        async () => {
                            const { result } = await smsMaiPageList({ pageNum: 1, pageSize: 999 }) as any
                            const res = handleDvmCommonListResult(result)
                            return res?.map(({ id: value, groupName: label, phoneNum }: any) => ({ label, value, phoneNum }))
                        }
                    }
                />
                {/* <a href="" className='ml-4'>新增通讯录</a> */}
                <div className='mt-2'>当前已选分组{groupIdListNumber.groupNumber}组，总共包含号码： {groupIdListNumber.phoneNumber}个</div>
            </div>
            : null
        }
        {/* 手动输入 */}
        {typeCode === 3 ?
            <div className='mt-4'>
                <Button type="primary" ghost>
                    点击过滤手机号码
                </Button>
                <Popover content={'过滤无效、错误号码'} className='ml-2' title="">
                    <QuestionCircleOutlined className='ml-2' />
                </Popover>
                <div className='mt-4'>
                    <Row>
                        <Col span={11}>
                            <ProFormTextArea
                                rules={[{ required: true, message: '请输入手机号码' }]}
                                placeholder='请在此输入或者黏贴手机号，用任意字符间隔开'
                                name="phone"
                                fieldProps={{
                                    allowClear: false,
                                    value: phoneData.content,
                                    onBlur: () => filterNumber(),
                                    onChange: (e: any) => setPhoneData({ ...phoneData, content: e.target.value })
                                }}
                            />
                            <div className='mt-2 true_text'>
                                <Popover content={'由1开头第二位是3~9的十一位数字'} title="手机号格式规则">
                                    <span className='text-blue-400'>格式</span>
                                </Popover>正确的号码： {phoneData.correct.length}个
                            </div>
                        </Col>
                        <Col span={10}>
                            <div className='errPhoneDiv'>
                                {
                                    phoneData.error.map((item: string, index: number) => {
                                        return <p key={index}>{item}</p>
                                    })
                                }
                            </div>
                            <div className='mt-4 ml-2'>过滤错误的号码： {phoneData.error.length}个</div>

                        </Col>
                    </Row>
                </div>
            </div> : null
        }
    </div>

    )
}





// 上传号码的组件   上传文件、手动输入过滤 ----国际短信
export const MsgSendTypeGlobal = ({ formRef, onlyExcel, setPhoneList }: any) => {
    const [typeCode, setTypeCode] = useState(1)

    // 手动输入手机号的数据存储
    const [phoneData, setPhoneData] = useState({
        content: '',  //输入框文本
        correct: [] as any,  //过滤出的正确号码list
    })

    useEffect(() => {
        onlyExcel && setTypeCode(1)
    }, [onlyExcel])

    // 过滤号码的方法，区分出正确错误号码
    const filterNumber = () => {
       
        const correct = [...new Set(phoneData.content.match(/\d+/g))]
        setPhoneData({
            content: (correct.length > 0 ? correct.join('\r\n') : ''),
            correct,
        })
        setPhoneList(correct.join(','));
        message.success(`筛选出格式正确号码${correct.length}个`)
    }

    // 分类的切换事件
    const typeChange = (typeId: number) => {
        setTypeCode(typeId)
        formRef?.current?.setFieldsValue({
            typeCode: typeId,
            // phoneNumber: []
        });
    }
    return (<div className='j-msg-send-type'>
        {/* 三类上传号码方式的切换 */}
        <Radio.Group value={typeCode} onChange={(e) => typeChange(e.target.value)}>
            <Radio.Button value={1}>excel上传</Radio.Button>
            {/* <Radio.Button value={2} disabled={onlyExcel}>通讯录</Radio.Button> */}
            <Radio.Button value={3} disabled={onlyExcel}>手动输入</Radio.Button>
        </Radio.Group>
        {/* 上传文件 */}
        {typeCode === 1 ?
            <div className='mt-4'>
                <ProFormUploadButton
                    width="md"
                    name="file"
                    
                    fieldProps={{ 
                        maxCount: 1,
                        beforeUpload:(file: any) => {
                            console.log(file)
                            return false
                        }
                    }}
                    rules={[{ required: true, message: '请上传资料' }]}
                    action={`${BASEURL}/upload`}
                    extra={<div className='mt-4'>
                        单次最多上传100000个号码，单次任务最大支持8个变量。<br />
                        点击下载：<a href="https://cloud-parent.oss-cn-hangzhou.aliyuncs.com/fileServer/%E7%BE%A4%E5%8F%91%E7%9F%AD%E4%BF%A1%E6%A8%A1%E6%9D%BF%281%29.xlsx">群发模板</a> 导入手机号和变量。
                    </div>}
                />
            </div> : null
        }
        {/* 手动输入 */}
        {typeCode === 3 ?
            <div className='mt-4'>
                <Button type="primary" ghost>
                    点击过滤手机号码
                </Button>
                <Popover content={'格式化按照隔断拆分号码'} className='ml-2' title="">
                    <QuestionCircleOutlined className='ml-2' />
                </Popover>
                <div className='mt-4'>
                    <ProFormTextArea
                        rules={[{ required: true, message: '请输入国际短信手机号码' }]}
                        placeholder='请在此输入或者黏贴手机号，用任意字符间隔开'
                        name="phone"
                        fieldProps={{
                            allowClear: false,
                            value: phoneData.content,
                            onBlur: () => filterNumber(),
                            onChange: (e: any) => setPhoneData({ ...phoneData, content: e.target.value })
                        }}
                    />
                    <div className='mt-2 true_text'>
                        <Popover content={'国际号码格式为号码串，不做具体位数限制'} title="国际号码格式规则">
                            <span className='text-blue-400'>格式</span>
                        </Popover>正确的号码： {phoneData.correct.length}个
                    </div>
                </div>
            </div> : null
        }
    </div>

    )
}