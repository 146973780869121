import ProTable, { ActionType } from "@ant-design/pro-table"
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home"
import { productApi } from "@utils/util"
import { Button, Col, Modal, Popover, Row, Tabs, Tag, message } from "antd"
import React, { useRef, useState } from "react"
import { columnsFn, templateLibColumnsFn } from "./data"
import ProForm, { 
    ModalForm,
    ProFormText, 
    ProFormSelect,
    ProFormRadio,
    ProFormTextArea
} from '@ant-design/pro-form';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { SMSTemplateEditor } from "@components/Common"
import { smsTempAdd, smsTempApply, smsTempDel, smsTempPageList } from "@api/product"
import { handleDvmCommonPageListResult } from "@utils/request"
import { useSelector } from "react-redux"
import { StateType } from "@store/index"
import { handleSmsTempHtmlToData } from "@components/Common/SMSTemplateEditor"
import { CERT_COMPANY_FAIL, CERT_PERSON_FAIL, calcCertStatus } from "@utils/index"

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 16 },
};

// 接口请求的 api 层
const getApi = productApi(smsTempPageList)  // 查询的接口封装
const addUpdateSmsTempApi = productApi(smsTempAdd) // 列表数据的新增/编辑 接口
const delApi = productApi(smsTempDel) // 表单数据的删除 接口
const applyApi = productApi(smsTempApply) // 应用模版
const getLibListApi = handleDvmCommonPageListResult(getApi)
const getListApi = handleDvmCommonPageListResult(getApi)

// 短信模版库
const SMSTemplateLib = () => {
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref

    // const getListApi = async (params: any, sorter: any, filter: any) => {
    //     const { data } = await getApi({...params}, false)
    //     const { values, total } = data || {}
    //     return {
    //         data: values || [],
    //         success: true,
    //         total
    //     }
    // }
    // const getLibListApi = handleDvmCommonPageListResult(getApi)

    const operate = async (type: any, record: any) => {
        const { utlId } = record || {}
        if (type === '应用该模版') {
            await applyApi({utlId}, true, onSuccess)
        }
    }

    const columns = templateLibColumnsFn(operate) // 表格列配置

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        console.log('处理后的成功回调触发了')
        reFresh()
    }
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    return (<ProTable<any>
        columns={columns}
        params={{}} // 查询所需要的额外参数
        request={getLibListApi}
        options={{reload: false, density: false, setting: false}}
        toolbar={{
            actions: [],
        }}
        rowKey="id"
        pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
        actionRef={ref as any}
        search={{
            optionRender: (searchConfig, formProps, dom) => [ ...dom.reverse() ]
        }}
    />)
}

interface SMSTemplateLibModalProps extends ModalFormProps {

}
// 模版库弹窗形式
const SMSTemplateLibModal = ({...rest}: SMSTemplateLibModalProps) => {
    return (<ModalForm {...rest}>
        <SMSTemplateLib />
    </ModalForm>)
}

// 新增编辑短信模版
const TemplateExtra = () => {
    return (<div>
        • <span dangerouslySetInnerHTML={{__html: '短信字数含"签名+模版内容+变量内容”，短信70个字数含以内，按1条短信计费；超出70个字为长短信，按照67个字数记为'}}></span>
        • 短信支持退订（结尾自动加上“回T退订”，占模版内容4个字数）<br/>
        • 推广短信及群发助手不支持加变量<br/>
        • 推广短信建议在结尾加上空格或其他字符，便于区分营销内容<br/>
        • 不能发送贷款/借款/中奖/抽奖类短信,不支持金融理财&房产通知类短信。
    </div>)
}

export const PopoverContent = () => (<div> 
    ①如有变量，可点击对应的变量类型插入到内容输入框，变量格式自动生成。<br />

    <div> ②变量格式是${`{ }`}，您可以修改{`{ }`}内的变量名称，支持英文、下划线。</div>

    ③变量名称对应的变量替换值不能超过50个字符。<br />

    ④同个模板内，变量名称不能重复。<br />
</div>)

interface AddUpdateSMSTemplateProps extends ModalFormProps{
    submitParams?: any;
    onSuccess?: () => void;
}
const AddUpdateGlobalSMSTemplate = ({submitParams, onSuccess, ...rest}: AddUpdateSMSTemplateProps) => {
    const { certInfo } = useSelector((store: StateType) => store?.userInfo) as any
    const { userId } = certInfo 
    const onFinish = async (values: any) => {
        const { utlContent: html, ...rest } = values
        const { content: utlContent }  = handleSmsTempHtmlToData(html) as any
        const { success } = await addUpdateSmsTempApi({...rest, utlOriginalText: html, utlContent, userId, utlSmsArea: 2, ...submitParams}, true, onSuccess) as any
        return success
    }

    return (<ModalForm<any>
        layout="horizontal" 
        {...layout} 
        onFinish={onFinish} 
        request={async (params: any) => {
            return {
                utlName: '',
                utlContent: ''
            }
        }}
        {...rest}>
        <ProFormText 
            width="md" 
            name="utlName" 
            label="模版名称"
            rules={[{ required: true, message: '请输入'}]} 
            placeholder="请选择" 
        />
        <ProForm.Item name="utlContent" label="模版内容" extra={`模版中不允许出现 "$", 避免被识别为变量!`}>
            <SMSTemplateEditor popoverContent={PopoverContent} />
        </ProForm.Item>
    </ModalForm>)
}

// 模版管理
export default () => {
    const { certInfo, baseInfo } = useSelector((store: StateType) => store?.userInfo) as any
    const { userId } = certInfo
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [modalProps, setModalProps] = useState({visible: false, title: '添加模版', params: {}})
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    const { userNature: type, verifyState: status } = (baseInfo || {}) as any
    const STATUS = calcCertStatus(type, status)
    // operate 函数为增删改查需要处理的业务
    const operate = (type: any, record: any) => {
        const { utlId } = record || {}
        if (type === '新增') {
            // 需求方说 认证失败{个人/企业} 不允许新增
            if ([CERT_PERSON_FAIL, CERT_COMPANY_FAIL].includes(STATUS)) return message.warning('实名认证失败，不允许操作')
            setModalProps({
                ...modalProps,
                title: '添加模版',
                params: {_t: new Date().valueOf()},
                visible: true,
            })
        } else if (type === '修改') {
        } else if (type === '详情') {
            // 陈曦老板说这是重复内容，不做（特此记录）
            // Modal.info({
            //     title: '详情信息',
            //     content: (
            //       <div>
            //         <div>模板名称: 无模板z-推广</div>
            //         <div>模板类型: </div>
            //         <div>模板CODE: </div>
            //         <div>创建时间: 2021-04-29 14:42:19</div>
            //         <div>审核状态: 审核成功</div>
            //         <div>模板内容: 审核成功</div>
            //         <div>申请说明: </div>
            //       </div>
            //     ),
            //     onOk() {},
            // })
        } else if (type === '删除') {
            const utlIds = record ? [utlId] : selectedRowKeys
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                centered: true,
                cancelText: '取消',
                onOk: async() => {
                    await delApi({utlIds: utlIds.toString()}, true, () => {
                        reFresh()
                    })
                },
            });
        }
    }

    // const getListApi = async (params: any, sorter: any, filter: any) => {
    //     const { data } = await getApi({...params}, false)
    //     const { values, total } = data || {}
    //     return {
    //         data: values || [],
    //         success: true,
    //         total
    //     }
    // }
    // const getListApi = handleDvmCommonPageListResult(getApi)

    const columns = columnsFn(operate) // 表格列配置

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        console.log('处理后的成功回调触发了')
        setModalProps({
            ...modalProps,
            visible: false
        })
        reFresh()
    }
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const calcDelDisabled = () => selectedRowKeys.length
    return (<><ProTable<any>
        style={{flex: 1, overflow: 'scroll'}}
        scroll={{x: 1300}}
        columns={columns}
        params={{ userId, utlSmsArea: 2 }} // 查询所需要的额外参数
        request={getListApi}
        options={{reload: false, density: false, setting: false}}
        toolbar={{
            actions: [
                // <SMSTemplateLibModal width={1200} layout="horizontal" key="btn0" title="模版库" trigger={<Button  type='primary'>模版库</Button>} />,
                // <AddUpdateGlobalSMSTemplate key="btn1" title="添加模版" onSuccess={reFresh} trigger={<Button type='primary'>添加模版</Button>} />,
                <Button key="btn1" type='primary' onClick={() => operate('新增', null)}>添加模版</Button>,
                // <Button key="btn2" type='primary' disabled={!calcDelDisabled()} onClick={() => operate('删除', null)}>删除</Button>,
            ],
        }}
        rowKey="utlId"
        pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
        actionRef={ref as any}
        search={{
            optionRender: (searchConfig, formProps, dom) => [ ...dom.reverse() ]
        }}
        // rowSelection={{
        //     preserveSelectedRowKeys: true,
        //     onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
        // }}
    />
    <AddUpdateGlobalSMSTemplate 
        modalProps={{
            maskClosable: false, 
            destroyOnClose: true,
            onCancel: () => setModalProps({...modalProps, visible: false})
        }}
        onSuccess={onSuccess} 
        {...modalProps}
    />,
    </>)
}
