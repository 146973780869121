import { Alert, Button, Form, Modal } from "antd"
import React, { useRef, useState } from "react"
import { Icon } from "@components/index"
import { userRecharge } from '@api/index'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ProForm, {
    ProFormText,
    ProFormUploadDragger,
    ProFormSelect,
    ProFormTextArea,
    ProFormDependency,
    ProFormMoney,
    ModalForm
} from '@ant-design/pro-form';
import {
  InfoCircleFilled
} from '@ant-design/icons';
import type { ModalFormProps, ProFormProps, ProFormInstance } from '@ant-design/pro-form'
import FormItem from "antd/es/form/FormItem";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "@store/index";
import { isBrowser, productApi } from "@utils/util";
import { asyncSetUserInfo } from "@store/actions";
import { useAlertNotLogin } from "@components/Home/Layout";
import { Description } from "@components/Common/AlertTip";

const { confirm } = Modal;
const userRechargeApi = productApi(userRecharge)

const PayAlert = () => (<Alert
  style={{marginBottom:'30px' }}
  type="warning"
  // description={
  //   <div>
  //     <InfoCircleFilled className="text-lg mr-2" style={{color: '#FAAD14'}}/>温馨提示<br />
  //     <div style={{textIndent: 22}}>1. 余额不足将影响现有业务的正常使用；</div>
  //     <div style={{textIndent: 22}}>2. 余额不足将无法购买平台上的任一产品。</div>
  //   </div>
  // }
  description={<Description title="温馨提示:" listTips={[
                '余额不足将影响现有业务的正常使用；',
                '余额不足将无法购买平台上的任一产品。'
            ]} />}
/>)

const useOncharge = () => {
  const dispatch = useDispatch();
  return async (values: any, onSuccess?: Function) => {
    const {data} = await userRechargeApi(values, false)
    const { url } = data || {}
    if (!isBrowser()) return
    const aliPayForm = document.createElement('div')
    aliPayForm.innerHTML = url
    document.body.appendChild(aliPayForm)
    const formList = document.getElementsByName('punchout_form')
    const getForm = formList[formList.length - 1] as any
    getForm.setAttribute('target', '_blank')
    getForm?.submit()
    getForm.remove()
    setTimeout(() => {
      confirm({
        title: '充值完成了吗?',
        okText: '支付完成',
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: '等待您完成支付，支付完成请点击支付完成',
        onOk() {
          dispatch(asyncSetUserInfo())
          onSuccess && onSuccess()
        },
        onCancel() {
          console.log('Cancel');
        }
      });
    }, 1000);
  }
}

const ChargeSubmitter = ({form}: any) => {
  const { baseInfo } = useSelector((store: StateType) => store?.userInfo) as any
  const onCharge = useAlertNotLogin(baseInfo)
  return <Button key="submit" type="primary" style={{marginLeft: '25%'}} onClick={() => onCharge(form?.submit)}>立即充值</Button>
}
interface ChargeFormProps extends ProFormProps {
  onSuccess?: () => void;
}
const ChargeForm = ({onSuccess, ...rest}: ChargeFormProps) => {

  const { baseInfo } = useSelector((store: StateType) => store?.userInfo) as any
  const { aciMoney, aciClouds, username, verifyState } = (baseInfo || {}) as any
  const onCharge = useOncharge()
  const onFinish = async (values: any)=> {
    onCharge(values)
  }

  return (<ProForm<any>
          style={{width: '100%'}}
          layout="horizontal"
          labelCol={{span: 6}}
          wrapperCol={{span: 18}}
          onFinish={onFinish}
          submitter={{render: ({form}, doms) => <ChargeSubmitter form={form} />}}
          {...rest}
      >
          <FormItem label="充值账户">{username}</FormItem>
          <FormItem label="账户余额"><span className="text-red-500">￥{aciMoney||0}</span></FormItem>
          <ProFormMoney
            // name="amount"
            name="rechargeBalance"
            label="充值金额"
            width="md"
            rules={[{ required: true, message: '请填写充值金额',}]}
            placeholder="请输入"
            extra={<div className="mt-2">可前往<a href="/cost/paymentDetail">充值明细</a>页面查看充值记录</div>}
          />
          <FormItem label="支付方式">
            <div className="flex items-center">
              <Icon type="icon-zhifubao" style={{fontSize: '20px' }} />
              <span className="ml-1">支付宝</span>
            </div>
          </FormItem>
      </ProForm>)
}

interface ChargeModalProps extends ModalFormProps{
  onSuccess?: () => void;
}
export const ChargeModal = ({onSuccess, ...rest}:ChargeModalProps)=> {
  const [form] = Form.useForm()
  const onCharge = useOncharge()
  const onFinish = async ()=> {
    const values = await form.validateFields()
    onCharge(values)
    onSuccess && onSuccess()
    return true
  }
  return (<ModalForm
      title="充值"
      layout="horizontal"
      labelCol={{span: 6}}
      wrapperCol={{span: 18}}
      submitter={{render: ({form}, doms) => <ChargeSubmitter form={form} />}}
      onFinish={onFinish}
      {...rest}
    >
        <PayAlert />
        <ChargeForm
          form={form}
          submitter={false}
        />
  </ModalForm>)
}

export default () => {
  return (<div className="bg-white p-6">
    <PayAlert />
    <ChargeForm />
  </div>)
}
