import React, { useContext, useEffect, useRef, useState } from "react"
import ProForm, { 
    ProFormText, 
    ProFormUploadButton, 
    ProFormSelect, 
    ProFormTextArea, 
    ProFormCascader,
    ProFormProps
} from '@ant-design/pro-form';
import { Button } from "antd";
import AlertTip from "@components/Common/AlertTip";
import { urlOptions } from "../../data";
import { productApi } from "@utils/index";
import { cdnRefresh } from "@api/index";
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 

const cdnRefreshApi = productApi(cdnRefresh)
const labelCol = {span: 2}
const wrapperCol = {span: 12}

const listTips: any = [
'CDN节点的缓存不实时更新，当您的源站内容更新后，可以通过控制台（或者调用API接口）的方式发送缓存刷新请求，强制CDN节点缓存过期。',
'选择URL刷新时，需要提交文件完整的URL路径，若提交目录则无刷新效果。',
'选择目录刷新时，建议谨慎刷新根目录。刷新根目录将导致所有缓存过期，所有请求将回源站下载，如果访问量过大可能导致源站崩溃。',
'选择目录刷新时，会对目录下所有资源，包含子目录进行刷新，仅占用一条目录刷新配额。',
'如果您需要刷新的URL中有中文，请同时刷新中文URL和转码后的URL。'
]

interface ProFormCommonProps extends ProFormProps{
  restNum?: any;
  otherSubmitParams?: string[];
  onSuccess?: () => void;
}
  
const mapType = ['', 'refreshUrlCount', 'refreshCatalogueCount']
// 用户--申请实名认证(个人)
export default ({restNum, ...rest}: ProFormCommonProps) => {
    const [num, setNum] = useState()
    const formRef = useRef<ProFormInstance>()
    const onFinish = async(values: any) => {
      const {urls, ...rest} = values
      await cdnRefreshApi({urls: urls.split('\n'), ...rest}, true, () => {
        formRef.current?.setFieldsValue({urls: ''})
      })
      
    }
    const onValuesChange = (value: any, {type}: any) => {
      const num = restNum[mapType[type]]
      setNum(num)
    }

    useEffect(() => {
      onValuesChange({}, {type: 1})
    }, [restNum])

    return (<ProForm<any>
      layout="horizontal"
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      onFinish={onFinish}
      style={{padding: 20}}
      formRef={formRef}
      onValuesChange={onValuesChange}
      submitter={{render: (props, doms) => [
        <Button key="submit" type="primary" style={{marginLeft: '8.5%'}} onClick={() => props.form?.submit?.()}>提交</Button>,
      ]}}
      {...rest}
      >
        <AlertTip type="warning" listTips={listTips} style={{marginBottom: 20}} />
        <ProFormSelect
          name="type" 
          label="操作方式"
          initialValue={1} 
          rules={[
            { required: true, message: '请选择操作方式',},
          ]} 
          request={async() => urlOptions}
          placeholder="请选择操作方式"
          extra={`今日剩余刷新量${num}条`}
        />
        <ProFormTextArea
          name="urls" 
          label="URL" 
          rules={[
            { required: true, message: '请填写URL'},
          ]} 
          placeholder={`一行一个URL, 示例:\n  http://www.example01.com/folder01/ \n  http://www.example01.com/folder02/`}
        />
    </ProForm>)
}
  