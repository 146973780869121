import { Empty, Result } from 'antd'
import React from 'react'
import './index.less'
import hardwork from '@assets/images/hardwork.png'
import empty from '@assets/images/empty.png'

export const NoPermission = () => {
   return <Result status="403" title="403" subTitle="暂无权限,请联系管理员!" />
}

export const SearchEmpty = () => {
   return(<Empty
      image={null}
      style={{height: '66vh', paddingTop: '15%'}}
      imageStyle={{ height: 120 }}
      description={<div><h3 style={{color: 'rgba(0, 0, 0, 0.85)'}}>未查询到相关知识内容</h3><p style={{color: 'rgba(0, 0, 0, 0.45)'}}>建议您修改搜索关键词重新再试</p></div>}
  >
  </Empty>)
}
 
export const NullData = () => {
   return(<Empty
      image={null}
      style={{height: '66vh', paddingTop: '8%'}}
      imageStyle={{ height: 10 }}
      description={<div>
         <img style={{width: '140px'}} src={empty} alt="" />
         <div className='mt-4'>暂无数据</div>
      </div>}
  >
  </Empty>)
}

export const HardWork = () => {
   return(<Empty
      image={hardwork}
      style={{paddingTop: '5%'}} 
      imageStyle={{ height: 400 }}
      description={<div>
         {/* <h3 style={{color: 'rgba(0, 0, 0, 0.85)'}}>程序员小哥哥正在努力开发该产品，敬请期待...</h3> */}
         <p style={{color: 'rgba(0, 0, 0, 0.45)'}}>程序员小哥哥正在努力开发该产品，敬请期待...</p>
      </div>}
  >
  </Empty>)
}

export default () => {
   return <div className="notfont-box"></div>
}
