import { getEnumByCode, homeGetCountry } from "@api/home"
import { smsTypeOptions } from "@utils/enum"
import { handleDvmCommonListResult } from "@utils/request"

export const columns_gmsg = [
  {
    title: '短信类型',
    dataIndex: 'pmisSmsType',
    valueType: 'select',
    request: async () => {
     return smsTypeOptions
    },
    search: {
      transform: (value: any) => {
        return {
          smsType: value
        }
      },
    },
  },
  {
    title: '国家',
    dataIndex: 'pmisCountryName',
    valueType: 'select',
    fieldProps: {
      mode: 'multiple',
    },
    request: async () => { // 搜索条件下拉框数据，可枚举，也可从接口获取
      const { result } = await homeGetCountry({}) as any
      // const res = handleDvmCommonListResult(result)
      return result?.map(({ countryCode: value, countryName: label }: any) => ({ label, value }))
    },
    search: {
      transform: (value: any) => {
        return {
          countryCode: value
        }
      },
    },
  },
  {
    title: '单价',
    dataIndex: 'pprUserPrice',
    hideInSearch: true,
  },
  {
    title: '单位',
    dataIndex: 'pmisUnit',
    hideInSearch: true,
  }
]


export const columns_msg = [
  {
    title: '短信类型',
    dataIndex: 'pmsmSmsType',
    hideInSearch: true,
    valueType: 'select',
    request: async () => {
      return smsTypeOptions
    },
  },
  {
    title: '单价',
    dataIndex: 'pprUserPrice',
    hideInSearch: true,
  },
  {
    title: '单位',
    dataIndex: 'pmsmUnit',
    hideInSearch: true,
  }
]

export const columns_cdn = [
  {
    title: '计费类型',
    dataIndex: 'pmcChargeType',
    valueType: 'select',
    hideInSearch: true,
    request: async () => { // 搜索条件下拉框数据，可枚举，也可从接口获取
      const { result } = await getEnumByCode({codeTypes: '606'}) as any
      return result[0]?.value?.map(({ value, caption: label }: any) => ({ label, value }))
    },
  },
  {
    title: '适合区域',
    dataIndex: 'pmcFitArea',
    valueType: 'select',
    hideInSearch: true,
    request: async () => { // 搜索条件下拉框数据，可枚举，也可从接口获取
      const { result } = await getEnumByCode({codeTypes: '607'}) as any
      return result[0]?.value?.map(({ value, caption: label }: any) => ({ label, value }))
    },
  },
  {
    title: '单价',
    dataIndex: 'pprUserPrice',
    hideInSearch: true,
  },
  {
    title: '单位',
    dataIndex: 'pmcUnit',
    hideInSearch: true,
  }
]

export const columns_others = [

  {
    title: '单价',
    dataIndex: 'pprUserPrice',
    hideInSearch: true,
  },
  {
    title: '单位',
    dataIndex: 'pmsgUnit',
    hideInSearch: true,
  }
]




export const partIspOptions = [
  {
    title: '运营商',
    dataIndex: 'pmsoType',
    valueType: 'select',
    hideInSearch: true,
    request: async () => { // 搜索条件下拉框数据，可枚举，也可从接口获取
      const { result } = await getEnumByCode({codeTypes: '504'}) as any
      return result[0]?.value?.map(({ value, caption: label }: any) => ({ label, value }))
    },
  },
  {
    title: '单价',
    dataIndex: 'pprUserPrice',
    hideInSearch: true,
  },
  {
    title: '单位',
    dataIndex: 'pmsoUnit',
    hideInSearch: true,
  }
]

export const paasOptions = [
  {
    title: '计费类型',
    dataIndex: 'pmsoType',
    valueType: 'select',
    hideInSearch: true,
    request: async () => { // 搜索条件下拉框数据，可枚举，也可从接口获取
      const { result } = await getEnumByCode({codeTypes: '647'}) as any
      return result[0]?.value?.map(({ value, caption: label }: any) => ({ label, value }))
    },
  },
  {
    title: '单价',
    dataIndex: 'pprUserPrice',
    hideInSearch: true,
  },
  {
    title: '单位',
    dataIndex: 'pmsoUnit',
    hideInSearch: true,
  }
]


export const saasOptions = [
  {
    title: '类型',
    dataIndex: 'pmsoType',
    valueType: 'select',
    hideInSearch: true,
    request: async () => { // 搜索条件下拉框数据，可枚举，也可从接口获取
      const { result } = await getEnumByCode({codeTypes: '649'}) as any
      return result[0]?.value?.map(({ value, caption: label }: any) => ({ label, value }))
    },
  },
  {
    title: '单价',
    dataIndex: 'pprUserPrice',
    hideInSearch: true,
  },
  {
    title: '单位',
    dataIndex: 'pmsoUnit',
    hideInSearch: true,
  }
]

export const columns_safe = [
  {
    title: '计费类型',
    dataIndex: 'pmsfType',
    valueType: 'select',
    hideInSearch: true,
    request: async () => { // 搜索条件下拉框数据，可枚举，也可从接口获取
      const { result } = await getEnumByCode({codeTypes: '608'}) as any
      return result[0]?.value?.map(({ value, caption: label }: any) => ({ label, value }))
    },
  },
  {
    title: '单价',
    dataIndex: 'pprUserPrice',
    hideInSearch: true,
  },
  {
    title: '单位',
    dataIndex: 'pmsfUnit',
    hideInSearch: true,
  }
]