import axios from 'axios';
import { RequestOptions } from './requestInterface';
import successHandler from './successHandler';
import { requestError } from './errorHandler';
import { message } from 'antd'
import { getStorage, isBrowser } from '../util';
import { stringify } from 'qs';
import FormData from 'form-data'
import { PROXY_BASEAPI } from '@/api/baseUrl';

export const TOKENKEY = 'X-Access-Token'

const server = axios.create({
    baseURL: '',
    withCredentials: true,
    timeout: 360000, // 请求超时时间
})

// 添加请求拦截器
server.interceptors.request.use(
    (config: any) => config,
    (error: any) => Promise.reject(error)
);

// 添加响应拦截器
server.interceptors.response.use(
    (response: any) => {
        const { data } = response
        response.data = Object.assign({}, {code: 200}, data)
        return Promise.resolve(response)
    },
    (error: any) => {
        const {data} = error.response || {};
        isBrowser() && message.error(`接口响应错误: ${JSON.stringify(data && data.msg || '')}`);
        return Promise.reject(error)
    }
)

export default function request<T>(options: RequestOptions): Promise<T>{
    const { url, headers = {}, data, withToken, ...rest } = {withToken: true, ...options}
    const newOptions: Record<string, any> = {
        credentials: 'include',
        method: 'POST',
        headers: new Headers( headers || {Accept: 'application/json'} ),
        // headers: new Headers( headers || {'Content-Type': 'application/x-www-form-urlencoded'} ),
        data,
        ...rest
    }

    if (['POST', 'PUT'].includes(newOptions.method)) {
        if (!(newOptions.body instanceof FormData)) {
            newOptions.headers = Object.assign({
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
            }, headers)
        } else {
            newOptions.headers = Object.assign({
                Accept: 'application/json',
            },headers)
        }
    }
    const requestUrl = !['GET'].includes(newOptions.method) ? url : `${url}?${stringify(data)}`;
    (getStorage(TOKENKEY) && withToken) && Object.assign(newOptions.headers, {
        [TOKENKEY]: getStorage(TOKENKEY)
    })
    return server(requestUrl as string, newOptions)
           .then((response: any) => response.data )
           .then((source: RequestOptions) => successHandler(source, newOptions))
           .catch((error: any) => requestError(error, newOptions))
}


// 处理后段接口返回的结果集 对象merge
export const handleDvmCommonResult = (result: any) => {
    // console.log('cxxxxx', result)
    const res = (result||[]).map(({value}: any, i: number) => value).flat().reduce((pre: any, next: any) => ({...pre, ...next}), {})
    return res
}

// 处理后段返回过来的 list 数据
export const handleDvmCommonListResult = (result: any, alias = 'result') => {
    return (result||[]).find((v: any) => v.alias === alias)?.value || []
}

// 处理后段返回的分页列表数据, 后段数据只在第一页的时候返回总条数，这里用闭包保存 总条数 total
export const handleDvmCommonPageListResult = (api: Function, callback?: Function) => {
    let total = 0
    return async (params: any, sorter: any, filter: any) => {
        const { current } = params || {}
        const { result } = await api({...params}, false)

        const data = handleDvmCommonListResult(result)

        if (current === 1) {
            total = handleDvmCommonListResult(result, 'resultcount')[0]?.RET
        }
        // console.log('total', params, total)
        return {
            data: data || [],
            success: true,
            total
        }
    }
}
