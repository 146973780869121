import React, { useEffect, useRef, useState } from "react"
import { homeGetCustomPage } from '@api/index'
import { handleDvmCommonListResult } from "@utils/request"
import { useLocation } from "react-router-dom";
import { parseSearch } from "@utils/util";
import IframeResizer from "iframe-resizer-react";

const domains = ['http://192.168.8.141']
const Iframe = () => {
    const ref = useRef<any>(null)
    const [height, setHeight] = useState<any>(300)

    return (<div style={{paddingTop: 64}}>
        <IframeResizer
            src="http://192.168.8.141/play"
            // src="./a.html"
            style={{ width: '1px', minWidth: '100%', height}}
            ref={ref}
            onLoad={() => {
                window.addEventListener('message', (e) => {
                    if (domains.includes(e.origin)) {
                        setHeight(e.data + 64)
                    }
                    console.log(`接收到消息:`, e)
                });
            }}
        />
    </div>)
}

const getCustomInitialProps = async (params: any) => {
    const { result } = await homeGetCustomPage(params) as any
    const [ item ] = handleDvmCommonListResult(result)
    return item.pdgContent
}

const Custom = ({initialProps}: any) => {
    const { search } = useLocation();
    const { pdgId } = parseSearch(search)
    const [pageContent, setPageContent] = useState<any>(initialProps)
    const getApi = async (params: any) => {
        const { result } = await homeGetCustomPage(params) as any
        const [ item ] = handleDvmCommonListResult(result)
        setPageContent(item?.pdgContent)
    }
    useEffect(() => {
        getApi({pdgId})
    }, [])

    useEffect(() => {
      initialProps ? null : getApi({pdgId})
    }, [initialProps]);

    return (<div style={{paddingTop: 64}}>
        <div dangerouslySetInnerHTML={{__html: pageContent}}></div>
    </div>)
}

Custom.getInitialProps = getCustomInitialProps

export default Custom
