import React, { Children, Fragment, cloneElement, forwardRef, useEffect, useMemo, useRef, useState } from "react"
import ReactDOM from "react-dom"
import './index.less'
import { rest } from "lodash"
import { isBrowser } from "@utils/util"

type type_Animation = {
    children: any, // 接收的子组件
    type?: 'left' | 'right' | 'top' | 'bottom',  //动画样式
    time?: number, // 动画时长 
    index? : number, //记录当前是第几个，对于多个子组件的动画给每个子组件添加对应延迟形成依次效果
    delay? :number, //多个组件的时候每个组件之间执行的间隔时间，一个一个渐进效果
    ref?: any,
    show?: boolean
}

export const Animation = ({children, height = 0, ...rest}:any)=> {
    const allRef = children.length > 1 ? children[0].ref || useRef(null) as any : useRef(null) as any
    const [show, setShow] = useState(false)
    const all_height = isBrowser() ? document.body.clientHeight : 1200   //页面显示区域总高度
    const handleScroll = () => {
        const { top } = allRef.current?.getBoundingClientRect() || {top: 0}
        // console.log(111, top, (-height || - allRef.current?.scrollHeight))
        if(top < 0 + (-height || - allRef.current?.scrollHeight) || top > all_height){
            setShow(false)
        }else {
            setShow(true)
        }
    }
    useEffect(()=> {
        handleScroll()
        isBrowser() &&  document.addEventListener('scroll', handleScroll, true);
        return (()=> {
            isBrowser() &&  document.removeEventListener('scroll', handleScroll)
        })
     }, [])
    if (children instanceof Array) {
        return  <Fragment>
            {
                children.map((element: any, index: number) => {
                    return useMemo(()=> <Animation.item key={index} show={show} ref={allRef} index={index} {...rest} >{element}</Animation.item>,[show]) 
                })
            }
        </Fragment>
    } else {
        return useMemo(()=> <Animation.item show={show} ref={allRef} {...rest}>{children}</Animation.item>,[show]) 
    }
}

//forwardRef  透传 ref
Animation.item =  forwardRef((props ,ref:any) => {
    const {children, ref: allRef,type = 'top',show = false, index = 0, time = 0.8, delay = 0.03 }:type_Animation = props as any
    const { props: { className = '', style }, type:domType } = children || {} 
    const [itemClass, setItemClass] = useState(className+ ' animation_goOut_' + type )  
    useEffect(()=> {
        setItemClass(`${className + (show ? ' animation_advance_' : ' animation_goOut_') + type }`)
    }, [show])
    const Children = cloneElement(children,{...children.props,id: 35 * index, ref:index === 0 ? ref : allRef,style:{...style, transition: (show ? time : 0) + 's', transitionDelay: (show ? delay : 0) * index + 's'}, className: itemClass, } )
    return Children
})



type AnimationTextProps = {
    children: any,
    type?: 'left' | 'right' | 'top' | 'bottom' | '3D_roll'
}
// 文字动画
Animation.text = (({children,  ...rest}:AnimationTextProps)=> {
    if(typeof children === 'string') {
        return <Animation  time={0.9} {...rest}>
           {children.split('').map((element:any, index:number) => {
               return <span key={index} className="animation_text_item ">{element}</span>
           })
           }
        </Animation>
    } else {
        return children
    }
    
})

type NumberRollProps = {
    children: any,
    time?: number,
    // type?: 'left' | 'right' | 'top' | 'bottom' | '3D_roll'
}
// 数字动画
Animation.NumberRoll = (({children, time = 3,  ...rest}:NumberRollProps)=> {
    const allRef = useRef(null) as any
    const timeRef = useRef(null) as any
    const [number, setNumber] = useState(0) as any
    const [show, setShow] = useState(false)
    const all_height = isBrowser() ? document.body.clientHeight : 1200   //页面显示区域总高度
    const handleScroll = () => {

        const { top } = allRef.current?.getBoundingClientRect() || {top: 0}
        if(top < 0 + (- allRef.current?.scrollHeight) || top > all_height){
            setShow(false)
        }else {
            setShow(true)
        }
    }
    useEffect(()=> {
        handleScroll()
        isBrowser() &&  window.addEventListener('scroll', handleScroll);
        return (()=> {
            isBrowser() &&  window.removeEventListener('scroll', handleScroll)
        })
     }, [])
     useEffect(()=> {
        if( show ) {
            timeRef.current =  setInterval(() => {
                setNumber((prevState: any) => {
                    if (prevState >= children * 0.9){
                        clearInterval(timeRef.current)
                        return children
                    }
                    return prevState + children * 0.04
                })
            }, 50);
        } else {
            setNumber(0)
        }
        return (()=> {
            timeRef.current && clearInterval(timeRef.current)
        })
    }, [show])
    
    return <span ref={allRef} >{number}</span>
    
})

export default Animation