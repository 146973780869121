import React, { useEffect } from 'react'
import './index.less'
export default () => {
    return (
        <div>
            other
        </div>
    )
}

// 网站首页移动端时的右上角菜单动态图标
export const MenuIcon = ({state, change}:{state: boolean, change: any}) => {
   const onClick = () => {
    document.getElementById('hamburger').className = (state ? "hamburger-menu" :'hamburger-menu  animate')
    change(!state)
   }
   useEffect(()=> {
    document.getElementById('hamburger').className = (state ? "hamburger-menu  animate" :'hamburger-menu ')
   }, [state])
    return (
        <div className="MobileMenuIcon">
            <div  id="hamburger" className="hamburger-menu" onClick={onClick} ></div>
        </div>
    )
}