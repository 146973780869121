import { ProColumns } from "@ant-design/pro-table"
import { fileHttpUrl } from "@api/baseUrl"
import React from "react"

export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '文件名',
            dataIndex: 'fileName',
            hideInSearch: true
        },
        {
            title: '上传时间',
            dataIndex: 'uploadTime',
            hideInSearch: true
        },
        {
            title: '号码总量',
            dataIndex: 'phoneTotal',
            hideInSearch: true
        },
        {
            title: '重复号码',
            dataIndex: 'repeatPhone',
            hideInSearch: true,
            tooltip: '下载的文件中不包含重复号码'
        },
        {
            title: '计费数',
            dataIndex: 'feeTotal',
            hideInSearch: true
        },
        {
            title: '备注',
            dataIndex: 'remark',
            hideInSearch: true
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            valueType: 'option',
            fixed: 'right',
            render: (text: any, record: any) => [
                // <a key="下载" style={{marginRight: 8}} download href={fileHttpUrl(record.url)}>下载</a>,
                [2].includes(record.status) ? <a key="下载" style={{marginRight: 8}} onClick={() => operate('下载', record)}>下载</a> : null,
                <a key="删除" onClick={() => operate('删除', record)}>删除</a>,
            ]
        }
    ]
}