import React, { useRef, useState } from 'react';
import './index.less';
import { Button, Table, Checkbox, Popover, message } from 'antd';
import { fileHttpUrl } from '@api/baseUrl';
import type { ProFormInstance } from '@ant-design/pro-form';
import ProForm, {
  ProFormText,
  ProFormUploadDragger,
  ProFormSelect,
  ProFormTextArea,
  ProFormDependency,
  ProFormRadio,
  ProFormProps,
} from '@ant-design/pro-form';
import { OverPack } from 'rc-scroll-anim';
import QueueAnim from 'rc-queue-anim';
import { emailReg, setStorage } from '@utils/util';
import { homeAddUs } from '@api/home';
import { StateType } from '@store/index';
import { useSelector } from 'react-redux';
const OverPackCpn = OverPack as any;

const TopDiv = () => {
  const { ueSaasName } = useSelector(
    (state: StateType) => state.domainInfo,
  ) as any;
  return (
    <div
      className="top-div"
      style={{
        background: `url(${fileHttpUrl(
          'test/image/0CC420B8459C4605A1EC39EEEBF0B17F.png',
        )}) no-repeat`,
      }}
    >
      <div className="title">{ueSaasName}合作伙伴</div>
      <div className="w-96 mt-8">
        开放生态，致力于帮助合作伙伴构建基于云产品和服务的成功业务实践和解决方案,让伙伴的分销网络呈现几何式裂变。
      </div>
      <a href="#joinUsDiv">
        <Button type="primary" danger className="mt-12">
          加入我们
        </Button>
      </a>
    </div>
  );
};

const StepDiv = () => {
  return (
    <div className="px-20 py-8 bg-white-500">
      <div className="mt-8 text-center text-3xl font-medium">
        开放平台、合作共赢
      </div>
      <div className="">
        <img
          className="w-full my-10"
          src={fileHttpUrl(
            'fileServer/2022/11/03/f1269ccc579446f68872e9593df0dc14.png',
          )}
          alt="云极合作流程"
        />
      </div>
    </div>
  );
};

const CooperationTypeDiv = () => {
  const { ueSaasName } = useSelector(
    (state: StateType) => state.domainInfo,
  ) as any;

  return (
    <div className="cooperation-type-div px-20 py-10">
      <div className="mt-8 text-center text-3xl font-medium">
        开放平台、合作共赢
      </div>
      <div className="content mt-10 relative">
        <div
          className="left relative "
          style={{
            background: `url(${fileHttpUrl(
              'fileServer/2022/11/03/9426db5aaac744e187927947dd250982.png',
            )}) no-repeat`,
          }}
        >
          <div className="redDiv"></div>
          <div className="insideDiv">
            <div className="font-medium text-lg">云商城SaaS云部署</div>
            <div className="mt-4 text-gray-400" style={{fontSize:'1rem'}}>合作模式</div>
            <div className="mt-2 textDiv">
              帮助合作伙伴快速集成{ueSaasName}
              的云应用和服务，提供售前售后全流程托管服务。为云合作伙伴降低运作成本，提高管理效率。要求合作伙伴有一定的客户资源和产品开发、整合能力。
              SaaS云部署总体拥有成本低，开放生态，无服务器架构，支持秒级弹性伸缩，快速实现大规模弹性负载需求。
            </div>
            <div className="mt-8 text-red-500" style={{fontSize: '1rem'}}>
              温馨提示：合作伙伴可以选择统一域名或者独立域名进行运作。
            </div>
          </div>

          <a href="#joinUsDiv">
            <Button type="primary" ghost block danger className="btn">
              加入我们
            </Button>
          </a>
        </div>
        <div
          className="right relative"
          style={{
            background: `url(${fileHttpUrl(
              'fileServer/2022/11/03/9426db5aaac744e187927947dd250982.png',
            )}) no-repeat`,
          }}
        >
          <div className="redDiv"></div>
          <div className="insideDiv">
            <div className="font-medium text-lg">云商城PaaS私有化部署</div>
            <div className="mt-4 text-gray-400" style={{fontSize:'1rem'}}>合作模式</div>
            <div className="mt-2 textDiv">
              全面帮助合作伙伴部署、发布、管理{ueSaasName}
              产品和服务。支持快速、低成本、免运维地构建{ueSaasName}相关产品应用，是完善的企业级平台服务。要求合作伙伴具备云产品销售咨询和服务能力，有一定的客户基础，能够挖掘更多的企业应用场景，推动更多业务实践落地.
            </div>
          </div>
          <a href="#joinUsDiv">
            <Button type="primary" ghost block danger className="btn">
              加入我们
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

const AdvantageDiv = () => {
  const { ueSaasName } = useSelector(
    (state: StateType) => state.domainInfo,
  ) as any;

  return (
    <div
      className="AdvantageDiv p-10"
      style={{
        background: `url(${fileHttpUrl(
          'fileServer/2022/11/04/f4b54eec24054333bb8bb5dcdbfcb4fd.png',
        )}) no-repeat`,
      }}
    >
      <div className=" text-center mb-6 text-3xl font-medium">
        {ueSaasName}的优势
      </div>
      <div className="flex h-full">
        <div className="left">
          <div className="circle-1"></div>
          <div className="circle-2"></div>
          <div className="circle-3"></div>
          <div className="circle-4">
            <img
              src={fileHttpUrl(
                'fileServer/2022/11/04/ff560be4dec440eca1a906945365d5e2.png',
              )}
              alt="云极"
            />
          </div>
        </div>
        <div className="right flex justify-evenly flex-col pb-6">
          <div className="items" key="1">
            <div className="title">高标准的激励政策</div>
            <div className="text">
              针对伙伴资源及业务类型不同，制定更灵活的商务政策，让伙伴收益最大化
            </div>
          </div>
          <div className="items" key="2">
            <div className="title">专业的赋能培训</div>
            <div className="text">
              提供专业的产品及业务培训，全面提升伙伴的业务能力和竞争力
            </div>
          </div>
          <div className="items" key="3">
            <div className="title">业内领先的产品技术</div>
            <div className="text">
              业内领先的自研产品技术，为伙伴带来更强的技术竞争力
            </div>
          </div>
          <div className="items" key="4">
            <div className="title">售后支持</div>
            <div className="text">
              1对1专属客服，支持在线提交问题、电话沟通协助等，提供完善的帮助文档。工程师在线值守，使用期限内免费修复Bug。功能持续更新优化，免费提供运营培训服务。
              客服在线时间：周一到周五9:00-18:00
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const cooperationTypeOptions = [
  {
    label: '云部署统一域名',
    value: 1,
  },
  {
    label: '云部署独立域名',
    value: 2,
  },
  {
    label: '私有化部署',
    value: 9,
  },
];

// 申请成为代理商
interface ProFormCommonProps extends ProFormProps {
  onSuccess?: () => void;
}
const JoinUsDiv = ({ ...rest }: ProFormCommonProps) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<ProFormInstance>();
  const onFinish = async (values: any) => {
    setLoading(true);
    await homeAddUs(values);
    formRef.current?.resetFields();
    message.success('申请已提交，我们会及时联系您');
    setLoading(false);
  };

  return (
    <div
      className="joinUsForm px-20 py-14 j-cover-red-color"
      id="joinUsDiv"
      style={{
        background: `url(${fileHttpUrl(
          'fileServer/2022/11/04/0c0abd68f92d419e8f5f037f9873796c.png',
        )}) no-repeat`,
        backgroundSize: 'cover',
      }}
    >
      <div className="bg-white rounded-2xl py-10 p-4">
        <div className=" text-center text-3xl  mb-10 font-medium">加入我们</div>

        <ProForm<any>
          layout="horizontal"
          labelAlign="right"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 8 }}
          formRef={formRef}
          submitter={{
            render: (props, doms) => [
              <Button
                key="submit"
                type="primary"
                loading={loading}
                style={{ marginLeft: '42%' }}
                onClick={() => props.form?.submit?.()}
              >
                提交
              </Button>,
            ],
          }}
          onFinish={onFinish}
          {...rest}
        >
          <ProFormRadio.Group
            label="合作方式"
            name="daDeployMode"
            initialValue={1}
            options={cooperationTypeOptions}
          />
          <ProFormText
            name="daName"
            width="md"
            label="姓名"
            rules={[{ required: true, message: '请输入姓名' }]}
            placeholder="请输入"
          />
          <ProFormText
            name="daPhone"
            width="md"
            label="电话"
            rules={[{ required: true, message: '请输入电话' }]}
            placeholder="请输入"
          />
          <ProFormText
            name="daEmail"
            width="md"
            label="邮箱"
            rules={[
              { required: false, message: '请输入邮箱' },
              { pattern: emailReg, message: '请填写正确的邮箱' },
            ]}
            placeholder="请输入"
          />
          <ProFormText
            name="daEnterprise"
            width="md"
            label="公司名称"
            rules={[{ required: false, message: '请输入公司名称' }]}
            placeholder="请输入"
          />
        </ProForm>
      </div>
    </div>
  );
};

export default () => {
  return (
    <div className="j-home-cooperation jc-antd-reset">
      {/* 上部分的组件 */}
      <TopDiv />
      {/* 申请步骤组件 */}
      <StepDiv />
      {/* 合作类型 */}
      <CooperationTypeDiv />
      {/* 云极优势组件 */}
      <AdvantageDiv />
      {/* 加入我们组件 */}
      <JoinUsDiv />
    </div>
  );
};
