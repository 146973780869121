import { ProColumns } from "@ant-design/pro-table"
import { DateRangeWithDisabled } from "@components/Common"
import moment from "moment"
import React from "react"

export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    const initTime = [moment().add(0, 'days'), moment()]
    return [
        {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone',
            fieldProps: {
                placeholder: '请输入上行手机号'
            }
        },
        {
            title: '短信内容',
            dataIndex: 'smsContent',
            key: 'smsContent',
            fieldProps: {
                placeholder: '请输入短信内容'
            }
        },
        {
            title: '上行时间',
            dataIndex: 'upTime',
            key: 'upTime',
            valueType: 'dateRange',
            fieldProps: { },
            formItemProps: {
                // initialValue: initTime,  
            },
            search: {
                transform: (value) => {
                    return {
                        startTime: value[0] ? value[0] + ' 00:00:00' : undefined,
                        endTime: value[1] ? value[1] + ' 23:59:59' : undefined
                    }
                },
            },
            renderFormItem: (props) => <DateRangeWithDisabled />,
            render: (text: any, record: any) => record.upTime
        },
        // {
        //     title: '操作',
        //     dataIndex: 'operate',
        //     key: 'operate',
        //     valueType: 'option',
        //     render: (text: any, record: any) => [
        //         <a key="详情" style={{marginRight: 8}} onClick={() => operate('详情', record)}>详情</a>,
        //         <a key="删除" onClick={() => operate('删除', record)}>删除</a>,
        //     ]
        // }
    ]
}
