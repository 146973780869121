import { Button, Tabs } from "antd"
import React, { useEffect, useRef, useState } from "react"
import ProTable, { ActionType } from "@ant-design/pro-table";
import { productApi } from "@utils/index";
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home";
import { columnsFn } from "./data";
import { BASEURL, smsGlobalSendDetailPageList } from "@api/index";
import { ExportButton } from "@components/Common";
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import moment from "moment";

// 接口请求的 api 层
const getApi = productApi(smsGlobalSendDetailPageList)  // 查询的接口封装
const addUpdateApi = productApi(homeTableAddUpdate) // 列表数据的新增/编辑 接口
const delApi = productApi(homeTableDel) // 表单数据的删除 接口

export default () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [modalProps, setModalProps] = useState({visible: false, title: '新增', params: {}})
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    const formRef = useRef<ProFormInstance>()
    // operate 函数为增删改查需要处理的业务
    const operate = (type: any, record: any) => {
        const { id } = record || {}
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params}, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const columns = columnsFn(operate) // 表格列配置
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const getQueryParams = () => ({
        // @ts-ignore
        ...formRef.current?.getFieldsFormatValue()
    })
    return (<ProTable<any>
        style={{flex: 1, overflow: 'scroll'}}
        scroll={{x: 1300}}
        columns={columns}
        params={{}} // 查询所需要的额外参数
        request={getListApi}
        options={{reload: false, density: false, setting: false}}
        toolbar={{
            actions: [
                <ExportButton key="export" type='primary' action={`${BASEURL}/portal/internationalMessage/exportInlSendDetails`} param={getQueryParams} btnText="导出" />
            ],
        }}
        // rowKey="tiId"
        pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
        actionRef={ref as any}
        formRef={formRef}
        search={{
            optionRender: (searchConfig, formProps, dom) => {
                return [ dom[1], <Button key="reset" onClick={() => {
                    const { onClick: onSearch } = (dom[1] as any)?.props || {}
                    formRef.current?.resetFields()
                    formRef.current?.setFieldsValue({tiId: '', sendTime: [moment().add(0, 'days'), moment()]})
                    // ref.current?.reloadAndRest()
                    onSearch && onSearch()
                }}>重置</Button> ]
            }
        }}
        // rowSelection={{
        //     preserveSelectedRowKeys: true,
        //     onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
        // }}
    />)
}
