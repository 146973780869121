import { fromData } from "@utils/index";
import request from "@utils/request";
import { stringify } from "qs";
import { BASEURL } from "../baseUrl";
import { headers } from "../login";

/****************资源监控******************/ 
export const cdnAnalysisResult  = (data?: object) => request({
    url:`${BASEURL}/web/cdn/analysis/getCdnAnalysisResult`,
    data
})

// 获取当前用户创建的加速域名
export const cdnDomainsOfCurrentUser  = (data?: object) => request({
    url:`${BASEURL}/web/cdn/analysis/getDomainsOfCurrentUser`,
    method: 'GET',
    data
})

// 获取cdn 全部供应商
export const cdnAllProviders  = (data?: object) => request({
    url:`${BASEURL}/web/cdn/analysis/getAllProviders`,
    method: 'GET',
    data
})

// 获取cdn 全部省份
export const cdnAllProvinces  = (data?: object) => request({
    url:`${BASEURL}/web/cdn/analysis/getAllProvinces`,
    method: 'GET',
    data
})

// 资源监控-获取统计折线图
export const cdnGraph  = (data?: object) => request({
    url:`${BASEURL}/web/cdn/analysis/getGraph`,
    data
})

// 资源监控-获取统计折线图
export const cdnStatusGraph  = (data?: object) => request({
    url:`${BASEURL}/web/cdn/analysis/getStatusGraph`,
    data
})

// CDN/刷新预热 查看URL数量
export const cdnGetUrlCounts  = (data?: object) => request({
    url:`${BASEURL}/web/cdn/getUrlCounts`,
    data
})

// CDN/刷新预热 缓存刷新提交
export const cdnRefresh = (data?: object) => request({
    url:`${BASEURL}/web/cdn/refresh`,
    data
})

// CDN/刷新预热 缓存预热提交
export const cdnPreHeat = (data?: object) => request({
    url:`${BASEURL}/web/cdn/preHeat`,
    data
})

// CDN/刷新预热 缓存预热提交
export const cdnGetRefreshPreHeatList = (data?: object) => request({
    url:`${BASEURL}/web/cdn/getRefreshPreHeatList`,
    data
})

// CDN 套餐包
export const cdnPkgList = (data?: object) => request({
    url:`${BASEURL}/web/cdn/showCdnQuota`,
    data
})

// 获取CDN 套餐包余量报警
export const cdnGetPkgAlert = (data?: object) => request({
    url:`${BASEURL}/web/cdn/showCdnQuotaSetting`,
    data
})

// CDN 套餐包余量报警
export const cdnSetPkgAlert = (data?: object) => request({
    url:`${BASEURL}/web/cdn/updateCdnQuotaSetting`,
    data
})

// 获取cdn 域名列表查询
export const cdnGetDomainList  = (data?: object) => request({
    url:`${BASEURL}/web/cdn/getDomainList`,
    data
})

// 判断用户是否有 全站加速的资格
export const cdnCurrentUserWhetherAllSpeed  = (data?: object) => request({
    url:`${BASEURL}/web/cdn/currentUserWhetherAllSpeed`,
    data
})

// 域名校验 校验cdn txt
export const cdnCheckDomainTxt = (data?: object) => request({
    url:`${BASEURL}/web/cdn/checkDomainTxt`,
    method: 'GET',
    data
})

// 域名新增请求
export const cdnAddDomain = (data?: object) => request({
    url:`${BASEURL}/web/cdn/newDomain`,
    data
})

// 查询用户所有的证书 表格
export const cdnGetCertConfigList = (data?: object) => request({
    url:`${BASEURL}/web/cdn/getCertConfigList`,
    data
})

// 配置证书提交接口
export const cdnSaveOrUpdateCertWithMultiSelect = (data?: object) => request({
    url:`${BASEURL}/web/cdn/saveOrUpdateCertWithMultiSelect`,
    data
})

// 获取用户已配置域名
export const cdnGetUserDomain = (data?: object) => request({
    url:`${BASEURL}/web/cdn/getUserDomain`,
    data
})

// 查询域名基础配置信息详情
export const cdnGetDomainDetail = (data?: object) => request({
    url:`${BASEURL}/web/cdn/getDomainDetail`,
    data
})

// 查询源站信息  列表详情
export const cdnPageDomainSourceByDomainId = (data?: object) => request({
    url:`${BASEURL}/web/cdn/pageDomainSourceByDomainId`,
    data
})


// 根据域名生成校验的值
export const cdnGenerateDomainTxt = (data?: object) => request({
    url:`${BASEURL}/web/cdn/generateDomainTxt`,
    method: 'GET',
    data
})

// 查询域名回源配置
export const cdnGetBackOriginConfig = (data?: object) => request({
    url:`${BASEURL}/web/cdn/getBackOriginConfig`,
    data
})

// 新增或修改域名源站信息
export const cdnSaveOrUpdateDomainOrigin = (data?: object) => request({
    url:`${BASEURL}/web/cdn/saveOrUpdateDomainOrigin`,
    data
})

// 修改回源HOST
export const cdnUpdateBackHost = (data?: object) => request({
    url:`${BASEURL}/web/cdn/updateBackHost`,
    data
})

// 回源请求头配置列表分页
export const cdnPageBackOriginHeaderByDomainId = (data?: object) => request({
    url:`${BASEURL}/web/cdn/pageBackOriginHeaderByDomainId`,
    data
})

// 回源请求头新增编辑
export const cdnSaveOrUpdateBackOriginRequestHeader = (data?: object) => request({
    url:`${BASEURL}/web/cdn/saveOrUpdateBackOriginRequestHeader`,
    data
})

// 开关切换复用接口
export const cdnSwitchStatus = (data?: object) => request({
    url:`${BASEURL}/web/cdn/switchStatus`,
    data
})

// 查询HTTPS配置
export const cdnGetHttpsConfig = (data?: object) => request({
    url:`${BASEURL}/web/cdn/getHttpsConfig`,
    data
})

// 新建或编辑证书(单选)
export const cdnSaveOrUpdateCert = (data?: object) => request({
    url:`${BASEURL}/web/cdn/saveOrUpdateCert`,
    data
})


// 查询已配置的证书列表
export const cdnGetUserCertList = (data?: object) => request({
    url:`${BASEURL}/web/cdn/getUserCertList`,
    method: 'GET',
    data
})

// tls版本配置修改
export const cdnUpdateTlsVersion = (data?: object) => request({
    url:`${BASEURL}/web/cdn/updateTlsVersion`,
    data
})

// 缓存配置表格查询
export const cdnDomainConfigurationRule = (data?: object) => request({
    url:`${BASEURL}/web/cdn/domainConfigurationRule`,
    data
})


// 缓存配置配置项查询
export const cdnDomainConfiguration = (data?: object) => request({
    url:`${BASEURL}/web/cdn/domainConfiguration`,
    data
})

// 缓存配置配置项修改提交
export const cdnUpDomainConfiguration = (data?: object) => request({
    url:`${BASEURL}/web/cdn/upDomainConfiguration`,
    data
})


// 回源配置删除
export const cdnDeleteBackOriginRequestHeader = (data?: object) => request({
    url:`${BASEURL}/web/cdn/deleteBackOriginRequestHeader`,
    data
})


// 缓存配置 新增规则
export const cdnSaveDomainConfigurationRule= (data?: object) => request({
    url:`${BASEURL}/web/cdn/saveDomainConfigurationRule`,
    data
})

// 查询访问控制规则
export const cdnGetAccessControlInfoByDomainId= (data?: object) => request({
    url:`${BASEURL}/web/cdn/getAccessControlInfoByDomainId`,
    data
})

// Referer防盗链配置提交
export const cdnConfigureReferer= (data?: object) => request({
    url:`${BASEURL}/web/cdn/configureReferer`,
    data
})

// IP黑白名单配置提交
export const cdnConfigureWhiteAndBlackList= (data?: object) => request({
    url:`${BASEURL}/web/cdn/configureWhiteAndBlackList`,
    data
})

// 缓存配置删除接口
export const cdnDelDomainConfigurationRule= (data?: object) => request({
    url:`${BASEURL}/web/cdn/delDomainConfigurationRule`,
    data
})

// cdn证书删除 删除接口
export const cdndeleteCert= (data?: object) => request({
    url:`${BASEURL}/web/cdn/deleteCert`,
    data
})

// cdn域名启用停用
export const cdnStartOrStopDomain= (data?: object) => request({
    url:`${BASEURL}/web/cdn/startOrStopDomain`,
    data
})

// cdn域名删除
export const cdnRemoveDomain= (data?: object) => request({
    url:`${BASEURL}/web/cdn/removeDomain`,
    data
})

// cdn证书添加
export const cdnSaveCert= (data?: object) => request({
    url:`${BASEURL}/web/cdn/saveCert`,
    data
})
