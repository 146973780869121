import { fileHttpUrl } from "@api/baseUrl"
import { PROTYPE_SMS, PROTYPE_GLOBALSMS, PROTYPE_CDN, PROTYPE_ID } from "@utils/enum"
import React, { useEffect, useState } from "react"
import './index.less'
// import logo from '@assets/images/logo.png'
import { homeGetHotProduct, homeGetProductPurchaseo, homeGetFriendlyLinks } from "@api/home"
import { useLocation } from "react-router-dom"
import { handleAreaTree, parseSearch, sliceText } from "@utils/util"
import { handleDvmCommonListResult, handleDvmCommonPageListResult, handleDvmCommonResult } from "@utils/request"
import { StateType } from "@store/index"
import { useSelector } from "react-redux"
import { Col, Collapse, Row } from "antd"
const { Panel } = Collapse;
export const getInitialProps = async () => {
   const menuList = [
      {
         name: '热门产品',
         list: []
      },
      {
         name: '快速入口',
         list: [
            {
               name: '首页',
               url: '/',
            },
            {
               name: '总览',
               url: '/overview',
            },
            {
               name: '账号中心',
               url: '/account/safeSetting',
            },
         ]
      },

      {
         name: '了解云极',
         list: [
            {
               name: '云极简介',
               url: '/home/AboutUs',
            },
            {
               name: '加入我们',
               url: '/home/cooperation',
            },
            {
               name: '行业资讯',
               url: '/home/industryInformation',
            },
         ]
      },
   ]

   const api = handleDvmCommonPageListResult(homeGetHotProduct)
   const [res, res2] = await Promise.all([api({
      piIsRecommend: 1,
      piPublishState: 1,
      pageNum: 1,
      pageSize: 99,
    }, {}, {}), homeGetFriendlyLinks()]) as any
    const { data } = res || {} as any
    const { result } = res2 || {} as any

    menuList[0].list = data.map((item: any) => {return {name: item.piName, url:`/home/product?piId=${item.piId}` }})
   //  const hotProList = (data || []).map((item: any) => {return {name: item.piName, url:`/home/product?piId=${item.piId}` }})
    const links  = handleDvmCommonListResult(result)
    return {menuList, links }

}


const Footer = ({initialProps}: any)=> {
   const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
   const { search } = useLocation()
   const { piId } = parseSearch(search)
   const [menData, setMenData] = useState<any[]>(initialProps?.menuList||[])
   const [friendlyLinks, setFriendlyLinks] = useState(initialProps?.links||[])
   const { ueSaasFullName, ueSaasIcpLicense, ueSaasPhone, ueSaasLogo, ueSaasPublicQrcode, ueSaasEmail } = useSelector((state: StateType) => state.domainInfo) as any;

   const getPreDataApi = async() => {
      const { menuList, links} = await getInitialProps()
      setMenData(menuList)
      setFriendlyLinks(links)
  }

  useEffect(() => {
    initialProps ? null : getPreDataApi()
  }, [initialProps])


    // 处理友情链接的格式   竖线均匀隔开
    const url_list_div = (list:any)=> {
      const [elementList, setElementList] = useState([]) as any
      let elemList = [] as any
      list.forEach((item:any, index:number) => {
         // eslint-disable-next-line react/jsx-no-target-blank
         elemList.push(<span key={index} className="a_color_black m-4"><a href={item.plUrl} target="_blank" >{item.plText}</a></span>)
         elemList.push(<span key={index+ '-2'}  className="text-gray-400">|</span>)
      });
      elemList.splice(-1, 1)
      return elemList
    }
   return (<div className="homeFooterMain">
   {/* <div className="left">

   </div> */}
   {/* pc端的组件 */}
   <div className="top pc-component">
     {menData.map((item: any, index: number) => {
      return <div  key={index}>
            <div className="title">{ item.name }</div>
            <div  className="menuItem">
               {item.list.map((item2: any, index2: any) => {
                  return <div key={index2} className="a_color_black"><a href={item2.url}>{ item2.name }</a></div>
               })}
            </div>

          </div>
     }) }
     <div>
        <p className="title">公众号</p>
        <img className="weChartCode" src={fileHttpUrl(ueSaasPublicQrcode)} alt={ueSaasName} />
        <div className="number">产品及服务咨询：{ueSaasPhone}</div>
        <div className="number">售前咨询热线：{ ueSaasEmail }</div>
     </div>
   </div>
   {/* 移动端的组件 */}
   <div className="jm-component ">
      <Collapse accordion>
      {menData.map((item: any, index: number) => {
         return  <Panel header={item.name} key={index}>
         <Row  className="jm_menuItem">
               {item.list.map((item2: any, index2: any) => {
                  return <Col span={11} key={index2} className="smallProduct"><a href={item2.url}>{ sliceText(item2.name, 6) }</a></Col>
               })}
            </Row>
      </Panel>
      })}
      <Panel header={'公众号'} key={99}>
        <img className="weChartCode" src={fileHttpUrl(ueSaasPublicQrcode)} alt={ueSaasName} />
        <div className="number">产品及服务咨询：{ueSaasPhone}</div>
        <div className="number">售前咨询热线：{ ueSaasEmail }</div>
      </Panel>
   </Collapse>
   </div>
   
   <div className="bottom mt-4 flex justify-center border-top">
         <div>
            <span>友情链接：</span>
            {
               url_list_div(friendlyLinks)
            }
         </div>

   </div>
   <div className="flex justify-center mt-4">
      <img className="logo-img mr-4" src={fileHttpUrl(ueSaasLogo)} alt={ueSaasName} />
         <p>Copyright © {ueSaasFullName} All Rights Reserved <a style={{color: 'inherit'}} href="https://beian.miit.gov.cn">{ueSaasIcpLicense}</a></p>
      </div>
  </div>)
}

Footer.getInitialProps = getInitialProps

export default Footer
