import React, { useRef, useState } from "react"
import { useTableHooks } from "@hooks/index"
import { columnsFn, editTableColumnFn } from './data'
import { Button, Col, InputNumber, Modal, Row, Switch, Table } from "antd"
import { phoneReg, productApi, setHref } from "@utils/index"
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home"
import { SearchBox } from "@components/index"

import { ActionType, EditableProTable, ProTable } from "@ant-design/pro-table"
import { ProFormProps, ProForm, ProFormText, ModalForm, ProFormRadio, ProFormUploadDragger, ProFormSelect, ProFormTextArea } from '@ant-design/pro-form' 
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { smsAlertPageList, smsGetAlertInfo, smsSetAlertInfo } from "@api/index"

// 列表操作的 类型校验
type Action = '新增' | '修改' | '删除' | '报警设置'

// 接口请求的 api 层
const getApi = productApi(smsAlertPageList)  // 查询的接口封装
const addUpdateApi = productApi(smsSetAlertInfo) // 列表数据的新增/编辑 接口
const delApi = productApi(homeTableDel) // 表单数据的删除 接口

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 16 },
};

// 新增｜编辑的 form 表单弹窗
// 弹窗参数的类型扩展
interface AlermSettingFormModalProps extends ModalFormProps{
    params?: any;
    onSuccess?: () => void;
}
const AlermSettingFormModal = ({onSuccess, params, ...rest}: AlermSettingFormModalProps) => {
    const formRef = useRef<ProFormInstance>()
    const [editableKeys, setEditableKeys] = useState<any>([])
    const request = async (params: any) => {
        const { data } = await smsGetAlertInfo(params) as any
        const { phone, items } = data || {}
        const newItems = (items||[]).map(({id, ...rest}: any, i: number) => ({...rest, id: id ? id : `zqs-${i}`}))
        setEditableKeys((newItems||[]).map((v: any) => v.id))
        return { phone, items: newItems }
    }
    const onFinish = async (values: any) => {
        const { items: arr, ...restParams } = values || {}
        const items = arr.map(({ualIsEnabled, id, ...rest}: any) => ({...rest, id: (id+'').startsWith('zqs-') ? null : id, ualIsEnabled: ualIsEnabled ? 1 : 0}))
        addUpdateApi({...restParams, items, ...params}, true, onSuccess)
    }
    return (<ModalForm<any>
        layout="horizontal" 
        {...layout} 
        onFinish={onFinish} 
        request={request}
        formRef={formRef}
        {...rest}>
       {/* <ProForm.Item label="短信通知">
         <span style={{display: 'inline-block', marginTop: 5,}}>设置余量报警阀值:</span><ProForm.Item name="a" style={{display: 'inline-block'}}><InputNumber /></ProForm.Item>  
         <span style={{display: 'inline-block', marginTop: 5, marginLeft: 50}}>状态设置:</span><ProForm.Item name="b" style={{display: 'inline-block'}}><Switch /></ProForm.Item> 
       </ProForm.Item>
       <ProForm.Item label="无变量">
         <span style={{display: 'inline-block', marginTop: 5,}}>设置余量报警阀值:</span> <ProForm.Item name="c" style={{display: 'inline-block'}}><InputNumber /></ProForm.Item>  
         <span style={{display: 'inline-block', marginTop: 5, marginLeft: 50}}>状态设置:</span><ProForm.Item name="d" style={{display: 'inline-block'}}><Switch /></ProForm.Item> 
       </ProForm.Item> */}
       <ProForm.Item name="items" label="报警设置">
            <EditableProTable<any>
                className="j-proform-editable"
                rowKey="id"
                columns={editTableColumnFn()}
                recordCreatorProps={false}
                controlled
                editable={{
                    // form,
                    type: 'multiple',
                    deleteText: null,
                    editableKeys,
                    actionRender: (row, config, defaultDoms) => {
                        return [defaultDoms.delete];
                    },
                    onValuesChange: (value: any, allValues: any) => {
                        formRef.current?.setFieldValue('items', allValues)
                    }
                }}
                // onChange={()}
            />
       </ProForm.Item>
        <ProFormText 
            width="md" 
            name="phone" 
            label="报警手机号" 
            fieldProps={{
                placeholder: '请输入报警手机号', 
            }} 
            rules={[{ required: true, message: '请输入11位手机号码'}, { pattern: phoneReg, message: '非法手机号' }]}
        />
    </ModalForm>)
}


/**
 * antd 升级版企业级组件(常用)
*/
export default () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [modalProps, setModalProps] = useState({visible: false, title: '新增', params: {}})
    const [restTotal, setRestTotal] = useState<number>(0)
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    // operate 函数为增删改查需要处理的业务
    const operate = (type: Action, record: any) => {
        const { id } = record || {}
        if (type === '报警设置') {
            setModalProps({
                ...modalProps,
                title: '新增',
                params: {},
                visible: true,
            })
            console.log('需要处理的新增业务')
        } else if (type === '删除') {
            const ids = record ? [id] : selectedRowKeys
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                centered: true,
                cancelText: '取消',
                onOk: async() => {
                    await delApi({ids}, true, () => {
                        console.log('数据删除成功, 可以执行回调了')
                        reFresh()
                    })
                },
            });
        }
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params}, false)
        // const { values, total } = data || {}
        const restTotal = (data||[]).reduce((pre: any, next: any) => (pre + next.left), 0)
        setRestTotal(restTotal)
        return {
            data: data || [],
            success: true,
            total: data.length
        }
    }

    const columns = columnsFn(operate) // 表格列配置

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        console.log('处理后的成功回调触发了')
        setModalProps({
            ...modalProps,
            visible: false
        })
        reFresh()
    }
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const calcDelDisabled = () => selectedRowKeys.length
    return (<><ProTable<any>
            style={{flex: 1, overflow: 'scroll'}}
            scroll={{x: 1300}}
            columns={columns}
            params={{}} // 查询所需要的额外参数
            request={getListApi}
            options={{reload: false, density: false, setting: false}}
            headerTitle={<span key="desc"><span style={{fontSize: 20}}>短信服务</span> (余量：{restTotal}条)</span>}
            toolbar={{
                actions: [
                    <Button key="btn0" type='primary' onClick={() => setHref('/cost/pkg')}>设置余量预警</Button>,
                    // <Button key="btn0" type='primary' onClick={() => operate('报警设置', null)}>报警设置</Button>,
                    // <Button key="btn1" type='primary' disabled={!calcDelDisabled()} onClick={() => operate('删除', null)}>删除</Button>,
                ],
            }}
            rowKey="ppgName"
            pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
            actionRef={ref as any}
            search={false}
            // rowSelection={{
            //     preserveSelectedRowKeys: true,
            //     onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
            // }}
        />
        <AlermSettingFormModal
            modalProps={{
                maskClosable: false, 
                onCancel: () => setModalProps({...modalProps, visible: false})
            }}
            onSuccess={onSuccess} 
            {...modalProps} 
        />
    </>)
}
