import React, { useEffect, useState } from "react"
import { Card } from "antd"
import { BASEURL } from "@api/index"
import { useCommonApiHooks } from "@hooks/index"
import { allDateRanges, fromData, toFixedNoRound } from "@utils/index"
import { Pie } from '@ant-design/plots'
import { CompactDate, handleCompactDateSubmitValue, handleCompactDateValue } from "@components/Common/Compact"
import { ExportButton, NotFond, NullData, SearchEmpty } from "@components/Common"
import { dateOptions } from "../SendAnalysis/data"

const data = [
    {
      type: '分类一',
      value: 27,
    },
    {
      type: '分类二',
      value: 25,
    },
    {
      type: '分类三',
      value: 18,
    },
    {
      type: '分类四',
      value: 15,
    },
    {
      type: '分类五',
      value: 10,
    },
    {
      type: '其他',
      value: 5,
    },
]
const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }: any) => `${toFixedNoRound(percent * 100, 2)}%`,
        style: {
          fontSize: 14,
          textAlign: 'center',
        },
    },
    interactions: [
        {
           type: 'element-active',
        },
    ],
    tooltip: {
      customContent: (title: any, items: any) => {
        const style = {marginLeft: 15, paddingBottom: 10}
        const { sendAmount, limitCount, phoneInvalidateCount, submitFailCount, unknownErrorCount } = items[0]?.data || {}
        return (
          <>
            <h5 style={{ paddingTop: 6 }}>{title}</h5>
            <ul style={{ paddingBottom: 5 }}>
              {items?.map((item: any, index: number) => {
                const { name, value, color, data } = item;
                console.log('iiitem', item)
                return (
                  <li
                    key={item.year}
                    className="g2-tooltip-list-item"
                    data-index={index}
                    style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
                  >
                    <span className="g2-tooltip-marker" style={{ backgroundColor: color }}></span>
                    <span
                      style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}
                    >
                      <span style={{ marginRight: 16 }}>{name}:</span>
                      <span className="g2-tooltip-list-item-value">{value}%</span>
                    </span>
                  </li>
                );
              })}
            </ul>
          </>
        )
      }
    },
}

const transformPieChatData = (data = []) => {
  const arr = (data||[]).filter((v: any) => v.rate > 0).map((v: any) => {
    const { failReason: type, rate: value } = v
    return [
      {
        type,
        value
      }
    ]
  })
  return [...arr.flat()]
}

const defaultValue = {type: 'date', value: allDateRanges['近30天']}
const Extra = ({value, onChange, getParams}: any) => {
  return (<div style={{display: 'flex'}}>
      <CompactDate options={dateOptions} value={value} onChange={onChange} allowClear={false} />
      <ExportButton key="export" type='primary' action={`${BASEURL}/web/dataStat/failAnalysisExport`} param={getParams} btnText="导出" />
  </div>)
}

export default () => {
  const [value, setValue] = useState<any>(defaultValue)
  const [data, , getApi] = useCommonApiHooks({url: `${BASEURL}/web/dataStat/failAnalysis`, }, {}, false)
 const responses = (data.responses || []).filter((item:any) => item.rate != 0)
  const getParams = () => {
    return handleCompactDateSubmitValue(value)
  }
  useEffect(() => {
    const params = getParams()
    getApi(params)
  }, [value])
  const Title = () => <>数据图表<span style={{color: '#686868', fontSize: 14, fontWeight: 'normal'}}>(最新数据更新时间：{data?.lastUpdateTime})</span></>
  return (<Card title={<Title />} extra={<Extra value={value} onChange={setValue} getParams={getParams} />}>
      {responses.length ? <Pie {...config} data={transformPieChatData(data?.responses)} />: <NullData /> }
  </Card>)
}
  