import { ADD_TASK, DEL_TASK, SET_ALL_COUNTRY, SET_AREA_TREE, SET_DOC_TREE, SET_HISTORY_LIST, SET_PREVIEW, SET_PRODUCT_DATA, SET_SHOPPINGCAR_NUMBER, SET_TASK } from "@store/contants";
import { omit } from "lodash";

export const historyList = (state = {}, action: { type?: any; historyList?: any[] }) => {
    const { historyList } = action
    switch(action.type) {
        case SET_HISTORY_LIST:
            return historyList
        default: 
            return state
    }
}

export const areaTree = (state = [], action: { type?: any; areaTree?: {} }) => {
    const { areaTree } = action
    switch(action.type) {
        case SET_AREA_TREE:
            return areaTree
        default: 
            return state
    }
}

export const allCountry = (state = {}, action: { type?: any; allCountry?: {} }) => {
    const { allCountry } = action
    switch(action.type) {
        case SET_ALL_COUNTRY:
            return allCountry
        default: 
            return state
    }
}

export const productData = (state = {}, action: { type?: any; productData?: {} }) => {
    const { productData } = action
    switch(action.type) {
        case SET_PRODUCT_DATA:
            return productData
        default: 
            return state
    }
}

export const docTreeData = (state = {}, action: { type?: any; docTreeData?: {} }) => {
    const { docTreeData } = action
    switch(action.type) {
        case SET_DOC_TREE:
            return docTreeData
        default: 
            return state
    }
}

export const ShoppingCarNumber = (state = {}, action: { type?: any; ShoppingCarNumber?: {} }) => {
    const { ShoppingCarNumber } = action
    switch(action.type) {
        case SET_SHOPPINGCAR_NUMBER:
            return ShoppingCarNumber
        default: 
            return state
    }
}

export const taskObj = (state = {}, action: {type?: any; taskObj?: Object | string | string[]}) => {
    const { taskObj } = action
    switch(action.type) {
        case SET_TASK:
            return taskObj
        case ADD_TASK:
            return {
                ...state,
                ...taskObj as Object
            }
        case DEL_TASK:
            const taskIds = typeof taskObj === 'string' ? [taskObj] : taskObj
            return omit(state, taskIds)
        default:
            return state
    }
}

export const previewObj = (state = {}, action: { type?: any; previewObj?: {} }) => {
    const { previewObj } = action
    switch(action.type) {
        case SET_PREVIEW:
            return previewObj
        default: 
            return state
    }
}

