import { ActionType } from "@ant-design/pro-table"
import { Table, Tooltip } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { TLSTable, tooltipData } from "../../data"
import { DescriptionsPro, SwitchAndMsg, TitleBar } from "./cpnts"
import ProForm, {
  ModalForm,
  ProFormSelect,
  ProFormTextArea,
  ProFormSwitch,
  ProFormText,
  ProFormCheckbox,
} from '@ant-design/pro-form';
import { ProFormDependency } from '@ant-design/pro-form';
import FormItem from "antd/es/form/FormItem";
import {
EditOutlined,
} from '@ant-design/icons';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import AlertTip from "@components/Common/AlertTip"
import {  cdnConfigureReferer, cdnConfigureWhiteAndBlackList, cdnGetAccessControlInfoByDomainId, smsTempPageList } from "@api/index"
import { handleDvmCommonListResult } from "@utils/request"
import { useLocation } from "react-router-dom"
import { filterObj, parseSearch, productApi } from "@utils/util"

const getDataApi = productApi(cdnGetAccessControlInfoByDomainId)  // 查询 访问控制配置信息
const RefererApi = productApi(cdnConfigureReferer)  // Referer防盗链 配置 提交
const ipApi = productApi(cdnConfigureWhiteAndBlackList)  // IP黑白名单 配置 提交




const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

//Referer配置
const RefererConfigModal = ({refereData, params, onSuccess, ...rest }: any) => {
  const formRef = useRef<ProFormInstance>();
  const [sourceName, setSourceName] = useState('')
  const [sourceTestValue, setSourceTestValue] = useState('')
  const [testResult, setTestResult] = useState(false)

  const onFinish = async (values: any) => {
      const data = { 
          ...values,
          ...params,
          opened: values.opened * 1
      }
      const { success } = await RefererApi(
          filterObj(data),
          true,
          () => {
              clearData()
              onSuccess ? onSuccess() : null
          }
      )
      return success
  }

 
    const clearData = () => {
      formRef.current?.resetFields()
    }
  return (
    <ModalForm<any>
      layout="horizontal"
      width={600}
      formRef={formRef}
      {...layout}
      onFinish={onFinish}
      params={refereData}
      request= {
        ()=> {
          const { refererSetStatus, refererRule, refererType,refererIncludeEmpty } = refereData || {}
         return {refererType: refererType || 1,refererIncludeEmpty, refererList: refererRule, opened: refererSetStatus}
        }
      }
      preserve={false}
      modalProps={{
        onCancel: clearData,
      }}
      {...rest}
    >
      <ProFormSwitch
        width="md"
        name="opened"
        label="状态"
        initialValue={false}
        rules={[{required: true, message: '请选择状态'}]}
        fieldProps={{}}
      />

      {/* Referer类型 */}
      <ProFormSelect
        width="md"
        name="refererType"
        label="Referer类型"
        initialValue={1}
        fieldProps={{
          allowClear: false,
          placeholder: "请选择Referer类型",
          defaultValue: 1,
        }}
        options={[
          {
            value: 2,
            label: "白名单",
          },
          {
            value: 1,
            label: "黑名单",
          },
        ]}
        rules={[{ required: true, message: "请选择Referer类型" }]}
      />
      {/* 空Referer访问  */}
      <ProFormSelect
        width="md"
        name="refererIncludeEmpty"
        label="空Referer访问 "
        initialValue={'true'}
        fieldProps={{
          allowClear: false,
          placeholder: "请选择空Referer访问规则",
          defaultValue: 1,
        }}
        options={[
          {
            value: 1,
            label: "允许空Referer字段访问CDN资源",
          },
          {
            value: 0,
            label: "不允许空Referer字段访问CDN资源",
          },
        ]}
        rules={[{ required: true, message: "请选择空Referer访问规则" }]}
      />
    
      <ProFormTextArea
          rules={[{ required: true, message: '请输入规则' }]}
          placeholder='请输入域名或IP地址，以";"进行分割，域名、IP地址可混合输入，支持添加泛域名和带端口的域名。输入的域名、IP地址总数不能超过400个，端口最大值为65535。例如：www.example.com:443;*.test.com;192.168.0.0'
          name="refererList"
          label="规则"
          fieldProps={{
              rows: 4,
              allowClear: true,
          }}
      />
    </ModalForm>
  );
}



//ip黑名单配置
const IPConfigModal = ({ipData, params, onSuccess, ...rest }: any) => {
  const formRef = useRef<ProFormInstance>();
  const [sourceName, setSourceName] = useState('')
  const [sourceTestValue, setSourceTestValue] = useState('')
  const [testResult, setTestResult] = useState(false)

  const onFinish = async (values: any) => {
      const data = { 
          ...values,
          ...params,
          ipList: values.ipList.split(','),
          opened: values.opened * 1
      }
      const { success } = await ipApi(
          filterObj(data),
          true,
          () => {
              clearData()
              onSuccess ? onSuccess() : null
          }
      )
      return true
  }

 
    const clearData = () => {
      formRef.current?.resetFields()
    }
  return (
    <ModalForm<any>
      layout="horizontal"
      width={600}
      formRef={formRef}
      {...layout}
      params={ipData}
      request= {
        ()=> {
          console.log(222, ipData)
          const { ipRule, ipSetStatus, ipType } =  ipData || {}
         return {opened: ipSetStatus,listType: ipType, ipList: ipRule.toString() }
        }
      }
      onFinish={onFinish}
      preserve={false}
      modalProps={{
        onCancel: clearData,
      }}
      {...rest}
    >
      <ProFormSwitch
        width="md"
        name="opened"
        initialValue={false}
        rules={[{required: true, message: '请选择状态'}]}
        label="状态"
        fieldProps={{}}
      />

      {/* Referer类型 */}
      <ProFormSelect
        width="md"
        name="listType"
        label="Referer类型"
        initialValue={1}
        fieldProps={{
          allowClear: false,
          placeholder: "请选择Referer类型",
          defaultValue: 1,
        }}
        options={[
          {
            value: 2,
            label: "白名单",
          },
          {
            value: 1,
            label: "黑名单",
          },
        ]}
        rules={[{ required: true, message: "请选择Referer类型" }]}
        extra={ <div>
          <div>●  支持配置IP地址和IP&掩码格式的网段，最多支持配置150个，一行一个。</div>
          <div>●  网段表示形式仅支持IP/8，IP/16，IP/24，IP/32四种。</div>
          <div>●  网段“IP/掩码”中的IP必须是该网段IP区间首个主机IP地址。</div>
          <div>●  多个完全重复的IP/IP段将合并为一个。</div>
          <div>●  不支持带通配符的地址，如192.168.0.*。</div>
          <div>●  支持IPv6，IPv6不支持网段形式。</div>
        </div>}
      />
    
      <ProFormTextArea
          rules={[{ required: true, message: '请输入规则' }]}
          placeholder='请输入域名或IP地址，以";"进行分割，域名、IP地址可混合输入，支持添加泛域名和带端口的域名。输入的域名、IP地址总数不能超过400个，端口最大值为65535。例如：www.example.com:443;*.test.com;192.168.0.0'
          name="ipList"
          label="规则"
          fieldProps={{
              rows: 4,
              allowClear: true,
          }}
      />
    </ModalForm>
  );
}


export default () => {
    const [refereData, setRefereData] = useState([]) as any
    const [ipData, setIpData] = useState([]) as any
  
  const { pathname, search } = useLocation();
    const { domainId } = parseSearch(search) 
    
    const operation = (type: string, data?: any) => {
        if (type === 'Referer防盗链') {

        } else if (type === 'IP黑白名单') {

        }
    }

    const [basicInfo_Referer, setBasicInfo_Referer] = useState([]) as any
    const [basicInfo_IP, setBasicInfo_IP] = useState([]) as any
    // const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    // const formRef = useRef<ProFormInstance>()
    
    const getData = async () => {
      const { data } = await getDataApi({domainId}, false) 
      const { referer, list } = data || {}
      setBasicInfo_Referer(referer)
      setBasicInfo_IP(list)
      const {refererSetStatus, refererType, refererRule, refererIncludeEmpty} = referer || {}
      const { ipSetStatus, ipType = undefined, ipRule } = list || {}
     
      if (refererSetStatus) {
        setRefereData( [{
          label: '状态',
          text: '已配置'
        },
        {
          label: 'Referer类型',
          text: ['不设置Referer过滤', '黑名单', '白名单'][refererType]
        },
        {
          label: '规则',
          text: refererRule
        }])
      } else {
        setRefereData([{
          label: '防盗链',
          text: '未配置'
        }])
      }
     
      if (ipSetStatus) {
        setIpData( [{
          label: '状态',
          text: '已配置'
        },
        {
          label: '名单类型',
          text: ipType === 1 ? '黑名单' : '白名单'
        },
        {
          label: 'ip列表',
          text: ipRule ? ipRule.join(';') : ''
        }])
      } else {
        setIpData(  [{
          label: 'IP黑白名单',
          text: '未配置'
        }])
      }
    }
    
    const onSuccess = () => {
      getData()  
    }


    useEffect(() => {
        getData()  
     }, [])


    return (<div>
        <TitleBar title='Referer防盗链'  tooltip={tooltipData.RefererFDL}  editNode={<RefererConfigModal refereData={basicInfo_Referer} onSuccess={onSuccess} params={{domainId}} key="btn0" title="Referer防盗链" trigger={<EditOutlined className="float-right"  type='primary' />}  />} />
        <DescriptionsPro list={refereData} column={1} />
        <TitleBar title='IP黑白名单'  tooltip={tooltipData.IPHBMD}  editNode={<IPConfigModal   ipData={basicInfo_IP} onSuccess={onSuccess} params={{domainId}} key="btn0" title="IP黑白名单" trigger={<EditOutlined className="float-right"  type='primary' />}  />} />
        <DescriptionsPro list={ipData} column={1} />
    </div>)
}
