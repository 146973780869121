
import request from "@utils/request";
import { fromData } from "@utils/util";
import { stringify } from "qs";
import { BASEURL } from "./baseUrl";
import { headers } from "./login";

// 获取消息未读数
export const msgGetCount = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmGet`,
    headers,
    data: stringify({dvmControl: 'portalConsoleMessage:getUnreceiveCount'}),
})

// 获取消息列表
export const msgGetPageList = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmGet`,
    headers,
    data: stringify({dvmControl: 'portalConsoleMessage:query', ...data}),
})

// 删除我的消息
export const msgDel = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({dvmControl: 'portalConsoleMessage:del', ...data}),
})

// 多个消息标记已读
export const msgMarkRead = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({dvmControl: 'portalConsoleMessage:receive', ...data}),
})

// 全部消息标记已读
export const msgAllMarkRead = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({dvmControl: 'portalConsoleMessage:receiveAll'}),
})

// 删除我的消息
export const msgDetail = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({dvmControl: 'portalConsoleMessage:getDetail', ...data}),
})