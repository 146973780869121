import { ProColumns } from "@ant-design/pro-table"

export const mockData = []

export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
  return [
    {
      title: '域名',
      dataIndex: 'domain'
    },
    {
      title: '回源流量',
      dataIndex: 'backtrackFlux',
      sorter: (a, b) => a.backtrackFlux - b.backtrackFlux,
      render: (text: any, record: any)  => record.backtrackFlux + 'mb'
    },
    {
      title: '回源带宽',
      dataIndex: 'maxBacktrackBw',
      sorter: (a, b) => a.maxBacktrackBw - b.maxBacktrackBw,
      render: (text: any, record: any)  => record.maxBacktrackBw + 'kbit/s'
    },
    {
      title: '回源失败率',
      dataIndex: 'backtrackMissingPercent',
      sorter: (a, b) => a.backtrackMissingPercent - b.backtrackMissingPercent,
      render: (text: any, record: any) => (record.backtrackMissingPercent||0) + '%'
    }
  ]
}
  