import moment from "moment"

// 产品类型的枚举
export enum ProductType {
    SMS = 1, //国内短信
    GLOBALSMS = 2, //国际短信
    CDN = 3, //cdn
    SAFE = 4, //安全产品
    PART = 5, //要素类
    CARDADDRESS = 6, //银行卡归属地
    ID = 7, //实人认证
    PHONECHECK = 8, // 号码清洗
    PARTISP = 9, //运营商要素类
    PAAS = 10, // paas 云商城
    SAAS = 11, // saas 云商城
}

// 产品code的枚举 2022.12.12 新增
export enum ProductCodeType {
    SMS = '10001', //国内短信
    GLOBALSMS = '10002', //国际短信
    // 身份认证类
    IDTWOELEMENT = '10101', // 身份证二要素
    PHONETWOELEMENT = '10107', // 手机号二要素
    PHONETHREEELEMENT = '10102', // 手机号三要素
    BANKCARDTWOELEMENT = '10108', // 银行卡二要素
    BANKCARDTHREEELEMENT = '10103', // 银行卡三要素
    BANKCARDFOURELEMENT = '10104', // 银行卡四要素
    BANKCARDADDRESS = '10105', // 银行卡归属地
    ENTERPRISEFOURELEMENT = '10106', // 企业四要素
    TEXTCAPTCHA = '10109',  // 文字验证码
    // 实人认证类
    FACERECONGNITION = '10201', // 人脸识别api
    FACECOMPARE = '10202', // 人脸比对
    WITNESSCOMPARE = '10203', // 人证比对
    PORTRAITCOMPARE = '10303', // 人像比对   新增于2024.1.10
    STATICLIVECHECK = '10304', // 静态活体检测 新增于2024.01.22
    DYNAMICLIVECHECK = '10305', // 动态活体检测 新增于2024.01.22
    LIVECHECK = '10204', // 活体检测
    HACK = '10205', // 防hack
    // 文字识别类
    IDOCR = '10301', // 身份证OCR
    BANKCARDOCR = '10302', // 银行卡OCR
    // 号码检测类
    EMPTYCHECK = '10401', // 空号检测
    REALTIMECHECK = '10402', // 实时检测
    HARASSBLACKLIST = '10403', // 防骚扰黑名单
    WOOLSHIELDTAG = '10404', // 羊毛盾标签版
    ONLINEDURATION = '10405', // 号码在网时长
    TRANSFERNETWORK = '10406', // 携号转网
    PHONEAPCHECK = '10407', // 号码活跃度检测
    // 安全服务类
    CLOUDGU = '10501', // 云罟主机安全
    PERMEATETEST = '10502', // 渗透测试服务
    // cdn 类
    CDN = '10601', //cdn
    // 云部署类
    UNIFYDOMAIN = '10701',
    UNIQUEDOMAIN = '10702',
    // 本地化部署
    STANDARD = '10801', // 标准版
    ENTERPRISE = '10802', // 企业版
    PROFESSIONAL = '10803', // 专业版
}

// 认证类型的枚举
enum CertType {
    PERSON = 1, // 认证类型：个人
    COMPANY = 2, // 认证类型: 企业
    UPGRADE = 3, // 认证升级: 从个人认证升级到企业认证
    UNCERT = 0, // 认证状态: 未认证 0
    CERTING = 1, // 认证状态: 认证中 1
    SUCCESS = 2, // 认证状态: 认证成功 2
    FAIL = 9, // 认证状态: 认证失败 9
    PERSON_CERTING = 11, // 个人认证中
    PERSON_SUCCESS = 12, // 个人认证成功
    PERSON_FAIL = 19, // 个人认证失败
    COMPANY_CERTING = 21, // 企业认证中
    COMPANY_SUCCESS = 22, // 企业认证成功
    COMPANY_FAIL = 29, // 企业认证失败
    UPGRADE_CERTING = 31, // 升级认证中
    UPGRADE_SUCCESS = 32, // 升级认证成功
    UPGRADE_FAIL = 39, // 升级认证失败
}

// 国内短信-模版变量类型枚举
export enum SmsTempVarType {
    CAPTCHA = 1, // 验证码变量 1
    CUSTOM = 2, // 自定义变量 8
    NOVAR = 3, // 无变量 0
    ALLVAR = 4, // 全变量
}

// 运行商枚举
export enum ISP {
    TELECOM = 1, // 中国电信
    MOBILE = 2, // 中国移动
    UNICOM = 3, // 中国联通
    OTHER = 9,  // 其他
}

// 认证常量
export const CERT_PERSON: CertType = CertType.PERSON // 认证类型：个人
export const CERT_COMPANY: CertType = CertType.COMPANY // 认证类型: 企业
export const CERT_UNCERT: CertType = CertType.UNCERT // 认证状态: 未认证 0
export const CERT_CERTING: CertType = CertType.CERTING // 认证状态: 认证中 1
export const CERT_SUCCESS: CertType = CertType.SUCCESS // 认证状态: 认证成功 2
export const CERT_FAIL: CertType = CertType.FAIL // 认证状态: 认证失败 9
export const CERT_UPGRADE: CertType = CertType.UPGRADE // 认证升级: 从个人认证升级到企业认证
export const CERT_PERSON_CERTING: CertType = CertType.PERSON_CERTING // 个人认证中
export const CERT_PERSON_SUCCESS: CertType = CertType.PERSON_SUCCESS // 个人认证成功
export const CERT_PERSON_FAIL: CertType = CertType.PERSON_FAIL // 个人认证失败
export const CERT_COMPANY_CERTING: CertType = CertType.COMPANY_CERTING // 企业认证中
export const CERT_COMPANY_SUCCESS: CertType = CertType.COMPANY_SUCCESS // 企业认证成功
export const CERT_COMPANY_FAIL: CertType = CertType.COMPANY_FAIL // 企业认证失败
export const CERT_UPGRADE_CERTING: CertType = CertType.UPGRADE_CERTING // 升级认证中
export const CERT_UPGRADE_SUCCESS: CertType = CertType.UPGRADE_SUCCESS// 升级认证成功
export const CERT_UPGRADE_FAIL: CertType = CertType.UPGRADE_FAIL // 升级认证失败

// 产品类型常量
export const PROTYPE_SMS: ProductType = ProductType.SMS;   //国内短信
export const PROTYPE_GLOBALSMS: ProductType = ProductType.GLOBALSMS;  //国际短信
export const PROTYPE_CDN: ProductType = ProductType.CDN;   //cdn
export const PROTYPE_SAFE: ProductType = ProductType.SAFE;  //安全产品
export const PROTYPE_PART: ProductType = ProductType.PART;  //要素类
export const PROTYPE_PHONECHECK: ProductType = ProductType.PHONECHECK;  //号码检测
export const PROTYPE_ID: ProductType = ProductType.ID;  //实人认证
export const PROTYPE_CARDADDRESS: ProductType = ProductType.CARDADDRESS;  //银行卡归属地
export const PROTYPE_PARTISP: ProductType = ProductType.PARTISP;  //运营商要素类
export const PROTYPE_PAAS: ProductType = ProductType.PAAS;  //PaaS云商城
export const PROTYPE_SAAS: ProductType = ProductType.SAAS;  //saaS云商城

// 产品code常量
export const PROCODE_SMS: ProductCodeType = ProductCodeType.SMS;   //国内短信
export const PROCODE_GLOBALSMS: ProductCodeType = ProductCodeType.GLOBALSMS;  //国际短信
export const PROCODE_CDN: ProductCodeType = ProductCodeType.CDN;   //cdn
export const PROCODE_IDTWOELEMENT: ProductCodeType = ProductCodeType.IDTWOELEMENT // 身份证二要素
export const PROCODE_PHONETWOELEMENT: ProductCodeType = ProductCodeType.PHONETWOELEMENT // 手机号二要素
export const PROCODE_PHONETHREEELEMENT: ProductCodeType = ProductCodeType.PHONETHREEELEMENT // 手机号三要素
export const PROCODE_BANKCARDTWOELEMENT: ProductCodeType = ProductCodeType.BANKCARDTWOELEMENT // 银行卡二要素
export const PROCODE_BANKCARDTHREEELEMENT: ProductCodeType = ProductCodeType.BANKCARDTHREEELEMENT // 银行卡三要素
export const PROCODE_BANKCARDFOURELEMENT: ProductCodeType = ProductCodeType.BANKCARDFOURELEMENT // 银行卡四要素
export const PROCODE_BANKCARDADDRESS: ProductCodeType = ProductCodeType.BANKCARDADDRESS // 银行卡归属地
export const PROCODE_FACECOMPARE: ProductCodeType = ProductCodeType.FACECOMPARE // 人脸比对
export const PROCODE_FACEDISTINGUISH: ProductCodeType = ProductCodeType.FACERECONGNITION // 人脸比识别
export const PROCODE_IDOCR: ProductCodeType = ProductCodeType.IDOCR // 身份证OCR
export const PROCODE_BANKCARDOCR: ProductCodeType = ProductCodeType.BANKCARDOCR // 银行卡OCR
export const PROCODE_WITNESSCOMPARE: ProductCodeType = ProductCodeType.WITNESSCOMPARE // 人证比对
export const PROCODE_PORTRAITCOMPARE: ProductCodeType = ProductCodeType.PORTRAITCOMPARE // 人像比对
export const PROCODE_HACK: ProductCodeType = ProductCodeType.HACK // 防hack
export const PROCODE_LIVECHECK: ProductCodeType = ProductCodeType.LIVECHECK // 活体检测
export const PROCODE_STATICLIVECHECK: ProductCodeType = ProductCodeType.STATICLIVECHECK // 静态活体检测
export const PROCODE_DYNAMICLIVECHECK: ProductCodeType = ProductCodeType.DYNAMICLIVECHECK // 动态活体检测
export const PROCODE_EMPTYCHECK: ProductCodeType = ProductCodeType.EMPTYCHECK // 空号检测
export const PROCODE_REALTIMECHECK: ProductCodeType = ProductCodeType.REALTIMECHECK // 实时检测
export const PROCODE_HARASSBLACKLIST: ProductCodeType = ProductCodeType.HARASSBLACKLIST // 防骚扰黑名单
export const PROCODE_WOOLSHIELDTAG: ProductCodeType = ProductCodeType.WOOLSHIELDTAG // 羊毛盾标签版
export const PROCODE_ONLINEDURATION: ProductCodeType = ProductCodeType.ONLINEDURATION // 号码在网时长
export const PROCODE_ENTERPRISEFOURELEMENT: ProductCodeType = ProductCodeType.ENTERPRISEFOURELEMENT // 企业四要素
export const PROCODE_TRANSFERNETWORK: ProductCodeType = ProductCodeType.TRANSFERNETWORK // 携号转网
export const PROCODE_TEXTCAPTCHA: ProductCodeType = ProductCodeType.TEXTCAPTCHA // 文字验证码

export const PROCODE_PHONEAPCHECK: ProductCodeType = ProductCodeType.PHONEAPCHECK;  //号码活跃度检测


// 短信变量常量
export const SMSTEMPVAR_CAPTCHA: SmsTempVarType = SmsTempVarType.CAPTCHA
export const SMSTEMPVAR_CUSTOM: SmsTempVarType = SmsTempVarType.CUSTOM
export const SMSTEMPVAR_NOVAR: SmsTempVarType = SmsTempVarType.NOVAR
export const SMSTEMPVAR_ALLVAR: SmsTempVarType = SmsTempVarType.ALLVAR

// 供应商枚举
export const ISP_TELECOM: ISP = ISP.TELECOM
export const ISP_MOBILE: ISP = ISP.MOBILE
export const ISP_UNICOM: ISP = ISP.UNICOM
export const ISP_OTHER: ISP = ISP.OTHER

export function calcProLinkByProCode(proCode: ProductCodeType){
    switch (proCode) {
        case PROCODE_SMS:
            return `/product/sms/overView`
        case PROCODE_GLOBALSMS:
            return `/product/globalSms/send`
        case PROCODE_IDTWOELEMENT:
            return `/product/idTwoElement/callDetail`
        case PROCODE_PHONETWOELEMENT:
            return `/product/phoneTwoElement/callDetail`
        case PROCODE_PHONETHREEELEMENT:
            return `/product/phoneThreeElement/callDetail`
        case PROCODE_BANKCARDTWOELEMENT:
            return `/product/bankcardTwoElement/callDetail`
        case PROCODE_BANKCARDTHREEELEMENT:
            return `/product/bankcardThreeElement/callDetail`
        case PROCODE_BANKCARDFOURELEMENT:
            return `/product/bankcardFourElement/callDetail`
        case PROCODE_BANKCARDADDRESS:
            return `/product/bankcardAddress/callDetail`
        case PROCODE_FACECOMPARE:
            return `/product/faceCompare/callDetail`
        case PROCODE_FACEDISTINGUISH:
            return `/product/faceDistinguish/callDetail`
        case PROCODE_CDN:
            return `/product/cdn/domian`
        case PROCODE_IDOCR:
            return `/product/idCardOCR/callDetail`
        case PROCODE_BANKCARDOCR:
            return `/product/bankcardOCR/callDetail`
        case PROCODE_WITNESSCOMPARE:
            return `/product/witnessCompare/callDetail`
        case PROCODE_HACK:
            return `/product/hack/callDetail`
        case PROCODE_LIVECHECK:
            return `/product/livingTest/callDetail`
        case PROCODE_STATICLIVECHECK:
            return `/product/staticLivingTest/callDetail`
        case PROCODE_DYNAMICLIVECHECK:
            return `/product/dynamicLivingTest/callDetail`
        case PROCODE_EMPTYCHECK:
            return `/product/emptyCheck/checkOnline`
        case PROCODE_REALTIMECHECK:
            return `/product/realTimeCheck/feeFeedback`
        case PROCODE_ENTERPRISEFOURELEMENT:
            return `/product/enterpriseFourElement/callDetail`
        case PROCODE_PORTRAITCOMPARE:
            return `/product/portraitCompare/callDetail`
        case PROCODE_HARASSBLACKLIST:
            return `/product/harassBlacklist/callDetail`
        case PROCODE_WOOLSHIELDTAG:
            return `/product/woolShieldTag/callDetail`
        case PROCODE_ONLINEDURATION:
            return `/product/onlineDuration/callDetail`
        case PROCODE_TRANSFERNETWORK:
            return `/product/transferNetwork/callDetail`
        case PROCODE_TEXTCAPTCHA:
            return `/product/textCaptcha/callDetail`
        case PROCODE_PHONEAPCHECK:
            return `/product/phoneAP/callDetail`
        // case PROTYPE_PART:
        // case PROTYPE_PHONECHECK:
        default:
            return `/product/wip`
    }
}

// 计算认证状态
export function calcCertStatus(type: any, status: any) {
    if (status === CERT_UNCERT) return status
    return (`${type}${status}` as unknown as number) * 1
}

export const smsTypeOptions = [
    {
        label: '验证码',
        value: 1,
        isLeaf: false,
    },
    {
        label: '短信通知',
        value: 2,
        isLeaf: false,
    },
    {
        label: '会员营销',
        value: 3,
        isLeaf: false,
    },
    {
        label: '推广短信',
        value: 4,
        isLeaf: false,
    },
]

export const globalSmsTypeOptions = [
    {
        label: '验证码',
        value: 1,
        isLeaf: false,
    },
    {
        label: '短信通知',
        value: 2,
        isLeaf: false,
    },
    {
        label: '会员营销',
        value: 3,
        isLeaf: false,
    }
    // {
    //     label: '推广短信',
    //     value: 4,
    //     isLeaf: false,
    // },
]

export const smsTempVarTypeOptions = [
    {
        label: '验证码变量',
        value: 1
    },
    {
        label: '自定义变量',
        value: 2
    },
    {
        label: '无变量',
        value: 3
    },
    {
        label: '全变量',
        value: 4
    },
]


export const unitOptions = [
    {
      label: 'GB',
      value: 2
    },
    {
      label: 'TB',
      value: 'TB'
    },
    {
      label: '次',
      value: 1
    },
    {
      label: '条',
      value: 3
    },
    {
      label: '亿次',
      value: '亿次'
    }
]

export const calcUnitLabel = (value: number) => {
    const { label } = (unitOptions.find((v: any) => v.value === value) || {}) as any
    return label
}

export const formatMap = {
    'time': 'YYYY-MM-DD HH:mm:ss',
    'date': 'YYYY-MM-DD',
    'week': 'YYYY-w',
    'month': 'YYYY-MM',
    'quarter': 'YYYY-Q',
    'year': 'YYYY'
}
export const allDateRanges: any = {
    '今天': [moment(), moment()],
    '昨天': [moment().add(-1, 'days'), moment().add(-1, 'days')],
    '近7天': [moment().add(-7, 'days'), moment()],
    '近30天': [moment().add(-30, 'days'), moment()],
    '本周': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
    '上周': [moment().startOf('isoWeek').add(-7, 'days'), moment().endOf('isoWeek').add(-7, 'days')],
    '本月': [moment().startOf('month'), moment().endOf('month')],
    '上月': [moment().startOf('month').add(-1, 'month'), moment().endOf('month').add(-1, 'month')],
    '本季': [moment().startOf('quarter'), moment().endOf('quarter')],
    '上季': [moment().startOf('quarter').add(-1, 'quarter'), moment().endOf('quarter').add(-1, 'quarter')],
    '本年': [moment().startOf('year'), moment().endOf('year')],
    '上年': [moment().startOf('year').add(-1, 'year'), moment().endOf('year').add(-1, 'year')]
}
