import { ProColumns } from "@ant-design/pro-table"

export const mockData = []

export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
  return [
    {
        title: '域名',
        dataIndex: 'domain'
    },
    {
        title: '请求数量',
        dataIndex: 'requestNum',
        // sorter: true,
        sorter: (a, b) => a.requestNum - b.requestNum,
        render: (text: any, record: any) => (record.requestNum||0) + '次'
    },
    {
        title: '请求命中率',
        dataIndex: 'hitPercent',
        // sorter: true,
        sorter: (a, b) => a.hitPercent - b.hitPercent,
        render: (text: any, record: any) => (record.hitPercent||0) + '%'
    },
    {
        title: '每秒请求数(QPS)',
        dataIndex: 'qps',
        // sorter: true,
        sorter: (a, b) => a.qps - b.qps,
        render: (text: any, record: any) => (record.qps||0) + '次'
    }
   ]
}
