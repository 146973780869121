import { ProColumns } from "@ant-design/pro-table";
import { fileHttpUrl } from "@api/baseUrl";
import { DateRangeWithDisabled } from "@components/Common";
import { Input, Select } from "antd";
import React, { ReactNode } from "react";

const options = [{label: 'options1', value: 1}, {label: 'options2', value: 2}]


const valueEnum = {
    // // 0: { text: '全部', status: 'Default' },
    // 1: { text: '成功', status: 'Sueecss' },
    // 2: { text: '失败', status: 'Error' },
    // // 3: { text: '其他错误', status: 'Processing' },
    1: { text: '核查一致', status: 'Sueecss' },
    2: { text: '核查不一致', status: 'Error' },
    9: { text: '其他错误', status: 'Error' },
}


const valueEnumCharge = {
    2: { text: '不收费', status: 'Default' },
    1: { text: '收费', status: 'Sueecss' },
}

/**
 * 列表表格配置:
 *  自定义表格 CustomerDemo 和 antd 升级版组件 ProTableDemo 都可使用, 
 * */
export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        // {
        //     title: '用户姓名',
        //     dataIndex: 'name',
        //     // valueType: 'select',
        //     // hideInSearch: true
        // },
        // {
        //     title: '身份证号',
        //     dataIndex: 'idCard',
        //     // key: 'phoneNum',
        //     // hideInSearch: true
        // },
        {
            title: '流水号',
            dataIndex: 'reqNo',
            hideInSearch: true
        },
        {
            title: '活体检测分数',
            dataIndex: 'livePortraitScore',
            hideInSearch: true
        },
        {
            // title: '状态',
            title: '核查结果',
            dataIndex: 'checkType',
            valueType: 'select',
            fieldProps: {
                // defaultValue: 2,
                allowClear: false
             },
            valueEnum: valueEnum,
            // valueEnum,
            // hideInSearch: true
        },
        {
            title: '备注',
            dataIndex: 'remark',
            hideInSearch: true
        },
        {
            title: '调用时间',
            dataIndex: 'callTime',
            // valueType: 'dateTimeRange',
            valueType: 'dateRange',
            fieldProps: { },
            search: {
                transform: (value) => {
                    return {
                        startTime: value[0] ? value[0] + ' 00:00:00' : undefined,
                        endTime: value[1] ? value[1] + ' 23:59:59' : undefined
                    }
                },
            },
            renderFormItem: (props) => <DateRangeWithDisabled />,
            render: (text: any, record: any) => record.callTime,
        },
        {
            title: '是否计费',
            dataIndex: 'fee',
            order: 4,
            valueType: 'select', 
            valueEnum: valueEnumCharge,
            fieldProps: {
                allowClear: false
            },
            formItemProps: {
                // initialValue: '1',  
            },
            search: {
                transform: (value) => {
                    return {fee: value}
                },
            },
        },
        
    
    ]
}
