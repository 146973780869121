import { smsDataPushQuery } from "@api/index"
import { SET_SMS_SETTING } from "@store/contants"
import { handleDvmCommonResult } from "@utils/request"

export const setSmsSetting = (smsSetting: any) => { // 国内短信设置相关数据: [上限设置,数据推送,API调用密钥]
    return {
        type: SET_SMS_SETTING,
        smsSetting
    }
}

// 异步获取国内短信设置
export const asyncSetSmsSetting = () => {
    return async (dispatch: any) => {
        dispatch(setSmsSetting({}))
        const {result} = await smsDataPushQuery({}) as any
        const res = handleDvmCommonResult(result)
        dispatch(setSmsSetting(res))
    }
}