import { ProColumns } from "@ant-design/pro-table";
import { fileHttpUrl } from "@api/baseUrl";
import { cdnDomainsOfCurrentUser } from "@api/product";
import { Input, Select } from "antd";
import { stringify } from "qs";
import React, { ReactNode } from "react";

const options = [{label: 'options1', value: 1}, {label: 'options2', value: 2}]
/**
 * 列表表格配置:
 *  自定义表格 CustomerDemo 和 antd 升级版组件 ProTableDemo 都可使用, 
 * */
export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
       
        {
            title: '加速域名',
            dataIndex: 'domainName',
            valueType: 'select',
            fieldProps:{
              showSearch: true,
            },
            request: async() => {
              const { data } = await cdnDomainsOfCurrentUser({}) as any
               return (data || []).map((v: any) => ({label: v, value: v}))
            },
            render: (text: any, record: any) => <a href={`/product/cdn/domianConfig?${stringify({domainId: record.domainId, domainName: record.domainName})}`}>{text}</a>,
        },
        {
            title: '证书名称',
            dataIndex: 'certName',
            hideInSearch: true
          },
          {
            title: '证书状态',
            dataIndex: 'certStatus',
            hideInSearch: true,
            render: (text: any, record: any) => ['正常', '已过期'][text]
          },
          {
            title: 'https状态',
            dataIndex: 'httpsStatus',
            hideInSearch: true,
            render: (text: any, record: any) => ['关闭', '开启'][text]
          },
          {
            title: '证书来源',
            dataIndex: 'certificateType',
            hideInSearch: true,
            render: (text: any, record: any) => ['自有证书', '华为云托管证书'][text]
          },
          {
            title: '证书到期时间',
            dataIndex: 'expirationTime',
            valueType: 'dateTime',
            hideInSearch: true
          },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            valueType: 'option',
            fixed: 'right',
            render: (text: any, record: any) => [
                <a key="编辑" style={{marginRight: 8}} onClick={() => operate('编辑', record)} >编辑</a>,
                <a key="删除" onClick={() => operate('删除', record)}>删除</a>,
            ]
        }
    ]
}
