import { ProColumns } from "@ant-design/pro-table";
import { fileHttpUrl } from "@api/baseUrl";
import { Input, Select } from "antd";
import React, { ReactNode } from "react";
import { Image } from 'antd';

const options = [{label: 'options1', value: 1}, {label: 'options2', value: 2}]

const valueEnum = {
    1: { text: '执行中', status: 'Processing' },
    2: { text: '执行成功', status: 'Success' },
    9: { text: '执行失败', status: 'Error' },
}

/**
 * 列表表格配置:
 *  自定义表格 CustomerDemo 和 antd 升级版组件 ProTableDemo 都可使用, 
 * */
export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '流水号',
            dataIndex: 'serialNumber',
            hideInSearch: true
        },
        {
            title: '任务名称',
            dataIndex: 'taskName',
        },
        {
            title: '任务状态',
            dataIndex: 'state',
            valueType: 'select',
            fieldProps: { mode: 'multiple' },
            valueEnum,
            search: {
                transform: (taskStatus) => {
                    return {taskStatus}
                },
            },
        },
        {
            title: '生成时间',
            dataIndex: 'startTime',
            key: 'startTime',
            valueType: 'dateRange',
            fieldProps: { },
            search: {
                transform: (value) => {
                    return {
                        startDate: value[0] ? value[0] + ' 00:00:00' : undefined,
                        endDate: value[1] ? value[1] + ' 23:59:59' : undefined
                    }
                },
            },
            render: (text: any, record: any) => record.startTime,
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            valueType: 'option',
            fixed: 'right',
            width: 120,
            render: (text: any, record: any) => [
                record.url ? <a key="下载" style={{marginRight: 8}} download href={fileHttpUrl(record.url)}>下载</a> : null,
                <a key="删除" onClick={() => operate('删除', record)}>删除</a>,
            ]
        }
    ]
}
