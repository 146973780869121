import { ProColumns } from "@ant-design/pro-table";
import { fileHttpUrl } from "@api/baseUrl";
import { Input, Progress, Select } from "antd";
import React, { ReactNode } from "react";
import { calcUnitLabel, smsTypeOptions, toFixedNoRound, unitOptions } from "@utils/index";
import { DateRangeWithDisabled } from "@components/Common";

const options = [{label: 'options1', value: 1}, {label: 'options2', value: 2}]


const valueEnum = {
    // 0: { text: '全部', status: 'Default' },
    1: { text: '有效', status: 'Sueecss' },
    2: { text: '已失效', status: 'Error' },
}


const valueEnumCharge = {
    0: { text: '不收费', status: 'Default' },
    1: { text: '收费', status: 'Sueecss' },
}


/**
 * 列表表格配置:
 *  自定义表格 CustomerDemo 和 antd 升级版组件 ProTableDemo 都可使用, 
 * */
export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
       
        {
            title: '套餐分类',
            dataIndex: 'interfaceName',
            // valueType: 'select',
            hideInSearch: true
        },
        {
            title: '套餐规格',
            dataIndex: 'ppgName',
            hideInSearch: true
        },
        {
            title: '资源包状态',
            dataIndex: 'status',
            filters: true,
            valueEnum,
            hideInSearch: true
        },
       
        {
            title: '生效时间',
            dataIndex: 'entryTime',
            valueType: 'date',
            sorter: true,
            hideInSearch: true,
            render: (text: any, record: any) => record.entryTime,
        },
        {
            title: '失效时间',
            dataIndex: 'failureTime',
            // valueType: 'dateTimeRange',
            hideInSearch: true,
            sorter: true,
            valueType: 'dateRange',
            fieldProps: { },
            search: {
                transform: (value) => {
                    return {
                        startTime: value[0] ? value[0] + ' 00:00:00' : undefined,
                        endTime: value[1] ? value[1] + ' 23:59:59' : undefined
                    }
                },
            },
            renderFormItem: (props) => <DateRangeWithDisabled />,
            render: (text: any, record: any) => record.failureTime,
        },
        {
            title: '使用情况',
            dataIndex: 'feeStr',
            order: 4,
            valueType: 'select', 
            valueEnum: valueEnumCharge,
            hideInSearch: true,
            render: (text: any, record: any) => <>
              <Progress percent={(1-record.left/record.sum) * 100} showInfo={false} />
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <span>已用: {[1, 3].includes(record.unit) ? (record.sum - record.left) : toFixedNoRound(record.sum - record.left)}{calcUnitLabel(record.unit)}</span>
                <span>剩余{record.left}{calcUnitLabel(record.unit)}/共{record.sum}{calcUnitLabel(record.unit)}</span>
              </div>
            </>
        },
    ]
}

export const editTableColumnFn: (operate?: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '套餐分类',
            dataIndex: 'unit',
            key: 'unit',
            editable: false,
            render: (text: any, record: any) => [1,3].includes(record.unit) ? '次数计费' : '流量计费'
        },
        {
            title: '余量预警值',
            dataIndex: 'ualLimit',
            key: 'ualLimit',
            // fieldProps: { style: {width: 150}, addonAfter: <Select style={{width: 72}} value={recorcd.ualModel1st} disabled options={unitOptions} /> },
            fieldProps:(form: any, {entity}) => {
                return { 
                    style: {width: 150}, 
                    addonAfter: <Select style={{width: 72}} value={entity.ualModel1st} disabled options={unitOptions} /> 
                }
            },
            valueType: 'digit',
        },
        {
            title: '预警开关',
            dataIndex: 'ualIsEnabled',
            key: 'ualIsEnabled',
            valueType: 'switch',
            fieldProps: {
                checkedChildren: '开启',
                unCheckedChildren: '关闭'
            },
        },
    ]
}