import { ProColumns } from "@ant-design/pro-table"
import { DateRangeWithDisabled, Ellipsis, Icon } from "@components/Common"
import { StateType } from "@store/index"
import { parseSearch } from "@utils/util"
import { Tooltip } from "antd"
import moment from "moment"
import React from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

export const statusOptions = [
    {
        label: '未执行',
        value: 0
    },
    {
        label: '执行中',
        value: 1
    },
    {
        label: '执行成功',
        value: 2
    },
    {
        label: '执行失败',
        value: 3
    },
    {
        label: '取消执行',
        value: 9
    },
]

export const valueEnum = {
    0: { text: '未执行', status: 'Default' },
    1: { text: '执行中', status: 'Processing' },
    2: { text: '执行完成', status: 'Success' },
    3: { text: '执行失败', status: 'Error' },
    9: { text: '取消执行', status: 'Error' },
}

const detailTypeOptions = [
    {
        label: '任务明细',
        value: 1
    },
    {
        label: 'Api 明细',
        value: 2
    }
]

export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    const { pathname, search } = useLocation();
    const { tiId, taskOrApiDetails, sendTime, createTime } = parseSearch(search)
    const allCountry = useSelector((state: StateType) => state?.allCountry) as any
    const initTime = sendTime ? [moment(createTime), moment(sendTime)] : [moment().add(0, 'days'), moment()]
    return [
        {
            title: '明细类型',
            dataIndex: 'taskOrApiDetails',
            key: 'taskOrApiDetails',
            valueType: 'select',
            fieldProps: {
                allowClear: false
            },
            formItemProps: {
                initialValue: taskOrApiDetails ? Number(taskOrApiDetails) : 2,  
            },
            request: async() => detailTypeOptions,
            hideInTable: true
        },
        {
            title: 'sendID',
            dataIndex: 'tiId',
            key: 'tiId',
            formItemProps: {
                initialValue: tiId,  
            },
        },
        {
            title: '发送内容',
            dataIndex: 'smsContent',
            key: 'smsContent',
            render: (text: any, record: any) => <Ellipsis.Nowrap width={300}>{`${(record.smsContent||'').toString()}`}</Ellipsis.Nowrap> 
        },
        {
            title: '总价',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            hideInSearch: true
        },
        {
            title: '单价',
            dataIndex: 'price',
            key: 'price',
            hideInSearch: true
        },
        {
            title: '接收号码',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '接收国家',
            dataIndex: 'receiveCountry',
            key: 'receiveCountry',
            fieldProps: {
                showSearch: true,
            },
            request: async () => allCountry
        },
        {
            // title: '字数 | 拆分条数 ',
            title: () => <span>字数 | 拆分条数 <Tooltip title={(<div>
                计费规则：纯英文：单条短信（含签名）长度为160字，超过160字按<br/>
                <div dangerouslySetInnerHTML={{__html: '153字/条分隔多条计费（纯英文签名符号为[])'}}></div>
                <div dangerouslySetInnerHTML={{__html: '其他语言：单条短信（含签名)长度70字，超过70字按67字/条分隔多条计费'}}></div>
                <div dangerouslySetInnerHTML={{__html: '普通英文字符按照一个字符计算长度，如出现^ { } \ [ ~ ] €| 这些'}}></div>
                特殊字符，按2个字符计算。
            </div>)}><Icon type="icon-ziyuan" style={{marginLeft: 6}} /></Tooltip></span>,
            dataIndex: 'word',
            key: 'word',
            hideInSearch: true,
            render: (text: any, record: any) => `${record.word||0}字 | ${record.row||0}条`
        },
        {
            title: '发送时间',
            dataIndex: 'sendTime',
            key: 'sendTime',
            valueType: 'dateRange',
            fieldProps: { },
            formItemProps: {
                initialValue: initTime,  
            },
            search: {
                transform: (value) => {
                    return {
                        startTime: value[0] ? value[0] + ' 00:00:00' : undefined,
                        endTime: value[1] ? value[1] + ' 23:59:59' : undefined
                    }
                },
            },
            renderFormItem: (props) => <DateRangeWithDisabled />,
            render: (text: any, record: any) => record.sendTime
        },
        {
            title: '发送状态',
            dataIndex: 'sendStatus',
            key: 'sendStatus',
            valueType: 'select',
            valueEnum,
            request: async () => statusOptions
        },
        {
            title: '失败原因',
            dataIndex: 'failCause',
            key: 'failCause',
            hideInSearch: true,
            render: (text: any, record: any) => <Ellipsis.Nowrap width={200}>{`${(record.failCause||'').toString()}`}</Ellipsis.Nowrap> 
        },
        // {
        //     title: '操作',
        //     dataIndex: 'operate',
        //     key: 'operate',
        //     valueType: 'option',
        //     render: (text: any, record: any) => [
        //         <a key="详情" style={{marginRight: 8}} onClick={() => operate('详情', record)}>详情</a>,
        //         <a key="删除" onClick={() => operate('删除', record)}>删除</a>,
        //     ]
        // }
    ]
}
