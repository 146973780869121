import { ProColumns } from "@ant-design/pro-table"
import { DateRangeWithDisabled } from "@components/Common"
import { InputCompactSelect } from "@components/Common/Compact"
import React from "react"

const usTypeOptions = [
    {
        label: '通用',
        value: 1
    }
]

const codeOptions = [
    {
        label: '签名名称',
        value: 1
    },
    {
        label: '签名Code',
        value: 2
    }
]

const statusOptions  = [
    {
        label: '未审批',
        value: 0
    },
    {
        label: '审批中',
        value: 1
    },
    {
        label: '审批通过',
        value: 2
    },
    {
        label: '审批不通过',
        value: 9
    },
]

export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '签名CODE',
            dataIndex: 'usCode',
            key: 'usCode',
            order: 1,
            search: {
                transform: (param) => {
                    return {usName: param}
                },
            },
        },
        {
            title: '签名名称',
            dataIndex: 'usName',
            key: 'usName',
            width: 150,
            order: 4,
            // initialValue: { type: 1, value: '' },
            // renderFormItem: () => <InputCompactSelect addonPosition="addonBefore" placeholder="请输入" selectProps={{options: codeOptions}} />,
            // search: {
            //     transform: (param) => {
            //         const { type, value } = param
            //         return { [type]: value }
            //     },
            // },
        },
        {
            title: '适用场景',
            dataIndex: 'usType',
            key: 'usType',
            hideInSearch: true,
            request: async () => usTypeOptions,
            render: (text: any, record: any) => record.usType ? '通用' : '-'
        },
        {
            title: '审核状态',
            dataIndex: 'utlVerifyState',
            key: 'utlVerifyState',
            // hideInSearch: true,
            valueType: 'select',
            order: 3,
            request: async () => statusOptions
        },
        {
            title: '审核备注',
            dataIndex: 'utlVerifyDescribe',
            key: 'utlVerifyDescribe',
            hideInSearch: true,
            width: 300
        },
        {
            title: '申请说明',
            dataIndex: 'usApplyDescribe',
            key: 'usApplyDescribe',
            hideInSearch: true,
            width: 300
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            valueType: 'dateRange',
            order: 2,
            render: (text: any, record: any) => record.createTime,
            search: {
                transform: (value) => {
                    return {
                        createTimeStart: value[0] ? value[0] + ' 00:00:00' : undefined,
                        createTimeEnd: value[1] ? value[1] + ' 23:59:59' : undefined
                    }
                },
            },
            renderFormItem: (props) => <DateRangeWithDisabled />,
            // hideInSearch: true
        },
        {
            title: '操作',
            dataIndex: 'option',
            key: 'option',
            valueType: 'option',
            fixed: 'right',
            width: 100,
            render: (text: any, record: any) => [
                // <a key="详情" style={{marginRight: 8}} onClick={() => operate('详情', record)}>详情</a>,
                <a key="删除" onClick={() => operate('删除', record)}>删除</a>,
            ]
        }
    ]
}
