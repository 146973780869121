import { ActionType } from "@ant-design/pro-table"
import { Table, Tooltip } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { tlsEditionList, TLSTable, tooltipData } from "../../data"
import { DescriptionsPro, SwitchAndMsg, TitleBar } from "./cpnts"
import ProForm, {
  ModalForm,
  ProFormSelect,
  ProFormTextArea,
  ProFormSwitch,
  ProFormText,
  ProFormCheckbox,
} from '@ant-design/pro-form';
import { ProFormDependency } from '@ant-design/pro-form';
import FormItem from "antd/es/form/FormItem";
import {
EditOutlined,
} from '@ant-design/icons';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import AlertTip from "@components/Common/AlertTip"
import {  cdnGetHttpsConfig, cdnGetUserCertList, cdnSaveOrUpdateCert, cdnSwitchStatus, cdnUpdateTlsVersion, smsTempPageList } from "@api/index"
import { handleDvmCommonListResult } from "@utils/request"
import { useLocation } from "react-router-dom"
import { filterObj, parseSearch, productApi } from "@utils/util"


const getDataApi = productApi(cdnGetHttpsConfig)  // 查询hpps配置数据
const submitHttpsConfig = productApi(cdnSaveOrUpdateCert)  //  https 配置请求提交请求
const backModeApi = productApi(cdnSwitchStatus)  // 统一的按钮切换请求
const tlsSubmitApi = productApi(cdnUpdateTlsVersion)  //  tls配置请求提交请求



const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

//HTTPS配置
const HTTPSConfigModal = ({ httpsData, params, onSuccess, ...rest }: any) => {
  const formRef = useRef<ProFormInstance>();

  const onFinish = async (values: any) => {
      const data = { 
          ...values,
          ...params,
          httpsStatus: values.httpsStatus * 1,
          certificateType: 0 
      }
      const { success } = await submitHttpsConfig(
          filterObj(data),
          true,
          () => {
              clearData()
              onSuccess ? onSuccess() : null
          }
      )
      return true
  }

  useEffect(()=> {
    // console.log(httpsData)
  //   const { httpsStatus, refererType = 2, id, certificate, privateKey } = httpsData 
    console.log(111, formRef)
    //   httpsData
  //     {
  //     httpsStatus,
  //      refererType,
  //       id,
  //       certificate,
  //       privateKey
  // }
  // );
  }, [])

    const clearData = () => {
      formRef.current?.resetFields()
    }
  return (
    <ModalForm<any>
      layout="horizontal"
      width={600}
      ref={formRef}
      {...layout}
      params={httpsData}
      onFinish={onFinish}
      preserve={false}
      request= {
        ()=> {
          console.log(httpsData)
        //  return {...httpsData,httpsStatus: httpsData.httpsStatus }
        return httpsData
        }
      }
      modalProps={{
        onCancel: clearData,
      }}
      {...rest}
    >
      <ProFormSwitch
        width="md"
        initialValue={0}
        name="httpsStatus"
        label="HTTPS安全加速"
        fieldProps={{}}
        rules={[{ required: true, message: "请选择HTTPS安全加速" }]}
      />

      {/* 源站类型 */}
      <ProFormSelect
        width="md"
        name="refererType"
        label="证书来源"
        initialValue={2}
        fieldProps={{
          allowClear: false,
          placeholder: "请选择证书来源",
          defaultValue: 2,
        }}
        options={[
          {
            value: 2,
            label: "已有证书",
          },
          {
            value: 1,
            label: "新建证书",
          },
        ]}
        rules={[{ required: true, message: "请选择证书来源" }]}
      />

      <ProFormDependency name={["refererType"]}>
        {({ refererType }: any, form) => {
          return (
            <>
              {refererType === 2 ?  <ProFormSelect
                 width="md"
                 name="id"
                 label="选择证书"
                 showSearch
                 fieldProps={{
                     placeholder: '请选择证书',
                     showSearch: true,
                     filterOption: false,
                     onChange: (data, option: any)=> {
                      const { certificate, privateKey } = option['data-item']
                      form.setFieldsValue({
                        certificate,
                        privateKey
                    });
                     }
                 }}
                 
                 options={[]}
                 request={
                     async () => {
                     const { data } = await cdnGetUserCertList({pageNum: 1, pageSize: 999}) as any
                     return data?.map(({ id: value, certName: label, certificate, privateKey }: any) => ({ label, value, privateKey, certificate }))
                   }
                 }
                 rules={[{ required: true, message: '请选择证书' }]}
              /> : null}
              {refererType === 1 ? <ProFormText
                width="md"
                name="certName"
                fieldProps={{maxLength: 32}}
                rules={[{ required: true, message: "请输入证书名称" }]}
                label="证书名称"
                placeholder="建议输入英文，长度不超过32个字符"
              /> : null}
            
      <ProFormTextArea
          rules={[{ required: true, message: '请输入PEM格式的公钥内容' }]}
          placeholder='请输入PEM格式的公钥内容'
          name="certificate"
          label="证书内容"
          disabled={refererType === 2}
          fieldProps={{
              allowClear: true,
          }}
          extra={ <Tooltip title={tooltipData.PEMBMSL}>
          <div className="text-blue-400 mt-2">pem编码参考样例</div>
        </Tooltip>}
  
      />
       <ProFormTextArea
          rules={[{ required: true, message: '请输入PEM格式的私钥内容' }]}
          placeholder='请输入PEM格式的私钥内容'
          name="privateKey"
          label="私钥内容"
          disabled={refererType === 2}
          fieldProps={{
              allowClear: true,
          }}
          extra={ <Tooltip title={tooltipData.PEMKEYBMSL}>
          <div className="text-blue-400 mt-2">密钥key参考样例</div>
        </Tooltip>}
      />
      </>
          );
        }}
      </ProFormDependency>
    </ModalForm>
    
  );
}


//TLS版本配置
const TLSConfigModal = ({tlsData, params, onSuccess, ...rest }: any) => {
  const formRef = useRef<ProFormInstance>();

  const onFinish = async (values: any) => {
      const data = { 
          ...values,
          ...params,
          tlsVersion: values.tlsVersion.toString()
      }
      const { success } = await tlsSubmitApi(
          filterObj(data),
          true,
          () => {
              clearData()
              onSuccess ? onSuccess() : null
          }
      )
      return true
  }

    // 校验tls输入
    const  tLSCheckBox = (rules: any, val: any, callback: Function)=> {
      const selectList = val.map((item: any) => item.slice(6, 7) )
      console.log(222222, selectList, val)
     
      const adopt = Math.max(...selectList) - Math.min(...selectList) + 1 === selectList.length
      if (!selectList) {
          return Promise.reject('请先选择TLS版本')
      }
      if (adopt) {
          return Promise.resolve()
      } else {
          return Promise.reject(['只可开启连续版本号或单个版本号'])
      }
    }


    const clearData = () => {
      formRef.current?.resetFields()
    }

    const listTips = [
      '只可开启连续版本号或单个版本号。例如，不可仅开启1.0和1.2而关闭1.1。',
      '不可关闭全部版本。'
    ] as any
  return (
    <ModalForm<any>
      layout="horizontal"
      width={600}
      formRef={formRef}
      {...layout}
      params={tlsData}
      request= {
        ()=> {
         return { tlsVersion: tlsData.tlsVersion.split(',')}
        }
      }
      onFinish={onFinish}
      preserve={false}
      modalProps={{
        onCancel: clearData,
      }}
      {...rest}
    >
      <AlertTip type="warning" listTips={listTips} />
      {/* 源站类型 */}
      <ProFormCheckbox.Group
        width="md"
        name="tlsVersion"
        label="TLS版本"
        options={tlsEditionList}
        rules={[{ required: true, validator: tLSCheckBox }]}
      />
    </ModalForm>
  );
}



//强制跳转编辑
const JumpModal = ({ jumpData, params, onSuccess, ...rest }: any) => {
  const formRef = useRef<ProFormInstance>();

  const onFinish = async (values: any) => {
      const data = { 
          ...values,
          ...params,
      }
      const { success } = await backModeApi(
          filterObj(data),
          true,
          () => {
              clearData()
              onSuccess ? onSuccess() : null
          }
      )
      return true
  }

 
    const clearData = () => {
      formRef.current?.resetFields()
    }
  return (
    <ModalForm<any>
      layout="horizontal"
      width={600}
      formRef={formRef}
      {...layout}
      params={jumpData}
      request= {
        ()=> {
         return {status: jumpData.forceRedirectStatus}
        }
      }
      onFinish={onFinish}
      preserve={false}
      modalProps={{
        onCancel: clearData,
      }}
      {...rest}
    >
     
      {/* 源站类型 */}
      <ProFormSelect
        width="md"
        name="status"
        label="跳转类型"
        initialValue={1}
        fieldProps={{
          allowClear: false,
          placeholder: "请选择跳转类型",
          defaultValue: 1,
        }}
        tooltip={tooltipData['YZLX']}
        options={[
              {
              value: 0,
              label: '默认'
              },
              {
              value: 1,
              label: 'HTTPS'
              },
              {
                value: 2,
                label: 'HTTP'
                },
          ]}
        rules={[{ required: false, message: "请选择源站类型" }]}
      />
    </ModalForm>
  );
}


export default () => {
    const [basicInfo, setBasicInfo] = useState([]) as any
    const [httpsData, setHttpsData] = useState({}) as any
    const [tableData, setTableData] = useState([]) as any

    
    const { pathname, search } = useLocation();
    const { domainId } = parseSearch(search)

    const operation = async (type: string, data?: any) => {
        if (type === 'http2') {
          const param = {
            status: data *1,
            domainId,
            action: 'http2'
          }  as any
          const { success } = await backModeApi(
              filterObj(param),
              true,
              () => {
                getData()
              }
          )
          return true
        }
    }


   

    // const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    // const formRef = useRef<ProFormInstance>()
    
    const getData = async () => {
      const { data } = await getDataApi({domainId}, false) 
      setHttpsData(data)
      const { certName, httpsStatus,expirationTime, certificate, tlsVersion  } = data || {}
        setBasicInfo([
          {
            label: 'HTTPS安全加速',
            text: httpsStatus ? '开启' : '关闭'
          },
          {
            label: '证书标准',
            text: '国际证书'
          },
          {
            label: '证书来源',
            text: '自有证书'
          },
          {
            label: '证书名称',
            text: certName || '未配置'
          },
          {
            label: '证书到期时间',
            text: expirationTime
          },
          {
            label: '证书内容',
            text: certificate
          },
          {
            label: '私钥内容',
            text: '由于私钥内容涉及用户敏感信息，不支持查看。'
          }])

         setTableData([
            {
              edition: 'TLSv1.0',
              state: tlsVersion.indexOf('TLSv1.0') >= 0 ? '1' : '0'
            },
            {
              edition: 'TLSv1.1',
              state:tlsVersion.indexOf('TLSv1.1') >= 0 ? '1' : '0'
            },
            {
              edition: 'TLSv1.2',
              state: tlsVersion.indexOf('TLSv1.2') >= 0 ? '1' : '0'
            },
            {
              edition: 'TLSv1.3',
              state:tlsVersion.indexOf('TLSv1.3') >= 0 ? '1' : '0'
            }
          ])
    }

    useEffect(() => {
        getData()  
     }, [])

    

      const onSuccess = () => {
        getData()  
      }


    return (<div>
       
        <TitleBar title='HTTPS配置'  tooltip={tooltipData.HTTPSPZ}  editNode={<HTTPSConfigModal httpsData={httpsData} onSuccess={onSuccess} params={{domainId}} key="btn0" title="HTTPS配置" trigger={<EditOutlined className="float-right"  type='primary' />}  />} />
        <DescriptionsPro list={basicInfo} column={1} />
        <TitleBar title='TLS版本配置'  tooltip={tooltipData.TLSBBKZ}  editNode={<TLSConfigModal tlsData={httpsData} onSuccess={onSuccess} params={{domainId}}  key="btn0" title="TLS版本配置" trigger={<EditOutlined className="float-right"  type='primary' />}  />} />
        <Table dataSource={tableData} columns={TLSTable} />
        <TitleBar title='强制跳转' editNode={<JumpModal  jumpData={httpsData} key="btn0"  onSuccess={onSuccess} params={{domainId, action: 'setForceRedirectStatus'}} title="强制跳转" trigger={<EditOutlined className="float-right"  type='primary' />}  />} />
        <SwitchAndMsg
          label='跳转类型：'
          textValue={['默认','HTTPS','HTTP', ' - '][httpsData.forceRedirectStatus || 3]}
          alertMsg='通过配置强制跳转功能，可以将客户端到CDN边缘节点的原请求方式强制重定向为HTTP或者HTTPS请求。选择“默认”时，同时支持HTTP和HTTPS方式的请求。'
        />
         <TitleBar title='HTTP/2'  tooltip={tooltipData.HTTP2} />
        <SwitchAndMsg
          label='HTTP/2：'
          checked= {!!httpsData.http2Status}
          onChange={(data: any) => operation('http2', data)}
          alertMsg='HTTP2.0协议是HTTP1.1协议的升级版本，在Web数据交互性能上具备更多的优势，开启前您需要先配置HTTPS证书。'
        />
    </div>)
}
