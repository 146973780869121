/* eslint-disable react/no-unknown-property */
import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

export default (props: any) => {
  const { nodes, materials } = useGLTF('/assets/glbFile/earth.glb') as any;
  const refEarth = useRef() as any;
  const ref0 = useRef() as any;
  const ref1 = useRef() as any;
  const ref2 = useRef() as any;
  const ref3 = useRef() as any;
  const ref4 = useRef() as any;
  const ref5 = useRef() as any;
  const ref6 = useRef() as any;
  const ref7 = useRef() as any;
  const ref8 = useRef() as any;
  const ref9 = useRef() as any;
  const ref10 = useRef() as any;
  const ref11 = useRef() as any;
  const ref12 = useRef() as any;
  // materials["底座白色材质"] = new THREE.MeshStandardMaterial({
  //   precision: "lowp",
  //   transparent: false,
  //   side:THREE.DoubleSide,
  //   shadowSide:THREE.FrontSide,
  //   roughness: 0.3,  //光滑度
  //   metalness: 0.70,
  //   color: "rgb(243, 243, 243)",
  // })
  materials['主红色'] = new THREE.MeshStandardMaterial({
    precision: 'lowp',
    side: THREE.DoubleSide,
    lightMapIntensity: 0.8, //烘焙光强度
    roughness: 0.01, //光滑度
    emissiveIntensity: 5,
    color: '#e34e2c',
  });

  // materials["底座白色材质"] = new THREE.MeshStandardMaterial({
  //   precision: "lowp",
  //   transparent: true,
  //   side:THREE.DoubleSide,
  //   opacity: 0.35,
  //   roughness: 0.0,  //光滑度
  //   metalness: 0.70,
  //   color: "rgb(243, 243, 243)",
  // });
  function Rotation({ v = new THREE.Vector3() }) {
    return useFrame((data: any) => {
      const t = data.clock.elapsedTime;
      // data.camera.position.lerp(v.set(Math.sin(t / 3), 0.3, 2.7 + Math.cos(t / 5) / 3), 0.1)
      refEarth.current.rotation.x += 0.001;
      ref1.current.rotation.z += 0.03;
      ref2.current.rotation.z += 0.03;
      ref2.current.rotation.z += 0.03;
      ref3.current.rotation.z += 0.03;
      ref3.current.rotation.z += 0.03;
      ref4.current.rotation.z += 0.03;
      ref5.current.rotation.z += 0.03;
      // ref6.current.rotation.z +=  0.03
      ref7.current.rotation.z += 0.03;
      ref8.current.rotation.z += 0.03;
      ref9.current.rotation.z += 0.03;
      ref10.current.rotation.z += 0.03;
      ref11.current.rotation.z += 0.03;
    });
  }

  return (
    <group {...props} dispose={null}>
      <Rotation></Rotation>
      <group
        position={[-7.57, 229.2, -2.31]}
        ref={refEarth}
        rotation={[0, -1.27, 0]}
      >
        <group position={[-1.76, 8.9, -7.02]}>
          {/* <mesh ref={ref12} geometry={nodes.圆盘.geometry} material={nodes.圆盘.material} position={[11.04, -24.19, -0.91]} rotation={[0, 1.27, 0.19]} /> */}
          <mesh
            ref={ref11}
            geometry={nodes.扫描11.geometry}
            material={materials.信号线}
            position={[-15.4, 3.2, -70.07]}
            rotation={[-0.63, -0.93, 2.89]}
          />
          <mesh
            ref={ref10}
            geometry={nodes.扫描10.geometry}
            material={materials.信号线}
            position={[15.2, -50.9, -40.1]}
            rotation={[1.71, -0.06, -2.09]}
          />
          <mesh
            ref={ref9}
            geometry={nodes.扫描9.geometry}
            material={materials.信号线}
            position={[-40.82, -62.3, -9.3]}
            rotation={[-2.15, -0.59, 1.43]}
          />
          <mesh
            ref={ref8}
            geometry={nodes.扫描8.geometry}
            material={materials.信号线}
            position={[-61.97, -15.34, -28.32]}
            rotation={[-0.36, -0.46, 1.95]}
          />
          <mesh
            ref={ref7}
            geometry={nodes.扫描7.geometry}
            material={materials.信号线}
            position={[24.22, -45.62, 4.16]}
            rotation={[1.43, -0.11, 0.75]}
          />
          <mesh
            ref={ref5}
            geometry={nodes.扫描5.geometry}
            material={materials.信号线}
            position={[-27.15, 45.08, -17.52]}
            rotation={[-0.36, -0.46, 1.02]}
          />
          <mesh
            ref={ref4}
            geometry={nodes.扫描4.geometry}
            material={materials.信号线}
            position={[-8.22, 10.22, 86.94]}
            rotation={[-0.96, 0.21, -1.2]}
          />
          <mesh
            ref={ref3}
            geometry={nodes.扫描3.geometry}
            material={materials.信号线}
            position={[60.29, 14.32, 6.4]}
            rotation={[2.27, 0.19, 0.32]}
          />
          <mesh
            ref={ref2}
            geometry={nodes.扫描2.geometry}
            material={materials.信号线}
            position={[-12.38, 57.22, -6.3]}
            rotation={[-0.79, 0.52, 1.29]}
          />
          <mesh
            ref={ref1}
            geometry={nodes.扫描1.geometry}
            material={materials.信号线}
            position={[-32.19, -53.06, 28.28]}
            rotation={[0.46, 0.53, -2.37]}
          />
          <mesh
            ref={ref0}
            geometry={nodes.扫描.geometry}
            material={materials.信号线}
            position={[36.15, 2.24, -80.19]}
            rotation={[0, 1.27, -0.42]}
          />
        </group>
        <mesh
          geometry={nodes.Curve.geometry}
          material={materials.底座白色材质}
          position={[4.39, -2.65, -6.47]}
          rotation={[1.97, -0.79, 2.11]}
          scale={1137.36}
        />
        <mesh
          geometry={nodes.球体005.geometry}
          material={materials.主红色}
          position={[5.33, 2.29, -8.2]}
          rotation={[Math.PI / 2, 0, 1.94]}
          scale={[95.63, 94.03, 97.22]}
        />
      </group>
      <mesh
        geometry={nodes.立方体.geometry}
        material={materials.底座白色材质}
        position={[-8.5, 43.93, -0.78]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[116.15, 116.15, 15.41]}
      />
      <mesh
        geometry={nodes.柱体.geometry}
        material={materials.底座白色材质}
        position={[-245.48, -11.33, 250.65]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={100}
      />
      <mesh
        geometry={nodes.柱体001.geometry}
        material={materials.红玻璃}
        position={[-245.48, 0, 250.65]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={100}
      />
      <mesh
        geometry={nodes.圆环.geometry}
        material={materials.玻璃}
        position={[-8.45, 94.48, -1.92]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={30.86}
      />
      <mesh
        geometry={nodes.柱体002.geometry}
        material={materials.底座白色材质}
        position={[-7.45, 74.93, -2.09]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[74.48, 74.48, 35.19]}
      />
      <mesh
        geometry={nodes.锥体.geometry}
        material={materials.主红色}
        position={[-7.67, 101.59, -2.19]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[11.4, 11.4, 7.01]}
      />
    </group>
  );
};

useGLTF.preload('/assets/glbFile/earth.glb');
