import { ProColumns } from "@ant-design/pro-table";
import { Badge, Input, Select } from "antd";
import moment from "moment";
import React, { ReactNode } from "react";

export const statusOptions = [
    {label: '已读', value: 1}, 
    {label: '未读', value: 0},
]

/**
 * 列表表格配置:
 *  自定义表格 CustomerDemo 和 antd 升级版组件 ProTableDemo 都可使用, 
 * */
export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '消息标题',
            dataIndex: 'miTitle',
            key: 'miTitle',
            render: (text: any, record: any) => <div style={{cursor: 'pointer', color: record?.mrTime ? '#989898' : '#282828'}} onClick={() => operate && operate('读消息', record)}>
                {/* {record?.mrTime ? null : <Badge dot offset={[-5, -3]} />}  */}
                {record.miTitle}
                {/* <p>{moment(record.miTime).format('YYYY-MM-DD hh:mm')}</p> */}
                <p>{(record.miTime||'').slice(0, -3)}</p>
            </div>
        },
        {
            title: '状态',
            dataIndex: 'isReceive',
            key: 'isReceive',
            valueType: 'select',
            hideInTable: true,
            request: async () => { // 搜索条件下拉框数据，可枚举，也可从接口获取
                return statusOptions
            }
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            valueType: 'option',
            fixed: 'right',
            readOnly: true,
            hideInTable: true,
            render: (text: any, record: any) => [
                <a key="删除" style={{marginRight: 8}} onClick={() => operate('删除', record)}>删除</a>
            ]
        }
    ]
}