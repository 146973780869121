import { ProColumns } from "@ant-design/pro-table"
import { DateRangeWithDisabled, Icon } from "@components/Common"
import { smsTypeOptions } from "@utils/index"
import { Tooltip } from "antd"
import React from "react"

const taskStateOptions = [
    {
        label: '未执行',
        value: 0
    },
    {
        label: '执行中',
        value: 1
    },
    {
        label: '执行完成',
        value: 2
    },
    {
        label: '取消执行',
        value: 9
    },
]

const valueEnum = {
    0: { text: '未执行', status: 'Default' },
    1: { text: '执行中', status: 'Processing' },
    2: { text: '执行完成', status: 'Success' },
    9: { text: '取消执行', status: 'Error' },
}

export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: 'sendID',
            dataIndex: 'tiId',
            key: 'tiId',
            hideInSearch: true
        },
        {
            title: '任务状态',
            dataIndex: 'taskState',
            key: 'taskState',
            valueEnum,
            hideInSearch: true
        },
        {
            title: '发送时间',
            dataIndex: 'sendTime',
            key: 'sendTime',
            valueType: 'dateRange',
            fieldProps: { },
            search: {
                transform: (value) => {
                    return {
                        startTime: value[0] ? value[0] + ' 00:00:00' : undefined,
                        endTime: value[1] ? value[1] + ' 23:59:59' : undefined
                    }
                },
            },
            renderFormItem: (props) => <DateRangeWithDisabled />,
            render: (text: any, record: any) => record.sendTime
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            valueType: 'dateRange',
            fieldProps: { },
            search: {
                transform: (value) => {
                    return {
                        createStartTime: value[0] ? value[0] + ' 00:00:00' : undefined,
                        createEndTime: value[1] ? value[1] + ' 23:59:59' : undefined
                    }
                },
            },
            renderFormItem: (props) => <DateRangeWithDisabled />,
            render: (text: any, record: any) => record.createTime
        },
        {
            title: '发送总数',
            dataIndex: 'sendCount',
            key: 'sendCount',
            hideInSearch: true
        },
        {
            title: '短信类型',
            dataIndex: 'smsType',
            key: 'smsType',
            valueType: 'select',
            request: async () => smsTypeOptions.filter(v => v.value !== 4) // 军军说，不显示推广短信
        },
        {
            title: '成功数',
            dataIndex: 'sendSuccessCount',
            key: 'sendSuccessCount',
            hideInSearch: true
        },
        {
            title: '失败数',
            dataIndex: 'sendFailCount',
            key: 'sendFailCount',
            hideInSearch: true,
            render: (text: any, record: any) => <>
                 {record.sendFailCount}
                 {
                    record.sendFailCount > 0 ? <Tooltip title="下载失败号码">
                        <Icon type="icon-xiazai" style={{color: '#479ad0'}} onClick={() => operate('下载失败号码', record)} />
                    </Tooltip> : null
                 }
                 </>
        },
        {
            // title: '发送成功未回执',
            title: '发送成功且有回执', // 刘冲说改成这个
            dataIndex: 'noBack',
            key: 'noBack',
            width: 130,
            hideInSearch: true
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            valueType: 'option',
            fixed: 'right',
            render: (text: any, record: any) => [
                // <a key="任务详情" style={{marginRight: 8}} onClick={() => operate('任务详情', record)}>任务详情</a>,
                <a key="发送明细" onClick={() => operate('发送明细', record)}>发送明细</a>,
                [0].includes(record.taskState) && record.tiIsTiming === 1 ? <a key="取消发送" onClick={() => operate('取消发送', record)}>取消发送</a> : null,
            ]
        }
    ]
}
