import { BASEURL, cdnAllProviders, cdnDomainsOfCurrentUser } from "@api/index";
import { useCommonApiHooks } from "@hooks/index";
import { treeDataToFlatArr } from "@utils/index";
import { Checkbox, Col, DatePicker, Input, Menu, Radio, Row, Select } from "antd"
import moment from "moment";
import React, { useState } from "react"
import { ReactNode } from "react"
import ProForm, {
  ProFormUploadButton,
  ProFormTextArea,
  ProFormSelect
} from '@ant-design/pro-form';

const { RangePicker } = DatePicker;

const mockAreaData: any = []

const commonOptions = [{ label: '4小时', value: 4 * 60 }, { label: '8小时', value: 8 * 60 }]
const fiveMin = { label: '5分钟', value: 5 }
const oneHour = { label: '1小时', value: 60 }
const oneDay = { label: '1天', value: 24 * 60 }
export const calcUnitOptionsByDiff = (diff: any) => {
  if (diff < 2) {
    return [fiveMin, oneHour, ...commonOptions]
  } else if (diff >= 2 && diff <= 7) {
    return [fiveMin, oneHour, ...commonOptions, oneDay]
  } else if (diff > 7) {
    return [...commonOptions, oneDay]
  }
}

export const allDateRanges: any = {
    '今天': [moment(), moment()],
    '昨天': [moment().add(-1, 'days'), moment().add(-1, 'days')],
    '近7天': [moment().add(-7, 'days'), moment()],
    '近30天': [moment().add(-30, 'days'), moment()],
    '本周': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
    '上周': [moment().startOf('isoWeek').add(-7, 'days'), moment().endOf('isoWeek').add(-7, 'days')],
    '本月': [moment().startOf('month'), moment().endOf('month')],
    '上月': [moment().startOf('month').add(-1, 'month'), moment().endOf('month').add(-1, 'month')],
    '本季': [moment().startOf('quarter'), moment().endOf('quarter')],
    '上季': [moment().startOf('quarter').add(-1, 'quarter'), moment().endOf('quarter').add(-1, 'quarter')],
    '本年': [moment().startOf('year'), moment().endOf('year')],
    '上年': [moment().startOf('year').add(-1, 'year'), moment().endOf('year').add(-1, 'year')]
}

const initTime = () => ({ dateRange: allDateRanges['今天'], unit: 5, quickDate: '今天', })
export const originForm = () => ({ domain: [], areaRange: [], provider: [], time: initTime()})
export const handleSubmit = (data: any) => {
  const { domain: domains, areaRange: provinceCodes, provider: ispCodes, time } = data || {}
  const { dateRange, unit: gap } = time || initTime()
  return {
    domains,
    provinceCodes,
    ispCodes,
    gap,
    startTime: dateRange[0] ? dateRange[0].format('YYYY-MM-DD') + ' 00:00:00' : undefined,
    endTime: dateRange[1] ? dateRange[1].format('YYYY-MM-DD') + ' 23:59:59' : undefined
  }
}

const TimeCondition = ({value: pValue, onChange}: any) => {
    const [value, setValue] = useState<any>(initTime())
    const onOwnChange = (type: string, val: any) => {
        let newVal = value
        if (type === 'quickDate') {
            const dateRange = allDateRanges[val]
            const [startDate, endDate] = dateRange
            const diff = endDate && moment(endDate).diff(startDate, 'days')
            const unit = (calcUnitOptionsByDiff(diff) as any)[0].value
            newVal = {...value, quickDate: val, unit, dateRange}
        } else if (type === 'dateRange') {
            const [startDate, endDate] = val
            const diff = endDate && moment(endDate).diff(startDate, 'days')
            const quickDate = ({ 0: '今天', 1: '昨天', 7: '近7天', 30: '近30天' } as any)[diff]
            const unit = (calcUnitOptionsByDiff(diff) as any)[0].value
            newVal = {quickDate, dateRange: val, unit}
        } else if (type === 'unit') {
            newVal = {...value, unit: val}
        }
        onChange && onChange(newVal)
        setValue(newVal)
    }
    const calcUnitOptions = () => {
        const [startDate, endDate] = value.dateRange
        const diff = endDate && moment(endDate).diff(startDate, 'days')
        // console.log('xxxxx', diff )
        return calcUnitOptionsByDiff(diff)
    }
    return (<>
        <Radio.Group value={value.quickDate} onChange={(ev: any) => onOwnChange('quickDate', ev.target?.value)}>
            <Radio.Button value="今天">今天</Radio.Button>
            <Radio.Button value="昨天">昨天</Radio.Button>
            <Radio.Button value="近7天">近7天</Radio.Button>
            <Radio.Button value="近30天">近30天</Radio.Button>
        </Radio.Group>
        <RangePicker value={value.dateRange} onChange={(val: any) => onOwnChange('dateRange', val)} style={{marginLeft: 24}} />
        <Select value={value.unit} onChange={(ev: any) => onOwnChange('unit', ev)} options={calcUnitOptions()} style={{width: 120, marginLeft: 24}} />
    </>)
}

const mockFlatAreaArr = treeDataToFlatArr(mockAreaData)
const AreaSelectDropdownDender = ({value, onChange, areaArr}: any) => {
  const flatAreaArr = treeDataToFlatArr(areaArr)
  // console.log('xxxxxx', areaArr)
  const onMenuChange = (e: any) => {
      console.log('dddd', e)
  }
  const onOwnChange = (type: string, val: any) => {
      let areaRange = []
      if (type === 'all') {
        areaRange = val ? flatAreaArr.map(v => v.value) : []
      } else {
        areaRange = val
      }
      console.log('areaRange', areaRange)
      onChange && onChange(areaRange)
  }
  return (<Row style={{display: 'flex', flexWrap: 'nowrap'}}>
      <Menu default-selected-keys={['china']} onClick={onMenuChange}>
        <Menu.Item key="china">
          <a href="javascript:;">中国境内</a>
        </Menu.Item>
      </Menu>
      <div style={{boxSizing: 'border-box', padding: 10}}>
        <Row gutter={24} style={{display: 'flex', margin: '8px 0'}}>
          <Col>全选</Col>
          <Col><Checkbox onChange={(e) => onOwnChange('all', e.target.checked)}>全部地区</Checkbox></Col>
        </Row>
        <Checkbox.Group value={value} onChange={(val) => onOwnChange('checkItem', val)}>
          {
            areaArr.map((v: any, i: number) => (<Row key={i} gutter={24} style={{display: 'flex', margin: '8px 0'}}>
              <Col>{v.label}</Col>
              <Col>
                {v.children.map((ck: any) => <Checkbox key={ck.value} value={ck.value}>{ck.label}</Checkbox>)}
              </Col>
            </Row>))
          }
        </Checkbox.Group>
      </div>
    </Row>)
}

// 搜索条件配置
const colProps = { span: 8 }
const Label = (label: ReactNode) => <span style={{display: 'inline-block', width: 80}}>{label}</span>
export const searchConfig = [
    {
        label: Label('范围'),
        name: 'domain', // 提交查询的字段
        rules: [],
        colProps,
        style: {marginBottom: '8px'},
        Item: (props: any) => <ProFormSelect placeholder="域名" mode="multiple" request={async () => {
          const { data } = await cdnDomainsOfCurrentUser({}) as any
          return (data || []).map((v: any) => ({label: v, value: v}))
        }} {...props} />
    },
    {
        label: '',
        name: 'areaRange',
        rules: [],
        colProps,
        Item: (props: any) => {
          const [data,] = useCommonApiHooks({url: `${BASEURL}/web/cdn/analysis/getAllProvinces`, method: 'GET'}, {}, true)
          const flatAreaArr = treeDataToFlatArr(data||mockAreaData)
          return <Select 
              placeholder="区域" 
              mode="multiple" 
              maxTagCount={4} 
              dropdownMatchSelectWidth={false} 
              options={flatAreaArr}
              dropdownRender={() => <AreaSelectDropdownDender areaArr={data||mockAreaData} {...props} />} 
              {...props} 
          />
        }
    },
    {
        label: '',
        name: 'provider',
        rules: [],
        colProps,
        Item: (props: any) => <ProFormSelect placeholder="运营商" mode="multiple" request={async () => {
          const { data } = await cdnAllProviders({}) as any
          return data || []
        }} {...props} />
    },
    {
        label: Label('时间'),
        name: 'time',
        rules: [],
        // colProps: {span: 17},
        Item: (props: any) => <TimeCondition {...props} />
    },
]

