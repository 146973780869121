import request from "@utils/request";
import { fromData } from "@utils/util";
import { stringify } from "qs";
import { BASEURL, MockUrl } from "./baseUrl";
import { headers } from "./login";

// 获取用户信息
export const userInfo = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmGet`,
    headers,
    data: stringify({dvmControl: 'portalConsoleSafe:getUserInfo'}),
})

// 获取个人实人认证信息
export const userPersonCertificationInfo = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmGet`,
    headers,
    data: stringify({dvmControl: 'portalConsoleSafe:getPersonRealname'}),
})

// 个人实人认证提交
export const userPersonCertification = (data?: any) => request({
    url: `${BASEURL}/portal/realName/person`,
    headers,
    data: stringify({...data, dvmControl: 'userDataUserRealnameVerify:person'}),
})

// 获取企业实人认证信息
export const userCompanyCertificationInfo = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmGet`,
    headers,
    data: stringify({dvmControl: 'portalConsoleSafe:getEnterpriseRealname'}),
})

// 企业实人认证信息提交
export const userCompanyCertification = (data?: any) => request({
    url: `${BASEURL}/portal/realName/enterprise`,
    headers,
    data: stringify({...data, dvmControl: 'userDataUserRealnameVerify:enterprise'}),
})

// 设置邮箱
export const userSetEmail = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({dvmControl: 'portalConsoleSafe:setEmail', ...data}),
})

// 设置手机号
export const userSetPhone = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({dvmControl: 'portalConsoleSafe:setPhone', ...data}),
})

// 修改密码
export const userUpdatePwd  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({dvmControl: 'portalConsoleSafe:setPassword', ...data}),
}) 

// 获取用户已经购买产品
export const userBuyedPro  = (data?: object) => request({
    url:`${BASEURL}/web/product/getMyProductInfo`,
    // headers,
    data
}) 

// 设置余额报警
export const userSetMoneyLimit  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({dvmControl: 'userMoneyLimit:update', ...data}),
}) 

// 首页充值请求
export const userRecharge = (data?: any) => request({
    // url: `${BASEURL}/web/product/doRecharge`,
    // headers,
    // data: stringify({...data,}),
    url: `${BASEURL}/web/userBalance/recharge`,
    data
})


// 获取图片验证码
export const userGetImgCapcha  = (data?: object) => request({
    url:`${BASEURL}/web/manage/validateCode?${stringify(data)}`,
    headers,
    // data: stringify({...data}),
}) 

// 设置手机号
export const getRecommandProducts = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({dvmControl: 'portalShoppingIndex:getReference', ...data}),
})
