import { Tabs } from "antd"
import React, { useRef, useState } from "react"
import { UseStatistic, BackSourceStatistic, VisitStatistic, StatusCode } from './cpnts'

const items = [
    {
        label: '使用量统计',
        key: '1',
        children: <UseStatistic />
    },
    {
        label: '回源统计',
        key: '2',
        children: <BackSourceStatistic />
    },
    {
        label: '访问统计',
        key: '3',
        children: <VisitStatistic />
    },
    {
        label: '状态码',
        key: '4',
        children: <StatusCode />
    }
]

export default () => {
    return (<Tabs 
        defaultActiveKey="1" 
        items={items} 
        style={{background: '#fff', }}
        tabBarStyle={{padding: '0 20px', margin: 0}} 
    />)
}
