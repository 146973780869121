import React, { useEffect, useState } from "react"
import './index.less'
import { homeGetInfomationDetail } from "@api/home"
import { productApi, sliceText } from "@utils/util"
import { Pagination } from "antd"
import { getCostMyCloudPageList } from "@api/costCenter"
import { useHistory, useParams } from "react-router-dom"
import { fileHttpUrl } from "@api/baseUrl"
import { handleDvmCommonListResult } from "@utils/request"
import { Top_header } from "."
import { GoRegister } from "../PageIndex"
export { default as Details_index } from './DetailsIndex'
import { RightOutlined } from '@ant-design/icons';

const getInitialProps = async (params: any) => {
    const { result} = await homeGetInfomationDetail(params) as any
    const res = handleDvmCommonListResult(result)
    return res[0]
}
const NewsDetail = ({newsInfo: propsNewsInfo}: any) => {
    const [newsInfo, setNewsInfo] = useState(propsNewsInfo) as any
    const { newsId } = useParams() as any
    const getNewsInfoApi = async (params: any) => {
        const info = await getInitialProps(params)
        setNewsInfo(info)
    }
    useEffect(() => {
        newsId && getNewsInfoApi({pinId: newsId})
    }, [newsId])

    return (<div className="j_home_industryInformation">
        {/* 下半部分  咨询详情  html部分 */}
        <div className="newsDiv" >
            <a href="/home/industryInformation" style={{color: 'black'}}>行业资讯</a> <RightOutlined /> <span>{newsInfo?.pinTitle}</span>
            <div className="title mt-4">{newsInfo?.pinTitle}</div>
            <div className="time">{newsInfo?.updateTime}</div>
            <div className="html_div" dangerouslySetInnerHTML={{__html: newsInfo?.pinContent}}></div>
        </div>
    </div>)
}

NewsDetail.getInitialProps = getInitialProps

export default NewsDetail
