import { ProColumns } from "@ant-design/pro-table";
import { getProductOptions } from "@api/index";
import { Icon } from "@components/Common";
import { handleDvmCommonListResult } from "@utils/request";
import { parseSearch, stringifySearch } from "@utils/util";
import { Input, Select, Tooltip, Typography } from "antd";
import React, { ReactNode } from "react";
import { useHistory, useLocation } from "react-router-dom";

const { Paragraph } = Typography;
 
export const orderStatusOptions = [
    {
        label: '未支付',
        value: 0
    },
    {
        label: '支付中',
        value: 1,
        // hidden: true
    },
    {
        label: '已支付',
        value: 2
    },
    {
        label: '支付失败',
        value: 9,
        // hidden: true
    },
    {
        label: '已退款',
        value: 10
    },
]

const valueEnum = {
    0: { text: '未支付', status: 'Default' },
    1: { text: '支付中', status: 'Processing' },
    2: { text: '已支付', status: 'Success' },
    9: { text: '支付失败', status: 'Error' },
    10: { text: '已退款', status: 'Default' },
}

/**
 * 列表表格配置:
 *  自定义表格 CustomerDemo 和 antd 升级版组件 ProTableDemo 都可使用, 
 * */
export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    const history = useHistory()
    const { search } = useLocation();
    const { oiCode, ...rest } = parseSearch(search)
    oiCode &&  history.replace({search: stringifySearch({...rest})})
    return [
        {
            title: '订单号',
            dataIndex: 'oiCode',
            key: 'oiCode',
            width: 240,
            initialValue: oiCode,
            fixed: 'left',
            render: (text: any, record: any) => <Paragraph copyable style={{margin: 0}}>{text}</Paragraph>
        },
        {
            title: '产品名称',
            dataIndex: 'piName',
            key: 'piName',
            valueType: 'select', // ProTable 专用，表示当前组件是 select 类型的搜索条件
            fieldProps: {
                mode: 'multiple'
            },
            request: async () => { // 搜索条件下拉框数据，可枚举，也可从接口获取
                const {result} = await getProductOptions({}) as any
                const res = handleDvmCommonListResult(result)
                return res?.map(({piId: value, piName: label}: any) => ({label, value}))
            },
            search: {
                transform: (piIds) => {
                    return {
                        piIds: piIds.toString()
                    }
                },
            },
        },
        {
            // title: (config, type) => {
            //     if (type === 'form') return '订单状态'
            //     if (type === 'table') return <span>订单状态<Tooltip title={
            //         (<div>
            //             未支付：客户提交订单之后，未完成支付的状态。<br />
            //             已支付：客户提交订单并支付完成。<br />
            //             已退款：订单已支付完成，但执行了退款操作的状态。
            //         </div>)
            //     }><Icon type="icon-ziyuan" style={{marginLeft: 6}} /></Tooltip></span>
            // },
            title: '订单状态',
            dataIndex: 'oiOrderState',
            key: 'oiOrderState',
            valueType: 'select',
            fieldProps: {
                mode: 'multiple',
            },
            valueEnum,
            width: 120,
            // request: async() => orderStatusOptions.filter(v => !v.hidden),
            request: async() => orderStatusOptions,
            search: {
                transform: (oiOrderState) => {
                    return {
                        oiOrderState: oiOrderState.toString()
                    }
                },
            },
        },
        {
            title: '应付金额(元)',
            dataIndex: 'oiShouldPay',
            key: 'oiShouldPay',
            width: 120,
            hideInSearch: true,
        },
        {
            title: '实付金额(元)',
            dataIndex: 'oiActualPay',
            key: 'oiActualPay',
            width: 120,
            hideInSearch: true,
        },
        {
            title: '云朵抵扣(元)',
            dataIndex: 'oiCloudsPay',
            key: 'oiCloudsPay',
            width: 120,
            hideInSearch: true,
        },
        {
            title: '下单时间',
            dataIndex: 'orderTime',
            key: 'orderTime',
            valueType: 'dateRange',
            fieldProps: { },
            render: (text: any, record: any) => record.orderTime,
            width: 180,
            search: {
                transform: (value) => {
                    return {
                        startTime: value[0] ? value[0] + ' 00:00:00' : undefined,
                        endTime: value[1] ? value[1] + ' 23:59:59' : undefined
                    }
                },
            },
        },
        {
            title: '操作',
            dataIndex: 'option',
            key: 'option',
            valueType: 'option',
            fixed: 'right',
            width: 160,
            render: (text: any, record: any) => [
                [0].includes(record.oiOrderState) ? <a key="支付" style={{marginRight: '8px'}} onClick={() => operate('支付', record)}>支付</a> : null,
                <a key="详情" style={{marginRight: '8px'}} onClick={() => operate('详情', record)}>详情</a>,
                [0].includes(record.oiOrderState) ? <a key="删除" style={{marginRight: '8px'}} onClick={() => operate('删除', record)}>删除</a> : null,
            ]
        }
    ]
}