import React from 'react';
import { BASEURL, fileExportUrl } from "@api/baseUrl"
import { Button, Modal } from "antd"
import { useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { columnsFn } from "./data"
import ProTable, { ActionType } from "@ant-design/pro-table"
import { textCaptchaCustomPageList, textCaptchaCustomPageListDel, textCaptchaCustomPageListSave, textCaptchaUpdateUseState } from '@api/product';
import { acceptImg, productApi, toFormData } from '@utils/util';
import { PROCODE_TEXTCAPTCHA } from '@utils/enum';
import { ExportButton } from '@components/index';
import ProForm, { 
    ModalForm,
    ProFormText, 
    ProFormUploadButton, 
    ProFormSelect, 
    ProFormTextArea, 
    ProFormCascader,
    ProFormProps
} from '@ant-design/pro-form';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import UploadButton, { handleUploadFiles, handleUploadInitValue, UploadDragger } from "@components/Common/Upload"

// 接口请求的 api 层
const getApi = productApi(textCaptchaCustomPageList)  // 查询的接口封装
const delApi = productApi(textCaptchaCustomPageListDel)  // 查询的接口封装
const addUpdateApi = productApi(textCaptchaCustomPageListSave) // 列表数据的新增/编辑 接口
const updateUseStateApi = productApi(textCaptchaUpdateUseState) // 列表数据的新增/编辑 接口

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 16 },
};

interface AddFormProps extends ModalFormProps{
    submitParams?: any;
    onSuccess?: () => void;
}
const AddFormModal = ({onSuccess, submitParams, ...rest}: AddFormProps) => {
    const onFinish = async (values: any) => {
        console.log('表单要提交了，提交参数为:', values)
        const { fileList, ...restValues } = values
        const captchaUrl = handleUploadFiles(fileList)[0]
        const params = {...restValues, captchaUrl, ...submitParams }
        addUpdateApi(params, true, onSuccess)
    }
    return (<ModalForm<any>
        layout="horizontal"
        {...layout}
        onFinish={onFinish}
        {...rest}>
        <ProFormText
            width="md"
            name="captchaName"
            label="添加字符"
            rules={[{ required: true, message: '请填写添加字符'}]}
            placeholder="请填写添加字符"
            extra={<>
                1、仅支持四个字符（中英文、特殊字符、数字、符号图案）；<br />
                2、可同时录入多组四字字符，每四个字符中间以英文逗号分隔；（申请成功后随机使用）<br />
                3、不支持重复字符（特殊字符未做限制）；
            </>}
        />
        <UploadButton
          width="md"
          name="fileList"
          label="字符背景"
          rules={[{required: true, message: '请上传字符背景图'}]}
          fieldProps={{
            accept: acceptImg,
            maxCount: 1,
          }}
          extra={<>
            1、图片大小不超过5MB，图片格式支持 JPG，PNG，BMP，GIF <br />
            2、仅支持上传一张文字背景（图片尺寸270×270展示效果较好）。<br/>
            3、上传后经平台审核后方可使用。
          </>}
        />
        <ProFormTextArea
          rules={[{ required: false, message: '请输入申请说明' }]}
          placeholder='请输入申请说明'
          name="applyDescribe"
          label="申请说明"
          fieldProps={{
              allowClear: true,
          }}
        />
    </ModalForm>)
}


// 在线发送
export default () => {
    const history = useHistory()
    const formRef = useRef()
    const otherParams =  {productCode: PROCODE_TEXTCAPTCHA}
    const dispatch = useDispatch()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [modalProps, setModalProps] = useState<any>({ visible: false, title: '新增', params: {}, initialValues: {} })
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    // operate 函数为增删改查需要处理的业务
    const operate = async (type: any, record: any) => {
        const { id, captchaName, captchaUrl, applyDescribe } = record || {}
        if (['新增', '编辑'].includes(type)) {
            setModalProps({
                visible: true,
                title: type,
                params: { id },
                submitParams: id ? { id } : {},
                initialValues: {
                    captchaName,
                    fileList: handleUploadInitValue(captchaUrl),
                    applyDescribe,
                }
            })
        } else if (type === '使用') {
            const ids = record ? [id] : selectedRowKeys
            Modal.confirm({
                title: '确认要使用该数据吗?',
                content: '',
                okText: '确认',
                centered: true,
                cancelText: '取消',
                onOk: async() => {
                    await updateUseStateApi({id, useState: 1}, true, () => {
                        console.log('数据删除成功, 可以执行回调了')
                        reFresh()
                    })
                },
            });
        } else if (type === '删除') {
            const ids = record ? [id] : selectedRowKeys
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                centered: true,
                cancelText: '取消',
                onOk: async() => {
                    await delApi({ids}, true, () => {
                        console.log('数据删除成功, 可以执行回调了')
                        reFresh()
                    })
                },
            });
        }
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({ ...params, }, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }
    // const getListApi = handleDvmCommonPageListResult(getApi)

    const columns = columnsFn(operate) // 表格列配置
    const getQueryParams = () => ({
        // @ts-ignore
        ...formRef.current?.getFieldsFormatValue(),
        ...otherParams
    })

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        setModalProps({
        ...modalProps,
        visible: false,
        })
        reFresh()
    }
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }

    const calcDelDisabled = () => selectedRowKeys.length
    return (<>
        <ProTable<any>
            style={{ flex: 1, overflow: 'scroll' }}
            scroll={{ x: 1200 }}
            columns={columns}
            params={otherParams} // 查询所需要的额外参数
            request={getListApi}
            formRef={formRef as any}
            options={{ reload: false, density: false, setting: false }}
            toolbar={{
                actions: [
                    // <ExportButton key="export" type='primary' action={`${BASEURL}/web/product/exportCardOperatorList`} param={getQueryParams} btnText="导出" />,
                    <Button key="新增" type='primary' onClick={() => operate('新增', null)}>新增</Button>,
                    <Button key="删除" type='primary' disabled={!calcDelDisabled()} onClick={() => operate('删除', null)}>删除</Button>,
                ],
            }}
            rowKey="id"
            pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
            actionRef={ref as any}
            search={{
                optionRender: (searchConfig, formProps, dom) => [...dom.reverse()]
            }}
            rowSelection={{
                preserveSelectedRowKeys: true,
                selectedRowKeys,
                onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
            }}
        />
        <AddFormModal
            modalProps={{
                maskClosable: false, 
                destroyOnClose: true,
                onCancel: () => setModalProps({...modalProps, visible: false})
            }}
            onSuccess={onSuccess}
            {...modalProps} 
        />
    </>)
}

