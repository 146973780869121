import request from "@utils/request";
import { fromData } from "@utils/util";
import { stringify } from "qs";
import { BASEUPLOADURL, BASEURL, MockUrl } from "../baseUrl";
import { headers } from "../login";

// const BASEURL = ''

/****************空号检测******************/ 
// 在线检测结果
export const emptyCheckResult  = (data?: object) => request({
    url:`${BASEURL}/web/emptyCheck/cleanResult`,
    // headers,
    data
})

// 清洗记录
export const emptyCheckRecord  = (data?: object) => request({
    url:`${BASEURL}/web/emptyCheck/cleanRecords`,
    // headers,
    data
})

// 上传清洗文件
export const emptyCheckUpload  = (data?: object) => request({
    url:`${BASEURL}/web/emptyCheck/uploadEmptyCheckFile`,
    // headers,
    data: fromData({ ...data})
})

// 清洗记录删除
export const emptyCheckRecodDel  = (data?: object) => request({
    url:`${BASEURL}/web/emptyCheck/recordDel`,
    // headers,
    method: 'GET',
    data,
})

// 计费反馈
export const emptyCheckFeeFeedbackPageList  = (data?: object) => request({
    url:`${BASEURL}/web/product/getFeeReturn`,
    // headers,
    data
})

// 计费反馈--实时检测
export const realTimeCheckFeeFeedbackPageList  = (data?: object) => request({
    url:`${BASEURL}/web/product/getFeeReturn`,
    // headers,
    data
})
