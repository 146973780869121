import { Alert, AlertProps } from "antd"
import React, { useState } from "react"
import {
    InfoCircleFilled
} from '@ant-design/icons';
import Ellipsis from "../Ellipsis";
import Icon from "../Icon";

type DescriptionProps = {
    title?: any;
    listTips?: Array<any>;
}
export const Description = ({title, listTips}: DescriptionProps) => {
    return (<ul style={{listStyle: 'disc',marginBottom: 0}}>
        <InfoCircleFilled className="text-lg mr-2" style={{color: '#FAAD14'}}/>{title || '温馨提示:'}<br/>
        {
            listTips?.map((v, i) => (<li key={i} style={{marginLeft: 40}}>{v}</li>))
        }
    </ul>)
}

interface AlertTipProps extends AlertProps{
    title?: any;
    listTips?: Array<any>;
}

const AlertTip = ({listTips, title, ...rest}: AlertTipProps) => {
    const [expand, setExpand] = useState(false)
    const arr = listTips ? expand ? listTips : [<Ellipsis.Nowrap key="0">{listTips[0]}</Ellipsis.Nowrap>] : undefined
    const description = <Description title={title} listTips={arr} />
    const action = (listTips || []).length > 1 ? <a onClick={() => setExpand(!expand)}>{expand ? '收起' : '展开'} <Icon style={{fontSize: 12}} type={expand ? 'icon-shouqi' : 'icon-zhankai2'} /></a> : null
    const ownProps = listTips ? { description, action } : {}
    return (<Alert
        {...ownProps}
        {...rest}
    />)
}

AlertTip.Description = Description

export default AlertTip