import { ProColumns } from "@ant-design/pro-table"
import { StateType } from "@store/index"
import { setWindowHref } from "@utils/index"
import { Breadcrumb } from "antd"
import React from "react"
import { useSelector } from "react-redux"

const replaceKeyword = (text: any, keyword: string) => (text||'').replace(new RegExp(keyword,'g'), `<span style="color:#ff4d4f">${keyword}</span>`)
const getPathById = (idStr: String, allArr: any[]) => {
    const ids = idStr.split('#').filter(Boolean)
    const paths = ids.map((id: any) => (allArr||[]).find((v: any) => {
        return v.docId === id * 1
    }))
    return paths
}

export const columnsFn: (keyword?: string) => ProColumns<any>[] = (keyword: any) => {
    const { treeData: memoTreeData, flatArr, allLeafArr } = useSelector((state: StateType) => state).docTreeData
    return [
        {
            title: '流水号',
            dataIndex: 'serialNumber',
            hideInSearch: true,
            render: (text: any, record: any) => {
                return (<div style={{cursor: 'pointer'}}>
                    <div dangerouslySetInnerHTML={{__html: replaceKeyword(record.docTitle, keyword)}} onClick={() => setWindowHref(`/home/doc/${record.docId}?docId=${record.docId}`)}></div>
                    <div dangerouslySetInnerHTML={{__html: replaceKeyword(record.docContentWord, keyword)}} onClick={() => setWindowHref(`/home/doc/${record.docId}?docId=${record.docId}`)}></div>
                    <div style={{display: 'flex', marginTop: 10}}>
                        <span style={{color: '#989898', marginRight: 8}}>来自:</span>
                        <Breadcrumb>
                            {
                                getPathById(record.docTid, flatArr).map((v: any) => <Breadcrumb.Item key={v?.docId}>
                                <a onClick={() => setWindowHref(`/home/doc/${record.docId}?docId=${record.docId}`)} dangerouslySetInnerHTML={{__html: replaceKeyword(v?.docTitle, keyword)}}></a>
                             </Breadcrumb.Item>)
                            }
                        </Breadcrumb>
                    </div>
                </div>)
            }
        }
    ]
}
