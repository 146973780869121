import { ProColumns } from "@ant-design/pro-table"
import { smsSignPageList, smsTempPageList } from "@api/index"
import { DateRangeWithDisabled, Ellipsis, Icon } from "@components/Common"
import { statusOptions, valueEnum } from "@components/MyProduct/GlobalSMS/SendDetailed/data"
import { StateType } from "@store/index"
import { parseSearch, productApi } from "@utils/index"
import { handleDvmCommonPageListResult } from "@utils/request"
import { Tooltip } from "antd"
import moment from "moment"
import React from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

const getSignApi = productApi(smsSignPageList)  // 查询的接口封装
const getSignListApi = handleDvmCommonPageListResult(getSignApi)

const getTempApi = productApi(smsTempPageList)  // 查询的接口封装
const getTempListApi = handleDvmCommonPageListResult(getTempApi)

export const ispValueEnum = {
    1: { text: '电信', },
    2: { text: '移动', },
    3: { text: '联通', },
    9: { text: '其他', }
}

const detailTypeOptions = [
    {
        label: '任务明细',
        value: 1
    },
    {
        label: 'Api 明细',
        value: 2
    }
]

export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    const { pathname, search } = useLocation();
    const { tiId, taskOrApiDetails, sendTime, createTime } = parseSearch(search)
    const allCountry = useSelector((state: StateType) => state?.allCountry) as any
    const initTime = sendTime ? [moment(createTime), moment(sendTime)] : [moment().add(0, 'days'), moment()]
    return [
        {
            title: '明细类型',
            dataIndex: 'taskOrApiDetails',
            key: 'taskOrApiDetails',
            valueType: 'select',
            fieldProps: {
                allowClear: false
            },
            formItemProps: {
                initialValue: taskOrApiDetails ? Number(taskOrApiDetails) : 2,  
            },
            request: async() => detailTypeOptions,
            hideInTable: true
        },
        {
            title: '签名',
            dataIndex: 'usCode',
            key: 'usCode',
            valueType: 'select',
            request: async() => {
                const { data } = await getSignListApi({pageSize: 1000}, {}, {})
                return (data||[]).map(({usName: label, usCode: value}: any) => ({label, value}))
            },
            hideInTable: true
        },
        {
            title: '模版',
            dataIndex: 'utlCode',
            key: 'utlCode',
            valueType: 'select',
            request: async() => {
                const { data } = await getTempListApi({pageSize: 1000, utlSmsArea: 1}, {}, {})
                return (data||[]).map(({utlName: label, utlCode: value}: any) => ({label, value}))
            },
            hideInTable: true
        },
        {
            title: '任务ID',
            dataIndex: 'tiId',
            key: 'tiId',
            hideInSearch: true
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '短信内容',
            dataIndex: 'smsContent',
            key: 'smsContent',
            // hideInSearch: true,
            render: (text: any, record: any) => <Ellipsis.Nowrap width={300}>{`${(record.smsContent||'').toString()}`}</Ellipsis.Nowrap> 
        },
        {
            // title: '字数 | 拆分条数 ',
            title: () => <span>字数 | 拆分条数 <Tooltip title={(<div>
                短信字数＝短信模版内容字数 + 签名字数 <br/>
                短信字数≤70个字数，按照70个字数一条短信计算 <br/>
                <div dangerouslySetInnerHTML={{__html: '短信字数>70个字数，即为长短信，按照67个字数'}}></div>
                记为一条短信计算。客户感知还是一条短信。<br/>

                注意：在统计短信字数时，小数点、字母、汉字以及其 <br/>
                他符号、空格均按照一个字符位进行统计。最终按照实 <br/>
                际发送成功的短信条数计费。 <br/>
            </div>)}><Icon type="icon-ziyuan" style={{marginLeft: 6}} /></Tooltip></span>,
            dataIndex: 'word',
            key: 'word',
            // tooltip: (<div>
            //     短信字数＝短信模版内容字数 + 签名字数 <br/>
            //     短信字数≤70个字数，按照70个字数一条短信计算 <br/>
            //     短信字数&gt70个字数，即为长短信，按照67个字数 <br/>
            //     记为一条短信计算。客户感知还是一条短信。<br/>

            //     注意：在统计短信字数时，小数点、字母、汉字以及其 <br/>
            //     他符号、空格均按照一个字符位进行统计。最终按照实 <br/>
            //     际发送成功的短信条数计费。 <br/>
            // </div>),
            hideInSearch: true,
            render: (text: any, record: any) => `${record.word||0}字 | ${record.row||0}条`
        },
        {
            title: '发送时间',
            dataIndex: 'sendTime',
            key: 'sendTime',
            valueType: 'dateRange',
            fieldProps: { },
            formItemProps: {
                initialValue: initTime,  
            },
            search: {
                transform: (value) => {
                    return {
                        startTime: value[0] ? value[0] + ' 00:00:00' : undefined,
                        endTime: value[1] ? value[1] + ' 23:59:59' : undefined,
                        tiId
                    }
                },
            },
            renderFormItem: (props) => <DateRangeWithDisabled />,
            render: (text: any, record: any) => record.sendTime
        },
        {
            title: '运营商',
            dataIndex: 'operatorType',
            key: 'operatorType',
            hideInSearch: true,
            valueEnum: ispValueEnum,
        },
        // 君君说说先去掉 2023.2.24 15:58
        // {
        //     title: '归属地',
        //     dataIndex: 'address',
        //     key: 'address',
        //     hideInSearch: true
        // },
        {
            title: '发送状态',
            dataIndex: 'sendStatus',
            key: 'sendStatus',
            valueType: 'select',
            valueEnum,
            request: async () => statusOptions
        },
        {
            title: '失败原因',
            dataIndex: 'failCause',
            key: 'failCause',
            hideInSearch: true,
            render: (text: any, record: any) => <Ellipsis.Nowrap width={200}>{`${(record.failCause||'').toString()}`}</Ellipsis.Nowrap> 
        },
        // {
        //     title: '操作',
        //     dataIndex: 'operate',
        //     key: 'operate',
        //     valueType: 'option',
        //     render: (text: any, record: any) => [
        //         // <a key="详情" style={{marginRight: 8}} onClick={() => operate('详情', record)}>详情</a>,
        //         // <a key="删除" onClick={() => operate('删除', record)}>删除</a>,
        //     ]
        // }
    ]
}
