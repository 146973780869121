import React, { lazy, ReactNode, useEffect } from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { KeepaliveRouterSwitch ,KeepaliveRoute ,addKeeperListener } from 'react-keepalive-router'
import { 
    isDef, 
    parseSearch, 
    PROCODE_BANKCARDFOURELEMENT, 
    PROCODE_BANKCARDTHREEELEMENT, 
    PROCODE_FACECOMPARE, 
    PROCODE_IDTWOELEMENT, 
    PROCODE_PHONETHREEELEMENT, 
    PROCODE_GLOBALSMS, 
    PROCODE_SMS, 
    PROCODE_FACEDISTINGUISH, 
    PROCODE_BANKCARDADDRESS, 
    PROCODE_PHONETWOELEMENT, 
    PROCODE_BANKCARDTWOELEMENT,
    PROCODE_BANKCARDOCR,
    PROCODE_WITNESSCOMPARE,
    PROCODE_PORTRAITCOMPARE,
    PROCODE_HACK,
    PROCODE_LIVECHECK,
    PROCODE_STATICLIVECHECK
} from '@utils/index';

import {
    Login,
    Register,
    ForgetPassWord,
    NotFond,
    Authorized,
    Test,
    BasicLayout,
    NoPermission,
    Home,
    CostCenter,
    MyOrder,
    PaymentDetail,
    MyClouds,
    Charge,
    Tools,
    PhoneShunt,
    IPWhiteList,
    AccountCenter,
    HomeLayOut,
    HomePartner,
    HomeAboutUs,
    HomeIndustryInformation,
    DetailsIndex,
    Activities_61,
    // Activities,
    Activities_CDN,
    HomePage,
    HomeProduct,
    HomeCooperation,
    HomeProPrice,
    HomePurchase,
    HomeOrderSubmit,
    HomeShoppingCar,
    OverView,
    MyProduct,
    OrderDetail,
    CloudDetail,
    SafeSetting,
    Certification,
    SMS,
    GlobalSMSSendStatistics,
    GlobalSMSSendDetailed,
    GlobalSMSTemplateManage,
    GlobalSMSSendOnline,
    Protocol,
    ChangeLog,
    CertificateAdmin,
    DomainAdmin,
    DomainAdminConfig,
    DomainRefresh,
    DomainStatistical,
    ResourceManage,
    WebSiteMessage,
    TaskList,
    HomeDoc,
    DocLayout,
    DocDetail,
    DocDetailForRoute,
    DocDetailDefault,
    BaseLayout,
    Activities_Preferred,
    ActivitiesCustom,
} from '@components/index'

import {
    GlobalSMS,
    IDTwoElements,
    PhoneThreeElements,
    BankCardThreeElements,
    BankCardFourElements,
    FaceCompare,
    BankCardAddress,
    PhoneAp
} from '@components/MyProduct';
import APISecretKey from '@components/MyProduct/SMS/ApplySetting/cpnts/APISecretKey';
import SMSOverView from '@components/MyProduct/SMS/OverView'
import { SignatureManage, TemplateManage, SendOnline, MailList } from '@components/MyProduct/SMS/Manage'
import { SendDetailed, DataStatistics, PkgList, ReplyRecord } from '@components/MyProduct/SMS/Statistics'
import { DataPush, UpperSetting, BlackList } from '@components/MyProduct/SMS/ApplySetting'
import { HardWork } from '@components/Common/404';
import { IDTwoElementCallDetail } from '@components/MyProduct/IDTwoElements';
import { PhoneThreeElementCallDetail } from '@components/MyProduct/PhoneThreeElements';
import { BankCardThreeElementCallDetail } from '@components/MyProduct/BankCardThreeElements';
import { BankCardFourElementCallDetail } from '@components/MyProduct/BankCardFourElements';
import { FaceCompareCallDetail } from '@components/MyProduct/FaceCompare';
import { FaceDistinguishCallDetail } from '@components/MyProduct/FaceDistinguish';
import FaceDistinguish from '@components/MyProduct/FaceDistinguish';
import { MessageDetail } from '@components/AccountCenter/Message';
import { ConsumeBill, PkgManage } from '@components/CostCenter';
import { BankCardAddressCallDetail } from '@components/MyProduct/BankCardAddress';

import { IDCardOCRCallDetail } from '@components/MyProduct/IDCardOCR';
import { BankCardOCRCallDetail } from '@components/MyProduct/BankCardOCR';
import { WitnessCompareCallDetail } from '@components/MyProduct/WitnessCompare';
import { PortraitCompareCallDetail } from '@components/MyProduct/PortraitCompare'
import { HackCallDetail } from '@components/MyProduct/Hack';
import { LivingTestCallDetail } from '@components/MyProduct/LivingTest';
import { EmptyCheckCheckOnline, EmptyCheckFeeFeedback } from '@components/MyProduct/EmptyCheck';
import { RealTimeCheckFeeFeedback, RealTimeCheckOnline } from '@components/MyProduct/RealTimeCheck';
import { EnterpriseFourElementCallDetail } from '@components/MyProduct/EnterpriseFourElements'
import { PhoneTwoElementCallDetail } from '@components/MyProduct/PhoneTwoElements';
import { BankCardTwoElementCallDetail } from '@components/MyProduct/BankCardTwoElements';
import { StaticLivingTestCallDetail } from '@components/MyProduct/StaticLivingTest';
import { DynamicLivingTestCallDetail } from '@components/MyProduct/DynamicLivingTest';
import { HarassBlackListCallDetail } from '@components/MyProduct/HarassBlacklist';
import { OnlineDurationCallDetail } from '@components/MyProduct/OnlineDuration';
import { WoolShieldTagCallDetail } from '@components/MyProduct/WoolShieldTag';
import { TransferNetworkCallDetail } from '@components/MyProduct/TransferNetwork';
import { CustomTextCaptcha, TextCaptchaCallDetail } from '@components/MyProduct/TextCaptcha';
import { PhoneAPCallDetail, PhoneAPCheckOnline } from '@components/MyProduct/PhoneAP';


export type MenuItem = {
    path: string; // 路由
    exact?: boolean;
    render?: () => ReactNode;
    parentPath?: string; // 所属父级菜单
    name?: ReactNode | string; // 菜单名称
    component?: ReactNode; // 路由组件
    custComponent?: ReactNode;  // 自定义缓存路由组件
    cacheId?: () => string | string; // 缓存组件的 id
    hideInMenu?: Boolean; // 是否显示在菜单栏
    noCache?: Boolean; // 是否不缓存该组件
    icon?: ReactNode | string; // 菜单icon
    authCode?: string | number; // -1 为常量权限(不受角色控制)
    children?: MenuItem[]
}

/************************* 产品路由 start ************************/
// cdn 内容加速
const CDNRouters: MenuItem[] = [
    {
        path: '/product/cdn/domian',
        name: '域名管理',
        authCode: -1,
        component: DomainAdmin
    },
    {
        path: '/product/cdn/domianConfig',
        name: '配置管理',
        authCode: -1,
        hideInMenu: true,
        component: DomainAdminConfig
    },
    {
        path: '/product/cdn/certificate',
        name: '证书管理',
        authCode: -1,
        component:  CertificateAdmin
    },
    {
        path: '/product/cdn/resourceWatch',
        name: '资源监控',
        authCode: -1,
        component: DomainStatistical
    },
    {
        path: '/product/cdn/refresh',
        name: '刷新预热',
        authCode: -1,
        component:  DomainRefresh
    },
    {
        path: '/product/cdn/resourcePkg',
        name: '资源包管理',
        authCode: -1,
        component:  ResourceManage
    },
]

// 短信服务--old
// const SMSRouters: MenuItem[] = [
//     {
//         path: '/product/sms/send',
//         name: '发送短信',
//         authCode: PROCODE_SMS,
//         component: SMSSend
//     },
//     {
//         path: '/product/sms/setting',
//         name: '短信设置',
//         authCode: PROCODE_SMS,
//         component: SMSSetting,
//     },
//     {
//         path: '/product/sms/statistics',
//         name: '短信统计',
//         authCode: PROCODE_SMS,
//         component: SMSStatistic
//     }
// ]

// 短信服务--new
const SMSRouters: MenuItem[] = [
  {
    path: '/product/sms/overView',
    name: '概览',
    authCode: PROCODE_SMS,
    component: SMSOverView,
  },
  {
    path: '/product/sms/manage',
    name: '短信管理',
    authCode: PROCODE_SMS,
    component: SMS,
    children: [
        {
            path: '/product/sms/manage/sign',
            name: '签名管理',
            authCode: -1,
            component: SignatureManage
        },
        {
            path: '/product/sms/manage/template',
            name: '模版管理',
            authCode: -1,
            component: TemplateManage
        },
        {
            path: '/product/sms/manage/sendOnLine',
            name: '在线群发',
            authCode: -1,
            component: SendOnline
        },
        {
            path: '/product/sms/manage/contactList',
            name: '通讯录',
            authCode: -1,
            component: MailList
        },
    ]
  },
  {
      path: '/product/sms/statistics',
      name: '业务统计',
      authCode: PROCODE_SMS,
      component: SMS,
      children: [
        {
            path: '/product/sms/statistics/sendDetail',
            name: '发送明细',
            authCode: -1,
            component: SendDetailed
        },
        {
            path: '/product/sms/statistics/data',
            name: '数据统计',
            authCode: -1,
            component: DataStatistics
        },
        {
            path: '/product/sms/statistics/pkgList',
            name: '套餐包列表',
            authCode: -1,
            component: PkgList
        },
        {
            path: '/product/sms/statistics/replay',
            name: '回复记录',
            authCode: -1,
            component: ReplyRecord
        },
      ]
  },
  {
      path: '/product/sms/applySetting',
      name: '应用设置',
      authCode: PROCODE_SMS,
      component: SMS,
      children: [
          {
              path: '/product/sms/applySetting/push',
              name: '推送设置',
              authCode: -1,
              component: DataPush
          },
          {
              path: '/product/sms/applySetting/upLimit',
              name: '上限设置',
              authCode: -1,
              component: UpperSetting
          },
          {
              path: '/product/sms/applySetting/blackList',
              name: '黑名单管理',
              authCode: -1,
              component: BlackList
          }
      ]
  }
]


// 国际短信
const GlobalSMSRouters: MenuItem[] = [
    {
        path: '/product/globalSms/send',
        name: '在线发送',
        authCode: PROCODE_GLOBALSMS,
        component: GlobalSMSSendOnline
    },
    {
        path: '/product/globalSms/template',
        name: '模版管理',
        authCode: PROCODE_GLOBALSMS,
        component: GlobalSMSTemplateManage
    },
    {
        path: '/product/globalSms/sendDetail',
        name: '发送明细',
        authCode: PROCODE_GLOBALSMS,
        component: GlobalSMSSendDetailed
    },
    {
        path: '/product/globalSms/sendStatistic',
        name: '发送统计',
        authCode: PROCODE_GLOBALSMS,
        component: GlobalSMSSendStatistics
    }
]

// 身份证二要素
const IDTwoElementRouters: MenuItem[] = [
    {
        path: '/product/idTwoElement/callDetail',
        name: '调用明细',
        authCode: PROCODE_IDTWOELEMENT,
        component: IDTwoElementCallDetail
    },
]

// 身份证ocr
const IDCardOCRRouters: MenuItem[] = [
    {
        path: '/product/idCardOCR/callDetail',
        name: '调用明细',
        // authCode: PROCODE_IDTWOELEMENT,
        authCode: -1,
        component: IDCardOCRCallDetail
    },
]

// 手机号二要素
const PhoneTwoElementRouters: MenuItem[] = [
    {
        path: '/product/phoneTwoElement/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_PHONETHREEELEMENT,
        authCode: -1,
        component: PhoneTwoElementCallDetail
    },
]

// 手机号三要素
const PhoneThreeElementRouters: MenuItem[] = [
    {
        path: '/product/phoneThreeElement/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_PHONETHREEELEMENT,
        authCode: -1,
        component: PhoneThreeElementCallDetail
    },
]

// 银行卡三要素
const BankCardTwoElementRouters: MenuItem[] = [
    {
        path: '/product/bankcardTwoElement/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_BANKCARDTHREEELEMENT,
        authCode: -1,
        component: BankCardTwoElementCallDetail
    },
]

// 银行卡三要素
const BankCardThreeElementRouters: MenuItem[] = [
    {
        path: '/product/bankcardThreeElement/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_BANKCARDTHREEELEMENT,
        authCode: -1,
        component: BankCardThreeElementCallDetail
    },
]

// 银行卡四要素
const BankCardFourElementRouters: MenuItem[] = [
    {
        path: '/product/bankcardFourElement/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_BANKCARDFOURELEMENT,
        authCode: -1,
        component: BankCardFourElementCallDetail
    },
]

// 银行卡归属地
const BankCardAddressRouters: MenuItem[] = [
    {
        path: '/product/bankcardAddress/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_BANKCARDFOURELEMENT,
        authCode: -1,
        component: BankCardAddressCallDetail
    },
]

// 银行卡OCR
const BankCardOCRRouters: MenuItem[] = [
    {
        path: '/product/bankcardOCR/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_BANKCARDFOURELEMENT,
        authCode: -1,
        component: BankCardOCRCallDetail
    },
]

// 人脸比对
const FaceCompareRouters: MenuItem[] = [
    {
        path: '/product/faceCompare/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_FACECOMPARE,
        authCode: -1,
        component: FaceCompareCallDetail
    },
]

// 人脸识别
const FaceDistinguishRouters: MenuItem[] = [
    {
        path: '/product/faceDistinguish/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_FACECOMPARE,
        authCode: -1,
        component: FaceDistinguishCallDetail
    },
]

// 人证比对
const WitnessCompareRouters:  MenuItem[] = [
    {
        path: '/product/witnessCompare/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_FACECOMPARE,
        authCode: -1,
        component: WitnessCompareCallDetail
    },
]

// 人像比对
const PortraitCompareRouters:  MenuItem[] = [
    {
        path: '/product/portraitCompare/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_FACECOMPARE,
        authCode: -1,
        component: PortraitCompareCallDetail
    },
]

// 防hack
const HackRouters:  MenuItem[] = [
    {
        path: '/product/hack/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_FACECOMPARE,
        authCode: -1,
        component: HackCallDetail
    },
]

// 活体检测
const LivingTestRouters:  MenuItem[] = [
    {
        path: '/product/livingTest/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_FACECOMPARE,
        authCode: -1,
        component: LivingTestCallDetail
    },
]

// 静态活体检测
const StaticLivingTestRouters:  MenuItem[] = [
    {
        path: '/product/staticLivingTest/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_FACECOMPARE,
        authCode: -1,
        component: StaticLivingTestCallDetail
    },
]

// 动态活体检测
const DynamicLivingTestRouters:  MenuItem[] = [
    {
        path: '/product/dynamicLivingTest/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_FACECOMPARE,
        authCode: -1,
        component: DynamicLivingTestCallDetail
    },
]

/************************* 产品路由 end ************************/

// 空号检测
const EmptyCheckRouters: MenuItem[] = [
    {
        path: '/product/emptyCheck/checkOnline',
        name: '在线检测',
        // authCode: PROTYPE_FACECOMPARE,
        authCode: -1,
        component: EmptyCheckCheckOnline
    },
    {
        path: '/product/emptyCheck/feeFeedback',
        name: '计费反馈',
        // authCode: PROTYPE_FACECOMPARE,
        authCode: -1,
        component: EmptyCheckFeeFeedback
    },
]

// 实时检测
const RealTimeCheckRouters: MenuItem[] = [
    {
        path: '/product/realTimeCheck/checkOnline',
        name: '在线检测',
        // authCode: PROTYPE_FACECOMPARE,
        authCode: -1,
        component: RealTimeCheckOnline
    },
    {
        path: '/product/realTimeCheck/feeFeedback',
        name: '计费反馈',
        // authCode: PROTYPE_FACECOMPARE,
        authCode: -1,
        component: RealTimeCheckFeeFeedback
    },
]

// 企业四要素
const EnterpriseFourElementRouters: MenuItem[] = [
    {
        path: '/product/enterpriseFourElement/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_BANKCARDFOURELEMENT,
        authCode: -1,
        component: EnterpriseFourElementCallDetail
    },
]

// 防骚扰黑名单
const HarassBlacklistRouters: MenuItem[] = [
    {
        path: '/product/harassBlacklist/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_BANKCARDFOURELEMENT,
        authCode: -1,
        component: HarassBlackListCallDetail
    },
]

// 羊毛盾标签版
const WoolShieldTagRouters: MenuItem[] = [
    {
        path: '/product/woolShieldTag/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_BANKCARDFOURELEMENT,
        authCode: -1,
        component: WoolShieldTagCallDetail
    },
]

// 号码在网时长
const OnlineDurationRouters: MenuItem[] = [
    {
        path: '/product/onlineDuration/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_BANKCARDFOURELEMENT,
        authCode: -1,
        component: OnlineDurationCallDetail
    },
]

// 携号转网
const TransferNetworkRouters: MenuItem[] = [
    {
        path: '/product/transferNetwork/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_BANKCARDFOURELEMENT,
        authCode: -1,
        component: TransferNetworkCallDetail
    },
]

// 文字验证码
const TextCaptchaRouters: MenuItem[] = [
    {
        path: '/product/textCaptcha/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_BANKCARDFOURELEMENT,
        authCode: -1,
        component: TextCaptchaCallDetail
    },
    {
        path: '/product/textCaptcha/custom',
        name: '自定义',
        // authCode: PROTYPE_BANKCARDFOURELEMENT,
        authCode: -1,
        component: CustomTextCaptcha
    },
]

// 号码活跃度检测
const PhoneAPRouters: MenuItem[] = [
    {
        path: '/product/phoneAP/checkOnline',
        name: '在线检测',
        // authCode: PROTYPE_FACECOMPARE,
        authCode: -1,
        component: PhoneAPCheckOnline
    },
    {
        path: '/product/phoneAP/callDetail',
        name: '调用明细',
        // authCode: PROTYPE_BANKCARDFOURELEMENT,
        authCode: -1,
        component: PhoneAPCallDetail
    },
]

// 我的产品
const ProductRouters: MenuItem[] = [
    {
        path: '/product/cdn',
        name: 'CDN',
        authCode: -1,
        hideInMenu: true,
        component: BaseLayout,
        children: [ ...CDNRouters ]
    },
    {
        path: '/product/sms',
        name: '国内短信',
        authCode: PROCODE_SMS,
        hideInMenu: true,
        component: BaseLayout,
        children: [ ...SMSRouters ]
    },
    {
        path: '/product/globalSms',
        name: '国际短信',
        authCode: PROCODE_GLOBALSMS,
        hideInMenu: true,
        component: BaseLayout,
        children: [ ...GlobalSMSRouters ]
    },
    {
        path: '/product/idTwoElement',
        name: '身份证二要素认证',
        authCode: PROCODE_IDTWOELEMENT,
        hideInMenu: true,
        component: BaseLayout,
        children: [ ...IDTwoElementRouters ]
    },
    {
        path: '/product/idCardOCR',
        name: '身份证识别',
        authCode: -1,
        hideInMenu: true,
        component: BaseLayout,
        children: [ ...IDCardOCRRouters ]
    },
    {
        path: '/product/phoneTwoElement',
        name: '手机号二要素',
        authCode: PROCODE_PHONETWOELEMENT,
        // authCode: -1,
        hideInMenu: true,
        component: BaseLayout,
        children: [ ...PhoneTwoElementRouters ]
    },
    {
        path: '/product/phoneThreeElement',
        name: '手机号三要素',
        authCode: PROCODE_PHONETHREEELEMENT,
        hideInMenu: true,
        component: BaseLayout,
        children: [ ...PhoneThreeElementRouters ]
    },
    {
        path: '/product/bankcardTwoElement',
        name: '银行卡二要素',
        authCode: PROCODE_BANKCARDTWOELEMENT,
        // authCode: -1,
        hideInMenu: true,
        component: BaseLayout,
        children: [ ...BankCardTwoElementRouters ]
    },
    {
        path: '/product/bankcardThreeElement',
        name: '银行卡三要素',
        authCode: PROCODE_BANKCARDTHREEELEMENT,
        hideInMenu: true,
        component: BaseLayout,
        children: [ ...BankCardThreeElementRouters ]
    },
    {
        path: '/product/bankcardFourElement',
        name: '银行卡四要素',
        authCode: PROCODE_BANKCARDFOURELEMENT,
        hideInMenu: true,
        component: BaseLayout,
        children: [ ...BankCardFourElementRouters ]
    },
    {
        path: '/product/bankcardOCR',
        name: '银行卡识别',
        authCode: PROCODE_BANKCARDOCR,
        hideInMenu: true,
        component: BaseLayout,
        children: [ ...BankCardOCRRouters ]
    },
    {
        path: '/product/bankcardAddress',
        name: '银行卡归属地',
        authCode: PROCODE_BANKCARDADDRESS,
        hideInMenu: true,
        component: BaseLayout,
        children: [ ...BankCardAddressRouters ]
    },
    {
        path: '/product/faceCompare',
        name: '人脸比对',
        authCode: PROCODE_FACECOMPARE,
        hideInMenu: true,
        component: BaseLayout,
        children: [ ...FaceCompareRouters ]
    },
    {
        path: '/product/faceDistinguish',
        name: '人脸识别',
        authCode: PROCODE_FACEDISTINGUISH,
        hideInMenu: true,
        component: BaseLayout,
        children: [ ...FaceDistinguishRouters ]
    },
    {
        path: '/product/witnessCompare',
        name: '人证比对',
        authCode: PROCODE_WITNESSCOMPARE,
        hideInMenu: true,
        component: BaseLayout,
        children: [ ...WitnessCompareRouters ]
    },
    {
        path: '/product/portraitCompare',
        name: '人像比对',
        authCode: PROCODE_PORTRAITCOMPARE,
        hideInMenu: true,
        component: BaseLayout,
        children: [ ...PortraitCompareRouters ]
    },
    {
        path: '/product/hack',
        name: '防hack',
        authCode: PROCODE_HACK,
        hideInMenu: true,
        component: BaseLayout,
        children: [ ...HackRouters ]
    },
    {
        path: '/product/livingTest',
        name: '活体检测',
        authCode: -1,
        hideInMenu: true,
        component: BaseLayout,
        children: [ ...LivingTestRouters ]
    },
    {
        path: '/product/staticLivingTest',
        name: '静态活体检测',
        authCode: PROCODE_STATICLIVECHECK,
        hideInMenu: true,
        component: BaseLayout,
        children: [ ...StaticLivingTestRouters ]
    },
    {
        path: '/product/dynamicLivingTest',
        name: '动态活体检测',
        authCode: -1,
        hideInMenu: true,
        component: BaseLayout,
        children: [ ...DynamicLivingTestRouters ]
    },
    {
        path: '/product/emptyCheck',
        name: '空号检测',
        authCode: -1,
        hideInMenu: true,
        component: FaceDistinguish,
        children: [ ...EmptyCheckRouters ]
    },
    {
        path: '/product/realTimeCheck',
        name: '实时检测',
        authCode: -1,
        hideInMenu: true,
        component: FaceDistinguish,
        children: [ ...RealTimeCheckRouters ]
    },
    {
        path: '/product/enterpriseFourElement',
        name: '企业四要素',
        authCode: -1,
        hideInMenu: true,
        component: FaceDistinguish,
        children: [ ...EnterpriseFourElementRouters ]
    },
    {
        path: '/product/harassBlacklist',
        name: '防骚扰黑名单',
        authCode: -1,
        hideInMenu: true,
        component: FaceDistinguish,
        children: [ ...HarassBlacklistRouters ]
    },
    {
        path: '/product/woolShieldTag',
        name: '羊毛盾标签版',
        authCode: -1,
        hideInMenu: true,
        component: FaceDistinguish,
        children: [ ...WoolShieldTagRouters ]
    },
    {
        path: '/product/onlineDuration',
        name: '号码在网时长',
        authCode: -1,
        hideInMenu: true,
        component: FaceDistinguish,
        children: [ ...OnlineDurationRouters ]
    },
    {
        path: '/product/transferNetwork',
        name: '携号转网',
        authCode: -1,
        hideInMenu: true,
        component: FaceDistinguish,
        children: [ ...TransferNetworkRouters ]
    },
    {
        path: '/product/textCaptcha',
        name: '文字验证码',
        authCode: -1,
        hideInMenu: true,
        component: FaceDistinguish,
        children: [ ...TextCaptchaRouters ]
    },
    {
        path: '/product/phoneAP',
        name: '号码活跃度检测',
        authCode: -1,
        hideInMenu: true,
        component: PhoneAp,
        children: [ ...PhoneAPRouters ]
    },
    {
        path: '/product/wip',
        name: '敬请期待',
        authCode: -1,
        hideInMenu: true,
        component: HardWork,
    }
]

// 实验室路由模块
const TestRouters: MenuItem[] = [
    {
        path: '/home',
        name: '首页',
        authCode: -1,
        component: Home
    }
]

// 总览
const OverViewRouters: MenuItem[] = [
    {
        path: '/overview',
        name: '总览',
        authCode: -1,
        component: OverView
    }
]

// 费用中心
const CostRouters: MenuItem[] = [
    {
        path: '/cost',
        name: '费用中心',
        authCode: -1,
        component: BaseLayout,
        children: [
            {
                path: '/cost/myorder',
                name: '我的订单',
                authCode: -1,
                component: MyOrder
            },
            {
                path: '/cost/consumeBill',
                name: '消费账单',
                authCode: -1,
                component: ConsumeBill
            },
            {
                path: '/cost/orderDetail',
                name: '订单详情',
                authCode: -1,
                hideInMenu: true,
                parentPath: '/cost/myorder',
                component: OrderDetail
            },
            {
                path: '/cost/paymentDetail',
                name: '收支明细',
                authCode: -1,
                component: PaymentDetail
            },
            // {
            //     path: '/cost/charge',
            //     name: '账户充值',
            //     authCode: -1,
            //     component: Charge
            // },
            {
                path: '/cost/pkg',
                name: '套餐包管理',
                authCode: -1,
                component: PkgManage
            },
            {
                path: '/cost/myClouds',
                name: '我的云朵',
                authCode: -1,
                component: MyClouds
            },
            {
                path: '/cost/cloudDetail',
                name: '云朵详情',
                authCode: -1,
                hideInMenu: true,
                parentPath: '/cost/myClouds',
                component: CloudDetail
            }
        ]
    }
]

// 便捷工具
const ToolRouters: MenuItem[] = [
    {
        path: '/tools',
        name: '便捷工具',
        authCode: -1,
        component: BaseLayout,
        children: [
            {
                path: '/tools/phoneShunt',
                name: '号码分流',
                authCode: -1,
                component: PhoneShunt
            }
        ]
    }
]

// 用户账号中心
const AccountRouters: MenuItem[] = [
    {
        path: '/account',
        name: '账号中心',
        hideInMenu: true,
        authCode: -1,
        component: BaseLayout,
        children: [
            {
                path: '/account/safeSetting',
                name: '安全设置',
                authCode: -1,
                component: SafeSetting
            },
            {
                path: '/account/certification',
                name: '实名认证',
                authCode: -1,
                component: Certification
            },
            {
                path: '/account/apiCall',
                name: 'API调用密钥',
                authCode: -1,
                component: APISecretKey
            },
            {
                path: '/account/ipWhiteList',
                name: 'IP白名单',
                authCode: -1,
                component: IPWhiteList
            },
            {
                path: '/account/taskList',
                name: '导出记录',
                authCode: -1,
                hideInMenu: true,
                component: TaskList
            },
            {
                path: '/account/message',
                name: '站内消息',
                authCode: -1,
                // hideInMenu: true,
                component: WebSiteMessage
            },
            {
                path: '/account/messageDetail',
                name: '消息详情',
                hideInMenu: true,
                authCode: -1,
                parentPath: '/account/message',
                component: MessageDetail
            }
        ]
    }
]

// 项目菜单数据(生成菜单用)
export const MenuRouter: MenuItem[] = [
    // ...TestRouters,
    ...OverViewRouters,
    ...ProductRouters,
    ...CostRouters,
    ...ToolRouters,
    ...AccountRouters
]

// 项目所有路由表
export const dynamicConfig = [
    ...OverViewRouters,
    ...ProductRouters,
    ...CostRouters,
    ...ToolRouters,
    ...AccountRouters
]

const staticRouters = [
  { path: '/protocol', component: Protocol },
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '/forgetPassWord', component: ForgetPassWord },
  { path: '/noPermission', component: NoPermission },
  { path: '/404', component: NotFond },
  { path: '/test', component: Test },
  { path: '/changelog', component: ChangeLog },
  { path: '/proPrice', component: HomeProPrice },
  { path: '/shopping/purchase', component: HomePurchase },
  { path: '/shopping/orderSubmit', component: HomeOrderSubmit },
  { path: '/shoppingCar', component: HomeShoppingCar },
]

const homeRouters = [
  { path: '/home/index', component: HomePage },
  { path: '/home/aboutUs', component: HomeAboutUs },
  { path: '/home/activities', component: Activities_61 },
  { path: '/home/activities_CDN', component: Activities_CDN },
  { path: '/home/partner', component: HomePartner },
  { path: '/home/product', component: HomeProduct },
  { path: '/home/cooperation', component: HomeCooperation },
  {
    path: '/home/doc',
    component: DocLayout,
    children: [
      { path: '/home/doc/:docId', component: DocDetailForRoute },
    ]
  },
  {
    path: '/home/industryInformation',
    component: HomeIndustryInformation,
    children: [
      { path: '/home/industryInformation/:newsId', component: DetailsIndex },
    ]
  }
]

export const allRouters = [
    // ...staticRouters,
    // {
    //   path: '/home',
    //   component: HomeLayOut,
    //   children: [...homeRouters]
    // },
    {
      path: '',
      component: Authorized,
      children: [ ...dynamicConfig ]
    }
]

type RouterTreeProps = {
    config: any[]
}
const RouterTree = ({config}: RouterTreeProps) => {
   return (<>
    {
        (config||[]).map((route: any, idx: number) => {
            const { children, component: Component, noCache, custComponent, cacheId: calcCacheId, redirect, path } = route
            const RouterView = children && <RouterTree config={children} />
            const cacheId = (!isDef(calcCacheId) || typeof calcCacheId === 'string') ? (calcCacheId || route.path) : calcCacheId()
            return children ? <Component key={path}>{RouterView}</Component> : true ? <Route {...route} key={path} /> : <KeepaliveRoute {...route} cacheId={cacheId} key={path} />
        })
    }
   </>)
}

export default () => {
  return (<KeepaliveRouterSwitch withoutRoute>
    <Switch>
        {/* 项目静态路由(不需要权限管控) */}
        <Route path={'/protocol'} component={Protocol}></Route>
        <Route path={'/login'} component={Login}></Route>
        <Route path={'/register'} component={Register}></Route>
        <Route path={'/forgetPassWord'} component={ForgetPassWord}></Route>
        <Route path={'/noPermission'} component={NoPermission}></Route>
        <Route path={'/404'} component={NotFond}></Route>
        <Route path={'/test'} component={Test}></Route>
        <Route path={'/changelog'} component={ChangeLog}></Route>
        <Route path={'/home'}>
            <HomeLayOut>
                <Route path={'/home/index'} component={HomePage}></Route>
                <Route path={'/home/aboutUs'} component={HomeAboutUs}></Route>
                <Route path={'/home/industryInformation'}>
                    <HomeIndustryInformation>
                       <Route path={'/home/industryInformation/:newsId'} component={DetailsIndex} />
                    </HomeIndustryInformation>
                </Route>
                <Route path={'/home/activities_CDN'} component={Activities_CDN}></Route>
                <Route path={'/home/activities'} component={Activities_61}></Route>
                <Route path={'/home/Activities_Preferred'} component={Activities_Preferred}></Route>
                <Route path={'/home/activitiesCustom'} component={ActivitiesCustom}></Route>
                <Route path={'/home/partner'} component={HomePartner}></Route>
                <Route path={'/home/product'} sensitive component={HomeProduct}></Route>
                <Route path={'/home/cooperation'} component={HomeCooperation}></Route>
                <Route path={'/home/doc'}>
                    <DocLayout>
                       <Route path={'/home/doc/:docId'} component={DocDetailForRoute} />
                    </DocLayout>
                </Route>
            </HomeLayOut>
        </Route>
        <Route path={'/proPrice'} component={HomeProPrice}></Route>
        <Route path={'/shopping/purchase'} component={HomePurchase}></Route>
        <Route path={'/shopping/orderSubmit'} component={HomeOrderSubmit}></Route>
        <Route path={'/shoppingCar'} component={HomeShoppingCar}></Route>

        {/* 动态菜单(需要权限管控的) */}
        <Route>
            <Authorized>
               <RouterTree config={dynamicConfig} />
            </Authorized>
        </Route>
        <Redirect from="*" to="/404" />
    </Switch>
</KeepaliveRouterSwitch>)
}
