import { StateType } from "@store/index";
import { useSelector } from "react-redux";

import servicePlan1 from '@/assets/images/home_servicePlan/1.png';
import servicePlan2 from '@/assets/images/home_servicePlan/2.png';
import servicePlan3 from '@/assets/images/home_servicePlan/3.png';
import servicePlan4 from '@/assets/images/home_servicePlan/4.png';
import servicePlan5 from '@/assets/images/home_servicePlan/5.png';
import servicePlan6 from '@/assets/images/home_servicePlan/6.png';
import servicePlan7 from '@/assets/images/home_servicePlan/7.png';
import servicePlan8 from '@/assets/images/home_servicePlan/8.png';
import r1 from '@/assets/images/home_servicePlan/r1.png';
import r2 from '@/assets/images/home_servicePlan/r2.png';
import r3 from '@/assets/images/home_servicePlan/r3.png';
import r4 from '@/assets/images/home_servicePlan/r4.png';
import r5 from '@/assets/images/home_servicePlan/r5.png';
import r6 from '@/assets/images/home_servicePlan/r6.png';
import r7 from '@/assets/images/home_servicePlan/r7.png';
import r8 from '@/assets/images/home_servicePlan/r8.png';
export const stepList = () => {
    const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
    return [
        {
            number: '01',
            icon: 'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng0a9887245d62d64333df4b6aa9e0223ef5670c442453578641cea9ec65220f90',
            name: `注册${ueSaasName}账号`,
            btnText: '免费注册',
            onClick: () => {
            }
        },
        {
            number: '02',
            icon: 'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng0a9887245d62d64333df4b6aa9e0223ef5670c442453578641cea9ec65220f90',
            name: '信息实名认证',
            btnText: '去认证',
            onClick: () => {
            }
        },
        {
            number: '03',
            icon: 'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng0a9887245d62d64333df4b6aa9e0223ef5670c442453578641cea9ec65220f90',
            name: '开通/购买产品',
            btnText: '前去购买',
            onClick: () => {
            }
        },
        {
            number: '04',
            icon: 'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng0a9887245d62d64333df4b6aa9e0223ef5670c442453578641cea9ec65220f90',
            name: '控制台',
            btnText: '立即前往',
            onClick: () => {
            }
        }
    ]
}

export const servicePlan = [
    {
        typeName: '新零售行业',
        title: '新零售行业服务方案',
        children: [
            '线上营销智能推荐ALRec',
            '大促数据库优化',
            '对分支组网',
            '数仓智慧导购',
            '物联网设备消息通信'
        ],
        imgUrl: servicePlan1,
        rImgUrl: r1,
        customerStories: ''
    },
    {
        typeName: '物流行业',
        title: '物流行业服务方案',
        children: [
            '网络货运平台',
            '智慧仓储',
            '智能配送'
        ],
        imgUrl: servicePlan8,
        rImgUrl: r8,
        customerStories: ''
    }, 
    {
        typeName: '制造行业',
        title: '制造行业服务方案',
        children: [
            '智能制造系统上云',
            '工业互联网混合云',
            '数据安全容灾备份',
            '应用安全'
        ],
        imgUrl: servicePlan2,
        rImgUrl: r2,
        customerStories: ''
    },
    {
        typeName: '电商行业',
        title: '电商行业服务方案',
        children: [
            '秒杀大促',
            '跨境电商',
            '直播带货'
        ],
        imgUrl: servicePlan3,
        rImgUrl: r3,
        customerStories: ''
    },
    {
        typeName: '医疗行业',
        title: '医疗行业服务方案',
        children: [
            '系统、影像上云',
            '医疗软件提供商',
            '互联网医院',
            '远程探视'
        ],
        imgUrl: servicePlan4,
        rImgUrl: r4,
        customerStories: ''
    },
    {
        typeName: '教育行业',
        title: '教育行业服务方案',
        children: [
            '在线教育',
            '智慧校园'
        ],
        imgUrl: servicePlan5,
        rImgUrl: r5,
        customerStories: ''
    },
    {
        typeName: '游戏行业',
        title: '游戏行业服务方案',
        children: [
            '安全防护',
            '资源弹性扩展',
            '全球化网络与分布式部署',
            '云数据解决方案',
            '大数据存储与计算能力'
        ],
        imgUrl: servicePlan6,
        rImgUrl: r6,
        customerStories: ''
    },
    {
        typeName: '交通行业',
        title: '交通行业服务方案',
        children: [
            '共享单车/电动车',
            '城市公共交通',
            '网约车',
            '智慧停车'
        ],
        imgUrl: servicePlan7,
        rImgUrl: r7,
        customerStories: ''
    }
]