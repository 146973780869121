import { Tabs } from "antd"
import React from "react"
import { SendAnalysis, ArrivedAnalysis, FailAnalysis } from './cpnts'

const items = [
    {
        label: '发送量统计',
        key: '1',
        children: <SendAnalysis />
    },
    {
        label: '到达率统计',
        key: '2',
        children: <ArrivedAnalysis />
    },
    {
        label: '失败分析',
        key: '3',
        children: <FailAnalysis />
    }
]

export default () => {
    return (<Tabs 
        defaultActiveKey="1" 
        items={items} 
        tabBarStyle={{background: '#fff', padding: '0 20px', margin: 0}} 
    />)
}
