import React, { useRef, useState } from "react"
import { useTableHooks } from "@hooks/index"
import { columnsFn } from './data'
import { Alert, Button, Col, Modal, Row, Table } from "antd"
import { productApi } from "@utils/index"
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home"
import { ActionType, ProTable } from "@ant-design/pro-table"
import { useHistory } from "react-router-dom"
import { getCostMyCloudPageList } from "@api/index"
import { stringify } from "qs"
import {
    InfoCircleFilled
  } from '@ant-design/icons';
import { Description } from "@components/Common/AlertTip"
import { useSelector } from "react-redux"
import { StateType } from "@store/index"
// 列表操作的 类型校验
type Action = '详情' | '修改' | '删除'

// 接口请求的 api 层
const getApi = productApi(getCostMyCloudPageList)  // 查询的接口封装
const addUpdateApi = productApi(homeTableAddUpdate) // 列表数据的新增/编辑 接口
const delApi = productApi(homeTableDel) // 表单数据的删除 接口

export default () => {
    const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
    const history = useHistory()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [modalProps, setModalProps] = useState({visible: false, title: '新增', params: {}})
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    // operate 函数为增删改查需要处理的业务
    const operate = (type: Action, record: any) => {
        const { aciId, useStatusStr } = record || {}
        if (type === '详情') {
            history.push(`/cost/cloudDetail?${stringify({aciId, useStatusStr})}`)
            console.log('需要处理的新增业务')
        } else if (type === '删除') {
            const ids = record ? [aciId] : selectedRowKeys
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                centered: true,
                cancelText: '取消',
                onOk: async() => {
                    await delApi({ids}, true, () => {
                        console.log('数据删除成功, 可以执行回调了')
                        reFresh()
                    })
                },
            });
        }
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params}, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const columns = columnsFn(operate) // 表格列配置

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        console.log('处理后的成功回调触发了')
        setModalProps({
            ...modalProps,
            visible: false
        })
        reFresh()
    }
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const calcDelDisabled = () => selectedRowKeys.length
    return (<>
        <div style={{padding: '20px 20px 0 20px', background:'#fff'}}>
            <Alert
            description={<Description title="云朵规则说明：" listTips={[
                `云朵是${ueSaasName}平台赠送给用户的虚拟币，一个云朵相当于1元人民币，可用于购买平台的产品。`,
                '购买产品时会先行抵扣云朵，不足部分使用余额进行支付。',
                '云朵无法转让、提现。'
            ]} />}
            type="warning"
            />
        </div>
        <ProTable<any>
            columns={columns}
            params={{}} // 查询所需要的额外参数
            request={getListApi}
            options={{reload: false, density: false, setting: false}}
            toolbar={{
                actions: [],
            }}
            rowKey="id"
            pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
            actionRef={ref as any}
            search={{
                optionRender: (searchConfig, formProps, dom) => [ ...dom.reverse() ]
            }}
            // rowSelection={{
            //     preserveSelectedRowKeys: true,
            //     onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
            // }}
        />
    </>)
}
