import { Button, Card, CardProps, Radio, RadioGroupProps, Row, Spin } from "antd"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Line } from '@ant-design/plots';
import { columnsFn, mockData } from "./data";
import ProTable, { ActionType } from "@ant-design/pro-table"
import { ExportButton, SearchBox } from "@components/index";
import { handleSubmit, originForm, searchConfig } from "../Search/data";
import { BASEURL, cdnAnalysisResult, cdnGraph } from "@api/index";
import { toFixedNoRound } from "@utils/index";

const options = [
    {
        label: '流量',
        value: 1,
    },
    {
        label: '带宽',
        value: 2,
    },
]

const baseLineConfig = {
    data: mockData,
    // padding: 'auto',
    xField: 'time',
    yField: 'num',
    xAxis: {
        // type: 'timeCat',
        // tickCount: 5,
    },
    yAxis: {
        // minLimit: 1,
        grid: {
            line: {
              style: {
                stroke: '#ddd',
                lineDash: [4, 2],
              },
            },
            // alternateColor: 'rgba(0,0,0,0.05)',
        },
    }
}

const RadioGroupButton = ({options, ...rest}: RadioGroupProps) => {
    return (<Radio.Group {...rest}>
        {options?.map((v: any) => <Radio.Button key={v.value} value={v.value}>{v.label}</Radio.Button>)}
    </Radio.Group>)
}
interface CommonCardLineChartProps extends CardProps {
    title?: any;
    options?: any[];
    yTitle?: any;
    lineConfig?: any;
    value: any[];
    onChange?: (val: any) => void;
}
export const CommonCardLineChart = ({title, options, yTitle, value, onChange, lineConfig: lconfig, ...rest}: CommonCardLineChartProps) => {
    const extra = options ? <RadioGroupButton options={options} defaultValue={options[0].value} onChange={onChange} /> : null
    const lineConfig = {...baseLineConfig, ...(lconfig||{}), data: value}
    return (<Card title={title} extra={extra} {...rest}>
        <span>{yTitle}</span>
        <Line {...lineConfig} style={{height: 200}} />
    </Card>)
}

export const Actions = ({onClick, children}: any) => {
    return (<Row justify="end" style={{padding: '0 20px'}}>
        <Button style={{marginRight: 10}} onClick={() => onClick && onClick('刷新')}>刷新</Button>
        {/* <Button type="primary" onClick={() => onClick && onClick('导出')}>导出</Button> */}
        {children}
    </Row>)
}

export default ()=> {
    const [queryParam, setQueryParam] = useState(originForm())
    const [radioValue, setRadioValue] = useState(1)
    const [chartData, setChartData] = useState(mockData)
    const [chartData2, setChartData2] = useState(mockData)
    const [loading, setLoading] = useState<boolean>(false)
    const onSearch = async (params: any) => {
        setLoading(true)
        setQueryParam(params)
        const requestParams = {...handleSubmit(params), graphType: radioValue, type: 1}
        const { data: chartData } = await cdnGraph(requestParams) as any
        const { data: chartData2 } = await cdnGraph({...requestParams, graphType: 3}) as any
        setChartData(chartData)
        setChartData2(chartData2)
        setLoading(false)
    }
    const onChange = async (type: any, val: any) => {
        setLoading(true)
        if (type === '图表') {
            setRadioValue(val)
            const requestParams = {...handleSubmit(queryParam), graphType: val, type: 1}
            const { data: chartData } = await cdnGraph(requestParams) as any
            setChartData(chartData)
        } else if (type === '刷新') {
            const requestParams = {...handleSubmit(queryParam), graphType: radioValue, type: 1}
            const { data: chartData } = await cdnGraph(requestParams) as any
            const { data: chartData2 } = await cdnGraph({...requestParams, graphType: 3}) as any
            setChartData(chartData)
            setChartData2(chartData2)
        }
        setLoading(false)
    }

    const onValuesChange = (values: any, allValues: any) => {
        if ('time' in values) {
            onSearch(allValues)
        }
    }

    useEffect(() => {
        onSearch({})
    }, [])

    const getQueryParams = () => {
        const requestParams = {...handleSubmit(queryParam), graphType: radioValue, type: 1}
        return requestParams
    }

    const calcYTitle = () => radioValue === 1 ? `总流量: ${toFixedNoRound(chartData.reduce((prev, next: any) => prev + next.num, 0), 2)}(mb)` : `宽带峰值: ${Math.max(...chartData.map((v: any) => v.num))}(kbit/s)`
    return (<>
      <SearchBox config={searchConfig} onFinish={onSearch} onValuesChange={onValuesChange} onReset={onSearch} />
      <Actions onClick={onChange}>
        <ExportButton key="export" type='primary' action={`${BASEURL}/web/cdn/analysis/export`} param={getQueryParams} btnText="导出" />
      </Actions>
      <div style={{padding: 20}}>
        <Spin spinning={loading}>
        <CommonCardLineChart
           title="访问流量/带宽"
           yTitle={calcYTitle()}
           options={options}
           value={chartData}
           lineConfig={{
                meta: {
                    num: { alias: radioValue === 1 ? '流量' : '宽带' },
                }
           }}
           onChange={(ev) => onChange && onChange('图表', ev.target?.value)}
        />
        <CommonCardLineChart
           title="流量命中率"
           yTitle="命中率(%)"
           value={chartData2}
           style={{marginTop: 20}}
           lineConfig={{
                meta: {
                    num: { alias: '命中率'},
                }
           }}
        />
        <div style={{margin: '20px 0'}}>域名流量/宽带使用详情</div>
        <ProTable<any>
            className="j-antd-protable-nopadding"
            columns={columnsFn(() => null)}
            params={{...handleSubmit(queryParam), type: 1}} // 查询所需要的额外参数
            request={async(params: any, sorter: any, filter: any) => {
                const { data } = await cdnAnalysisResult(params) as any
                const { domainUsageItems } = data
                return {
                    data: domainUsageItems || [],
                    success: true,
                    total: domainUsageItems.length,
                };
            }}
            options={{ reload: false, density: false, setting: false }}
            toolbar={{
                actions: [],
            }}
            search={false}
            pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
        />
        </Spin>
      </div>
    </>)
}
