import React, { useEffect, useMemo, useRef, useState } from "react"
import { useCommonApiHooks, useTableHooks } from "@hooks/index"
import { columnsFn } from './data'
import { Alert, Button, Checkbox, Col, Input, Modal, Row, Table } from "antd"
import { parseSearch, productApi, setWindowHref } from "@utils/index"
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home"
import { ActionType, ProTable } from "@ant-design/pro-table"
import { useHistory, useLocation } from "react-router-dom"
import { getCostMyCloudPageList } from "@api/index"
import { stringify } from "qs"
import { msgGetPageList, msgDel, msgMarkRead, msgAllMarkRead, msgDetail } from '@api/message'
import {
    InfoCircleFilled
  } from '@ant-design/icons';
import { Description } from "@components/Common/AlertTip"
import { handleDvmCommonListResult, handleDvmCommonPageListResult, handleDvmCommonResult } from "@utils/request"
import { useDispatch } from "react-redux"
import { asyncSetMsgInfo } from "@store/actions"
import moment from "moment"
// 列表操作的 类型校验
type Action = '详情' | '修改' | '删除' | '标为已读' | '全部标为已读' | '读消息'

const { TextArea } = Input;

// 接口请求的 api 层
const getApi = productApi(msgGetPageList)  // 查询的接口封装
const addUpdateApi = productApi(msgMarkRead) // 列表数据的新增/编辑 接口
const addUpdateAllApi = productApi(msgAllMarkRead) // 列表数据的新增/编辑 接口
const delApi = productApi(msgDel) // 表单数据的删除 接口
const getListApi = handleDvmCommonPageListResult(getApi)
const getMsgDetailApi = productApi(msgDetail)

const MegDetail = ({msgId}: any) => {
    const [msgInfo, setMsgInfo] = useState<any>({})
    const getDetailApi = async (params: any) => {
        const { result } = await getMsgDetailApi(params)
        const [ msgInfo ] = handleDvmCommonListResult(result)
        setMsgInfo(msgInfo)
        addUpdateApi({mrIds: [msgId].toString()})
    }
    useEffect(() => {
        getDetailApi({mrId: msgId})
    }, [msgId])
    return (<div style={{boxSizing: 'border-box', padding: 20, background: '#fff'}}>
        <h1>{msgInfo?.miTitle}</h1>
        <div className="j-text-black-100">{msgInfo?.miTime}</div>
        <div style={{marginTop:8}}>{msgInfo?.miContent}</div>
    </div>)
}

const InlineMegDetail = ({msgId, record}: any) => {
    const dispatch = useDispatch()
    const [msgInfo, setMsgInfo] = useState<any>(record||{})
    const getDetailApi = async (params: any) => {
        const { result } = await getMsgDetailApi(params)
        const [ msgInfo ] = handleDvmCommonListResult(result)
        setMsgInfo(msgInfo)
        addUpdateApi({mrIds: [msgId].toString()})
        dispatch(asyncSetMsgInfo())
    }
    useEffect(() => {
        getDetailApi({mrId: msgId})
    }, [msgId])
    useEffect(() => {
        setMsgInfo(record)
    }, [record])
    return (<div style={{boxSizing: 'border-box', padding: 20, background: '#fff'}}>
        <h1>{msgInfo?.miTitle}</h1>
        <div className="j-text-black-100">{(msgInfo?.miTime||'').slice(0, -3)}</div>
        {/* <div style={{marginTop:8, wordBreak: 'break-all'}}>{msgInfo?.miContent}</div> */}
        <TextArea
            value={msgInfo?.miContent}
            bordered={false}
            disabled
            autoSize={{ minRows: 6 }}
            style={{cursor: 'auto', color: 'inherit'}}
        />
    </div>)
}


export const MessageDetail = () => {
    const { search } = useLocation();
    const { msgId } = parseSearch(search)
    return <MegDetail msgId={msgId} />
}

const InlineMegDetailWithId = () => {
    const { search } = useLocation();
    const { msgTitle: miTitle, msgId } = parseSearch(search)

    const MemoInlineMegDetail = useMemo(() => <InlineMegDetail msgId={msgId} />, [msgId])
    return MemoInlineMegDetail
}

export default () => {
    const history = useHistory()
    const { search } = useLocation();
    const dispatch = useDispatch()
    const { msgTitle: miTitle, msgId } = parseSearch(search)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [modalProps, setModalProps] = useState({visible: false, title: '新增', params: {}})
    const dataRef = useRef([])
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref

    // operate 函数为增删改查需要处理的业务
    const operate = (type: Action, record: any) => {
        const { mrId } = record || {}
        if (type === '详情') {
            // history.push(`/cost/cloudDetail?${stringify({aciId, useStatusStr})}`)
            console.log('需要处理的新增业务')
        } else if (type === '删除') {
            const mrIds = record ? [mrId] : selectedRowKeys
            console.log(mrIds)
            Modal.confirm({
                title: '确认删除',
                content: '删除后消息将无法恢复, 您确定要删除吗?',
                okText: '确认',
                centered: true,
                cancelText: '取消',
                onOk: async() => {
                    await delApi({mrIds: mrIds.toString()}, true, () => {
                        console.log('数据删除成功, 可以执行回调了')
                        reFresh()
                    })
                },
            });
        } else if (type === '标为已读') {
            const mrIds = record ? [mrId] : selectedRowKeys
            Modal.confirm({
                title: '确认要标为已读吗?',
                content: '',
                okText: '确认',
                centered: true,
                cancelText: '取消',
                onOk: async() => {
                    await addUpdateApi({mrIds: mrIds.toString()}, true, () => {
                        console.log('数据删除成功, 可以执行回调了')
                        dispatch(asyncSetMsgInfo())
                        reFresh()
                    })
                },
            });
        } else if (type === '全部标为已读') {
            const mrIds = record ? [mrId] : selectedRowKeys
            Modal.confirm({
                title: '确认要全部标为已读吗?',
                content: '',
                okText: '确认',
                centered: true,
                cancelText: '取消',
                onOk: async() => {
                    await addUpdateAllApi({}, true, () => {
                        console.log('数据删除成功, 可以执行回调了')
                        dispatch(asyncSetMsgInfo())
                        reFresh()
                    })
                },
            });
        } else if (type === '读消息') {
            const mrIds = record ? [mrId] : selectedRowKeys
            // addUpdateApi({mrIds: [mrId].toString()}, true, () => {
            //     console.log('数据删除成功, 可以执行回调了')
            //     reFresh()
            // })
            // const href = `/account/messageDetail?${stringify({msgId: mrId})}`
            // setWindowHref(href)
            // setCurMsgId(miId)
            history.push(`/account/message?msgId=${mrId}`)
            addUpdateApi({mrIds: mrIds.toString()}, false, () => {
                console.log('数据删除成功, 可以执行回调了')
                reFresh()
            })
        }
    }

    const columns = columnsFn(operate) // 表格列配置

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        console.log('处理后的成功回调触发了')
        setModalProps({
            ...modalProps,
            visible: false
        })
        reFresh()
    }
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const calcDelDisabled = () => !selectedRowKeys.length
    const calcCheckObj = () => {
        const checkAll = selectedRowKeys.length && dataRef.current.length === selectedRowKeys.length
        const indeterminate = selectedRowKeys.length && selectedRowKeys.length < dataRef.current.length
        return {
            checked: checkAll,
            indeterminate
        }
    }
    const onCheckAll = (e: any) => {
        const checked = e.target.checked
        checked ? setSelectedRowKeys(dataRef.current.map((v: any) => v.mrId)) : setSelectedRowKeys([])
    }
    return (<ProTable<any>
            columns={columns}
            params={{}} // 查询所需要的额外参数
            request={async (...params) => {
                const { data, ...rest } = await getListApi(...params) as any
                dataRef.current = data
                return {
                    data,
                    ...rest
                }
            }}
            options={{reload: false, density: false, setting: false}}
            headerTitle={[
                <Checkbox key="ckAll" {...calcCheckObj() as any} onChange={onCheckAll} style={{marginLeft: 8}}></Checkbox>
            ]}
            toolbar={{
                actions: [
                    <Button key="btn2" disabled={calcDelDisabled()} onClick={() => operate && operate('删除', null)}>删除</Button>,
                    <Button key="btn0" onClick={() => operate && operate('全部标为已读', null)}>全部已读</Button>,
                    <Button key="btn1" disabled={calcDelDisabled()} onClick={() => operate && operate('标为已读', null)}>标记已读</Button>,
                ],
            }}
            tableRender={(_, dom) => (<Row style={{backgroundColor: '#fff'}}>
                {/* {dom} */}
                <Col span={msgId ? 15 : 24}>{dom}</Col>
                {
                    msgId ? <Col span={8} style={{borderLeft: '1px dashed #E8E8E8', margin: '20px 20px 20px 0', paddingLeft: 20}}>
                        <InlineMegDetailWithId />
                    </Col> : null
                }
            </Row>)}
            rowKey="mrId"
            pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
            actionRef={ref as any}
            showHeader={false}
            search={{
                optionRender: (searchConfig, formProps, dom) => [ ...dom.reverse() ]
            }}
            rowSelection={{
                preserveSelectedRowKeys: true,
                selectedRowKeys,
                onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
            }}
        />)
}
