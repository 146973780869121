export const urlOptions = [
    {
      label: 'URL刷新',
      value: 1
    },
    {
      label: '目录刷新',
      value: 2
    }
  ]
  
export const typeOptions = [...urlOptions, { label: 'URL预热', value: 3 }]
  const statusOptions = [
    { label: '处理中', value: 1 },
    { label: '完成', value: 2 },
    { label: '失败', value: 3 },
    { label: '等待', value: 4 },
    { label: '刷新中', value: 5 },
    { label: '预热中', value: 6 }
  ]
  export const searchBoxConfig = [
    {
      vcType: 'a-input',
      name: 'keyWord',
      vcProps: {
        placeholder: '请输入域名、URL、目录'
      }
    },
    {
      vcType: 'a-select',
      name: 'type',
      vcProps: {
        placeholder: '操作类型',
        options: typeOptions
      }
    },
    {
      vcType: 'a-select',
      name: 'status',
      vcProps: {
        options: statusOptions,
        placeholder: '状态'
      }
    },
    {
      vcType: 'a-range-picker',
      name: 'createTime',
      vcProps: {
        placeholder: ['创建时间', '创建时间']
      }
    }
  ]
  
  export const columns = [
    {
      title: '操作类型',
      dataIndex: 'type'
    },
    {
      title: 'URL/目录',
      dataIndex: 'urls',
      width: 420,
      scopedSlots: { customRender: 'urls' }
    },
    {
      title: '状态',
      dataIndex: 'status'
    },
    {
      title: '创建时间',
      dataIndex: 'createTime'
    }
  ]
  
  export const placeholder = `一行一个URL，示例：
  http://www.example01.com/folder01/
  http://www.example01.com/folder02/
  `
  