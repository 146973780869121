/* eslint-disable react/no-unknown-property */

import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
export default (props: any) => {
  const { nodes, materials, scene } = useGLTF(
    '/assets/glbFile/demo.glb',
  ) as any;
  const ref1 = useRef() as any;
  const ref2 = useRef() as any;
  const ref3 = useRef() as any;

  materials['透明玻璃'] = new THREE.MeshPhysicalMaterial({
    precision: 'lowp',
    transparent: true,
    side: THREE.DoubleSide,
    opacity: 0.35,
    roughness: 0.0, //光滑度
    metalness: 0.7,
    color: 'rgb(243, 243, 243)',
  });
  materials['红色'] = new THREE.MeshPhysicalMaterial({
    precision: 'lowp',
    side: THREE.DoubleSide,
    lightMapIntensity: 0.8, //烘焙光强度
    roughness: 0.01, //光滑度
    emissiveIntensity: 5,
    color: '#e34e2c',
  });

  materials['其他方体2'] = new THREE.MeshPhysicalMaterial({
    precision: 'lowp',
    transparent: false,
    side: THREE.DoubleSide,
    shadowSide: THREE.FrontSide,
    roughness: 0.3, //光滑度
    metalness: 0.7,
    color: 'rgb(243, 243, 243)',
  });
  materials['白色底座环'] = new THREE.MeshPhysicalMaterial({});

  function Rotation({ v = new THREE.Vector3() }) {
    return useFrame((data: any) => {
      const t = data.clock.elapsedTime;
      // data.camera.position.lerp(v.set(Math.sin(t / 3), 0.3, 2.7 + Math.cos(t / 5) / 3), 0.1)
      ref1.current.rotation.x += 0.005;
      ref1.current.rotation.y += 0.005;
      ref2.current.rotation.y += 0.005;
    });
  }

  return (
    <group {...props} dispose={null}>
      <Rotation></Rotation>
      <group position={[1.79, 77.59, -17.81]} ref={ref2}>
        <mesh
          geometry={nodes.大球.geometry}
          material={materials.红色}
          position={[-147.08, -48.26, -33.75]}
        />
        <mesh
          geometry={nodes.小球.geometry}
          material={materials.红色}
          position={[148.87, 52.07, 15.95]}
        />
        <mesh
          geometry={nodes.球圆环.geometry}
          material={materials.透明玻璃}
          position={[-1.79, -3.81, 17.81]}
          rotation={[0.18, 0, 0.35]}
        />
      </group>
      {/* <mesh ref={ref3}  geometry={nodes.圆盘.geometry} material={materials.红色} position={[-1.47, 93.6, -2.98]} rotation={[0.29, -0.08, -0.07]} /> */}
      <mesh
        geometry={nodes.立方体9.geometry}
        material={materials.红色}
        position={[32.88, 112.93, -30.86]}
      />
      <mesh
        geometry={nodes.立方体8.geometry}
        material={materials.红色}
        position={[-31.02, 112.93, 31.67]}
      />
      <mesh
        geometry={nodes.立方体7.geometry}
        material={materials.其他方体2}
        position={[-32.77, 112.93, -31.46]}
      />
      <mesh
        ref={ref1}
        geometry={nodes.运动方体.geometry}
        material={materials.透明玻璃}
        position={[36.27, 120.19, 34.2]}
        rotation={[0.63, -0.46, 0.51]}
      />
      <mesh
        geometry={nodes.立方体6.geometry}
        material={materials.透明玻璃}
        position={[32.88, 52.61, -30.86]}
      />
      <mesh
        geometry={nodes.立方体5.geometry}
        material={materials.透明玻璃}
        position={[-31.02, 52.61, 31.67]}
      />
      <mesh
        geometry={nodes.立方体3.geometry}
        material={materials.红色}
        position={[-32.77, 52.61, -31.46]}
      />
      <mesh
        geometry={nodes.立方体2.geometry}
        material={materials.红色}
        position={[32.25, 52.61, 32.53]}
      />
      <mesh
        geometry={nodes.小底座.geometry}
        material={materials.透明玻璃}
        position={[-0.36, 10.57, 0]}
      />
      <mesh geometry={nodes.大底座.geometry} material={materials.透明玻璃} />
    </group>
  );
};

useGLTF.preload('/assets/glbFile/demo.glb');
