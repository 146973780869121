import React, { useEffect, useMemo, useState } from "react"
import { Avatar, Button, Card, Descriptions, Alert, Row, Col, Divider, Tabs, Image, CardProps } from "antd"
import ProForm, { 
  ProFormText, 
  ProFormUploadButton, 
  ProFormSelect, 
  ProFormTextArea, 
  ProFormCascader,
  ProFormProps
} from '@ant-design/pro-form';
import { PreviewProps } from "@components/Common/Preview";
import { acceptImg, emailReg, getToken, idCardNumReg, mobileNumberMask, parseSearch, phoneReg, productApi, strMask } from "@utils/index";
import { BASEURL, userCompanyCertification, userPersonCertification, userPersonCertificationInfo } from "@api/index";
import { Icon, Upload, } from "@components/Common";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { stringify } from "qs";
import { StateType } from "@store/index";
import { handleUploadFiles, handleUploadInitValue } from "@components/Common/Upload";
import boyImg from '@assets/images/cert.png';
import {
  CERT_PERSON,
  CERT_COMPANY,
  CERT_UNCERT,
  CERT_CERTING,
  CERT_SUCCESS,
  CERT_FAIL,
  CERT_UPGRADE,
  calcCertStatus,
  CERT_PERSON_CERTING,
  CERT_PERSON_SUCCESS,
  CERT_PERSON_FAIL,
  CERT_COMPANY_CERTING,
  CERT_COMPANY_SUCCESS,
  CERT_COMPANY_FAIL,
  CERT_UPGRADE_CERTING,
  CERT_UPGRADE_SUCCESS,
  CERT_UPGRADE_FAIL,
} from '@utils/enum'
import { asyncSetUserInfo } from "@store/actions";
import { Description } from "@components/Common/AlertTip";
import { useCommonApiHooks } from "@hooks/index";
import { handleDvmCommonResult } from "@utils/request";

const userPersonCertificationApi = productApi(userPersonCertification)
const userCompanyCertificationApi = productApi(userCompanyCertification)

type CertificationStatusMapsProps = {
  type?: 0 | 1 | 2 | 3; // 认证类型 0: 未知, 1: 个人, 2: 企业, 3: 个人升级企业
  status?: 0 | 1 | 2 | 9; // 认证状态 0: 未认证, 1: 认证中, 2: 认证成功, 9: 认证失败
}

const labelCol = {span: 6}
const wrapperCol = {span: 8}

interface ProFormCommonProps extends ProFormProps{
  otherSubmitParams?: string[];
  onSuccess?: () => void;
}

// 用户--实名认证(未认证)
const UnCertification = () => {
  const history = useHistory()
  const onClick = () => history.push(`/account/certification?${stringify({status: CERT_UNCERT})}`)
  return (<Card title="实名认证">
    <div style={{textAlign: 'center', padding: '80px 0', minHeight: 500}}>
      <p><Icon type="icon-tixingshixin" style={{fontSize: 70, color:'#FAAD14'}} /></p>
      <p>您尚未进行实名认证, <a onClick={onClick}>前去认证</a></p>
      <Button type="primary" onClick={onClick}>立即去认证</Button>
    </div>
  </Card>)
}

// 用户--实名认证(审核中【个人，企业，个人升级企业】)
const Certificationing = () => {
  const history = useHistory()
  const { baseInfo, certInfo } = useSelector((store: StateType) => store?.userInfo) as any
  const { userNature: type, verifyState: status } = (baseInfo || {}) as any
  const STATUS = type === CERT_PERSON ? CERT_PERSON_CERTING : type === CERT_COMPANY ? CERT_COMPANY_CERTING : CERT_UPGRADE_CERTING
  const onClick = () => history.push(`/account/certification?${stringify({status: STATUS})}`)
  return (<Card title="实名认证">
    <div style={{textAlign: 'center', padding: '80px 0', minHeight: 500}}>
      <p><Icon type="icon-tixingshixin" style={{fontSize: 70, color:'#FAAD14'}} /></p>
      <p>您的<a onClick={onClick}>{ STATUS === CERT_UPGRADE_CERTING ? '个人升级为企业实名认证' : '实名认证'}</a>正在审核中, 请耐心等待...</p>
    </div>
  </Card>)
}

// 用户个人--实名认证(已认证 【个人】)
const PassCertificationPerson = () => {
  const history = useHistory()
  const { certInfo } = useSelector((store: StateType) => store?.userInfo) as any
  const { urpName, urpIdCard, updateTime } = (certInfo || {}) as any

  return (<Card title="实名认证" style={{paddingBottom: 80}}>
      <Alert
          type="warning"
          description={<Description listTips={[
              '实名认证决定了账号归属。请确保认证主体与账号使用主体保持一致，以免在人员变动交接账号或账号下财产出现纠纷。若是企业账号，请您务必进行企业实名认证。',
              '个人认证可以升级为企业认证，但企业认证不能降级为个人认证。',
              '个人认证：1个身份证最多可认证3个平台账号。',
              '企业认证：1个企业信息主体最多可认证10个平台账号。'
          ]} />}
      />
      <Row align="middle" justify="center" style={{marginTop: 20}}>
        <Col>
          <Avatar shape="square" style={{ width: 278, height: 278 }} src={boyImg} />
        </Col>
        <Col span={10} style={{marginLeft: 40}}>
          <h3 style={{fontSize: 22, marginBottom: 20}}>恭喜您已完成个人实名认证</h3>
          <Descriptions>
            <Descriptions.Item label="认证状态" span={3}><span className="text-gray-500">已认证</span></Descriptions.Item>
            <Descriptions.Item label="认证类型" span={3}><span className="text-gray-500">个人认证</span></Descriptions.Item>
            <Descriptions.Item label="真实姓名" span={3}><span className="text-gray-500">{strMask(urpName)}</span></Descriptions.Item>
            <Descriptions.Item label="身份证号" span={3}><span className="text-gray-500">{mobileNumberMask(urpIdCard)}</span></Descriptions.Item>
            <Descriptions.Item label="认证时间" span={3}><span className="text-gray-500">{updateTime}</span></Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Divider />
      <Row justify="space-between">
        <Col>完成企业实名认证，享更多专属特权</Col>
        <Col>
          <Button type="primary" onClick={() => history.push(`/account/certification?${stringify({status: CERT_UPGRADE})}`)}>升级为企业实名认证</Button>
        </Col>
      </Row>
  </Card>)
}

// 用户企业--实名认证(已认证 【企业，个人升级企业】)
const PassCertificationCompany = () => {
  const { certInfo } = useSelector((store: StateType) => store?.userInfo) as any
  const { urpName, ureName, ureUnifiedSocialCoding, ureContactPerson, ureContactPhone, urpIdCard, updateTime } = (certInfo || {}) as any

  return (<Card title="实名认证" style={{paddingBottom: 80}}>
      <Row align="middle" justify="center">
        <Col>
          <Avatar shape="square" style={{ width: 278, height: 278 }} src={boyImg} />
        </Col>
        <Col span={10} style={{marginLeft: 40}}>
          <h3 style={{fontSize: 22, marginBottom: 20}}>恭喜您已完成企业实名认证</h3>
          <Descriptions>
            <Descriptions.Item label="认证状态" span={3}><span className="text-gray-500">已认证</span></Descriptions.Item>
            <Descriptions.Item label="认证类型" span={3}><span className="text-gray-500">企业认证</span></Descriptions.Item>
            <Descriptions.Item label="企业名称" span={3}><span className="text-gray-500">{strMask(ureName)}</span></Descriptions.Item>
            <Descriptions.Item label="社会信用代码" span={3}><span className="text-gray-500">{strMask(ureUnifiedSocialCoding)}</span></Descriptions.Item>
            <Descriptions.Item label="联系人姓名" span={3}><span className="text-gray-500">{strMask(ureContactPerson)}</span></Descriptions.Item>
            <Descriptions.Item label="联系人手机号" span={3}><span className="text-gray-500">{strMask(ureContactPhone)}</span></Descriptions.Item>
            <Descriptions.Item label="认证时间" span={3}><span className="text-gray-500">{updateTime}</span></Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
  </Card>)
}

// 用户--实名认证(认证失败 【个人，企业】)
const CertificationFail = () => {
  const history = useHistory()
  const { baseInfo, certInfo } = useSelector((store: StateType) => store?.userInfo) as any
  const { verifyDescribe } = certInfo || {}
  const { userNature: type, verifyState: status } = (baseInfo || {}) as any
  const STATUS = type === CERT_PERSON ? CERT_PERSON_FAIL : type === CERT_COMPANY ? CERT_COMPANY_FAIL : CERT_UPGRADE_FAIL
  const onClick = () => history.push(`/account/certification?${stringify({status: STATUS})}`)
  return (<Card title="实名认证">
    <div style={{textAlign: 'center', padding: '80px 0', minHeight: 500}}>
      <p><Icon type="icon-tixingshixin" style={{fontSize: 70, color:'#FF2020'}} /></p>
      <h2 style={{fontSize: 24}}>抱歉,您的实名认证审核未通过</h2>
      <p style={{color: '#999'}}>失败原因: {verifyDescribe} (建议修改信息后重新提交)</p>
      <Button type="primary" onClick={onClick}>修改认证信息</Button>
    </div>
  </Card>)
}

// 个人升级企业--实名认证(认证失败 【个人升级企业】)
const UpgradeCertificationFail = () => {
  const history = useHistory()
  const [certPersonInfo, setCertPersonInfo] = useState({})
  const { certInfo } = useSelector((store: StateType) => store?.userInfo) as any

  const getCertPersonInfoApi = async () => {
      const { result } = await userPersonCertificationInfo({}) as any
      const certPersonInfo = handleDvmCommonResult(result)
      setCertPersonInfo(certPersonInfo)
  }
  useEffect(() => {
    getCertPersonInfoApi()
  }, [])

  const { urpName, urpIdCard, updateTime } = (certPersonInfo || {}) as any
  const { verifyDescribe } = certInfo || {}

  return (<Card title="实名认证" style={{paddingBottom: 80}}>
      <Alert
          type="warning"
          description={<Description listTips={[
              '实名认证决定了账号归属。请确保认证主体与账号使用主体保持一致，以免在人员变动交接账号或账号下财产出现纠纷。若是企业账号，请您务必进行企业实名认证。',
              '个人认证可以升级为企业认证，但企业认证不能降级为个人认证。',
              '个人认证：1个身份证最多可认证3个平台账号。',
              '企业认证：1个企业信息主体最多可认证10个平台账号。'
          ]} />}
      />
      <Row align="middle" justify="center" style={{marginTop: 20}}>
        <Col>
          <Avatar shape="square" style={{ width: 278, height: 278 }} src={boyImg} />
        </Col>
        <Col span={10} style={{marginLeft: 40}}>
          <h3 style={{fontSize: 22, marginBottom: 20}}>恭喜您已完成个人实名认证</h3>
          <Descriptions>
            <Descriptions.Item label="认证状态" span={3}><span className="text-gray-500">已认证</span></Descriptions.Item>
            <Descriptions.Item label="认证类型" span={3}><span className="text-gray-500">个人认证</span></Descriptions.Item>
            <Descriptions.Item label="真实姓名" span={3}><span className="text-gray-500">{strMask(urpName)}</span></Descriptions.Item>
            <Descriptions.Item label="身份证号" span={3}><span className="text-gray-500">{mobileNumberMask(urpIdCard)}</span></Descriptions.Item>
            <Descriptions.Item label="认证时间" span={3}><span className="text-gray-500">{updateTime}</span></Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Divider />
      <Row justify="space-between" style={{background: '#F6F6F6', borderRadius: 4, padding: 20}}>
        <Col>
          <div style={{display: 'flex', fontSize: 22, alignItems: 'center', color: '#FF2020'}}>
            <Icon type="icon-tixingshixin" style={{fontSize: 24, color:'#FF2020', marginRight: 8}} /> 企业认证升级失败
          </div>
          <div style={{color: '#585858', paddingLeft: 30}}>失败原因: {verifyDescribe}</div>
        </Col>
        <Col>
          <Button type="primary" onClick={() => history.push(`/account/certification?${stringify({status: CERT_UPGRADE_FAIL})}`)}>修改认证信息</Button>
        </Col>
      </Row>
  </Card>)
}

// 认证提示
const tips = [
  '实名认证决定了账号归属。请确保认证主体与账号使用主体保持一致，以免在人员变动交接账号或账号下财产出现纠纷。若是企业账号，请您务必进行企业实名认证。',
  '个人认证可以升级为企业认证，但企业认证不能降级为个人认证。',
  '个人认证：1个身份证最多可认证3个平台账号。',
  <>企业认证：1个企业信息主体最多可认证10个平台账号<span style={{color: 'red', fontWeight: 500}}>(使用短信、要素类身份认证、人脸识别服务请完成企业认证，个人用户不支持)。</span></>
]

const CertificationTips = (props: CardProps) => {
  return (
    <Card title="实名认证" bordered={false} {...props}>
      <Alert description={<Description listTips={tips} />} type="warning" />
    </Card>)
}

// 用户--申请实名认证(个人)
const CertificationPersonForm = ({...rest}: ProFormCommonProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const onFinish = async(values: any) => {
    const {urpPhoto: files, urpIdCardEmblem: files1, urpIdCardHead: files2, ...rest} = values
    // 2.9号因为觉得繁琐暂时去掉了自拍照
    // const urpPhoto = handleUploadFiles(files)[0]
    const urpIdCardEmblem = handleUploadFiles(files1)[0]
    const urpIdCardHead = handleUploadFiles(files2)[0]
    await userPersonCertificationApi({...rest, urpIdCardEmblem, urpIdCardHead})
    await dispatch(asyncSetUserInfo()) as any // 获取账号权限信息
    history.push('/account/certification')
  }
  return (<ProForm<any>
    layout="horizontal"
    labelCol={labelCol}
    wrapperCol={wrapperCol}
    onFinish={onFinish}
    submitter={{render: (props, doms) => [
      <Button key="submit" type="primary" style={{marginLeft: '25%'}} onClick={() => props.form?.submit?.()}>提交</Button>,
    ]}}
    {...rest}
    >
      <ProFormText name="urpName" label="姓名" rules={[{ required: true, message: '请输入姓名',}]} placeholder="请输入与身份证上一致的名字"/>
      <ProFormText 
        name="urpIdCard" 
        label="身份证号" 
        rules={[
          { required: true, message: '请输入身份证号',},
          { pattern: idCardNumReg, message: '非法身份证号' }
        ]} 
        placeholder="请输入身份证号"
      />
      <ProFormText 
        name="urpEmail" 
        label="邮箱" 
        rules={[
          { required: false, message: '请输入邮箱'},
          { pattern: emailReg, message: '请填写正确的邮箱'}
        ]} 
        placeholder="请输入邮箱"
      />
      {/* <Upload
        label="自拍照"
        name="urpPhoto"
        fieldProps={{
          accept: acceptImg,
          maxCount: 1
        }}
        rules={[
          { required: true, message: '请上传自拍照'}
        ]}
      /> */}
      <Upload
        label="身份证头像照"
        name="urpIdCardHead"
        fieldProps={{
          accept: acceptImg,
          maxCount: 1
        }}
        rules={[
          { required: true, message: '请上传身份证头像照'}
        ]}
      />
      <Upload
        label="身份证国徽照"
        name="urpIdCardEmblem"
        fieldProps={{
          accept: acceptImg,
          maxCount: 1
        }}
        rules={[
          { required: true, message: '请上传身份证国徽照'}
        ]}
      />
  </ProForm>)
}

// 用户--申请实名认证(企业)
const CertificationCompanyForm = ({...rest}: ProFormCommonProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const areaTreeData = useSelector((store: StateType) => store?.areaTree) as any
  const onFinish = async(values: any) => {
    const {ureBusinessLicense: files0, ureJuridicalImage: files1, ureJuridicalIdCardHead: files2, ureJuridicalIdCardEmblem: files3, areaCode, ...rest} = values
    const ureBusinessLicense = handleUploadFiles(files0)[0]
    // 2.9号因为觉得繁琐暂时去掉了自拍照
    // const ureJuridicalImage = handleUploadFiles(files1)[0]
    const ureJuridicalIdCardHead = handleUploadFiles(files2)[0]
    const ureJuridicalIdCardEmblem = handleUploadFiles(files3)[0]
    const [ureProvince, ureCity, ureDistrict] = areaCode
    await userCompanyCertificationApi({...rest, ureProvince, ureCity, ureDistrict, ureBusinessLicense, ureJuridicalIdCardHead, ureJuridicalIdCardEmblem})
    await dispatch(asyncSetUserInfo()) as any // 获取账号权限信息
    history.push('/account/certification')
  }
  return ( <ProForm<any>
    layout="horizontal"
    labelCol={labelCol}
    wrapperCol={wrapperCol}
    onFinish={onFinish}
    submitter={{render: (props, doms) => [
      <Button key="submit" type="primary" style={{marginLeft: '25%'}} onClick={() => props.form?.submit?.()}>提交</Button>,
    ]}}
    {...rest}
    >
      <ProFormText name="ureName" label="企业名称" rules={[{ required: true, message: '请输入姓名',}]} placeholder="请填写营业执照的企业名称"/>
      <ProFormText name="ureUnifiedSocialCoding" label="社会信用代码" rules={[{ required: true, message: '请输入社会信用代码',}]} placeholder="请输入社会信用代码"/>
      <Upload
        label="营业执照"
        name="ureBusinessLicense"
        fieldProps={{
          accept: acceptImg,
          maxCount: 1
        }}
        rules={[
          { required: true, message: '请上传营业执照'}
        ]} 
      /> 
      <ProFormCascader 
        name="areaCode" 
        label="通讯地址" 
        fieldProps={{
          options: areaTreeData,
          showSearch: true,
        }}
        rules={[{ required: true, message: '请输入省市区',}]} 
        placeholder="请输入省市区" 
      />
      <ProFormTextArea 
        name="ureAddress" 
        label="详细地址"
        fieldProps={{maxLength: 200, showCount: true}} 
        placeholder="请填写您的详细地址,在有需要时方便寄送书面文件"
        rules={[{required: false, message: '请填写详细地址'}, {max: 200, type:'string', message: '200字以内'}]} 
      />

    <ProFormText name="ureContactPerson" label="联系人姓名" rules={[{ required: true, message: '请输入联系人姓名',}]} placeholder="请输入"/>
    <ProFormText 
      name="ureContactPhone" 
      label="联系人手机号" 
      rules={[
        { required: true, message: '请输入联系人手机号',},
        { pattern: phoneReg, message: '非法手机号', }
      ]} 
      placeholder="请输入"
    />
    <ProFormText 
      name="ureContactEmail" 
      label="联系人邮箱" 
      rules={[
        { required: false, message: '请输入邮箱',},
        { pattern: emailReg, message: '请填写正确的邮箱'}
      ]} 
      placeholder="请输入邮箱"
    />

    <ProFormText name="ureJuridicalPerson" label="法人姓名" rules={[{ required: true, message: '请输入法人姓名',}]} placeholder="请输入请输入法人姓名"/>
    <ProFormText 
      name="ureJuridicalIdCard" 
      label="法人身份证号" 
      rules={[
        { required: true, message: '请输入法人身份证号',},
        { pattern: idCardNumReg, message: '非法身份证号' }
      ]} 
      placeholder="请输入法人身份证号"
    />
    {/* <Upload
      label="法人自拍照"
      name="ureJuridicalImage"
      fieldProps={{
        accept: acceptImg,
        maxCount:1
      }}
      rules={[
        { required: true, message: '请上传法人自拍照'}
      ]} 
    /> */}
    <Upload
      label="法人身份证头像照"
      name="ureJuridicalIdCardHead"
      fieldProps={{
        accept: acceptImg,
        maxCount:1
      }}
      rules={[
        { required: true, message: '请上传法人身份证头像照'}
      ]} 
    />
    <Upload
      label="法人身份证国徽照"
      name="ureJuridicalIdCardEmblem"
      fieldProps={{
        accept: acceptImg,
        maxCount:1
      }}
      rules={[
        { required: true, message: '请上传法人身份证国徽照'}
      ]} 
    />
  </ProForm>)
}

const CertificationPerson = (props: ProFormCommonProps) => {
  return (<div style={{background: '#fff', paddingBottom: 30}}>
    <CertificationTips />
    <CertificationPersonForm {...props} />
  </div>)
}

const CertificationCompany = (props: ProFormCommonProps) => {
  return (<div style={{background: '#fff', paddingBottom: 30}}>
    <CertificationTips />
    <CertificationCompanyForm {...props} />
  </div>)
}

const tabItems = [
  { label: '企业认证', key: ''+CERT_COMPANY, children: <><Alert description={<Description listTips={tips} />} type="warning" /><CertificationCompanyForm style={{marginTop: 20}} /></> },
  { label: '个人认证', key: ''+CERT_PERSON, children: <><Alert description={<Description listTips={tips} />} type="warning" /><CertificationPersonForm style={{marginTop: 20}} /></> }
]
// 申请实名认证界面
const Certification = () => {
  return (<Tabs items={tabItems} tabBarStyle={{padding: '0 20px', marginBottom: 0}} style={{background: '#fff', minHeight: 500, paddingBottom: 30}} />)
}

const certCommonStyle = {
  padding: '2px 6px',
  borderRadius: 2,
  fontSize: 12
}

const styleCerting = {
  background: 'rgba(3,121,254,0.06)',
  color: 'rgba(3,121,254,1)',
  ...certCommonStyle
}

const styleCertSuccess = {
  background: 'rgba(0,177,34,0.06)',
  color: 'rgba(0,177,34,1)',
  ...certCommonStyle
}

const styleCertFail = {
  background: 'rgba(255,32,32,0.06)',
  color: 'rgba(255,32,32,1)',
  ...certCommonStyle
}

const styleNoCert = {
  background: 'rgba(255,32,32,0.06)',
  color: 'rgba(255,32,32,1)',
  ...certCommonStyle
}

export const CertificationStatusMaps: any = ({type, status}: CertificationStatusMapsProps) => {
  const STATUS = calcCertStatus(type, status)
  // 2023.1.9 产品方说这几个状态文案要改，不区分那么细，特此记录
  switch (STATUS) {
    case CERT_UNCERT:
      return <span style={styleNoCert}>未认证 <Icon type="icon-tixingshixin" /></span>
    case CERT_PERSON_CERTING: 
      return <span style={styleCerting}>审核中 <Icon type="icon-tixingshixin" /></span>
    case CERT_PERSON_SUCCESS: 
      return <span style={styleCertSuccess}>个人实名认证 <Icon type="icon-renzheng" /></span>
    case CERT_PERSON_FAIL:
      return <span style={styleCertFail}>认证失败 <Icon type="icon-tixingshixin" /></span>
    
    case CERT_COMPANY_CERTING:
      return <span style={styleCerting}>审核中 <Icon type="icon-tixingshixin" /></span>
    case CERT_COMPANY_SUCCESS:
      return <span style={styleCertSuccess}>企业实名认证 <Icon type="icon-renzheng" /></span>
    case CERT_COMPANY_FAIL:
      return <span style={styleCertFail}>认证失败 <Icon type="icon-tixingshixin" /></span>

    case CERT_UPGRADE_CERTING:
      return <span style={styleCerting}>审核中 <Icon type="icon-tixingshixin" /></span>
    case CERT_UPGRADE_SUCCESS:
      return <span style={styleCertSuccess}>企业实名认证 <Icon type="icon-renzheng" /></span>
    case CERT_UPGRADE_FAIL:
      // return <span style={{color: '#FF2020'}}>认证失败(企业认证)</span>
      return <span style={styleCertSuccess}>个人实名认证 <Icon type="icon-renzheng" /></span>

    default:
      return null
  }
}

const CertificationPageMaps: any = ({type, status}: CertificationStatusMapsProps) => {
  const STATUS = calcCertStatus(type, status)
  switch (status) {
    case CERT_UNCERT:
      return <UnCertification />
    case CERT_CERTING: 
      return <Certificationing />
    case CERT_SUCCESS: 
      return type === CERT_PERSON ? <PassCertificationPerson /> : <PassCertificationCompany />
    case CERT_FAIL:
      return type === CERT_UPGRADE ? <UpgradeCertificationFail /> : <CertificationFail />
    default:
      return null
  }
}

const CertificationPage = () => {
  const { search } = useLocation()
  const { status: result } = parseSearch(search)
  const { baseInfo, certInfo } = useSelector((store: StateType) => store?.userInfo) as any
  const { userNature: type, verifyState: status } = (baseInfo || {}) as any
  const { 
    urpName, 
    urpIdCard, 
    urpEmail, 
    urpPhoto,
    urpIdCardHead,
    urpIdCardEmblem,

    // 企业认证信息
    ureName,
    ureUnifiedSocialCoding,
    ureBusinessLicense,
    ureAddress,
    ureContactPerson,
    ureContactPhone,
    ureContactEmail,
    ureJuridicalPerson,
    ureJuridicalImage,
    ureJuridicalIdCardHead,
    ureJuridicalIdCardEmblem,
    ureJuridicalIdCard,
    ureProvince, 
    ureCity, 
    ureDistrict
  } = certInfo || {}
  const certPersonInitValue = {
    urpName, 
    urpIdCard, 
    urpEmail, 
    urpPhoto: handleUploadInitValue(urpPhoto),
    urpIdCardHead: handleUploadInitValue(urpIdCardHead),
    urpIdCardEmblem: handleUploadInitValue(urpIdCardEmblem),
  }
  const certCompanyInitValue = {
    ureName,
    ureUnifiedSocialCoding,
    ureBusinessLicense: handleUploadInitValue(ureBusinessLicense),
    ureAddress,
    ureContactPerson,
    ureContactPhone,
    ureContactEmail,
    ureJuridicalPerson,
    ureJuridicalIdCard,
    ureJuridicalImage: handleUploadInitValue(ureJuridicalImage), 
    ureJuridicalIdCardHead: handleUploadInitValue(ureJuridicalIdCardHead), 
    ureJuridicalIdCardEmblem: handleUploadInitValue(ureJuridicalIdCardEmblem), 
    areaCode: [ureProvince * 1, ureCity * 1, ureDistrict * 1]
  }

  const Page = () => {
    const state = result * 1 // string 类型转换成 number
    switch (state) {
      // case CERT_UNCERT: // 未认证，需要呈现的页面
      //   return <Certification />
      // case CERT_CERTING: // 审核中，查看认证信息的页面
      //   return type === CERT_PERSON ? <CertificationPerson initialValues={certPersonInitValue} disabled /> : <CertificationCompany initialValues={certCompanyInitValue} disabled />
      // case CERT_FAIL: // 认证失败，修改认证信息需要呈现的页面
      //   return type === CERT_PERSON ? <CertificationPerson initialValues={certPersonInitValue} /> : <CertificationCompany initialValues={certCompanyInitValue} />
      // case CERT_UPGRADE: // 个人升级到企业
      //   return <CertificationCompany />
      // case CERT_SUCCESS: // 认证成功，呈现认证信息的页面
      // default:
      //   return <CertificationPageMaps type={type} status={status} />

      case CERT_UNCERT:
        return <Certification />
      case CERT_PERSON_CERTING: 
        return <CertificationPerson initialValues={certPersonInitValue} disabled /> 
      case CERT_PERSON_SUCCESS: 
        return <CertificationPageMaps type={type} status={status} />
      case CERT_PERSON_FAIL:
        return <CertificationPerson initialValues={certPersonInitValue} />
      
      case CERT_COMPANY_CERTING:
        return <CertificationCompany initialValues={certCompanyInitValue} disabled />
      case CERT_COMPANY_SUCCESS:
        return <CertificationPageMaps type={type} status={status} />
      case CERT_COMPANY_FAIL:
        return <CertificationCompany initialValues={certCompanyInitValue} />
  
      case CERT_UPGRADE: // 个人升级到企业
        return <CertificationCompany />
      case CERT_UPGRADE_CERTING:
        return <CertificationCompany initialValues={certCompanyInitValue} disabled />
      case CERT_UPGRADE_SUCCESS:
        return <CertificationPageMaps type={type} status={status} />
      case CERT_UPGRADE_FAIL:
        return <CertificationCompany initialValues={certCompanyInitValue} />
      default:
        return <CertificationPageMaps type={type} status={status} />
    }
  }
  const MemoPage = useMemo(() => <Page />, [result])
  return MemoPage
}

export default CertificationPage
