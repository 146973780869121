import React, { useEffect, useState, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { BasicLayout, HomeLayOut, HomePage, NoPermission } from '@components/index'
import { getStorage, parseSearch, treeDataToFlatArr } from '@utils/index';
import { MenuRouter } from '@router/index'
import { Spin } from 'antd';
import { asyncSetAreaTree, asyncSetSmsSetting, asyncSetTaskObj, asyncSetUserInfo } from '@store/actions';
import { TOKENKEY } from '@utils/request';

export default ({children}: any) => {
    const [loading, setLoading] = useState<boolean>(true)
    const history = useHistory()
    const { pathname, search } = useLocation();
    const dispatch = useDispatch();
    const { fromPath } = parseSearch(search)
    const handleLogin = async() => {
        setLoading(true)
        const { permissions } = await dispatch(asyncSetUserInfo()) as any // 获取账号权限信息
        await dispatch(asyncSetSmsSetting()) // 获取国内短信设置
        setLoading(false)
        return permissions
    }

    const calcWillToPath = (permissions: any[]) => {
        const flatArr = treeDataToFlatArr(MenuRouter, false)
        const len = (permissions||[]).length
        const curPathnameCode = (flatArr.find(v => v.path === pathname)||{}).authCode
        const hasCurrentPathnameAuth = (permissions||[]).includes(curPathnameCode)
        if (['/login'].includes(fromPath) || ['/'].includes(pathname)) { // 从登陆页过来的
            if (['/'].includes(pathname)) {
                return '/home/index'
            }
            const toPath = len ? (flatArr.find(v => v.authCode === permissions[0])||{}).path : '/noPermission'
            return toPath
        }
        if (hasCurrentPathnameAuth || [-1].includes(curPathnameCode)) {
            return `${pathname}${search}`
        }
        return '/home/index'
        // return '/noPermission'
    }

    const initLoginCheck = async() => {
        const isLogin = getStorage(TOKENKEY)
        if (isLogin) {
            const permissions = await handleLogin()
            if (pathname !== '/') {
                const willTo = calcWillToPath(permissions)
                history.push(willTo)
            }
        } else {
            history.push('/')
        }
    }

    useEffect(() => {
        initLoginCheck()
    }, [])

    const MemoBasicLayout = useMemo(() => <Spin spinning={loading}><BasicLayout >{ !loading ? children : null }</BasicLayout></Spin>, [children,loading])
    return pathname === '/' ? <HomeLayOut><HomePage /></HomeLayOut> : MemoBasicLayout
}