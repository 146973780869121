import React from 'react';
import { BASEURL, fileExportUrl } from "@api/baseUrl"
import { Modal } from "antd"
import { useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { columnsFn } from "./data"
import ProTable, { ActionType } from "@ant-design/pro-table"
import { checkGetCardOperatorList } from '@api/product';
import { productApi } from '@utils/util';
import { PROCODE_BANKCARDTHREEELEMENT } from '@utils/enum';
import { ExportButton } from '@components/index';

// 接口请求的 api 层
const getApi = productApi(checkGetCardOperatorList)  // 查询的接口封装

// 在线发送
export default () => {
    const history = useHistory()
    const formRef = useRef()
    const otherParams =  {productCode: PROCODE_BANKCARDTHREEELEMENT}
    const dispatch = useDispatch()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [modalProps, setModalProps] = useState({ visible: false, title: '新增', params: {} })
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    // operate 函数为增删改查需要处理的业务
    const operate = async (type: any, record: any) => {

    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({ ...params }, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }
    // const getListApi = handleDvmCommonPageListResult(getApi)

    const columns = columnsFn(operate) // 表格列配置
    const getQueryParams = () => ({
        // @ts-ignore
        ...formRef.current?.getFieldsFormatValue(),
        ...otherParams
    })

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        console.log('处理后的成功回调触发了')
        reFresh()
    }
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const calcDelDisabled = () => selectedRowKeys.length
    return (<ProTable<any>
        style={{ flex: 1, overflow: 'scroll' }}
        scroll={{ x: 1300 }}
        columns={columns}
        params={otherParams} // 查询所需要的额外参数
        request={getListApi}
        formRef={formRef as any}
        options={{ reload: false, density: false, setting: false }}
        toolbar={{
            actions: [
                <ExportButton key="export" type='primary' action={`${BASEURL}/web/product/exportCardOperatorList`} param={getQueryParams} btnText="导出" />
            ],
        }}
        rowKey="tiId"
        pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
        actionRef={ref as any}
        search={{
            optionRender: (searchConfig, formProps, dom) => [...dom.reverse()]
        }}
    />)
}

