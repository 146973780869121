import { getAuthCodeArr } from "@utils/index";
import { DOMAIN_INFO, SET_MSG_INFO, SET_USER_INFO } from "../contants";
import {
  userInfo as userInfoApi,
  userPersonCertificationInfo,
  userCompanyCertificationInfo,
  userBuyedPro,
} from "@api/user";
import { handleDvmCommonListResult, handleDvmCommonResult } from "@utils/request";
import { homegetDomainInfo } from "@api/home";
import { msgGetCount, msgGetPageList } from '@api/message'

export const setUserInfo = (userInfo: any) => {
  return {
    type: SET_USER_INFO,
    userInfo,
  };
};

export const setDomainInfo = (domainInfo: any) => {
  return {
    type: DOMAIN_INFO,
    domainInfo,
  };
};

export const setMsgInfo = (msgInfo: any) => {
  return {
    type: SET_MSG_INFO,
    msgInfo
  }
}

// 异步获取当前账号用户信息
export const asyncSetUserInfo = () => {
    return async (dispatch: any) => {
        dispatch(setUserInfo({baseInfo: {}, certInfo: {}, permissions: []}))
        const { result } = await userInfoApi({}) as any
        const baseInfo = handleDvmCommonResult(result)
        const {userNature, ...rest} = baseInfo || {}
        let verifyState = 0 // 审核状态初始化为0
        let certInfo = { verifyState, ...rest } as any
        if (userNature === 1) { // 获取个人认证信息
            const { result } = await userPersonCertificationInfo({}) as any
            certInfo = handleDvmCommonResult(result)
            verifyState = certInfo?.urpVerifyState || 0
        } else if ([2,3].includes(userNature)) { // 获取企业认证信息
            const { result } = await userCompanyCertificationInfo({}) as any
            certInfo = handleDvmCommonResult(result)
            verifyState = certInfo?.ureVerifyState || 0
        }
        certInfo = {...certInfo, verifyState}
        baseInfo.verifyState = verifyState
        const { userId } = baseInfo
        const { data: buyedPro } = await userBuyedPro({userId}) as any
        const buyedProPermissions = (buyedPro||[]).map((v: any) => v.piCode) // 获取用户购买的产品，生成权限
        const userInfo = { baseInfo, certInfo, buyedPro, permissions: [...buyedProPermissions] }
        dispatch(setUserInfo(userInfo))
        return userInfo
    }
};

// 初始化个性化配置信息
// 包含企业名称，备案号，logo图片等等，目的是为了sass部署，灵活配置，以下是在拿不到数据或者拿数据之前赋的默认值
const initDomainInfoValue = {
  isnull: 1,
  ueSaasAboutUs:'',
  ueSaasFullName: "浙江卓见云科技有限公司",
  ueSaasIcpLicense: "浙ICP备16013515号-16",
  ueSaasLink: "卓见云=www.juncyun.com",
  ueSaasLogo: "fileServer/2022/11/29/edffcd3f235148b8a012796cf18e15ed.png",
  ueSaasName: "云极",
  ueSaasPhone: "0571-87960033",
  ueSaasIcon: 'fileServer/2022/11/29/26d29e517fe4457483c2b46b6500234f.ico',
  ueSaasPublicQrcode:
      "fileServer/2022/11/29/7ddbb4b8287c415c932dbba8ab8b5e4a.png",
  ueSaasWechatQrcode:
      "fileServer/2022/11/29/7ddbb4b8287c415c932dbba8ab8b5e4a.png",
  ueSaasAddress: '',
  ueSaasEmail: '15979290517',
};

// 获取个性化配置信息
export const asyncSetDomainInfo = () => {
  return async (dispatch: any) => {
    dispatch(setDomainInfo(initDomainInfoValue));
    const { value } = (await homegetDomainInfo({})) as any;
    const domainInfo = { ...initDomainInfoValue, ...value };
    dispatch(setDomainInfo(domainInfo));
  };
};

// 设置信息
export const asyncSetMsgInfo = () => {
  return async (dispatch: any) => {
    dispatch(setMsgInfo({}));
    const [ { result: result1 }, { result: result2 } ] = await Promise.all([msgGetCount({}), msgGetPageList({isReceive: 0, pageNum: 1, pageSize: 5 })]) as any
    const res1 = handleDvmCommonResult(result1)
    const msgTop5 = handleDvmCommonListResult(result2)
    const { recCount: unRead } = res1 
    const msgInfo = { unRead, msgTop5 };
    dispatch(setMsgInfo(msgInfo));
  };
};
