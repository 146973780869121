import { ProColumns } from "@ant-design/pro-table"

export const mockData = []

export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
  return [
    {
        title: '状态码',
        dataIndex: 'status'
    },
    {
        title: '数量(次)',
        dataIndex: 'num',
        sorter: (a, b) => a.num - b.num,
        render: (text: any, record: any) => record.num + '次'
    },
    {
        title: '占比(%)',
        dataIndex: 'percent',
        sorter: (a, b) => a.percent - b.percent,
        render: (text: any, record: any) => (record.percent || 0) + '%'
    }
  ]
}
