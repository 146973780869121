import { CertificationLink } from "@components/AccountCenter/SafeSetting"
import { Icon, NotFond } from "@components/Common"
import { StateType } from "@store/index"
import { Alert, Avatar, Button, Card, Col, Divider, Empty, InputNumber, Row, Tabs, Tag, Tooltip } from "antd"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import moneyIcon from '@assets/images/money-icon.png'
import cloudIcon from '@assets/images/cloud-icon.png'
import bugedProImg from '@assets/images/buyedPro-bg.png';
import emptyImg from '@assets/images/empty.png';
import boyImg from '@assets/images/boy.png';
import { ChargeModal } from "@components/CostCenter/Charge"
import { fileHttpUrl } from "@api/baseUrl"
import { useHistory } from "react-router-dom"
import { calcProLinkByProCode, phoneReg, productApi } from "@utils/index"
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import ProForm, { 
    ModalForm,
    ProFormText, 
    ProFormUploadButton, 
    ProFormSelect, 
    ProFormTextArea, 
    ProFormDependency,
    ProFormSwitch
} from '@ant-design/pro-form';
import { userSetMoneyLimit } from "@api/index"
import { asyncSetUserInfo } from "@store/actions"
import { stringify } from "qs"
import { Description } from "@components/Common/AlertTip"

const setMoneyLimitApi = productApi(userSetMoneyLimit)

// 设置余额不足提醒
const SetMoneyWarning = ({...rest}: ModalFormProps) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { baseInfo } = useSelector((store: StateType) => store?.userInfo) as any
    const { remindSwitch, moneyLimit, ueAlarmPhone, userPhone } = (baseInfo || {}) as any
    const onFinish = async (values: any) => {
        const { remindSwitch: warning, ...rest } = values || {}
        const { success, data } = await setMoneyLimitApi({...rest, remindSwitch: warning ? 1 : 0}, true) as any
        if (success) {
            dispatch(asyncSetUserInfo()) as any
            return true
        }
    }
    return (<ModalForm<any>
        layout="horizontal"
        labelCol={{span: 4}}
        wrapperCol={{span: 12}}
        onFinish={onFinish}
        modalProps={{
            destroyOnClose: true
        }}
        params={{remindSwitch, moneyLimit}}
        request={async() => {
            return { remindSwitch: remindSwitch ? true : false, moneyLimit, phone: ueAlarmPhone||userPhone }
        }}
        {...rest}
        >
            <Alert 
                description={<Description listTips={[
                    <span key="0">当账户余额低于预警阈值时，系统将发送短信提醒（最多连续提醒3天）</span>,
                    // <span key="1">可在<a onClick={() => history.push(`/account/safeSetting`)}>账号中心-安全设置</a>中修改接收提醒的手机号</span>,
                    '建议设置的阈值足够支撑现有业务运行'
                ]} />}
                type="warning"
                style={{marginBottom: 20}}
            />
            <ProFormText 
                width="md" 
                name="phone" 
                label="预警手机号" 
                fieldProps={{
                    placeholder: '请输入报警手机号', 
                }} 
                rules={[{ required: true, message: '请输入11位手机号码'}, { pattern: phoneReg, message: '非法手机号' }]}
            />
            <ProForm.Item
              label="提醒规则"
              rules={[{required: true, message: '请设置余额'}]}
            >  
                <span style={{display: 'inline-block', paddingTop: 5}}>账户余额小于</span> 
                <ProForm.Item 
                    name="moneyLimit" 
                    style={{display: 'inline-block', marginBottom: 0}}
                    rules={[
                        { required: true, message: '请输入警示余额',},
                        { pattern: /^[0-9]\d*$/, message: '请输入大于0的正整数' }
                    ]} 
                >
                    <InputNumber 
                        min={0} 
                        precision={0} 
                        // pattern={new RegExp(/^[0-9]\d*$/, 'g')}
                        formatter={value => `¥ ${Number(value)}`.replace(/^[0-9]\d*$/g, ',')}
                        parser={(value: any) => value.replace(/\¥\s?|(,*)/g, '')}
                        
                    />
                </ProForm.Item>元，发送短信提醒
            </ProForm.Item>
            <ProFormSwitch 
                name="remindSwitch" 
                label="预警通知" 
                initialValue={false}
                rules={[{required: false, message: '请设置提醒'}]}
            />
      </ModalForm>)
}

// 账号信息
const AccountInfo = () => {
    const history = useHistory()
    const { baseInfo } = useSelector((store: StateType) => store?.userInfo) as any
    const { username, userNature: type, verifyState: status } = (baseInfo || {}) as any

    return (<Card style={{ width: 458 }}>
        <Row justify="space-between">
            <Col style={{fontSize: 16, color: 'rgba(40,40,40,0.85)'}}>账号信息</Col>
            <Col>
               <a onClick={() => history.push(`/account/safeSetting`)}>更多 <Icon type="icon-jiantou_xiangyouliangci" /></a>
            </Col>
        </Row>
        <Row style={{marginTop: 12}}>
            <Col>
               <Avatar shape="square" style={{ width: 120, height: 120 }} src={boyImg} />
            </Col>
            <Col style={{padding: 12}}>
                <div>用户名: {username}</div>
                <div style={{margin: '10px 0' }}>
                    认证状态: <CertificationLink type={type} status={status} />
                </div>
                <div><Tag color="processing">主账号</Tag></div>
            </Col>
        </Row>
    </Card>)
}

// 费用信息
const FeeInfo = () => {
    const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
    const history = useHistory()
    const { baseInfo } = useSelector((store: StateType) => store?.userInfo) as any
    const { aciMoney, aciClouds, moneyLimit, remindSwitch, ueRechargeEnbale } = (baseInfo || {}) as any

    // const calcWarning = () => remindSwitch && (aciMoney||0) <= moneyLimit
    const calcWarning = () => (aciMoney||0) < 0 // 判断逻辑改成 与 0 判断 (测试，产品统一定的)

    return (<Card style={{flex: 1, marginLeft: 20}}>
        <Row justify="space-between">
            <Col style={{fontSize: 16, color: 'rgba(40,40,40,0.85)'}}>费用信息</Col>
            <Col>
               {/* 2023.1.9 优化需求: 费用中心跳转至云朵(很奇怪) */}
               <a onClick={() => history.push(`/cost/myClouds`)}>更多 <Icon type="icon-jiantou_xiangyouliangci" /></a>
            </Col>
        </Row>
        <Row align="middle" style={{marginTop: 12}}>
            <Col span={11}>
                <p className="flex items-center">
                  <img src={moneyIcon} style={{marginRight: 8}} />
                  <span className="text-gray-500">账户余额(元)</span>
                </p>
               <p style={{color: calcWarning() ? '#FF2020' : '#333'}}>
                    <span>¥</span>
                    <span style={{fontSize: 40}}>{aciMoney||0}</span>
                    {
                        calcWarning() ? <Tag color="#FFEBEB" style={{fontSize: 12, marginLeft: 12}}>
                            <span style={{color: '#FF2020'}}>余额不足</span>
                        </Tag> : null
                    }
                </p>
            </Col>
            <Col span={1}><Divider type="vertical" style={{height: 90,}} /></Col>
            <Col span={11}>
               <p className="flex items-center">
                <img src={cloudIcon} style={{marginRight: 8}} />
                <span className="text-gray-500">可用云朵&nbsp;</span><Tooltip title={<div>
                    云朵规则说明：<br />
                    1. 云朵是{ueSaasName}平台赠送给用户的虚拟币，一个云朵相当于1元人民币，可用于购买平台的产品。<br />
                    2. 购买产品时会先行抵扣云朵，不足部分使用余额进行支付。<br />
                    3. 订单退款时，云朵不支持退回；并且云朵无法转让、提现。<br />
                </div>}><Icon type="icon-ziyuan" /></Tooltip>
               </p>
               <p><span style={{fontSize: 40}}>{aciClouds||0}</span></p>
            </Col>
        </Row>
        <Row align="middle">
            { ueRechargeEnbale ? <ChargeModal trigger={<Button type="primary">充值</Button>} /> : null}
            <Button style={{margin: '0 16px'}} onClick={() => history.push(`/cost/paymentDetail`)}>收支明细</Button>
            <SetMoneyWarning title="余额不足提醒" trigger={<a style={{color: '#282828', borderBottom: '1px dashed #282828'}}>设置余额不足提醒</a>} />
        </Row>
    </Card>)
}

const ProductCardItem = ({item}: any) => {
    const history = useHistory()
    const { piName, describe, unit, piLogo, piModelType, productType, piCode } = item
    const proLink = calcProLinkByProCode(piCode)
    const jumpTo = () => history.push(proLink)
    return (<Col span={8}>
        <div className="transition duration-500 ease-in-out transform hover:border-blue-600 border" style={{ background: `url(${bugedProImg})`, backgroundSize: 'cover', padding: 20, display: 'flex', cursor: 'pointer'}}>
            <div style={{marginRight: 10}}><img src={fileHttpUrl(piLogo)} style={{width: 56, height: 56}} /></div>
            <div>
                <p>{piName}</p>
                <div>
                    <div style={{display: 'flex'}}>
                        {productType === 2 ? <Tag color="processing">按量扣费</Tag> : null }

                        {describe ? <div>
                            <span className="text-gray-500 mr-2">余量:</span>
                            <a>{describe}</a>
                        </div>: null}
                    </div>
                </div>
            </div>
            <div className="self-center text-right" style={{flex: '1'}}>
                 <Button style={{margin: 'auto 16px'}} type="primary" onClick={jumpTo}>去使用</Button>
            </div>
        </div>
    </Col>)
}

// 我的产品
const ProductCard = () => {
    const { buyedPro } = useSelector((store: StateType) => store?.userInfo) as any
    return (<Card title="我的产品">
        {
            (buyedPro||[]).length ? 
            <Row gutter={[16, 16]}>
                {(buyedPro||[]).map((v: any, i: number) => <ProductCardItem key={i} item={v} />)}
            </Row> :
            <Empty
                image={emptyImg}
                style={{height: 200, paddingTop: 0}}
                imageStyle={{ height: 136 }}
                description={<div><p style={{color: 'rgba(0, 0, 0, 0.45)'}}>暂无产品, <a href="/home/index">前去购买</a></p></div>}
            />
        }
    </Card>)
}


// const ProductServeLeft = () => {
//     return (<ul>
//         <li>基础类产品</li>
//         <li>基础类产品</li>
//         <li>基础类产品</li>
//         <li>基础类产品</li>
//         <li>基础类产品</li>
//     </ul>)
// }

const ProductServeRight = ({treeData}: any) => {
    const ProItem = ({item}: any) => {
        const history = useHistory()
        const jumpTo = ({piId}: any) => {
            history.push(`/home/product?${stringify({piId})}`)
        }
        return (<div>
            <h3 style={{paddingTop: 8, color: '#717272'}}>{item?.label}</h3>
            <Row wrap>
                {(item.children||[]).map((v: any) => (<Col 
                    key={v.value} 
                    className='shadow-md transition duration-500 ease-in-out transform hover:border-blue-600 border'
                    style={{
                        background: '#fff', 
                        padding: 10, 
                        marginRight: 10, 
                        marginBottom: 10, 
                        borderRadius: 2,
                        width: 200,
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                    onClick={() => jumpTo(v)}
                    >
                      <img src={fileHttpUrl(v.piLogo)} style={{width: 24, height: 24, marginRight: 6}} /> 
                      <span style={{color: '#282828'}}>{v.label}</span>
                </Col>))}
            </Row>
        </div>)
    }
    return (treeData||[]).map((v: any) => <ProItem key={v.value} item={v} />)
}

// 平台产品与服务
const ProductServe = () => {
    const { treeData } = useSelector((store: StateType) => store?.productData) as any
    const items = (treeData||[]).map((v: any, i: number) => ({
        label: v.label,
        key: v.id,
        children: <ProductServeRight key={v.id} treeData={v.children} />
    }))
    return (<Card title="平台产品与服务" style={{marginTop: 20}}>
         <Tabs 
           className='j-overview-product'
           style={{background: '#F5F5F5', border: '1px solid #E8E8E8', borderRadius: 4, minHeight: 300}}
           tabBarStyle={{background: '#fff', width: 150, paddingTop: 10}} 
           tabPosition="left" 
           items={[...items]} 
           tabBarGutter={2}
        />
    </Card>)
}

export default () => {
    return (<div>
        <Row style={{marginBottom: 20}}>
            <AccountInfo />
            <FeeInfo />
        </Row>
        <ProductCard />
        <ProductServe />
    </div>)
}