import { fileHttpUrl } from '@api/baseUrl';
import React, { useEffect, Children, useRef } from 'react';
import './index.less'


export function CarouselImg() {
    return (
        <div className='j-carousel-img-contentontent'>
            <Scroll>
                <div>
                    <img src={fileHttpUrl("fileServer/2022/11/07/05d22cac4fd84151be70315d5df3de5b.jpg")} className='item' alt="公司环境" />
                    <img src={fileHttpUrl("fileServer/2022/11/07/45a9f654c6a742019635eb14af2cb9a9.jpg")} className='item' alt="公司环境" />
                    <img src={fileHttpUrl("fileServer/2022/11/07/33b0f42aff7f4b648b89d5038ba61ce9.jpg")} className='item' alt="公司环境" />
                    <img src={fileHttpUrl("fileServer/2022/11/07/0bc5281303754af48f6319d442f2c082.jpg")} className='item' alt="公司环境" />
                    <img src={fileHttpUrl("fileServer/2022/11/07/1db400b3ca90405ba72cd97ffa55b1f0.jpg")} className='item' alt="公司环境" />
                    <img src={fileHttpUrl("fileServer/2022/11/07/918955e353d949618363a2bab3ad5a4f.jpg")} className='item' alt="公司环境" />
                    <img src={fileHttpUrl("fileServer/2022/11/07/d5ec0d80c55d44d096a25b093d1355e6.jpg")} className='item' alt="公司环境" />
                    <img src={fileHttpUrl("fileServer/2022/11/07/92fb5ffe4fff46318a2a65940a2ebb4c.jpg")} className='item' alt="公司环境" />
                    <img src={fileHttpUrl("fileServer/2022/11/07/d59c2a00ff5d4d0b9bd183a79596f65f.jpg")} className='item' alt="公司环境" />
                </div>
            </Scroll>
        </div>
    )
}
function Scroll(props: any) {

    return <div className='j-dom-Copy flex'>
        {
            Children.map(props.children, child => {
                console.log('child', child)
                return [//复制一份包裹的组件
                    React.cloneElement(child, {
                        className: 'scrollSource flex'
                    }),
                    React.cloneElement(child, {
                        className: 'scrollClone flex'
                    })
                ]
            })
        }
    </div>
}