import {SET_SMS_SETTING} from '../contants'
// 存储用户信息
export const smsSetting = (state = {}, action: { type: any; smsSetting: any }) => {
    const { smsSetting } = action
    switch(action.type) {
        case SET_SMS_SETTING: 
            return smsSetting
        default: 
            return state
    }
}