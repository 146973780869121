import React, { useState } from "react"
import { Col, Descriptions, Row, Table, Typography } from "antd"
import { ActionType, ProTable, ProTableProps } from "@ant-design/pro-table"
import { columnsFn } from "./data"
import { parseSearch, productApi } from "@utils/index"
import { BackBan } from "@components/Common"
import { getCostMyOrderInfo } from "@api/index"
import { useLocation } from "react-router"
import { handleDvmCommonListResult, handleDvmCommonPageListResult } from "@utils/request"
import { orderStatusOptions } from "../../data"
const { Paragraph } = Typography;
// 接口请求的 api 层
const getApi = productApi(getCostMyOrderInfo)  // 查询的接口封装

const BaseInfo = ({baseInfo}: any) => {
    const { oiCode, actualPay, orderTime, payTime } = baseInfo || {}
    return (<Descriptions bordered style={{padding: '0 20px', background: '#fff'}}>
    <Descriptions.Item label="订单号"><Paragraph copyable style={{margin: 0}}>{oiCode}</Paragraph></Descriptions.Item>
    <Descriptions.Item label="订单实付">{actualPay}</Descriptions.Item>
    <Descriptions.Item label="下单时间">{orderTime}</Descriptions.Item>
    <Descriptions.Item label="支付时间">{payTime}</Descriptions.Item>
  </Descriptions>)
}
interface OrderTableprops extends ProTableProps<any, any>{

}
const OrderTable = (props: OrderTableprops) => {
    const [baseInfo, setBaseInfo] = useState<any>({})
    const { search } = useLocation();
    const { oiId } = parseSearch(search)
    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { result } = await getApi({...params}, false)
        const dataSource = handleDvmCommonListResult(result, 'orderinfo')
        const baseInfo = handleDvmCommonListResult(result, 'basicinfo')
        setBaseInfo(baseInfo[0]|| {})
        // const { values, total } = data || {}
        return {
            data: dataSource || [],
            success: true,
            // total
        }
    }
    // const getListApi = handleDvmCommonPageListResult(getApi)
    const calcOrderTitle = () => orderStatusOptions.find(v => v.value === baseInfo.payStatus)?.label
    return (<div style={{background: '#fff'}}>
        <BackBan title={calcOrderTitle()}></BackBan>
        <div className="j-zen-ban" style={{margin: 20}}>基本信息</div>
        <BaseInfo baseInfo={baseInfo} />
        <div className="j-zen-ban" style={{margin: 20}}>订单详情</div>
        <ProTable<any>
            columns={columnsFn(() => null)}
            params={{oiId}} // 查询所需要的额外参数
            request={getListApi}
            options={{reload: false, density: false, setting: false}}
            toolbar={{}}
            search={false}
            rowKey="oiId"
            pagination={false}
            {...props}
        />
        <div style={{background: '#F5F5F5', padding: 20}}>
            <Row justify="end">
                <Col>订单应付:</Col>
                <Col style={{textAlign: 'right', width: 120, paddingRight: 10}}>{baseInfo.shouldPay}</Col>
            </Row>
            <Row justify="end">
                <Col>云朵抵扣:</Col>
                <Col style={{textAlign: 'right', width: 120, paddingRight: 10}}>{baseInfo.cloudPay}</Col>
            </Row>
            <Row justify="end">
                <Col>订单实付:</Col>
                <Col style={{textAlign: 'right', width: 120, paddingRight: 10}}>{baseInfo.actualPay}</Col>
            </Row>
        </div>
</div>)
}

export default OrderTable
