import React from "react"

export const proType = {
    proName: '国内短信',
    typeTitleName: '套餐类型',
    list: [
        {
            name: '验证码',
            typeTitleName:'套餐规格',
            list:[
                {
                    name: '10条',
                    typeTitleName:'有效时长',
                    list:[
                        {
                            name: '53个月',
                            price: 4
                        },
                        {
                            name: '44个月',
                            price: 2
                        },
                        {
                            name: '53个月',
                            price: 4
                        },
                        {
                            name: '44个月',
                            price: 2
                        },
                        {
                            name: '53个月',
                            price: 4
                        },
                        {
                            name: '44个月',
                            price: 2
                        },
                        {
                            name: '53个月',
                            price: 4
                        },
                        {
                            name: '44个月',
                            price: 2
                        },
                        {
                            name: '53个月',
                            price: 4
                        },
                        {
                            name: '44个月',
                            price: 2
                        }
                    ]
                },
                {
                    name: '50条',
                    typeTitleName:'有效时长',
                    list:[
                        {
                            name: '45个月',
                            price: 2
                        },
                        {
                            name: '12个月',
                            price: 1
                        }
                    ]
                }
            ]
        },
        {
            name: '通知',
            typeTitleName:'套餐规格',
            list:[
                {
                    name: '1000条',
                    typeTitleName:'有效时长',
                    list:[
                        {
                            name: '4个月',
                            price: 4
                        },
                        {
                            name: '68个月',
                            price: 2
                        }
                    ]
                },
                {
                    name: '1500条',
                    typeTitleName:'有效时长',
                    list:[
                        {
                            name: '44个月',
                            price: 43
                        },
                        {
                            name: '66个月',
                            price: 56
                        }
                    ]
                },
                {
                    name: '2500条',
                    typeTitleName:'有效时长',
                    list:[
                        {
                            name: '88个月',
                            price: 2
                        },
                        {
                            name: '55个月',
                            price: 23
                        }
                    ]
                }
            ]
        }
    ]
}

export const columns = [
    {
        title: '商品名称',
        dataIndex: 'piName',
        render: (text: string, record: any, index: number) => {
            return <div>
                <div>{text}</div>
                <div className="mt-2" style={{color: '#989898'}}>套餐类型： {record.packageType}</div>
                <div style={{color: '#989898'}}>套餐规格： {record.packgeModel}</div>
            </div>
        }
    }, 
    {
        title: '有效时长',
        dataIndex: 'effectiveTime',
    },
    {
        title: '商品单价',
        dataIndex: 'salePrice',
        render: (text: any, record: any)=> text + '元'
    },
    {
        title: '数量',
        dataIndex: 'amount',
    },
    {
        title: '优惠金额',
        dataIndex: 'thriftPrice',
    },
    {
        title: '小计',
        dataIndex: 'subtotal',
        render: (text: any, record: any)=> (record.salePrice * record.amount) + '元'
    },
]