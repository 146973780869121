import { homePageReg, isBrowser } from "@utils/util"

// 开发环境 nginx 代理路径
const PROXY_BASEAPI = `http://192.168.8.146:9191`  // 测试环境-代理API接口
const PROXY_PRE_BASEAPI = `http://192.168.8.161` // 预发环境-代理API接口
const PROXY_PROD_BASEAPI = `https://juncyun.com` // 生产环境-代理API接口

export const PROXY_ALIOSS = `https://cloud-parent.oss-cn-hangzhou.aliyuncs.com` // 导出的域名

// 文件上传后返回的绝对路径
export const fileHttpUrl = (url: string) => url ? homePageReg.test(url) ? url : PROXY_ALIOSS + `/${url}` : ''

// 文件导出地址处理方法
export const fileExportUrl = (url: string) => homePageReg.test(url) ? url : PROXY_ALIOSS + `/${url}`

// 接口调用相对路径
const APIURL = `/cloud_manage`
const UPLOADURL = `/common_service`

// 正式环境api 全路径（如果生产环境做nginx 代理了则不需要, ssr 渲染时候需要）
export const TESTBASEURL = PROXY_BASEAPI + APIURL // 测试环境api 前缀
export const PREBASEURL = PROXY_PRE_BASEAPI + APIURL // 预发环境api 前缀
export const PRODBASEURL = PROXY_PROD_BASEAPI + APIURL // 生产环境api 前缀

export const MockUrl = `https://www.fastmock.site/mock/6d8f8fb1cd319200694403efde36d5b4/cloudPole/api`

export const BASEURL = isBrowser() ? APIURL : process.env.UMI_ENV == 'prod' ? PRODBASEURL : process.env.UMI_ENV == 'pre' ? PREBASEURL : TESTBASEURL
export const BASEUPLOADURL = UPLOADURL

