import { ProColumns } from "@ant-design/pro-table";
import { Input, Select } from "antd";
import React, { ReactNode } from "react";

const options = [{label: 'options1', value: 1}, {label: 'options2', value: 2}]

/**
 * 列表表格配置:
 *  自定义表格 CustomerDemo 和 antd 升级版组件 ProTableDemo 都可使用, 
 * */
export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '手机号',
            dataIndex: 'ublPhone',
            key: 'ublPhone',
        },
        {
            title: '添加时间',
            dataIndex: 'createTime',
            key: 'createTime',
            hideInSearch: true
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            valueType: 'option',
            render: (text: any, record: any) => [
                <a key="删除" onClick={() => operate('删除', record)}>删除</a>,
            ]
        }
    ]
}