import { Button, message, Modal, Table, Tabs } from "antd"
import React, { useEffect, useRef, useState } from "react"
import ProTable, { ActionType } from "@ant-design/pro-table";
import { copyText, filterObj, productApi } from "@utils/index";
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home";
import { BASEURL, cdnAddDomain, cdnCheckDomainTxt, cdnCurrentUserWhetherAllSpeed, cdnGenerateDomainTxt, cdnGetDomainList, cdnStartOrStopDomain, cdnRemoveDomain } from "@api/index";
import { ExportButton } from "@components/Common";
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import moment from "moment";
import { ProFormDependency } from '@ant-design/pro-form';
import ProForm, {
    ModalForm,
    ProFormText,
    ProFormSelect,
    ProFormRadio,
    ProFormCascader,
    ProFormTextArea,
    ProFormDateTimePicker,
    ProFormCheckbox,
    ProFormUploadButton
} from '@ant-design/pro-form';
import { CopyOutlined } from '@ant-design/icons';
import { accelerateType, domainColumnsFn_ip, domainColumnsFn_domain, columnsFn, sourceType, tooltipData } from "./data";
import FormItem from "antd/es/form/FormItem";
import { useHistory } from "react-router-dom";

import './index.less'
import { stringify } from "qs";
import AlertTip from "@components/Common/AlertTip";

// 接口请求的 api 层
const getApi = productApi(cdnGetDomainList)  // 查询的接口封装
const addDomainApi = productApi(cdnAddDomain) // 新增域名
const testCdnCheckDomainTxt = productApi(cdnCheckDomainTxt) // 校验加速域名
const cdnStartOrStopDomainApi = productApi(cdnStartOrStopDomain) // 域名启用停用
const delApi = productApi(cdnRemoveDomain) // 域名的删除 接口


interface AddDomainModalProps extends ModalFormProps {
    params?: any;
    onSuccess?: () => void;
}

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
 
// 子组件---输入项---源站地址
const DomainAddress = ({formRef, originType = 1}:any) => {
    const [tableData, setTableData] = useState([
        {
          ipOrDomain: '',
          activeStandby: 1
        }
    ])
    
    // 表格修改后立刻同步值
    const setFormValue = (data: any) => {
      data.forEach((item: any) => {
         if(item.ipOrDomain !== ''){
          formRef.current?.setFieldsValue({sourceSiteIpOrDomainDTOS: data })
         }
      });
    }

    const operate = (type: any, record: any) => {
        const { id, index, activeStandby } = record || {}
        // 新增
        if (type === 'add') {
            setTableData([...tableData, {
                ipOrDomain: '',
                activeStandby: 0
            }])
            // 数据改变之后立即设置表单数据
            setFormValue([...tableData, {
              ipOrDomain: '',
              activeStandby: 0
          }])
        // 删除
        } else if (type === 'del') {
            const data = [...tableData]
            data.splice(index, 1)
            setTableData(data)
            // 数据改变之后立即设置表单数据
            setFormValue(data)
        // 修改域名
        } else if (type === '修改域名') {
            const data = [...tableData]
            data[index] = record
            setTableData(data)
            // 数据改变之后立即设置表单数据
            setFormValue(data)
        // 修改源站优先级状态
        } else if (type === '源站优先级') {
          console.log(111, activeStandby)
          if (activeStandby) {
            const data = [...tableData]
            data.forEach(element => {
              element.activeStandby = 0
            })
            data[index].activeStandby = 1
            setTableData(data)
            // 数据改变之后立即设置表单数据
            setFormValue(data)
          } else {
            message.info('主源站无法关闭，请直接开启您要设置的源站')
          }
        }
        
    }
     const columns_ip = domainColumnsFn_ip(operate, tableData) as any // 表格列配置
    const columns_domain = domainColumnsFn_domain(operate, tableData) as any // 表格列配置
   console.log(1112, originType)
    return (
        <div>
            <Table dataSource={tableData} pagination={false} columns={originType === 1 ? columns_ip : columns_domain } />
        </div>
    )
}

// 添加域名的表单
const AddDomainModal = ({ params, onSuccess, ...rest }: AddDomainModalProps) => {
    const formRef = useRef<ProFormInstance>();
    const [sourceName, setSourceName] = useState('')
    const [sourceTestValue, setSourceTestValue] = useState('')
    const [testResult, setTestResult] = useState(false)


    const onAfterClose = ()=> {
      setSourceName('')
      setSourceTestValue('')
    }

    // 随机字符乱码
    const createString = ()=> {
      const items = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
      console.log(items[Math.floor(Math.random() * items.length)])
      return items[Math.floor(Math.random() * items.length)] +
      items[Math.floor(Math.random() * items.length)] +
      items[Math.floor(Math.random() * items.length)] +
      items[Math.floor(Math.random() * items.length)] +
      items[Math.floor(Math.random() * items.length)] +
      items[Math.floor(Math.random() * items.length)]
    }


    const domainNameChange = async (e: any) => {
      const domain = e.target.value || {}
      const domainValue = `${createString()}.${domain.split('.').splice(-2).join('.')}`
      const { data } = await cdnGenerateDomainTxt({ domain: domainValue }) as any
      setSourceTestValue(data)
      setSourceName(domainValue)
    }

    // 源站校验  点击校验事件 
    const sounrceTest = async () => {
      if (sourceTestValue) {
        const { data } = await testCdnCheckDomainTxt({ domain:sourceName }, true) as any
        if(data) {
          setTestResult(data)
          formRef.current?.setFieldsValue({domainTest: data })
          // setTestResult(1)
          formRef.current?.setFieldsValue({domainTest: 1 })
        }
      } else {
        message.info('请正确填写域名或等待生成域名值')
      }
    }
    const onFinish = async (values: any) => {
        const data = { 
            ...values,
            ...params,
            serviceArea: 1
        }
        const { success } = await addDomainApi(
            filterObj(data), 
            true,
            () => {
                clearData()
                onSuccess ? onSuccess() : null
            }
        )
        return success
    }
    const Explain_1 = (
        <div>
            ①选择已经审核通过的模板。<br />
            ②如果短信模板有变量，仅支持上传文件。<br />
            ③如果短信模板无变量，支持上传文件、通讯录或者手动输入号码 。<br />
        </div>
    )
    const Explain_2 = (
        <div>
            ①可在下方直接输入“模板内容”。<br />
            ②上传手机号码的方式：excel上传/通讯录/手动输入。<br />
            ③不支持有变量的短信内容 。<br />
            ④任务提交以后进入待审核,审核通过后将立即执行发送任务（1小时内审核） 。<br />
        </div>
    )
   
      const clearData = () => {
        formRef.current?.resetFields()
      }
    return (
      <ModalForm<any>
        layout="horizontal"
        width={800}
        formRef={formRef}
        {...layout}
        onFinish={onFinish}
        preserve={false}
        modalProps={{
          afterClose: onAfterClose,
          onCancel: clearData,
        }}
        {...rest}
      >
        <div className="j-domainAdmin-titleText">基础信息</div>
        {/* 加速域名 */}
        <ProFormText
          width="md"
          name="domainName"
          rules={[{ required: true, message: "请输入加速域名" }]}
          label="加速域名"
          validateTrigger='onBlur'
          fieldProps={{
            onBlur: (e)=> domainNameChange(e),
            
          }}
          tooltip={tooltipData['JSYM']}
          placeholder="请输入加速域名"
        />
        {/* 加速类型 */}
         <ProFormSelect
        width="md"
        name="businessType"
        label="加速类型"
        showSearch
        initialValue={1}
        fieldProps={{
            placeholder: '请选择加速类型',
            defaultValue: 1,
        }}
        options={[]}
        request={
            async () => {
            const { data } = await cdnCurrentUserWhetherAllSpeed({pageNum: 1, pageSize: 999, utlSmsArea: 2,utlVerifyState:2}) as any
            // const data = 1
            return data ? accelerateType : accelerateType.slice(0, 3)
          }
        }
        rules={[{ required: true, message: '请选择加速类型' }]}
     /> 
        {/* 加速区域 */}
        <FormItem
          name="serviceArea"
          initialValue={1}
          rules={[{ required: true, message: "" }]}
          label="加速区域"
          tooltip={tooltipData['JSQY']}
        >
           中国境内
        </FormItem>
        <div className="j-domainAdmin-titleText">源站信息</div>
        {/* 源站类型 */}
        <ProFormSelect
          width="md"
          name="originType"
          label="选择源站类型"
          initialValue={1}
          fieldProps={{
            allowClear: false,
            placeholder: "请选择源站类型",
            defaultValue: 1,
          }}
          tooltip={tooltipData['YZLX']}
          options={sourceType}
          rules={[{ required: false, message: "请选择源站类型" }]}
        />

        <ProFormDependency
            name={["originType"]}
          >
            {({ originType }: any) => {
              return (
                <>
                  {/* 源站地址输入项 */}
                  <FormItem
                    name="sourceSiteIpOrDomainDTOS"
                    rules={[{ required: true, message: "请输入源站地址" }]}
                    label="源站地址"
                  >
                    <DomainAddress
                      originType={originType}
                      formRef={formRef}
                    />
                  </FormItem>
                  {/* 源站校验 */}
                  <FormItem
                    name="domainTest"
                    // fieldProps={{
                    // initialValue={1}
                    rules={[{ required: true, message: "请先完成校验" }]}
                    // }}
                    label="加速区域"
                    tooltip={tooltipData['YZJY']}
                  >
                    <div>1. 在源站域名DNS服务提供商处创建一条对应的源站域名解析TXT记录，且记录内容为：</div>
                    <div  className=" mt-2">域名：<span className="text-blue-500">{ sourceName || '请先填写加速域名' }</span></div>
                    <div>值：
                      <span className="text-blue-500">{ sourceTestValue || '请先填写加速域名' }</span>
                      {sourceTestValue ? <CopyOutlined onClick={()=> copyText(sourceTestValue)} type="copy" /> : null}
                    </div>
                    <div className=" mt-2">
                      2. 已配置，
                      {!testResult ? <Button type="primary" ghost size="small" onClick={()=> sounrceTest()}>点击校验</Button> : null}
                      {testResult ?  <span style={{color:'rgba(82, 196, 26, 1)'}}>已校验</span> : null }
                    </div>
                  </FormItem>
                </>
              )
            }}
          </ProFormDependency>
      </ModalForm>
    );
}





export default () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [modalProps, setModalProps] = useState({visible: false, title: '新增', params: {}})
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    const formRef = useRef<ProFormInstance>()
    const history = useHistory()


    // operate 函数为增删改查需要处理的业务
    const operate = (type: any, record: any) => {
      console.log(selectedRowKeys)
      const { id, domainName = ''} = record || {}
        if (type === 'add') {

        } else if (type === 'open') {
          Modal.confirm({
            title: '确认要启用域名吗?',
            content: '点击确定将启用这些域名',
            okText: '确认',
            centered: true,
            cancelText: '取消',
            onOk: async() => {
                await cdnStartOrStopDomainApi({domainIds: id ? [id] : selectedRowKeys, domainStatus: 1}, true, () => {
                    reFresh()
                })
            },
        });
        } else if (type === 'close') {
          Modal.confirm({
            title: '确认要停用域名吗?',
            content: '点击确定将停用这些域名',
            okText: '确认',
            centered: true,
            cancelText: '取消',
            onOk: async() => {
                await cdnStartOrStopDomainApi({domainIds:id ? [id] : selectedRowKeys, domainStatus: 2}, true, () => {
                    reFresh()
                })
            },
        });
        } else if (type === 'del') {
          Modal.confirm({
            title: '确认要删除域名吗?',
            content: '删除后当前域名将永久删除，不可恢复。',
            okText: '确认',
            centered: true,
            cancelText: '取消',
            onOk: async() => {
                await delApi({domainIds: id ? [id] : selectedRowKeys}, true, () => {
                    reFresh()
                })
            },
        });
        } else if (type === '配置管理') {
          history.push(`/product/cdn/domianConfig?${stringify({domainId: id, domainName})}`)
        }
    }

    const calcDelDisabled = () => selectedRowKeys.length

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params}, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total: 1
        }
    }
    
    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        console.log('处理后的成功回调触发了')
        setModalProps({
            ...modalProps,
            visible: false
        })
        reFresh()
    }

    const columns = columnsFn(operate) // 表格列配置
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const getQueryParams = () => ({
        // @ts-ignore
        ...formRef.current?.getFieldsFormatValue()
    })

    const listTips = [
      '可添加的域名数量上限为100个。',
      '成功添加域名后，系统为您分配的CNAME域名，您需到您的域名解析服务商处为此域名配置CNAME记录，CDN加速服务才会生效。',
      '当域名的源站有资源更新或有配置变更，可提交缓存刷新任务，保证全网用户可访问到最新资源或正常访问。'
    ] as any


    return (<div>
              <div style={{background: 'white'}} className='p-4'>
                <AlertTip type="warning" listTips={listTips} />
              </div>
              <ProTable<any>
                style={{flex: 1, overflow: 'scroll'}}
                scroll={{x: 1300}}
                columns={columns}
                params={{}} // 查询所需要的额外参数
                request={getListApi}
                options={{reload: false, density: false, setting: false}}
                toolbar={{
                    actions: [
                        <AddDomainModal  onSuccess={onSuccess} key="btn0" title=" 添加域名" trigger={<Button type='primary'>添加域名</Button>} />,
                        <Button key="btn1" disabled={!calcDelDisabled()} onClick={() => operate('open', null)}>批量启用</Button>,
                        <Button key="btn0" disabled={!calcDelDisabled()} onClick={() => operate('close', null)}>批量停用</Button>,
                        <Button key="btn2" disabled={!calcDelDisabled()} onClick={() => operate('del', null)}>批量删除</Button>,
                    ],
                }}
                rowKey="id"
                pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
                actionRef={ref as any}
                formRef={formRef}
                search={{
                    optionRender: (searchConfig, formProps, dom) => {
                        return [ dom[1], <Button key="reset" onClick={() => {
                            const { onClick: onSearch } = (dom[1] as any)?.props || {}
                            formRef.current?.resetFields()
                            formRef.current?.setFieldsValue({tiId: '', sendTime: [moment().add(0, 'days'), moment()]})
                            // ref.current?.reloadAndRest()
                            onSearch && onSearch()
                        }}>重置</Button> ]
                    }
                }}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
                }}
            />
          </div>)
}
