
import React, { useState, useRef, forwardRef, useImperativeHandle, useCallback } from "react"
import { Button, Col, message, Popover, Row, Tag } from "antd"
import './index.less'
import { isBrowser, SMSTEMPVAR_CUSTOM, SmsTempVarType } from "@utils/index"
import Icon from "../Icon"

// 处理 富文本编辑内容
const handleTemplateContent = (nodeList: any[]) => {
    let content: any = '', variables: any[] = []
    nodeList.forEach(node => {
        const { nodeName, value, data } = node
        if (nodeName === 'INPUT') {
            content += '${' + value + '}'
            variables.push({ variableName: value })
        } else if (nodeName === 'BR') {
            // 类型div则代表用户有换行
           content += '\n'
        } else {
            content += node.textContent
        }
    })
    // 解决 html &nbsp；空格 转义问题
    content = content.split('').map((ch: string) => ch.charCodeAt(0) === 160 ? ' ' : ch).join('')
    return { content, variables }
}

/**
 * 待完善: ${type}_${str}
 * 1.根据 type 类型区分 str自增，例如变量类型 other 类型 和 url 类型都同时存在 n 个区分为:
 *   other_a  other_b  other_c  other_d ...
 *   url_a  url_b url_c url_d ...
 * 2. 计算如果 存在 other_c 那么在此新增的变量如何命名，str 该如何自增？
*/
const word = 'abcdefghijklmnopqrstuvwxyz'
const codeValue = (value: number, type?:any) => {
    const prelen = Math.floor(value/26)
    const suflen = value % 26
    const prefix = prelen ? word[prelen-1] : ''
    const suffix = word[suflen]
    const str = prefix + suffix
    // console.log(str, prefix, suffix, value)
    return value ? `${type}_${str}` : type
}

// 富文本中生成 input 节点
const createInputNode = (value: any, type? :string, title?: string, ) => {
   if (isBrowser()) {
    let input = document.createElement('input')
    input.setAttribute('class', `inputVar_${type}`)
    input.setAttribute('type', type || '')
    input.setAttribute('value', codeValue(value, type))
    // input.setAttribute('onkeyup', "value=value.replace(/[^a-zA-Z_]/g,'')")
    const reg = new RegExp(`^(${type}_)([a-zA-Z]+)`)
    input.setAttribute('oninput', `value=${reg}.test(value) ? ${reg}.exec(value)[0] : "${type}_"`)
    input.setAttribute('title', title||'')
    return input
   }
}

const findNotEmptyTextNode = (node: any) => {
    let textNode = node.nextSibling
    while(![null, undefined].includes(textNode) && !((textNode.nodeName == '#text') && (textNode.nodeValue !== ''))) {
        textNode = textNode.nextSibling
    }
    return textNode
}

// 设置光标位置
const setCursorPosition = (node: any, selection: any, range: any, input: any) => {
    const { startContainer, } = range
    const that = findNotEmptyTextNode(startContainer) // 查找当前光标的下一个文本节点
    const inputNext = findNotEmptyTextNode(input) // 查找当前插入的 input 元素的下一个文本节点
    if (that && that !== null) { // 如果 光标的下一个文本节点存在， 则focus
        selection.collapse(that, 1);
        selection.modify('move', 'left', 'character')
    } else if (input && inputNext) { // 如果 插入的 input 的下一个文本几点存在， 则focus
        selection.collapse(inputNext, 1);
        selection.modify('move', 'left', 'character')
    } else { // 否则光标移到最后
        const wRange = window.getSelection()
        wRange?.selectAllChildren(node)
        wRange?.collapseToEnd()
    }
}

export const handleSmsTempHtmlToData = (html: string)=> {
    if (isBrowser()) {
      const dom = document.createElement('div')
      dom.innerHTML = html
      const nodeList = dom.childNodes
      // console.log('nnnnode', nodeList)
      const res = handleTemplateContent(nodeList as any)
      return res
    }
}

type VarType = 'code' | 'url' | 'other'
// 模版内容组件
type TemplateContentprops = {
    showInsertBtn?: string[];
    popoverContent?: any;
    value?: any;
    varMaxCount?: number; // 允许的最大变量数量
    onChange?: (html: any) => void;
    type?: number; //短信类型   验证码 / 通知 / 营销、。。。
}
const TemplateContent = ({value: pValue,type, onChange, showInsertBtn = ['code'], popoverContent, varMaxCount, ...rest}: TemplateContentprops, ref: any) => {
    const [value, setValue] = useState<any>(pValue|| '')
    const editorDivRef = useRef<any>(null)
    // 添加变量
    const addVariable = (typeString:string)=> {
        const editorDom = editorDivRef.current?.ownerDocument.defaultView || editorDivRef.current?.ownerDocument.parentWindow
        const selection = editorDom.getSelection()
        const range = selection.getRangeAt(0)
        const { commonAncestorContainer } = range
        editorDivRef.current?.focus();
        const nodeList = editorDivRef.current?.childNodes
        const res = handleTemplateContent(nodeList)
        const varCount = res.variables.length
        if (varMaxCount && varCount >= varMaxCount) {
            return message.warning(`最多${varMaxCount}个变量`)
        }
        const input = createInputNode(varCount, typeString)
        const flag = editorDivRef.current.contains(commonAncestorContainer) // 是否在编辑器里面
        if (flag) {
            range.insertNode(input)
            // 新增变量之后手动取消变量选中状态
            window.getSelection()?.empty()
            setCursorPosition(editorDivRef.current, selection, range, input) // 设置光标位置
            onInput()
        } else {
            message.warning('请在编辑器里面插入')
        }
    }

    const onInput = (e?: any) => {
        const target = e?.target
        if (target?.nodeName === 'INPUT') {
            target.setAttribute('value', target.value)
        }
        const html = editorDivRef.current.innerHTML
        onChange && onChange(html)
    }

    const onPaste = async (e: any) => {
        e.preventDefault()
        const text = e.clipboardData.getData('Text')
        document.execCommand('insertText', false, text)
    }

    useImperativeHandle(ref, () => ({
        setValue: (val: string) => {
            editorDivRef.current.innerHTML = val
            setValue(val)
        }
    }));

    return (<div className="j-sms-template-editor">
        <Row className="editor-toolbar" justify="space-between">
            <Col>
                { showInsertBtn?.includes('url') ? <Button size="small" className="mr-2 addUrlButton" onClick={()=> addVariable('url')}>插入网址变量</Button> : null }
                { showInsertBtn?.includes('other') ? <Button size="small" className="mr-2 addVariableButton" onClick={()=> addVariable('other')}>插入其他变量</Button> : null }
                { showInsertBtn?.includes('code') ? <Button size="small" className="mr-2 addVariableButton" onClick={()=> addVariable('code')}>插入变量</Button> : null }
                
            </Col>
            {/* <Col> */}
               <Popover placement="rightTop" content={popoverContent}>
                   <a>变量说明 <Icon type="icon-ziyuan" /></a>
               </Popover>
            {/* </Col> */}
        </Row>
        <span
          contentEditable={true}
          ref={editorDivRef}
          className='editor-content'
          onInput={onInput}
          onPaste={onPaste}
          dangerouslySetInnerHTML={{__html: value}}
          {...rest}
        ></span>
    </div>)
}

// 根据变量类型计算允许的最大变量数
export const calcVarMaxCountBySmsType = (type: SmsTempVarType) => {
    switch (type) {
        case SmsTempVarType.CAPTCHA:
            return 1
        case SmsTempVarType.CUSTOM:
            return 9999  // 原本是最多 8个 v2.6.0 版本需求方说不做限制
        case SmsTempVarType.NOVAR:
            return 0
        default:
            return undefined
    }
}

// 根据变量类型计算要显示的插入 btn
export const calcShowInsertBtn = (type: SmsTempVarType) => {
    switch (type) {
        case SmsTempVarType.CAPTCHA:
            return ['code']
        case SmsTempVarType.CUSTOM:
            return ['url', 'other']
        case SmsTempVarType.NOVAR:
            return []
        default:
            return ['code']
    }
}

export default forwardRef(TemplateContent)

