import { ProColumns } from "@ant-design/pro-table"
import { smsSignPageList, smsTempPageList } from "@api/index"
import { DateRangeWithDisabled, Icon } from "@components/Common"
import { productApi, smsTypeOptions } from "@utils/index"
import { handleDvmCommonPageListResult } from "@utils/request"
import { Popover, Tooltip } from "antd"
import React from "react"

const timerOptions = [
    {
        label: '是',
        value: 1
    },
    {
        label: '否',
        value: 0
    }
]

const taskStateOptions = [
    {
        label: '未执行',
        value: 0
    },
    {
        label: '执行中',
        value: 1
    },
    {
        label: '执行完成',
        value: 2
    },
    {
        label: '取消执行',
        value: 9
    },
]

const valueEnum = {
    0: { text: '未执行', status: 'Default' },
    1: { text: '执行中', status: 'Processing' },
    2: { text: '执行完成', status: 'Success' },
    9: { text: '取消执行', status: 'Error' },
}

const getSignApi = productApi(smsSignPageList)  // 查询的接口封装
const getSignListApi = handleDvmCommonPageListResult(getSignApi)

const getTempApi = productApi(smsTempPageList)  // 查询的接口封装
const getTempListApi = handleDvmCommonPageListResult(getTempApi)

const Content = ({record}: any) => {
    const { tiId, usName, utlName, smsContent, tiAmount, susCount, failCount, otherCount, tiExecMemo } = record || {}
    return (<div>
        <div>签名名称：{usName}</div>
        <div>模板名称：{utlName}</div>
        <div style={{display: 'flex'}}>短信内容：<span style={{display: 'inline-block', width: 360, wordBreak: 'break-all'}}>{smsContent}</span></div>
        <div>发送总数：{tiAmount}</div>
        <div>发送成功：{susCount}</div>
        <div>发送失败：{failCount}</div>
        <div>失败原因：{tiExecMemo}</div>
        <div>发送中：{otherCount}</div>
      </div>)
}

export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '任务ID',
            dataIndex: 'tiId',
            key: 'tiId',
            order: 7,
        },
        {
            title: '任务名称',
            dataIndex: 'tiName',
            key: 'tiName',
            order: 6
            // hideInSearch: true
        },
        {
            title: '短信类型',
            dataIndex: 'tiSmsType',
            key: 'tiSmsType',
            order: 4,
            valueType: 'select',
            request: async() => smsTypeOptions,
            search: {
                transform: (value) => {
                    return {
                        msgType: value,
                    }
                },
            },
            // hideInSearch: true
        },
        {
            title: '短信签名',
            dataIndex: 'usCode',
            key: 'usCode',
            order: 10,
            valueType: 'select',
            request: async() => {
                const { data } = await getSignListApi({pageSize: 1000, utlSmsArea: 1, dvmControl: 'smsSign:query'}, {}, {})
                return (data||[]).map(({usName: label, usCode: value}: any) => ({label, value}))
            },
            // hideInSearch: true
        },
        {
            title: '短信模版',
            dataIndex: 'utlCode',
            key: 'utlCode',
            valueType: 'select',
            request: async() => {
                const { data } = await getTempListApi({pageSize: 1000, utlSmsArea: 1}, {}, {})
                return (data||[]).map(({utlName: label, utlCode: value}: any) => ({label, value}))
            },
            order: 9
            // hideInTable: true
        },
        {
            title: '模版内容',
            dataIndex: 'utlContent',
            key: 'utlContent',
            order: 8,
            width: 240
            // hideInSearch: true
        },
        {
            title: '定时任务',
            dataIndex: 'tiIsTiming',
            key: 'tiIsTiming',
            order: 1,
            valueType: 'select',
            request: async() => timerOptions
            // render: (text: any, record: any) => record.tiIsTiming ? '是' : '否'
        },
        {
            title: '任务状态',
            dataIndex: 'tiTaskState',
            key: 'tiTaskState',
            valueType: 'select',
            valueEnum,
            order: 5,
            request: async () => taskStateOptions
        },
        {
            title: '创建时间',
            dataIndex: 'tiCreateTime',
            key: 'tiCreateTime',
            valueType: 'dateRange',
            fieldProps: { },
            order: 3,
            search: {
                transform: (value) => {
                    return {
                        createStartTime: value[0] ? value[0] + ' 00:00:00' : undefined,
                        createEndTime: value[1] ? value[1] + ' 23:59:59' : undefined
                    }
                },
            },
            renderFormItem: (props) => <DateRangeWithDisabled />,
            render: (text: any, record: any) => record.createTime
        },
        {
            title: '执行时间',
            dataIndex: 'tiStartTime',
            key: 'tiStartTime',
            valueType: 'dateRange',
            fieldProps: { },
            order: 2,
            search: {
                transform: (value) => {
                    return {
                        tiStartTime: value[0] ? value[0] + ' 00:00:00' : undefined,
                        tiEndTime: value[1] ? value[1] + ' 23:59:59' : undefined
                    }
                },
            },
            renderFormItem: (props) => <DateRangeWithDisabled />,
            render: (text: any, record: any) => record.tiExecTime
        },

        {
            title: '发送号码总数',
            dataIndex: 'tiAmount',
            key: 'tiAmount',
            hideInSearch: true,
        },
        {
            title: '号码成功数',
            dataIndex: 'susCount',
            key: 'susCount',
            hideInSearch: true,
        },
        {
            title: '号码失败数',
            dataIndex: 'failCount',
            key: 'failCount',
            hideInSearch: true,
            render: (text: any, record: any) => <>
                {record.failCount}
                {
                    record.failCount > 0 ? <><Tooltip title="下载失败号码">
                        <Icon type="icon-xiazai" style={{color: '#479ad0', marginRight: 8}} onClick={() => operate('下载失败号码', record)} />
                    </Tooltip>
                    <Tooltip title="失败号码重新发送，仅限重发一次">
                        <Icon type="icon-zhongxinfasong" style={{color: '#479ad0'}} onClick={() => operate('失败号码重新发送', record)} />
                    </Tooltip></> : null
                }
            </>
        },
        {
            title: '号码发送中',
            dataIndex: 'otherCount',
            key: 'otherCount',
            hideInSearch: true,
        },
       
        // {
        //     title: '发送时间',
        //     dataIndex: 'tiStartTime',
        //     key: 'tiStartTime',
        //     valueType: 'dateRange',
        //     fieldProps: { },
        //     search: {
        //         transform: (value) => {
        //             return {
        //                 tiStartTime: value[0] ? value[0] + ' 00:00:00' : undefined,
        //                 tiEndTime: value[1] ? value[1] + ' 23:59:59' : undefined
        //             }
        //         },
        //     },
        //     render: (text: any, record: any) => record.tiExecTime
        // },
        // {
        //     title: '签名',
        //     dataIndex: 'usCode',
        //     key: 'usCode',
        //     valueType: 'select',
        //     request: async() => {
        //         const { data } = await getSignListApi({pageSize: 1000, utlSmsArea: 1}, {}, {})
        //         return (data||[]).map(({usName: label, usCode: value}: any) => ({label, value}))
        //     },
        //     hideInTable: true
        // },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            valueType: 'option',
            width: 150,
            fixed: 'right',
            render: (text: any, record: any) => [
                // <Popover content={<Content record={record} />} title="任务详情" trigger="hover" key="详情">
                //     <a style={{marginRight: 8}}>任务详情</a>
                // </Popover>,
                [2].includes(record.tiTaskState) ? <a key="发送明细" onClick={() => operate('发送明细', record)}>发送明细</a> : null,
                [0].includes(record.tiTaskState) && record.tiIsTiming === 1 ? <a key="取消发送" onClick={() => operate('取消发送', record)}>取消发送</a> : null,
                // <a key="删除" onClick={() => operate('删除', record)}>删除</a>,
            ]
        }
    ]
}


export const sendType = [
    {
        value: 1,
        label: '选择已审核模板发送'
    },
    {
        value: 2,
        label: '填写模板内容发送'
    }
]