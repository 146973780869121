import ProTable, { ActionType } from "@ant-design/pro-table"
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home"
import { productApi } from "@utils/util"
import { Button, Card, Form, Input, Modal, Tabs } from "antd"
import React, { useRef, useState } from "react"
import ProForm, { 
    ModalForm,
    ProFormText, 
    ProFormSwitch
} from '@ant-design/pro-form';
import { smsDataPushQuery, smsDataSmsReceipt, smsDataUpdateUpReply } from "@api/index"
import { handleDvmCommonResult } from "@utils/request"
import { useDispatch, useSelector } from "react-redux"
import { StateType } from "@store/index"
import { asyncSetSmsSetting } from "@store/actions"
import AlertTip from "@components/Common/AlertTip"

const updateApi_back = productApi(smsDataSmsReceipt)
const updateApi = productApi(smsDataUpdateUpReply)

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 12 },
};

const UpTooltip = () => {
    return (<>
        <div style={{padding: '10px 0'}}>请根据以下规范设置回调接口：</div>
        <Card style={{color: 'rgba(0, 0, 0, 0.45)'}}>
            HTTP请求方式 : POST<br/>
            HTTP Content-Type : application/json<br/>
            HTTP 接收参数 :
            <div 
            style={{background: '#F9F9F9', borderRadius: 4, boxSizing: 'border-box', padding: '10px 16px'}} 
            dangerouslySetInnerHTML={{__html: `
            {
                <div style="padding-left: 20px">"content":"T",</div>
                <div style="padding-left: 20px">"phone":"18888888888"</div>
            }`
            }}></div>
        </Card>
    </>)
}

const BackTooltip = () => {
    return (<>
        <div style={{padding: '10px 0'}}>请根据以下规范设置回调接口：</div>
        <Card style={{color: 'rgba(0, 0, 0, 0.45)'}}>
            HTTP请求方式 : POST <br/>
            HTTP Content-Type : application/json <br/>
            HTTP 接收参数 :
            <div 
            style={{background: '#F9F9F9', borderRadius: 4, boxSizing: 'border-box', padding: '10px 16px'}} 
            dangerouslySetInnerHTML={{__html: `
            {
                <div style="padding-left: 20px">"phone":"18888888888",     // 触达的手机号 </div>
                <div style="padding-left: 20px">"taskId":"task1234567890", // 您定义的业务ID</div>
                <div style="padding-left: 20px">"status":0,                // 发送状态（0 短信发送成功, 1短信发送失败）</div>
                <div style="padding-left: 20px">"sendTime":1606878358000   // 发送时间</div>
            }`
            }}></div>
            {/* 注意：短信回执仅在短信发送接口返回<span dangerouslySetInnerHTML={{__html: '"短信发送成功"'}}></span>时才存在回调推送。 */}
        </Card>
    </>)
}

const UpSetting = ({...rest}: any) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch();
    const { ueId, ueUpReplyUrl, ueSmsReceiptUrl, ueUpReplySwitch } = useSelector((store: StateType) => store?.smsSetting) as any
    const onFinish = async ({ueUpReplySwitch, ...rest}: any) => {
        await updateApi({...rest, ueSmsReceiptUrl, ueId, ueUpReplySwitch: ueUpReplySwitch ? 1 : 0}, true)
        await dispatch(asyncSetSmsSetting()) // 获取国内短信设置
    }
    return (<ProForm<any> 
        form={form} 
        onFinish={onFinish} 
        submitter={false} 
        request={async () => {
            return {
                ueUpReplyUrl,
                ueUpReplySwitch: !!ueUpReplySwitch
                // ueSmsReceiptUrl
            }
        }}
        layout="horizontal"
        {...layout}
        {...rest}
        >
        <ProFormText 
            name="ueUpReplyUrl" 
            label="上行回复推送地址"
            // tooltip="上行消息是指终端用户发送给通信服务提供商的短信"
            rules={[
                { required: false, message: '请输入上行回复推送地址'}, 
            ]} 
            extra={<UpTooltip />}
        />
        {/* <ProFormText 
            name="ueSmsReceiptUrl" 
            width="md"
            label="短信回执推送地址"
            extra={<BackTooltip />}
            rules={[
                { required: false, message: '请输入短信回执推送地址'}, 
            ]} 
        /> */}
        <ProFormSwitch  label="推送开关" name="ueUpReplySwitch" />
        <Form.Item wrapperCol={{offset: layout.labelCol.span, span: 12}}>
            <Button 
                type="primary" 
                size="large" 
                htmlType="submit" 
                block 
            >
                保存
            </Button>
        </Form.Item>
    </ProForm>)
}

const DownSetting = ({...rest}: any) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch();
    const { ueId, ueUpReplyUrl, ueSmsReceiptUrl, ueSmsReceiptSwitch } = useSelector((store: StateType) => store?.smsSetting) as any
    const onFinish = async ({ueSmsReceiptSwitch, ...rest}: any) => {
        await updateApi_back({...rest, ueUpReplyUrl, ueId, ueSmsReceiptSwitch: ueSmsReceiptSwitch ? 1 : 0}, true)
        await dispatch(asyncSetSmsSetting()) // 获取国内短信设置
    }
    return (<ProForm<any> 
        form={form} 
        onFinish={onFinish} 
        submitter={false} 
        request={async () => {
            return {
                // ueUpReplyUrl,
                ueSmsReceiptUrl,
                ueSmsReceiptSwitch: !!ueSmsReceiptSwitch 
            }
        }}
        {...layout}
        layout="horizontal"
        {...rest}
        >
        {/* <ProFormText 
            name="ueUpReplyUrl" 
            width="md"
            label="上行回复推送地址"
            rules={[
                { required: false, message: '请输入上行回复推送地址'}, 
            ]} 
            extra={<UpTooltip />}
        /> */}
        <ProFormText 
            name="ueSmsReceiptUrl" 
            label="短信回执推送地址"
            // tooltip="下行回执是指每条短信的发送情况，短信是否到达终端用户的状态与相关信息"
            extra={<BackTooltip />}
            rules={[
                { required: false, message: '请输入短信回执推送地址'}, 
            ]} 
        />
        <ProFormSwitch label="推送开关" name="ueSmsReceiptSwitch" />
        <Form.Item wrapperCol={{offset: layout.labelCol.span, span: 12}}>
            <Button 
                type="primary" 
                size="large" 
                htmlType="submit" 
                block 
            >
                保存
            </Button>
        </Form.Item>
    </ProForm>)
}

const items = [
    {
        label: '上行消息推送',
        key: '1',
        children: <div style={{background: '#fff', padding: 20}}>
          <AlertTip 
            type="warning" 
            style={{marginBottom: 20}}
            listTips={['上行消息是指终端用户发送给通信服务提供商的短信。']}  
          />
          <UpSetting />
        </div>
    },
    {
        label: '下行回执推送',
        key: '2',
        children: <div style={{background: '#fff', padding: 20}}>
           <AlertTip 
            type="warning" 
            style={{marginBottom: 20}}
            listTips={[
                '下行回执是指每条短信的发送情况，短信是否到达终端用户的状态与相关信息。',
                `短信回执仅在短信发送接口返回"短信发送成功"时才存在回调推送`
            ]}  
          />
          <DownSetting />
        </div>
    },
]

export default () => {
    return (<Tabs 
        defaultActiveKey="1" 
        items={items} 
        tabBarStyle={{background: '#fff', padding: '0 20px', margin: 0}} 
    />)
}
