import React, { useRef, useState } from "react"
import { useTableHooks } from "@hooks/index"
import { columnsFn } from './data'
import { Button, Col, Modal, Row, Table } from "antd"
import { acceptExcel, getToken, productApi, setHref, toFormData } from "@utils/index"
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home"
import { SearchBox } from "@components/index"

import { ActionType, ProTable } from "@ant-design/pro-table"
import { ProFormProps, ProForm, ProFormText, ModalForm, ProFormRadio, ProFormUploadDragger, ProFormSelect, ProFormTextArea } from '@ant-design/pro-form'
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { BASEURL, smsMaiAdd, smsMaiDel, smsMaiPageList } from "@api/index"
import UploadButton, { handleUploadFiles, UploadDragger } from "@components/Common/Upload"
import { handleDvmCommonPageListResult } from "@utils/request"

// 列表操作的 类型校验
type Action = '新增' | '修改' | '删除' | '下载'

// 接口请求的 api 层
const getApi = productApi(smsMaiPageList)  // 查询的接口封装
const addUpdateApi = productApi(smsMaiAdd) // 列表数据的新增/编辑 接口
const delApi = productApi(smsMaiDel) // 表单数据的删除 接口
const getListApi = handleDvmCommonPageListResult(getApi)

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 16 },
};

const downloadUrl = 'https://juncdt-market-public.oss-cn-hangzhou.aliyuncs.com/ProductDoc/%E6%96%87%E6%A1%A3%E4%B8%8B%E8%BD%BD/%E6%89%8B%E6%9C%BA%E5%8F%B7%E7%A0%81%E6%A8%A1%E6%9D%BF.xlsx'

// 新增｜编辑的 form 表单弹窗
// 弹窗参数的类型扩展
interface AddFormProps extends ModalFormProps{
    submitParams?: any;
    onSuccess?: () => void;
}
const AddFormModal = ({onSuccess, submitParams, ...rest}: AddFormProps) => {
    const onFinish = async (values: any) => {
        console.log('表单要提交了，提交参数为:', values)
        const { fileList, ...rest } = values
        const url = handleUploadFiles(fileList)[0]
        const formData = toFormData({...rest, fileList, url, ...submitParams, dvmControl: 'smsContacts:import'})
        addUpdateApi(formData, true, onSuccess)
    }
    return (<ModalForm<any>
        layout="horizontal"
        {...layout}
        onFinish={onFinish}
        {...rest}>
        <ProFormText
            width="md"
            name="groupName"
            label="通讯组"
            rules={[{ required: true, message: '请填写分组名称'}]}
            placeholder="请填写分组名称"
        />
        <UploadButton
          width="md"
          name="fileList"
          label="导入号码"
          rules={[{required: true, message: '请上传资料'}]}
          fieldProps={{
            accept: acceptExcel,
            listType: 'text',
            maxCount: 1
          }}
          extra={<div>单次最多上传100000个号码；将号码放至第一页第一列，从一行开始，
            <a download onClick={() => setHref(downloadUrl) }>模板下载</a>
            导入文件的同时会过滤格式错误和重复的手机号码</div>}
        />
    </ModalForm>)
}


/**
 * antd 升级版企业级组件(常用)
*/
export default () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [modalProps, setModalProps] = useState<any>({visible: false, title: '新增', params: {}})
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    // operate 函数为增删改查需要处理的业务
    const operate = (type: Action, record: any) => {
        const { id } = record || {}
        if (type === '新增') {
            setModalProps({
                ...modalProps,
                title: '新增',
                params: {_t: new Date().valueOf()},
                request: async () => {
                    return {
                        groupName: ''
                    }
                },
                visible: true,
            })
            console.log('需要处理的新增业务')
        } else if (type === '下载') {
            console.log('需要处理的修改业务')
        } else if (type === '删除') {
            const ids = record ? [id] : selectedRowKeys
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                centered: true,
                cancelText: '取消',
                onOk: async() => {
                    await delApi({ids: ids.toString()}, true, () => {
                        console.log('数据删除成功, 可以执行回调了')
                        reFresh()
                    })
                },
            });
        }
    }

    // const getListApi = async (params: any, sorter: any, filter: any) => {
    //     const { data } = await getApi({...params}, false)
    //     const { values, total } = data || {}
    //     return {
    //         data: values || [],
    //         success: true,
    //         total
    //     }
    // }
    // const getListApi = handleDvmCommonPageListResult(getApi)

    const columns = columnsFn(operate) // 表格列配置

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        console.log('处理后的成功回调触发了')
        setModalProps({
            ...modalProps,
            visible: false
        })
        reFresh()
    }
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const calcDelDisabled = () => selectedRowKeys.length
    return (<><ProTable<any>
            columns={columns}
            params={{}} // 查询所需要的额外参数
            request={getListApi}
            options={{reload: false, density: false, setting: false}}
            toolbar={{
                actions: [
                    <Button key="btn0" type='primary' onClick={() => operate('新增', null)}>新增</Button>,
                    <Button key="btn1" type='primary' disabled={!calcDelDisabled()} onClick={() => operate('删除', null)}>删除</Button>,
                ],
            }}
            rowKey="id"
            pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
            actionRef={ref as any}
            search={{
                optionRender: (searchConfig, formProps, dom) => [ ...dom.reverse() ]
            }}
            rowSelection={{
                preserveSelectedRowKeys: true,
                onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
            }}
        />
        <AddFormModal
            modalProps={{
                maskClosable: false,
                destroyOnClose: true,
                onCancel: () => setModalProps({...modalProps, visible: false})
            }}
            onSuccess={onSuccess}
            {...modalProps}
        />
    </>)
}
