import React, { useEffect, useRef, useState } from 'react';

import "./index.less";
import activiesBg from "@assets/images/activities/Activities_61/activiesBg.png";
import bg from "@assets/images/activities/底.png";
import center_img from "@assets/images/activities/中.png";
import bottom from "@assets/images/activities/上.png";

import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import wt from "@assets/images/activities/Activities_61/wt.png";

import TweenOne from "rc-tween-one";
import "rc-texty/assets/index.css";
import QueueAnim from "rc-queue-anim";
import Texty from "rc-texty";
import { fileHttpUrl } from "@api/baseUrl";
import { Animation } from '@components/index'
import { GoRegister } from '@components/Home/PageIndex';
import { RecommendProductItem } from '../CDN';
import { useHistory } from 'react-router-dom';
import { homeGetProductHTML } from '@api/home';
import { handleDvmCommonListResult } from '@utils/request';
import { isBrowser } from '@utils/index';
const TweenOneCpn = TweenOne as any;

// 活动页面之一：  云极年中活动   61活动 

const TopDiv = () => {
  return (
    <div
      className="activities_topDiv2"
      style={{
        background: `url(${fileHttpUrl(
          "test/image/BAB25182D76C4307A3ECD6F5755985B0.png"
        )}) center center`,
      }}
    >
      <div className="topText">
        <TweenOneCpn style={{ height: 96 }} playscale={[0.1, 0.2]}>
          <QueueAnim
            key="23446543"
            leaveReverse
            delay={300}
            type="scale"
            forcedReplay={true}
            appear={true}
          >
             <h2 key="10"   className="title">
              <Texty>{`云极优选，特惠来袭`}</Texty>
            </h2>
            <h2 key="22">新客户专享福利，云极销量TOP产品礼券大放送，0元套餐包免费领啦！</h2>
          </QueueAnim>
        </TweenOneCpn>
      </div>
      {/* <img src={top} alt="" className="b_img" /> */}
    </div>
  );
};

const WeChartImg = () => {

  return (<div className="WeChartImgDiv_activities">
      <div className="top">
          <div className="title">扫码领取</div>
      </div>
      <div className="QRcode">
          <div className="imgBg">
              <img src={wt} alt="" />
          </div>
      </div>
  </div>)
}

const One = () => {
  const history = useHistory();

  const proList_cdn = [
    {
      piName: "国内短信",
      ppgName: "验证码",
      ppgModelAmountName: "100条",
      ppgModelLenName: "1个月",
      ppgSaleTotalPrice: "0",
      ppgDiscount: 0,
      ppgSourceTotalPrice: "5",
      btnText: '新用户请联系客服领取'
    },
    {
      piName: "国内短信",
      ppgName: "短信通知",
      ppgModelAmountName: "100条",
      ppgModelLenName: "1个月",
      ppgSaleTotalPrice: "0",
      ppgDiscount: 0,
      ppgSourceTotalPrice: "5",
      btnText: '新用户请联系客服领取'
    }
  ];
  const submit = (url:string) => {
    isBrowser() && (window.open(url, '_blank'))
    isBrowser() && window.scrollTo(0,0);
  }
  return (
    <div className='oneDiv'>
      <div className='div_big_title'>新用户专享特惠</div>
       <div className='div_title'>国内短信文本短信<span onClick={()=> submit(`/home/product?piId=5`)} className='red_button'>进入了解更多产品介绍 &gt;&gt;</span></div>
       <div className='div_title_2'>企业级 106 短信平台，多通道备用切换</div>
       
       <div className='flex justify-between'>
          <RecommendProductItem key={`1`} submit={()=> ''} piId={5} data={proList_cdn[0]} />
          <RecommendProductItem key={`2`} submit={()=> ''} piId={5} data={proList_cdn[1]} />
          <div style={{width: '30%'}} className='red_box'>
            <img src={bg} alt="" className="bg" />
            <Animation type="top">
            <div className="center_img" >
              <img src={center_img} alt=""/>
              <div className='title' style={{width: '80%', marginLeft: '10%', lineHeight: '15px'}}>新客户专享代金券/云朵/现金红包</div>
              <div className='text2'><span>50</span>元</div>
              <div className='text3'>单笔充值满2000</div>
            </div>
            </Animation>
            <img src={bottom} alt="" className="bottom" />
            <div className='submit_button hover_wxCode'>立即领取<WeChartImg /></div>
          </div>
       </div>
    </div>
  )
}

const Two = () => {
  const history = useHistory();


  const list = [
    {
      number: 10,
      typeList: ['新客户专享', '代金券/云朵'],
      text: '每个用户领取一次'
    },
    {
      number: 50,
      typeList: ['新客户专享', '代金券/云朵'],
      text: '充值单笔满1000元 每个用户领取一次'
    }
  ]

  const submit = () => {
    console.log(history)
    isBrowser() && (window.open(`/home/product?piId=3`, '_blank'))
    isBrowser() && window.scrollTo(0,0);
  }
  return (
    <div className='Two'>
       <div className='div_title'>人脸识别<span onClick={submit}  className='red_button'>进入了解更多产品介绍 &gt;&gt;</span></div>
       <div className='div_title_2'>金融级实人认证方案，唤起支付宝App，完成用户实人认证</div>
       <div className='content'>
            {
              list.map((element, idx) => {
                 return <div className='item' key={idx} >
                  <Animation type="left">
                    <div className='white_bg'>
                        <div className='small_div_type'>{
                          element.typeList.map((item, index)=> {
                            return <span key={index}>
                                 {item}
                            </span>
                          })
                        }</div>
                        <div><span style={{fontSize: '40px'}}>{element.number}</span><span>元</span></div>
                        <div style={{width: '130px', lineHeight: '16px'}}>{element.text}</div>
                    </div>
                    </Animation>
                    <div key="88888889" className='right'>
                        <div className='submit_button hover_wxCode'>立即领取<WeChartImg /></div>
                    </div>
                    
                 </div>
              })
            }
       </div>
    </div>
  )
}

const Three = () => {
  const list = [
    {
      name: 'S2通用型云主机 1核2G',
      text: '适用于企业官网、个人网站、网站搭建等应用',
      
    }
  ]
  return (
    <div className='Three'>
      <div className='div_title'>云服务器限时特惠<span className='red_button'>进入了解更多产品介绍 &gt;&gt;</span></div>
       <div className='div_title_2'>限时抢购爆款S2通用型云主机1核2G尝鲜价33.9元起/3个月，快来拼手速！<span style={{color: '#E80000'}}>（新客专享，限购一台）</span></div>
       <div className='content'>
          
       </div>
    </div>
  )
}



const BottomTextDiv = () => {
  const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
  return (
    <div className='bottomTextDiv'>
      <div className="content">
          <div className="flex mt-2">
              规则说明：
              <div >
               1、本页面活动仅限新用户参加，指的是在云极没有下单和消费记录的客户。<br />
               2、代金券（云朵）可消费平台任意产品，优先抵扣使用云朵。云朵需在有效期内使用完毕。<br />
               3、老用户成功邀请新用户后，可联系在线客服领取现金红包50元或者平台代金券（云朵）50元。<br />
               4、新用户完成企业认证后，可联系客服领取免费套餐包和代金券，工作时间1小时内处理到账。
               
                  
                  活动最终解释权在法律允许范围内归{ueSaasName}所有。<br />
              </div>
          </div>
          <br />
      </div>
    </div>
  );
};

export default () => {
  const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
  const [innerHtml, setInnerHtml] = useState('')
  const getData = async ()=> {
     const { result } = await homeGetProductHTML({piId: 49, dvmControl: 'ProductPurchase:queryDetail'}) as any
     setInnerHtml(handleDvmCommonListResult(result)[0].peContent)
    } 

  useEffect(()=> {
    getData()
  }, [])
  return (
    <div className="j-home-activities-preferred">
      <TopDiv />

      <One />
      <Two />
      {/* <Three /> */}
      <div dangerouslySetInnerHTML={{__html: innerHtml}} style={{padding: '20px 10%'}}>

      </div>
      <BottomTextDiv />
      <GoRegister />
    </div>
  );
};
