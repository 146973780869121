import { fromData } from "@utils/index";
import request from "@utils/request";
import { stringify } from "qs";
import { BASEURL } from "../baseUrl";
import { headers } from "../login";

/****************文字验证码******************/ 
export const textCaptchaCustomPageList  = (data?: object) => request({
    url:`${BASEURL}/web/captcha/captchaDetails`,
    data
})

export const textCaptchaCustomPageListDel  = (data?: object) => request({
    url:`${BASEURL}/web/captcha/delCaptcha`,
    data
})

export const textCaptchaCustomPageListSave  = (data?: object) => request({
    url:`${BASEURL}/web/captcha/saveOrUpdateCaptcha`,
    data
})

export const textCaptchaUpdateUseState  = (data?: object) => request({
    url:`${BASEURL}/web/captcha/updateUseState`,
    data
})
