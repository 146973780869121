import request from "@utils/request";
import { fromData, isBrowser } from "@utils/util";
import { stringify } from "qs";
import { BASEURL, PRODBASEURL } from "./baseUrl";
import { headers } from "./login";

// 首页列表数据-查询
export const homeSearch = (data?: any) => request({
    url: `${BASEURL}/table`,
    data,
})

// 首页列表数据-新增/修改
export const homeTableAddUpdate = (data?: any) => request({
    url: `${BASEURL}/tableAddUpdate`,
    data,
})

// 首页列表数据-删除
export const homeTableDel = (data?: any) => request({
    url: `${BASEURL}/tableDel`,
    data,
})

/*********************************************************以上是测试接口    */
// 获取上架产品树数据
export const homeGetProductTree = (data?: any) => request({
    url: `${BASEURL}/web/product/getPublishProductTree`,
    data: fromData({ ...data})
})

// 首页产品价格查询
export const homeGetProPrice = (data?: any) => request({
    url: `${BASEURL}/web/product/getProductModelPrice`,
    data: fromData({ ...data})
})

// 国内短信  概览页面 价格查询
export const homeGetMsgServicePricing = (data?: any) => request({
    url: `${BASEURL}/web/product/msgServicePricing`,
    data: fromData({ ...data})
})

// 获取国家列表
export const homeGetCountry = (data?: any) => request({
    url: `${BASEURL}/web/dvm/getOptions`,
    data: fromData({ ...data, dvmControl: 'portalSystem:getCountryList'})
})


// 查询产品套餐价格详细信息
export const homeGetProPackages = (data?: any) => request({
    url: `${BASEURL}/web/product/getPackagesByProductId`,
    data: fromData({ ...data})
})

// 获取推荐产品列表
export const homeGetHotProduct = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    // data: fromData({ ...data, dvmControl: 'portalShoppingIndex:getRec'}),
    headers,
    data: stringify({dvmControl: 'portalShoppingIndex:getRec', ...data}),
})

// 获取  友情链接  列表
export const homeGetFriendlyLinks = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    // data: fromData({ ...data, dvmControl: 'portalShoppingIndex:getLink'}),
    headers,
    data: stringify({dvmControl: 'portalShoppingIndex:getLink', ...data}),
})

// 获取  行业咨询  列表
export const homeGetGetInfomation = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    data: fromData({ ...data, dvmControl: 'portalShoppingIndex:getInfomation'})
})

// 获取  行业咨询  详情  列表
export const homeGetInfomationDetail = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    // data: fromData({ ...data, dvmControl: 'portalShoppingIndex:getInfomationDetail'}),
    headers,
    data: stringify({dvmControl: 'portalShoppingIndex:getInfomationDetail', ...data}),
})


//获取用户购物车列表数据
export const homeGetShoppingCar = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    data: fromData({ ...data, dvmControl: 'userOrderCar:Query'})
})

//用户购物车数量修改
export const homeEditShoppingCarNumber = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    data: fromData({ ...data, dvmControl: 'userOrderCar:update'})
})

//用户购物车删除
export const homeDelShoppingCar = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    data: fromData({ ...data, dvmControl: 'userOrderCar:del'})
})

//用户购物车总数量查询
export const homeGetShoppingCarNumber = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    data: fromData({ ...data, dvmControl: 'userOrderCar:getCount'})
})


//用户购物车提交
export const homePostShoppingCarSubmit = (data?: any) => request({
    url: `${BASEURL}/web/product/generateBatchOrder`,
    data
})

//获取提交的订单详情
export const homeGetOrderInfo = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    data: fromData({ ...data, dvmControl: 'userOrderDetails:query'})
})


//获取产品详情
export const homeGetproductInfo = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    data: fromData({ ...data, dvmControl: 'productInfo:query'})
})

//合作伙伴页面   加入我们页面
export const homeAddUs = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    data: fromData({ ...data, dvmControl: 'distributionApply:save'})
})


//获取产品  推荐产品
export const homeGetProductPurchaseo = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    // data: fromData({ ...data, dvmControl: 'ProductPurchase:queryRecs'}),
    headers,
    data: stringify({dvmControl: 'ProductPurchase:queryRecs', ...data}),
})

//获取产品 富文本内容
export const homeGetProductHTML = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    // data: fromData({ ...data, dvmControl: 'ProductPurchase:queryDetail'}),
    headers,
    data: stringify({dvmControl: 'ProductPurchase:queryDetail', ...data}),
})

//获取home页面轮播
export const homeGetCarousel = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    data: fromData({ ...data, dvmControl: 'portalShoppingIndex:getBanners'})
})

//获取home首页的产品展示列表
export const homeGetproductList = (data?: any) => request({
    url: `${BASEURL}/web/product/getPublishProductTree`,
    headers,
    data: stringify({...data}),
})

//home 加入购物车
export const homeAddToCart = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    data: fromData({ ...data, dvmControl: "purchase:addToCart" })
})

//home 查询产品是否已经开通
export const homeGetProFrequency = (data?: any) => request({
    url: `${BASEURL}/web/product/checkPurchase`,
    data: fromData({ ...data })
})

//home 查询产品是否已经勾选过协议
export const homeGetCheckTickAgree = (data?: any) => request({
    url: `${BASEURL}/web/product/checkTickAgree`,
    data: fromData({ ...data })
})

//home 获取首页轮播下边产品列表的热门产品
export const homeGetHotProList = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    // data: fromData({ ...data, dvmControl: "recommendProductInfo:query" }),
    headers,
    data: stringify({dvmControl: 'recommendProductInfo:query', ...data}),
})

//home home 加入购物车请求
export const homePostBuySubmit = (data?: any) => request({
    url: `${BASEURL}/web/product/generateOrder`,
    data: fromData({ ...data })
})

//home  订单提交请求
export const homePostBuydoPay = (data?: any) => request({
    url: `${BASEURL}/web/product/doPay`,
    data: fromData({ ...data })
})

//home 开通产品
export const homeOpenProductPurchase = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    data: fromData({ ...data, dvmControl: "ProductPurchase:open" })
})

// 根据域名获取个性化配置信息
export const homegetDomainInfo = (data?: any) => request({
    url: `${BASEURL}/portal/manage/getDomainInfo`,
    data
})


// 获取数字字典
export const getEnumByCode = (data?: any) => request({
    url: `${BASEURL}/web/manage/getEnumByCode`,
    data: fromData({ ...data })
})

// 获取自定义页面
export const homeGetCustomPage = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmGet`,
    headers,
    data: stringify({ ...data, dvmControl: "portalShoppingIndex:getDiyPage" })
})

// -------测试语雀api-------
export const getYuque = (data?: any) => request({
    url: `https://www.yuque.com/api/v2/api/v2/user`,
    headers: {
        'Content-Type': 'application/json',
        'User-Agent': 'zengqingsen',
        'X-Auth-Token': 'LPMubaLDHRGaSrki5e9twWQ7Duzm766f9bINsWx6'
    },
    // method: 'GET',
    data
})
