import { ProColumns } from "@ant-design/pro-table";
import { fileHttpUrl } from "@api/baseUrl";
import { DateRangeWithDisabled } from "@components/Common";
import { Input, Select } from "antd";
import React, { ReactNode } from "react";

const options = [{label: 'options1', value: 1}, {label: 'options2', value: 2}]

/**
 * 列表表格配置:
 *  自定义表格 CustomerDemo 和 antd 升级版组件 ProTableDemo 都可使用, 
 * */
export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '号码组',
            dataIndex: 'groupName',
            key: 'groupName',
        },
        {
            title: '号码数量',
            dataIndex: 'phoneNum',
            key: 'phoneNum',
            hideInSearch: true
        },
        {
            title: '操作时间',
            dataIndex: 'updateTime',
            key: 'updateTime',
            valueType: 'dateRange',
            fieldProps: { },
            search: {
                transform: (value) => {
                    return {
                        startTime: value[0] ? value[0] + ' 00:00:00' : undefined,
                        endTime: value[1] ? value[1] + ' 23:59:59' : undefined
                    }
                },
            },
            renderFormItem: (props) => <DateRangeWithDisabled />,
            render: (text: any, record: any) => record.updateTime,
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            valueType: 'option',
            fixed: 'right',
            render: (text: any, record: any) => [
                <a key="下载" style={{marginRight: 8}} download href={fileHttpUrl(record.url)}>下载</a>,
                <a key="删除" onClick={() => operate('删除', record)}>删除</a>,
            ]
        }
    ]
}
