import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'; // 异步中间件
import reducer from './reducers'
export type PreviewType = {
    visible?: Boolean; 
    src?: string | string[]
}
export type StateType = {
    userInfo?: { baseInfo?: any, certInfo?: any, buyedPro?: any; permissions?: any }; // 用户相关的信息
    historyList: any[]; // 面包屑
    areaTree?: any[]; // 省市区树
    allCountry?: any; // 所有国家
    smsSetting?: any; // 短信设置相关
    productData?: any; // 产品数据
    ShoppingCarNumber?: number; // 购物车数量
    domainInfo?: Object; //个性化配置信息
    taskObj?: object; // 异步下载任务
    previewObj?: PreviewType // 全局文件预览
    docTreeData?: any; // 文档树
}
const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
const initState: StateType = {
    userInfo: {},
    historyList: [],
    areaTree: [],
    smsSetting: {},
    productData: [],
    allCountry: [],
    ShoppingCarNumber: 0,
    domainInfo: {
        isnull: 1,
        ueSaasAboutUs:'',
        ueSaasFullName: "浙江卓见云科技有限公司",
        ueSaasIcpLicense: "浙ICP备16013515号-16",
        ueSaasLink: "卓见云=www.juncyun.com",
        ueSaasLogo: "fileServer/2022/11/29/edffcd3f235148b8a012796cf18e15ed.png",
        ueSaasName: "云极",
        ueSaasPhone: "0571-87960033",
        ueSaasIcon: 'fileServer/2022/11/29/26d29e517fe4457483c2b46b6500234f.ico',
        ueSaasPublicQrcode:
            "fileServer/2022/11/29/7ddbb4b8287c415c932dbba8ab8b5e4a.png",
        ueSaasWechatQrcode:
            "fileServer/2022/11/29/7ddbb4b8287c415c932dbba8ab8b5e4a.png",
        ueSaasAddress: '',
        ueSaasEmail: '15979290517',
    },
    taskObj: {},
    previewObj: {},
    docTreeData: {}
};
export default createStoreWithMiddleware(reducer, initState as any);
