import { fromData } from "@utils/index";
import request from "@utils/request";
import { stringify } from "qs";
import { BASEURL, MockUrl } from "../baseUrl";
import { headers } from "../login";

/****************短信签名******************/ 
// 短信签名pageList
export const smsSignPageList  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsSign:query' })
})

// 短信在线发送
export const smsSendMessageByFormData  = (data?: object) => request({
    url:`${BASEURL}/web/product/sendMessageByFormData`,
    headers,
    data: fromData({...data})
})

export const smsSendCancel  = (data?: object) => request({
    url:`${BASEURL}/web/product/cancelTask`,
    headers,
    data: fromData({...data})
})


// 短信签名添加
export const smsSignAdd  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsSign:add' })
})

// 短信签名删除
export const smsSignDel  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsSign:del' })
})

/****************短信模版******************/ 
// 短信模版pageList
export const smsTempPageList  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsTemplate:query' })
})

export const smsTempPublicPageList  = (data?: object) => request({
    url:`${BASEURL}/web/product/getPublicTemplateList`,
    data: fromData(data)
    // headers,
    // data: stringify({...data, dvmControl: 'smsTemplate:query' })
})

// 短信模版-add
export const smsTempAdd  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsTemplate:add' })
})

// 短信模版-查询添加模版的变量
export const smsTempVarOptions  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsTemplate:getTypes' })
})

// 根据id 获取模版详情数据
export const smsTempInfo  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsTemplate:getById' })
})

// 短信模版-获取模版类型
export const smsTempGetTempTypes  = (data?: object) => request({
    url:`${BASEURL}/web/product/getTemplateRelation`,
    // headers,
    data
})

// 短信模版-edit
export const smsTempUpdate  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsTemplate:edit' })
})

// 短信模版-del
export const smsTempDel  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsTemplate:del' })
})

// 短信模版pageList
export const smsTempApply  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsTemplate:apply' })
})

/****************短信在线发送******************/ 
export const smsSendPageList  = (data?: object) => request({
    url:`${BASEURL}/web/product/getTaskList`,
    data,
    // headers,
    // data: stringify({...data, dvmControl: 'smsSend:query' })
})

export const smsGlobelSendDownLoadFailPhone  = (data?: object) => request({
    url:`${BASEURL}/portal/internationalMessage/downloadFailPhone`,
    data,
    // headers,
    // data: stringify({...data, dvmControl: 'smsSend:query' })
})

export const smsSendDownLoadFailPhone  = (data?: object) => request({
    url:`${BASEURL}/portal/domesticMessage/downloadFailPhone`,
    data,
    // headers,
    // data: stringify({...data, dvmControl: 'smsSend:query' })
})

export const smsSendResend  = (data?: object) => request({
    url:`${BASEURL}/web/product/resend`,
    method: 'GET',
    data,
    // headers,
    // data: stringify({...data, dvmControl: 'smsSend:query' })
})

/****************短信通讯录******************/ 
// 短信通讯录-pageList
export const smsMaiPageList  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsContacts:query' })
})

export const smsMaiAdd  = (data?: object) => request({
    url:`${BASEURL}/web/product/saveAddressBook`,
    data
    // data: fromData({ ...data, dvmControl: 'userOrderCar:del'})
    // headers,
    // data: stringify({...data})
})

export const smsMaiDel  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsContacts:del' })
})

/****************短信设置--余量报警******************/ 
export const smsAlertPageList  = (data?: object) => request({
    url:`${BASEURL}/web/product/showSmsQuota`,
    data,
    // headers,
    // data: stringify({...data, dvmControl: 'smsContacts:del' })
})

// 获取短信余量报警
export const smsGetAlertInfo  = (data?: object) => request({
    url:`${BASEURL}/web/product/showSmsQuotaSetting`,
    data,
    // headers,
    // data: stringify({...data, dvmControl: 'smsContacts:del' })
})

// 设置短信余量报警
export const smsSetAlertInfo  = (data?: object) => request({
    url:`${BASEURL}/web/product/updateSmsQuotaSetting`,
    data,
    // headers,
    // data: stringify({...data, dvmControl: 'smsContacts:del' })
})

/****************短信设置--上限设置******************/ 

/****************短信设置--数据推送******************/ 
export const smsDataPushQuery  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsDataPush:query' })
})

export const smsDataPushUpdate  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsDataPush:edit' })
})	

export const smsDataUpdateUpReply  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsDataPush:updateUpReply' })
})

export const smsDataAmountLimit  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsDataPush:amountLimit' })
})

export const smsDataSmsReceipt = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsDataPush:smsReceipt' })
})

/****************短信设置--API调用密钥******************/ 


/****************短信设置--黑名单管理******************/ 
export const smsBlackListPageList  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsBlacklist:query' })
})

export const smsBlackListAdd  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsBlacklist:add' })
})

export const smsBlackListDel  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsBlacklist:del' })
})

/****************短信统计--发送明细******************/ 
export const smsSendDetailPageList  = (data?: object) => request({
    url:`${BASEURL}/portal/domesticMessage/sendMessageDetails`,
    // headers,
    data
})

/****************短信统计--发送统计******************/ 
export const smsSendStatisticPageList  = (data?: object) => request({
    url:`${BASEURL}/portal/domesticMessage/sendMessagesStatistic`,
    // headers,
    data
})

/****************短信统计--发送分析******************/ 
// 短信到达率分析
export const smsSendRate  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsAnalysis:rate' })
})

// 短信失败率分析
export const smsSendFail  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsAnalysis:fail' })
})

// 统计分析
export const smsSendGeneral  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({...data, dvmControl: 'smsAnalysis:general' })
})

// 数据统计-发送量统计
export const smsSendStatistic  = (data?: object) => request({
    url:`${BASEURL}/web/dataStat/sendAmountStat`,
    // headers,
    data
})


/****************短信统计--计费反馈******************/ 
export const smsSendFeedbackPageList  = (data?: object) => request({
    url:`${BASEURL}/web/product/getFeeReturn`,
    // headers,
    data
})

/****************短信统计--回复记录******************/ 
export const smsSendReplyRecordPageList  = (data?: object) => request({
    url:`${BASEURL}/portal/domesticMessage/replyRecord`,
    // headers,
    data
})
