import request from "@utils/request";
import { stringify } from "qs";
import { BASEURL, MockUrl } from "./baseUrl";
import { headers } from "./login";

/****************** 公共接口 *******************/
// 获取省市区数据
export const getAreaTree  = (data?: object) => request({
    url:`${BASEURL}/web/dvm/getTreeTable`,
    headers,
    data: stringify({ dvmControl: 'portalSystem:getAreaTree' })
})

// 获取所有产品 select 下拉框
export const getProductOptions = (data?: any) => request({
    url: `${BASEURL}/web/dvm/dvmPost`,
    headers,
    data: stringify({dvmControl: 'userOrderProductInfo:query'}),
})

// 导出：根据序列号查询导出结果
export const getExportTask = (data?: any) => request({
    url: `${BASEURL}/web/task/exportTask`,
    // headers,
    method: 'GET',
    data,
})

// 导出任务列表
export const getTaskList = (data?: object) => request({
    url:`${BASEURL}/web/task/exportTaskList`,
    data
})

// 删除任务
export const delTask = (data?: object) => request({
    url:`${BASEURL}/web/task/delExportTask`,
    data
})

export const seoApi = (data?: any) => request({
  url: `${MockUrl}/table`,
  data,
})

// 获取行为验证码
export const getTextCapcha = (data?: object) => request({
    url:`${BASEURL}/web/manage/captcha`,
    data
})

// 校验行为验证码
export const checkTextCapcha = (data?: object) => request({
    url:`${BASEURL}/web/manage/verifyCaptcha`,
    data
})
