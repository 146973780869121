import { BASEURL, toolPhoneShunt } from "@api/index"
import { Icon, ImportButton } from "@components/Common"
import { handleUploadFiles, UploadDragger } from "@components/Common/Upload"
import { Alert, Button, Col, Input, List, Row, Typography } from "antd"
import React, { useState } from "react"
import ProForm, { 
    ProFormText, 
    ProFormUploadButton, 
    ProFormSelect, 
    ProFormTextArea, 
    ProFormCascader,
    ProFormProps
} from '@ant-design/pro-form';
import {
    CopyOutlined,
} from '@ant-design/icons';
import { acceptExcel, cmPhoneReg, copyText, ctPhoneReg, cuPhoneReg, productApi, toFormData } from "@utils/index"
const { Paragraph } = Typography;


const { TextArea } = Input

const toolPhoneShuntApi = productApi(toolPhoneShunt)

const initPhoneLists = [
    {
        desc: '移动号码',
        phoneList: []
    },
    {
        desc: '电信号码',
        phoneList: []
    },
    {
        desc: '联通号码',
        phoneList: []
    },
    {
        desc: '未知号码',
        phoneList: []
    }
]

const phonesShunt = (phones: any[]) => {
    const cm: any[] = [] // 移动
    const ct: any[] = [] // 电信
    const cu: any[] = [] // 联通
    const un: any[] = [] // 未知
    phones.forEach(phone => {
        if (cmPhoneReg.test(phone)) {
            cm.push(phone)
        } else if (ctPhoneReg.test(phone)) {
            ct.push(phone)
        } else if (cuPhoneReg.test(phone)) {
            cu.push(phone)
        } else {
            phone && un.push(phone)
        }
    })
    return [
        {
            desc: `移动号码`,
            phoneList: cm
        },
        {
            desc: `电信号码`,
            phoneList: ct
        },
        {
            desc: `联通号码`,
            phoneList: cu
        },
        {
            desc: `未知号码`,
            phoneList: un
        }
    ]
}

export default () => {
    const [phoneLists, setPhoneLists] = useState<any>(initPhoneLists)
    const [phoneSource, setPhoneSource] = useState<any>('')
    const onValuesChange = async ({fileList}: any) => {
        const file0 = fileList[0]
        const { status } = file0
        if (status === 'done') {
            const formData = toFormData({fileList})
            toolPhoneShuntApi(formData, true, (data: any) => {
                const { phoneClassifyDatas } = data
                setPhoneLists(phoneClassifyDatas||[])
            })
        }
        // const reader = new FileReader()
        // const ddd = reader.readAsBinaryString(file0.originFileObj)
        // // console.log('rrres', file0, ddd)
        // reader.onload = (ev) => {
        //     const data = ev.target?.result
        //     const res = XLSX.read(data, {type: 'binary'})
        //     console.log('data', res)
        // }
    }
    const onFinish = async (values: any) => {
        const { fileList } = values
        const file0 = fileList[0]
        // const { status } = file0
        const formData = toFormData({fileList})
        const { success } = await toolPhoneShuntApi(formData, true, (data: any) => {
            const { phoneClassifyDatas } = data
            setPhoneLists(phoneClassifyDatas||[])
        })
        return success
    }
    const onShunt = () => {
        const phones = phoneSource.split('\n')
        const phoneLists = phonesShunt(phones)
        setPhoneLists(phoneLists)
    }
    return (<div style={{background: '#fff'}}>
        <div style={{padding: 20, paddingBottom: 0}}>
            <Alert
                showIcon
                message="提示：将手机号码手动填写或批量导入到源号码框里面，然后点击“号码分流按钮”，系统自动将所有的号码按运营商都区分开了。"
                type="warning"
            />
        </div>
        {/* <ProForm<any>
            layout="horizontal"
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 24 }}
            submitter={false}
            style={{padding: 20}}
            onValuesChange={onValuesChange}
        >
            <UploadDragger
              label=""
              name="fileList"
              width={600}
              fieldProps={{
                accept: acceptExcel,
                listType: 'text',
                maxCount: 1,
              }}
              extra={<div>将文件拖拽此处，将自动分流, 只接受excel 文件 <a download href={'https://juncdt-market-public.oss-cn-hangzhou.aliyuncs.com/ProductDoc/%E6%96%87%E6%A1%A3%E4%B8%8B%E8%BD%BD/%E5%8F%B7%E7%A0%81%E5%88%86%E6%B5%81%E6%A8%A1%E6%9D%BF.xlsx'}>模版下载</a></div>}
            />
        </ProForm> */}
        <Row gutter={[16, 16]} style={{padding: '20px 20px'}}>
            <Col>
                <ImportButton 
                    trigger={<Button>批量导入</Button>} 
                    title="批量导入"
                    templateUrl={'https://juncdt-market-public.oss-cn-hangzhou.aliyuncs.com/ProductDoc/%E6%96%87%E6%A1%A3%E4%B8%8B%E8%BD%BD/%E5%8F%B7%E7%A0%81%E5%88%86%E6%B5%81%E6%A8%A1%E6%9D%BF.xlsx'} 
                    onFinish={onFinish}
                />
            </Col>
            <Col>
                <Button type="primary" onClick={onShunt}>号码分流</Button>
            </Col>
        </Row>
        <Row gutter={[16, 16]} justify="space-around">
            <Col key="源号码" span={4}>
               <div style={{textAlign: 'center'}}>源号码</div>
                <TextArea 
                    rows={22} 
                    style={{height: 500, width: '100%', overflow: 'scroll'}}
                    onChange={(e) => {
                        const text = e.target.value
                        setPhoneSource(text)
                    }} 
                />
            </Col>
            {
                phoneLists.map((v: any) => (<Col key={v.desc} span={4} style={{position: 'relative'}}>
                    <div style={{textAlign: 'center'}}>{v.desc}<span style={{color: 'red'}}>{v.phoneList.length||0}</span>个</div>
                    <Paragraph 
                       copyable={!!v.phoneList.length}
                       className="jc-paragraph-copy"
                       style={{height: 500, boxSizing: 'border-box', padding: 5, overflow: 'scroll', whiteSpace: 'break-spaces', border: '1px solid #d9d9d9', borderRadius: 2}}
                    >
                        {(v.phoneList||[]).join('\n')}
                    </Paragraph>
                    {/* <List
                        bordered
                        dataSource={v.phoneList}
                        style={{height: 500, boxSizing: 'border-box', padding: '0 5px', width: '100%', whiteSpace: 'break-spaces', overflow: 'scroll'}}
                        renderItem={item => item + '\n'}
                    />
                    <CopyOutlined 
                      style={{position: 'absolute', right: '10px', bottom: '5px', color: '#1890ff', cursor: 'pointer'}} 
                      onClick={() => copyText((v.phoneList||[]).map(v => v + '\r\n'))}
                    /> */}
                </Col>))
            }
        </Row>
    </div>)
}