import ProTable, { ActionType } from "@ant-design/pro-table"
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home"
import { acceptImg, productApi } from "@utils/util"
import { Button, Modal, Tabs, message } from "antd"
import React, { useRef, useState } from "react"
import { columnsFn } from "./data"
import ProForm, { 
    ProFormText, 
    ProFormUploadButton, 
    ProFormSelect, 
    ProFormTextArea, 
    ProFormRadio,
    ProFormCascader,
    ProFormDependency,
    ModalForm 
} from '@ant-design/pro-form';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { smsSignAdd, smsSignDel, smsSignPageList } from "@api/product"
import { handleDvmCommonPageListResult } from "@utils/request"
import { useSelector } from "react-redux"
import { StateType } from "@store/index"
import { CERT_COMPANY, CERT_COMPANY_FAIL, CERT_PERSON, CERT_PERSON_FAIL, CERT_UPGRADE_FAIL, calcCertStatus } from "@utils/index"
import UploadButton, { handleUploadFiles } from "@components/Common/Upload"

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 18 },
};

// 接口请求的 api 层
const getApi = productApi(smsSignPageList)  // 查询的接口封装
const addUpdateApi = productApi(smsSignAdd) // 列表数据的新增/编辑 接口
const delApi = productApi(smsSignDel) // 表单数据的删除 接口
const getListApi = handleDvmCommonPageListResult(getApi)

const SignNameTooltip = () => {
    return (<div>
        • 建议长度为2-8个字符<br/>
        • 签名内容为短信发送方的真实应用名称、网站名称、公司名称或商标名称<br/>
        • 若涉及到第三方权益必须获得第三方真实授权<br/>
        • 不支持如“客户服务”、“友情提醒”等过于宽泛内容<br/>
        • 通用场景下不支持带“测试”字样的签名，如需测试请选择验证码场景<br/>
        • 若签名／模版内容侵犯到第三方权益必须获得第三方真实授权<br/>
        • 无须添加【】、()、[]符号，签名发送会自带【】符号，避免重复<br/>
        • 禁止发送涉及：色情、赌博、毒品、党政、维权、众筹、慈善募捐、宗教、迷信、股票、移民、面试招聘、 博彩、贷款、催款、信用卡提额、投资理财、中奖、抽奖、一元夺宝、一元秒杀、A货、整形、烟酒、交友、暴力、 恐吓、皮草、返利、代开发票、代理注册、代办证件、加群、加QQ或者加微信、贩卖个人信息、运营商策反、流量 营销等信息的短信
    </div>)
}

const Placeholder = `填写完整可提高通过率，可填写签名来源、应用场景、应用链接等；签名和主体可以查到关联性。填写示例：
        1.  该签名是某某app的名称，用户登录验证；
        2.  该签名是我司微信小程序名称，用于用户消费提醒。
        3.  该签名是我司网站名称，网址是xxxx.com，用户系统注册提醒。`

// 添加签名
interface AddFormProps extends ModalFormProps{
    params?: any;
    onSuccess?: () => void;
}

const AddFormModal = ({onSuccess, params, ...rest}: AddFormProps) => {
    const onFinish = async (values: any) => {
        const { fileList, ...restValues } = values
        const businessLicense = handleUploadFiles(fileList)[0]
        addUpdateApi({...restValues, businessLicense, ...params}, true, onSuccess)
    }
    return (<ModalForm<any>
        layout="horizontal" 
        {...layout} 
        onFinish={onFinish} 
        labelCol={{span: 6}}
        {...rest}>
        <ProFormText 
            name="usName" 
            label="签名名称" 
            rules={[
             {required: true, message: '签名名称不能为空',},
            ]} 
            placeholder="请填写签名名称,建议2～8个字符"
            tooltip={<SignNameTooltip />}
        />
        <ProFormRadio.Group 
           name="usType" 
           label="签名类型" 
           initialValue={1}
           options={[{label: '通用', value: 1}]} 
           rules={[{ required: true, message: '请选择签名类型',}]} 
        />
        <ProFormTextArea 
           name="usApplyDescribe" 
           label="申请说明" 
           fieldProps={{maxLength: 500, rows: 6, showCount: true}} 
           placeholder={Placeholder}
           rules={[
            {required: true, message: '请填写申请说明, 500字以内'}, 
            {max: 500, type:'string', message: '500字以内'}
           ]} 
        />
        
        <ProFormRadio.Group 
           name="relateMe" 
           label="签名来源" 
           initialValue={1}
           options={[{label: '自用（签名为本账号实名认证的网站、App等）', value: 0}, {label: '他用（签名为非本账号实名认证的企业、网站、产品名等）', value: 1},]} 
           rules={[{ required: true, message: '请选择签名来源',}]} 
        />
        <ProFormDependency name={['relateMe']}>
            {
                ({relateMe}) => relateMe ? (<>
                    <ProFormText 
                        name="entName" 
                        label="企业名称" 
                        rules={[
                            {required: true, message: '企业名称不能为空',},
                        ]} 
                        placeholder="请填写企业名称"
                    />
                    <ProFormText 
                        name="entQualificationNum" 
                        label="信用编码" 
                        rules={[
                            {required: true, message: '信用编码不能为空',},
                        ]} 
                        placeholder="请填写社会信用编码"
                    />
                    <ProFormText 
                        name="legalRepresentativeName" 
                        label="法人/经办人姓名" 
                        rules={[
                            {required: true, message: '法人/经办人姓名不能为空',},
                        ]} 
                        placeholder="请填写法人/经办人姓名"
                    />
                    <ProFormText 
                        name="corporateIdCard" 
                        label="法人/经办人身份证号码" 
                        rules={[
                            {required: true, message: '法人/经办人身份证号码不能为空',},
                        ]} 
                        placeholder="请填写法人/经办人身份证号码"
                    />
                    <UploadButton
                        name="fileList"
                        label="营业执照"
                        rules={[{required: true, message: '请上传营业执照'}]}
                        fieldProps={{
                            accept: acceptImg,
                            maxCount: 1
                        }}
                    />
                </>) : null
            }
        </ProFormDependency>
    </ModalForm>)
}

// 签名管理
export default () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [modalProps, setModalProps] = useState({visible: false, title: '新增', params: {}})
    const { baseInfo, certInfo } = useSelector((store: StateType) => store?.userInfo) as any
    const { userNature: type, verifyState: status } = (baseInfo || {}) as any
    const STATUS = calcCertStatus(type, status)
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    // operate 函数为增删改查需要处理的业务
    const operate = (type: any, record: any) => {
        const {usId, usName, usType, usCode, createTime, utlVerifyState } = record || {}
        if (type === '新增') {
            // 需求方说 认证失败{个人/企业} 不允许新增
            if ([CERT_PERSON_FAIL, CERT_COMPANY_FAIL].includes(STATUS)) return message.warning('实名认证失败，不允许操作')
            setModalProps({
                ...modalProps,
                title: '新增',
                params: {},
                visible: true,
            })
        } else if (type === '详情') {
            Modal.info({
                title: '详情信息',
                content: (
                  <div>
                    <p>签名名称：{usName}</p>
                    <p>适用场景：{usType}</p>
                    <p>签名CODE： {usCode}</p>
                    <p>创建时间：{createTime}</p>
                    <p>审核状态：{utlVerifyState}</p>
                  </div>
                ),
                onOk() {},
            })
        } else if (type === '删除') {
            const usIds = record ? [usId] : selectedRowKeys
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                centered: true,
                cancelText: '取消',
                onOk: async() => {
                    await delApi({usIds: usIds.toString()}, true, () => {
                        reFresh()
                    })
                },
            });
        }
    }

    // const getListApi = async (params: any, sorter: any, filter: any) => {
    //     const { data } = await getApi({...params}, false)
    //     const { values, total } = data || {}
    //     return {
    //         data: values || [],
    //         success: true,
    //         total
    //     }
    // }
    // const getListApi = handleDvmCommonPageListResult(getApi)

    const columns = columnsFn(operate) // 表格列配置

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        console.log('处理后的成功回调触发了')
        setModalProps({
            ...modalProps,
            visible: false
        })
        reFresh()
    }
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const calcDelDisabled = () => selectedRowKeys.length
    
    return (<><ProTable<any>
        style={{flex: 1, overflow: 'scroll'}}
        scroll={{x: 1300}}
        columns={columns}
        params={{}} // 查询所需要的额外参数
        request={getListApi}
        options={{reload: false, density: false, setting: false}}
        toolbar={{
            actions: [
                <Button key="btn0" type='primary' onClick={() => operate('新增', null)}>添加签名</Button>,
                <Button key="btn1" type='primary' disabled={!calcDelDisabled()} onClick={() => operate('删除', null)}>删除</Button>,
            ],
        }}
        rowKey="usId"
        pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
        actionRef={ref as any}
        search={{
            optionRender: (searchConfig, formProps, dom) => [ ...dom.reverse() ]
        }}
        rowSelection={{
            preserveSelectedRowKeys: true,
            onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
        }}
    />
    <AddFormModal
        modalProps={{
            maskClosable: false, 
            destroyOnClose: true,
            onCancel: () => setModalProps({...modalProps, visible: false})
        }}
        onSuccess={onSuccess} 
        {...modalProps} 
    />
    </>)
}
