
// 产品假数据   产品类型数据    服务类型数据
export const testProDetailedList = (name:any) => {
  // const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
  return {
    serviceText: '安全团队提供一站式网络完全运维：风险评估',
    cardList: [
        {
          title: 'SAAS云部署',
          text: `快速集成${name}的云应用和服务`,
          url: 'test/image/48A54D2F4846497685D441EB85517B0A.png',
          alt: 'SAAS云部署'
        },
        {
          title: 'PAAS私有化部署',
          text: `快速部署、发布、管理${name}产品和服务`,
          url:'test/image/41C249A39DD046369FE9AD8EE4E0BEC8.png',
          alt: 'PAAS私有化部署'
        }
      ],
  }
}



  // ueSaasName:传入企业名称生成菜单，考虑sass
  export const menuList = (ueSaasName: string) => {
    return [
    {
      title: '首页',
      url: '/'
      // child: true
    },
    {
      title: '产品',
      type: 'product',
    },
    // {
    //   title: '服务',
    //   type: 'service',
    //   url: '',
    // },
    // {
    //   title: '解决方案',
    // type: 'service',
    //   url: '',
    // },
    {
      title: '合作伙伴',
      url: '/home/cooperation',
    },
    // {
    //   title: '促销活动',
    //   url: '/home/activities',
    // },
    {
      title: '了解'+  ueSaasName,
      // url: '/home/AboutUs',
      children: [
        {
          label: ueSaasName + '简介',
          key: '/home/AboutUs',
        },
        {
          label: '行业资讯',
          key: '/home/industryInformation',
        },
      ]
    },
    {
      title: '帮助文档',
      url: '/home/doc',
    }
  ]}
