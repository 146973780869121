import { ProColumns } from "@ant-design/pro-table";
import { fileHttpUrl } from "@api/baseUrl";
import { DateRangeWithDisabled } from "@components/Common";
import { Input, Select } from "antd";
import React, { ReactNode } from "react";

const options = [{label: 'options1', value: 1}, {label: 'options2', value: 2}]


const valueEnum = {
    // 0: { text: '全部', status: 'Default' },
    1: { text: '成功', status: 'Sueecss' },
    2: { text: '失败', status: 'Error' },
    // 3: { text: '其他错误', status: 'Processing' },
}

const checkValueEnum = {
    1: { text: '白名单', status: 'Sueecss' },
    2: { text: '黑名单', status: 'Default' },
    3: { text: '可信用度低', status: 'Processing' },
}

const valueEnumCharge = {
    2: { text: '不收费', status: 'Default' },
    1: { text: '收费', status: 'Sueecss' },
}

/**
 * 列表表格配置:
 *  自定义表格 CustomerDemo 和 antd 升级版组件 ProTableDemo 都可使用, 
 * */
export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '接口名称',
            dataIndex: 'interfaceName',
            // valueType: 'select',
            hideInSearch: true
        },
        // {
        //     title: '调用类型',
        //     dataIndex: 'callType',
        //     hideInSearch: true
        // },
        {
            title: '数量',
            dataIndex: 'count',
            hideInSearch: true
        },
        {
            title: '调用时间',
            dataIndex: 'callTime',
            // valueType: 'dateTimeRange',
            valueType: 'dateRange',
            fieldProps: { },
            search: {
                transform: (value) => {
                    return {
                        startTime: value[0] ? value[0] + ' 00:00:00' : undefined,
                        endTime: value[1] ? value[1] + ' 23:59:59' : undefined
                    }
                },
            },
            renderFormItem: (props) => <DateRangeWithDisabled />,
            render: (text: any, record: any) => record.callTime,
        },
        {
            // title: '状态',
            title: '调用结果',
            dataIndex: 'callResult',
            valueType: 'select',
            valueEnum: valueEnum,
            // valueEnum,
            hideInSearch: true
        },
        {
            title: '是否计费',
            dataIndex: 'feeStr',
            order: 4,
            valueType: 'select', 
            valueEnum: valueEnumCharge,
            fieldProps: {
                allowClear: false
            },
            formItemProps: {
                // initialValue: '1',  
            },
            search: {
                transform: (value) => {
                    return {fee: value}
                },
            },
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            valueType: 'option',
            fixed: 'right',
            render: (text: any, record: any) => [
                // <a key="下载" style={{marginRight: 8}} download href={fileHttpUrl(record.url)}>下载</a>,
                <a key="下载" style={{marginRight: 8}} onClick={() => operate('下载', record)}>下载</a>
            ]
        }
    ]
}
