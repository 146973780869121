import { ProColumns } from "@ant-design/pro-table"
import { StateType } from "@store/index"
import { handleIds } from "@utils/index"
import { Switch, TreeSelect } from "antd"
import React from "react"
import { useSelector } from "react-redux"

/**
 * 列表表格配置:
 *  自定义表格 CustomerDemo 和 antd 升级版组件 ProTableDemo 都可使用, 
 * */
 export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    const { productData } = useSelector((state: StateType) => state) as any;
    const { treeData } = productData
    return [
        {
            title: '产品类别',
            dataIndex: 'ptName',
            key: 'ptName',
            fieldProps: {
                maxTagCount: 4,
                treeCheckable: true,
                showSearch: true,
                treeNodeFilterProp: 'title',
                treeData: treeData
            },
            renderFormItem: (props) => <TreeSelect />,
            search: {
                transform: (ids) => {
                    return {
                        piIds: handleIds(ids, 'p').toString()
                    }
                },
            },
        },
        {
            title: '产品名称',
            dataIndex: 'piName',
            key: 'piName',
            hideInSearch: true
        },
        {
            title: 'ip',
            dataIndex: 'uilIp',
            key: 'uilIp',
            hideInSearch: true
        },
        {
            title: '开启状态',
            dataIndex: 'uilIsEnabled',
            key: 'uilIsEnabled',
            hideInSearch: true,
            render: (text: any, record: any) => <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={!!record.uilIsEnabled} onChange={() => operate('开启状态', record)} />
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            valueType: 'option',
            fixed: 'right',
            readOnly: true,
            render: (text: any, record: any) => [
                <a key="修改" style={{marginRight: 8}} onClick={() => operate('修改', record)}>修改</a>,
                <a key="删除" onClick={() => operate('删除', record)}>删除</a>,
            ]
        }
    ]
}