import React from 'react';
import ReactDOM from 'react-dom';
import App from './Apps'
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import '@assets/css/index.less'
// import "tailwindcss/tailwind.css"

ReactDOM.render(
    <ConfigProvider locale={zhCN}>
        <App />
    </ConfigProvider>,
    document.getElementById('root')
)
