import { WithSearchTree } from "@components/Common"
import { StateType } from "@store/index"
import { Button, Col, Divider, Input, Row } from "antd"
import React, { Children, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { ActionType, ProTable } from "@ant-design/pro-table"
import { columnsFn } from "./data"
import { SearchOutlined, DownOutlined } from '@ant-design/icons'
import './index.less'
import { useHistory, useLocation, useParams } from "react-router-dom"
import { parseSearch, productApi, setHref } from "@utils/index"
import { stringify } from "qs"
import { docGetDocDetailById, docGetDocTreeData, docSearch } from "@api/doc"
import liucheng from '@assets/images/liucheng.png'
import {
    CloseOutlined
  } from '@ant-design/icons';
const docSearchApi = productApi(docSearch)

const depsGetParentIds = (targetId: any, arr: any) => {
    const returnIds = []
    let searchId = targetId
    let count = 0
    while(searchId) {
        const target = (arr||[]).find((v: any) => v.docId === searchId)
        if (target) {
            searchId = target.docPid
            returnIds.push(searchId)
        }
        count++
        if (count > 1000)
        return
    }
    return returnIds
}

const { Search } = Input
type DocSearchProps = {
    onSearch?: (keyword: string) => void;
}
const DocSearch = ({onSearch}: DocSearchProps) => {
    const keyWordRef = useRef<any>(null)
    const [status, setStatus] = useState<any>('')
    return (<Input.Group compact>
            <Input
                placeholder='输入关键字搜索, 如"国际短信"'
                size="large"
                status={status}
                className="j-search-input"
                // style={{width: 600}}
                prefix={<SearchOutlined />}
                onFocus={() => setStatus('error')}
                onBlur={() => setStatus('')}
                onChange={(e) => {
                    keyWordRef.current = e.target.value
                }}
                onPressEnter={() => onSearch && onSearch(keyWordRef.current)}
            />
            <Button className="jc-theme-button" size="large" danger style={{color: '#fff'}} onClick={() => onSearch && onSearch(keyWordRef.current)}>搜索</Button>
        </Input.Group>)
}

type DocSearchResultProps = {
    keyword: string;
}
const DocSearchResult = ({keyword}: DocSearchResultProps) => {
    const [total, setTotal] = useState(0)
    const getApi = async (params: any) => {
        const { data } = await docSearchApi(params)
        const { values, total } = data || {};
        setTotal(total)
        return {
            data: values || [],
            success: true,
            total,
        };
    }

    return (<div>
        <div style={{borderBottom: '1px solid #F5F5F5', padding: '15px 0', color: '#989898'}}>共找到{total||0}个搜索结果</div>
        {/* <div style={{padding: '15px 0'}}>
            <div style={{color: '#989898', marginBottom: 8}}>相关产品:</div>
            <div>
                <Button danger style={{marginRight: 8}}>国内短信</Button>
                <Button danger>国际短信</Button>
            </div>
        </div> */}
        <ProTable<any>
            className="j-antd-protable-nopadding"
            columns={columnsFn(keyword)}
            params={{text: keyword}} // 查询所需要的额外参数
            request={getApi}
            options={{reload: false, density: false, setting: false}}
            rowKey="docId"
            pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
            showHeader={false}
            // actionRef={ref as any}
            search={false}
        />
    </div>)
}

// 没有 docId 时的默认文档详情
export const DocDetailDefault = () => {
    const { ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
    return (<div style={{minHeight: 560}}>
        <h1>文档中心</h1>
        <div >我们为您提供丰富的产品及服务介绍、产品使用指南、API说明文档等，帮助您快速高效地使用{ueSaasName}产品和服务。</div>
        <Divider style={{margin: '10px 0'}} />
        <h3 style={{textAlign: 'center', marginTop: 48}}>快速入门</h3>
        <div className="j-text-black-100" style={{textAlign: 'center'}}>实名认证成功即可免费试用产品</div>
        <img src={liucheng} alt={`${ueSaasName}使用流程`} />
    </div>)
}

// 真正意义上的文档详情
type DocDetailPureProps = {
    docId?: String | Number;
    docInfo?: any;
}

const getDocDetailInitialProps = async (params: any) => {
    const { data } = await docGetDocDetailById(params) || {} as any
    return data
}

const DocDetailPure = ({docId, docInfo: propsDocInfo}: DocDetailPureProps) => {
    const iframeRef = useRef(null)
    const [iframeHeight, setIframeHeight] = useState(0)
    const [docInfo, setDocInfo] = useState<any>(propsDocInfo)
    const getDocInfoApi = async (params: any) => {
        const docInfo = await getDocDetailInitialProps(params) || {} as any
        setDocInfo(docInfo||{})
    }
    useEffect(() => {
      propsDocInfo ? null : (docId && getDocInfoApi({id: docId}))
    }, [docId, propsDocInfo])

    // useEffect(() => {
    //     window.addEventListener('message', (e) => {
    //         if (e?.data?.type === 'doc_ready') {
    //             setIframeHeight(e?.data?.payload.height)
    //         }
    //     })
    // }, [])

    return (<div>
        <h1 style={{fontSize: '2rem'}}>{docInfo?.docTitle}</h1>
        <div  style={{fontSize: '1rem'}} className="j-text-black-100">最近更新时间: {docInfo?.updateTime}</div>
        <Divider style={{margin: '10px 0'}} />
        <div style={{minHeight: 400}} className="tinymce" dangerouslySetInnerHTML={{__html: docInfo?.docContent}}></div>
        {/* <iframe ref={iframeRef} style={{width: '100%', height: iframeHeight}} scrolling='no' src={`https://www.yuque.com/yuque/blog/digital_garden?${stringify({view: 'doc_embed', from: 'anything', title: 0, outline: 0})}`}></iframe> */}
    </div>)
}

DocDetailPure.getInitialProps = getDocDetailInitialProps

// 具有子目录的文档详情
const DocDetailWithChildren = ({treeItem, onClick}: any) => {
    const { docTitle, children } = treeItem || {}
    return (<ul style={{listStyle: 'inside'}}>
        <h1>{docTitle}</h1>
        {
            (children||[]).map((item: any) => {
                const {docId, docTitle} = item || {}
                return <li key={docId} className={'cursor-pointer text-blue-600'} onClick={() => onClick && onClick(item)}>
                    {docTitle}
                </li>
            })
        }
    </ul>)
}

// 文档详情
export const DocDetail = ({docId, docInfo}: DocDetailPureProps) => {
    const history = useHistory()
    const { treeData: memoTreeData, flatArr, allLeafArr } = useSelector((state: StateType) => state).docTreeData
    const getSelectedNode = () => (flatArr||[]).find((v: any) => (v.docId - (docId as any)) === 0)
    const isDocDirectory = () => getSelectedNode()?.children?.length

    const findPrevAndNext = (docId: any) => {
        if (!docId || !(allLeafArr||[]).length) return {}
        const allIds = (allLeafArr||[]).map((v: any) => v.id)
        const idx = allIds.indexOf(docId)
        const prev = allLeafArr[idx - 1]
        const next = allLeafArr[idx + 1]
        return {
            prev,
            next
        }
    }

    const prevAndNext = (docId: any) => {
        // setSelectId(docId)
        history.push(`/home/doc/${docId}?docId=${docId}`)
    }

    return (<>
        { isDocDirectory() ? <DocDetailWithChildren treeItem={getSelectedNode()} onClick={({docId}: any) => prevAndNext(docId)} /> : docId ? <DocDetailPure docId={docId} docInfo={docInfo} /> : <DocDetailDefault /> }
        <Divider style={{margin: '10px 0'}} />
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <span
               className='hover:text-red-500'
               style={{cursor: 'pointer',}}
               onClick={() => prevAndNext(findPrevAndNext((docId as any)*1)?.prev?.docId)}
            >
                {findPrevAndNext((docId as any)*1).prev ? `上一篇: ${findPrevAndNext((docId as any)*1)?.prev?.docTitle}` : null}
            </span>
            <span
               className='hover:text-red-500'
               style={{cursor: 'pointer',}}
               onClick={() => prevAndNext(findPrevAndNext((docId as any)*1)?.next?.docId)}
            >
               {findPrevAndNext((docId as any)*1).next ? `下一篇: ${findPrevAndNext((docId as any)*1)?.next?.docTitle}` : null}
            </span>
        </div>
    </>)
}

export const DocDetailForRoute = () => {
    const { docId } = useParams() as any
    return <DocDetail docId={docId} />
}

export const DocDetailForSSR = ({docInfo}: any) => {
  const { docId } = useParams() as any
  return <DocDetail docId={docId} docInfo={docInfo} />
}

DocDetailForSSR.getInitialProps = getDocDetailInitialProps

export const DocWarp = ({docId, children}: any) => {
    const history = useHistory()
    const [treeData, setTreeData] = useState(null)
    const [selectId, setSelectId] = useState(docId*1)
    const { treeData: memoTreeData, flatArr, allLeafArr } = useSelector((state: StateType) => state).docTreeData
    const [expandedKeys, setExpandedKeys] = useState<string[]>([docId])
    const treeSearch = ({ filterData, expandedKeys }: any) => {
        setTreeData(filterData)
        setExpandedKeys(expandedKeys)
    }

    const onSelect = (e:any, {node}:any) => {
        const { docId, children = [] } = node || {}
        setSelectId(docId)
        // history.push(`/home/doc/${docId}?docId=${docId}`)
        setHref(`/home/doc/${docId}?docId=${docId}`)
    }
    useEffect(() => {
        const expandedKeys = docId ? depsGetParentIds(docId*1, flatArr) as any : (flatArr||[]).map((v: any) => v.docId)
        setExpandedKeys(expandedKeys)
    }, [docId, flatArr])

    return (
      <>
        <Row className="doc-tree-search-warp pc-component">
          <Col>
            <div
              style={{
                background: "#ECECEC",
                padding: "10px 24px",
                fontWeight: 500,
              }}
            >
              文档目录
            </div>
            <div style={{ background: "#F7F7F7", padding: 20 }}>
              <WithSearchTree
                switcherIcon={<DownOutlined />}
                // showLine
                style={{ background: "#F7F7F7" }}
                treeData={treeData || memoTreeData || []}
                selectedKeys={[docId * 1 || ""]}
                blockNode
                showIcons={[]}
                fieldNames={{
                  key: "docId",
                  title: "docTitle",
                }}
                expandedKeys={expandedKeys}
                // autoExpandParent={true}
                defaultExpandAll={true}
                onExpand={setExpandedKeys as any}
                onSearch={treeSearch}
                onSelect={onSelect}
                // titleOperate={titleOperate}
                searchProps={{
                  enterButton: false,
                }}
              />
            </div>
          </Col>
          <Col
            style={{ padding: 40, maxWidth: "calc(100% - 260px)" }}
            flex="auto"
          >
            {children}
          </Col>
        </Row>
        <div className="jm-component">
            
          {/* 目录 */}
          {
            !docId ? 
          <div>
            <div
              style={{
                background: "#ECECEC",
                padding: "10px 24px",
                fontWeight: 500,
              }}
            >
              文档目录
            </div>
            <div style={{ background: "#F7F7F7", padding: 20 }}>
              <WithSearchTree
                switcherIcon={<DownOutlined />}
                // showLine
                style={{ background: "#F7F7F7" }}
                treeData={treeData || memoTreeData || []}
                selectedKeys={[docId * 1 || ""]}
                blockNode
                showIcons={[]}
                fieldNames={{
                  key: "docId",
                  title: "docTitle",
                }}
                expandedKeys={expandedKeys}
                // autoExpandParent={true}
                defaultExpandAll={true}
                onExpand={setExpandedKeys as any}
                onSearch={treeSearch}
                onSelect={onSelect}
                // titleOperate={titleOperate}
                searchProps={{
                  enterButton: false,
                }}
              />
            </div>
          </div> : <div className="docContentDiv" style={{padding: '40px',}}>
            <CloseOutlined onClick={()=> setHref(`/home/doc`)} className="docCloseIcon"/>
             {children}
          </div>
          }
        </div>
      </>
    );
}

export const DocLayout = ({children}: any) => {
    const history = useHistory()
    const { search, pathname } = useLocation()
    const { keyword, docId } = parseSearch(search)
    const onSearch = (keyword: any) => {
        history.push(`/home/doc?${stringify({keyword})}`)
    }

    const RenderWarp = () => {
        if (pathname === '/home/doc' && keyword) {
            return <div style={{padding: '0 5%'}}><DocSearchResult keyword={keyword} /></div>
        }
        return (<DocWarp docId={docId}>
            {pathname === '/home/doc' ? <DocDetailDefault /> : children}
        </DocWarp>)
    }

    return (<div style={{paddingTop: 64}} className="jc-doc">
        <div className="doc-search-warp" style={{padding: '5rem 5%'}}>
            <h1>文档中心</h1>
            <DocSearch onSearch={onSearch} />
        </div>
        <RenderWarp />
    </div>)
}

const Doc = () => {
  const history = useHistory()
  const { search } = useLocation()
  const { keyword, docId } = parseSearch(search)
  const onSearch = (keyword: any) => {
      history.push(`/home/doc?${stringify({keyword})}`)
  }
  return (<div style={{paddingTop: 64}} className="jc-doc">
      <div className="doc-search-warp" style={{padding: '80px 5%'}}>
          <h1>文档中心</h1>
          <DocSearch onSearch={onSearch} />
      </div>
      {
          keyword ?  <div style={{padding: '0 5%'}}>
                          <DocSearchResult keyword={keyword} />
                      </div> : <DocWarp docId={docId} />
      }
  </div>)
}

export default Doc
