import { fileHttpUrl } from '@api/baseUrl'
import React, { useRef, useState } from 'react'
import './index.less'
import { isBrowser } from '@utils/util'

export default ({src,...rest}:any) => {
    let [imgLength, setImgLength] = useState(0)
    const interval1 = useRef<any>(null)
    const interval2 = useRef<any>(null)

    const onMouseOver = () => {
        clearInterval(interval2.current)
        interval1.current = setInterval(()=> {
            setImgLength( ++imgLength);
            if(imgLength >= 24){
                clearInterval(interval1.current)
            }
        }, 30)
    }
    const onMouseOut = () => {
        clearInterval(interval1.current)

        interval2.current = setInterval(()=> {
            setImgLength( --imgLength);
            if(imgLength <= 1){
                clearInterval(interval2.current)
                setImgLength(0);
            }
        }, 30)
    }
    return (
        <div {...rest}>
            <div className='imgOrder_imgDiv_cpn' onMouseOver={()=>onMouseOver() } onMouseOut={()=>onMouseOut()} style={{ background: `url(${src}) no-repeat`,backgroundPositionY: -(imgLength >= 0 ? imgLength : 0 ) * (isBrowser() ? document.getElementsByClassName('imgOrder_imgDiv_cpn')[0]?.offsetWidth : 0 )}}>
            </div>
        </div>
    )
}