import { ProColumns } from "@ant-design/pro-table"
import { DateRangeWithDisabled } from "@components/Common"
import { smsTypeOptions } from "@utils/index"
import moment from "moment"
import React from "react"

export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '时间',
            dataIndex: 'sendTime',
            key: 'sendTime',
            valueType: 'dateRange',
            fieldProps: { defaultValue: [moment(), moment()]},
            formItemProps: {
                initialValue: [moment(), moment()],  
            },
            search: {
                transform: (value) => {
                    return {
                        startTime: value[0] ? value[0] + ' 00:00:00' : undefined,
                        endTime: value[1] ? value[1] + ' 23:59:59' : undefined
                    }
                },
            },
            renderFormItem: (props) => <DateRangeWithDisabled />,
            render: (text: any, record: any) => record.sendTime
        },
        {
            title: '发送数量',
            dataIndex: 'sendCount',
            key: 'sendCount',
            hideInSearch: true
        },
        {
            title: '短信类型',
            dataIndex: 'smsType',
            key: 'smsType',
            valueType: 'select',
            request: async () => smsTypeOptions
        },
        {
            title: '计费',
            dataIndex: 'chargeCount',
            key: 'chargeCount',
            hideInSearch: true
        },
        {
            title: '计费率',
            dataIndex: 'chargeRate',
            key: 'chargeRate',
            hideInSearch: true
        },
        {
            title: '不计费数',
            dataIndex: 'notChargeCount',
            key: 'notChargeCount',
            hideInSearch: true
        },
        {
            title: '消费金额',
            dataIndex: 'balance',
            key: 'balance',
            hideInSearch: true
        },
        // {
        //     title: '操作',
        //     dataIndex: 'operate',
        //     key: 'operate',
        //     valueType: 'option',
        //     render: (text: any, record: any) => [
        //         <a key="详情" style={{marginRight: 8}} onClick={() => operate('详情', record)}>详情</a>,
        //         <a key="删除" onClick={() => operate('删除', record)}>删除</a>,
        //     ]
        // }
    ]
}
