import { ProColumns } from "@ant-design/pro-table";
import { fileHttpUrl } from "@api/baseUrl";
import { DateRangeWithDisabled } from "@components/Common";
import { Input, Select, Image } from "antd";
import React, { ReactNode } from "react";

const options = [{label: 'options1', value: 1}, {label: 'options2', value: 2}]


const valueEnum = {
    // // 0: { text: '全部', status: 'Default' },
    // 1: { text: '成功', status: 'Sueecss' },
    // 2: { text: '失败', status: 'Error' },
    // // 3: { text: '其他错误', status: 'Processing' },
    1: { text: '审批通过', status: 'Sueecss' },
    2: { text: '审批不通过', status: 'Error' },
    0: { text: '未审批', status: 'Default' },
}

const valueEnumCharge = {
    2: { text: '不收费', status: 'Default' },
    1: { text: '收费', status: 'Sueecss' },
}

/**
 * 列表表格配置:
 *  自定义表格 CustomerDemo 和 antd 升级版组件 ProTableDemo 都可使用, 
 * */
export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
        {
            title: '用户姓名',
            dataIndex: 'userName',
            // valueType: 'select',
            hideInSearch: true
        },
        {
            title: '手机号',
            dataIndex: 'userPhone',
            // key: 'phoneNum',
            hideInSearch: true
        },
        {
            title: '文字成语',
            dataIndex: 'captchaName',
        },
        {
            title: '文字背景',
            dataIndex: 'captchaUrl',
            hideInSearch: true,
            render: (text: any, record: any) => <Image width={100} src={fileHttpUrl(text)} />
        },
        {
            // title: '状态',
            title: '审批状态',
            dataIndex: 'verifyState',
            valueType: 'select',
            fieldProps: {
                // defaultValue: 2,
                allowClear: false
             },
            valueEnum: valueEnum,
            // valueEnum,
            // hideInSearch: true
        },
        {
            title: '审批备注',
            dataIndex: 'verifyDescribe',
            hideInSearch: true
        },
        {
            title: '审批时间',
            dataIndex: 'verifyTime',
            // valueType: 'dateTimeRange',
            valueType: 'dateRange',
            fieldProps: { },
            hideInSearch: true,
            search: {
                transform: (value) => {
                    return {
                        startTime: value[0] ? value[0] + ' 00:00:00' : undefined,
                        endTime: value[1] ? value[1] + ' 23:59:59' : undefined
                    }
                },
            },
            renderFormItem: (props) => <DateRangeWithDisabled />,
            render: (text: any, record: any) => record.verifyTime,
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            valueType: 'option',
            fixed: 'right',
            readOnly: true,
            width: 160,
            render: (text: any, record: any) => [
                <a key="编辑" onClick={() => operate('编辑', record)}>编辑</a>,
                <a key="删除" onClick={() => operate('删除', record)}>删除</a>,
                [1].includes(record?.verifyState) ? <a key="使用" onClick={() => operate('使用', record)}>{ record.useState ? '使用中' : '使用'}</a> : null
                
            ]
        }
    ]
}
