import React, { ReactNode, useEffect, useMemo, useState } from "react";
import ProForm, { ProFormItemProps } from '@ant-design/pro-form';
import ProTable, { ProTableProps } from "@ant-design/pro-table";
import { AutoComplete, TreeSelect, Cascader, DatePicker } from "antd";
import { ParamsType } from "@ant-design/pro-provider";
import { FormDragSetting, TextCapcha, getFormDragSettingOptions, setColumnsByOptions } from "..";
import { cloneDeep } from 'lodash'
import type { Moment } from 'moment';
import { RangePickerProps } from "antd/lib/date-picker";
import { CaptchaProps } from "../Captcha";

const { RangePicker } = DatePicker;

interface AddproFormItemProps extends ProFormItemProps{
  onSelect?: any; //添加切换事件   切换部门  自动回填负责人
}
export const ProFormTreeSelect = ({fieldProps, ...rest}: AddproFormItemProps) => {
    return (
      <ProForm.Item {...rest}>
        <TreeSelect {...(fieldProps||{}) as any} {...rest} />
      </ProForm.Item>
    );
};

export const ProFormAutoComplete = ({fieldProps, ...rest}: AddproFormItemProps) => {
  return (
    <ProForm.Item {...rest}>
      <AutoComplete {...(fieldProps||{}) as any} {...rest} />
    </ProForm.Item>
  );
};

export const ProFormCascader = ({fieldProps, ...rest}: ProFormItemProps) => {
  return (
    <ProForm.Item {...rest}>
      <Cascader {...(fieldProps||{}) as any} {...rest} />
    </ProForm.Item>
  );
}

type RangeValue = [Moment | null, Moment | null] | null;
type DateRangeWithDisabledProps = RangePickerProps & {
  nearDays?: number
}
export const DateRangeWithDisabled = ({value: initialValue, onChange, nearDays, ...rest}: DateRangeWithDisabledProps) => {
  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<any>(initialValue);

  const disabledDate = (current: Moment) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > (nearDays || 30);
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > (nearDays || 30);
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const onOwnChange = (val: any) => {
    setValue(val)
    onChange?.(val)
  }

  return (
    <RangePicker
      value={dates || value}
      disabledDate={disabledDate}
      onCalendarChange={val => setDates(val)}
      onChange={onOwnChange}
      onOpenChange={onOpenChange}
      {...rest}
    />
  );
};

interface ProTableWithFormSettingProps<T> extends ProTableProps<T,ParamsType>{
  settingOptions?: any[];
  searchState?: { persistenceKey: string, persistenceType: 'localStorage' | 'sessionStorage'}
}

export const ProTableWithFormSetting: <T>(props: ProTableWithFormSettingProps<T>) => JSX.Element = ({columns, searchState, ...rest}) => {
  const { persistenceKey, persistenceType } = searchState || {}
  const columnsBack = cloneDeep(columns as any)

  const getInitSettingOptions = (columns: any[]) => {
    if ( persistenceType && persistenceKey ) {
      const settingOptionsStr = window[persistenceType].getItem(persistenceKey)
      const settingOptions = JSON.parse(settingOptionsStr as any) || getFormDragSettingOptions(columns as any)
      return settingOptions
    } else {
      return getFormDragSettingOptions(columns as any)
    }
  }

  const getInitColumns = (settingOptions: any) => {
    return setColumnsByOptions(settingOptions as any, columnsBack as any)
  }

  const initSettingOptions = getInitSettingOptions(columnsBack as any)
  const initColumns = getInitColumns(initSettingOptions)
  const [ownColumns, setOwnColumns] = useState(initColumns)
  const [settingOptions, setSettingOptions] = useState(initSettingOptions)

  const onFormSettingChange = (options: any[], type: string) => {
    if (type === 'reset') {
      const settingOptions = getFormDragSettingOptions(columns as any)
      setSettingOptions(settingOptions)
    } else {
      setSettingOptions(options as any)
    }
  }

  useEffect(() => {
    persistenceType && persistenceKey && window[persistenceType].setItem(persistenceKey, JSON.stringify(settingOptions))
    const newColumns = setColumnsByOptions(settingOptions as any, columnsBack as any)
    setOwnColumns([...newColumns] as any)
  }, [settingOptions, columns])

  return <ProTable
            columns={ownColumns as any}
            search={{
              optionRender: (searchConfig, formProps, dom) => [
                ...dom.reverse(),
                <FormDragSetting key="drag" options={settingOptions as any} onChange={onFormSettingChange} />,
              ],
            }}
            {...rest}
          />
}


export const ProFormTextCaptcha = ({fieldProps, children, ...rest}: ProFormItemProps) => {
  return (
    <ProForm.Item {...rest}>
      <TextCapcha {...fieldProps as CaptchaProps}>{children}</TextCapcha>
    </ProForm.Item>
  );
}