import { Tag, Timeline } from "antd"
import React from "react"

const changelog = [
    {
        version: 'v2.0.0',
        time: '2022-11-30',
        desc: [
            'v2.0.0 版本首次发布'
        ]
    },
    {
        version: 'v2.6.4',
        time: '2023-12-14',
        desc: [
            'v2.6.4 版本发布',
            '【费用中心/收支明细】 查询条件: 交易时间(默认查询一个月 => 默认查询当天)',
            '新产品:【手机号二要素，银行卡二要素】上线'
        ]
    },
]

export default () => {
    return (<div style={{display: 'flex', justifyContent: 'center', padding: 20}}>
        <Timeline>
            {
                changelog?.map((v: any, i: number) => {
                    const {version, time, desc} = v
                    return (<Timeline.Item key={i}>
                        <div>
                            <h3>版本: {version}</h3>
                            <div><Tag>2022-11-30</Tag></div>
                            <ul style={{listStyle: 'circle', paddingLeft: 30}}>
                                {desc?.map((d: any, k: number) => (<li key={k}>{d}</li>))}
                            </ul>
                        </div>
                    </Timeline.Item>)
                })
            }
            <Timeline.Item>
                doing...
            </Timeline.Item>
        </Timeline>
    </div>)
}
